import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { BsArrowDownShort } from "react-icons/bs";
import { useReactToPrint } from "react-to-print";
import Swal from "sweetalert2";
import Select from "react-select";

import pinCorreios from "../../../../../assets/icons/pin-correios.svg";

import { ContentDeclaration } from "../../../../../components/ContentDeclaration";
import { Loading } from "../../../../../components/Loading";
import { PlpTag } from "../../../../../components/PlpTag";
import { Tag } from "../../../../../components/Tag";
import { TagOwnDelivery } from "../../../../../components/TagOwnDelivery";
import DeliveryTypeEnum from "../../../../../enums/DeliveryTypeEnum";
import { RateTypeShippingEnum } from "../../../../../enums/RateTypeShippingEnum";
import { OriginEnum } from "../../../../../enums/OriginEnum";
import { BrComerceApiService } from "../../../../../services/BrComerceApiService";
import styles from "./styles.module.scss";

const selectStyle = {
  control: styles => ({
    ...styles,
    backgroundColor: "inherit",
    width: "100%",
    border: "none",
    boxShadow: "none",
    outline: "none",
    color: "#807E80",
    padding: "0",
    "&:hover": {
      borderColor: "none"
    },
    maxHeight: "20px",
    cursor: "pointer"
  }),
  container: styles => ({
    ...styles,
    minWidth: "50%",
    outline: "none",
    border: "1px solid #c4c4c4",
    borderRadius: '16px',
    padding: "0 10px",
    cursor: "pointer"
  }),
  indicatorSeparator: styles => ({
    ...styles,
    display: "none",
    border: "none"
  }),
  option: (styles, { isSelected }) => ({
    ...styles,
    fontSize: "14px",
    fontFamily: "Mulish",
    fontWeight: 400,
    color: isSelected ? "#fff" : "#807E80",
    "&:hover": {
      backgroundColor: "#d6d5d5",
      color: "#color: #333333;",
      cursor: "pointer"
    },
    backgroundColor: isSelected ? "#409e6a" : "#fff"
  }),
  dropdownIndicator: styles => ({
    ...styles,
    padding: "0",
    color: "#4D4D4D"
  }),
  placeholder: styles => ({
    ...styles,
    fontSize: "14px",
    fontFamily: "Mulish",
    fontWeight: 400,
    color: "rgba(193, 193, 193, 1)",
    whiteSpace: "nowrap"
  })
};

const Tags = ({ width }) => {
  const api = new BrComerceApiService();
  const [tags, setTags] = useState([]);

  const [one, setOne] = useState(false);
  const [declaration, setDeclaration] = useState(false);
  const [onlyDeclaration, setOnlyDeclaration] = useState(false);
  const [onlyPlp, setOnlyPlp] = useState(false);
  const [senderDefaultName, setSenderDefaultName] = useState("");
  const [senderDefaultAddress, setSenderDefaultAddress] = useState("");
  const [plpTag, setPlpTag] = useState("");
  const [showPlp, setShowPlp] = useState(false);
  const [rateType, setRateType] = useState("");
  const [orderPrint, setOrderPrint] = useState();

  const [orderPrintOptions, setOrderPrintOptions] = useState([])

  const [userLocation, setUserLocation] = useState({
    lat: 0,
    lng: 0
  });

  const [showPostOfficesList, setShowPostOfficesList] = useState(false);

  const [loading, setLoading] = useState(false);

  const componentRef = useRef();
  const containerForPrintTags = useRef(null);

  const integrationToDisplayName = useMemo(() => {
    return [
      OriginEnum.VNDA,
      OriginEnum.BLINGV3
    ]
  }, []);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    copyStyles: () => true,
    pageStyle: () => `
      @page {
        size: ${one ? "100mm 150mm !important" : "A4 !important"};
        padding: 0 !important;
        margin: 3mm !important;
      }
    `
  });

  const handlePrinterButton = () => {
    handlePrint();

    const isRetail = tags[0]?.shipping.rateType === RateTypeShippingEnum.RETAIL;

    if (isRetail && userLocation.lat !== 0) {
      setTimeout(() => {
        Swal.fire({
          title: "Agências próximas",
          text: "Veja no mapa as agências dos Correios próximo a você",
          icon: "info"
        });
        setShowPostOfficesList(true);
      }, 2000);
    }
  };

  const goToPrintContainer = () => {
    containerForPrintTags.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "start"
    });
  };

  const getSenderName = useCallback(async (origin, idShipping) => {
    let response;
    setLoading(true);

    switch (origin) {
      case "nuvemshop":
        response = await api.makeHttpRequest({
          method: "GET",
          url: `/nuvemshop/config/SENDER_DEFAULT_NAME?idShipping=${idShipping}`
        });

        setLoading(false);
        setSenderDefaultName(response);
        break;

      case "vtex":
        response = await api.makeHttpRequest({
          method: "GET",
          url: `/vtex/config/key/SENDER_DEFAULT_NAME/?idShipping=${idShipping}`
        });

        setLoading(false);
        setSenderDefaultName(response);
        break;

      case "bling":
        response = await api.makeHttpRequest({
          method: "GET",
          url: `/bling/config/key/SENDER_DEFAULT_NAME?idShipping=${idShipping}`
        });

        setLoading(false);
        setSenderDefaultName(response);
        break;

      case "tray":
        response = await api.makeHttpRequest({
          method: "GET",
          url: `/tray/config/key/SENDER_DEFAULT_NAME?idShipping=${idShipping}`
        });

        setLoading(false);
        setSenderDefaultName(response);
        break;

      case "woocommerce":
        response = await api.makeHttpRequest({
          method: "GET",
          url: `/woocommerce/config/SENDER_DEFAULT_NAME?idShipping=${idShipping}`
        });

        setLoading(false);
        setSenderDefaultName(response);
        break;

      default:
        return;
    }
  }, []);

  const turnStringIntoObject = object => {
    const address = JSON.parse(object || "[]");
    return address;
  };

  const getSenderAddress = useCallback(async (origin, idShipping) => {
    setLoading(true);

    if (origin === "vtex") {
      const response = await api.makeHttpRequest({
        method: "GET",
        url: `/vtex/config/key/SENDER_DEFAULT_ADDRESS?idShipping=${idShipping}`
      });

      const addressObject = turnStringIntoObject(response);
      setSenderDefaultAddress(addressObject);
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    const etiquetaStore = window.localStorage.getItem("Brcom:Etiquetas");

    const collectIdstore = window.localStorage.getItem(
      "Brcom:EtiquetasCollectId"
    );

    if (etiquetaStore) {
      const data = JSON.parse(etiquetaStore);

      setLoading(true);
      api
        .makeHttpRequest({
          method: "post",
          url: `tag`,
          data: {
            shippings: data,
            idCollect: Number(collectIdstore)
          }
        })
        .then(async response => {
          console.log(
            "🚀 ~ file: index.jsx ~ line 81 ~ useEffect ~ response",
            response[0]
          );
          if (response[0].plpNumber) {
            setPlpTag(response[0]);
            response.shift();
            setRateType(response[0].shipping.rateType);
          }

          setTags([...response]);
          if (response[0].shipping.originCreation !== "app") {
            const idShipping = response[0]?.shipping?.id;
            //setSenderDefaultName(response[0].shipping.user.senderName);
            await Promise.all([
              getSenderName(response[0].shipping.originCreation, idShipping),
              getSenderAddress(response[0].shipping.originCreation, idShipping)
            ]);
          }
        })
        .catch(error => {
          console.log(error);
          Swal.fire({
            icon: "error",
            title: "Etiquetas",
            text: "Não foi possível gerar etiquetas."
          });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, []);

  useEffect(() => {
    if (tags.length) {
      const array = [];

      const options = [
        // { condition: tags.some(t => t?.shipping?.name), value: 'name', label: 'Nome' },
        { condition: tags.some(t => t?.shipping?.invoiceNumber), value: 'invoiceNumber', label: 'NF' },
        { condition: tags.some(t => t?.shipping?.orderNumber), value: 'orderNumber', label: 'Pedido' },
        { condition: tags.some(t => t?.shipping?.destination?.name), value: 'destinationName', label: 'Destinatário' },
      ];

      options.forEach(option => {
        if (option.condition) {
          array.push({ value: option.value, label: option.label });
        }
      });
      setOrderPrintOptions(array);
    }
  }, [tags])

  useEffect(() => {
    if (!declaration || one) {
      setOnlyDeclaration(false);
    }

    if (!plpTag || one) {
      setOnlyPlp(false);
    }
  }, [plpTag, declaration, one]);

  const summary = useMemo(() => {
    return tags?.reduce(
      (a, b) => {
        const sedexCount = a.expressos + (b.shipping.type === "SEDEX" ? 1 : 0);
        const pacCount = a.economicos + (b.shipping.type === "PAC" ? 1 : 0);
        const pacMiniCount =
          a.pacmini + (b.shipping.type === "PACMINI" ? 1 : 0);
        const ownDeliveryCount =
          a.ownDelivery + (b.shipping.type === "OWN_DELIVERY" ? 1 : 0);
        return {
          coletas: a.coletas.includes(b.shipping.idCollect)
            ? a.coletas
            : [...a.coletas, b.shipping.idCollect],
          expressos: sedexCount,
          economicos: pacCount,
          pacmini: pacMiniCount,
          ownDelivery: ownDeliveryCount
        };
      },
      { coletas: [], expressos: 0, economicos: 0, pacmini: 0, ownDelivery: 0 }
    );
  }, [tags]);

  const lightThemeStyle = [
    {
      featureType: "poi",
      stylers: [
        {
          visibility: "off"
        }
      ]
    },
    {
      featureType: "road",
      elementType: "labels.icon",
      stylers: [
        {
          visibility: "off"
        }
      ]
    },
    {
      featureType: "transit",
      stylers: [
        {
          visibility: "off"
        }
      ]
    }
  ];

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY
  });

  const [map, setMap] = useState(null);

  const onLoad = useCallback(
    map => {
      if (map) {
        const bounds = new window.google.maps.LatLngBounds(userLocation);

        map.fitBounds(bounds);

        const service = new window.google.maps.places.PlacesService(map);

        service.nearbySearch(
          {
            keyword: "Agência dos Correios",
            type: "post_office",
            location: userLocation,
            radius: 5000
          },
          (results, status) => {
            if (status === window.google.maps.places.PlacesServiceStatus.OK) {
              for (const i of results) {
                console.log(i);
                const infoWindow = new window.google.maps.InfoWindow();

                const marker = new window.google.maps.Marker({
                  position: i.geometry.location,
                  icon: {
                    url: pinCorreios,
                    scaledSize: new window.google.maps.Size(60, 60)
                  },
                  map,
                  title: i?.vicinity || "Agência dos correios"
                });

                marker.setMap(marker);

                marker.addListener("click", ({ domEvent, latLng }) => {
                  infoWindow.setContent(marker.title);
                  infoWindow.open(marker.map, marker);
                });
              }
            }
          }
        );
      }
    },
    [userLocation]
  );

  const onUnmount = useCallback(map => {
    setMap(null);
  }, []);

  const [showHelperForPrint, setShowHelperForPrint] = useState(true);
  const onScroll = e => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;
    const totalScrolled = scrollTop + clientHeight;

    if (Number(totalScrolled.toFixed()) === scrollHeight) {
      setShowHelperForPrint(false);
    } else {
      setShowHelperForPrint(true);
    }
  };

  function parsedStringToNumber(keyA, keyB) {
    return parseInt(keyA, 16) - parseInt(keyB, 16)
  }

  function extractComponents(value) {
    const letters = value.match(/[a-zA-Z]+/);
    const numbers = value.match(/\d+/);
    return {
      letters: letters ? letters[0].toLowerCase() : '',
      numbers: numbers ? parseInt(numbers[0], 10) : 0
    };
  }

  const dynamicSort = (orderKey) => {
    return function (a, b) {

      const sortByName =
        integrationToDisplayName?.includes(a?.shipping?.originCreation) &&
        b?.shipping?.originCreation === a?.shipping?.originCreation;

      if (orderKey === 'orderNumber' && sortByName) {
        orderKey = "name";
      }

      let aValue = orderKey === 'destinationName' ? a?.shipping?.destination.name : a?.shipping[orderKey];
      let bValue = orderKey === 'destinationName' ? b?.shipping?.destination.name : b?.shipping[orderKey];

      if (sortByName && orderKey !== 'destinationName') {
        return parsedStringToNumber(aValue, bValue)
      }

      // Verifica se contém valor, se não tiver coloca no final do array
      if (!aValue) aValue = '\uffff';
      if (!bValue) bValue = '\uffff';

      // Verifica se o valor é um numero
      const aIsNumber = !isNaN(aValue);
      const bIsNumber = !isNaN(bValue);

      if (aIsNumber && bIsNumber) {
        aValue = parseFloat(aValue);
        bValue = parseFloat(bValue);
        return aValue - bValue;

      } else {
        const compA = extractComponents(aValue);
        const compB = extractComponents(bValue);

        if (isNaN(aValue[0]) && isNaN(bValue[0])) {
          if (compA.letters < compB.letters) return -1;
          if (compA.letters > compB.letters) return 1;
        }

        return compA.numbers - compB.numbers
      }
    }
  }
  const handleOrderTags = (value) => {
    setLoading(true)
    setTimeout(() => {
      const result = tags.sort(dynamicSort(value));
      setTags(result);
      setLoading(false);
    },
      1000
    );
  }

  useEffect(() => {
    window.addEventListener("scroll", onScroll, true);

    // Remove the event listener
    return () => {
      window.removeEventListener("scroll", onScroll, true);
    };
  }, []);

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        p => {
          setUserLocation({
            lat: p.coords.latitude,
            lng: p.coords.longitude
          });
        },
        e => console.log(e)
      );
    }
  }, []);

  useEffect(() => {
    if (map) {
      const bounds = new window.google.maps.LatLngBounds(userLocation);

      map.fitBounds(bounds);

      const service = new window.google.maps.places.PlacesService(map);

      service.nearbySearch(
        {
          keyword: "Agência dos Correios",
          type: "post_office",
          location: userLocation,
          radius: 5000
        },
        (results, status) => {
          if (status === window.google.maps.places.PlacesServiceStatus.OK) {
            for (const i of results) {
              console.log(i);
              const infoWindow = new window.google.maps.InfoWindow({
                content: i?.vicinity || "Carregando..."
              });

              const marker = new window.google.maps.Marker({
                position: i.geometry.location,
                icon: {
                  url: pinCorreios,
                  scaledSize: new window.google.maps.Size(60, 60)
                },
                map
              });

              marker.setMap(marker);

              marker.addListener("click", ({ domEvent, latLng }) => {
                const { target } = domEvent;

                infoWindow.close();
                infoWindow.setContent(marker.title);
                infoWindow.open(marker.map, marker);
              });
            }
          }
        }
      );
    }
  }, [userLocation, map]);

  useEffect(() => {
    if (orderPrint) {
      handleOrderTags(orderPrint?.value)
    }
  }, [orderPrint?.value, tags])

  console.log("uma etiqueta por folha. ", one);
  console.log("Imprimir declarações", declaration);
  console.log("Imprimir apenas declarações", onlyDeclaration);
  console.log("---");
  console.log("\n");

  return (
    <main className={styles.main}>
      <Loading loading={loading} />
      <div className={styles.container}>
        {showPostOfficesList && (
          <>
            <br></br>
            <div>
              <h2 className={styles.title}>Correios Próximos</h2>
              <div className={styles.googleMaps}>
                {isLoaded && (
                  <GoogleMap
                    mapContainerStyle={{
                      display: "flex",
                      flex: "1"
                    }}
                    options={{
                      styles: lightThemeStyle,
                      maxZoom: 16,
                      minZoom: 11
                    }}
                    onLoad={onLoad}
                    onUnmount={onUnmount}
                  >
                    {userLocation?.lat && userLocation?.lng && (
                      <Marker position={userLocation} title="Sua localização" />
                    )}
                  </GoogleMap>
                )}
              </div>
            </div>
            <br></br>
          </>
        )}
        <h2 className={styles.title}>Impressão de Etiquetas</h2>
        <div ref={componentRef} className={styles.content}>
          {!onlyDeclaration && !onlyPlp && (
            <>
              <div className={styles.tags} role={one ? "zebra" : ""}>
                {tags?.map((tag, n) => (
                  <React.Fragment key={tag.shipping.id}>
                    {tag.shipping.type === DeliveryTypeEnum.OWN_DELIVERY ? (
                      <TagOwnDelivery
                        origin={
                          tag.shipping.originCreation !== "app" &&
                            senderDefaultAddress?.zip
                            ? {
                              ...senderDefaultAddress,
                              name: tag.shipping.origin.name
                            }
                            : tag.shipping.origin
                        }
                        destination={tag.shipping.destination}
                        tagCode={tag.shipping.sro}
                        vd={tag.shipping.insurance ? "VD" : ""}
                        shipping={tag.shipping}
                        tagBarCode={tag.tagBarCode}
                        dataMatrix={tag.dataMatrix}
                        senderDefaultName={senderDefaultName}
                        invoiceNumber={tag.shipping.invoiceNumber}
                      />
                    ) : (
                      <Tag
                        contratoId={tag.contratoId}
                        origin={
                          tag.shipping.originCreation !== "app" &&
                            senderDefaultAddress?.zip
                            ? {
                              ...senderDefaultAddress,
                              name: tag.shipping.origin.name
                            }
                            : tag.shipping.origin
                        }
                        destination={tag.shipping.destination}
                        tagCode={tag.shipping.sro}
                        vd={tag.shipping.insurance ? "VD" : ""}
                        shipping={tag.shipping}
                        destinationCepBarcode={tag.destinationCepBarcode}
                        tagBarCode={tag.tagBarCode}
                        dataMatrix={tag.dataMatrix}
                        typeImg={tag}
                        senderDefaultName={senderDefaultName}
                        invoiceNumber={tag.shipping.invoiceNumber}
                      />
                    )}

                    {(n + 1) % (one ? 1 : 4) === 0 && (
                      <p className={styles.pageBreak} />
                    )}
                  </React.Fragment>
                ))}
              </div>
              {!one && <p className={styles.pageBreak} />}
            </>
          )}
          {declaration && !onlyPlp && (
            <div className={`${styles.declarations}`} role={one ? "zebra" : ""}>
              {tags?.map((tag, n) => (
                <React.Fragment key={tag.shipping.id}>
                  <ContentDeclaration shipping={tag?.shipping} />
                  {tag?.shipping?.length > 2 &&
                    (n + 1) % (one ? 1 : 2) === 0 && (
                      <p className={styles.pageBreak} />
                    )}
                </React.Fragment>
              ))}
            </div>
          )}
          {plpTag && showPlp && (
            <>
              <p className={styles.pageBreak} />
              <div className={styles.plpTags}>
                <PlpTag
                  sedexShippings={plpTag.sedexShippings}
                  pacShippings={plpTag.pacShippings}
                  pacMiniShippings={plpTag.pacMiniShippings}
                  plpBarcodeBase64={plpTag.plpBarcodeBase64}
                  plpNumber={plpTag.plpNumber}
                  shippingsNumber={plpTag.shippingsNumber}
                  plpDate={plpTag.plpDate}
                  cartaoPostagem={plpTag.cartaoPostagem}
                  cartaoPostagemBarcodeBase64={
                    plpTag.cartaoPostagemBarcodeBase64
                  }
                  isClientCopy={false}
                  userPLPType={tags[0]?.shipping?.user?.plpType}
                />
                <div className={styles.cutHereSeparator} />
                <PlpTag
                  sedexShippings={plpTag.sedexShippings}
                  pacShippings={plpTag.pacShippings}
                  pacMiniShippings={plpTag.pacMiniShippings}
                  plpBarcodeBase64={plpTag.plpBarcodeBase64}
                  plpNumber={plpTag.plpNumber}
                  shippingsNumber={plpTag.shippingsNumber}
                  plpDate={plpTag.plpDate}
                  cartaoPostagem={plpTag.cartaoPostagem}
                  cartaoPostagemBarcodeBase64={
                    plpTag.cartaoPostagemBarcodeBase64
                  }
                  isClientCopy={true}
                  // This rule has been applied because we won't have industrial and retail plp mixed together.
                  userPLPType={tags[0]?.shipping?.user?.plpType}
                />
              </div>
            </>
          )}
        </div>
      </div>
      <aside className={styles.aside}>
        <div className={styles.printOpts}>
          <h2>Opções de impressão</h2>

          <div className={styles.switchOption}>
            <span>Uma etiqueta por folha</span>
            <label className={styles.switch}>
              <input
                type="checkbox"
                onChange={() => setOne(!one)}
                checked={one}
              />
              <span className={`${styles.slider} ${styles.round}`}></span>
            </label>
          </div>

          <div className={styles.switchOption}>
            <span>Imprimir declarações</span>
            <label className={styles.switch}>
              <input
                type="checkbox"
                onChange={() => {
                  setDeclaration(!declaration);
                  setOnlyPlp(false);
                }}
                checked={declaration}
              />
              <span className={`${styles.slider} ${styles.round}`}></span>
            </label>
          </div>

          <div className={styles.switchOption}>
            <span>Imprimir apenas declarações</span>
            <label className={`${styles.switch} ${styles.switchInactive}`}>
              <input
                type="checkbox"
                onChange={() => {
                  setOne(false);
                  setDeclaration(true);
                  setOnlyDeclaration(!onlyDeclaration);
                  setOnlyPlp(false);
                  setShowPlp(false);
                }}
                checked={onlyDeclaration}
              />
              <span className={`${styles.slider} ${styles.round}`}></span>
            </label>
          </div>
          {plpTag && (
            <div className={styles.switchOption}>
              <span>Imprimir PLP</span>
              <label className={styles.switch}>
                <input
                  type="checkbox"
                  onChange={() => {
                    setShowPlp(!showPlp);
                    setOnlyDeclaration(false);
                  }}
                  checked={showPlp}
                />
                <span className={`${styles.slider} ${styles.round}`}></span>
              </label>
            </div>
          )}

          {plpTag && (
            <div className={styles.switchOption}>
              <span>Imprimir apenas PLP</span>
              <label className={`${styles.switch} ${styles.switchInactive}`}>
                <input
                  type="checkbox"
                  onChange={() => {
                    setOne(false);
                    setOnlyPlp(!onlyPlp);
                    setOnlyDeclaration(false);
                    setDeclaration(false);
                    setShowPlp(true);
                  }}
                  checked={onlyPlp}
                />
                <span className={`${styles.slider} ${styles.round}`}></span>
              </label>
            </div>
          )}
        </div>

        <div className={styles.switchOption}>
          <span>Ordenar por</span>
          <Select
            styles={selectStyle}
            value={orderPrint}
            options={orderPrintOptions}
            placeholder="Selecione"
            onChange={e => setOrderPrint(e)}
          />
        </div>
        <div ref={containerForPrintTags} className={styles.summary}>
          <h2>Resumo da impressão</h2>

          <div className={styles.summaryResume}>
            <div>
              <p>
                <strong>Coletas:</strong>
              </p>
              <span>
                {summary?.coletas?.map((a, n) =>
                  n < summary.coletas.length - 1 ? `${a}, ` : `${a}`
                )}
              </span>
            </div>

            <div>
              <p>
                <strong>Objetos:</strong>
              </p>
              {!!summary.expressos && (
                <span>{summary.expressos} Expressos</span>
              )}
              {!!summary.economicos && (
                <span>{summary.economicos} Econômico</span>
              )}
              {!!summary.pacmini && (
                <span>{summary.pacmini} Super Econômico</span>
              )}
              {!!summary.ownDelivery && (
                <span>{summary.ownDelivery} Super Expresso</span>
              )}
            </div>
          </div>
        </div>
        <button className={styles.button} onClick={handlePrinterButton}>
          Imprimir Etiquetas
        </button>
      </aside>

      {showHelperForPrint && width <= 880 && (
        <div
          className={`${styles.goToPrintContainer}`}
          onClick={goToPrintContainer}
        >
          <button type="button">
            Imprimir Etiquetas <BsArrowDownShort />
          </button>
        </div>
      )}
    </main>
  );
};

export { Tags };
