import React, { useState, useMemo, useCallback, useEffect } from "react";
import Select from "react-select";
import { useHistory, useParams } from "react-router-dom";
import Swal from "sweetalert2";

import styles from "./styles.module.scss";

import { UFsEnum } from "../../../../../enums/UFsEnum";

import { Loading } from "../../../../../components/Loading";
import Container from "../../../components/Container";
import InputSelectAsync, {
  selectStyle
} from "../../../../../components/InputSelectAsync";

import { BrComerceApiService } from "../../../../../services/BrComerceApiService";

const CreatePricePolicy = () => {
  const api = new BrComerceApiService();
  const history = useHistory();
  const params = useParams();

  const [scope, setScope] = useState("");
  const [scopeOK, setScopeOK] = useState("vazio");
  const [scopeError, setScopeError] = useState("");
  const [ufOrigin, setUfOrigin] = useState("");
  const [ufOriginOK, setUfOriginOK] = useState("vazio");
  const [ufOriginError, setUfOriginError] = useState("");

  const [ufDestination, setUfDestination] = useState("");
  const [ufDestinationOK, setUfDestinationOK] = useState("vazio");
  const [ufDestinationError, setUfDestinationError] = useState("");

  const [rateType, setRateType] = useState("");
  const [rateTypeOK, setRateTypeOK] = useState("vazio");
  const [rateTypeError, setRateTypeError] = useState("");

  const [shippingType, setShippingType] = useState("");
  const [shippingTypeOK, setShippingTypeOK] = useState("vazio");
  const [shippingTypeError, setShippingTypeError] = useState("");

  const [isCollect, setIsCollect] = useState("");
  const [isCollectOK, setIsCollectOK] = useState("vazio");
  const [isCollectError, setIsCollectError] = useState("");

  const [percentage, setPercent] = useState("");
  const [percentageOK, setPercentageOK] = useState("vazio");
  const [percentageError, setPercentageError] = useState("");

  const [type, setType] = useState("");
  const [typeOK, setTypeOK] = useState("vazio");
  const [typeError, setTypeError] = useState("");

  const [loading, setLoading] = useState(false);

  const [selectedUsersToPolicy, setSelectedUsersToPolicy] = useState(null);

  const handleScope = useCallback(e => {
    
    if (e?.value === "global") {
      setUfOrigin({ label: "", value: "vazio" });
      setUfOriginError("");
      setUfOriginOK("");
    }

    if (e?.value === "global") {
      setUfDestination({ label: "", value: "vazio" });
      setUfDestinationError("");
      setUfDestinationOK("");
    }

    if (e) {
      setScopeOK("");
      setScopeError("");
    }

    setScope(e);
  }, []);

  const handleUfOrigin = useCallback(e => {
    if (e) {
      setUfOriginOK("");
      setUfOriginError("");
    }
    setUfOrigin(e);
  }, []);

  const handleUfDestination = useCallback(e => {
    if (e) {
      setUfDestinationOK("");
      setUfDestinationError("");
    }
    setUfDestination(e);
  }, []);

  const handleShippingType = useCallback(e => {
    if (e) {
      setShippingTypeError("");
      setShippingTypeOK("");
    }

    setShippingType(e);
  }, []);

  const handleRateType = useCallback(e => {
    if (e) {
      setRateTypeError("");
      setRateTypeOK("");
    }
    setRateType(e);
  }, []);

  const handleCollect = useCallback(e => {
    if (e) {
      setIsCollectError("");
      setIsCollectOK("");
    }
    setIsCollect(e);
  }, []);

  const handlePercetage = useCallback(e => {
    e.currentTarget.maxLength = 5;
    const value = e.currentTarget.value;
    const result = value.replace(/[^0-9.]/g, "");

    if (e) {
      setPercentageError("");
      setPercentageOK("");
    }

    setPercent(result > 100 ? 100 : result);
  });

  const handleType = useCallback(e => {
    if (e) {
      setTypeError("");
      setTypeOK("");
    }
    setType(e);
  }, []);

  const getOptionsUFs = useMemo(() => {
    const optionsHandled = Object.entries(UFsEnum).map(([key, value]) => {
      return {
        value: value,
        label: value
      };
    });
    return optionsHandled;
  }, []);

  const scopeOptions = [
    {
      label: "Específico",
      value: "specific"
    },
    {
      label: "Global",
      value: "global"
    }
  ];

  const shippingTypeOptions = [
    {
      label: "Normal",
      value: "normal"
    },
    {
      label: "Reverso",
      value: "reverse"
    }
  ];

  const rateTypeOptions = [
    {
      label: "Industrial",
      value: "industrial"
    },
    {
      label: "Varejo",
      value: "retail"
    }
  ];

  const collectOptions = [
    {
      label: "Sim",
      value: true
    },
    {
      label: "Não",
      value: false
    }
  ];

  const typeOtions = [
    {
      label: "Acréscimo",
      value: "increase"
    },
    {
      label: "Desconto",
      value: "decrease"
    }
  ];

  const isNullOrUndefinedOrEmpty = value => {
    return (
      value === null ||
      value === undefined ||
      (typeof value === "string" && value.trim() === "")
    );
  };

  const handleFormSubmit = useCallback(
    async e => {
      e.preventDefault();
      const dataValidate = {
        scope,
        ufOrigin,
        ufDestination,
        rateType,
        shippingType,
        isCollect,
        percentage,
        type
      };
      const result = Object.values(dataValidate).map(val => {
        return isNullOrUndefinedOrEmpty(val);
      });

      if (result.includes(true)) {
        if (scope === "" || !scope) {
          setScopeError("Campo obrigatório");
          setScopeOK("notOk");
        }

        if (rateType === "" || !rateType) {
          setRateTypeError("Campo obrigatório");
          setRateTypeOK("notOk");
        }

        if (shippingType === "" || !shippingType) {
          setShippingTypeError("Campo obrigatório");
          setShippingTypeOK("notOk");
        }

        if (percentage === "" || !percentage) {
          setPercentageError("Campo obrigatório");
          setPercentageOK("notOk");
        }

        if (type === "" || !type) {
          setTypeError("Campo obrigatório");
          setTypeOK("notOk");
        }

        if (scope?.value !== "global" && (ufOrigin === "" || !ufOrigin)) {
          setUfOriginError("Campo obrigatório");
          setUfOriginOK("notOk");
        }

        if (
          scope?.value !== "global" &&
          (ufDestination === "" || !ufDestination)
        ) {
          setUfDestinationError("Campo obrigatório");
          setUfDestinationOK("notOk");
        }

        if (rateType?.value !== "retail" && (isCollect === "" || !isCollect)) {
          setIsCollectError("Campo obrigatório");
          setIsCollectOK("notOk");
        }
        return;
      }

      const data = {
        scope: scope?.value,
        percentage: Number(percentage),
        rateType: rateType?.value,
        shippingType: shippingType?.value,
        isCollect: isCollect?.value,
        type: type?.value,
        ufOrigin: ufOrigin?.value,
        ufDestination: ufDestination?.value
      };

      if (data.scope === "global" && data.ufOrigin === "vazio") {
        delete data.ufOrigin;
      }

      if (data.scope === "global" && data.ufDestination === "vazio") {
        delete data.ufDestination;
      }

      if (selectedUsersToPolicy) {
        data.users = selectedUsersToPolicy.map(user => user.sub);
      }

      try {
        setLoading(true);
        await api.makeHttpRequest({
          method: params.id ? "PUT" : "POST",
          url: params.id ? `/price-policy/${params.id}` : `/price-policy`,
          data
        });

        Swal.fire({
          title: "Sucesso!",
          text: params.id
            ? "Política editada com sucesso"
            : "Política cadastrada com sucesso",
          icon: "success",
          showCancelButton: false,
          confirmButtonText: "Ok",
          confirmButtonColor: "#409E6A"
        }).then(() => {
          history.push("/politica-de-preco");
        });
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Erro",
          text: params.id
            ? error.response.data.message.message
            : error.response.data.message,
          confirmButtonText: "Ok",
          confirmButtonColor: "#CA1818"
        });
      } finally {
        setLoading(false);
      }
    },
    [
      scope,
      percentage,
      rateType,
      isCollect,
      type,
      ufOrigin,
      ufDestination,
      selectedUsersToPolicy,
      shippingType
    ]
  );

  const handleParseResponse = data => {
    setScope(scopeOptions.find(score => score.value === data.scope));
    setUfOrigin(
      data.uf_origin
        ? getOptionsUFs.find(uf => uf.value === data.uf_origin)
        : { label: "UF", value: "vazio" }
    );
    setUfDestination(
      data.uf_destination
        ? getOptionsUFs.find(uf => uf.value === data.uf_destination)
        : { label: "UF", value: "vazio" }
    );
    setRateType(rateTypeOptions.find(rate => rate.value === data.rate_type));
    setIsCollect(
      collectOptions.find(collect => collect.value === data.is_collect)
    );
    setPercent(JSON.stringify(data.percentage));
    setType(typeOtions.find(type => type.value === data.type));
    setSelectedUsersToPolicy(
      data.userPolicy?.map(userPolicy => {
        return {
          label: userPolicy.user.userData.find(data => data.Name === "name").Value,
          value: userPolicy.user.id,
          sub: userPolicy.sub_user
        };
      })
    );
    setShippingType(
      shippingTypeOptions.find(
        shippingType => shippingType.value === data.shipping_type
      )
    );
  };
  useEffect(() => {
    if (params.id) {
      (async () => {
        try {
          const id = params.id;
          setLoading(true);
          const response = await api.makeHttpRequest({
            method: "GET",
            url: `/price-policy/${id}`
          });
          handleParseResponse(response);
        } catch (e) {
          setLoading(false);
          Swal.fire({
            icon: "error",
            title: "Política de Preço",
            text: "Não encontramos informações sobre essa política!"
          });
          console.error(e);
        } finally {
          setLoading(false);
        }
      })();
    }
  }, [params]);

  return (
    <>
      <Loading loading={loading} />
      <Container
        title={params.id ? "Editar política" : "Adicionar nova política"}
      >
        <div className={styles.formWrapper}>
          <form className={styles.form} onSubmit={handleFormSubmit}>
            <div className={styles.row}>
              <div className={styles.inputSelect} role={scopeOK}>
                <Select
                  onChange={e => handleScope(e)}
                  options={scopeOptions}
                  value={scope}
                  isClearable
                  placeholder="Abrangência"
                  styles={selectStyle}
                />
                {scopeOK === "notOk" && (
                  <p className={styles.error}>{scopeError}</p>
                )}
              </div>
              <div className={styles.inputSelect}>
                <Select
                  onChange={e => handleUfOrigin(e)}
                  options={getOptionsUFs}
                  value={ufOrigin}
                  isClearable
                  placeholder="UF de origem"
                  styles={selectStyle}
                  isDisabled={scope?.value === "global"}
                />
                {ufOriginOK === "notOk" && (
                  <p className={styles.error}>{ufOriginError}</p>
                )}
              </div>
              <div className={styles.inputSelect}>
                <Select
                  onChange={e => handleUfDestination(e)}
                  options={getOptionsUFs}
                  value={ufDestination}
                  isClearable
                  placeholder="UF de destino"
                  styles={selectStyle}
                  isDisabled={scope?.value === "global"}
                />
                {ufDestinationOK === "notOk" && (
                  <p className={styles.error}>{ufDestinationError}</p>
                )}
              </div>
            </div>
            <div className={styles.row}>
              <div className={styles.inputSelect}>
                <Select
                  onChange={e => handleRateType(e)}
                  options={rateTypeOptions}
                  value={rateType}
                  isClearable
                  placeholder="Tipo de cotação"
                  styles={selectStyle}
                />
                {rateTypeOK === "notOk" && (
                  <p className={styles.error}>{rateTypeError}</p>
                )}
              </div>
              <div className={styles.inputSelect}>
                <Select
                  onChange={e => handleCollect(e)}
                  options={collectOptions}
                  value={isCollect}
                  isClearable
                  placeholder="Coleta"
                  styles={selectStyle}
                />
                {isCollectOK === "notOk" && (
                  <p className={styles.error}>{isCollectError}</p>
                )}
              </div>
              <div className={styles.inputSelect}>
                <Select
                  onChange={e => handleShippingType(e)}
                  options={shippingTypeOptions}
                  value={shippingType}
                  isClearable
                  placeholder="Tipo de envio"
                  styles={selectStyle}
                />
                {shippingTypeOK === "notOk" && (
                  <p className={styles.error}>{shippingTypeError}</p>
                )}
              </div>
            </div>
            <div className={styles.row}>
              <div className={styles.inputWrapper}>
                <div className={styles.input}>
                  <input
                    type="text"
                    name="valor"
                    placeholder="Valor (%)"
                    value={percentage}
                    onChange={e => handlePercetage(e)}
                  />
                </div>
                {percentageOK === "notOk" && (
                  <p className={styles.error}>{percentageError}</p>
                )}
              </div>
              <div className={styles.inputSelect}>
                <Select
                  onChange={e => handleType(e)}
                  options={typeOtions}
                  value={type}
                  isClearable
                  placeholder="Tipo"
                  styles={selectStyle}
                />
                {typeOK === "notOk" && (
                  <p className={styles.error}>{typeError}</p>
                )}
              </div>
              <div className={styles.inputSelect}>
                <InputSelectAsync
                  search={selectedUsersToPolicy}
                  setSearch={setSelectedUsersToPolicy}
                  userParenting={selectedUsersToPolicy}
                  isMulti
                />
              </div>
            </div>
            <div className={styles.buttonsContainer}>
              <div className={styles.buttonContainerBack}>
                <button
                  type="button"
                  onClick={() => history.push("/politica-de-preco")}
                >
                  Voltar
                </button>
              </div>
              <div className={styles.buttonContainer}>
                <button type="submit">Salvar</button>
              </div>
            </div>
          </form>
        </div>
      </Container>
    </>
  );
};
export { CreatePricePolicy };
