import React, { useCallback, useEffect, useState } from "react";
import { FiTrash2 } from "react-icons/fi";
import InfiniteScroll from "react-infinite-scroll-component";
import Select from "react-select";
import Swal from "sweetalert2";

import InputSelectAsync, { selectStyle } from "../../../../components/InputSelectAsync";
import { BrComerceApiService } from '../../../../services/BrComerceApiService';
import Loading from '../../../home/components/Loading';
import ScrollDiv from "../../components/ScrollDiv";
import styles from "./styles.module.scss";
import IntegrationsKeysEnum from "../../../../enums/IntegrationsKeysEnum";
import { PiWarningCircleBold } from "react-icons/pi";


const ManageIntegrations = () => {


  const api = new BrComerceApiService();

  const [isLoading, setIsLoading] = useState(false);

  const [integrations, setIntegrations] = useState([]);

  const [integrationConfigs, setIntegrationConfigs] = useState([]);
  const [selectedIntegration, setSelectedIntegration] = useState({
    value: "",
    label: "Selecione a integração"
  });

  const [searchClient, setSearchClient] = useState(null);
  const [showConfirmDeleteIntegration, setShowConfirmDeleteIntegration] = useState(false);
  const [showConfirmDeleteSingleIntegration, setShowConfirmDeleteSingleIntegration] = useState(false);
  const [integrationId, setIntegrationId] = useState(null);
  const [integrationKey, setIntegrationKey] = useState(null);

  const handleSelectedIntegrationChange = useCallback(e => {
    const label = e?.label || "";

    setSelectedIntegration({
      value: e?.value,
      label: label.charAt(0).toUpperCase() + label.slice(1)
    })
  }, [])


  const getAvailablesIntegrations = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await api.makeHttpRequest({
        method: "GET",
        url: `/users/${searchClient?.sub}/list-integrations`
      });

      const parsedIntegrations = [];

      if (response) {
        for (const integration of response) {
          parsedIntegrations.push({
            label: integration.charAt(0).toLocaleUpperCase() + integration.slice(1),
            value: integration
          })
        }
      }

      setIntegrations(parsedIntegrations);
    } catch (err) {
      return Swal.fire({
        title: "Algo deu errado",
        text: "Não foi possível buscar as integrações desse usuário",
        icon: "error",
      })
    } finally {
      setIsLoading(false);
    }
  }, [searchClient]);

  const getIntegrationConfigs = useCallback(async () => {
    setIsLoading(true);
    try {

      const response = await api.makeHttpRequest({
        method: "GET",
        url: `/users/${searchClient?.sub}/list-integrations/${selectedIntegration?.value}`
      });

      if (response?.length) {
        setIntegrationConfigs(response);
      } else {
        resetIntegration();
        await getAvailablesIntegrations();
      }
    } catch (err) {
      return Swal.fire({
        title: "Algo deu errado",
        text: "Não foi possível buscar as integrações desse usuário",
        icon: "error",
      })
    } finally {
      setIsLoading(false);
    }
  }, [selectedIntegration]);

  const handleRemoveIntegration = useCallback(async () => {
    setIsLoading(true);
    setShowConfirmDeleteSingleIntegration(false);
    try {

      if (integrationId) {
        await api.makeHttpRequest({
          method: "DELETE",
          url: `/users/delete-integration/${integrationId}`
        });
      } else {
        return Swal.fire({
          title: "Algo deu errado",
          text: "Não foi possível remover a integração desse usuário",
          icon: "error",
        })
      }

      await getIntegrationConfigs();

      return Swal.fire({
        title: "Sucesso!",
        text: "A configuração foi deletada",
        icon: "success"
      })

    } catch (err) {
      return Swal.fire({
        title: "Algo deu errado",
        text: "Não foi possível remover a integração desse usuário",
        icon: "error",
      })
    } finally {
      setIsLoading(false);
    }
  }, [integrationId, integrationConfigs]);

  const handleWipeIntegrations = useCallback(async () => {
    setIsLoading(true);
    setShowConfirmDeleteIntegration(false);

    try {

      await api.makeHttpRequest({
        method: "DELETE",
        url: `/users/delete-integration-by-user`,
        params: {
          subUser: searchClient?.sub,
          integration: selectedIntegration?.value
        }
      });

      resetIntegration();
      await getAvailablesIntegrations();

      return Swal.fire({
        title: "Sucesso!",
        text: "As configurações foram deletadas",
        icon: "success"
      })
    } catch (err) {
      return Swal.fire({
        title: "Algo deu errado",
        text: "Não foi possível remover a integração desse usuário",
        icon: "error",
      })
    } finally {
      setIsLoading(false);
    }
  }, [searchClient, selectedIntegration]);

  const resetIntegration = useCallback(() => {
    setSelectedIntegration({
      value: "",
      label: "Selecione a integração"
    });
    setIntegrationConfigs([]);
    setIntegrationId(null);
    setIntegrationKey(null);
  }, []);

  useEffect(() => {
    (async () => {
      if (searchClient?.sub) {
        await getAvailablesIntegrations();
      }
    })()
  }, [searchClient]);

  useEffect(() => {
    (async () => {
      if (searchClient?.sub && selectedIntegration?.value) {
        getIntegrationConfigs();
      }
    })()
  }, [searchClient, selectedIntegration]);

  useEffect(() => {
    if (integrationConfigs.length && (!searchClient?.sub || !selectedIntegration?.value)) {
      resetIntegration()
    }
  }, [searchClient, selectedIntegration, integrationConfigs]);

  useEffect(() => {
    if (integrations.length) {
      setSelectedIntegration({
        value: "",
        label: "Selecione a integração"
      });
    }
  }, [searchClient]);

  return (
    <>
      <Loading isLoading={isLoading} />
      {(showConfirmDeleteIntegration || showConfirmDeleteSingleIntegration) && (
        <>
          <div className={styles.confirmDeleteBackground}></div>
          <div className={styles.confirmDeleteModal}>
            <PiWarningCircleBold color="#ff6b71" size={40} />
            <p>Deseja DELETAR {
              showConfirmDeleteIntegration
                ? "todas as configurações"
                : `a chave ${integrationKey ?? ""}`
            } da {selectedIntegration?.label} do usuário {searchClient?.label}?</p>
            <div className={styles.confirmDeleteBtnContainer}>
              <button
                className={styles.cancelDeleteBtn}
                onClick={() =>
                  showConfirmDeleteIntegration
                    ? setShowConfirmDeleteIntegration(false)
                    : setShowConfirmDeleteSingleIntegration(false)
                }
              >
                Cancelar
              </button>
              <button
                className={styles.confirmDeleteBtn}
                onClick={() =>
                  showConfirmDeleteIntegration
                    ? handleWipeIntegrations()
                    : handleRemoveIntegration()
                }
              >
                DELETAR
              </button>
            </div>
          </div>
        </>
      )}
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.title}>
            <h2>Gerenciar Integrações</h2>
          </div>
          <div className={styles.inputContainer}>
            <div className={styles.inputWrapper}>
              <div className={styles.input}>
                <InputSelectAsync
                  search={searchClient}
                  setSearch={setSearchClient}
                />
              </div>
              <div className={styles.selectInput}>
                <Select
                  onChange={e => handleSelectedIntegrationChange(e)}
                  defaultValue={selectedIntegration}
                  value={selectedIntegration}
                  options={integrations}
                  placeholder="Selecione a integração"
                  styles={selectStyle}
                />
              </div>
            </div>
            <div className={`${styles.buttonContainer}`}>
              <button
                type="submit"
                onClick={() => setShowConfirmDeleteIntegration(true)}
                disabled={
                  !searchClient?.sub ||
                  !selectedIntegration?.value
                }
              >
                Deletar integração
              </button>
            </div>
          </div>
          <ScrollDiv data={integrationConfigs}>
            <InfiniteScroll
              dataLength={integrationConfigs.length}
              next={() => { return }}
              hasMore={false}
              scrollableTarget="scrollDiv"
              className={styles.infiniteScroll}
            >
              <div className={styles.tableContainer}>
                <table className={styles.table}>
                  <thead className={styles.tableHead}>
                    <tr>
                      <th>
                        Chave
                      </th>
                      <th>
                        Valor
                      </th>
                      <th>
                        Ação
                      </th>
                    </tr>
                  </thead>
                  <tbody className={styles.tableBody}>
                    {integrationConfigs?.length > 0 && integrationConfigs.map((integration, i) => (
                      <tr key={i}>
                        <td>
                          {IntegrationsKeysEnum?.[integration?.key] ?? integration?.key}
                        </td>
                        <td style={{ width: "50%", overflowWrap: "anywhere" }}>
                          {integration?.value}
                        </td>
                        <td onClick={() => {
                          setIntegrationId(integration?.id)
                          setIntegrationKey(IntegrationsKeysEnum?.[integration?.key] ?? integration?.key)
                          setShowConfirmDeleteSingleIntegration(true)
                        }}>
                          <FiTrash2 />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </InfiniteScroll>
          </ScrollDiv>
        </div>
      </div >
    </>
  )
}

export { ManageIntegrations };

