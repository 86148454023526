import statusShipping from "../enums/ShippingStagesEnum";

export const handleStatusColor = statusId => {
  const status = {
    [statusShipping.OBJETO_CRIADO.id]: "#C1C1C1",
    [statusShipping.OBJETO_COLETADO.id]: "#F5CB2B",
    [statusShipping.OBJETO_EXPEDIDO.id]: "#F5CB2B",
    [statusShipping.OBJETO_EM_TRANSITO.id]: "#9DDFF3",
    [statusShipping.OBJETO_SAIU_PARA_ENTREGA.id]: "#FFA624",
    [statusShipping.OBJETO_NAO_FOI_ENTREGUE.id]: "#FF6B71",
    [statusShipping.OBJETO_ATRASADO.id]: "#FF6B71",
    [statusShipping.OBJETO_PRONTO_PARA_RETIRADA.id]: "#6CEC98",
    [statusShipping.ENTREGA_REALIZADA.id]: "#87C79F",
    [statusShipping.OBJETO_DEVOLVIDO.id]: "#FF6B71",
    [statusShipping.OBJETO_EXTRAVIADO.id]: "#FF6B71",
    [statusShipping.NAO_FOI_POSSIVEL_ENTREGAR_O_OBJETO.id]: "#FF6B71",
    [statusShipping.OBJETO_CANCELADO.id]: "#FF6B71",
    [statusShipping.OBJETO_EM_DEVELOCAO.id]: "#F5CB2B",
    [statusShipping.AGUARDANDO_POSTAGEM.id]: "#F5CB2B",
    [statusShipping.OBJETO_POSTADO.id]: "#5CA4B9"
  };

  return status[statusId];
};
