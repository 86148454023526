import React from "react";
import {
  Bar,
  ComposedChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  Line
} from "recharts";

import styles from "./barChart.module.scss";

const CustomTooltip = ({ month, granularity, active, payload }) => {
  if (active && payload && payload.length) {
    const data = payload[0].payload;
    const customLabel =
      granularity === "daily"
        ? `${data.label.padStart(2, "0")} - ${month}/${data.year}`
        : String(data.label) === String(data.year)
        ? data.label
        : `${data.label} - ${data.year}`;

    return (
      <div className={styles.customTooltip}>
        <h3>{customLabel}</h3>
        <p>Quantidade: {data.amount}</p>
        <p>
          {`Preço total: ${new Intl.NumberFormat("pt-BR", {
            style: "currency",
            currency: "BRL"
          }).format(data.price)}`}
        </p>
      </div>
    );
  }

  return null;
};

const BarChart = ({ data, monthSelected, granularitySelected }) => {
  return (
    <ResponsiveContainer
      className={styles.test}
      minHeight={250}
      width="100%"
      height="100%"
    >
      <ComposedChart
        data={data}
        margin={{ top: 40, right: 0, left: 0, bottom: 5 }}
      >
        <text
          x={"110px"}
          y={"12px"}
          fill="#333"
          fontFamily="Mulish"
          textAnchor="middle"
          dominantBaseline="central"
        >
          <tspan
            fontSize="18"
            color="#333"
            fontWeight={700}
            fontFamily="Mulish"
          >
            Valores por Período
          </tspan>
        </text>
        <CartesianGrid strokeDasharray="3 3" stroke="#f5f5f5" />

        <XAxis dataKey="label" padding={{ left: 10, right: 10 }} />
        <YAxis yAxisId={1} padding={{ top: 10 }} dataKey="amount" />
        <YAxis
          yAxisId={2}
          padding={{ top: 10 }}
          orientation="right"
          dataKey={data => Number(data.price)}
        />

        <Tooltip
          content={props => (
            <CustomTooltip
              month={monthSelected.label}
              granularity={granularitySelected.value}
              {...props}
            />
          )}
        />

        <Bar
          yAxisId={1}
          type="monotone"
          dataKey="amount"
          label="Quantidade de envios"
          fill="#409e6a"
        />

        <Line
          yAxisId={2}
          type="monotone"
          dataKey={data => Number(data.price)}
          stroke="#ff7300"
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
};

export { BarChart };
