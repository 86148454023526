import { Checkbox } from "@material-ui/core";
import React, { useCallback, useMemo, useState } from "react";
import { AiOutlineCheck } from "react-icons/ai";
import { BsExclamation } from "react-icons/bs";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import { Loading } from "../../../../../components/Loading";
import { BrComerceApiService } from "../../../../../services/BrComerceApiService";
import { cnpjValidation } from "../../../../../utils/CnpjValidation";
import { emailValidation } from "../../../../../utils/EmailValidation";
import { StyledFormControlLabel } from "../../../../auth/Components/Input";
import checkedIcon from "../../../../../assets/icons/icon-checked.svg";
import notCheckedIcon from "../../../../../assets/icons/icon-not-checked.svg";
import styles from "./styles.module.scss";
import { cpfValidation } from "../../../../../utils/CpfValidation";
import Swal from "sweetalert2";
import ProfileEnum from "../../../../../enums/ProfileEnum";
import InputSelectAsync, {
  selectStyle
} from "../../../../../components/InputSelectAsync";
import PlpTypeEnum from "../../../../../enums/PlpTypeEnum";
import Container from "../../../components/Container";

const CreateUserForm = () => {
  const api = new BrComerceApiService();

  const history = useHistory();

  const [nameOK, setNameOK] = useState("vazio");
  const [nameError, setNameError] = useState("");
  const [name, setName] = useState("");

  const [profileOK, setProfileOK] = useState("vazio");
  const [profileError, setProfileError] = useState("");
  const [profile, setProfile] = useState("");
  const [frete, setFrete] = useState("");

  const profileOpt = useMemo(() => {
    return [
      {
        label: ProfileEnum.USER.label,
        value: ProfileEnum.USER.value
      },
      {
        label: ProfileEnum.MANAGER.label,
        value: ProfileEnum.MANAGER.value
      },
      {
        label: ProfileEnum.ADMIN.label,
        value: ProfileEnum.ADMIN.value
      },

    ];
  }, []);

  const plpTypeOpt = useMemo(() => {
    return [
      {
        label: PlpTypeEnum.INDUSTRIAL.label,
        value: PlpTypeEnum.INDUSTRIAL.value
      },
      {
        label: PlpTypeEnum.RETAIL.label,
        value: PlpTypeEnum.RETAIL.value
      }
    ];
  }, []);

  const freteOpt = useMemo(() => {
    return [
      {
        label: "Industrial",
        value: "industrial"
      },
      {
        label: "Varejo",
        value: "varejo"
      }
    ];
  }, []);

  const [canCreateCollectSchedule, setCanCreateCollectSchedule] = useState(
    false
  );
  const [createCollectAutomatically, setCreateCollectAutomatically] = useState(false);

  const [emailOK, setEmailOK] = useState("vazio");
  const [emailError, setEmailError] = useState("");
  const [email, setEmail] = useState("");

  const [creditLimitOK, setCreditLimitOk] = useState("vazio");
  const [creditLimitError, setCreditLimitError] = useState("");
  const [creditLimit, setCreditLimit] = useState("");

  const [documentOK, setDocumentOK] = useState("vazio");
  const [documentError, setDocumentError] = useState("");
  const [document, setDocument] = useState("");

  const [isCompany, setIsCompany] = useState(false);

  const [phoneOK, setPhoneOK] = useState("vazio");
  const [phoneError, setPhoneError] = useState("");
  const [phone, setPhone] = useState("");

  const [loading, setLoading] = useState(false);

  const [searchClient, setSearchClient] = useState(null);

  const [plpTypeOK, setPlpTypeOK] = useState("vazio");
  const [plpType, setPlpType] = useState("");

  const handleInputEmail = useCallback(e => {
    let value = e.currentTarget.value;
    const emailValid = emailValidation({
      email: value,
      emptyEmail: "Campo obrigatório",
      incorrectEmail: "Email informado não é valido"
    });
    setEmailOK(emailValid.status);
    setEmailError(emailValid.message);
    setEmail(value);
  }, []);

  const handleInputName = useCallback(e => {
    let value = e.currentTarget.value;
    setName(value);
    value.length < 2 ? setNameOK("notOk") : setNameOK("ok");
    if (value.length === 0) {
      setNameError("Campo obrigatório");
    } else if (value.length === 1) {
      setNameError("Nome deve ter pelo menos 2 caracteres");
    } else {
      setNameError("");
    }
  }, []);

  const handleValidationCPF = value => {
    const validCPF = cpfValidation({
      cpf: value,
      emptyCpf: "Campo obrigatório",
      incorrectCpf: "CPF incorreto"
    });
    setDocumentOK(validCPF.status);
    setDocumentError(validCPF.message);
  };

  const handleValidationCNPJ = value => {
    const validCnpj = cnpjValidation({
      cnpj: value,
      emptyCnpj: "Campo obrigatório",
      incorrectCnpj: "CNPJ incorreto"
    });
    setDocumentOK(validCnpj.status);
    setDocumentError(validCnpj.message);
  };

  const handleInputDocument = useCallback(e => {
    let inputValue = e.currentTarget.value.replace(/\W/gi, "");

    if (inputValue.length <= 11) {
      inputValue = inputValue.replace(/\D/g, "");
      inputValue = inputValue.replace(
        /^(\d{3})(\d{3})(\d{3})(\d{2})/,
        "$1.$2.$3-$4"
      );
      e.currentTarget.inputValue = inputValue;
      handleValidationCPF(inputValue);
      setDocument(inputValue);
      setIsCompany(false);
    } else {
      e.currentTarget.maxLength = 18;

      inputValue = inputValue.replace(/\D/g, "");
      inputValue = inputValue.replace(
        /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
        "$1.$2.$3/$4-$5"
      );
      e.currentTarget.inputValue = inputValue;
      handleValidationCNPJ(inputValue);
      setDocument(inputValue);
      setIsCompany(true);
    }
  }, []);

  const handleInputPhone = useCallback(e => {
    e.currentTarget.maxLength = 15;
    let value = e.currentTarget.value;
    value = value.replace(/\D/g, "");
    if (value.length === 11) {
      value = value.replace(/^(\d{2})(\d{5})(\d{4})/, "($1) $2-$3");
    } else if (value.length === 10) {
      value = value.replace(/^(\d{2})(\d{4})(\d{4})/, "($1) $2-$3");
    }
    e.currentTarget.value = value;
    if (value.length > 0 && value.length < 14) {
      setPhoneError("Telefone incorreto");
      setPhoneOK("notOk");
    } else if (value.length === 0) {
      setPhoneError("Campo obrigatório");
      setPhoneOK("notOk");
    } else {
      setPhoneError("");
      setPhoneOK("ok");
    }
    setPhone(value);
  }, []);

  const handleInputCanCreateCollectSchedule = () => {
    setCanCreateCollectSchedule(!canCreateCollectSchedule);
  };

  const handleInputCreateCollectAutomatically = () => {
    setCreateCollectAutomatically(!createCollectAutomatically);
  };

  const replaceFormatter = (match, group1, group2, group3) => {
    if (group1) {
      return `R$ ${group1}.${group2},${group3}`;
    }

    if (group2) {
      return `R$ ${group2},${group3}`;
    }

    return `R$ ${group3}`;
  };

  const handleInputCreditLimit = useCallback(value => {
    let valueNumber = String(value.replace(/\D/g, ""));

    if (valueNumber !== "" && valueNumber.length < 3) {
      valueNumber = valueNumber.padStart(3, "0");
    }

    if (valueNumber !== "" && valueNumber.length === 4) {
      if (valueNumber.startsWith("0")) {
        const arrayNumbers = valueNumber.split("");
        arrayNumbers.splice(0, 1);

        valueNumber = arrayNumbers.join("");
      }
    }

    if (valueNumber.length === 9) {
      return;
    }

    setCreditLimit(
      valueNumber.replace(/(\d{0,3}?)(\d{0,3}?)(\d{1,2}$)/g, replaceFormatter)
    );
  }, []);

  const handleFormSubmit = useCallback(
    async e => {
      e.preventDefault();

      if (name === "" || email === "" || document === "" || phone === "") {
        if (name === "") {
          setNameError("Campo obrigatório");
          setNameOK("notOk");
        }

        if (email === "") {
          setEmailError("Campo obrigatório");
          setEmailOK("notOk");
        }

        if (document === "") {
          setDocumentError("Campo obrigatório");
          setDocumentOK("notOk");
        }

        if (phone === "") {
          setPhoneError("Campo obrigatório");
          setPhoneOK("notOk");
        }
      }
      if (
        name === "" ||
        email === "" ||
        document === "" ||
        phone === "" ||
        nameOK === "notOk" ||
        emailOK === "notOk" ||
        documentOK === "notOk" ||
        phoneOK === "notOk"
      ) {
        return;
      }

      try {
        setLoading(true);
        let data = {
          name,
          email,
          cnpj: document.replace(/\D/g, ""),
          phone: "+55" + phone.replace(/\D/g, ""),
          profile: profile.value,
          isIndustrial: frete?.value === "industrial",
          canCreateCollectSchedule,
          creditLimit: creditLimit.replace(/\D/g, "") / 100,
          isCompany,
          createCollectAutomatically,
          plpType: plpType.value
        };

        if (profile.value === ProfileEnum.MANAGER.value) {
          const subUsers = searchClient?.map((i) => { return i.sub })
          data = {
            ...data,
            subUsers
          }
        }

        await api.makeHttpRequest({
          method: "POST",
          url: `/users/admin`,
          data
        });

        Swal.fire({
          title: "Sucesso!",
          text: "Usuário cadastrado com sucesso",
          icon: "success",
          showCancelButton: false,
          confirmButtonText: "Ok",
          confirmButtonColor: "#409E6A"
        }).then(() => {
          history.push("/users");
        })

      } catch (error) {

        Swal.fire({
          icon: "error",
          title: "Erro",
          text: "Não foi possível cadastrar o usuário",
          confirmButtonText: "Ok",
          confirmButtonColor: "#CA1818"
        });

        if (error.response.data.message === "Email is not available") {
          setEmailError("Este e-mail não está disponível");
          setEmailOK("notOk");
        }

      } finally {
        setLoading(false);
      }
    },
    [name,
      email,
      document,
      phone,
      nameOK,
      emailOK,
      documentOK,
      phoneOK,
      profile.value,
      frete.value,
      canCreateCollectSchedule,
      creditLimit,
      isCompany,
      api,
      searchClient,
      history,
      createCollectAutomatically,
      plpType
    ]
  );

  return (
    <>
      <Loading loading={loading} />
      <Container title="Adicionar nova usuário">
        <form className={styles.form} onSubmit={handleFormSubmit}>
          <div className={styles.contentForm}>
            <div className={styles.row}>
              <div className={`${styles.inputContainer}`}>
                <div className={`${styles.input}`} role={emailOK}>
                  <input
                    type="text"
                    name="email"
                    placeholder="E-mail"
                    value={email}
                    onChange={handleInputEmail}
                  />
                  <AiOutlineCheck className={styles.check} role={emailOK} />
                  <BsExclamation className={styles.danger} role={emailOK} />
                </div>
                {emailError && <p className={styles.error}>{emailError}</p>}
              </div>

              <div className={styles.inputContainer}>
                <div className={styles.input} role={nameOK}>
                  <input
                    type="text"
                    name="name"
                    placeholder="Nome"
                    onChange={e => handleInputName(e)}
                    value={name}
                  />
                  <AiOutlineCheck className={styles.check} role={nameOK} />
                  <BsExclamation className={styles.danger} role={nameOK} />
                </div>
                {nameError && <p className={styles.error}>{nameError}</p>}
              </div>

              <div className={`${styles.inputContainer}`}>
                <div className={styles.input} role={documentOK}>
                  <input
                    type="text"
                    name="document"
                    placeholder="Número do Documento"
                    onChange={handleInputDocument}
                    value={document}
                  />
                  <AiOutlineCheck className={styles.check} role={documentOK} />
                  <BsExclamation className={styles.danger} role={documentOK} />
                </div>
                {documentError && (
                  <p className={styles.error}>{documentError}</p>
                )}
              </div>

              <div className={`${styles.inputContainer}`}>
                <div className={styles.input} role={phoneOK}>
                  <input
                    type="text"
                    name="phone"
                    placeholder="Telefone"
                    onChange={handleInputPhone}
                    value={phone}
                  />
                  <AiOutlineCheck className={styles.check} role={phoneOK} />
                  <BsExclamation className={styles.danger} role={phoneOK} />
                </div>
                {phoneError && <p className={styles.error}>{phoneError}</p>}
              </div>
            </div>
            <div className={styles.row}>
              <div
                className={`${styles.formSelect} ${profileOK === "notOk" &&
                  styles.formError}`}
              >
                <Select
                  onChange={e => setProfile(e)}
                  defaultValue={profile}
                  value={profile}
                  options={profileOpt}
                  placeholder="Perfil"
                  styles={selectStyle}
                />
              </div>
              <div
                className={`${styles.formSelect} ${profileOK === "notOk" &&
                  styles.formError}`}
              >
                <Select
                  onChange={e => setFrete(e)}
                  defaultValue={frete}
                  value={frete}
                  options={freteOpt}
                  placeholder="Tipo de Cotação"
                  styles={selectStyle}
                />
              </div>

              <div
                className={`${styles.formSelect} ${plpTypeOK === "notOk" &&
                  styles.formError}`}
              >
                <Select
                  onChange={e => setPlpType(e)}
                  defaultValue={plpType}
                  value={plpType}
                  options={plpTypeOpt}
                  placeholder="Tipo de PLP"
                  styles={selectStyle}
                />
              </div>

              <div className={`${styles.inputContainer} `}>
                <div className={styles.input} role={creditLimitOK}>
                  <input
                    type="text"
                    name="creditLimit"
                    placeholder="Limite de crédito"
                    onChange={e =>
                      handleInputCreditLimit(e.currentTarget.value)
                    }
                    value={creditLimit}
                  />
                  <AiOutlineCheck
                    className={styles.check}
                    role={creditLimitOK}
                  />
                  <BsExclamation
                    className={styles.danger}
                    role={creditLimitOK}
                  />
                </div>
                {creditLimitError && (
                  <p className={styles.error}>{creditLimitError}</p>
                )}
              </div>
            </div>
            <div className={styles.row}>
              {profile?.value === ProfileEnum.MANAGER.value && (
                <div className={`${styles.inputContainer}`}>
                  <InputSelectAsync
                    search={searchClient}
                    setSearch={setSearchClient}
                    isMulti
                  />
                </div>
              )}
            </div>
            <div className={styles.inputsContainer}>
              <div
                className={`${styles.inputContainer} ${styles.checkBoxSchedule}`}
              >
                <StyledFormControlLabel
                  control={
                    <Checkbox
                      name="canCreateCollectSchedule"
                      value={canCreateCollectSchedule}
                      onChange={handleInputCanCreateCollectSchedule}
                      icon={<img src={notCheckedIcon} alt="" />}
                      checkedIcon={<img src={checkedIcon} alt="" />}
                      label=""
                    />
                  }
                  label="Permitir a COTAÇÃO e AGENDAMENTO de FRETES do tipo COLETA"
                />
              </div>

              <div
                className={`${styles.inputContainer} ${styles.checkBoxSchedule}`}
              >
                <StyledFormControlLabel
                  control={
                    <Checkbox
                      name="createCollectAutomatically"
                      value={createCollectAutomatically}
                      onChange={handleInputCreateCollectAutomatically}
                      icon={<img src={notCheckedIcon} alt="" />}
                      checkedIcon={<img src={checkedIcon} alt="" />}
                      label=""
                    />
                  }
                  label="Criar pedidos e agendar coleta automaticamente"
                />
              </div>
            </div>
          </div>
          <div className={styles.buttonsContainer}>
            <div className={styles.buttonContainerBack}>
              <button type="button" onClick={() => history.push("/users")}>
                Voltar
              </button>
            </div>
            <div className={styles.buttonContainer}>
              <button type="submit">Cadastrar</button>
            </div>
          </div>
        </form>
      </Container>
    </>
  );
};

export { CreateUserForm };
