import React, { useCallback, useEffect, useMemo, useState } from "react";
import moment from "moment";
import Swal from "sweetalert2";
import styles from "./styles.module.scss";
import { IoIosPricetags } from "react-icons/io";
import { FiInfo, FiSearch } from "react-icons/fi";
import { MdClear } from "react-icons/md";
import debounce from "../../../../../utils/debounce";
import ScrollDiv from "../../../components/ScrollDiv";
import { UFsEnum } from "../../../../../enums/UFsEnum";
import InputText from "../../../../../components/InputText";
import InfiniteScroll from "react-infinite-scroll-component";
import InputSelect from "../../../../../components/InputSelect";
import DatePicker from "../../../../../components/Input/DatePicker";
import InputSelectAsync from "../../../../../components/InputSelectAsync";
import { handleSimpleSelect } from "../../../../../utils/handleSimpleSelect";
import LoadInfiniteScroll from "../../../../../components/LoadInfiniteScroll";
import { BrComerceApiService } from "../../../../../services/BrComerceApiService";
import { capitalizeFirstLetter } from "../../../../../utils/CapitalizeFirstLetter";
import { ReactComponent as SvgChecked } from "../../../../../assets/icons/checked.svg";
import { Loading } from "../../../../../components/Loading";
import Container from "../../../components/Container";
import { handleStatusColor } from "../../../../../utils/StatusColor";
import ReactModal from "react-modal";

const ValidatedObjectsAndWithoutPLP = () => {
  const api = new BrComerceApiService();

  const [uf, setUf] = useState("");
  const [search, setSearch] = useState(null);
  const [shippings, setShippings] = useState([]);
  const [nPages, setNPages] = useState(0);
  const [loading, setLoading] = useState(false);
  const [actualPage, setActualPage] = useState(0);
  const [searchClient, setSearchClient] = useState(null);
  const [periodInitialDate, setPeriodInitialDate] = useState(null);
  const [periodFinalDate, setPeriodFinalDate] = useState(null);
  const [packagesSelected, setPackagesSelected] = useState([]);
  const [isSelectedAllCartItems, setIsSelectedAllCartItems] = useState(false);
  const [ufIsEqual, setUfIsEqual] = useState(false);
  const [showPlpModal, setShowPlpModal] = useState(false);
  const [showConflictDestinyModal, setModalConflictDestiny] = useState(false);

  const [loadingInfinit, setLoadingInfinit] = useState(false);
  const [hasMore, setHasMore] = useState(false);

  const handleToggleCheckAllItems = () => {
    if (isSelectedAllCartItems) {
      setIsSelectedAllCartItems(false);
      setPackagesSelected([]);
      return;
    }

    if (shippings.length < 1000) {
      setIsSelectedAllCartItems(true);
      setPackagesSelected([...shippings]);
    } else {
      Swal.fire({
        icon: "warning",
        title: "Quantidade excedida",
        text: "A quantidade de objetos selecionados não pode ultrapassar 1000"
      });
    }
  };

  const handleFetchData = useCallback(async () => {
    try {
      if (actualPage === nPages) {
        return;
      }

      setLoadingInfinit(true);

      const response = await api.makeHttpRequest({
        method: "GET",
        url: "/shipping/is-validate/not-plp",
        params: {
          page: actualPage + 1,
          resultsPerPage: 20,
          ...(periodInitialDate && {
            periodInitialDate: periodInitialDate
          }),
          ...(periodFinalDate && {
            periodFinalDate: periodFinalDate
          }),
          ...(search && { text: search }),
          ...(uf && { state: uf?.value }),
          ...(searchClient && { client: searchClient?.label })
        }
      });
      setShippings(previousState => [...previousState, ...response.results]);
      setActualPage(Number(response.actualPage));
      setNPages(Number(response.nPages));
    } catch (e) {
      console.log(e);
      Swal.fire({
        icon: "error",
        title: "PLP",
        text: "Não foi possível carregar os envios."
      });
    } finally {
      setLoadingInfinit(false);
    }
  }, [
    actualPage,
    api,
    nPages,
    periodFinalDate,
    periodInitialDate,
    search,
    searchClient,
    uf
  ]);

  const handlePackageSelection = useCallback(
    pack => {
      const find = packagesSelected.find(p => p.id === pack.id);

      const rate = packagesSelected.find(
        p => p?.destination?.state !== pack?.destination?.state
      );

      if (packagesSelected.length > 0) {
        if (rate) {
          setModalConflictDestiny(true);
          return setPackagesSelected([...packagesSelected]);
        }
      }

      if (find) {
        const newPacks = packagesSelected.filter(p => p.id !== pack.id);
        setPackagesSelected([...newPacks]);
      } else {
        if (packagesSelected.length < 1000) {
          packagesSelected.push(pack);
          setPackagesSelected([...packagesSelected]);
        } else {
          Swal.fire({
            icon: "warning",
            title: "Quantidade excedida",
            text:
              "A quantidade de objetos selecionados não pode ultrapassar 1000"
          });
        }
      }
    },
    [packagesSelected]
  );

  const getShippings = useCallback(
    debounce(async (search, uf, searchClient, initial, final) => {
      setLoading(true);

      try {
        const response = await api.makeHttpRequest({
          method: "GET",
          url: `/shipping/is-validate/not-plp`,
          params: {
            resultsPerPage: 20,
            page: 1,
            ...(initial && {
              periodInitialDate: initial
            }),
            ...(final && {
              periodFinalDate: final
            }),
            ...(search && { text: search }),
            ...(uf && { state: uf?.value }),
            ...(searchClient && { client: searchClient?.label })
          }
        });

        setShippings(response.results);
        setActualPage(Number(response.actualPage));
        setNPages(Number(response.nPages));
        setPackagesSelected([]);

        if (response.results.length === 20) {
          setHasMore(true);
        } else {
          setHasMore(false);
        }
      } catch (e) {
        console.log(e);
        Swal.fire({
          icon: "error",
          title: "PLP",
          text: "Não foi possível carregar os envios."
        });
      } finally {
        setLoading(false);
      }
    }, 500),
    []
  );

  const handleCreatePLP = useCallback(async () => {
    setLoading(true);
    try {
      if (packagesSelected?.length > 0) {
        const ids = packagesSelected.map(item => item.id);

        await api.makeHttpRequest({
          method: "POST",
          url: "/plp",
          data: {
            ids
          }
        });

        setShowPlpModal(true);
      }
    } catch (e) {
      console.log(e);
      Swal.fire({
        icon: "error",
        title: "PLP",
        text:
          "Não foi possível solicitar a criação de PLPs, por favor tente novamente."
      });
    } finally {
      setLoading(false);
    }
  }, [api, packagesSelected]);

  const getOptionsUFs = useMemo(() => {
    const optionsHandled = Object.entries(UFsEnum).map(([key, value]) => {
      return {
        value: value,
        label: value
      };
    });
    return optionsHandled;
  }, []);

  const handleOnChangeCalendarPeriod = e => {
    if (e.dateEnd) {
      const dateStart = moment(e.dateStart, "DD/MM/YYYY").format("MM/DD/YYYY");
      const dateEnd = moment(e.dateEnd, "DD/MM/YYYY").format("MM/DD/YYYY");

      setPeriodInitialDate(dateStart);
      setPeriodFinalDate(dateEnd);
    }
  };

  const handleClearPeriodFilter = () => {
    setPeriodInitialDate("");
    setPeriodFinalDate("");
  };

  useEffect(() => {
    window.setPageTitle("Pedidos sem PLP");
  }, []);

  useEffect(() => {
    if (!packagesSelected.length) {
      setIsSelectedAllCartItems(false);
    }
  }, [packagesSelected]);

  useEffect(() => {
    getShippings(search, uf, searchClient, periodInitialDate, periodFinalDate);
  }, [periodInitialDate, periodFinalDate, search, searchClient, uf]);

  useEffect(() => {
    if (shippings?.length) {
      const response = shippings.every(
        shipping =>
          shipping.destination?.state === shippings[0].destination?.state
      );
      setUfIsEqual(response);
    }
  }, [shippings]);

  return (
    <>
      <Loading loading={loading} />
      <ReactModal
        isOpen={showPlpModal}
        onRequestClose={() => setShowPlpModal(false)}
        style={{
          overlay: {
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(51, 51, 51, 0.75)",
            zIndex: 999
          },
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            transform: "translate(-50%, -50%)",
            zIndex: 999,
            border: "none",
            backgroundColor: "transparent",
            padding: 0
          }
        }}
      >
        <div className={styles.modalPlpContainer}>
          <FiInfo color="#409E6A" size={40} />
          <h3>Criar PLP</h3>
          <span>
            A solicitação está sendo processada... Você pode acompanhar a
            criação da PLP na tela de Acompanhamento.
          </span>
          <button
            onClick={() => {
              getShippings();
              setShowPlpModal(false);
            }}
          >
            Fechar
          </button>
        </div>
      </ReactModal>
      <ReactModal
        isOpen={showConflictDestinyModal}
        onRequestClose={() => setModalConflictDestiny(false)}
        style={{
          overlay: {
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(51, 51, 51, 0.75)",
            zIndex: 999
          },
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            transform: "translate(-50%, -50%)",
            zIndex: 999,
            border: "none",
            backgroundColor: "transparent",
            padding: 0
          }
        }}
      >
        <div className={styles.modalPlpContainer}>
          <FiInfo color="#F5CB2B" size={40} />
          <h3>UFs com destino diferente</h3>

          <div>
            <span>
              Não é possível selecionar envios com UF de destino diferente.
            </span>
            <br />
            <b>{`UF selecionada: ${packagesSelected[0]?.destination?.state}`}</b>
          </div>

          <button
            onClick={() => {
              setModalConflictDestiny(false);
            }}
            style={{ backgroundColor: "#F5CB2B" }}
          >
            Fechar
          </button>
        </div>
      </ReactModal>
      <Container title="Ausentes de PLP">
        <div className={styles.filtersContainer}>
          <div className={`${styles.inputContainer}`}>
            <InputText
              id="buscar"
              placeholder="Digite o código da etiqueta"
              value={search}
              onChange={e => setSearch(e.target.value)}
              renderIcon={() =>
                !search?.length ? (
                  <FiSearch size={20} color="#409e6a" />
                ) : (
                  <MdClear
                    style={{ cursor: "pointer" }}
                    onClick={() => setSearch("")}
                  />
                )
              }
            />
          </div>
          <div className={`${styles.inputContainer}`}>
            <InputSelectAsync
              search={searchClient}
              setSearch={setSearchClient}
            />
          </div>
          <div className={`${styles.inputContainer}`}>
            <div className={styles.input}>
              <DatePicker
                id="date"
                className={`datePickerConfig`}
                change={handleOnChangeCalendarPeriod}
                placeholder="Filtrar por período"
                showClearButton={!!periodInitialDate && !!periodFinalDate}
                onClearFields={handleClearPeriodFilter}
                range
              />
            </div>
          </div>

          <div className={`${styles.inputContainer}`}>
            <InputSelect
              id="uf"
              placeholder="Selecione a UF"
              onChange={e => handleSimpleSelect(e, setUf)}
              options={getOptionsUFs}
              value={uf}
            />
          </div>
        </div>

        <div className={`${styles.cardShippingActionButtons}`}>
          <div className={styles.containerCheck2}>
            <button
              type="button"
              onClick={handleCreatePLP}
              className={`${!packagesSelected.length ? styles.disabled : ""}`}
            >
              {<IoIosPricetags />}
              <p>
                Criar
                {packagesSelected.length > 1 ? " PLPs" : " PLP"}
              </p>
            </button>
          </div>
        </div>

        <div className={styles.tableContainer}>
          <ScrollDiv data={shippings}>
            <InfiniteScroll
              dataLength={shippings.length}
              next={handleFetchData}
              hasMore={hasMore}
              loader={loadingInfinit && <LoadInfiniteScroll />}
              scrollableTarget="scrollDiv"
              className={styles.infiniteScroll}
            >
              <table className={styles.table}>
                <thead className={styles.tableHead}>
                  <tr>
                    {ufIsEqual ? (
                      <th
                        className={styles.thCheck}
                        onClick={handleToggleCheckAllItems}
                      >
                        {isSelectedAllCartItems && packagesSelected.length ? (
                          <SvgChecked />
                        ) : (
                          <div />
                        )}
                      </th>
                    ) : (
                      <th>
                        <div />
                      </th>
                    )}
                    <th>Etiqueta</th>
                    <th>Data Criação</th>
                    <th>Cliente</th>
                    <th>Destinátario</th>
                    <th>UF</th>
                    <th>Status</th>
                    {/* <th>Ações</th> */}
                  </tr>
                </thead>
                <tbody className={styles.tableBody}>
                  {shippings.map(shipping => {
                    return (
                      <tr key={shipping?.id}>
                        <td>
                          {packagesSelected?.find(
                            packSelected => shipping.id === packSelected.id
                          ) ? (
                            <div
                              className={styles.containerCheck}
                              onClick={() => handlePackageSelection(shipping)}
                            >
                              <SvgChecked />
                            </div>
                          ) : (
                            <div
                              className={styles.containerCheck}
                              onClick={() => handlePackageSelection(shipping)}
                            >
                              <div />
                            </div>
                          )}
                        </td>

                        <td className={styles.thCodigo}>
                          <a
                            href={`detalhes-objeto/${shipping?.id}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {shipping?.sro}
                          </a>
                        </td>

                        <td className={styles.thCshippingado}>
                          <p>
                            {shipping?.created_at &&
                              moment(shipping?.created_at).format("DD/MM/YYYY")}
                          </p>
                        </td>

                        <td className={styles.thClient}>
                          <p>
                            {capitalizeFirstLetter(
                              shipping?.user?.userData?.[1].Value?.trim()
                            )}
                          </p>
                        </td>

                        <td className={styles.thCshippingado}>
                          <p>
                            {capitalizeFirstLetter(
                              shipping?.destination?.name?.trim()
                            )}
                          </p>
                        </td>

                        <td className={styles.thCshippingado}>
                          <p>{shipping?.destination?.state}</p>
                        </td>

                        <td className={styles.thStatus}>
                          <span
                            style={{
                              borderRadius: "4px",
                              padding: "5px 4px",
                              backgroundColor: `${handleStatusColor(
                                shipping?.status?.id
                              )}`
                            }}
                          >
                            {shipping.status.name}
                          </span>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </InfiniteScroll>
          </ScrollDiv>
        </div>
      </Container>
    </>
  );
};

export { ValidatedObjectsAndWithoutPLP };
