import React, { useCallback, useEffect, useState } from "react";
import { AiOutlineCheck } from "react-icons/ai";
import { BsChevronRight, BsExclamation } from "react-icons/bs";
import { PiCaretRightBold } from "react-icons/pi";
import Swal from "sweetalert2";
import { BrComerceApiService } from "../../../../../services/BrComerceApiService";
import { emailValidation } from "../../../../../utils/EmailValidation";
import { useHistory } from "react-router-dom";
import styles from "./styles.module.scss";
import Loading from "../../../../home/components/Loading";
import { selectStyle } from "../../../../../components/InputSelectAsync";
import Steps from "../../../components/Steps";

const ReverseForm = ({ step, totalSteps, reverseLogistics }) => {
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [emailOK, setEmailOK] = useState("vazio");
  const [emailError, setEmailError] = useState("");

  const handleSubmit = e => {
    setLoading(true);
    e.preventDefault();

    const formCanSubmit = fieldsInFormIsAbleToSubmit();
    if (formCanSubmit === false) {
      Swal.fire({
        icon: "warning",
        title: "Validação",
        text: "É necessário que seja preenchidos todos os campos sinalizados."
      });
      setLoading(false);
      return;
    }
    window.localStorage.setItem("brcom:email-logistics-reverse", email);
    setLoading(false);
    history.push("/nova-coleta/reverse-send-form");
    return;
  };

  function handleInputEmail(email) {
    handleValidationEmail(email);
    setEmail(email);
  }

  const handleValidationEmail = useCallback(value => {
    const validEmail = emailValidation({
      email: value,
      emptyEmail: "Campo obrigatório",
      incorrectEmail: "Email incorreto"
    });

    setEmailOK(validEmail.status);
    setEmailError(validEmail.message);
  }, []);

  const fieldsInFormIsAbleToSubmit = useCallback(() => {
    let result = true;

    if (emailOK === "notOk") {
      return false;
    }

    return result;
  }, [emailOK]);

  return (
    <>
      <Loading loading={loading} />

      <main className={styles.main}>
        <div className={styles.container}>
          <div className={styles.title}>
            <h2>Dados da Logística Reversa</h2>
          </div>
          <Steps step={step} total={totalSteps} />

          <p className={styles.info}>
            Por gentileza, informar o email do remetente para que seja enviado o
            código de autorização assim que o mesmo for gerado
          </p>
          <form className={styles.form}>
            <div className={styles.formContent}>
              <div className={styles.inputsContainer}>
                <div className={styles.inputContainer}>
                  <div className={styles.input} role={emailOK}>
                    <input
                      type="text"
                      name="email"
                      placeholder="Digite o email"
                      onChange={e => handleInputEmail(e.target.value)}
                      value={email}
                    />
                    <AiOutlineCheck className={styles.check} role={emailOK} />
                    <BsExclamation className={styles.danger} role={emailOK} />
                  </div>
                  <p className={styles.error}>{emailError}</p>
                </div>
              </div>
            </div>
            <div className={styles.buttonsContainer}>
              <div className={styles.buttonContainer}>
                <button type="submit" onClick={handleSubmit}>
                  Próximo passo
                  <PiCaretRightBold />
                </button>
              </div>
            </div>
          </form>
        </div>
      </main>
    </>
  );
};

export { ReverseForm };
