const WalletHistoryTypeEnum = {
    CREDIT: {
        value: "credit",
        label: "Crédito"
    },
    DEBIT: {
        value: "debit",
        label: "Débito"
    },
    DISCHARGE: {
        value: "discharge",
        label: "Abatimento"
    },
    REFUND: {
        value: "refund",
        label: "Estorno"
    },
};

export default Object.freeze({
    ...WalletHistoryTypeEnum,
});
