import React from "react";
import { SyncLoader } from "react-spinners";
import styles from "./styles.module.scss";

export default function LoadInfiniteScroll() {
  const myCollectsPage = () =>
    document.title.includes("Minhas coletas | Brcomerce");

  return (
    <div
      className={
        myCollectsPage()
          ? styles.infiniteCollectPage
          : styles.infiniteScrollLoader
      }
    >
      <SyncLoader color="#409E6A" size={17} margin={3} />
    </div>
  );
}
