import React, { useState, useCallback, useEffect } from 'react';

import { useHistory } from "react-router-dom";


import { BrComerceApiService } from "../../../../../services/BrComerceApiService";
import store from "../../../../../store/store";
import Loading from "../../../../home/components/Loading";
import Container from "../../../components/Container";

import { AiOutlineCheck } from "react-icons/ai";
import { BsExclamation } from "react-icons/bs";

import styles from "./createRange.module.scss";
import MessageModal from '../../../../../components/MessageModal';

import { MdOutlineError } from "react-icons/md";
import { FaCheckCircle } from "react-icons/fa";

const CreateRangeSuperExpress = () => {
  const { auth } = store.getState();
  const { user } = auth;

  const api = new BrComerceApiService();
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [uf, setUf] = useState();
  const [buttonStyle, setButtonStyle] = useState({ cursor: 'not-allowed' })
  const [openSuccessModal, setOpenSuccessModal] = useState(false)
  const [openErrorModal, setOpenErrorModal] = useState(false)
  const [modalMessage, setModalMessage] = useState("")
  const [modalTitle, setModalTitle] = useState("")

  const useZipCode = (initialState = "vazio") => {
    const [zipCodeOK, setZipCodeOK] = useState(initialState);
    const [zipCodeError, setZipCodeError] = useState("");
    const [zipCode, setZipCode] = useState("");
    const [zipCodeValidated, setZipCodeValidated] = useState(false);

    const handleValidationZipCode = useCallback((value) => {
      if (value.length === 0) {
        setZipCodeOK("notOk");
        setZipCodeError("Preenchimento obrigatório");
      } else if (value.length < 9) {
        setZipCodeOK("notOk");
        setZipCodeError("Preenchimento incorreto");
      } else {
        setZipCodeOK("ok");
        setZipCodeError("");
      }
    }, []);

    const handleZipCode = useCallback(async (e, whichZipCode) => {
      e.currentTarget.maxLength = 9;
      let value = e.currentTarget.value;

      value = value.replace(/\D/g, "");
      value = value.replace(/^(\d{5})(\d{3})/, "$1-$2");

      e.currentTarget.value = value;
      setZipCode(value);

      if (value.length === 9) {
        const formatZipCode = value.replace('-', '');
        await handleCepConsult(formatZipCode, whichZipCode);
      }
    }, [handleValidationZipCode]);

    return {
      zipCodeOK,
      setZipCodeOK,
      zipCodeError,
      setZipCodeError,
      zipCode,
      setZipCode,
      handleZipCode,
      setZipCodeValidated,
      zipCodeValidated
    };
  };

  const {
    zipCodeOK: zipCodeStartOK,
    setZipCodeOK: setZipCodeStartOK,
    zipCodeError: zipCodeStartError,
    setZipCodeError: setZipCodeStartError,
    zipCode: zipCodeStart,
    handleZipCode: handleZipCodeStart,
    setZipCodeValidated: setZipCodeStartValidated,
    zipCodeValidated: zipCodeStartValidated
  } = useZipCode();

  const {
    zipCodeOK: zipCodeEndOK,
    setZipCodeOK: setZipCodeEndOK,
    zipCodeError: zipCodeEndError,
    setZipCodeError: setZipCodeEndError,
    zipCode: zipCodeEnd,
    handleZipCode: handleZipCodeEnd,
    setZipCodeValidated: setZipCodeEndValidated,
    zipCodeValidated: zipCodeEndValidated
  } = useZipCode();

  const handleZipCodeValidationMessage = (whichZipCode, errorMessage) => {
    if (errorMessage && errorMessage.length > 0) {
      if (whichZipCode === "start") {
        setZipCodeStartOK("notOk")
        setZipCodeStartError(errorMessage)
        setZipCodeStartValidated(false)
      } else if (whichZipCode === "end") {
        setZipCodeEndOK("notOk")
        setZipCodeEndError(errorMessage)
        setZipCodeEndValidated(false)
      }
    } else {
      if (whichZipCode === "start") {
        setZipCodeStartValidated(true)
        setZipCodeStartOK("ok")
        setZipCodeStartError("")
      } else if (whichZipCode === "end") {
        setZipCodeEndValidated(true)
        setZipCodeEndOK("ok")
        setZipCodeEndError("")
      }
    }
  }

  const handleCepConsult = useCallback(async (cep, whichZipCode) => {
    try {
      setLoading(true)
      const result = await api.makeHttpRequest({
        method: "POST",
        url: `addressbook/getAddress`,
        data: {
          zipCode: cep
        }
      });

      setUf(result.uf)

      // if (result.uf === "RJ") {
        handleZipCodeValidationMessage(whichZipCode, "")
      // } else {
      //   handleZipCodeValidationMessage(whichZipCode, "O CEP informado deve ser do RJ!")
      // }

    } catch (error) {
      handleZipCodeValidationMessage(whichZipCode, "CEP inválido!")

      setModalMessage("Por favor, verifique o CEP inserido.")
      setModalTitle("CEP não encontrado!")
      setOpenErrorModal(true)
    } finally {
      setLoading(false)
    }
  }, []);

  const formatZipCode = (value) => {
    return value.replace('-', '');
  }

  const fieldFormIsAbleToSubmit = value => {
    return (
      value === null ||
      value === undefined ||
      (typeof value === "string" && value.trim() === "")
    );
  };

  const validateForm = (zipCodeStart, setZipCodeStartError, setZipCodeStartOK, zipCodeEnd, setZipCodeEndError, setZipCodeEndOK, dataValidate) => {
    const result = Object.values(dataValidate).map(val => {
      return fieldFormIsAbleToSubmit(val);
    });

    let hasError = false;

    if (result.includes(true)) {
      if (zipCodeStart === "" || !zipCodeStart) {
        setZipCodeStartError("Campo obrigatório");
        setZipCodeStartOK("notOk");
        hasError = true;
      }

      if (zipCodeEnd === "" || !zipCodeEnd) {
        setZipCodeEndError("Campo obrigatório");
        setZipCodeEndOK("notOk");
        hasError = true;
      }

      return hasError
    }
  };

  const handleFormSubmit = useCallback(
    async e => {
      e.preventDefault();
      let dataValidate = {
        zipCodeStart,
        zipCodeEnd,
      };

      const formValidationHasError = validateForm(zipCodeStart, setZipCodeStartError, setZipCodeStartOK, zipCodeEnd, setZipCodeEndError, setZipCodeEndOK, dataValidate);

      if (formValidationHasError || !zipCodeEndValidated || !zipCodeStartValidated) return

      const formatZipCodeStart = formatZipCode(zipCodeStart);
      const formatZipCodeEnd = formatZipCode(zipCodeEnd);

      let data = {
        zipStart: formatZipCodeStart,
        zipEnd: formatZipCodeEnd,
      }

      try {
        setLoading(true);

        await api.makeHttpRequest({
          method: "POST",
          url: '/rates',
          data
        });

        setModalMessage("Faixa de CEP criada com sucesso")
        setModalTitle("Sucesso!")
        setOpenSuccessModal(true)
      } catch (error) {
        let text = `Ocorreu um erro ao criar faixa de CEP`
        const { response } = error
        if (response?.data?.message) {
          text = response?.data?.message
        }

        setModalMessage(text)
        setModalTitle("Erro!")
        setOpenErrorModal(true)
      } finally {
        setLoading(false);
      }
    },
    [
      zipCodeStart,
      zipCodeEnd,
      zipCodeEndValidated,
      zipCodeStartValidated,
      uf
    ]
  )

  useEffect(() => {
    if (user.profile !== "admin") {
      history.push("/");
    }
  }, []);

  useEffect(() => {
    if (zipCodeStart && zipCodeStartValidated
      && zipCodeEnd && zipCodeEndValidated) {
      setButtonStyle(undefined)
    } else {
      setButtonStyle({ cursor: 'not-allowed' })
    }
  }, [
    zipCodeStart,
    zipCodeEnd,
    zipCodeStartValidated,
    zipCodeEndValidated,
  ])

  return (
    <>
      <Loading isLoading={loading} />
      <MessageModal
        isOpen={openSuccessModal}
        setIsOpen={setOpenSuccessModal}
        icon={<FaCheckCircle style={{ fontSize: "34px", color: "#409E6A" }} />}
        title={modalTitle}
        text={modalMessage}
        buttonStyle={{ color: "#FFF", backgroundColor: "#409E6A" }}
        onButtonClick={() => {
          history.push("/super-expresso");
          setOpenSuccessModal(false)
        }}
        onClose={() => {
          history.push("/super-expresso");
          setOpenSuccessModal(false)
        }}
      />

      <MessageModal
        isOpen={openErrorModal}
        setIsOpen={setOpenErrorModal}
        icon={<MdOutlineError style={{ fontSize: "34px", color: "#CA1818" }} />}
        buttonStyle={{ color: "#FFF", backgroundColor: "#CA1818" }}
        title={modalTitle}
        text={modalMessage}
        onClose={() => setOpenErrorModal(false)}
      />

      <Container title="Criar faixa de CEP">
        <form className={styles.form} onSubmit={handleFormSubmit}>
          <section className={styles.formContent}>
            <div className={styles.row}>
              <div className={styles.inputContainer}>
                <div
                  className={styles.input}
                  role={zipCodeStartOK}
                >
                  <input
                    type="text"
                    name="zipCodeStart"
                    placeholder="CEP Inicial"
                    value={zipCodeStart}
                    onChange={e => handleZipCodeStart(e, 'start')}
                  />
                  <AiOutlineCheck className={styles.check} role={zipCodeStartOK} />
                  <BsExclamation className={styles.danger} role={zipCodeStartOK} />
                </div>
                {zipCodeStartError && <p className={styles.error}>{zipCodeStartError}</p>}
              </div>
              <div className={styles.inputContainer}>
                <div
                  className={styles.input}
                  role={zipCodeEndOK}
                >
                  <input
                    type="text"
                    name="zipCodeEnd"
                    placeholder="CEP Final"
                    value={zipCodeEnd}
                    onChange={e => handleZipCodeEnd(e, 'end')}
                  />
                  <AiOutlineCheck className={styles.check} role={zipCodeEndOK} />
                  <BsExclamation className={styles.danger} role={zipCodeEndOK} />
                </div>
                {zipCodeEndError && <p className={styles.error}>{zipCodeEndError}</p>}
              </div>
            </div>
          </section>
          <div className={styles.buttonContainer}>
            <button
              type="button"
              onClick={() => history.push("/super-expresso")}
              className={styles.buttonBack}
            >
              Voltar
            </button>
            <button type="submit" style={buttonStyle}>
              Salvar
            </button>
          </div>
        </form>
      </Container>
    </>
  )
}

export { CreateRangeSuperExpress }
