import React from "react";

import { addDays, format } from "date-fns";
import moment from "moment";
import { FiSearch, FiPrinter, FiEdit3 } from "react-icons/fi";
import { useHistory } from "react-router-dom";
import { handleStatusColor } from "../../../../../../utils/StatusColor";
import { StyledTooltip } from "../../../../components/Tooltip";


import styles from "./tableCustomerShipping.module.scss";

const TableCustomerShipping = ({
  shippings,
  trackerTable = false,
  isLogisticReverse = false
}) => {
  const history = useHistory();

  const handlePrintShippings = id => {
    window.localStorage.setItem("Brcom:Etiquetas", JSON.stringify([id]));
    window.open("/etiquetas", "_blank");
  };

  const handleDetailsShipping = id => {
    window.open(`/detalhe-coleta/${id}`);
  };

  return (
    <div className={styles.container}>
      <table className={styles.table}>
        <thead className={styles.tableHead}>
          <tr>
            <th>
              {isLogisticReverse
                ? "Código de autotização"
                : "Código da etiqueta"}
            </th>
            <th>Pedido</th>
            <th>Coleta</th>
            <th>Data Criação</th>
            <th>Previsão Entrega</th>
            <th>Data Entrega</th>
            {trackerTable ? <th>Destinatário</th> : <th>Remetente</th>}
            <th>Status</th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody className={styles.tableBody}>
          {shippings.length ? (
            shippings.map(shipping => {
              return (
                <tr key={shipping?.id}>
                  <td className={styles.thCodigo}>
                    <a
                      href={`detalhes-objeto/${shipping?.id}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {isLogisticReverse
                        ? shipping?.authorizationCode
                        : shipping?.sro}
                    </a>
                  </td>
                  <td>
                    <p>{shipping.orderNumber || shipping.name}</p>
                  </td>
                  <td>
                    <a
                      href={`detalhe-coleta/${shipping?.idCollect}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <p>{shipping?.idCollect}</p>
                    </a>
                  </td>
                  <td>
                    <p>
                      {moment(
                        moment(shipping.created_at).subtract(3, "hours")
                      ).format("DD/MM/YYYY")}
                    </p>
                  </td>
                  <td>
                    <p>
                      {shipping?.collect?.invoice?.created_at
                        ? format(
                            addDays(
                              new Date(shipping?.collect?.invoice?.created_at),
                              Number(shipping?.correios?.prazoEntrega || 0)
                            ),
                            "dd/MM/yyyy"
                          )
                        : format(
                            addDays(
                              new Date(),
                              Number(shipping?.correios?.prazoEntrega || 0)
                            ),
                            "dd/MM/yyyy"
                          )}
                    </p>
                  </td>
                  <td>
                    <p>
                      {shipping?.status?.id === 10 || shipping?.status?.id === 8
                        ? format(new Date(shipping.deliveredDate), "dd/MM/yyyy")
                        : ""}
                    </p>
                  </td>

                  <td>
                    {trackerTable ? (
                      <p>{shipping.destination?.name}</p>
                    ) : (
                      <p>{shipping.origin?.name}</p>
                    )}
                  </td>
                  <td className={styles.thStatus}>
                    <span
                      style={{
                        backgroundColor: `${handleStatusColor(
                          shipping?.status?.id
                        )}`
                      }}
                    >
                      {shipping?.status?.name}
                    </span>
                  </td>
                  <td className={styles.tooltipWrapper}>
                    {!!shipping?.sro && (
                       <StyledTooltip text="Imprimir" position="top" arrow>
                        <button
                          onClick={() => handlePrintShippings(shipping?.id)}
                        >
                          <FiPrinter size={20} />
                        </button>
                       </StyledTooltip>
                    )}
                    <button
                      onClick={() => handleDetailsShipping(shipping?.idCollect)}
                    >
                      <FiSearch size={20} />
                    </button>
                    {(shipping?.numeroPLP <= 1 ||
                      shipping?.numeroPLP === "null") && (
                        <StyledTooltip text="Editar" position="top" arrow>
                          <button
                            onClick={() =>
                              history.push(
                                `/nova-coleta/send-form?shipping=${shipping?.id}`
                              )
                            }
                          >
                            <FiEdit3 size={20} />
                          </button>

                        </StyledTooltip>
                    )}
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td
                colSpan={9}
                style={{
                  fontSize: 16,
                  fontWeight: 600,
                  textAlign: "center",
                  margin: 16
                }}
              >
                Não foram encontrados.
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export { TableCustomerShipping };
