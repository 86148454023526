import React, { useState } from "react";
import { capitalizeFirstLetter } from "../../../../../utils/CapitalizeFirstLetter";
import CardConciliation from "../CardConciliation";
import { Table, Container } from "./styles";
import { ActionsMenu } from "../../ActionsMenu";
import DeliveryTypeEnum from "../../../../../enums/DeliveryTypeEnum";
import { styles } from "@material-ui/pickers/views/Calendar/Calendar";
import ShippingStagesEnum from '../../../../../enums/ShippingStagesEnum';

const Accordion = props => {
  const [toggle, setToggle] = useState([]);

  const handleToggle = companyTitle => {
    const companyIsToggled = toggle.includes(companyTitle);

    if (companyIsToggled) {
      setToggle(prevState =>
        prevState.filter(company => company !== companyTitle)
      );
      return;
    }

    setToggle(prevState => [...prevState, companyTitle]);
  };

  return (
    <Container>
      <Table>
        <thead>
          <tr>
            <th style={{ textAlign: "start", paddingLeft: 22 }}>
              Código da Etiqueta
            </th>
            <th>Envio</th>
            <th>Cliente</th>
            <th>Diferença</th>
            <th style={{ width: "218px" }}>Status</th>
            <th>Ações</th>
          </tr>
        </thead>
        {props?.conciliation?.length > 0 &&
          props?.conciliation?.map(conciliation => {
            const dontShowActualCompany = toggle.includes(conciliation.id);
            const status = Object.entries(ShippingStagesEnum).find(st => st[1].id === conciliation?.shipping?.idShippingStatus);
            return (
              <tbody
                className={styles.tableBodyAccordion}
                key={conciliation.id}
              >
                <tr
                  className="titleHeader"
                  onClick={() => handleToggle(conciliation.id)}
                >
                  <td style={{ textAlign: "start", paddingLeft: 22 }}>
                    <span className="iconTitle">
                      {dontShowActualCompany ? "-" : "+"}
                    </span>
                    <span
                      style={{ textDecoration: "underline", fontWeight: 700 }}
                    >
                      {conciliation?.shipping?.sro}
                    </span>
                  </td>
                  <td>
                    {conciliation?.shipping?.correios?.type ===
                      DeliveryTypeEnum.OWN_DELIVERY
                      ? "Super Expressa"
                      : conciliation?.shipping?.correios?.type}
                  </td>
                  <td>
                    {capitalizeFirstLetter(
                      conciliation?.shipping?.user?.userData[1]?.Value?.trim()
                    )}
                  </td>
                  <td
                    role={
                      conciliation?.priceDiff > 0
                        ? "ok"
                        : conciliation?.priceDiff < 0
                          ? "notOk"
                          : ""
                    }
                  >
                    {conciliation?.priceDiff != 0
                      ? new Intl.NumberFormat("pt-BR", {
                        style: "currency",
                        currency: "BRL"
                      }).format(conciliation?.priceDiff * -1)
                      : "Nenhuma"}
                  </td>
                  <td>
                    <span
                      style={{
                        display: "flex",
                        justifyContent: "center"
                      }}
                      className={
                        conciliation?.shipping?.idShippingStatus === 1
                          ? "gray"
                          : conciliation?.shipping?.idShippingStatus <= 7
                            ? "going"
                            : conciliation?.shipping?.idShippingStatus <= 10
                              ? "delivered"
                              : "error"
                      }
                    >
                      {status[1]?.message}
                    </span>
                  </td>
                  <td className="tolltip">
                    <ActionsMenu
                      shipping={conciliation?.shipping}
                      setFunction={props.setAllConciliation}
                      setLoading={props.setLoading}
                      getShippingTracking={() => { }}
                      selectedStatusInFilter={props.setSearchStringCollect}
                      search={props.search}
                    />
                  </td>
                </tr>
                {dontShowActualCompany && (
                  <tr>
                    <td colSpan={7} className="rowCardConciliation">
                      <CardConciliation conciliation={conciliation} />
                    </td>
                  </tr>
                )}
              </tbody>
            );
          })}
      </Table>
    </Container>
  );
};

export default Accordion;
