export const PLPStatusEnum = Object.freeze({
    PENDING: {
        value: "pending",
        label: "Pendente"
    },
    FINISHED: {
        value: "finished",
        label: "Finalizado"
    },
    ERROR: {
        value: "error",
        label: "Erro"
    }
});

