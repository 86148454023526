import PaymentMethodEnum from "../../../../enums/PaymentMethodEnum";

export const selectStyle = {
  control: styles => ({
    ...styles,
    backgroundColor: "inherit",
    width: "100%",
    border: "none",
    boxShadow: "none",
    outline: "none",
    color: "#333",
    padding: "0",
    "&:hover": {
      borderColor: "none"
    },
    cursor: "pointer"
  }),
  container: styles => ({
    ...styles,
    width: "100%",
    outline: "none",
    border: "none",
    padding: "2px 0",
    cursor: "pointer"
  }),
  indicatorSeparator: styles => ({
    ...styles,
    display: "none",
    border: "none"
  }),
  option: (styles, { isSelected }) => ({
    ...styles,
    fontSize: "14px",
    fontFamily: "Mulish",
    fontWeight: 400,
    color: isSelected ? "#fff" : "#333",
    "&:hover": {
      backgroundColor: "#c1c1c1",
      color: "#fff",
      cursor: "pointer"
    },

    backgroundColor: isSelected ? "#409e6a" : "#fff"
  }),
  singleValue: styles => ({
    ...styles,
    color: "#333",
    fontSize: "14px"
  }),
  placeholder: styles => ({
    ...styles,
    fontSize: "14px",
    fontFamily: "Mulish",
    fontWeight: 400,
    color: "#333",
    whiteSpace: "nowrap"
  })
};

export const methodOptions = [
  { value: PaymentMethodEnum.PIX, label: "PIX" },
  { value: PaymentMethodEnum.CREDIT_CARD, label: "Cartão" },
];

export const valueOptions = [
  { value: 10, label: "R$ 10,00" },
  { value: 50, label: "R$ 50,00" },
  { value: 100, label: "R$ 100,00" },
  { value: 500, label: "R$ 500,00" },
]
