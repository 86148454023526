export const shippingImportModel = [
  {
    Nome: "Envio Exemplo (Apague ou reescreva essa linha)",
    Comprimento: 15,
    Largura: 10,
    Altura: 5,
    Diametro: 0,
    Peso: 0.5,
    Valor: 65.99,
    Seguro: "sim | nao (Escolha uma opcao)",
    TipoEnvio: "super | sedex | pac | mini (Escolha uma opcao)",
    NomeRemetente: "BRComerce",
    DocumentoRemetente: "99999999999",
    CelularRemetente: "21991829840",
    CepRemetente: "22250040",
    LogradouroRemetente: "Praia de Botafogo",
    NumeroRemetente: "501",
    ComplementoRemetente: "Sala 999",
    BairroRemetente: "Botafogo",
    CidadeRemetente: "Rio de Janeiro",
    EstadoRemetente: "RJ",
    NomeDestinatario: "BRComerce",
    DocumentoDestinatario: "99999999999",
    CelularDestinatario: "21991829840",
    CepDestinatario: "22250040",
    LogradouroDestinatario: "Praia de Botafogo",
    NumeroDestinatario: "501",
    ComplementoDestinatario: "Sala 999",
    BairroDestinatario: "Botafogo",
    CidadeDestinatario: "Rio de Janeiro",
    EstadoDestinatario: "RJ"
  }
];
