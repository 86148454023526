import React, { useCallback, useEffect, useMemo, useState } from "react";

import Swal from "sweetalert2";
import Select from "react-select";
import { useHistory } from "react-router-dom";

import { BrComerceApiService } from '../../../../../services/BrComerceApiService';
import { selectStyle } from "../../../../../components/InputSelectAsync";
import Loading from '../../../../home/components/Loading';
import Container from '../../../components/Container';

import { OriginEnum } from "../../../../../enums/OriginEnum";
import DeliveryTypeEnum from "../../../../../enums/DeliveryTypeEnum";

import { formatMoney } from "../../../../../utils/FormatMoney";

import { AiOutlineCheck } from "react-icons/ai";
import { BsExclamation } from "react-icons/bs";

import { CiCircleCheck } from "react-icons/ci";
import ReactModal from "react-modal";
import store from "../../../../../store/store";

import styles from "./createFreightTable.module.scss";

const modalStyle = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(51, 51, 51, 0.75)",
    zIndex: 999
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    zIndex: 999,
    border: "none",
    backgroundColor: "transparent",
    padding: 0
  }
};

const CreateFreightTable = () => {
  const api = new BrComerceApiService();
  const history = useHistory();

  const { auth } = store.getState();
  const { user } = auth;
  const userCanCollect = user?.canCreateCollectSchedule;

  const [isLoading, setIsLoading] = useState(false);

  const [nameOK, setNameOK] = useState("vazio");
  const [nameError, setNameError] = useState("");
  const [name, setName] = useState("");

  const [zipCodeOK, setZipCodeOK] = useState("vazio");
  const [zipCodeError, setZipCodeError] = useState("");
  const [zipCode, setZipCode] = useState("");

  const [serviceError, setServiceError] = useState("");
  const [serviceOK, setServiceOK] = useState("vazio");
  const [service, setService] = useState("");

  const [activeAdditionalTerm, setActiveAdditionalTerm] = useState(false);

  const [additionalDeadlineOK, setAdditionalDeadlineOK] = useState("");
  const [additionalDeadlineError, setAdditionalDeadlineError] = useState("");
  const [additionalDeadline, setAdditionalDeadline] = useState("");

  const [additionalPriceOK, setAdditionalPriceOK] = useState("vazio");
  const [additionalPriceError, setAdditionalPriceError] = useState("");
  const [additionalPrice, setAdditionalPrice] = useState("");

  const [additionalTypePriceError, setAdditionalTypePriceError] = useState("");
  const [additionalTypePriceOK, setAdditionalTypePriceOK] = useState("vazio");
  const [additionalTypePrice, setAdditionalTypePrice] = useState("");

  const [activePackageDimensions, setActivePackageDimensions] = useState(false);

  const [alturaOK, setAlturaOK] = useState("vazio");
  const [alturaError, setAlturaError] = useState("");
  const [altura, setAltura] = useState("");

  const [larguraOK, setLarguraOK] = useState("vazio");
  const [larguraError, setLarguraError] = useState("");
  const [largura, setLargura] = useState("");

  const [comprimentoOK, setComprimentoOK] = useState("vazio");
  const [comprimentoError, setComprimentoError] = useState("");
  const [comprimento, setComprimento] = useState("");

  const [formatTableError, setFormatTableError] = useState("");
  const [formatTableOK, setFormatTableOK] = useState("vazio");
  const [formatTable, setFormatTable] = useState("");

  const [showModal, setShowModal] = useState(false);

  const [formatTableOpt, setFormatTableOpt] = useState([])

  const getAvailablesIntegrations = async (subUser) => {
    setIsLoading(true);

    const response = await api.makeHttpRequest({
      method: "GET",
      url: `/users/${subUser}/list-integrations`
    });

    if (response) {
      const arr = [];

      // eslint-disable-next-line array-callback-return
      response.map(res => {
        const integration = res.toLowerCase();
        if (integration.includes(OriginEnum.VNDA) || integration.includes(OriginEnum.VTEX)) {
          arr.push({
            label: res.charAt(0).toUpperCase() + res.slice(1).toLowerCase(),
            value: res
          });
        }
      });

      setFormatTableOpt(arr);
      setIsLoading(false);
    }
  };

  const clearStates = () => {
    setName("");
    setNameOK("vazio");
    setNameError("");

    setZipCode("");
    setZipCodeOK("vazio");
    setZipCodeError("");

    setService("");
    setServiceOK("vazio");
    setServiceError("");

    setActiveAdditionalTerm(false);

    setAdditionalDeadline("");
    setAdditionalDeadlineOK("vazio");
    setAdditionalDeadlineError("");

    setAdditionalPrice("");
    setAdditionalPriceOK("vazio");
    setAdditionalPriceError("");

    setAdditionalTypePrice("");
    setAdditionalTypePriceOK("vazio");
    setAdditionalTypePriceError("");

    setActivePackageDimensions(false);

    setAltura("");
    setAlturaOK("vazio");
    setAlturaError("");

    setLargura("");
    setLarguraOK("vazio");
    setLarguraError("");

    setComprimento("");
    setComprimentoOK("vazio");
    setComprimentoError("");

    setFormatTable("");
    setFormatTableOK("vazio");
    setFormatTableError("");

    setShowModal(false);
  }

  const formatPriceOpt = useMemo(() => {
    return [
      {
        label: "Fixo",
        value: "fixed",
      },
      {
        label: "Porcentagem",
        value: "percent"
      }
    ];
  }, []);

  const serviceOpt = useMemo(() => {
    return [
      {
        value: DeliveryTypeEnum.PAC,
        label: "Econômico"
      },
      {
        value: DeliveryTypeEnum.SEDEX,
        label: "Expresso",
      },
      {
        value: DeliveryTypeEnum.PACMINI,
        label: "Super Econômico",
      }
    ];
  }, []);

  const handleName = useCallback(e => {
    e.currentTarget.maxLength = 30;
    const value = e.currentTarget.value;
    const valueParse = value?.replace(/[^a-zA-Z0-9 ]+/g, "");

    if (valueParse.length === 0) {
      setNameOK("notOk");
      setNameError("Preenchimento obrigatório");
    } else {
      setNameOK("ok");
      setNameError("");
    }

    setName(valueParse)
  }, []);

  const handleValidationZipCode = useCallback(
    async (value) => {
      if (value.length === 0) {
        setZipCodeOK("notOk");
        setZipCodeError("Preenchimento obrigatório");
      } else if (value.length < 9) {
        setZipCodeOK("notOk");
        setZipCodeError("Preenchimento incorreto");
      } else {
        setZipCodeOK("ok");
        setZipCodeError("");
      }
    },
    []
  );

  const handleZipCode = useCallback(
    async e => {
      e.currentTarget.maxLength = 9;
      let value = e.currentTarget.value;
      value = value.replace(/\D/g, "");
      value = value.replace(/^(\d{5})(\d{3})/, "$1-$2");

      handleValidationZipCode(value);
      e.currentTarget.value = value;
      setZipCode(value);

      setService("");

      const thereIsOwnDeliveryOption = serviceOpt?.some((s) => s.value === DeliveryTypeEnum.OWN_DELIVERY);
      if (thereIsOwnDeliveryOption) {
        serviceOpt.pop();
      }

      if (value.length === 9) {
        const cep = value.substr(0, 5) + value.substr(6, 3);
        await handleCepConsult(cep);
      }

    },
    [handleValidationZipCode]
  );

  const handleSetService = useCallback(e => {
    if (e) {
      setServiceOK("ok");
      setServiceError("");
    }

    setService(e);
  }, []);

  const handleActivePackageDimensions = useCallback(
    e => {
      setActivePackageDimensions(e)

      setAltura('')
      setAlturaError('')
      setAlturaOK('vazio')

      setComprimento(null)
      setComprimentoError('')
      setComprimentoOK('vazio')

      setLargura('')
      setLarguraError('')
      setLarguraOK('vazio')
    },
    []
  )

  const handleDeadline = useCallback(
    e => {
      let value = e.currentTarget.value;

      value = value.replace(/\D/g, "");

      if (value.length === 0) {
        if (additionalPrice) {
          setAdditionalDeadlineOK("vazio");
          setAdditionalDeadlineError("");
        } else {
          setAdditionalDeadlineOK("notOk");
          setAdditionalDeadlineError("Preenchimento obrigatório");
        }
      } else {
        setAdditionalDeadlineOK("ok");
        setAdditionalDeadlineError("");
      }

      setAdditionalDeadline(value)
    }, [additionalPrice]
  )

  const handleSetAdditionalTypePrice = useCallback(e => {
    if (e) {
      setAdditionalTypePriceOK("ok");
      setAdditionalTypePriceError("");

      setAdditionalPriceError("Campo obrigatório");
      setAdditionalPriceOK("notOk");
      setAdditionalPrice("")
    }
    setAdditionalTypePrice(e);
  }, []);

  const handleAddPrice = useCallback(
    e => {
      let value = formatMoney(e.currentTarget.value);

      if (Number(value.replace(/,/g, ".")) < 1 && additionalTypePrice.value) {
        setAdditionalPriceOK("notOk");
        setAdditionalPriceError("Preenchimento obrigatório");
      } else {
        setAdditionalPriceOK("ok");
        setAdditionalPriceError("");
      }

      setAdditionalPrice(value)
    }, [additionalTypePrice]
  )

  const handleAddPricePercent = useCallback(
    e => {
      e.currentTarget.maxLength = 5;
      const value = e.currentTarget.value;
      const result = value.replace(/[^0-9.]/g, "");

      if (e) {
        setAdditionalPriceOK("ok");
        setAdditionalPriceError("");
      }

      setAdditionalPrice(result > 100 ? 100 : result);
    }, [additionalTypePrice]
  )

  const handleActiveAdditionalTerm = useCallback(
    e => {
      setActiveAdditionalTerm(e)

      setAdditionalDeadline('')
      setAdditionalDeadlineError('')
      setAdditionalDeadlineOK('vazio')

      setAdditionalTypePrice(null)
      setAdditionalTypePriceOK('vazio')
      setAdditionalTypePriceError('')

      setAdditionalPrice('')
      setAdditionalPriceError('')
      setAdditionalPriceOK('vazio')
    },
    []
  )

  const handleValidationAltura = useCallback(
    value => {
      value.length < 1
        ? setAlturaOK("notOk")
        : setAlturaOK("ok");

      if (value.length === 0) {
        setAlturaOK("notOk")
        setAlturaError("Campo obrigatório");
      } else {
        setAlturaOK("ok");
        setAlturaError("");
      }
    },
    []
  );

  const handleAltura = useCallback(
    e => {
      e.currentTarget.maxLength = 3;
      const value = e.currentTarget.value;
      const result = value.replace(/[^0-9.]/g, "");

      setAltura(result > 100 ? 100 : result);
      handleValidationAltura(result > 100 ? 100 : result);
    },
    [handleValidationAltura]
  );

  const handleValidationLargura = useCallback(
    value => {
      value.length < 1
        ? setLarguraOK("notOk")
        : setLarguraOK("ok");

      if (value.length === 0) {
        setLarguraOK("notOk");
        setLarguraError("Campo obrigatório");
      } else {
        setLarguraOK("ok");
        setLarguraError("");
      }
    },
    []
  );

  const handleLargura = useCallback(
    e => {
      e.currentTarget.maxLength = 3;
      const value = e.currentTarget.value;
      const result = value.replace(/[^0-9.]/g, "");

      setLargura(result > 100 ? 100 : result);
      handleValidationLargura(result > 100 ? 100 : result);
    },
    [handleValidationLargura]
  );

  const handleValidationComprimento = useCallback(
    value => {
      value.length < 1
        ? setComprimentoOK("notOk")
        : setComprimentoOK("ok");

      if (value.length === 0) {
        setComprimentoOK("notOk");
        setComprimentoError("Campo obrigatório");
      } else {
        setComprimentoOK("ok");
        setComprimentoError("");
      }
    },
    []
  );

  const handleComprimento = useCallback(
    e => {
      e.currentTarget.maxLength = 3;
      const value = e.currentTarget.value;
      const result = value.replace(/[^0-9.]/g, "");

      setComprimento(result > 100 ? 100 : result);
      handleValidationComprimento(result > 100 ? 100 : result);
    },
    [handleValidationComprimento]
  );

  const handleSetFormatTable = useCallback(e => {
    if (e) {
      setFormatTableOK("ok");
      setFormatTableError("");
    }

    if (serviceError === "Serviço indisponível para integração" && e?.value === OriginEnum.VTEX) {
      setServiceError("");
      setServiceOK("ok")
    }

    setFormatTable(e);
  }, [serviceError]);

  const handleCepConsult = useCallback(async cep => {
    setIsLoading(true);
    setService("")
    try {
      let response = await api.makeHttpRequest({
        method: "POST",
        url: `addressbook/getAddress`,
        data: {
          zipCode: cep
        }
      });

      let hasSedexHojeOption = await api.makeHttpRequest({
        method: "GET",
        url: `zipcode-ranges/check-zipcode`,
        params: {
          zipcode: cep,
          type: DeliveryTypeEnum.SEDEX_HOJE
        }
      });

      if (userCanCollect) {
        if (hasSedexHojeOption) {
          if (!serviceOpt.find(r => r.value === DeliveryTypeEnum.SEDEX_HOJE)) {
            serviceOpt.push({
              value: DeliveryTypeEnum.SEDEX_HOJE,
              label: "Entrega Hoje",
            });
          }
        } else {
          if (serviceOpt.find(r => r.value === DeliveryTypeEnum.SEDEX_HOJE)) {
            const index = serviceOpt.findIndex(r => r.value === DeliveryTypeEnum.SEDEX_HOJE);
            serviceOpt.splice(index, 1);
          }
        }

        if (!serviceOpt.find(r => r.value === DeliveryTypeEnum.OWN_DELIVERY)) {
          serviceOpt.push({
            value: DeliveryTypeEnum.OWN_DELIVERY,
            label: "Super Expresso",
          })

        } else {
          if (serviceOpt.find(r => r.value === DeliveryTypeEnum.OWN_DELIVERY)) {
            const index = serviceOpt.findIndex(r => r.value === DeliveryTypeEnum.OWN_DELIVERY);
            serviceOpt.splice(index, 1);
          }
        }
      }

    } catch (error) {
      console.error(error);
      setZipCodeOK("notOk");
      setZipCodeError("Cep inválido");

      Swal.fire({
        icon: "error",
        title: "CEP não encontrado",
        text: "Por favor, verifique o CEP inserido."
      });
    }
    finally {
      setIsLoading(false);
    }
  }, [userCanCollect]);

  const fieldFormIsAbleToSubmit = value => {
    return (
      value === null ||
      value === undefined ||
      (typeof value === "string" && value.trim() === "")
    );
  };

  const handleFormSubmit = useCallback(
    async e => {
      e.preventDefault();

      let dataValidate = {
        name,
        zipCode,
        service,
        formatTable
      };

      if (activeAdditionalTerm) {
        dataValidate.additionalDeadline = additionalDeadline;

        if (additionalTypePrice) {
          dataValidate.additionalPrice = additionalPrice;
        }
      }

      if (activePackageDimensions) {
        Object.assign(dataValidate, {
          altura,
          largura,
          comprimento
        });
      }

      if (service?.value === DeliveryTypeEnum.SEDEX_HOJE && formatTable?.value !== OriginEnum.VTEX) {
        setServiceError("Serviço indisponível para integração");
        setServiceOK("notOk");

        return;
      }

      const result = Object.values(dataValidate).map(val => {
        return fieldFormIsAbleToSubmit(val);
      });

      if (result.includes(true)) {
        if (name === "" || !name) {
          setNameError("Campo obrigatório");
          setNameOK("notOk");
        }

        if (zipCode === "" || !zipCode) {
          setZipCodeError("Campo obrigatório");
          setZipCodeOK("notOk");
        }

        if (service === "" || !service) {
          setServiceError("Campo obrigatório");
          setServiceOK("notOk");
        }

        if (formatTable === "" || !formatTable) {
          setFormatTableOK("notOk");
          setFormatTableError("Campo obrigatório")
        }

        if (activeAdditionalTerm) {
          if (additionalDeadline === "" || !additionalDeadline) {
            setAdditionalDeadlineError("Campo obrigatório");
            setAdditionalDeadlineOK("notOk");
          }

          if (additionalPrice === "" || !additionalPrice) {
            setAdditionalPriceError("Campo obrigatório");
            setAdditionalPriceOK("notOk");
          }
        }

        if (activePackageDimensions) {
          if (altura === "" || !altura) {
            setAlturaError("Campo obrigatório");
            setAlturaOK("notOk");
          }

          if (largura === "" || !largura) {
            setLarguraError("Campo obrigatório");
            setLarguraOK("notOk");
          }

          if (comprimento === "" || !comprimento) {
            setComprimentoError("Campo obrigatório");
            setComprimentoOK("notOk");
          }
        }
        return
      }
      const formatZipCode = zipCode.replace('-', '');

      let data = {
        name,
        originZipCode: formatZipCode,
        service: service?.value,
        integration: formatTable?.value
      }

      if (activeAdditionalTerm) {
        data.addDeadline = additionalDeadline

        if (additionalTypePrice) {
          data.addTypePrice = additionalTypePrice?.value;
          data.addPrice = additionalPrice;
        }
      }

      if (activePackageDimensions) {
        data.defaultPackage = {
          height: Number(altura),
          width: Number(largura),
          length: Number(comprimento)
        }
      }

      try {
        setIsLoading(true);
        await api.makeHttpRequest({
          method: "POST",
          url: '/rates-sheet',
          data
        });

        setShowModal(true);
      } catch (error) {
        console.log("🚀 ~ CreateFreightTable ~ error:", error)
        Swal.fire({
          icon: "error",
          title: "Erro",
          text: 'Erro ao criar a tabela',
          confirmButtonText: "Ok",
          confirmButtonColor: "#CA1818"
        });
      } finally {
        setIsLoading(false);
      }
    },
    [
      name,
      zipCode,
      service,
      formatTable,
      activeAdditionalTerm,
      additionalDeadline,
      additionalTypePrice,
      additionalPrice,
      activePackageDimensions,
      altura,
      largura,
      comprimento
    ]
  )

  useEffect(() => {
    getAvailablesIntegrations(user.sub);
  }, [user]);

  return (
    <>
      <ReactModal
        isOpen={showModal}
        onRequestClose={() => setShowModal(false)}
        style={modalStyle}
      >
        <div className={styles.prePostModal}>
          <CiCircleCheck color="#409E6A" size={50} />
          <h3>Tabela gerada com sucesso!</h3>
          <div>
            <span>
              A tabela foi solicitada e será encaminhada para o e-mail cadastrado. Fique atento ao seu e-mail, pois sua tabela será gerada em até 3 horas.
            </span>
          </div>
          <div className={styles.buttonContainer}>
            <button
              onClick={() => {
                setShowModal(false);
                history.push("/tabelas-frete")
              }}
              style={{ backgroundColor: "#B3B3B3" }}
            >
              Fechar
            </button>
            <button
              onClick={() => clearStates()}
              style={{ backgroundColor: "#409e6a" }}
            >
              Gerar nova tabela
            </button>
          </div>
        </div>
      </ReactModal>
      <Loading isLoading={isLoading} />
      <Container title="Tabelas de Frete">
        <p className={styles.subtitle}>Preencha os campos abaixo com as informações para a tabela:</p>
        <form className={styles.form} onSubmit={handleFormSubmit}>
          <section className={styles.formContent}>
            <h4>Infomações básicas</h4>
            <div className={styles.row}>
              <div className={styles.inputContainer}>
                <div
                  className={styles.input}
                  role={nameOK}
                >
                  <input
                    type="text"
                    name="name"
                    placeholder="Identificação da tabela"
                    value={name}
                    onChange={e => handleName(e)}
                  />
                  <AiOutlineCheck className={styles.check} role={nameOK} />
                  <BsExclamation className={styles.danger} role={nameOK} />
                </div>
                {nameError && <p className={styles.error}>{nameError}</p>}
              </div>
              <div className={styles.inputContainer}>
                <div
                  className={styles.input}
                  role={zipCodeOK}
                >
                  <input
                    type="text"
                    name="zipcode"
                    placeholder="CEP de Origem"
                    value={zipCode}
                    onChange={e => handleZipCode(e)}
                  />
                  <AiOutlineCheck className={styles.check} role={zipCodeOK} />
                  <BsExclamation className={styles.danger} role={zipCodeOK} />
                </div>
                {zipCodeError && <p className={styles.error}>{zipCodeError}</p>}
              </div>

              <div
                className={`${styles.formSelect}`}
              >
                <div
                  className={styles.selectInput}
                  role={serviceOK}
                >
                  <Select
                    onChange={e => handleSetService(e)}
                    defaultValue={service}
                    value={service}
                    options={serviceOpt}
                    placeholder="Selecione o tipo de serviço"
                    styles={selectStyle}
                    required={true}
                  />
                </div>
                {serviceError && <p className={styles.error}>{serviceError}</p>}
              </div>
            </div>

            <h4>Configurações adicionais</h4>
            <div className={styles.row}>
              <div className={styles.switchOption}>
                <label className={styles.switch}>
                  <input
                    type="checkbox"
                    onChange={event =>
                      handleActiveAdditionalTerm(event.target.checked)
                    }
                    checked={activeAdditionalTerm}
                  />
                  <span
                    className={`${styles.slider} ${styles.round}`}
                  ></span>
                </label>
                Informar prazo e preço adicional
              </div>
            </div>

            {activeAdditionalTerm && (
              <div className={styles.row}>
                <div className={styles.inputContainer}>
                  <div

                    className={styles.input}
                    role={additionalDeadlineOK}
                  >
                    <input
                      type="text"
                      name="additionalDeadline"
                      placeholder="Prazo adicional"
                      value={additionalDeadline}
                      onChange={e =>
                        handleDeadline(e)
                      }
                    />
                    <AiOutlineCheck className={styles.check} role={additionalDeadlineOK} />
                    <BsExclamation className={styles.danger} role={additionalDeadlineOK} />
                  </div>
                  {additionalDeadlineError && <p className={styles.error}>{additionalDeadlineError}</p>}
                </div>
                <div
                  className={`${styles.formSelect}`}
                >
                  <div
                    className={styles.selectInput}
                    role={additionalTypePriceOK}
                  >
                    <Select
                      onChange={e => handleSetAdditionalTypePrice(e)}
                      defaultValue={additionalTypePrice}
                      value={additionalTypePrice}
                      options={formatPriceOpt}
                      placeholder="Selecione o formato do preço"
                      styles={selectStyle}
                    />
                  </div>
                  {additionalTypePriceError && <p className={styles.error}>{additionalTypePriceError}</p>}
                </div>
                {additionalTypePrice && (
                  <div className={styles.inputContainer}>
                    <div

                      className={styles.input}
                      role={additionalPriceOK}
                    >
                      <input
                        type="text"
                        name="additionalPrice"
                        placeholder="Preço adicional"
                        value={additionalPrice}
                        onChange={e => additionalTypePrice?.value === 'fixed' ? handleAddPrice(e) : handleAddPricePercent(e)}
                      />
                      <AiOutlineCheck className={styles.check} role={additionalPriceOK} />
                      <BsExclamation className={styles.danger} role={additionalPriceOK} />
                    </div>
                    {additionalPriceError && <p className={styles.error}>{additionalPriceError}</p>}
                  </div>
                )}
              </div>
            )}

            <div className={styles.row}>
              <div className={styles.switchOption}>
                <label className={styles.switch}>
                  <input
                    type="checkbox"
                    onChange={event =>
                      handleActivePackageDimensions(event.target.checked)
                    }
                    checked={activePackageDimensions}
                  />
                  <span
                    className={`${styles.slider} ${styles.round}`}
                  ></span>
                </label>
                Possuo um pacote padrão e quero informar
              </div>
            </div>

            {
              activePackageDimensions && (
                <div className={styles.row}>
                  <div className={styles.inputContainer}>
                    <div

                      className={styles.input}
                      role={alturaOK}
                    >
                      <input
                        type="text"
                        name="altura"
                        placeholder="Altura (cm)"
                        value={altura}
                        onChange={e => handleAltura(e)}
                      />
                      <AiOutlineCheck className={styles.check} role={alturaOK} />
                      <BsExclamation className={styles.danger} role={alturaOK} />
                    </div>
                    {alturaError && <p className={styles.error}>{alturaError}</p>}
                  </div>
                  <div className={styles.inputContainer}>
                    <div
                      className={styles.input}
                      role={larguraOK}
                    >
                      <input
                        type="text"
                        name="largura"
                        placeholder="Largura (cm)"
                        value={largura}
                        onChange={e => handleLargura(e)}
                      />
                      <AiOutlineCheck className={styles.check} role={larguraOK} />
                      <BsExclamation className={styles.danger} role={larguraOK} />
                    </div>
                    {larguraError && <p className={styles.error}>{larguraError}</p>}
                  </div>
                  <div className={styles.inputContainer}>
                    <div

                      className={styles.input}
                      role={comprimentoOK}
                    >
                      <input
                        type="text"
                        name="comprimento"
                        placeholder="Comprimento (cm)"
                        value={comprimento}
                        onChange={e => handleComprimento(e)}
                      />
                      <AiOutlineCheck className={styles.check} role={comprimentoOK} />
                      <BsExclamation className={styles.danger} role={comprimentoOK} />
                    </div>
                    {comprimentoError && <p className={styles.error}>{comprimentoError}</p>}
                  </div>
                </div>
              )
            }

            <h4>Formato da tabela</h4>
            <div className={styles.row}>
              <div className={`${styles.format}`}              >
                <div
                  className={styles.selectInput}
                  role={formatTableOK}
                >
                  <Select
                    onChange={e => handleSetFormatTable(e)}
                    defaultValue={formatTable}
                    value={formatTable}
                    options={formatTableOpt}
                    placeholder="Selecione o formato"
                    styles={selectStyle}
                  />
                </div>
                {formatTableError && <p className={styles.error}>{formatTableError}</p>}
              </div>
            </div>
          </section>
          <div className={`${styles.buttonContainer}`}>
            <button type="submit"
              disabled={
                nameOK !== "ok" ||
                zipCodeOK !== "ok" ||
                serviceOK !== "ok" ||
                formatTableOK !== "ok" ||
                (activeAdditionalTerm && (
                  (additionalDeadlineOK !== "ok" &&
                    (additionalTypePriceOK !== "ok" || additionalPriceOK !== "ok")) ||
                  (additionalTypePriceOK === "ok" && additionalPriceOK !== "ok")
                )) ||
                (activePackageDimensions && (alturaOK !== "ok" || larguraOK !== "ok" || comprimentoOK !== "ok"))
              }
            >
              Gerar tabela
            </button>
          </div>
        </form>
      </Container>
    </>
  )
}

export { CreateFreightTable }
