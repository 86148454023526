import React, { useState, useEffect, useCallback } from "react";

import Swal from "sweetalert2";
import Select from "react-select";
import InfiniteScroll from "react-infinite-scroll-component";
import moment from "moment";
import { FiDownload } from "react-icons/fi";

import { BrComerceApiService } from "../../../../../services/BrComerceApiService";
import store from "../../../../../store/store";
import { Loading } from "../../../../../components/Loading";
import { selectStyle } from "../../../../../components/InputSelectAsync";
import DatePicker from "../../../../../components/Input/DatePicker";
import ScrollDiv from "../../../components/ScrollDiv";
import LoadInfiniteScroll from "../../../../../components/LoadInfiniteScroll";
import { ReactComponent as SvgChecked } from "../../../../../assets/icons/checked.svg";
import debounce from "../../../../../utils/debounce";

import { OriginEnum } from "../../../../../enums/OriginEnum";

import styles from "./styles.module.scss";

const ImportOrders = () => {
  const api = new BrComerceApiService();

  const { auth } = store.getState();
  const { user } = auth;

  const [loading, setLoading] = useState(false);

  const [integrations, setIntegrations] = useState([]);
  const [integrationSelected, setIntegrationSelected] = useState();
  const [periodInitialDate, setPeriodInitialDate] = useState("");
  const [periodFinalDate, setPeriodFinalDate] = useState("");
  const [ordersSelected, setOrdersSelected] = useState([]);
  const [ordersToImport, setOrdersToImport] = useState([]);
  const [actualPage, setActualPage] = useState(0);
  const [nPages, setNPages] = useState(0);
  const [hasMore, setHasMore] = useState(false);
  const [isSelectedAllItems, setIsSelectedAllItems] = useState(false);
  const [importMarket, setImportMarket] = useState();
  const [deliveryType, setDeliveryType] = useState();

  const choiceOption = [
    {
      value: true,
      label: "Sim"
    },
    {
      value: false,
      label: "Não"
    }
  ];

  const deliveryOption = [
    {
      value: "SEDEX",
      label: "Expressa"
    },
    {
      value: "PAC",
      label: "Econômica"
    }
  ];

  const getAvailablesIntegrations = async subUser => {
    setLoading(true);

    const response = await api.makeHttpRequest({
      method: "GET",
      url: `/users/${subUser}/list-integrations`
    });
    if (response) {
      const arr = [];

      // eslint-disable-next-line array-callback-return
      response.map(res => {
        arr.push({
          label: res === OriginEnum.TRAYCORP ? "Wakecommerce" : res.charAt(0).toUpperCase() + res.slice(1).toLowerCase(),
          value: res
        });
      });

      setIntegrations(arr);
      setLoading(false);
    }
  };

  const handleGetOrdersIntegration = useCallback(
    debounce(
      async (integration, deliveryType, startDate, endDate) => {
        setLoading(true);

        const params = {
          page: 1,
          perPage: 15,
          startDate: startDate ? startDate : null,
          endDate: endDate ? endDate : null,
          date:
            startDate && endDate
              ? [
                moment(startDate).format("YYYY-MM-DD"),
                moment(endDate).format("YYYY-MM-DD")
              ].join(",")
              : null
        };

        if (deliveryType && integration === 'tiny') {
          params.source = deliveryType;
        }

        if (integration === 'vtex') {
          delete params.date
        }

        try {
          const response = await api.makeHttpRequest({
            method: "GET",
            url: `/${integration}/orders/all`,
            params
          });

          setOrdersToImport(response.results);

          if (integration?.includes("bling")) {
            setHasMore(response.continue);
          }

          if (response.results?.length < params.perPage) {
            setActualPage(0);
            setNPages(0);
            return;
          }

          setActualPage(response.actualPage ? Number(response.actualPage) : 0);
          setNPages(response.nPages ? Number(response.nPages) : 0);
        } catch (e) {
          console.log(e);
          Swal.fire({
            title: "Erro ao carregar os pedidos.",
            text: "Tente novamente dentro de instantes. Se o erro persistir, entre em contato com o suporte.",
            icon: "error"
          });
        } finally {
          setLoading(false);
        }
      },
      [
        api,
        integrationSelected?.value,
        importMarket?.value,
        deliveryType?.value
      ]
    )
  );

  const handleOnChangeCalendarPeriod = e => {
    const inicio = e.dateStart.split("/");
    const fim = e.dateEnd.split("/");

    setPeriodInitialDate(
      new Date(Number(inicio[2]), Number(inicio[1] - 1), Number(inicio[0]))
    );
    setPeriodFinalDate(
      new Date(Number(fim[2]), Number(fim[1] - 1), Number(fim[0]))
    );

    setOrdersSelected([])
  };

  const handleClearPeriodFilter = () => {
    setPeriodInitialDate("");
    setPeriodFinalDate("");
    setOrdersSelected([])
  };

  const handleImportOrder = async () => {
    try {
      setLoading(true);

      await api.makeHttpRequest({
        method: "POST",
        url: `/${integrationSelected?.value}/orders/all`,
        data: {
          orders: ordersSelected
        }
      });

      Swal.fire({
        title: "Sucesso!",
        text:
          "Os pedidos foram encaminhados para a fila de importação. Este processo pode levar algum tempo.",
        icon: "success",
        showCancelButton: false,
        confirmButtonText: "Ok"
      }).then(result => {
        if (result.value) {
          handleGetOrdersIntegration(
            integrationSelected?.value,
            deliveryType?.value,
            periodInitialDate,
            periodFinalDate
          );
        }
      });
    } catch (e) {
      Swal.fire({
        title: "Erro ao importar pedido(s)",
        text: "Tente novamente dentro de instantes. Se o erro persistir, entre em contato com o suporte.",
        icon: "error"
      });
    } finally {
      setIsSelectedAllItems(false);
      setOrdersSelected([]);
      setLoading(false);
    }
  };

  const handleToggleCheckAllItems = () => {
    if (isSelectedAllItems) {
      setIsSelectedAllItems(false);
      setOrdersSelected([]);
      return;
    }

    setIsSelectedAllItems(true);
    setOrdersSelected([...ordersToImport]);
  };

  const handleOrderSelection = useCallback(
    order => {
      if (integrationSelected?.value === OriginEnum.VTEX) {
        const find = ordersSelected.find(o => o.OrderId === order.OrderId);

        if (find) {
          const newPacks = ordersSelected.filter(p => p.OrderId !== order.OrderId);
          setOrdersSelected([...newPacks]);
        } else {
          ordersSelected.push(order);
          setOrdersSelected([...ordersSelected]);
        }
      } else {
        const find = ordersSelected.find(
          o => o.orderId === order.orderId
        );

        if (find) {
          const newPacks = ordersSelected.filter(
            p => p.orderId !== order.orderId
          );
          setOrdersSelected([...newPacks]);
        } else {
          ordersSelected.push(order);
          setOrdersSelected([...ordersSelected]);
        }
      }
    },
    [ordersSelected, integrationSelected]
  );

  const handleLoadMoreOrders = async () => {
    const integration = integrationSelected?.value;
    if (
      (integration?.includes("bling") && !hasMore) ||
      (!integration?.includes("bling") && actualPage === nPages)
    ) {
      return;
    }

    const response = await api.makeHttpRequest({
      method: "GET",
      url: `/${integration}/orders/all`,
      params: {
        perPage: 15,
        page: actualPage + 1,
        startDate: periodInitialDate ? periodInitialDate : null,
        endDate: periodFinalDate ? periodFinalDate : null
      }
    });

    setOrdersToImport(prevState => {
      return [...prevState, ...response.results];
    });

    if (integration?.includes("bling")) {
      setHasMore(response.continue);
    }
    setActualPage(Number(response.actualPage));
    setNPages(Number(response.nPages));
  };

  const handleFormatStatus = status => {
    const formatStatus = status;

    if (integrationSelected?.value === "nuvemshop") {
      const nuvemStatus = {
        paid: "Pago"
      };

      return nuvemStatus[status];
    }
    if (integrationSelected?.value === "traycorp") {
      const traycorpStatus = {
        1: "Pago",
        2: "Aguardando Pagamento",
        8: "Pedido Cancelado",
        10: "Pedido Autorizado",
        14: "Pedido Aprovado Análise"
      };

      return traycorpStatus[status];
    }

    return formatStatus;
  };

  const handleFormatDate = date => {
    if (integrationSelected?.value === "tiny") {
      return date;
    }

    return moment(date).format("DD/MM/YYYY");
  };

  useEffect(() => {
    window.setPageTitle("Integrações | Importação Manual");
  }, []);

  useEffect(() => {
    getAvailablesIntegrations(user.sub);
  }, [user]);

  useEffect(() => {
    if (integrationSelected?.value) {
      handleGetOrdersIntegration(
        integrationSelected.value,
        deliveryType?.value,
        periodInitialDate,
        periodFinalDate
      );
    }
  }, [integrationSelected?.value, deliveryType?.value, periodFinalDate]);

  useEffect(() => {
    if (!integrationSelected?.value) {
      setOrdersToImport([]);
      setOrdersSelected([]);
      handleClearPeriodFilter();
      setNPages(0);
      setActualPage(0);
      setImportMarket();
      setDeliveryType();
    } else {
      setOrdersSelected([]);
    }
  }, [integrationSelected?.value]);

  return (
    <>
      <Loading loading={loading} />
      <div className={styles.container}>
        <div className={styles.filtersWrapper}>
          <div className={styles.filterInput}>
            <Select
              options={integrations}
              value={integrationSelected}
              isClearable
              placeholder="Selecionar integração"
              styles={selectStyle}
              onChange={setIntegrationSelected}
            />
          </div>
          {integrationSelected?.value === "tiny" && (
            <>
              <div className={styles.filterInput}>
                <Select
                  options={choiceOption}
                  value={importMarket}
                  isClearable
                  placeholder="Desejo importar com marcador"
                  styles={selectStyle}
                  onChange={setImportMarket}
                />
              </div>
              {importMarket?.value && importMarket?.value === true && (
                <>
                  <div className={styles.filterInput}>
                    <Select
                      options={deliveryOption}
                      value={deliveryType}
                      isClearable
                      placeholder="Escolher para qual tipo de frete"
                      styles={selectStyle}
                      onChange={setDeliveryType}
                    />
                  </div>
                </>
              )}
            </>
          )}
          <div className={`${styles.filterInput} ${styles.filterDateInput}`}>
            <DatePicker
              className={"datePickerConfig"}
              change={handleOnChangeCalendarPeriod}
              placeholder="Filtrar por periodo"
              showClearButton={!!periodInitialDate && !!periodFinalDate}
              onClearFields={handleClearPeriodFilter}
              range
            />
          </div>

          <div className={styles.buttonImport}>
            <button
              type="button"
              onClick={handleImportOrder}
              className={`${!ordersSelected?.length ? styles.disabled : styles.buttonImport
                }`}
            >
              <FiDownload />
              <p>
                Importar
                {ordersSelected?.length > 1 ? " Pedidos" : " Pedido"}
              </p>
            </button>
          </div>
        </div>

        <div className={styles.tableContainer}>
          <ScrollDiv data={ordersToImport}>
            <InfiniteScroll
              dataLength={ordersToImport?.length}
              next={handleLoadMoreOrders}
              hasMore={
                integrationSelected?.value?.includes("bling")
                  ? hasMore
                  : nPages === 0
                    ? false
                    : !(actualPage === nPages)
              }
              loader={<LoadInfiniteScroll />}
              scrollableTarget="scrollDiv"
              className={styles.infiniteScroll}
            >
              <table className={styles.table}>
                <thead className={styles.tableHead}>
                  <tr>
                    <th
                      className={styles.thCheck}
                      onClick={handleToggleCheckAllItems}
                    >
                      {isSelectedAllItems && ordersSelected.length ? (
                        <SvgChecked />
                      ) : (
                        <div />
                      )}
                    </th>
                    <th>Número</th>
                    <th>Data Criação</th>
                    <th>Valor</th>
                    <th>Status</th>
                    <th>Situação</th>
                  </tr>
                </thead>
                <tbody className={styles.tableBody}>
                  {ordersToImport.map((order, i) => {
                    return (
                      <tr
                        key={i}
                        style={{
                          backgroundColor:
                            order?.already_created && "rgba(230, 230, 230)"
                        }}
                      >
                        {!order?.already_created ? (
                          <td>
                            {ordersSelected?.find(selected =>
                              integrationSelected?.value === OriginEnum.VTEX
                                ? order.OrderId === selected.OrderId
                                : order.orderId === selected.orderId
                            ) ? (
                              <div
                                className={styles.containerCheck}
                                onClick={() => handleOrderSelection(order)}
                              >
                                <SvgChecked />
                              </div>
                            ) : (
                              <div
                                className={styles.containerCheck}
                                onClick={() => handleOrderSelection(order)}
                              >
                                <div />
                              </div>
                            )}
                          </td>
                        ) : (
                          <td></td>
                        )}
                        <td>{`${integrationSelected?.value === OriginEnum.VTEX
                          ? order?.OrderId
                          : integrationSelected?.value === OriginEnum.NUVEMSHOP
                            ? order?.orderNumber
                            : integrationSelected?.value === OriginEnum.BLINGV3
                              ? order?.id
                              : order?.orderId
                          }`}</td>
                        <td>{handleFormatDate(order.date)}</td>
                        <td>
                          {new Intl.NumberFormat("pt-BR", {
                            style: "currency",
                            currency: "BRL"
                          }).format(order?.value)}
                        </td>
                        <td>{handleFormatStatus(order?.status)}</td>
                        <td>
                          <span
                            className={styles.tdStatus}
                            style={{
                              backgroundColor: order?.already_created
                                ? "#409E6A"
                                : "#ED7878"
                            }}
                          >
                            {order?.already_created
                              ? "Objeto Importado"
                              : "Objeto Pendente"}
                          </span>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </InfiniteScroll>
          </ScrollDiv>
        </div>
      </div>
    </>
  );
};

export { ImportOrders };
