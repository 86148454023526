import React from "react";
// import UserNotifications from "../../../app/partials/layout/UserNotifications";
import styled from "styled-components";
import { WalletDeposit } from "../../../app/pages/admin/pages/WalletDeposit";
import LanguageSelector from "../../../app/partials/layout/LanguageSelector";
import UserProfile from "../../../app/partials/layout/UserProfile";
import { BrComerceApiService } from "../../../app/services/BrComerceApiService";
import * as authDuck from "../../../app/store/ducks/auth.duck";
import store from "../../../app/store/store";

export default class Topbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      api: new BrComerceApiService(),
      showDeposit: false
    };
  }

  async componentDidMount() {
    const result = await this.state.api.makeHttpRequest({
      method: "GET",
      url: "/users/me/"
    });

    store.dispatch(authDuck.actions.fulfillUser(result));
  }

  render() {
    const {
      auth: {
        user: { walletBalance, balance }
      }
    } = store.getState();
    const walletBalanceCheck = walletBalance !== null ? walletBalance : 0.0;
    const balanceCheck = balance !== null ? balance : 0.0;

    const handleShowDeposit = () => {
      this.setState({
        showDeposit: !this.state.showDeposit
      });
    };

    const UserBalanceContainer = styled.div`
      flex-direction: row;
      align-self: center;
      font-size: 1em;
      display: flex;
      gap: 12px;
    `;

    const WalletDepositContainer = styled.div`
      position: absolute;
      height: 100vh;
      width: 100vw;
      left: 0;
      top: 0;
    `;

    const UserWalletBalance = styled.span`
      color: ${walletBalanceCheck >= 0 ? "#409e6a" : "#ca1818"};
    `;
    const UserBalance = styled.span`
      color: ${balanceCheck >= 0 ? "#409e6a" : "#ca1818"};
    `;

    const AddWalletCredit = styled.span`
      padding: 1em;
      font-size: 1em;
      cursor: pointer;
      text-decoration: underline;
      color: #409e6a;
      align-items: center;
    `;

    return (
      <div className="kt-header__topbar">
        {/* <UserNotifications
          skin="light"
          iconType=""
          icon="flaticon2-bell-alarm-symbol"
          type="primary"
          dot="false"
        /> */}

        {/* <UserBalanceContainer onClick={() => handleShowDeposit()}>
          <AddWalletCredit>Depositar saldo</AddWalletCredit>
        </UserBalanceContainer> */}
        <UserBalanceContainer>
          {balanceCheck > 0 ? (
            <>
              Seu crédito faturado:
              <UserBalance>
                {balanceCheck >= 0
                  ? `${Intl.NumberFormat("pt-BR", {
                      style: "currency",
                      currency: "BRL"
                    }).format(Number(balanceCheck))}`
                  : `-${Intl.NumberFormat("pt-BR", {
                      style: "currency",
                      currency: "BRL"
                    }).format(Number(balanceCheck * -1))}`}
              </UserBalance>
            </>
          ) : (
            <>
              Seu saldo:
              <UserWalletBalance>
                {walletBalanceCheck >= 0
                  ? `${Intl.NumberFormat("pt-BR", {
                      style: "currency",
                      currency: "BRL"
                    }).format(Number(walletBalanceCheck))}`
                  : `-${Intl.NumberFormat("pt-BR", {
                      style: "currency",
                      currency: "BRL"
                    }).format(Number(walletBalanceCheck * -1))}`}
              </UserWalletBalance>
            </>
          )}
        </UserBalanceContainer>

        <LanguageSelector iconType="" />
        <UserProfile showBadge={true} />
        {this.state.showDeposit ? (
          <WalletDepositContainer>
            <WalletDeposit handleCancel={handleShowDeposit} />
          </WalletDepositContainer>
        ) : null}
      </div>
    );
  }
}
