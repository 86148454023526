export const PaymentMethodEnum = {
  CARTAO: 'cartao',
  CREDIT_CARD: 'credit_card',
  BILLING: 'billing',
  WALLET: 'wallet',
  PIX: 'pix',
  BOLETO: 'boleto'
}

export default Object.freeze({
  ...PaymentMethodEnum
});
