import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";

import { IoIosArrowDown } from "react-icons/io";
import styles from "./styles.module.scss";

const CardCollectDetails = ({ shipping, collectStages }) => {
  const [toggleHeader, setToggleHeader] = useState(false);
  const history = useHistory();

  return (
    <div className={styles.collectDetails}>
      <div className={styles.header}>
        <h3 onClick={() => history.push(`/detalhes-objeto/${shipping.id}`)}>
          {shipping?.sro || shipping?.authorizationCode}{" "}
        </h3>
        <IoIosArrowDown
          className={`${toggleHeader ? styles.active : ""}`}
          onClick={() => setToggleHeader(prev => !prev)}
        />
      </div>
      {toggleHeader && (
        <Row className={styles.collectContent}>
          <Col xs={6}>
            <strong>Origem:</strong>
            <span>{` ${shipping?.origin.zip.substring(
              0,
              5
            )}-${shipping?.origin.zip.substring(5, 8)}`}</span>
          </Col>
          <Col xs={6}>
            <strong>Destino:</strong>
            <span>{` ${shipping?.destination.zip.substring(
              0,
              5
            )}-${shipping?.destination.zip.substring(5, 8)}`}</span>
          </Col>
          <Col xs={6}>
            <strong>Valor Frete:</strong>
            <span>
              {new Intl.NumberFormat("pt-BR", {
                style: "currency",
                currency: "BRL"
              }).format(shipping?.price)}
            </span>
          </Col>
          <Col xs={6}>
            <strong>Valor Seguro:</strong>
            <span>
              {new Intl.NumberFormat("pt-BR", {
                style: "currency",
                currency: "BRL"
              }).format(shipping?.insuranceValue)}
            </span>
          </Col>
          <Col xs={6}>
            <strong>Dimensões:</strong>
            <span>{`${shipping?.height}x${shipping?.width}x${shipping?.length}cm`}</span>
          </Col>
          <Col xs={6}>
            <strong>Peso:</strong>
            <span>{shipping?.weight}(kg)</span>
          </Col>
          <Col xs={12}>
            <strong>Status:</strong>
            <span
              className={`${styles.statusCollect} ${
                shipping?.idShippingStatus === 1
                  ? styles.gray
                  : shipping?.idShippingStatus <= 7
                  ? styles.going
                  : shipping?.idShippingStatus <= 10
                  ? styles.delivered
                  : styles.error
              }`}
            >
              {Object.entries(collectStages).map(([key, value]) => {
                if (Number(shipping?.idShippingStatus) === value) {
                  const stageItemsSplitted = key.split(".");
                  stageItemsSplitted.pop();

                  return collectStages[
                    `${stageItemsSplitted.join(".")}.message`
                  ];
                }
              })}
            </span>
          </Col>
        </Row>
      )}
    </div>
  );
};

export { CardCollectDetails };
