import React, { useCallback, useEffect, useState } from "react";

import { Link, useHistory } from "react-router-dom";
import Select from "react-select";
import Swal from "sweetalert2";

import { useDidMountEffect } from "../../../../../hooks/useDidMountEffect";

import { BrComerceApiService } from "../../../../../services/BrComerceApiService";
import debounce from "../../../../../utils/debounce";
import InputText from "../../../../../components/InputText";
import Loading from "../../../../home/components/Loading";
import Container from "../../../components/Container";
import { StyledTooltip } from "../../../components/Tooltip";
import store from "../../../../../store/store";

import styles from "./userListing.module.scss";
import { MdAdd } from "react-icons/md";
import { FaCirclePlus } from "react-icons/fa6";
import {
  FiEdit3,
  FiTrash2,
  FiChevronLeft,
  FiChevronRight
} from "react-icons/fi";

const selectStyle = {
  control: styles => ({
    ...styles,
    backgroundColor: "inherit",
    width: "100%",
    border: "none",
    boxShadow: "none",
    outline: "none",
    color: "#807E80",
    padding: "0",
    "&:hover": {
      borderColor: "none"
    },
    maxHeight: "48px",
    cursor: "pointer"
  }),
  container: styles => ({
    ...styles,
    minWidth: "56px",
    outline: "none",
    borderBottom: "1px solid #4D4D4D",
    padding: "0",
    cursor: "pointer"
  }),
  indicatorSeparator: styles => ({
    ...styles,
    display: "none",
    border: "none"
  }),
  option: (styles, { isSelected }) => ({
    ...styles,
    fontSize: "14px",
    fontFamily: "Mulish",
    fontWeight: 400,
    color: isSelected ? "#fff" : "#807E80",
    "&:hover": {
      backgroundColor: "#c1c1c1",
      color: "#color: #333333;",
      cursor: "pointer"
    },

    backgroundColor: isSelected ? "#409e6a" : "#fff"
  }),
  valueContainer: styles => ({
    ...styles,
    padding: "0"
  }),
  dropdownIndicator: styles => ({
    ...styles,
    padding: "0",
    color: "#4D4D4D"
  })
};

const UserListing = () => {
  const { auth } = store.getState();
  const { user } = auth;

  const api = new BrComerceApiService();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const [actualPage, setActualPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [resultsPages, setResultsPages] = useState({ label: "10", value: 10 });

  const [users, setUsers] = useState([]);
  const [searchedName, setSearchedName] = useState("");

  const loadUsers = useCallback(
    debounce(async params => {
      try {
        setIsLoading(true);
        const response = await api.makeHttpRequest({
          url: "/users",
          params: {
            page: params?.page ?? 1,
            resultsPerPage: params?.resultsPages ?? 10,
            ...(params?.text && { text: params.text })
          }
        });

        setActualPage(response.actualPage);
        setTotalPages(response.nPages);
        setUsers(response.results);
      } catch (e) {
        console.log("🚀 ~ file: index.jsx ~ line 58 ~ UserListing ~ e", e);
        Swal.fire({
          text: "Erro ao carregar lista de usuários",
          title: "Usuários",
          icon: "error"
        });
      } finally {
        setIsLoading(false);
      }
    }, 500),
    [users]
  );

  useDidMountEffect(() => {
    loadUsers({
      page: actualPage,
      text: searchedName,
      resultsPages: resultsPages.value
    });
  }, [actualPage, resultsPages, searchedName]);

  useEffect(() => {
    loadUsers();
  }, []);

  useEffect(() => {
    window.setPageTitle("Usuários");
    if (user.profile !== "admin") {
      history.push("/");
    }
  }, []);

  const handleOnConfirmDelete = async id => {
    setIsLoading(true);
    await api
      .makeHttpRequest({
        method: "DELETE",
        url: `/users/${id}`
      })
      .then(response => {
        Swal.fire({
          title: "Sucesso!",
          text: "O usuário foi deletado com sucesso.",
          icon: "success",
          showCancelButton: false,
          confirmButtonText: "Ok"
        });
        history.push("/users");
      })
      .catch(e => {
        Swal.fire({
          icon: "error",
          title: "Ocorreu um erro!",
          text: "Não foi possível fazer a deleção do usuário."
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleDeleteUser = async id => {
    Swal.fire({
      title: "Você tem certeza?",
      html:
        "Deseja prosseguir com a deleção do usuário?<br>Não será possível desfazer essa ação",
      icon: "warning",
      cancelButtonText: "Cancelar",
      showCancelButton: true,
      confirmButtonText: "Sim, fazer a deleção!"
    }).then(response => {
      if (response?.value) {
        handleOnConfirmDelete(id);
      }
    });
  };

  const onPage = newPage => {
    setActualPage(newPage - 1);
  };

  const onNext = () => {
    setIsLoading(true);
    onPage(actualPage + 1);
    setActualPage(actualPage + 1);
  };

  const onPrevious = () => {
    setIsLoading(true);
    onPage(actualPage - 1);
    setActualPage(actualPage - 1);
  };

  const perPageOptions = [
    {
      label: "10",
      value: 10
    },
    {
      label: "15",
      value: 15
    },
    {
      label: "20",
      value: 20
    },
    {
      label: "50",
      value: 50
    },
    {
      label: "100",
      value: 100
    }
  ];

  return (
    <>
      <Loading isLoading={isLoading} />
      <Container title="Usuários">
        <div className={styles.contentBody}>
          <div className={styles.filters}>
            <div className={styles.inputSearch}>
              <InputText
                id="Buscar"
                value={searchedName}
                onChange={e => setSearchedName(e.target.value)}
                renderIcon={null}
                placeholder="Buscar por nome, email ou documento"
              />
            </div>

            <div className={styles.containerBtnAddUser}>
              <Link to="/users/create">
                <button type="button" className={styles.btnAddUser}>
                  <FaCirclePlus fill="#fff" color="#fff" />
                  <span>Adicionar novo usuário</span>
                </button>
              </Link>
            </div>
          </div>
          <div className={styles.contentTable}>
            <table className={styles.tableContainer}>
              <thead>
                <tr>
                  <th width="25%">Nome</th>
                  <th>Email</th>
                  <th width="10%">Documento</th>
                  <th width="10%">Telefone</th>
                  <th width="10%">Limite de Crédito</th>
                  <th width="10%">Saldo Faturado</th>
                  <th width="10%">Saldo Carteira</th>
                  <th width="5%">Ações</th>
                </tr>
              </thead>
              <tbody>
                {!!users.length &&
                  users.map(user => {
                    const document = user?.userData?.find(
                      attr => attr.Name === "custom:cnpj"
                    ).Value;
                    const documentFormated =
                      document?.length === 14
                        ? document?.replace(
                          /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
                          "$1.$2.$3/$4-$5"
                        )
                        : document?.replace(
                          /^(\d{3})(\d{3})(\d{3})(\d{2})/,
                          "$1.$2.$3-$4"
                        );

                    return (
                      <tr key={user.id}>
                        <td>{user.userData[1].Value}</td>
                        <td>{user.email}</td>
                        <td>{documentFormated}</td>
                        <td>
                          {user.userData[2].Value.substring(3, 100).replace(
                            /^(\d{2})(\d{5})(\d{4})/,
                            "($1) $2-$3"
                          )}
                        </td>
                        <td>
                          {user.creditLimit &&
                            new Intl.NumberFormat("pt-BR", {
                              style: "currency",
                              currency: "BRL"
                            }).format(user.creditLimit)}
                        </td>
                        <td>
                          {user.balance &&
                            new Intl.NumberFormat("pt-BR", {
                              style: "currency",
                              currency: "BRL"
                            }).format(user.balance)}
                        </td>
                        <td>
                          {new Intl.NumberFormat("pt-BR", {
                            style: "currency",
                            currency: "BRL"
                          }).format(user?.walletBalance ?? 0)}
                        </td>
                        <td className={styles.tooltipWrapper}>
                          <StyledTooltip text="Editar" position="top" arrow>
                            <div>
                              <FiEdit3
                                onClick={() =>
                                  history.push(`/users/${user.id}/update`)
                                }
                                style={{
                                  cursor: "pointer",
                                  marginRight: "5px"
                                }}
                                size={20}
                                color="#409E6A"
                              />
                            </div>
                          </StyledTooltip>
                          <StyledTooltip text="Deletar" position="top" arrow>
                            <div>
                              <FiTrash2
                                onClick={() => handleDeleteUser(user.id)}
                                style={{
                                  cursor: "pointer"
                                }}
                                size={20}
                                color="#409E6A"
                              />
                            </div>
                          </StyledTooltip>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
          <div className={styles.paginationContainer}>
            <div className={styles.perPageContent}>
              <span>Itens por página:</span>
              <Select
                styles={selectStyle}
                value={resultsPages}
                options={perPageOptions}
                onChange={e => setResultsPages(e)}
                menuPlacement="top"
              />
            </div>
            <h6>
              {actualPage} - {resultsPages.value} de {totalPages}
            </h6>
            <div className={styles.controlPage}>
              <FiChevronLeft
                size={25}
                onClick={actualPage > 1 ? onPrevious : ""}
                color={actualPage > 1 ? "#409E6A" : "#2D724C"}
                className="arrow"
              />

              <FiChevronRight
                size={25}
                onClick={actualPage < totalPages ? onNext : ""}
                color={actualPage < totalPages ? "#409E6A" : "#2D724C"}
                className="arrow"
              />
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export { UserListing };
