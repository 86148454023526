import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import Select from "react-select";
import DatePicker from "../../../../components/Input/DatePicker";
import InputSelectAsync from "../../../../components/InputSelectAsync";
import LoadInfiniteScroll from "../../../../components/LoadInfiniteScroll";
import { Loading } from "../../../../components/Loading";
import ProfileEnum from "../../../../enums/ProfileEnum";
import WalletHistoryTypeEnum from "../../../../enums/WalletHistoryTypeEnum";
import { BrComerceApiService } from "../../../../services/BrComerceApiService";
import store from "../../../../store/store";
import debounce from "../../../../utils/debounce";
import ScrollDiv from "../../../admin/components/ScrollDiv";
import { options } from "./TypesOptions";
import styles from "./walletHistory.module.scss";
import { selectStyle } from "../../../../components/InputSelectAsync";
import { StyledTooltip } from "../../components/Tooltip";
import { FiSearch } from "react-icons/fi";

const WalletHistory = () => {
  const api = new BrComerceApiService();

  const { auth } = store.getState();
  const { user } = auth;

  const [walletHistories, setWalletHistories] = useState([]);
  const [actualPage, setActualPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loadingInfinit, setLoadingInfinit] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [periodInitialDate, setPeriodInitialDate] = useState(null);
  const [periodFinalDate, setPeriodFinalDate] = useState(null);
  const [searchClient, setSearchClient] = useState(null);
  const [type, setType] = useState({
    label: "Selecione",
    value: ""
  });

  const isAdmin = user?.profile === ProfileEnum.ADMIN.value;

  const handleType = e => {
    setType(e);
  };

  const hanldeOpenConciliation = sro => {
    window.open(`/conciliacao/${sro}`);
  };

  const handleParseWalletHistories = useCallback(
    walletHistory => ({
      ...walletHistory,
      type:
        walletHistory.type === WalletHistoryTypeEnum.CREDIT.value
          ? WalletHistoryTypeEnum.CREDIT.label
          : walletHistory.type === WalletHistoryTypeEnum.DEBIT.value
          ? WalletHistoryTypeEnum.DEBIT.label
          : walletHistory.type === WalletHistoryTypeEnum.DISCHARGE.value
          ? WalletHistoryTypeEnum.DISCHARGE.label
          : WalletHistoryTypeEnum.REFUND.label,
      created_at: moment(walletHistory.created_at)
        .subtract(3, "hours")
        .format("DD/MM/YYYY")
    }),
    [user.profile]
  );

  const getWalletHistories = useCallback(
    debounce(async (initial, final) => {
      setLoading(true);
      try {
        const response = await api.makeHttpRequest({
          method: "GET",
          url: `/walletHistories`,
          params: {
            page: 1,
            initialDate: initial ? moment(initial).format("MM/DD/YYYY") : null,
            finalDate: final ? moment(final).format("MM/DD/YYYY") : null,
            type: type.value || null,
            ...(searchClient && { client: searchClient?.label })
          }
        });

        const parsedResult = response.results.map(walletHistory =>
          handleParseWalletHistories(walletHistory)
        );
        setWalletHistories(parsedResult);
        setActualPage(1);

        if (response.results.length === 20) {
          setHasMore(true);
        } else {
          setHasMore(false);
        }
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    }, 500),
    [user, type.value, periodInitialDate, periodFinalDate, searchClient]
  );

  const handleFetchData = useCallback(async () => {
    setLoadingInfinit(true);
    try {
      const response = await api.makeHttpRequest({
        method: "GET",
        url: `/walletHistories`,
        params: {
          page: actualPage + 1,
          initialDate: periodInitialDate
            ? moment(periodInitialDate).format("MM/DD/YYYY")
            : null,
          finalDate: periodFinalDate
            ? moment(periodFinalDate).format("MM/DD/YYYY")
            : null,
          type: type.value || null,
          ...(searchClient && { client: searchClient?.label })
        }
      });

      const parsedResult = response.results.map(walletHistory =>
        handleParseWalletHistories(walletHistory)
      );
      setWalletHistories(previousState => [...previousState, ...parsedResult]);

      setActualPage(Number(response.actualPage));

      if (response.results.length === 20) {
        setHasMore(true);
      } else {
        setHasMore(false);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoadingInfinit(false);
    }
  }, [api, actualPage, periodInitialDate, periodFinalDate, type, searchClient]);

  const handleBackGroundColor = type => {
    let color = "";

    switch (type) {
      case WalletHistoryTypeEnum.DEBIT.label:
        color = "#FFC0C0";
        break;
      case WalletHistoryTypeEnum.REFUND.label:
        color = "#BDDFFF";
        break;
      case WalletHistoryTypeEnum.CREDIT.label:
        color = "#B8F2CE";
        break;
      case WalletHistoryTypeEnum.DISCHARGE.label:
        color = "#F9FFB7";
        break;
      default:
        color = "";
        break;
    }
    return color;
  };

  const handleClearPeriodFilter = () => {
    setPeriodInitialDate("");
    setPeriodFinalDate("");
  };

  const handleOnChangeCalendarPeriod = e => {
    if (e.dateEnd) {
      setPeriodInitialDate(e.dateStart);
      setPeriodFinalDate(e.dateEnd);
    }
  };

  useEffect(() => {
    getWalletHistories(periodInitialDate, periodFinalDate);
  }, [getWalletHistories, periodInitialDate, periodFinalDate]);

  return (
    <>
      <Loading loading={loading} />
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.contentHeader}>
            <p>Extrato da Carteira</p>
          </div>
          <div className={styles.contentBody}>
            <div className={styles.filterers}>
              {isAdmin && (
                <div className={styles.inputClient}>
                  <InputSelectAsync
                    search={searchClient}
                    setSearch={setSearchClient}
                  />
                </div>
              )}
              <div className={styles.input}>
                <DatePicker
                  className={"datePickerConfig"}
                  change={handleOnChangeCalendarPeriod}
                  placeholder="Selecione no calendário"
                  showClearButton={!!periodInitialDate && !!periodFinalDate}
                  onClearFields={handleClearPeriodFilter}
                  dateStart={periodInitialDate}
                  popAuto={true}
                  dateFinal={periodFinalDate}
                  range
                />
              </div>
              <div className={styles.formSelect}>
                <Select
                  onChange={handleType}
                  options={options}
                  value={type}
                  placeholder={type}
                  styles={selectStyle}
                />
              </div>
            </div>
            <ScrollDiv data={walletHistories}>
              <InfiniteScroll
                dataLength={walletHistories.length}
                next={handleFetchData}
                hasMore={hasMore}
                loader={loadingInfinit && <LoadInfiniteScroll />}
                scrollableTarget="scrollDiv"
                className={styles.infiniteScroll}
              >
                <div className={styles.tableContainer}>
                  <table className={styles.table}>
                    <thead className={styles.tableHead}>
                      <tr>
                        <th>Código</th>
                        {isAdmin && <th>Cliente</th>}
                        <th>Tipo</th>
                        <th>Nº da Nota</th>
                        <th>Valor (R$)</th>
                        <th>Saldo Anterior (R$)</th>
                        <th>Saldo Posterior (R$)</th>
                        <th>Data</th>
                        <th>Conciliação</th>
                      </tr>
                    </thead>
                    <tbody className={styles.tableBody}>
                      {walletHistories.map(walletHistory => {
                        return (
                          <tr
                            key={walletHistory?.id}
                            style={{
                              backgroundColor: handleBackGroundColor(
                                walletHistory?.type
                              )
                            }}
                          >
                            <td className={styles.thId}>
                              <p>{walletHistory?.id}</p>
                            </td>
                            {isAdmin && (
                              <td className={styles.thClient}>
                                <p>
                                  {walletHistory?.user?.userData?.[1].Value}
                                </p>
                              </td>
                            )}
                            <td className={styles.thType}>
                              <p>{walletHistory?.type}</p>
                            </td>
                            <td className={styles.thInvoice}>
                              <p>{walletHistory?.idInvoice}</p>
                            </td>
                            <td className={styles.thValue}>
                              <p>{walletHistory?.value}</p>
                            </td>
                            <td className={styles.thPreviousBalance}>
                              <p>{walletHistory?.previousBalance}</p>
                            </td>
                            <td className={styles.thBalance}>
                              <p>{walletHistory?.balance}</p>
                            </td>
                            <td className={styles.thDate}>
                              <p>{walletHistory?.created_at}</p>
                            </td>
                            <td className={styles.thConciliation}>
                              {walletHistory?.idShippingConciliation > 0 ? (
                                <>
                                  <StyledTooltip
                                    text="Detalhes"
                                    position="top"
                                    arrow
                                  >
                                    <button
                                      onClick={() =>
                                        hanldeOpenConciliation(
                                          walletHistory?.shippingConciliation
                                            ?.shipping?.sro
                                        )
                                      }
                                    >
                                      <FiSearch size={20} color="#000" />
                                    </button>
                                  </StyledTooltip>
                                  {/* <div className={`${openConiliation === walletHistory?.idShippingConciliation ? `${styles.openConciliation}` : `${styles.closedConciliation}`}`}>
                                    <div className={styles.conciliationRow}>
                                      <p className={styles.conciliationHeadTitle}>Diferenças</p>
                                    </div>
                                    <div className={styles.conciliationRow}>
                                      <p className={styles.conciliationTitle}>Objeto:</p>
                                      <p className={styles.conciliationText}>{walletHistory?.shippingConciliation?.idShipping}</p>
                                    </div>
                                    <div className={styles.conciliationRow}>
                                      <p className={styles.conciliationTitle}>Altura (cm):</p>
                                      <p className={styles.conciliationText}>{walletHistory?.shippingConciliation?.heightDiff}</p>
                                    </div>
                                    <div className={styles.conciliationRow}>
                                      <p className={styles.conciliationTitle}>Largura (cm):</p>
                                      <p className={styles.conciliationText}>{walletHistory?.shippingConciliation?.widthDiff}</p>
                                    </div>
                                    <div className={styles.conciliationRow}>
                                      <p className={styles.conciliationTitle}>Comprimento (cm):</p>
                                      <p className={styles.conciliationText}>{walletHistory?.shippingConciliation?.lengthDiff}</p>
                                    </div>
                                    <div className={styles.conciliationRow}>
                                      <p className={styles.conciliationTitle}>Peso (kg):</p>
                                      <p className={styles.conciliationText}>{walletHistory?.shippingConciliation?.weightDiff}</p>
                                    </div>
                                  </div> */}
                                </>
                              ) : null}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </InfiniteScroll>
            </ScrollDiv>
          </div>
        </div>
      </div>
    </>
  );
};

export { WalletHistory };
