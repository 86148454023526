import React from 'react';
import useGetWindowWidth from '../../utils/useGetWindowWidth';
import {
    Container,
} from './styles';
import TextPrivacy from './TextPrivacy';

const PrivacyPolicy = ({ location }) => {
    const width = useGetWindowWidth();

    return (
        <Container width={width}>
            <h2>POLÍTICA DE PRIVACIDADE</h2>
            <TextPrivacy />
        </Container>
    )
};

export default PrivacyPolicy;
