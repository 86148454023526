import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 488px;
  gap: 32px;
  padding: 32px 0;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12),
    0px 1px 3px rgba(0, 0, 0, 0.2);
  border-radius: 12px;
  background-color: #ffffff;

  p {
    font-family: "Mulish";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21.6px;
    text-align: center;
    margin-bottom: 0px;
  }

  > span {
    background: #409e6a !important;
  }

  img {
    width: 48px;
    height: 48px;
  }
`;
