import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  useRef
} from "react";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import Swal from "sweetalert2";
import moment from "moment";
import { format } from "date-fns";

import store from "../../../../store/store";
import { BrComerceApiService } from "../../../../services/BrComerceApiService";

import { ChartBySla } from "./components/ChartBySla";
import { Loading } from "../../../../components/Loading";
import InputText from "../../../../components/InputText";
import InputSelectAsync from "../../../../components/InputSelectAsync";
import { CartCardShipping } from "../../components/CartCardShipping";
import { GoogleMapsDashboard } from "../../../../components/GoogleMapsDashboard";

import useGetWindowWidth from "../../../../utils/useGetWindowWidth";
import ProfileEnum from "../../../../enums/ProfileEnum";
import ShippingStagesEnum from "../../../../enums/ShippingStagesEnum";

import { Calendar } from "../../pages/Dashboard/partials/Calendar";
import { definedsDates } from "../../pages/Dashboard/partials/Calendar/config";

import { ReactComponent as CalendarIcon } from "../../../../assets/icons/mdi-calendar.svg";

import styles from "./styles.module.scss";
import { capitalizeFirstLetter } from "../../../../utils/CapitalizeFirstLetter";
import pinTruck from "../../../../assets/icons/pin-truck.svg";

const DriverLocation = () => {
  const { auth } = store.getState();
  const { user } = auth;
  const isAdmin = user?.profile === ProfileEnum.ADMIN.value;

  const [center, setCenter] = useState({
    lat: -22.9035,
    lng: -43.2096
  })

  const api = new BrComerceApiService();
  const widthWindows = useGetWindowWidth();

  const calendarWrapperRef = useRef(null);

  const initialFilters = {
    initialDate: definedsDates.startOfLastSevenDay,
    endDate: definedsDates.endOfToday,
    driverSub: []
  };

  const [isLoading, setIsLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [isShowCalendar, setIsShowCalendar] = useState(false);
  const [chartBySLA, setChartBySLA] = useState(null);
  const [searchDriver, setSearchDriver] = useState(null);
  const [resumeRoutes, setResumeRoutes] = useState([]);
  const [locations, setLocations] = useState([]);
  const [shippings, setShippings] = useState([]);
  const [shippingsExpand, setShippingsExpand] = useState([]);
  const [requestErrorsBalance, setRequestErrorsBalance] = useState([""]);
  const [period, setPeriod] = useState({
    string: "",
    value: [
      {
        startDate: initialFilters.initialDate,
        endDate: initialFilters.endDate,
        key: "selection"
      }
    ]
  });
  const [periodDate, setPeriodDate] = useState([
    {
      startDate: initialFilters.initialDate,
      endDate: initialFilters.endDate,
      key: "selection"
    }
  ]);

  const mapperToPeriodDates = (
    dateInCalendarPeriod,
    formatDate = "MM/dd/yyyy"
  ) => {
    const { endDate, startDate } = dateInCalendarPeriod;
    const initialDateFormatted = format(startDate, formatDate);
    const endDateFormatted = format(endDate, formatDate);

    return {
      endDate: endDateFormatted,
      startDate: initialDateFormatted
    };
  };

  const selectYearOptions = useMemo(() => {
    const initialSoftwareDate = 2021;
    const actualYear = new Date().getFullYear();

    const years = [];
    for (let y = initialSoftwareDate; y <= actualYear; y++) {
      years.push({
        label: y,
        value: y
      });
    }

    return years;
  }, []);

  const handleSetCalendarPeriod = () => {
    setIsShowCalendar(false);

    const { endDate, startDate } = mapperToPeriodDates(
      periodDate[0],
      "dd/MM/yyyy"
    );

    setPeriod({
      string: `${startDate} - ${endDate}`,
      value: periodDate
    });
  };

  const handleShippingExpand = useCallback(
    pack => {
      const find = shippingsExpand.find(p => p.id === pack.id);
      if (find) {
        const newPacks = shippingsExpand.filter(p => p.id !== pack.id);
        setShippingsExpand([...newPacks]);
      } else {
        shippingsExpand.push(pack);
        setShippingsExpand([...shippingsExpand]);
      }
    },
    [shippingsExpand]
  );

  const handleLocations = value => {
    const handledGeolocations = [];
    if (value.length) {
      value.forEach(item => {
        handledGeolocations.push({
          lat: Number(item?.lastLocation.lat),
          lng: Number(item?.lastLocation.lng),
          driver: item?.driver?.name ?? ""
        });
      });
    }
    setLocations(handledGeolocations);
  };

  const loadDashboard = async (period, drivers) => {
    const subs = drivers?.map(d => d.sub)
    try {
      const result = await api.makeHttpRequest({
        method: "GET",
        url: "/dashboard/adminIndicators/own-delivery",
        params: {
          endDate: period.value[0].endDate,
          initialDate: period.value[0].startDate,
          driverSub: subs
        }
      });

      setChartBySLA({
        totalActivedRoute: result.activeRoutes.length,
        ...result.chartBySLA
      });
      setResumeRoutes(result.activeRoutes);
      setShippings(result.lastDeliveries);
      handleLocations(result.locations);
      setRequestErrorsBalance([])
    } catch (e) {
      if (e?.message === "Sessão expirada!") {
        setRequestErrorsBalance(prev => [...prev, e.message])
      } else {
        setRequestErrorsBalance(prev => [...prev, `Ocorreu um erro ao carregar o dashboard`])
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadDashboard(period, searchDriver);

    const interval = setInterval(() => { loadDashboard(period, searchDriver) }, 15000);

    return () => clearInterval(interval);
  }, [period, searchDriver]);

  useEffect(() => {
    if (requestErrorsBalance.length > 1) {
      Swal.fire({
        icon: "error",
        title: "Dashboard",
        text: requestErrorsBalance[requestErrorsBalance.length - 1]
      });
    }
  }, [requestErrorsBalance])


  useEffect(() => {
    window.setPageTitle("Localização de Motoristas");
  }, []);

  return (
    <section className={styles.main}>
      <Loading loading={isLoading} />
      <h1>Localização de Motoristas</h1>
      <Container fluid className="pt-4">
        {chartBySLA ? (
          <ChartBySla data={chartBySLA} />
        ) : (
          <Row className={`${styles.cardsStatus}`}>
            <Col xs={12} className={styles.anErrorOcurred}>
              {isLoading ? (
                <Spinner animation="border" role="status">
                  <span style={{ visibility: "hidden" }}>Loading...</span>
                </Spinner>
              ) : (
                <p>Ocorreu um erro ao carregar indicadores.</p>
              )}
            </Col>
          </Row>
        )}
      </Container>

      <Container fluid className={styles.indicatorsCharts}>
        <Row>
          <Col className={styles.mapWrapper}>
            {!isLoading ? (
              <GoogleMapsDashboard
                zoom={11}
                centralizeCoordinates={center}
                locations={locations}
                iconMarker={pinTruck}
                setCenter={setCenter}
              />
            ) : (
              <div className={styles.anErrorOcurred}>
                <Spinner animation="border" role="status">
                  <span style={{ visibility: "hidden" }}>Loading...</span>
                </Spinner>
              </div>
            )}
          </Col>
        </Row>
      </Container>

      <div className={styles.filtersContainer}>
        <Col xs={12} sm={4} style={{ position: "initial" }}>
          <div className={styles.calendarInput}>
            <InputText
              id="select_calendar"
              placeholder="Filtrar por período"
              onChange={() => { }}
              onClick={() => setIsShowCalendar(true)}
              value={period.string}
              renderIcon={() => <CalendarIcon />}
            />
          </div>
          <div
            style={{
              display: isShowCalendar ? "flex" : "none",
              maxHeight: isShowCalendar ? "100%" : ""
            }}
            ref={calendarWrapperRef}
            className={styles.boxCalendar}
            onClick={e => {
              if (e.currentTarget !== e.target) return;

              setIsShowCalendar(false);
            }}
          >
            <div className={styles.calendarWrapper}>
              <Calendar
                onChange={item => setPeriodDate([item.selection])}
                ranges={periodDate}
                minDate={new Date(selectYearOptions[0].value, 0, 1)}
              />
              <div className={styles.calendarFooter}>
                <button onClick={() => setIsShowCalendar(false)}>
                  Cancelar
                </button>
                <button onClick={handleSetCalendarPeriod}>Aplicar</button>
              </div>
            </div>
          </div>
        </Col>
        <Col xs={12} sm={4} style={{ position: "initial" }}>
          <InputSelectAsync
            search={searchDriver}
            setSearch={setSearchDriver}
            listUserOrDriver={"driver"}
            isMulti
          />
        </Col>
      </div>

      <h1>Resumo de rotas ativas</h1>

      <table className={styles.tableRoutes}>
        <thead>
          <tr>
            <th>Rota</th>
            <th>Objetos Entregues</th>
            <th>Motorista</th>
            <th>Data Criação</th>
            <th>Status</th>
          </tr>
        </thead>
        {resumeRoutes.length ? (
          <tbody>
            {resumeRoutes.map(resume => {
              return (
                <tr>
                   <td>
                    <a
                      href={`detalhes-rota/${resume?.id}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <p>{resume.id}</p>
                    </a>
                  </td>
                  <td>
                    {resume.objectsDelivered} de {resume.routeShipping.length}
                  </td>
                  <td>{capitalizeFirstLetter(resume?.driver?.name?.trim())}</td>
                  <td>
                    {moment(resume.created_at, "YYYY-MM-DD").format(
                      "DD/MM/YYYY"
                    )}
                  </td>
                  <td style={{ fontWeight: "bold" }}>{resume.status.name}</td>
                </tr>
              );
            })}
          </tbody>
        ) : null}
      </table>

      {!resumeRoutes.length && (
        <div className={styles.nothingData}>
          <span>Nenhuma rota ativa no momento</span>
        </div>
      )}

      <h1 style={{ marginTop: "24px" }}>Últimas entregas</h1>

      <div className={styles.shippingsContainer}>
        {shippings?.map(pack => {
          return (
            <CartCardShipping
              widthWindows={widthWindows}
              key={pack?.shipping?.id}
              shipping={pack?.shipping}
              isSelected={false}
              isCancelled={
                pack.idShippingStatus === ShippingStagesEnum.OBJETO_CANCELADO.id
              }
              isAdmin={isAdmin}
              setLoading={setLoading}
              invoiceMethod={"null"}
              collectStage={4}
              edit={false}
              isExpand={shippingsExpand.find(
                shipExpand => pack.id === shipExpand.id
              )}
              onExpand={() => handleShippingExpand(pack)}
              linkTracking
              select={false}
              delivery={pack?.delivery ?? {}}
              status={true}
              dashDrivers={true}
            />
          );
        })}
      </div>
    </section>
  );
};

export { DriverLocation };
