const RouteStageEnum = Object.freeze({
    A_FAZER: {
        id: 1,
        message: "A fazer"
    },
    EM_ANDAMENTO: {
        id: 2,
        message: "Em andamento"
    },
    CONCLUIDA: {
        id: 3,
        message: "Concluída"
    },
})

export { RouteStageEnum };
