import styled from 'styled-components';

export const ErrorRequest =styled.div`

@keyframes animate {
  from {
     width: 0%;
  }
  to {
     width: 100%;
  }
}

  margin-bottom: 20px;
  background-color: ${({data}) => data.type === 'error' ?  '#FF7563' :
  data.type === 'alert' ? '#FFE230' : '#3DFF9E'};
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
  padding-top: 8px;
  flex-direction: row;

  span {
    cursor: pointer;
    text-decoration: underline;
    font-size: 16px;
  }

  p {
    flex: 1;
    text-align: justify;
    margin: 0  8px;
    color: #031D3D;
    font-size: 14px;
  }

  div {
    margin-top: 2px;
    height: 4px;
    background-color: #031D3D;
    animation: ${({data}) => `animate ${data.time / 2}s ease-out alternate infinite`};
  }

`;
