import React, { Suspense, useCallback, useEffect, useState } from "react";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import store from "../../store/store";
import debounce from "../../utils/debounce";
import ErrorPage from "../errors/ErrorPage";
import { Billing } from "./pages/Billing";
import { BlingCallback } from "./pages/BlingCallback";
import { Collect } from "./pages/Collect";
import { CollectDetail } from "./pages/Collect/CollectDetail";
import { ShippingDetail } from "./pages/Collect/ShippingDetail";
import { Conciliation } from "./pages/Conciliation";
import { Dashboard } from "./pages/Dashboard";
import { Integrations } from "./pages/Integrations";
import { Bling } from "./pages/Integrations/Bling";
import { Nuvemshop } from "./pages/Integrations/Nuvemshop";
import { Tray } from "./pages/Integrations/Tray";
import {
  TutorialBling,
  TutorialBlingV3,
  TutorialNuvemShop,
  TutorialShopify,
  TutorialTiny,
  TutorialTray,
  TutorialVnda,
  TutorialVtex,
  TutorialWakecomerce,
  TutorialWoommerce,
  TutorialYampi
} from "./pages/Integrations/Tutorials";
import { Vtex } from "./pages/Integrations/Vtex";
import { Woocommerce } from "./pages/Integrations/Woocommerce";
import { Yampi } from "./pages/Integrations/Yampi";
import { NuvemshopCallback } from "./pages/NuvemshopCallback";
import { RequestInformation } from "./pages/RequestInformation";
// import { ScheduleCollects } from "./pages/ScheduleCollects";
import { BlingV3Callback } from "./pages/BlingV3Callback";
import { ConciliationDetail } from "./pages/ConciliationDetail";
import { DriverLocation } from "./pages/Driver";
import { DriversForm } from "./pages/Drivers/DriversForm";
import { DriversListing } from "./pages/Drivers/DriversListing";
import { CreateFreightTable } from './pages/FreightTable/CreateFreightTable';
import { FreightList } from "./pages/FreightTable/FreightList";
import { BlingV3 } from "./pages/Integrations/BlingV3";
import { Shopify } from "./pages/Integrations/Shopify";
import { Tiny } from "./pages/Integrations/Tiny";
import { Vnda } from "./pages/Integrations/Vnda";
import { Wakecomerce } from "./pages/Integrations/Wakecomerce";
import { ManageIntegrations } from "./pages/ManageIntegrations";
import { PLPCreated } from "./pages/PLP/PLPCreated";
import { PLPDetail } from "./pages/PLP/PLPDetail";
import { PrintPLP } from "./pages/PLP/PrintPLP";
import { ValidatedObjectsAndWithoutPLP } from "./pages/PLP/ValidatedObjectsAndWithoutPLP";
import { PricePolicy } from "./pages/PricePolicy";
import { CreatePricePolicy } from "./pages/PricePolicy/Create";
import { AddRoutes } from "./pages/Routes/AddRoutes";
import { RouteDetail } from "./pages/Routes/RouteDetails";
import { EditRoutes } from "./pages/Routes/RouteForm";
import { RouteList } from "./pages/Routes/RouteList";
import { DestinyForm } from "./pages/Shipping/DestinyForm";
import { ImportShipping } from "./pages/Shipping/ImportShipping";
import { ImportTrackingShipping } from "./pages/Shipping/ImportTrackingShipping";
import { PackageForm } from "./pages/Shipping/PackageForm";
import { Payment } from "./pages/Shipping/Payment";
import { ReverseForm } from "./pages/Shipping/ReverseForm";
import { SendForm } from "./pages/Shipping/SendForm";
import { Tags } from "./pages/Shipping/Tags";
import { TrackingDetails } from "./pages/Shipping/TrackingDetails";
import { SuperExpressRangeList } from "./pages/SuperExpress";
import { CreateRangeSuperExpress } from "./pages/SuperExpress/CreateRange";
import { Tracking } from "./pages/Tracking";
import { TrayCallback } from "./pages/TrayCallback";
import { CreateUserForm } from "./pages/Users/CreateUserForm";
import EditAccount from "./pages/Users/EditAccount";
import { UpdateUserForm } from "./pages/Users/UpdateUserForm";
import { UserListing } from "./pages/Users/UserListing";
import { WalletHistory } from "./pages/WalletHistory";


export default function AdminPage() {
  const history = useHistory();
  const { auth } = store.getState();
  const { user } = auth;

  const [width, setWidth] = useState(window.innerWidth);

  const resizeEvent = useCallback(() => {
    if (window.innerWidth !== width) {
      setWidth(window.innerWidth);
    }
  }, []);

  useEffect(() => {
    window.addEventListener("resize", debounce(resizeEvent, 500));
  });

  useEffect(() => {
    if (history.location.pathname === "/home") {
      history.push("/nova-coleta/normal-send-form");
    }
  }, [history.location.pathname, history]);

  useEffect(() => {
    const store = window.localStorage.getItem("Brcom:NuvemshopCode:");
    const pathname = window.location.pathname;
    const pathnameAndIntegrationNuvemshop =
      pathname === "/integracao/nuvemshop";
    if (store && pathnameAndIntegrationNuvemshop) {
      history.push(`/integracao/nuvemshop/${store}`);
    }
  }, [history]);

  return (
    <Suspense>
      <Switch>
        {/* Coleta */}
        {/* <Route exact path="/nova-coleta" component={NewShipping} /> */}
        <Route
          exact
          path="/nova-coleta/reverse-email-form"
          component={() => (
            <ReverseForm step={1} totalSteps={4} isReverseLogistics={true} />
          )}
        />
        <Route
          exact
          path="/nova-coleta/reverse-send-form"
          component={() => (
            <SendForm step={2} totalSteps={4} isReverseLogistics={true} />
          )}
        />
        <Route
          exact
          path="/nova-coleta/reverse-destiny-form"
          component={() => (
            <DestinyForm step={3} totalSteps={4} isReverseLogistics={true} />
          )}
        />
        <Route
          exact
          path="/nova-coleta/reverse-package-form"
          component={() => (
            <PackageForm step={4} totalSteps={4} isReverseLogistics={true} />
          )}
        />

        <Route
          exact
          path="/nova-coleta/normal-send-form"
          component={() => (
            <SendForm step={1} totalSteps={3} isReverseLogistics={false} />
          )}
        />
        <Route
          exact
          path="/nova-coleta/normal-destiny-form"
          component={() => (
            <DestinyForm step={2} totalSteps={3} isReverseLogistics={false} />
          )}
        />
        <Route
          exact
          path="/nova-coleta/normal-package-form"
          component={() => (
            <PackageForm step={3} totalSteps={3} isReverseLogistics={false} />
          )}
        />
        <Route exact path="/nova-coleta/import" component={ImportShipping} />
        <Route exact path="/nova-coleta/tracking-import-shipping" component={ImportTrackingShipping} />

        <Route exact path="/carrinho" component={Payment} />
        <Route exact path="/editar-conta" component={EditAccount} />
        <Route
          exact
          path="/minhas-coletas"
          component={() => <Collect width={width} />}
        />
        <Route exact path="/detalhe-coleta/:id" component={CollectDetail} />
        <Route exact path="/detalhes-objeto/:id" component={ShippingDetail} />
        <Route
          exact
          path="/etiquetas"
          component={() => <Tags width={width} />}
        />

        <Route
          exact
          path="/plp/:id"
          component={() => <PrintPLP width={width} />}
        />

        <Route
          exact
          path="/detalhes-plp/:id"
          component={() => <PLPDetail width={width} />}
        />

        <Route exact path="/integracoes" component={Integrations} />
        <Route exact path="/rastrear-encomenda" component={Tracking} />

        <Route exact path="/rastrear-motorista" component={DriverLocation} />

        <Route
          exact
          path="/integracao/nuvemshop/callback"
          component={NuvemshopCallback}
        />

        {/* <Route exacth path="/pagamento-pix/:collect_id" component={PaymentPix} /> */}

        <Route exact path="/integracao/bling" component={Bling} />
        <Route exact path="/callback/bling" component={BlingCallback} />
        <Route exact path="/integracao/blingV3" component={BlingV3} />
        <Route exact path="/callback/blingV3" component={BlingV3Callback} />
        <Route exact path="/integracao/woocommerce" component={Woocommerce} />
        <Route exact path="/integracao/yampi" component={Yampi} />
        <Route exact path="/integracao/tray" component={Tray} />
        <Route exact path="/callback/tray" component={TrayCallback} />
        <Route exact path="/integracao/nuvemshop" component={Nuvemshop} />
        <Route exact path="/faturamento" component={Billing} />
        {/* <Route exact path="/coletas-agendadas" component={ScheduleCollects} /> */}
        <Route exact path="/integracao/vtex" component={Vtex} />
        <Route exact path="/integracao/wakecomerce" component={Wakecomerce} />
        <Route exact path="/integracao/tiny" component={Tiny} />
        <Route exact path="/integracao/vnda" component={Vnda} />
        <Route exact path="/integracao/shopify" component={Shopify} />

        <Route
          exact
          path="/integracao/ajuda-nuvemshop"
          component={TutorialNuvemShop}
        />

        <Route exact path="/integracao/ajuda-tray" component={TutorialTray} />
        <Route
          exact
          path="/integracao/ajuda-wakecomerce"
          component={TutorialWakecomerce}
        />
        <Route exact path="/integracao/ajuda-yampi" component={TutorialYampi} />
        <Route exact path="/integracao/ajuda-bling" component={TutorialBling} />
        <Route exact path="/integracao/ajuda-blingV3" component={TutorialBlingV3} />
        <Route exact path="/integracao/ajuda-tiny" component={TutorialTiny} />
        <Route
          exact
          path="/integracao/ajuda-woocommerce"
          component={TutorialWoommerce}
        />
        <Route exact path="/integracao/ajuda-vtex" component={TutorialVtex} />
        <Route exact path="/integracao/ajuda-vnda" component={TutorialVnda} />
        <Route exact path="/integracao/ajuda-shopify" component={TutorialShopify} />

        {/* USUARIO */}

        <Route
          exact
          path="/users"
          component={() => <UserListing width={width} />}
        />
        <Route exact path="/users/:id/update" component={UpdateUserForm} />
        <Route exact path="/users/create" component={CreateUserForm} />
        <Route exact path="/gerenciar-integracoes" component={ManageIntegrations} />

        <Route exact path="/dashboard" component={Dashboard} />

        <Route exact path="/conciliacao" component={Conciliation} />

        <Route exact path="/conciliacao/:sro" component={ConciliationDetail} />

        <Route
          exact
          path="/pedidos-de-informacao"
          component={RequestInformation}
        />

        <Route
          exact
          path="/pedidos-sem-plp"
          component={ValidatedObjectsAndWithoutPLP}
        />

        <Route exact path="/plps" component={PLPCreated} />

        <Route exact path="/rastreio/:rastreio" component={TrackingDetails} />

        <Route exact path="/error-page" component={ErrorPage} />

        <Route exact path="/politica-de-preco" component={PricePolicy} />
        <Route
          exact
          path="/politica-de-preco/create"
          component={CreatePricePolicy}
        />
        <Route
          exact
          path="/politica-de-preco/:id"
          component={CreatePricePolicy}
        />

        <Route
          exact
          path="/motoristas"
          component={() => <DriversListing width={width} />}
        />
        <Route exact path="/motoristas/:id/editar" component={DriversForm} />
        <Route exact path="/motoristas/cadastrar" component={DriversForm} />

        {/* Routes */}
        <Route exact path="/listagem-rotas" component={RouteList} />
        <Route exact path="/adicionar-rota" component={AddRoutes} />
        <Route exact path="/detalhes-rota/:id" component={RouteDetail} />
        <Route exact path="/editar-rota/:id" component={EditRoutes} />

        <Route exact path="/extrato-conta" component={WalletHistory} />

        <Route exact path="/super-expresso" component={SuperExpressRangeList} />
        <Route exact path="/super-expresso/create-range" component={CreateRangeSuperExpress} />
        <Route exact path="/super-expresso/edit/:id" component={CreateRangeSuperExpress} />
        
        <Route exact path="/tabelas-frete" component={FreightList} />
        <Route exact path="/tabelas-frete/create" component={CreateFreightTable} />

        <Route
          component={() => {
            const isInitialPath = window.location.pathname === "/";

            if (isInitialPath) {
              if (user.profile === "admin") {
                return <Redirect to="/carrinho" />;
              } else {
                return <Redirect to="/nova-coleta/normal-send-form" />;
              }
            }

            return <Redirect to="/error-page" />;
          }}
        />
      </Switch>
    </Suspense>
  );
}
