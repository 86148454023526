import React, { useEffect } from "react";
import { format } from "date-fns";
import styles from "./styles.module.scss";

import correiosLogo from "../../assets/logo/correios-color.png";
import PlpTypeEnum from "../../enums/PlpTypeEnum";

const PlpTag = ({
  sedexShippings,
  pacShippings,
  pacMiniShippings,
  plpBarcodeBase64,
  plpNumber,
  shippingsNumber,
  plpDate,
  cartaoPostagem,
  cartaoPostagemBarcodeBase64,
  isClientCopy,
  userPLPType
}) => {

  const typePLPIsRetail = userPLPType === PlpTypeEnum.RETAIL.value;

  return (
    <>
      <div className={styles.correiosHeader}>
        <img src={correiosLogo} alt="LOGO CORREIOS" />
        <p>EMPRESA BRASILEIRA DE CORREIOS E TELÉGRAFOS</p>
      </div>
      <div className={styles.container}>
        <div className={styles.header}>
          PRÉ - LISTA DE POSTAGEM - PLP - SIGEP WEB
        </div>
        <div className={styles.plp}>
          <div>
            <p>
              <strong>SIGEP WEB - Gerenciador de Postagens dos Correios</strong>
            </p>
            <p>
              <strong>Contrato:&nbsp;&nbsp;</strong>
              9912541295
            </p>
            <p>
              <strong>Cliente:&nbsp;&nbsp;</strong>
              BR COMERCE LTDA EPP
            </p>
            <p>
              <strong>Telefone de contato:&nbsp;&nbsp;</strong>
            </p>
            <p>
              <strong>Email de contato:&nbsp;&nbsp;</strong>
            </p>
          </div>

          <div className={styles.plpCode}>
            <p className={styles.plpNumber}>
              <strong>Codigo Cartão: {cartaoPostagem}</strong>
            </p>
            <img
              src={`data:image/jpeg;base64,${cartaoPostagemBarcodeBase64}`}
              alt={`CODIGO DE BARRAS ${cartaoPostagem}`}
            />
          </div>

          <div className={styles.plpCode}>
            <p className={styles.plpNumber}>
              <strong>Nº PLP: {plpNumber}</strong>
            </p>
            <img
              src={`data:image/jpeg;base64,${plpBarcodeBase64}`}
              alt={`CODIGO DE BARRAS ${plpNumber}`}
            />
          </div>
        </div>

        <div className={styles.object}>
          <div className={styles.objectLeft}>
            <div className={styles.quantityServiceContainer}>
              <table>
                <thead>
                  <tr>
                    <th>Código</th>
                    <th>Quantidade</th>
                    <th>Serviço</th>
                  </tr>
                </thead>
                <tbody>
                  {sedexShippings > 0 ? (
                    <tr>
                      <td>{typePLPIsRetail ? 3220 : 3280}</td>
                      <td>{sedexShippings}</td>
                      <td>{typePLPIsRetail ? "SEDEX CONTRATO AG" : "SEDEX INDUSTRIAL UO"}</td>
                    </tr>
                  ) : (
                    <></>
                  )}
                  {pacShippings > 0 ? (
                    <tr>
                      <td>{typePLPIsRetail ? 3298 : 3336}</td>
                      <td>{pacShippings}</td>
                      <td>{typePLPIsRetail ? "PAC CONTRATO AG" : "PAC INDUSTRIAL UO"}</td>
                    </tr>
                  ) : (
                    <></>
                  )}
                  {pacMiniShippings > 0 ? (
                    <tr>
                      <td>{typePLPIsRetail ? 4227 : 4391}</td>
                      <td>{pacMiniShippings}</td>
                      <td>{typePLPIsRetail ? "CORREIOS MINI ENVIOS CTR AG" : "CORREIOS MINI ENVIOS CTR UO"}</td>
                    </tr>
                  ) : (
                    <></>
                  )}
                  <tr>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                  </tr>
                  <tr>
                    <td>Total: </td>
                    <td>{shippingsNumber}</td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>

            <p>Data de fechamento: {format(new Date(plpDate), "dd/MM/yyyy")}</p>
          </div>
          <div className={styles.objectRight}>
            <div className={styles.date}>
              <strong>Data da entrega:</strong>
              <div className={styles.dateField}>
                <div className={styles.numberDate}></div>
                <div className={styles.dateSlash}></div>
                <div className={styles.numberDate}></div>
                <div className={styles.dateSlash}></div>
                <div className={styles.numberDate}></div>
              </div>
            </div>
            <div className={styles.sign}>
              <div className={styles.signField}></div>
              Assinatura / Matrícula dos Correios
              <p>{isClientCopy ? "2º via - Cliente" : "1º via - Correios"}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export { PlpTag };
