import moment from "moment";
import { addDays, format } from "date-fns";
import { capitalizeFirstLetter } from "./CapitalizeFirstLetter";
import formatCep from "./FormatCep";

const collectsCSVFormat = collects => {
  const csvCollectsData = [];

  collects.map(row => {
    const rowData = {
      Coleta: row?.id || "",
      Cliente: row?.user.userData[1].Value || "",
      "Quantidade de Objetos":
        row.shippings.length !== 0
          ? `${row.shippings.length} objeto${
              row.shippings.length > 1 ? "s" : ""
            }`
          : 0,
      "Data Criação":
        moment(row.created_at)
          .subtract(3, "hours")
          .format("DD/MM/YYYY") || "",
      "Data Agendada": format(new Date(row.collectDate), "dd/MM/yyyy"),
      "Período Coleta": row.collectPeriod === "Morning" ? "Manhã" : "Tarde",
      Status: row.collectsStatus?.name
    };

    return csvCollectsData.push(rowData);
  });

  return csvCollectsData;
};

const shippingsCSVFormat = (shippings, type, isDeleted = false) => {
  const csvShippingsData = [];

  shippings.map(row => {
    const rowData = {
      "Código da etiqueta": row.sro || "",
      Pedido: row.orderNumber || "",
      Coleta: row.idCollect || "",
      "Data Criação":
        moment(row.created_at)
          .subtract(3, "hours")
          .format("DD/MM/YYYY") || "",
      "Previsão Entrega":
        row?.numeroPLP > 0
          ? format(
              addDays(
                new Date(row?.created_at),
                Number(row?.correios?.prazoEntrega || 0)
              ),
              "dd/MM/yyyy"
            )
          : "",
      "Data Entrega":
        row.status.id === 10 || row.status.id === 8
          ? format(new Date(row.deliveredDate), "dd/MM/yyyy")
          : "",
      Cliente: row.user?.userData?.[1].Value,
      "CEP de Origem": formatCep(row?.origin?.zip),
      "CEP de Destino": formatCep(row?.destination?.zip),
      Destinatário:
        type.value === "normal" ? row.destination?.name : row.origin?.name,
      Status: capitalizeFirstLetter(row.status?.name) || "",
      "Valor do Frete": Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL"
      }).format(row?.price),
      ...(isDeleted && { "Data de deleção": format(new Date(row?.deleted_at), "dd/MM/yyyy") })
    };
    return csvShippingsData.push(rowData);
  });

  return csvShippingsData;
};

const routesCSVFormat = routes => {
  const csvRoutesData = [];

  routes.map(row => {
    const started = row.started_at ? moment(row.started_at).subtract(3, "hours").format("DD/MM/YYYY - HH:mm") : "";
    const finalized = row.finalized_at ? moment(row.started_at).subtract(3, "hours").format("DD/MM/YYYY - HH:mm") : ""
    const kms = row?.kms_traccar ? `~ ${row?.kms_traccar?.toFixed(2)} km` : "--";

    let totalValue = 0;
    let totalValueDelivered = 0;
    let totalDelivered = 0;
    let totalRouteShipping = 0;

    row.routeShipping.forEach((rs) => {
      totalValue += rs?.shipping?.price;
      totalRouteShipping += 1;

      if (rs.id_own_delivery_status === 11) {
        totalValueDelivered += rs?.shipping?.price;
        totalDelivered += 1;
      }
    })

    const rowData = {
      Rota: row?.id || "",
      "Objetos Entregues": `${totalDelivered} de ${totalRouteShipping}`,
      "Data Criação":
        moment(row.created_at)
          .subtract(3, "hours")
          .format("DD/MM/YYYY") || "",
      "Motorista": capitalizeFirstLetter(row?.driver?.name) || "",
      Status: row.status?.name,
      Inicio: started,
      Fim: finalized,
      "Kms Percorrido": kms,
      "Valor Total": new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL"
      }).format(totalValue),
      "Valor Total Entregue": new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL"
      }).format(totalValueDelivered)
    };

    return csvRoutesData.push(rowData);
  });

  return csvRoutesData;
};

export { collectsCSVFormat, shippingsCSVFormat, routesCSVFormat };
