const statusShipping = {
  OBJETO_CRIADO: {
    id: 1,
    message: "Objeto Criado"
  },
  OBJETO_COLETADO: {
    id: 2,
    message: "Aguardando Coleta"
  },
  OBJETO_EXPEDIDO: {
    id: 3,
    message: "Objeto Expedido"
  },
  OBJETO_EM_TRANSITO: {
    id: 4,
    message: "Objeto em Trânsito"
  },
  OBJETO_SAIU_PARA_ENTREGA: {
    id: 5,
    message: "Objeto saiu para entrega"
  },
  OBJETO_NAO_FOI_ENTREGUE: {
    id: 6,
    message: "Objeto não foi entregue"
  },
  OBJETO_ATRASADO: {
    id: 7,
    message: "Objeto Atrasado"
  },
  OBJETO_PRONTO_PARA_RETIRADA: {
    id: 8,
    message: "Objeto Pronto para Retirada"
  },
  ENTREGA_REALIZADA: {
    id: 10,
    message: "Entrega Realizada"
  },
  OBJETO_DEVOLVIDO: {
    id: 11,
    message: "Objeto Devolvido"
  },
  OBJETO_EXTRAVIADO: {
    id: 12,
    message: "Objeto Extraviado"
  },
  NAO_FOI_POSSIVEL_ENTREGAR_O_OBJETO: {
    id: 13,
    message: "Não foi possível entregar o objeto"
  },
  OBJETO_CANCELADO: {
    id: 14,
    message: "Objeto Cancelado"
  },
  OUTROS: {
    id: 15,
    message: "Outros"
  },
  OBJETO_EM_DEVELOCAO: {
    id: 16,
    message: "Em Devolução"
  },
  AGUARDANDO_POSTAGEM: {
    id: 17,
    message: "Aguardando Postagem"
  },
  OBJETO_POSTADO: {
    id: 18,
    message: "Objeto Postado"
  }
};

export default Object.freeze({
  ...statusShipping
});
