import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { CSVDownload } from "react-csv";
import Select from "react-select";
import DatePicker from "../../../../components/Input/DatePicker/";
import InputSelectAsync from "../../../../components/InputSelectAsync";
import LoadInfiniteScroll from "../../../../components/LoadInfiniteScroll";
import { Loading } from "../../../../components/Loading";
import { RateTypeShippingEnum } from "../../../../enums/RateTypeShippingEnum";
import shippingStagesEnum from "../../../../enums/ShippingStagesEnum";
import { BrComerceApiService } from "../../../../services/BrComerceApiService";
import store from "../../../../store/store";
import { shippingsCSVFormat } from "../../../../utils/CSVFormatter";
import debounce from "../../../../utils/debounce";
import filtersFromQueryString from "../../../../utils/parserQueryString";
import { ActionsMenu } from "../../components/ActionsMenu";
import ScrollDiv from "../../components/ScrollDiv";
import { ButtonExport } from "./styles";
import styles from "./styles.module.scss";
import ProfileEnum from "../../../../enums/ProfileEnum";
import DeliveryTypeEnum from "../../../../enums/DeliveryTypeEnum";
import SaraErrorModal from "../../components/SaraErrorModal";
import { Redo, SyncProblem, Undo } from "@material-ui/icons";
import Swal from "sweetalert2";
import { selectStyle } from "../../../../components/InputSelectAsync";
import Container from "../../components/Container";
import { FiDownload, FiSearch } from "react-icons/fi";
import { handleStatusColor } from "../../../../utils/StatusColor";
import ShippingStagesEnum from "../../../../enums/ShippingStagesEnum";
import { ImBlocked, ImWarning } from "react-icons/im";
import { StyledTooltip } from "../../components/Tooltip";
import { TooltipBox, TooltipCard, TooltipText } from "./styles";
import { OriginEnum } from "../../../../enums/OriginEnum";

const Tracking = () => {
  const api = new BrComerceApiService();

  const { auth } = store.getState();
  const { user } = auth;
  const isAdmin = user?.profile === ProfileEnum.ADMIN.value;
  const isManager = user?.profile === ProfileEnum.MANAGER.value;

  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [shippings, setShippings] = useState([]);
  const [actualPage, setActualPage] = useState(0);
  const [initiateDownload, setInitiateDownload] = useState(false);
  const [shippingExportData, setShippingExportData] = useState([]);
  const [loadingInfinit, setLoadingInfinit] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [showConfirmPrepostarAll, setShowConfirmPrepostarAll] = useState(false);
  const [showShippingsWithSaraError, setShowShippingsWithSaraError] = useState(
    false
  );

  const [type, setType] = useState({
    label: "Normal",
    value: "normal"
  });

  const [selectedStatusInFilter, setSelectedStatusInFilter] = useState({
    value: "",
    label: "Status"
  });

  const [filterType, setFilterType] = useState({
    label: "Filtrar por",
    value: ""
  });

  const [alReadyDidIt, setAlreadyDidIt] = useState(false);

  const [periodInitialDate, setPeriodInitialDate] = useState(null);
  const [periodFinalDate, setPeriodFinalDate] = useState(null);

  const [searchClient, setSearchClient] = useState(null);

  const integrationToDisplayName = useMemo(() => {
    return [
      OriginEnum.VNDA,
      OriginEnum.BLINGV3
    ]
  }, []);

  const handleChangeSelectFilterByStatus = e => {
    if (!e) {
      return setSelectedStatusInFilter({ value: "", label: "Status" });
    }
    setSelectedStatusInFilter(e);
  };

  const handleFilterType = e => {
    if (!e) {
      return setFilterType({ label: "Filtrar por", value: "" });
    }
    setFilterType(e);
  };

  const handlePrepostarAll = async () => {
    setLoading(true);
    setShowConfirmPrepostarAll(false);
    try {
      await api.makeHttpRequest({
        method: "GET",
        url: `/shipping/prepostarAll`
      });

      return Swal.fire({
        title: "Sucesso!",
        text:
          "Os objetos foram enviados para fila de Prepostagem, esse processo pode levar alguns minutos",
        icon: "success"
      });
    } catch (e) {
      console.log(e);
      return Swal.fire({
        title: "Erro!",
        text: "Não foi possível enviar os objetos para fila de Prepostagem",
        icon: "error"
      });
    } finally {
      getShippingTracking(
        selectedStatusInFilter.value,
        search,
        periodInitialDate,
        periodFinalDate
      );
      setLoading(false);
    }
  };

  const handleTypeShipping = e => {
    if (!e) {
      return setType({ label: "Selecione o tipo de envio", value: "" });
    }
    setType(e);
  };

  const handleParseShipping = useCallback(
    shipping => ({
      ...shipping,
      errorPLP:
        !shipping?.prePostado &&
        shipping?.rateType === RateTypeShippingEnum.COLLECT &&
        user.profile === "admin" &&
        !shipping?.authorizationCode &&
        !(shipping.type === DeliveryTypeEnum.OWN_DELIVERY) &&
        (shipping?.user?.sendToPrepost || shipping?.prePostado === false),
      blockedDate: moment(shipping?.blockedDate).format("DD/MM/YYYY"),
      created_at: moment(shipping.created_at)
        .subtract(3, "hours")
        .format("DD/MM/YYYY"),
      previewDate: shipping?.previewDate
        ? moment(shipping.previewDate).format("DD/MM/YYYY")
        : "",
      deliveredDate: shipping?.deliveredDate
        ? moment(shipping.deliveredDate).format("DD/MM/YYYY")
        : "",
      deleted_at: shipping?.deleted_at
        ? moment(shipping?.deleted_at).format("DD/MM/YYYY")
        : ""
    }),
    [user.profile]
  );

  const handleFetchData = useCallback(async () => {
    setLoadingInfinit(true);
    try {
      /* if (actualPage === nPages) {
        return;
      } */
      const response = await api.makeHttpRequest({
        method: "GET",
        url: `/shipping/tracking`,
        params: {
          resultsPerPage: 20,
          page: actualPage + 1,
          status: selectedStatusInFilter?.value,
          initialDate: periodInitialDate ? periodInitialDate : null,
          finalDate: periodFinalDate ? periodFinalDate : null,
          text: search,
          reverse: type.value === "normal" ? false : true,
          prePosted: filterType?.value === "error" ? "false" : "true",
          ...(filterType?.value === "blocked" && { blocked: "true" }),
          ...(filterType?.value.includes("sla") && { sla: filterType?.value }),
          ...(searchClient && { client: searchClient?.label }),
          ...(filterType?.value === "saraError" && { hasSaraError: "true" }),
          ...(filterType?.value === "deleted" && { deleted: "true" }),
          ...(filterType?.value === "processCorreiosError" && { processCorreiosError: "true" }),
        }
      });

      const parsedShippings = response.results.map(shipping =>
        handleParseShipping(shipping)
      );

      setShippings(previousState => [...previousState, ...parsedShippings]);
      //setShippingsWithoutFilter([...shippings, ...response.results]);
      setActualPage(Number(response.actualPage));
      //setNPages(Number(response.nPages));
    } catch (e) {
      console.log(e);
    } finally {
      setLoadingInfinit(false);
      setLoading(false);
    }
  }, [
    api,
    actualPage,
    selectedStatusInFilter.value,
    periodInitialDate,
    periodFinalDate,
    search,
    type,
    filterType.value,
    searchClient,
    handleParseShipping
  ]);

  const handleExportSpreadsheet = async () => {
    setLoading(true);

    try {
      const page = actualPage === 0 ? 1 : actualPage;
      const response = await api.makeHttpRequest({
        method: "GET",
        url: `/shipping/tracking`,
        params: {
          resultsPerPage: page * 20,
          page: 1,
          status: selectedStatusInFilter?.value,
          initialDate: periodInitialDate ? periodInitialDate : null,
          finalDate: periodFinalDate ? periodFinalDate : null,
          text: search,
          reverse: type.value === "normal" ? false : true,
          prePosted: filterType?.value === "error" ? "false" : "true",
          exportSpreadsheet: true,
          ...(filterType?.value === "blocked" && { blocked: "true" }),
          ...(filterType?.value.includes("sla") && { sla: filterType?.value }),
          ...(searchClient && { client: searchClient?.label }),
          ...(filterType?.value === "saraError" && { hasSaraError: "true" }),
          ...(filterType?.value === "deleted" && { deleted: "true" }),
          ...(filterType?.value === "processCorreiosError" && { processCorreiosError: "true" }),
        }
      });

      setShippingExportData(shippingsCSVFormat(response, type, isDeleted));
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
      return Swal.fire({
        title: "Erro!",
        text:
          "A busca de dados para a exportação da planilha não foi realizada; por favor, tente novamente.",
        icon: "error"
      });
    }
  };

  const handleRevertShippingCancel = async (shippingId) => {
    Swal.fire({
      title: "Atenção!",
      text: "Deseja reverter a deleção esse objeto?",
      icon: "warning",
      cancelButtonText: "Cancelar",
      confirmButtonText: "Reverter",
      showCancelButton: true,
      showConfirmButton: true,
    }).then(async r => {

      if (r.dismiss) {
        return;
      }

      setLoading(true);
      try {
        await api.makeHttpRequest({
          method: "POST",
          url: `/shipping/revert-shipping-cancel`,
          params: { shippingId }
        });

        const updatedShippings = shippings.filter(s => s.id !== shippingId);

        setShippings(updatedShippings);
      } catch (err) {
        console.log(err.message);
        let message = "A reversão dessa deleção não pôde ser concluída."

        if (err.response.data.message === "Another shipping with the same order number was created") {
          message = "Outro objeto já foi criado com o mesmo número de pedido."
        }

        return Swal.fire({
          title: "Erro!",
          text: message,
          icon: "error"
        });
      } finally {
        setLoading(false);
      }

    });
  };

  const getShippingTracking = useCallback(
    debounce(async (status, searchParam, initial, final) => {
      setLoading(true);

      try {
        const response = await api.makeHttpRequest({
          method: "GET",
          url: `/shipping/tracking`,
          params: {
            resultsPerPage: 20,
            page: 1,
            status: status,
            initialDate: initial ? moment(initial, 'DD/MM/YYYY').format("MM/DD/YYYY") : null,
            finalDate: final ? moment(final, 'DD/MM/YYYY').format("MM/DD/YYYY") : null,
            text: searchParam,
            reverse: type.value === "normal" ? false : true,
            prePosted: filterType?.value === "error" ? "false" : "true",
            ...(filterType?.value === "blocked" && { blocked: "true" }),
            ...(filterType?.value.includes("sla") && {
              sla: filterType?.value
            }),
            ...(searchClient && { client: searchClient?.label }),
            ...(filterType?.value === "saraError" && { hasSaraError: "true" }),
            ...(filterType?.value === "deleted" && { deleted: "true" }),
            ...(filterType?.value === "processCorreiosError" && { processCorreiosError: "true" }),

          }
        });

        const parsedShippings = response.results.map(shipping =>
          handleParseShipping(shipping)
        );

        setShippings(parsedShippings);
        //setShippingsWithoutFilter(response.results);
        setActualPage(1);

        if (response.results.length === 20) {
          setHasMore(true);
        } else {
          setHasMore(false);
        }

        setLoading(false);
      } catch (e) {
        setLoading(false);
        console.error(e);
      }
    }, 500),
    [
      user,
      type.value,
      filterType.value,
      periodInitialDate,
      periodFinalDate,
      searchClient
    ]
  );

  const handleOnChangeCalendarPeriod = e => {
    const inicio = e.dateStart ? e.dateStart.split("/") : e[0].split("/");
    const fim = e.dateEnd ? e.dateEnd.split("/") : e[1].split("/");

    setPeriodInitialDate(
      new Date(Number(inicio[2]), Number(inicio[1] - 1), Number(inicio[0]))
    );
    setPeriodFinalDate(
      new Date(Number(fim[2]), Number(fim[1] - 1), Number(fim[0]))
    );


    // if (e.dateEnd) {
    //   setPeriodInitialDate(e.dateStart);
    //   setPeriodFinalDate(e.dateEnd);
    // }
  };

  const handleClearPeriodFilter = () => {
    setPeriodInitialDate("");
    setPeriodFinalDate("");
  };

  const handleBackGroundColor = shipping => {
    return shipping.blocked
      ? "#FFC0C0"
      : !shipping?.prePostado &&
        user.profile === "admin" &&
        !shipping?.authorizationCode &&
        shipping?.collect?.collectDate &&
        shipping?.rateType === RateTypeShippingEnum.COLLECT &&
        !(shipping.type === DeliveryTypeEnum.OWN_DELIVERY)
        ? "#F9FFB7"
        : "";
  };

  const extraColumn = useMemo(() => {
    const result = shippings.some(
      shipping => shipping.blocked || shipping.errorPLP || shipping.hasProcessCorreiosError
    );
    return result;
  }, [shippings]);

  const status = [
    {
      label: "Antes do prazo",
      value: "slaEarlier"
    },
    {
      label: "Dentro do prazo",
      value: "slaOntime"
    },
    {
      label: "Fora do prazo",
      value: "slaDelayed"
    },
    {
      label: "Bloqueados",
      value: "blocked"
    },
  ];

  const sendTypes = [
    {
      label: "Normal",
      value: "normal"
    },
    {
      label: "Reversa",
      value: "reversa"
    }
  ];

  const shippingStatus = [
    {
      label: "Todos",
      value: "all"
    },
    {
      label: "Objeto Criado",
      value: 1
    },
    {
      label: "Aguardando Coleta",
      value: 2
    },
    {
      label: "Objeto Expedido",
      value: 3
    },
    {
      label: "Objeto em trânsito",
      value: 4
    },
    {
      label: "Objeto saiu para entrega",
      value: 5
    },
    {
      label: "Objeto não foi entregue",
      value: 6
    },
    {
      label: "Objeto Atrasado",
      value: 7
    },
    {
      label: "Objeto pronto para retirada",
      value: 8
    },
    {
      label: "Entrega realizada",
      value: 10
    },
    {
      label: "Objeto Devolvido",
      value: 11
    },
    {
      label: "Objeto Extraviado",
      value: 12
    },
    {
      label: "Não foi possível entregar o objeto",
      value: 13
    },
    {
      label: "Objeto Cancelado",
      value: 14
    },
    {
      label: "Outros",
      value: 15
    },
    {
      label: "Em Devolução",
      value: 16
    },
    {
      label: "Aguardando Postagem",
      value: 17
    },
    {
      label: "Objeto Postado",
      value: 18
    }
  ];

  useEffect(() => {
    window.setPageTitle("Rastreamento");
  }, []);

  useEffect(() => {
    if (!alReadyDidIt && window.location.href.includes("value")) {
      const [value, label, initial, final] = filtersFromQueryString(
        window.location.href
      );
      setSelectedStatusInFilter({ label, value: parseInt(value) });
      handleOnChangeCalendarPeriod([initial, final]);
      setAlreadyDidIt(true);
    } else if (!alReadyDidIt && window.location.href.includes("status")) {
      const [status, label, initialDate, finalDate] = filtersFromQueryString(
        window.location.href
      );
      setFilterType({ label, value: status });
      setPeriodInitialDate(initialDate)
      setPeriodFinalDate(finalDate)
      setAlreadyDidIt(true);
    } else {
      getShippingTracking(
        selectedStatusInFilter.value,
        search,
        periodInitialDate,
        periodFinalDate
      );
    }
  }, [
    getShippingTracking,
    selectedStatusInFilter.value,
    search,
    periodInitialDate,
    periodFinalDate,
    alReadyDidIt
  ]);

  useEffect(() => {
    if (shippingExportData.length) {
      setInitiateDownload(true);
    }
  }, [shippingExportData]);

  useEffect(() => {
    // Enables user to download data more than once
    if (initiateDownload) {
      setInitiateDownload(false);
    }
  }, [initiateDownload]);

  useEffect(() => {
    if (isAdmin) {
      status.push(
        {
          label: "Erros na pré-postagem",
          value: "error"
        },
        {
          label: "Erro no SARA",
          value: "saraError"
        },
        {
          label: "Deletados",
          value: "deleted"
        },
        // {
        //   label: "Erro de processamento dos correios",
        //   value: 'processCorreiosError'
        // }
      );
    }
  }, [isAdmin, status]);

  const isDeleted = filterType?.value === "deleted";

  return (
    <>
      <Loading loading={loading} />
      {showConfirmPrepostarAll && (
        <>
          <div className={styles.confirmPrepostarBackground}></div>
          <div className={styles.confirmPrepostarModal}>
            <p>Deseja enviar todos os objetos para a fila de Prepostagem?</p>
            <div className={styles.confirmPrepostarBtnContainer}>
              <button
                className={styles.cancelPrepostarBtn}
                onClick={() => setShowConfirmPrepostarAll(false)}
              >
                Cancelar
              </button>
              <button
                className={styles.confirmPrepostarBtn}
                onClick={handlePrepostarAll}
              >
                Prepostar
              </button>
            </div>
          </div>
        </>
      )}
      <Container title="Rastreamento">
        <div className={styles.contentBody}>
          <SaraErrorModal
            isOpen={showShippingsWithSaraError}
            setIsOpen={setShowShippingsWithSaraError}
            getShippingTracking={getShippingTracking}
            selectedStatusInFilter={selectedStatusInFilter}
            search={search}
          />
          <div className={styles.filterers}>
            <div className={styles.row}>
              <div className={styles.input}>
                <input
                  type="text"
                  name="search"
                  placeholder="Buscar por código da etiqueta, pedido ou coleta"
                  value={search}
                  onChange={e => setSearch(e.currentTarget.value)}
                />
                <FiSearch size={20} color="#409e6a" />
              </div>
              {(isAdmin || isManager) && (
                <div className={styles.inputClient}>
                  <InputSelectAsync
                    search={searchClient}
                    setSearch={setSearchClient}
                  />
                </div>
              )}
            </div>
            <div className={styles.row}>
              <div className={`${styles.formSelect} ${styles.selectPicker}`}>
                <DatePicker
                  className={`datePickerConfig ${styles.inputPicker}`}
                  change={handleOnChangeCalendarPeriod}
                  placeholder="Filtrar por período"
                  showClearButton={!!periodInitialDate && !!periodFinalDate}
                  onClearFields={handleClearPeriodFilter}
                  dateStart={periodInitialDate}
                  dateFinal={periodFinalDate}
                  range
                />
              </div>
              <div className={styles.formRow}>
                <div className={styles.formSelect}>
                  <Select
                    onChange={handleChangeSelectFilterByStatus}
                    isClearable={selectedStatusInFilter.value}
                    options={shippingStatus}
                    value={selectedStatusInFilter}
                    placeholder="Status"
                    styles={selectStyle}
                  />
                </div>
                <div className={styles.formSelect}>
                  <Select
                    onChange={handleFilterType}
                    options={status}
                    value={filterType}
                    isClearable={filterType.value}
                    placeholder="Filtrar por"
                    styles={selectStyle}
                  />
                </div>
                <div className={styles.formSelect}>
                  <Select
                    onChange={handleTypeShipping}
                    options={sendTypes}
                    value={type}
                    isClearable={type.value}
                    placeholder="Selecione o tipo de envio"
                    styles={selectStyle}
                  />
                </div>
              </div>
              <div className={styles.svgRow}>

                <ButtonExport type="button" onClick={handleExportSpreadsheet}>
                  <FiDownload />
                  <span>CSV</span>
                </ButtonExport>
                {initiateDownload && (
                  <CSVDownload data={shippingExportData} target="_self" />
                )}

                {isAdmin && (
                  <StyledTooltip
                    text="Objetos com erro no SARA"
                    position="top"
                    arrow
                  >
                    <div
                      className={styles.btnShippingsWithSaraError}
                      onClick={() => setShowShippingsWithSaraError(true)}
                    >
                      <SyncProblem />
                    </div>
                  </StyledTooltip>
                )}
                {isAdmin && (
                  <StyledTooltip
                    text="Prepostar todos os objetos"
                    position="top"
                    arrow
                  >
                    <div
                      className={styles.btnPrepostarAll}
                      onClick={() => setShowConfirmPrepostarAll(true)}
                    >
                      <ImWarning />
                    </div>
                  </StyledTooltip>
                )}
              </div>
            </div>
          </div>
          <ScrollDiv data={shippings}>
            <InfiniteScroll
              dataLength={shippings.length}
              next={handleFetchData}
              hasMore={hasMore}
              loader={loadingInfinit && <LoadInfiniteScroll />}
              scrollableTarget="scrollDiv"
              className={styles.infiniteScroll}
            >
              <div className={styles.tableContainer}>
                <table className={styles.table}>
                  <thead className={styles.tableHead}>
                    <tr>
                      {extraColumn && <th></th>}
                      <th>Status</th>
                      {type.value === "normal" ? (
                        <th>Código da etiqueta</th>
                      ) : (
                        <th>Autorização de postagem</th>
                      )}
                      <th>Pedido</th>
                      <th>Coleta</th>
                      <th>Data Criação</th>
                      {isAdmin && isDeleted && <th>Deletado em</th>}
                      <th>Previsão Entrega</th>
                      <th>Data Entrega</th>
                      {(isAdmin || isManager) && <th>Cliente</th>}
                      {type.value === "normal" ? (
                        <th>Destinatário</th>
                      ) : (
                        <th>Origem</th>
                      )}
                      {<th width="5%">Ações</th>}
                    </tr>
                  </thead>
                  <tbody className={styles.tableBody}>
                    {shippings.map(shipping => {
                      const status = Object.entries(ShippingStagesEnum).find(
                        st => st[1].id === shipping?.idShippingStatus
                      );
                      return (
                        <tr
                          key={shipping.id}
                          style={{
                            backgroundColor: handleBackGroundColor(shipping)
                          }}
                        >
                          {shipping.blocked ? (
                            <td>
                              <TooltipCard>
                                <TooltipText>
                                  <ImBlocked color="#CA1818" size={24} />
                                </TooltipText>
                                <TooltipBox>
                                  <div>
                                    Bloqueio de envio solicitado:{" "}
                                    {shipping.blockedDate}
                                  </div>
                                </TooltipBox>
                              </TooltipCard>
                            </td>
                          ) : shipping?.errorPLP ? (
                            <td style={{ backgroundColor: "F5CB2B" }}>
                              <TooltipCard>
                                <TooltipText>
                                  <ImWarning color="#F5CB2B" size={24} />
                                </TooltipText>
                                <TooltipBox>
                                  <div>
                                    {shipping?.prePostado === null
                                      ? "Pré-Postagem não realizada."
                                      : shipping.prePostadoObject?.mMsgErro ||
                                      "Erro ao realizar prepostagem, tente novamente"}
                                  </div>
                                </TooltipBox>
                              </TooltipCard>
                            </td>
                            // ) : shipping?.hasProcessCorreiosError ? (
                            //   <td>
                            //   <TooltipCard>
                            //     <TooltipText>
                            //       <ImWarning color="#ca1818" size={24} />
                            //     </TooltipText>
                            //     <TooltipBox>
                            //       <div>
                            //         Erro no processamento interno dos correios
                            //       </div>
                            //     </TooltipBox>
                            //   </TooltipCard>
                            // </td>
                          ) : (
                            extraColumn && <th></th>
                          )}
                          <td className={styles.thStatus}>
                            <div
                              style={{
                                borderRadius: "4px",
                                padding: "5px 4px",
                                backgroundColor: `${handleStatusColor(
                                  shipping?.idShippingStatus
                                )}`
                              }}
                            >
                              <span>{status[1]?.message}</span>
                            </div>
                          </td>
                          <td className={styles.thCodigoEtiqueta}>
                            {!isDeleted ? (
                              <a
                                href={`detalhes-objeto/${shipping.id}`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {type.value === "normal"
                                  ? shipping.sro
                                  : shipping.authorizationCode}
                              </a>
                            ) : (
                              <p>
                                {type.value === "normal"
                                  ? shipping.sro || "--"
                                  : shipping.authorizationCode || "--"}
                              </p>
                            )}
                          </td>
                          <td className={styles.thPedido}>
                            <p>{integrationToDisplayName?.includes(shipping?.originCreation) ? shipping?.name : shipping?.orderNumber || shipping?.name}</p>
                          </td>
                          <td className={styles.thCodigo}>
                            {!isDeleted ? (
                              <a
                                href={`detalhe-coleta/${shipping?.idCollect}`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <p>{shipping?.idCollect}</p>
                              </a>
                            ) : (
                              <p>{shipping?.idCollect || "--"}</p>
                            )}
                          </td>
                          <td className={styles.thCriado}>
                            <p>{shipping.created_at}</p>
                          </td>
                          {isAdmin && isDeleted && (
                            <td>
                              <p>{shipping.deleted_at}</p>
                            </td>
                          )}
                          <td className={styles.thPrevisao}>
                            <p>{shipping?.previewDate || "--"}</p>
                          </td>
                          <td className={styles.thEntrega}>
                            <p>{shipping.deliveredDate || "--"}</p>
                          </td>
                          {(isAdmin || isManager) && (
                            <td className={styles.thClient}>
                              <p>{shipping?.user?.userData?.[1].Value}</p>
                            </td>
                          )}
                          <td className={styles.thDestinatario}>
                            {type.value === "normal" ? (
                              <p>{shipping?.destination?.name}</p>
                            ) : (
                              <p>{shipping?.origin?.name}</p>
                            )}
                          </td>
                          {!isDeleted ? (
                            <td>
                              <ActionsMenu
                                shipping={shipping}
                                setFunction={setShippings}
                                setLoading={setLoading}
                                getShippingTracking={getShippingTracking}
                                selectedStatusInFilter={selectedStatusInFilter}
                                search={search}
                              />
                            </td>
                          ) :
                            <td>
                              <StyledTooltip
                                text={"Reverter deleção"}
                              >
                                <Undo
                                  style={{
                                    width: 20,
                                    height: 20,
                                    cursor: "pointer",
                                    color: "#409e6a"
                                  }}
                                  onClick={() =>
                                    handleRevertShippingCancel(shipping?.id)
                                  }
                                />
                              </StyledTooltip>
                            </td>
                          }
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </InfiniteScroll>
          </ScrollDiv>
        </div>
      </Container>
    </>
  );
};

export { Tracking };
