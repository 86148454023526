import React, { useCallback } from "react";
import Swal from "sweetalert2";
import { BrComerceApiService } from "../../../../services/BrComerceApiService";
import { ButtonsContainer, Container } from "./styles";

const CancelShipping = ({
  setShowCancelShippingModal,
  setLoading,
  shipping,
  setFunction
}) => {
  const api = new BrComerceApiService();

  const confirmCancelShipping = useCallback(async () => {
    try {
      setShowCancelShippingModal(false);
      setLoading(true);
      await api.makeHttpRequest({
        url: `/shipping/${shipping.id}/block`,
        method: "DELETE"
      });

      Swal.fire({
        title: "Sucesso",
        text: "A solicitação de bloqueio foi feita com sucesso",
        icon: "success",
        confirmButtonColor: "#409e6a",
        preConfirm() {
          setShowCancelShippingModal(false);
        }
      });

      setFunction(oldState => {
        if (Array.isArray(oldState)) {
          const newState = oldState.slice();

          const foundItem = newState.find(item => item.id === shipping.id);

          if (foundItem) {
            foundItem.blocked = true;
            foundItem.status.name = "Bloqueio Solicitado";
            foundItem.blockedDate = new Date();
          }

          return foundItem ? newState : oldState;
        }

        const newState = oldState["shippings"].slice();

        const foundItem = newState.find(item => item.id === shipping.id);

        if (foundItem) {
          foundItem.blocked = true;
          foundItem.status.name = "Bloqueio Solicitado";
          foundItem.blockedDate = new Date();
        }

        return { id: oldState.id, shippings: newState };
      });
    } catch (e) {
      console.log(e);
      Swal.fire({
        title: "Erro",
        text:
          "Um erro ocorreu ao tentar bloquear o o objeto"
          || e.response.data.message,
        icon: "error",
        confirmButtonColor: "#409e6a",
      });
    } finally {
      setShowCancelShippingModal(false);
      setLoading(false);
    }
  }, [api, setFunction, setLoading, setShowCancelShippingModal, shipping.id]);

  return (
    <Container>
      <h1>Suspensão de Entrega</h1>
      <p>
        A suspensão serve para tentar bloquear a entrega de uma encomenda ao
        destinatário e retornar o objeto ao remetente. Você pode usar quando
        perceber ter sido vítima de golpe ou se arrepender do envio por qualquer
        motivo.
      </p>
      <p>
        O valor da postagem não é ressarcido pela transportadora e a ação é
        irreversível. Além disso, a suspensão não é garantida. Significa que,
        após solicitada, ela será atendida sempre que viável operacional e
        tecnicamente para a transportadora.
      </p>
      <ButtonsContainer>
        <button onClick={() => setShowCancelShippingModal(false)}>
          Voltar
        </button>
        <button onClick={confirmCancelShipping}>Solicitar</button>
      </ButtonsContainer>
    </Container>
  );
};

export { CancelShipping };
