import React, { useCallback, useEffect, useState } from "react";

import { Link } from "react-router-dom";
import Select from "react-select";
import Swal from "sweetalert2";
import moment from 'moment';

import { BrComerceApiService } from "../../../../../services/BrComerceApiService";
import debounce from "../../../../../utils/debounce";
import Loading from "../../../../home/components/Loading";
import Container from "../../../components/Container";
import { StyledTooltip } from "../../../components/Tooltip";
import { OriginEnum } from "../../../../../enums/OriginEnum";
import store from "../../../../../store/store";
import { selectStyle } from "../../../../../components/InputSelectAsync";

import { FaCirclePlus } from "react-icons/fa6";
import {
  FiTrash2,
  FiChevronLeft,
  FiChevronRight,
  FiDownload
} from "react-icons/fi";

import styles from "./freightList.module.scss";

const selectPageStyle = {
  control: styles => ({
    ...styles,
    backgroundColor: "inherit",
    width: "100%",
    border: "none",
    boxShadow: "none",
    outline: "none",
    color: "#807E80",
    padding: "0",
    "&:hover": {
      borderColor: "none"
    },
    maxHeight: "48px",
    cursor: "pointer"
  }),
  container: styles => ({
    ...styles,
    minWidth: "56px",
    outline: "none",
    borderBottom: "1px solid #4D4D4D",
    padding: "0",
    cursor: "pointer"
  }),
  indicatorSeparator: styles => ({
    ...styles,
    display: "none",
    border: "none"
  }),
  option: (styles, { isSelected }) => ({
    ...styles,
    fontSize: "14px",
    fontFamily: "Mulish",
    fontWeight: 400,
    color: isSelected ? "#fff" : "#807E80",
    "&:hover": {
      backgroundColor: "#c1c1c1",
      color: "#color: #333333;",
      cursor: "pointer"
    },

    backgroundColor: isSelected ? "#409e6a" : "#fff"
  }),
  valueContainer: styles => ({
    ...styles,
    padding: "0"
  }),
  dropdownIndicator: styles => ({
    ...styles,
    padding: "0",
    color: "#4D4D4D"
  })
};

const FreightList = () => {
  const { auth } = store.getState();
  const { user } = auth;

  const api = new BrComerceApiService();

  const [initDownload, setInitDownload] = useState(false);

  const [tables, setTables] = useState([]);
  const [integrations, setIntegrations] = useState([]);
  const [integrationSelected, setIntegrationSelected] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [actualPage, setActualPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [resultsPages, setResultsPages] = useState({ label: "10", value: 10 });

  const [hasStatusProgress, setHasStatusProgress] = useState(false);

  const getAvailablesIntegrations = async subUser => {
    setIsLoading(true);

    const response = await api.makeHttpRequest({
      method: "GET",
      url: `/users/${subUser}/list-integrations`
    });

    if (response) {
      const arr = [];

      // eslint-disable-next-line array-callback-return
      response.map(res => {
        const integration = res.toLowerCase();
        if (integration.includes(OriginEnum.VNDA) || integration.includes(OriginEnum.VTEX)) {
          arr.push({
            label: res.charAt(0).toUpperCase() + res.slice(1).toLowerCase(),
            value: res
          });
        }
      });

      setIntegrations(arr);
      setIsLoading(false);
    }
  };


  const loadTables = useCallback(
    debounce(async params => {
      try {
        setIsLoading(true);
        const response = await api.makeHttpRequest({
          url: '/rates-sheet',
          params: {
            page: params?.page ?? 1,
            resultsPerPage: params?.resultsPages ?? 10,
            ...(origin && {origin: integrationSelected?.value})
          }
        });

        const result = response.results.some(res => !res.s3Path)
        setHasStatusProgress(result)

        setActualPage(response.actualPage);
        setTotalPages(response.nPages);
        setTables(response.results);
      } catch (e) {
        Swal.fire({
          text: "Erro ao carregar lista de tabelas",
          title: "Tabelas de frete",
          icon: "error"
        });
      } finally {
        setIsLoading(false);
      }
    }, 500),
    [tables, integrationSelected?.value]
  );

  const handleOnConfirmDelete = async id => {
    setIsLoading(true);
    await api
      .makeHttpRequest({
        method: "DELETE",
        url: `/rates-sheet/${id}`,
      })
      .then(response => {
        Swal.fire({
          title: "Sucesso!",
          text: "A tabela foi deletada com sucesso.",
          icon: "success",
          showCancelButton: false,
          confirmButtonText: "Ok"
        });

        loadTables({
          page: actualPage,
          resultsPages: resultsPages.value
        });
      })
      .catch(e => {
        Swal.fire({
          icon: "error",
          title: "Ocorreu um erro!",
          text: "Não foi possível deletar a tabela"
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleDeleteTable = async id => {
    Swal.fire({
      title: "Você tem certeza?",
      html:
        "Deseja prosseguir com a deleção da tabela?<br>Não será possível desfazer essa ação",
      icon: "warning",
      cancelButtonText: "Cancelar",
      showCancelButton: true,
      confirmButtonText: "Sim, fazer a deleção!"
    }).then(response => {
      if (response?.value) {
        handleOnConfirmDelete(id);
      }
    });
  };

  const handleDownloadTable = async (id) => {
    try {
      setIsLoading(true);
      const link = await api.makeHttpRequest({
        method: "GET",
        url: `/rates-sheet/${id}`
      });

      window.open(link.link, "_blank");
    } catch (e) {
      const msg = e?.response?.body?.data?.message;
      Swal.fire({
        title: "Erro!",
        text: msg || "Erro ao baixar arquivo",
        icon: "error"
      });
    } finally {
      setIsLoading(false);
    }
  }

  const handleFormatOrigin = origin => {
    const formatOrigin = origin.toUpperCase();
    return OriginEnum[formatOrigin].toUpperCase();
  }

  const onPage = newPage => {
    setActualPage(newPage - 1);
  };

  const onNext = () => {
    setIsLoading(true);
    onPage(actualPage + 1);
    setActualPage(actualPage + 1);
  };

  const onPrevious = () => {
    setIsLoading(true);
    onPage(actualPage - 1);
    setActualPage(actualPage - 1);
  };

  const perPageOptions = [
    {
      label: "10",
      value: 10
    },
    {
      label: "15",
      value: 15
    },
    {
      label: "20",
      value: 20
    },
    {
      label: "50",
      value: 50
    },
    {
      label: "100",
      value: 100
    }
  ];

  useEffect(() => {
    loadTables({
      page: actualPage,
      resultsPages: resultsPages.value
    });
  }, [actualPage, resultsPages, integrationSelected?.value]);


  useEffect(() => {
    if (hasStatusProgress) {
      loadTables({
       page: actualPage,
        resultsPages: resultsPages.value
      });

      const interval = setInterval(() => {
        loadTables({
          page: actualPage,
          resultsPages: resultsPages.value
        })
      }, 60000);

      return () => clearInterval(interval);
    }
  }, [actualPage, resultsPages, hasStatusProgress]);

  useEffect(() => {
    window.setPageTitle("Tabelas de Frete");
  }, []);

  useEffect(() => {
    getAvailablesIntegrations(user.sub);
  }, [user]);

  useEffect(() => {
    // Enables user to download data more than once
    if (initDownload) {
      setInitDownload(false);
    }
  }, [initDownload]);

  return (
    <>
      <Loading isLoading={isLoading} />
      <Container title="Tabelas de Frete">
        <div className={styles.contentBody}>
          <div className={styles.filters}>
            <div className={styles.filterInput}>
              <Select
                options={integrations}
                value={integrationSelected}
                isClearable
                placeholder="Selecionar integração"
                styles={selectStyle}
                onChange={setIntegrationSelected}
                />
            </div>
            <Link to="/tabelas-frete/create">
              <button type="button" className={styles.btnAddTable}>
                <FaCirclePlus fill="#fff" color="#fff" />
                <span>Criar nova tabela</span>
              </button>
            </Link>
          </div>
          <div className={styles.contentTable}>
            <table className={styles.tableContainer}>
              <thead>
                <tr>
                  <th width="10%">Indentificação</th>
                  <th width="10%">Integração</th>
                  <th width="10%">Status</th>
                  <th width="10%">Data de criação</th>
                  <th width="3%">Ações</th>
                </tr>
              </thead>
              <tbody>
                {!!tables.length &&
                  tables.map(table => {
                    const s3Path = table.s3Path;

                    return (
                      <tr key={table.id}>
                        <td>{table.name}</td>
                        <td>{handleFormatOrigin(table.origin)}</td>
                        <td>
                          <span
                              style={{
                                borderRadius: "4px",
                                padding: "5px 4px",
                                backgroundColor: !!s3Path ? "#5BC289" : "#F5CB2B",
                                fontWeight: 700
                              }}
                            >
                              {!!s3Path ? "Concluido" : "Em processo"}
                            </span>
                        </td>
                        <td>
                          {moment(table.created_at).format('DD/MM/YYYY')}
                        </td>
                        <td className={styles.tooltipWrapper}>
                          {/* <StyledTooltip text="Reeviar" position="top" arrow>
                            <div>
                              <PiArrowCounterClockwiseFill
                                onClick={() =>
                                 handleReSendTable(table.id)
                                }
                                style={{
                                  cursor: "pointer",
                                  marginRight: "5px"
                                }}
                                size={20}
                                color="#409E6A"
                              />
                            </div>
                          </StyledTooltip> */}
                          <StyledTooltip text="Fazer download" position="top" arrow>
                            <div>
                              <FiDownload
                                onClick={() => !!s3Path ? handleDownloadTable(table.id) : ""}
                                style={{
                                  cursor: !!s3Path ? "pointer" : "default",
                                  marginRight: "5px"
                                }}
                                size={20}
                                color={!!s3Path ? "#409E6A" : "#B3B3B3"}
                              />
                            </div>
                          </StyledTooltip>
                          <StyledTooltip text="Deletar" position="top" arrow>
                            <div>
                              <FiTrash2
                                onClick={() => !!s3Path ? handleDeleteTable(table.id) : ""}
                                style={{
                                  cursor: !!s3Path ? "pointer" : "default"
                                }}
                                size={20}
                                color={!!s3Path ? "#409E6A" : "#B3B3B3"}
                              />
                            </div>
                          </StyledTooltip>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
            <div className={styles.paginationContainer}>
              <div className={styles.perPageContent}>
                <span>Itens por página:</span>
                <Select
                  styles={selectPageStyle}
                  value={resultsPages}
                  options={perPageOptions}
                  onChange={e => setResultsPages(e)}
                  menuPlacement="top"
                />
              </div>
              <h6>
                {actualPage} - {resultsPages.value} de {totalPages}
              </h6>
              <div className={styles.controlPage}>
                <FiChevronLeft
                  size={25}
                  onClick={actualPage > 1 ? onPrevious : ""}
                  color={actualPage > 1 ? "#409E6A" : "#2D724C"}
                  className="arrow"
                />

                <FiChevronRight
                  size={25}
                  onClick={actualPage < totalPages ? onNext : ""}
                  color={actualPage < totalPages ? "#409E6A" : "#2D724C"}
                  className="arrow"
                />
              </div>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export { FreightList };
