export function formatMoney(value) {
  let valueNumber = value?.replace(/\D/g, "");

  valueNumber = valueNumber?.padStart(3, "0");
  valueNumber = valueNumber?.substr(0, 1) === "0" && valueNumber?.length > 4 ? valueNumber?.replace(/\b0+/g, '') : valueNumber

  const inputLength = valueNumber?.length - 2

  const milhares = Math.floor(inputLength / 3) // 2

  let groups = "(\\d{3})".repeat(milhares)

  const resto = inputLength % 3

  groups = resto ? `(\\d{${resto}})` + groups : groups

  const groupsNumber = milhares + (resto ? 1 : 0) + 1;

  let ordem = ""

  for (let index = 1; index <= groupsNumber; index++) {

    if (index === 1) {
      ordem = ordem + `$${index}`
    } else if (index === groupsNumber) {
      ordem = ordem + `,$${index}`
    } else {
      ordem = ordem + `.$${index}`
    }
  }

  const regex = new RegExp(`${groups}(\\d{2})`);
  valueNumber = valueNumber?.replace(regex, ordem);

  return valueNumber;
};
