import React, { useCallback, useState, useEffect } from "react";
import { Redirect, Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import queryString from "querystring";
import ReCAPTCHA from "react-google-recaptcha";
import TagManager from "react-gtm-module";
import { isValidNewPassword } from "../../../utils/validators/isValidYup";

import { actionTypes } from "../../../store/ducks/auth.duck";
import Button from "../../../components/Button";
import { BrComerceApiService } from "../../../services/BrComerceApiService";
import useGetWindowWidth from "../../../utils/useGetWindowWidth";
import windowSize from "../../../constants/WindowSize";
import { useMessage } from "../components/Message";
import logoLight from "../../../assets/logo/logo-brcomerce.png";

import Input from "../components/Input";
import useDocumentTitle from "../../../utils/useDocumentTitle";

import {
  Container,
  Body,
  ContainerInputs,
  ContainerReCaptcha,
  ContainerWithIcon
} from "./styles";
import Header from "../../../components/Header";

const RecoverData = ({ match, history }) => {
  useDocumentTitle("Brcomerce - Cadastro de Senha");
  const [tokenReCaptcha, setTokenReCaptcha] = useState(null);
  const { Message, handleShowError } = useMessage(8);
  const [typeMessage, setTypeMessage] = useState("error");
  const [messageError, setMessageError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isVisibleForm, setIsVisibleForm] = useState(true);
  const width = useGetWindowWidth();
  const dispath = useDispatch();
  const hasToken = queryString.parse(window.location.search.replace("?", "")).t;
  const token = window.location.search.replace("?t=", "");
  const { path } = match;
  const [messages, setMessage] = useState(() => {
    switch (path) {
      case "/ativar-conta":
        return {
          title: "Seu email foi confirmado!",
          subtitle: "Sua conta está pronta para ser utilizada",
          button: "Entrar"
        };
      case "/recuperar-senha":
        return {
          title: "Definir nova senha",
          subtitle: "Crie uma nova senha para sua conta",
          button: "Definir senha"
        };
      case "/cadastrar-senha":
        return {
          title: "Definir senha",
          subtitle: "Agora, crie sua senha para acesso",
          button: "Cadastrar senha"
        };
      default:
        break;
    }
  });

  const formik = useFormik({
    initialValues: {
      password: "",
      confirmePassword: ""
    },
    validationSchema: isValidNewPassword,
    onSubmit: (values, { setSubmitting }) => {
      handleSubmit(values);
      setSubmitting(false);
    }
  });

  const activeAccount = useCallback(async () => {
    try {
      const api = new BrComerceApiService();

      await api.makeHttpRequest({
        url: "/requisicaotemporaria",
        method: "POST",
        data: { token }
      });

      const tagManagerArgs = {
        gtmId: process.env.REACT_APP_GTM_ID ? process.env.REACT_APP_GTM_ID : ""
      };

      TagManager.initialize(tagManagerArgs);
    } catch (error) {
      setMessage({
        title: "Erro ao ativar conta",
        subtitle: "Entre em contato com o administrador",
        button: "Entrar",
        error: true
      });
    }
  }, [token]);

  const logOutSession = useCallback(() => {
    dispath({
      type: actionTypes.Logout
    });
  }, [dispath]);

  const handleSubmit = useCallback(
    async values => {
      if (tokenReCaptcha === null && path !== "/ativar-conta") {
        setMessageError("Clique em (Não sou um robô)");
        handleShowError();
        return;
      }

      try {
        const api = new BrComerceApiService();
        setIsLoading(true);
        await api.makeHttpRequest({
          url: "/requisicaotemporaria",
          method: "POST",
          data: { token, password: values.password }
        });

        if (path === "/recuperar-senha") {
          setMessageError("Senha alterado com sucesso!");
          setTypeMessage("success");
          setIsLoading(false);
          handleShowError();

          logOutSession();

          setTimeout(() => {
            history.push("/auth");
          }, 6000);
          return;
        }

        setIsVisibleForm(false);
        setMessage({
          title: "Seu email foi confirmado!",
          subtitle: "Sua conta está pronta para ser utilizada",
          button: "Entrar"
        });
        setIsLoading(false);
      } catch (error) {
        setMessageError(
          error?.response?.data || "Erro interno, tente mais tarde"
        );
        handleShowError();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [tokenReCaptcha]
  );

  useEffect(() => {
    if (path === "/ativar-conta") {
      activeAccount();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path]);

  return (
    <>
      {!hasToken && <Redirect to="/entrar" />}
      <Container>
        {width < windowSize.SM && <Header />}
        <Body width={width}>
          {width > windowSize.SM && (
            <div style={{ width: "100%", marginBottom: 10 }}>
              <Header />
            </div>
          )}

          <ContainerWithIcon width={width}>
            {width >= windowSize.SM && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                <Link to="/home">
                  <img
                    alt="logo Devaria"
                    src={logoLight}
                    style={{ width: 280, marginBottom: 66 }}
                  />
                </Link>
              </div>
            )}

            <ContainerInputs error={messages.error}>
              {width >= windowSize.SM ? (
                <h3>{messages.title}</h3>
              ) : (
                <h1>{messages.title}</h1>
              )}
              <p>{messages.subtitle}</p>

              <Message message={messageError} type={typeMessage} />

              {path !== "/ativar-conta" && isVisibleForm && (
                <form onSubmit={formik.handleSubmit}>
                  <Input
                    placeholder="Senha"
                    type="password"
                    icon="flaticon2-lock"
                    name="password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    isError={
                      formik.touched?.password && formik.errors?.password
                    }
                    messageError={formik.errors?.password}
                  />
                  <Input
                    placeholder="Confirme sua senha"
                    type="password"
                    icon="flaticon2-lock"
                    name="confirmePassword"
                    value={formik.values.confirmePassword}
                    onChange={formik.handleChange}
                    isError={
                      formik.touched?.confirmePassword &&
                      formik.errors?.confirmePassword
                    }
                    messageError={formik.errors?.confirmePassword}
                  />
                  <ContainerReCaptcha>
                    <ReCAPTCHA
                      sitekey={process.env.REACT_APP_SITE_KEY_RECAPTCHA}
                      onChange={value => {
                        setTokenReCaptcha(value);
                      }}
                    />
                  </ContainerReCaptcha>
                  <Button
                    text={messages.button}
                    disabled={
                      (!formik.values?.password ||
                        !formik.values?.confirmePassword ||
                        tokenReCaptcha === null) &&
                      true
                    }
                    opacity={
                      (!formik.values?.password ||
                        !formik.values?.confirmePassword ||
                        tokenReCaptcha === null) &&
                      0.5
                    }
                    cursor={
                      (!formik.values?.password ||
                        !formik.values?.confirmePassword ||
                        tokenReCaptcha === null) &&
                      "not-allowed"
                    }
                    loading={isLoading}
                    type="submit"
                  />
                </form>
              )}
              {((!messages?.error && path === "/ativar-conta") ||
                !isVisibleForm) && (
                <>
                  <Button
                    text={"Login"}
                    opacity={1}
                    cursor={"pointer"}
                    loading={isLoading}
                    type="button"
                    onClick={() => {
                      history.push("/Logout");
                    }}
                  />
                  <Link to="/home">Voltar para Home</Link>
                </>
              )}
            </ContainerInputs>
          </ContainerWithIcon>
        </Body>
      </Container>
    </>
  );
};

export default RecoverData;
