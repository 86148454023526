import React, { useState, useCallback } from "react";
import styles from "./styles.module.scss";
import { FiUser } from "react-icons/fi";
import { FaRegBuilding } from "react-icons/fa";
import { useHistory } from "react-router-dom";

const Registration = () => {
  const [selection, setSelection] = useState("pf");
  const [hovering, setHovering] = useState(false);

  const history = useHistory();

  const handleCustomerSelection = useCallback(() => {
    setSelection("pf");
    history.push("/auth/Registration/pf");
  }, [history]);

  const handlePartnerSelection = useCallback(() => {
    setSelection("pj");
    history.push("/auth/Registration/pj");
  }, [history]);

  return (
    <div className={styles.container}>
      <div className={styles.switchContainer}>
        <div>
          <div className={styles.switchTitle}>Faça seu cadastro</div>
          <div className={styles.switchContent}>
            <div className={styles.switchSubTitle}>
              <span>Escolha uma das opções abaixo:</span>
            </div>
            <div className={styles.switch}>
              <div
                className={`${styles.switchLeft}  ${
                  selection === "pf" ? styles.check : ""
                }`}
                onClick={handleCustomerSelection}
                style={{
                  backgroundColor: hovering ? "white" : "",
                  color: hovering ? "#409e6a" : ""
                }}
              >
                <div className={styles.switchOptLogo}>
                  <FiUser />
                </div>
                <div className={styles.switchOptTitle}>
                  <p>Para Você</p>
                </div>
                <div className={styles.switchOptDesc}>
                  <p>Desejo me cadastrar utilizando o CPF</p>
                </div>
              </div>
              <div
                className={`${styles.switchRight}  ${
                  selection === "pj" ? styles.check : ""
                }`}
                onClick={handlePartnerSelection}
                onMouseEnter={() => setHovering(true)}
                onMouseLeave={() => setHovering(false)}
              >
                <div className={styles.switchOptLogo}>
                  <FaRegBuilding />
                </div>
                <div className={styles.switchOptTitle}>
                  <p>Para Empresas</p>
                </div>
                <div className={styles.switchOptDesc}>
                  <p>Desejo me cadastrar utilizando o CNPJ</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.signInRedirect}>
          <p>
            Já possui uma conta? &nbsp;
            <span onClick={() => history.push("/auth/login")}>
              Faça o seu login
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Registration;
