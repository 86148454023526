import React, { useCallback, useState } from "react";
import { Link } from "react-router-dom";
import { ReactComponent as CalendarTime } from "../../assets/icons/calendar-time.svg";
import { ReactComponent as ChatError } from "../../assets/icons/chat-error.svg";
import { ReactComponent as Dashboard } from "../../assets/icons/dashboard.svg";
import { ReactComponent as DollarSign } from "../../assets/icons/dollar-sign.svg";
import { ReactComponent as IntegrationTable } from "../../assets/icons/integration-table.svg";
import { ReactComponent as Integration } from "../../assets/icons/integration.svg";
import { ReactComponent as Management } from "../../assets/icons/management.svg";
import { ReactComponent as MapPin } from "../../assets/icons/map-pin.svg";
import { ReactComponent as Map } from "../../assets/icons/map.svg";
import { ReactComponent as Marker } from "../../assets/icons/marker.svg";
import { ReactComponent as MyRequest } from "../../assets/icons/my-request.svg";
import { ReactComponent as Note } from "../../assets/icons/note.svg";
import { ReactComponent as OwnDelivery } from "../../assets/icons/own-delivery.svg";
import { ReactComponent as Package } from "../../assets/icons/package.svg";
import { ReactComponent as PersonInfo } from "../../assets/icons/person-info.svg";
import { ReactComponent as PigMoney } from "../../assets/icons/pig-money.svg";
import { ReactComponent as Reverse } from "../../assets/icons/reverse.svg";
import { ReactComponent as Send } from "../../assets/icons/send.svg";
import { ReactComponent as ShoppingCart } from "../../assets/icons/shopping-cart.svg";
import { ReactComponent as SlideSearch } from "../../assets/icons/slide-search.svg";
import { ReactComponent as User } from "../../assets/icons/user.svg";

import { FaFileExcel, FaRegFileExcel } from "react-icons/fa";
import { FiChevronDown } from "react-icons/fi";
import { IoIosList } from "react-icons/io";
import { PiGear, PiSteeringWheel } from "react-icons/pi";

import logo from "../../assets/logo/logo-brcomerce-white.png";
import { CanImportTrackingShipingsUserIds } from "../../enums/CanImportTrackingShipingsUserIds";
import store from "../../store/store";
import styles from "./styles.module.scss";
import { IntegrationInstructions } from "@mui/icons-material";

const Sidebar = ({ toggleMobile, mobileBarOpen }) => {
  const { auth } = store.getState();
  const { user } = auth;
  const actualLocation = window.location.href;
  const userCanCollect = user?.canCreateCollectSchedule;
  const userIsAdmin = user?.profile === "admin";
  const canImportTrackingShippings = CanImportTrackingShipingsUserIds.includes(user.id) || userIsAdmin;
  const userHasCredtLimit = Number(user?.creditLimit) > 0;
  const [menuSelected, setMenuSelected] = useState([]);

  const handleSelectedMenu = useCallback(
    menu => {
      const find = menuSelected.find(selected => selected === menu);
      if (find) {
        const newSelecteds = menuSelected.filter(selected => selected !== menu);
        setMenuSelected([...newSelecteds]);
      } else {
        setMenuSelected([...menuSelected, menu]);
      }
    },
    [menuSelected]
  );

  return (
    <>
      <div className={`${styles.container} ${styles[mobileBarOpen ? "aberto" : "fechado"]}`}>
        <div className={styles.logo}>
          <Link to="/nova-coleta/normal-send-form">
            <img src={logo} alt="BrCommerce" />
          </Link>
        </div>
        <div className={styles.menuList}>
          <Link
            to="/dashboard"
            className={`${styles.menuItem} ${actualLocation.includes("/dashboard") ? styles.activeItem : ""
              }`}
          >
            <Dashboard style={{ width: "18px", height: "18px" }} />
            <p>Dashboard</p>
          </Link>

          <ul>
            <li id="new-shipping">
              <Link
                to="#"
                className={`${styles.menuItem} ${styles.menuDropdown}`}
                onClick={() => handleSelectedMenu("new-shipping")}
              >
                <div className={styles.contentLabel}>
                  <Package style={{ width: "24px", height: "24px" }} />
                  <p>Novo Envio</p>
                </div>
                <FiChevronDown style={{ width: "15px", height: "15px" }} />
              </Link>
              <div
                className={`${styles.menuHidden} ${menuSelected.includes("new-shipping") ? canImportTrackingShippings ? styles.menuShowPlus : styles.menuShow : ""
                  }`}
              >
                <div>
                  <Link
                    to="/nova-coleta/normal-send-form"
                    className={`${styles.menuItem} ${actualLocation.includes("nova-coleta/normal")
                      ? styles.activeItem
                      : ""
                      }`}
                  >
                    <Send style={{ width: "18px", height: "18px" }} />
                    <p>Envio normal</p>
                  </Link>
                </div>
                <div>
                  <Link
                    to="/nova-coleta/reverse-email-form"
                    className={`${styles.menuItem} ${actualLocation.includes("nova-coleta/reverse")
                      ? styles.activeItem
                      : ""
                      }`}
                  >
                    <Reverse style={{ width: "18px", height: "18px" }} />
                    <p>Envio reverso</p>
                  </Link>
                </div>
                <div>
                  <Link
                    to="/nova-coleta/import"
                    className={`${styles.menuItem} ${actualLocation.includes("nova-coleta/import")
                      ? styles.activeItem
                      : ""
                      }`}
                  >
                    <FaRegFileExcel />
                    <p>Importar</p>
                  </Link>
                </div>
                {canImportTrackingShippings &&
                  <div>
                    <Link
                      to="/nova-coleta/tracking-import-shipping"
                      className={`${styles.menuItem} ${actualLocation.includes("/nova-coleta/tracking-import-shipping")
                        ? styles.activeItem
                        : ""
                        }`}
                    >
                      <FaFileExcel />
                      <p>Importar para Rastreio</p>
                    </Link>
                  </div>
                }
              </div>
            </li>
          </ul>

          <Link
            to="/carrinho"
            className={`${styles.menuItem} ${actualLocation.includes("/carrinho") ? styles.activeItem : ""
              }`}
          >
            <ShoppingCart />
            <p>Meu Carrinho</p>
          </Link>
          <Link
            to="/minhas-coletas"
            className={`${styles.menuItem} ${actualLocation.includes("/minhas-coletas") ? styles.activeItem : ""
              }`}
          >
            <MyRequest />
            <p>Meus Pedidos</p>
          </Link>

          <Link
            to="/rastrear-encomenda"
            className={`${styles.menuItem} ${actualLocation.includes("/rastrear-encomenda")
              ? styles.activeItem
              : ""
              }`}
          >
            <MapPin />
            <p>Rastreamento</p>
          </Link>

          <Link
            to="/conciliacao"
            className={`${styles.menuItem} ${actualLocation.includes("/conciliacao") ? styles.activeItem : ""
              }`}
          >
            <Note />
            <p>Conciliação</p>
          </Link>
          {userIsAdmin && (
            <ul>
              <Link
                to="/pedidos-de-informacao"
                className={`${styles.menuItem} ${actualLocation.includes("/pedidos-de-informacao")
                  ? styles.activeItem
                  : ""
                  }`}
              >
                <PersonInfo style={{ width: "26px", height: "26px" }} />
                <p>Pedidos de Informação</p>
              </Link>

              <li id="own_delivery">
                <Link
                  to="#"
                  className={`${styles.menuItem} ${styles.menuDropdown}`}
                  onClick={() => handleSelectedMenu("own_delivery")}
                >
                  <div className={styles.contentLabel}>
                    <OwnDelivery />
                    <p>Entrega Própria</p>
                  </div>
                  <FiChevronDown style={{ width: "15px", height: "15px" }} />
                </Link>
                <div
                  className={`${styles.menuHidden} ${menuSelected.includes("own_delivery") ? styles.menuShowPlus : ""}`}
                >
                  <div>
                    <div>
                      <Link
                        to="/rastrear-motorista"
                        className={`${styles.menuItem} ${actualLocation.includes("/rastrear-motorista")
                          ? styles.activeItem
                          : ""
                          }`}
                      >
                        <Map />
                        <p>Motorista</p>
                      </Link>
                    </div>
                    <div>
                      <Link
                        to="/listagem-rotas"
                        className={`${styles.menuItem} ${actualLocation.includes("/listagem-rotas")
                          ? styles.activeItem
                          : ""
                          }`}
                      >
                        <OwnDelivery />
                        <p>Rotas</p>
                      </Link>
                    </div>
                    <div>
                      <Link
                        to="/adicionar-rota"
                        className={`${styles.menuItem} ${actualLocation.includes("/adicionar-rota")
                          ? styles.activeItem
                          : ""
                          }`}
                      >
                        <Marker />
                        <p>Criar rota</p>
                      </Link>
                    </div>
                    <div>
                      <Link
                        to="/super-expresso"
                        className={`${styles.menuItem} ${actualLocation.includes("/super-expresso") ? styles.activeItem : ""
                          }`}
                      >
                        <IoIosList />
                        <p>Gestão de CEPs</p>
                      </Link>
                    </div>
                  </div>
                </div>
              </li>

              <li id="prepost">
                <Link
                  to="#"
                  className={`${styles.menuItem} ${styles.menuDropdown}`}
                  onClick={() => handleSelectedMenu("prepost")}
                >
                  <div className={styles.contentLabel}>
                    <CalendarTime />
                    <p>Pré-Postagem</p>
                  </div>
                  <FiChevronDown style={{ width: "15px", height: "15px" }} />
                </Link>
                <div
                  className={`${styles.menuHidden} ${menuSelected.includes("prepost") ? styles.menuShow : ""
                    }`}
                  style={{ maxHeight: "115px" }}
                >
                  <div>
                    <Link
                      to="/pedidos-sem-plp"
                      className={`${styles.menuItem} ${actualLocation.includes("pedidos-sem-plp")
                        ? styles.activeItem
                        : ""
                        }`}
                    >
                      <ChatError />
                      <p>Ausentes de PLP</p>
                    </Link>
                  </div>
                  <div>
                    <Link
                      to="/plps"
                      className={`${styles.menuItem} ${styles.menuFollowUp} ${actualLocation.includes("plps") ? styles.activeItem : ""
                        }`}
                    >
                      <SlideSearch />
                      <p>Acompanhamento</p>
                    </Link>
                  </div>
                </div>
              </li>
            </ul>
          )}

          {(userIsAdmin || (!userCanCollect && userHasCredtLimit)) && (
            <Link
              to="/faturamento"
              className={`${styles.menuItem} ${actualLocation.includes("/faturamento") ? styles.activeItem : ""
                }`}
            >
              <PigMoney style={{ width: "24px", height: "24px" }} />
              <p>Faturamento</p>
            </Link>
          )}

          {userIsAdmin && (
            <Link
              to="/politica-de-preco"
              className={`${styles.menuItem} ${actualLocation.includes("/politica-de-preco")
                ? styles.activeItem
                : ""
                }`}
            >
              <DollarSign />
              <p>Política de Preço</p>
            </Link>
          )}

          <ul>
            <li id="integration">
              <Link
                to="#"
                className={`${styles.menuItem} ${styles.menuDropdown}`}
                onClick={() => handleSelectedMenu("integration")}
              >
                <div className={styles.contentLabel}>
                  <Integration />
                  <p>Integrações</p>
                </div>
                <FiChevronDown style={{ width: "15px", height: "15px" }} />
              </Link>
              <div
                className={`${styles.menuHidden} ${menuSelected.includes("integration") ? styles.menuShow : ""
                  }`}
                style={{ maxHeight: "115px" }}
              >
                <div>
                  <Link
                    to="/integracoes"
                    className={`${styles.menuItem} ${actualLocation.includes("integracoes")
                      ? styles.activeItem
                      : ""
                      }`}
                  >
                    <PiGear />
                    <p>Configuração</p>
                  </Link>
                </div>
                <div>
                  <Link
                    to="/tabelas-frete"
                    className={`${styles.menuItem} ${actualLocation.includes("tabelas-frete")
                      ? styles.activeItem
                      : ""
                      }`}
                  >
                    <IntegrationTable />
                    <p>Tabelas</p>
                  </Link>
                </div>
              </div>
            </li>
          </ul>


          {userIsAdmin && (
            <ul>
              <li id="management">
                <Link
                  to="#"
                  className={`${styles.menuItem} ${styles.menuDropdown}`}
                  onClick={() => handleSelectedMenu("management")}
                >
                  <div className={styles.contentLabel}>
                    <Management />
                    <p>Gestão</p>
                  </div>
                  <FiChevronDown style={{ width: "15px", height: "15px" }} />
                </Link>
                <div
                  className={`${styles.menuHidden} ${menuSelected.includes("management") ? styles.menuShow : ""
                    }`}
                >
                  <Link
                    to="/users"
                    className={`${styles.menuItem} ${actualLocation.includes("/users") ? styles.activeItem : ""
                      }`}
                  >
                    <User />
                    <p>Usuários</p>
                  </Link>
                  <Link
                    to="/gerenciar-integracoes"
                    className={`${styles.menuItem} ${actualLocation.includes("gerenciar-integracoes")
                      ? styles.activeItem
                      : ""
                      }`}
                  >
                    <IntegrationInstructions />
                    <p>Gerenciar Integrações</p>
                  </Link>
                  <Link
                    to="/motoristas"
                    className={`${styles.menuItem} ${actualLocation.includes("/motoristas") ? styles.activeItem : ""
                      }`}
                  >
                    <PiSteeringWheel />
                    <p>Motoristas</p>
                  </Link>
                </div>
              </li>
            </ul>
          )}
        </div>
      </div>
      {mobileBarOpen && <div className={styles.tapToClose} onClick={() => toggleMobile(!mobileBarOpen)} />}
    </>
  );
};

export { Sidebar };

