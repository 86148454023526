import moment from "moment";
import React, { useCallback, useEffect, useState, useRef } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { AiOutlineWarning } from "react-icons/ai";
import { FiSquare } from "react-icons/fi";
import { MdClear, MdContentCopy } from "react-icons/md";
import { RiMoneyDollarCircleFill } from "react-icons/ri";
import { PiNoteFill } from "react-icons/pi";
import { FaUser } from "react-icons/fa";
import { TbSearch } from "react-icons/tb";
import { LuFileDown } from "react-icons/lu";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { FaCheckToSlot } from "react-icons/fa6";
import ReactModal from "react-modal";
import { useParams } from "react-router-dom";
import { PulseLoader } from "react-spinners";
import Swal from "sweetalert2";
import { useReactToPrint } from "react-to-print";

import { Loading } from "../../../../../components/Loading";
import {
  default as CollectStagesEnum,
  default as collectStagesEnum
} from "../../../../../enums/CollectStagesEnum";
import PaymentMethodEnum from "../../../../../enums/PaymentMethodEnum";
import ProfileEnum from "../../../../../enums/ProfileEnum";
import { RateTypeShippingEnum } from "../../../../../enums/RateTypeShippingEnum";
import ShippingStagesEnum from "../../../../../enums/ShippingStagesEnum";
import { BrComerceApiService } from "../../../../../services/BrComerceApiService";
import * as authDuck from "../../../../../store/ducks/auth.duck";
import store from "../../../../../store/store";
import isQrCodeExpired from "../../../../../utils/ExpireTimePix";
import debounce from "../../../../../utils/debounce";
import useGetWindowWidth from "../../../../../utils/useGetWindowWidth";
import { CartCardShipping } from "../../../components/CartCardShipping";
import { DateFilter } from "../../../components/DateFilter";
import RetryPayment from "../../../components/RetryPayment";
import { ModalButtonsContainer, ModalContainer } from "../CollectDetail/styles";
import styles from "./styles.module.scss";

const CollectDetail = () => {
  window.setPageTitle("Detalhes do Pedido");

  const componentRef = useRef(null);
  const promiseResolveRef = useRef(null);
  const api = new BrComerceApiService();
  const params = useParams();
  const { auth } = store.getState();
  const { user } = auth;
  const widthWindows = useGetWindowWidth();
  const [collectStage, setCollectStage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [collect, setCollect] = useState([]);
  const [packagesFiltered, setPackagesFiltered] = useState([]);
  const [searchStringCollect, setSearchStringCollect] = useState("");
  const [packagesSelectedId, setPackagesSelectedId] = useState([]);
  const [isSelectedAllCartItems, setIsSelectedAllCartItems] = useState(true);
  const [packagesExpand, setPackagesExpand] = useState([]);
  const [showScheduleModal, setShowScheduleModal] = useState(false);
  const [goToPayment, setGoToPayment] = useState(false);
  const [qrCodeData, setQrCodeData] = useState({});
  const [invoiceMethod, setInvoiceMethod] = useState(null);
  const [isQrCodeCopied, setIsQrCodeCopied] = useState(false);
  const [cardInfo, setCardInfo] = useState(null);
  const [cancelScheduleModal, setCancelScheduleModal] = useState(false);
  const [printTagsWarningModal, setPrintTagsWarningModal] = useState(false);
  const [visible, setVisible] = useState(false);

  const invoiceValue = Number(collect?.invoice?.value) || 0.0;
  const walletDiscount = Number(collect?.invoice?.walletDiscount) || 0.0;
  const initialValue = invoiceValue + walletDiscount;
  const isAdmin = user.profile === ProfileEnum.ADMIN.value;

  const handleClearSearchFilter = () => {
    setSearchStringCollect("");
  };

  const handlePackageSelection = useCallback(
    packId => {
      const findedPackage = packagesSelectedId.find(id => id === packId);

      if (findedPackage) {
        setPackagesSelectedId(prevState =>
          prevState.filter(id => id !== packId)
        );
      } else {
        setPackagesSelectedId(prevState => [...prevState, packId]);
      }
    },
    [packagesSelectedId]
  );

  const handleRemoveShipping = useCallback(
    id => {
      const newAllPackages = packagesFiltered.map(p => {
        if (p.id === id) {
          return {
            ...p,
            idShippingStatus: ShippingStagesEnum.OBJETO_CANCELADO.id
          };
        }

        return p;
      });

      if (
        !newAllPackages.find(
          p => p.idShippingStatus !== ShippingStagesEnum.OBJETO_CANCELADO.id
        )
      ) {
        setCollectStage(CollectStagesEnum.COLETA.COLETA_CANCELADA.id);
      }

      setPackagesFiltered(newAllPackages);
    },
    [packagesFiltered]
  );

  const handleToggleCheckAllCartItems = useCallback(() => {
    if (isSelectedAllCartItems) {
      setIsSelectedAllCartItems(false);
      setPackagesSelectedId([]);
      return;
    }

    setIsSelectedAllCartItems(true);
    setPackagesSelectedId(
      packagesFiltered
        .filter(
          p => p.idShippingStatus !== ShippingStagesEnum.OBJETO_CANCELADO.id
        )
        .map(s => s.id)
    );
  }, [isSelectedAllCartItems, packagesFiltered]);

  const handlePrintTags = useCallback(
    async (collect, confirmPrint = false) => {
      const shippings = packagesSelectedId.map(pack => {
        return pack;
      });

      const warningOnPrintTags = !collect?.shippings?.some(
        shipping => shipping.numeroPLP
      );

      if (
        warningOnPrintTags &&
        !printTagsWarningModal &&
        !confirmPrint &&
        !user?.canCreateCollectSchedule
      ) {
        setPrintTagsWarningModal(true);
        return;
      }

      if (confirmPrint) {
        setPrintTagsWarningModal(false);
      }

      try {
        setLoading(true);

        window.localStorage.setItem(
          "Brcom:Etiquetas",
          JSON.stringify(shippings)
        );

        window.localStorage.setItem(
          "Brcom:EtiquetasCollectId",
          JSON.stringify(collect.id)
        );

        const win = window.open("/etiquetas", "_blank");

        win.focus();
      } catch (e) {
        console.error(e.message);
        if (e.message === "win is null") {
          Swal.fire({
            icon: "error",
            title: "Pop-Up",
            text: "Por favor habilite os pop-ups no seu navegador."
          });
          return;
        }

        Swal.fire({
          icon: "error",
          title: "Etiquetas",
          text:
            "Houve um erro ao carregar etiquetas, tente novamente mais tarde."
        });
      } finally {
        setLoading(false);
      }
    },
    [printTagsWarningModal, packagesSelectedId, user.canCreateCollectSchedule]
  );

  const handlePackageForSearch = useCallback(
    debounce(searchString => {
      const packagesFiltered = collect.shippings.filter(pack => {
        let searchableInfoInPackage = null;
        if (searchString) {
          searchableInfoInPackage = {
            orderNumber: pack.orderNumber,
            invoiceNumber: pack.invoiceNumber,
            type: pack.type,
            originCepFormatted: `${pack.origin.zip.substring(
              0,
              5
            )}-${pack.origin.zip.substring(5, 8)}`,
            originCep: pack.origin.zip,
            destinationCepFormatted: `${pack.destination.zip.substring(
              0,
              5
            )}-${pack.destination.zip.substring(5, 8)}`,
            destinationCep: pack.destination.zip,
            originName: pack.origin.name,
            destinationName: pack.destination.name
          };
        }

        const packHasInfoSearchedSetted =
          searchableInfoInPackage !== null
            ? Object.values(searchableInfoInPackage)
                .filter(value => !!value)
                .some(
                  packageInfo =>
                    !!packageInfo &&
                    String(packageInfo)
                      .toLowerCase()
                      .includes(searchString.toLowerCase())
                )
            : true;

        return packHasInfoSearchedSetted;
      });

      setPackagesFiltered(packagesFiltered);
    }, 100),
    [collect]
  );

  const loadShippingsByCollect = useCallback(async () => {
    try {
      setLoading(true);
      const response = await api.makeHttpRequest({
        method: "GET",
        url: `collects/${params.id}`
      });

      return response;
    } catch (e) {
      Swal.fire({
        icon: "error",
        title: "Erro",
        text: "Houve um erro ao carregar a página, tente novamente mais tarde."
      });
      console.error("Houve um erro ao carregar a página");
    } finally {
      setLoading(false);
    }
  }, [api, params.id]);

  const handleRevertCancel = useCallback(async () => {
    try {
      setLoading(true);
      await api.makeHttpRequest({
        method: "GET",
        url: `collects/${params.id}/revertCancel`
      });

      Swal.fire({
        icon: "success",
        title: "Sucesso",
        text: "O cancelamento dessa coleta foi revertido.",
        onClose: () => window.location.reload()
      });
    } catch (e) {
      Swal.fire({
        icon: "error",
        title: "Erro",
        text:
          "Houve um erro ao reverter a coleta, confira se essa coleta esta apta para ser revertida."
      });
    } finally {
      setLoading(false);
    }
  }, [api, params.id]);

  const handleCopyQrCodeString = useCallback(() => {
    navigator.clipboard.writeText(qrCodeData?.qrCodeString);
    setIsQrCodeCopied(true);
  }, [qrCodeData.qrCodeString]);

  const handleCollectSelection = useCallback(
    async collect => {
      console.log({ collect });
      setCollect(collect);
      setCollectStage(collect?.collectsStatus?.id);
      setInvoiceMethod(collect?.invoice?.method);

      if (collect?.invoice?.method === PaymentMethodEnum.CREDIT_CARD) {
        const cards = await api.makeHttpRequest({
          method: "GET",
          url: `payments/cards`
        });

        const findCard = cards.find(
          card => collect?.invoice?.lastTransaction?.cardId === card.externalId
        );

        console.log(findCard);

        setCardInfo(findCard);
      }

      setPackagesSelectedId(
        collect.shippings
          .filter(pack => !pack.blocked && pack.idShippingStatus !== 14)
          .map(s => s.id)
      );

      const expiresDate = moment(collect?.invoice?.lastTransaction?.expire_at);

      setQrCodeData({
        expiresDate,
        qrCodeUrl: collect?.invoice?.lastTransaction?.qrCodeUrl,
        qrCodeString: collect?.invoice?.lastTransaction?.qrCodeString
      });
    },
    [api]
  );

  const handleScheduleCollect = () => {
    setShowScheduleModal(true);
  };

  const handlePrintPage = useReactToPrint({
    content: () => componentRef.current,
    onBeforeGetContent: () => {
      return new Promise((resolve) => {
        promiseResolveRef.current = resolve;
        setVisible(true);
      });
    },
    onAfterPrint: () => {
      promiseResolveRef.current = null;
      setVisible(false);
    },
    copyStyles: () => true,
    pageStyle: () => `
      @page {
        size: A4 !important;
        padding: 0 !important;
        margin: 3mm !important;
      }
    `
  });

  useEffect(() => {
    if (visible && promiseResolveRef.current) {
      promiseResolveRef.current();
    }
  }, [visible]);

  useEffect(() => {
    (async () => {
      const result = await api.makeHttpRequest({
        method: "GET",
        url: `/users/me/`
      });

      store.dispatch(authDuck.actions.fulfillUser(result));
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const response = await loadShippingsByCollect();

      if (response) {
        await handleCollectSelection(response);
      }
    })();
  }, []);

  useEffect(() => {
    if (!collect?.shippings) return;

    if (!searchStringCollect) {
      setPackagesFiltered(collect.shippings);
      return;
    }

    if (searchStringCollect) {
      const searchString = searchStringCollect.trim();
      handlePackageForSearch(searchString);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collect, searchStringCollect]);

  useEffect(() => {
    if (!isAdmin) {
      if (collect.idCollectStatus === 5) {
        setShowScheduleModal(true);
      }
    }
  }, [collect.idCollectStatus, isAdmin]);

  useEffect(() => {
    if (!isQrCodeCopied) return;

    const timeout = setTimeout(() => {
      setIsQrCodeCopied(false);
    }, 2500);

    return () => clearTimeout(timeout);
  }, [isQrCodeCopied]);

  useEffect(() => {
    const interval = setInterval(async () => {
      if (collectStage === 1 && invoiceMethod === PaymentMethodEnum.PIX) {
        console.log(collectStage);
        console.log(invoiceMethod);
        const collect = await api.makeHttpRequest({
          method: "GET",
          url: `collects/${params.id}`
        });

        setCollectStage(collect?.collectsStatus?.id);

        if (collect?.collectsStatus?.id > 1) {
          console.log("clear");
          (async () => {
            await clearInterval(interval);
          })();
        }
      }
    }, 2000);

    const intervalCancelamento = setInterval(async () => {
      if (collectStage === 9) {
        const collect = await api.makeHttpRequest({
          method: "GET",
          url: `collects/${params.id}`
        });

        setCollectStage(collect?.collectsStatus?.id);

        if (
          collect?.collectsStatus?.id === 10 ||
          collect?.collectsStatus?.id === 8
        ) {
          console.log("clear");
          (async () => {
            await clearInterval(intervalCancelamento);
          })();
        }
      }
    }, 2000);
  }, [collectStage, invoiceMethod]);

  const handleCancelScheduleModal = () => {
    setCancelScheduleModal(true);
  };

  const confirmCancelShipping = async collect => {
    try {
      setLoading(true);
      await api.makeHttpRequest({
        url: `/collects/${collect.id}/cancel`,
        method: "DELETE"
      });

      const updatedUser = await api.makeHttpRequest({
        method: "GET",
        url: "/users/me"
      });
      store.dispatch(authDuck.actions.fulfillUser(updatedUser));

      setCancelScheduleModal(false);
      Swal.fire({
        title: "Sucesso",
        text: "A solicitação de cancelamento foi feita com sucesso",
        icon: "success",
        confirmButtonColor: "#409e6a",
        preConfirm() {
          setCancelScheduleModal(false);
        }
      });
    } catch (e) {
      console.log(e);
      setCancelScheduleModal(false);
      Swal.fire({
        title: "Erro",
        text:
          e.response.data.message ||
          "Um erro ocorreu ao tentar cancelar o objeto",
        icon: "error",
        confirmButtonColor: "#409e6a",
        preConfirm() {
          setCancelScheduleModal(false);
        }
      });
    } finally {
      const response = await loadShippingsByCollect();
      if (response) {
        await handleCollectSelection(response);
      }
      setLoading(false);
    }
  };

  const scheduleCancel = collect => {
    return (
      <ReactModal
        isOpen={cancelScheduleModal}
        dialogClassName="schedule-pickup-modal"
        onRequestClose={() => setCancelScheduleModal(false)}
        style={{
          overlay: {
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(51, 51, 51, 0.75)",
            zIndex: 999
          },
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            // marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            zIndex: 999,
            border: "none",
            backgroundColor: "transparent",
            padding: 0
          }
        }}
      >
        <ModalContainer>
          <AiOutlineWarning size={60} color="#409e6a" />
          <p style={{ width: "75%" }}>
            Deseja cancelar este pedido por completo?
          </p>
          <p style={{ width: "85%" }}>
            Confirmando esta ação{" "}
            <strong>
              todas as etiquetas do pedido serão canceladas de forma
              irreversível
            </strong>
            . Deseja continuar?
          </p>
          <ModalButtonsContainer>
            <button onClick={() => setCancelScheduleModal(false)}>
              Voltar
            </button>
            <button
              onClick={() => {
                confirmCancelShipping(collect);
              }}
            >
              Confirmar
            </button>
          </ModalButtonsContainer>
        </ModalContainer>
      </ReactModal>
    );
  };

  const printTagsWarning = collect => {
    if (!packagesSelectedId.length) {
      Swal.fire({
        icon: "warning",
        title: "Etiquetas",
        text: "Selecione os itens a serem imprimidos."
      });
      return;
    }

    return (
      <ReactModal
        isOpen={printTagsWarningModal && !user?.canCreateCollectSchedule}
        dialogClassName="print-tags-warning-modal"
        onRequestClose={() => setPrintTagsWarningModal(false)}
        style={{
          overlay: {
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(51, 51, 51, 0.75)",
            zIndex: 999
          },
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            // marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            zIndex: 999,
            border: "none",
            backgroundColor: "transparent",
            padding: 0
          }
        }}
      >
        <ModalContainer>
          <AiOutlineWarning size={60} color="#409e6a" />
          <>
            <p>
              Após a impressão da etiqueta não será mais possível cancelar os
              envios ou realizar seu estorno.
            </p>
            <p>
              Cada etiqueta tem um prazo de validade de 7 dias para ser
              utilizada, sem possibilidade de ressarcimento caso o prazo expire.
            </p>
            <p>Deseja continuar?</p>
          </>
          <ModalButtonsContainer>
            <button onClick={() => setPrintTagsWarningModal(false)}>
              Voltar
            </button>
            <button
              onClick={() => {
                handlePrintTags(collect, true);
              }}
            >
              Confirmar
            </button>
          </ModalButtonsContainer>
        </ModalContainer>
      </ReactModal>
    );
  };

  const isReverse = collect?.shippings?.some(
    shipping => shipping.isLogisticsReverse === true
  );

  const handlePackageExpand = useCallback(
    pack => {
      const find = packagesExpand.find(p => p.id === pack.id);
      if (find) {
        const newPacks = packagesExpand.filter(p => p.id !== pack.id);
        setPackagesExpand([...newPacks]);
      } else {
        packagesExpand.push(pack);
        setPackagesExpand([...packagesExpand]);
      }
    },
    [packagesExpand]
  );

  return (
    <>
      <Loading loading={loading} />
      {showScheduleModal && (
        <ReactModal
          isOpen={showScheduleModal}
          dialogClassName="schedule-pickup-modal"
          onRequestClose={() => setShowScheduleModal(false)}
          style={{
            content: {
              top: "50%",
              left: "50%",
              right: "auto",
              bottom: "auto",
              marginRight: "-50%",
              transform: "translate(-50%, -50%)"
            }
          }}
        >
          <DateFilter
            scheduleCollectsIds={[params.id]}
            setShowModal={setShowScheduleModal}
            setCollect={setCollect}
            loadShippingsByCollect={loadShippingsByCollect}
            setPackagesFiltered={setPackagesFiltered}
            setCollectStage={setCollectStage}
            handleCollectSelection={handleCollectSelection}
          />
        </ReactModal>
      )}
      <Container fluid>
        <Row className={styles.main}>
          <Col xs={12} lg={8} className={styles.container}>
            <div className={styles.title}>
              <h2>
                <strong>Pedido</strong> n°{collect?.id}{" "}
              </h2>
            </div>
            <div className={styles.actionButtons}>
              <div
                className={`${styles.inputContainer} ${styles.inputContainerSmall}`}
              >
                <div className={styles.input}>
                  <input
                    type="text"
                    name="search"
                    placeholder="Busque pela informação desejada"
                    onChange={e => setSearchStringCollect(e.target.value)}
                    value={searchStringCollect}
                  />

                  {!!searchStringCollect?.length && (
                    <MdClear
                      style={{ cursor: "pointer" }}
                      onClick={handleClearSearchFilter}
                    />
                  )}
                </div>
              </div>

              <div className={`${styles.checkActionButton}`}>
                {!!packagesFiltered && packagesFiltered.length >= 2 && (
                  <>
                    <div className={styles.containerCheck}>
                      <button
                        type="button"
                        className={styles.expand}
                        onClick={handleToggleCheckAllCartItems}
                      >
                        <BsFillCheckCircleFill size={16} />
                        <p>{(isSelectedAllCartItems &&
                          packagesSelectedId.length) ||
                          packagesSelectedId.length ===
                          packagesFiltered.length ?
                          "Desmarcar todos" : "Marcar todos"}
                        </p>
                      </button>
                    </div>
                  </>
                )}
                <button className={styles.print} onClick={handlePrintPage}>
                  <LuFileDown  color="#FFF" size={20} />
                  <p>Gerar relatório</p>
                </button>
              </div>
            </div>

            <div className={styles.collectWrapper}>
              {!!packagesFiltered.length ? (
                packagesFiltered?.map(pack => (
                  <CartCardShipping
                    widthWindows={widthWindows}
                    key={pack.id}
                    shipping={pack}
                    isSelected={packagesSelectedId.find(id => pack.id === id)}
                    isExpand={packagesExpand.find(
                      packExpand => pack.id === packExpand.id
                    )}
                    isCancelled={
                      pack.idShippingStatus ===
                      ShippingStagesEnum.OBJETO_CANCELADO.id
                    }
                    onClick={() => handlePackageSelection(pack.id)}
                    onExpand={() => handlePackageExpand(pack)}
                    setLoading={setLoading}
                    removeShipping={handleRemoveShipping}
                    isAdmin={isAdmin}
                    invoiceMethod={invoiceMethod}
                    collectStage={collectStage}
                    edit={invoiceMethod === PaymentMethodEnum.BILLING}
                    linkTracking
                  />
                ))
              ) : (
                <div className={styles.notFoundOrEmptyContainer}>
                  <p>
                    {!!collect?.shippings?.length && !!searchStringCollect
                      ? "Não foram encontradas envios para essa pesquisa."
                      : "Ainda não há envios para esse Pedido."}
                  </p>
                </div>
              )}
            </div>
          </Col>
          <Col xs={12} lg={4} className={styles.processingContainer}>
            {!goToPayment && (
              <>
                <div className={styles.processingTitle}>
                  <h2>Processamento</h2>
                </div>
                <div className={styles.processingStages}>
                  <div className={styles.stage}>
                    <div className={styles.check}>
                      <div>
                        <FiSquare />
                      </div>
                    </div>
                    <p>Pedido criado</p>
                  </div>

                  {!!collectStage &&
                    Object.entries(collectStagesEnum).map(([key, value]) => {
                      if (key === "AGUARDANDO_PAGAMENTO") {
                        const { id, message } = value;
                        return (
                          <div className={styles.stage} key={id}>
                            <div className={styles.check}>
                              <div>
                                <FiSquare />
                              </div>
                            </div>
                            <p
                              style={{
                                fontWeight:
                                  collectStage ===
                                  collectStagesEnum.AGUARDANDO_PAGAMENTO.id
                                    ? "700"
                                    : "400"
                              }}
                            >
                              {message}
                            </p>
                          </div>
                        );
                      } else if (
                        collectStage ===
                        collectStagesEnum.AGUARDANDO_PAGAMENTO.id
                      ) {
                        return null;
                      }

                      if (key === "ESTORNO") {
                        const {
                          ESTORNO_CONCLUIDO,
                          ESTORNO_PROCESSANDO
                        } = value;

                        if (
                          collectStage ===
                          collectStagesEnum.COLETA.COLETA_CANCELADA.id
                        )
                          return (
                            <>
                              <div
                                className={styles.stage}
                                key={ESTORNO_PROCESSANDO.id}
                              >
                                <div className={styles.check}>
                                  <div>
                                    <FiSquare />
                                  </div>
                                </div>
                                <p
                                  style={{
                                    fontWeight: "400"
                                  }}
                                >
                                  {ESTORNO_PROCESSANDO.message}
                                </p>
                              </div>
                              <div
                                className={styles.stage}
                                key={ESTORNO_CONCLUIDO.id}
                              >
                                <div className={styles.check}>
                                  <div>
                                    <FiSquare />
                                  </div>
                                </div>
                                <p
                                  style={{
                                    fontWeight: "400"
                                  }}
                                >
                                  {ESTORNO_CONCLUIDO.message}
                                </p>
                              </div>
                            </>
                          );

                        if (collectStage === ESTORNO_PROCESSANDO.id)
                          return (
                            <div
                              className={styles.stage}
                              key={ESTORNO_PROCESSANDO.id}
                            >
                              <div className={styles.check}>
                                <div>
                                  <FiSquare />
                                </div>
                              </div>
                              <p
                                style={{
                                  fontWeight: "400"
                                }}
                              >
                                {ESTORNO_PROCESSANDO.message}
                              </p>
                            </div>
                          );

                        if (collectStage === ESTORNO_CONCLUIDO.id)
                          return (
                            <div
                              className={styles.stage}
                              key={ESTORNO_CONCLUIDO.id}
                            >
                              <div className={styles.check}>
                                <div>
                                  <FiSquare />
                                </div>
                              </div>
                              <p
                                style={{
                                  fontWeight: "400"
                                }}
                              >
                                {ESTORNO_CONCLUIDO.message}
                              </p>
                            </div>
                          );
                      }

                      if (
                        collectStage ===
                        collectStagesEnum.ESTORNO.ESTORNO_PROCESSANDO.id
                      ) {
                        return null;
                      }

                      if (key === "PAGAMENTO") {
                        const { PAGAMENTO_NEGADO, PAGAMENTO_APROVADO } = value;

                        if (collectStage < PAGAMENTO_APROVADO.id) {
                          return (
                            <div
                              className={styles.stage}
                              key={PAGAMENTO_APROVADO.id}
                            >
                              <div className={styles.check}>
                                <div />
                              </div>
                              <p
                                style={{
                                  color: `${colorWarning}`,
                                  fontWeight:
                                    collectStage === PAGAMENTO_APROVADO.id
                                      ? "700"
                                      : "400"
                                }}
                              >
                                {PAGAMENTO_APROVADO.message}
                              </p>
                            </div>
                          );
                        }

                        const colorWarning =
                          collectStage === PAGAMENTO_NEGADO.id ? "#CA1818" : "";

                        return (
                          <div
                            className={styles.stage}
                            key={
                              collectStage === PAGAMENTO_NEGADO.id
                                ? PAGAMENTO_NEGADO.id
                                : PAGAMENTO_APROVADO.id
                            }
                          >
                            <div className={styles.check}>
                              <div>
                                <FiSquare
                                  style={{
                                    color: `${colorWarning}`,
                                    backgroundColor: `${colorWarning}`
                                  }}
                                />
                              </div>
                            </div>
                            <p
                              style={{
                                color: `${colorWarning}`,
                                fontWeight:
                                  collectStage === PAGAMENTO_NEGADO.id ||
                                  collectStage === PAGAMENTO_APROVADO.id
                                    ? "700"
                                    : "400"
                              }}
                            >
                              {collectStage === PAGAMENTO_NEGADO.id
                                ? PAGAMENTO_NEGADO.message
                                : PAGAMENTO_APROVADO.message}
                            </p>
                          </div>
                        );
                      } else if (
                        collectStage ===
                        collectStagesEnum.PAGAMENTO.PAGAMENTO_NEGADO.id
                      ) {
                        return null;
                      }

                      if (isReverse) return;

                      if (
                        key === "ETIQUETAS_DISPONIVEIS" &&
                        (collect.shippings.some(
                          shipping =>
                            shipping.rateType === RateTypeShippingEnum.RETAIL
                        ) ||
                          collect.collectDate !== null)
                      ) {
                        if (collectStage < value.id) {
                          return (
                            <div className={styles.stage} key={value.id}>
                              <div className={styles.check}>
                                <div />
                              </div>
                              <p
                                style={{
                                  fontWeight: "400"
                                }}
                              >
                                {value.message}
                              </p>
                            </div>
                          );
                        }

                        if (collectStage >= value.id) {
                          return (
                            <div className={styles.stage} key={value.id}>
                              <div className={styles.check}>
                                <div>
                                  <FiSquare />
                                </div>
                              </div>
                              <p
                                style={{
                                  fontWeight:
                                    collectStage === value.id ||
                                    collectStage ===
                                      collectStagesEnum.AGENDAMENTO
                                        .COLETA_AGENDADA.id
                                      ? "700"
                                      : "400"
                                }}
                              >
                                {value.message}
                              </p>
                            </div>
                          );
                        }
                      }

                      if (
                        key === "AGENDAMENTO" &&
                        collect.shippings.some(
                          shipping =>
                            shipping.rateType === RateTypeShippingEnum.COLLECT
                        )
                      ) {
                        const {
                          // AGUARDANDO_AGENDAMENTO,
                          COLETA_AGENDADA
                        } = value;

                        // if (collectStage < AGUARDANDO_AGENDAMENTO.id) {
                        //   return (
                        //     <div
                        //       className={styles.stage}
                        //       key={AGUARDANDO_AGENDAMENTO.id}
                        //     >
                        //       <div className={styles.check}>
                        //         <div />
                        //       </div>
                        //       <p
                        //         style={{
                        //           fontWeight:
                        //             collectStage === AGUARDANDO_AGENDAMENTO.id
                        //               ? "700"
                        //               : "400"
                        //         }}
                        //       >
                        //         {AGUARDANDO_AGENDAMENTO.message}
                        //       </p>
                        //     </div>
                        //   );
                        // }

                        if (collectStage === COLETA_AGENDADA.id) {
                          return (
                            <div
                              className={styles.stage}
                              // key={
                              //   collectStage === AGUARDANDO_AGENDAMENTO.id
                              //     ? AGUARDANDO_AGENDAMENTO.id
                              //     : COLETA_AGENDADA.id
                              // }
                              key={COLETA_AGENDADA.id}
                            >
                              <div className={styles.check}>
                                <div>
                                  <FiSquare />
                                </div>
                              </div>
                              <p
                                style={{
                                  fontWeight: "400"
                                  // collectStage === AGUARDANDO_AGENDAMENTO.id
                                  //   ? "700"
                                  //   : "400"
                                }}
                              >
                                {/* {collectStage === AGUARDANDO_AGENDAMENTO.id
                                ? AGUARDANDO_AGENDAMENTO.message
                                : COLETA_AGENDADA.message} */}
                                {collectStage === COLETA_AGENDADA.id &&
                                  COLETA_AGENDADA.message}
                              </p>
                            </div>
                          );
                        }
                      }

                      if (key === "COLETA") {
                        const { COLETA_CONCLUIDA, COLETA_CANCELADA } = value;

                        if (collectStage < COLETA_CONCLUIDA.id) {
                          return (
                            <div
                              className={styles.stage}
                              key={COLETA_CONCLUIDA.id}
                            >
                              <div className={styles.check}>
                                <div />
                              </div>
                              <p
                                style={{
                                  fontWeight:
                                    collectStage === COLETA_CONCLUIDA.id
                                      ? "700"
                                      : "400"
                                }}
                              >
                                {COLETA_CONCLUIDA.message}
                              </p>
                            </div>
                          );
                        }

                        const colorWarning =
                          collectStage === COLETA_CANCELADA.id ? "#CA1818" : "";

                        return (
                          <div
                            className={styles.stage}
                            key={
                              collectStage === COLETA_CANCELADA.id
                                ? COLETA_CANCELADA.id
                                : COLETA_CONCLUIDA.id
                            }
                          >
                            <div className={styles.check}>
                              <div>
                                <FiSquare
                                  style={{
                                    color: `${colorWarning}`,
                                    backgroundColor: `${colorWarning}`
                                  }}
                                />
                              </div>
                            </div>
                            <p
                              style={{
                                fontWeight:
                                  collectStage === COLETA_CONCLUIDA.id ||
                                  collectStage === COLETA_CANCELADA.id
                                    ? "700"
                                    : "400"
                              }}
                            >
                              {collectStage === COLETA_CANCELADA.id
                                ? COLETA_CANCELADA.message
                                : COLETA_CONCLUIDA.message}
                            </p>
                          </div>
                        );
                      }

                      if (collectStage < value.id) {
                        return (
                          <div className={styles.stage} key={value.id}>
                            <div className={styles.check}>
                              <div />
                            </div>
                            <p>{value.message}</p>
                          </div>
                        );
                      }
                    })}
                </div>
              </>
            )}

            {collectStage === 1 && invoiceMethod === PaymentMethodEnum.PIX && (
              <>
                <div className={styles.processingTitle}>
                  <h2>Pagamento</h2>
                </div>
                <div className={styles.pixContainer}>
                  <p className={styles.warningText}>
                    O código PIX expira em{" "}
                    <strong>
                      {isQrCodeExpired(qrCodeData?.expiresDate)} minuto
                      {isQrCodeExpired(qrCodeData?.expiresDate) === 1
                        ? "."
                        : "s."}
                    </strong>{" "}
                    Caso o pagamento não seja realizado dentro desse período, o
                    pedido será automaticamente cancelado.
                  </p>
                  <div className={styles.loader}>
                    <p>Estamos verificando seu pagamento junto ao banco...</p>
                    <PulseLoader
                      loading={true}
                      size={5}
                      color="#409E6A"
                      speedMultiplier={0.5}
                    />
                  </div>
                  <h3>Escaneie o QR Code</h3>
                  <div className={styles.qrCodeContainer}>
                    <img
                      className={styles.qrCodeImage}
                      src={qrCodeData?.qrCodeUrl}
                      alt="QR Code"
                    />
                  </div>
                  <div className={styles.qrCodeCopy}>
                    <div
                      className={styles.copyIconContainer}
                      onClick={() => handleCopyQrCodeString()}
                    >
                      <h3>PIX Copia e Cola</h3>
                      <MdContentCopy className={styles.copyIcon} />
                    </div>
                    {isQrCodeCopied && (
                      <span className={styles.copyWarning}>Copiado!</span>
                    )}
                  </div>
                  <p className={styles.codeText}>{qrCodeData?.qrCodeString}</p>
                </div>
              </>
            )}

            {collectStage === 3 &&
              !goToPayment &&
              invoiceMethod === PaymentMethodEnum.CREDIT_CARD && (
                <div className={styles.somethingWrongContainer}>
                  {/* <span className={styles.redWarning}>
                    Ocorreu um erro ao processar o pagamento
                  </span> */}
                  <p className={styles.informativeText}>
                    Não foi possível realizar o pagamento do pedido
                    <strong>{` ${params.id}`}</strong>
                    {cardInfo ? (
                      <>
                        com o cartão
                        <strong>
                          {cardInfo?.brand} {cardInfo?.last_four_digits}.
                        </strong>
                      </>
                    ) : (
                      <>.</>
                    )}
                  </p>
                  <button onClick={() => setGoToPayment(true)}>
                    Tentar novamente
                  </button>
                </div>
              )}

            {collectStage === 3 &&
              !goToPayment &&
              invoiceMethod === PaymentMethodEnum.PIX && (
                <div className={styles.somethingWrongContainer}>
                  <span className={styles.redWarning}>
                    Seu código PIX expirou
                  </span>
                  <p className={styles.informativeText}>
                    Solicite um novo código para realizar o seu pagamento. Não
                    se preocupe, você tem <strong>7 dias para pagar.</strong>
                  </p>
                  <button onClick={() => setGoToPayment(true)}>
                    Tentar novamente
                  </button>
                </div>
              )}

            {goToPayment && (
              <RetryPayment
                total={initialValue}
                packagesSelected={packagesSelectedId}
                collectId={params.id}
                setLoading={setLoading}
                setGoToPayment={setGoToPayment}
                handleCollectSelection={handleCollectSelection}
              />
            )}
            <div className={styles.processingTitle}>
              <h2>Sobre o pedido</h2>
            </div>
            <div className={styles.aboutCollect}>
              <p>
                Criado em{" "}
                <strong>
                  {moment(collect?.invoice?.created_at).format("DD/MM/YYYY")}
                </strong>
              </p>
              <p>
                Contém <strong>{collect?.shippings?.length}</strong> envios
              </p>
              <p>
                Pagamento{" "}
                <strong>
                  {collect?.invoice?.method === PaymentMethodEnum.BILLING
                    ? "Faturado"
                    : collect?.invoice?.method === PaymentMethodEnum.CREDIT_CARD
                    ? "Cartão de Crédito"
                    : collect?.invoice?.method === PaymentMethodEnum.WALLET
                    ? "Carteira"
                    : PaymentMethodEnum.PIX.toLocaleUpperCase()}
                </strong>
              </p>
              {/* {collectStage ===
                collectStagesEnum.AGENDAMENTO.COLETA_AGENDADA.id && (
                <p>
                  Coleta agendada para{" "}
                  <strong>
                    {moment(collect?.collectDate).format("DD/MM/YYYY")}
                  </strong>
                </p>
              )} */}
              {collectStage < 8 && (
                <>
                  <p>
                    <span>Total:</span>{" "}
                    <strong>
                      {new Intl.NumberFormat("pt-BR", {
                        style: "currency",
                        currency: "BRL"
                      }).format(invoiceValue)}
                      {walletDiscount > 0 ? (
                        <strong>
                          {" "}
                          (R$ {initialValue.toFixed(2)} - R${" "}
                          {walletDiscount.toFixed(2)})
                        </strong>
                      ) : walletDiscount < 0 ? (
                        <strong>
                          {" "}
                          (R$ {initialValue.toFixed(2)} + R${" "}
                          {(walletDiscount * -1).toFixed(2)})
                        </strong>
                      ) : null}
                    </strong>
                  </p>
                  {walletDiscount != 0 && (
                    <>
                      <p>
                        <span>Envios:</span>{" "}
                        <strong>
                          {new Intl.NumberFormat("pt-BR", {
                            style: "currency",
                            currency: "BRL"
                          }).format(initialValue)}
                        </strong>
                      </p>
                      <p>
                        <span>Saldo abatido:</span>{" "}
                        <strong>
                          {new Intl.NumberFormat("pt-BR", {
                            style: "currency",
                            currency: "BRL"
                          }).format(walletDiscount)}
                        </strong>
                      </p>
                    </>
                  )}
                </>
              )}
              {collectStage >= 8 && walletDiscount < 0 && (
                <p>
                  <span>Saldo abatido:</span>{" "}
                  <strong>
                    {new Intl.NumberFormat("pt-BR", {
                      style: "currency",
                      currency: "BRL"
                    }).format(walletDiscount)}
                  </strong>
                </p>
              )}
            </div>

            {isAdmin &&
              invoiceMethod === PaymentMethodEnum.BILLING &&
              (collectStage === collectStagesEnum.COLETA.COLETA_CANCELADA.id ||
                collectStage ===
                  collectStagesEnum.ESTORNO.ESTORNO_CONCLUIDO.id) && (
                <div className={styles.buttonsContainerPayment}>
                  <button
                    onClick={handleRevertCancel}
                    className={`${styles.buttonPayment} ${styles.buttonCancel}`}
                  >
                    Reverter Cancelamanto
                  </button>
                </div>
              )}
            {collectStage !== collectStagesEnum.COLETA.COLETA_CONCLUIDA.id &&
              (collectStage >= collectStagesEnum.ETIQUETAS_DISPONIVEIS.id ||
                collectStage ===
                  collectStagesEnum.PAGAMENTO.PAGAMENTO_APROVADO.id) &&
              collectStage !== collectStagesEnum.COLETA.COLETA_CANCELADA.id &&
              collectStage !==
                collectStagesEnum.ESTORNO.ESTORNO_PROCESSANDO.id &&
              !isReverse && (
                <div className={styles.buttonsContainerPayment}>
                  {!collect.shippings.some(shipping => shipping.numeroPLP) && (
                    <button
                      className={`${styles.buttonPayment} ${styles.buttonCancel}`}
                      onClick={handleCancelScheduleModal}
                      disabled={collect.length === 0}
                    >
                      {collect.length > 1
                        ? "Cancelar Pedidos"
                        : "Cancelar Pedido"}
                    </button>
                  )}

                  {collectStage !==
                    collectStagesEnum.COLETA.COLETA_CONCLUIDA.id &&
                    !isReverse && (
                      <button
                        onClick={() => handlePrintTags(collect)}
                        className={`
                        ${styles.buttonPayment}
                        ${styles.buttonPrintTickt}
                        ${styles.tooltip}
                      `}
                        disabled={!packagesSelectedId.length}
                      >
                        {!packagesSelectedId.length && (
                          <a className={styles.tooltip}>
                            <span className={styles.tooltiptext}>
                              {!packagesSelectedId.length &&
                                `${"Selecione o objeto para imprimir a(s) etiqueta(s)"}`}
                            </span>
                          </a>
                        )}
                        Imprimir Etiquetas
                      </button>
                    )}

                  {/* {user.canCreateCollectSchedule &&
                    !collect.shippings.some(
                      shipping => shipping.isLogisticsReverse
                    ) &&
                    collect.shippings.some(
                      shipping =>
                        shipping.rateType === RateTypeShippingEnum.COLLECT
                    ) &&
                    !collect.shippings.some(shipping => shipping.numeroPLP) && (
                      <button
                        onClick={handleScheduleCollect}
                        className={styles.buttonPayment}
                      >
                        {collect.collectDate
                          ? "Reagendar Coleta"
                          : "Agendar Coleta"}
                      </button>
                    )} */}
                </div>
              )}
          </Col>
        </Row>
      </Container>
      {visible && (
        <div ref={componentRef}>
          <div className={styles.title}>
            <h2>
              <strong>Pedido</strong> n°{collect?.id}{" "}
            </h2>
          </div>
          <div className={`${styles.cardContainer} ${styles.pageBreakCollect}`}>
            {!!packagesFiltered.length ? (
              packagesFiltered?.map(pack => (
                <>
                  <div className={styles.cardPrint}>
                    {pack?.orderNumber !== null && !!pack?.orderNumber && (
                      <div className={styles.groupInfo}>
                        <div>
                          <PiNoteFill color="#409E6A" size={22} />
                          <strong className={styles.titleDescription}>PEDIDO:</strong>
                        </div>
                        <span className={styles.shippingInfo}>
                          {pack?.orderNumber}
                        </span>
                      </div>
                    )}
                    {pack?.destination.name !== null && !!pack?.destination?.name && (
                      <div className={styles.groupInfo}>
                        <div>
                          <FaUser color="#409E6A" size={18} />
                          <strong className={styles.titleDescription}>
                            DESTINATÁRIO:
                          </strong>
                        </div>
                        <span className={styles.shippingInfo}>
                          {pack?.destination.name}
                        </span>
                      </div>
                    )}
                    {pack?.sro !== null && !!pack?.sro && (
                      <div className={styles.groupInfo}>
                        <div>
                          <TbSearch color="#409E6A" size={22} />
                          <strong className={styles.titleDescription}>CÓDIGO:</strong>
                        </div>
                        <span className={styles.shippingInfo}>{pack?.sro}</span>
                      </div>
                    )}
                    {pack?.authorizationCode !== null && !!pack?.authorizationCode && (
                      <div className={styles.groupInfo}>
                        <div>
                          <FaCheckToSlot color="#409E6A" size={22} />
                          <strong className={styles.titleDescription}>AUTORIZAÇÃO:</strong>
                        </div>
                        <span className={styles.shippingInfo}>{pack?.authorizationCode}</span>
                      </div>
                    )}
                    {pack?.price !== null && !!pack?.price && (
                      <div className={styles.groupInfo}>
                        <div>
                          <RiMoneyDollarCircleFill color="#409E6A" size={22} />
                          <strong className={styles.titleDescription}>VALOR:</strong>
                        </div>
                        <span className={styles.shippingInfo}>
                          {new Intl.NumberFormat("pt-BR", {
                            style: "currency",
                            currency: "BRL"
                          }).format(pack?.price)}
                        </span>
                      </div>
                    )}
                    <strong>
                      {`${pack?.correios?.nome?.replace("Entrega", "")}
                        ${pack?.isLogisticsReverse ? " (Reversa)" : ""}
                        ${pack.idShippingStatus === ShippingStagesEnum.OBJETO_CANCELADO.id ? " (Cancelado)" : ""}
                      `}
                    </strong>
                  </div>
                </>
              ))
            ) : (
              <div className={styles.notFoundOrEmptyContainer}>
                <p>
                  {!!collect?.shippings?.length && !!searchStringCollect
                    ? "Não foram encontradas envios para essa pesquisa."
                    : "Ainda não há envios para esse Pedido."}
                </p>
              </div>
            )}
          </div>
        </div>
      )}
      {cancelScheduleModal && scheduleCancel(collect)}
      {printTagsWarningModal && printTagsWarning(collect)}
    </>
  );
};

export { CollectDetail };
