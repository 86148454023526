const ProfileEnum = {
    ADMIN: {
        value: "admin",
        label: "Admin"
    },
    USER: {
        value: "usuario",
        label: "Usuário"
    },
    MANAGER: {
        value: "manager",
        label: "Gerente de contas"
    }
};

export default Object.freeze({
    ...ProfileEnum,
});