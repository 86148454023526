import React, { useMemo } from "react";
// import logoTag from "../../assets/logo/logoTagThicker.svg";
import logoTag from "../../assets/logo/logoTag.png";

import logoBRCom from "../../assets/logo/logo-brcomerce.svg";

import styles from "./styles.module.scss";
import formatCep from "../../utils/FormatCep";
import { capitalizeFirstLetter } from "../../utils/CapitalizeFirstLetter";
import { OriginEnum } from "../../enums/OriginEnum";

const TagOwnDelivery = ({
  origin,
  destination,
  tagCode,
  vd,
  shipping,
  tagBarCode,
  dataMatrix,
  senderDefaultName,
  invoiceNumber
}) => {
  const integrationToDisplayName = useMemo(() => {
    return [
      OriginEnum.VNDA,
      OriginEnum.BLINGV3
    ]
  }, []);

  return (
    <div className={styles.etiqueta1}>
      <div className={`${styles.head1}`}>
        <img src={logoBRCom} id="img-logo-brcom1" alt="logo empresa" />
        <img src={logoTag} id="img-logo" alt="logo empresa 2" />
      </div>

      <div className={`${styles.body1}`}>
        <div className={styles.tracking1}>
          <img
            src={`data:image/jpeg;base64,${tagBarCode}`}
            alt="Código de barras"
          />
          {/* <div className={styles.sro}> */}
          <span>{tagCode}</span>
          {/* </div> */}
        </div>

        <div className={styles.recipientData1}>
          <div>
            <span>{invoiceNumber && `NF: ${invoiceNumber}`}</span>
            <span>
              {" "}
              {shipping?.orderNumber && `Pedido: ${integrationToDisplayName?.includes(shipping?.originCreation) ? shipping?.name : shipping?.orderNumber || shipping?.orderNumber}`}
            </span>
            <span>{`Peso: ${shipping?.weight}kg`}</span>
          </div>

          <div>
            <p className={styles.destinary1}>DESTINATÁRIO:</p>
            <div>
              <span>{capitalizeFirstLetter(destination?.name)}</span>
              <span>
                {destination?.street}
                {destination?.number && `, ${destination?.number}`}
                {destination?.complement && ` - ${destination?.complement}`}
              </span>
              <span>{destination?.district}</span>
            </div>
          </div>

          <div>
            <p>
              {formatCep(shipping?.destination?.zip)} {destination?.city} -{" "}
              {destination?.state}
            </p>
            <span>Obs: {shipping?.destination?.observation}</span>
          </div>
        </div>
      </div>

      <div className={styles.recebedor1}>
        <p>
          Assinatura do recebedor: <span></span>
        </p>
        <div>
          <p>
            Documento: <span></span>
          </p>
          <p>
            Data da entrega: <span></span>
          </p>
        </div>
      </div>

      <div className={styles.separation1}>
        <p></p>
      </div>

      <div className={styles.remetente1}>
        <span>
          {`Remetente: ${senderDefaultName
            ? capitalizeFirstLetter(senderDefaultName)
            : capitalizeFirstLetter(origin?.name)
            }`}
        </span>
        <span>
          {origin?.street}
          {origin?.number && `, ${origin?.number}`}
          {origin?.complement && `, ${origin?.complement}`}
        </span>
        <span>{origin?.district}</span>
        <span>
          {formatCep(origin?.zip)} {origin?.city} - {origin?.state}
        </span>
      </div>
    </div>
  );
};

export { TagOwnDelivery };
