import React from "react";
import styles from "./styles.module.scss";
import { SyncLoader } from "react-spinners";

const Loading = ({ loading }) => {

  return (
    <>
    {loading && (
      <div className={styles.loading}>
        <SyncLoader loading={loading} size={10} color="#fff" />
      </div>
    )}
    </>
  );
};

export { Loading };
