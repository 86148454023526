import React from "react";
import { DashboardAdmin } from "./views/DashboardAdmin/";
import { DashboardClient } from "./views/DashboardClient/";
import store from "../../../../store/store";
import ProfileEnum from "../../../../enums/ProfileEnum";

const Dashboard = () => {
  const { auth } = store.getState();
  const { user } = auth;

  window.setPageTitle("Dashboard");
  
  const profile = user.profile === ProfileEnum.ADMIN.value || user.profile === ProfileEnum.MANAGER.value

  return profile ? <DashboardAdmin /> : <DashboardClient />;
};

export { Dashboard };
