import React, { useCallback, useEffect, useState } from "react";
import Swal from "sweetalert2";
import { Loading } from "../../../../../components/Loading";
import { BrComerceApiService } from "../../../../../services/BrComerceApiService.js";
import styles from "./styles.module.scss";
import Select from "react-select";
import { ShippingTypeEnum } from "../../../../../enums/ShippingTypeEnum";
import { optionsShippingType } from "./optionsShippingType.jsx";

const Tray = () => {
  const api = new BrComerceApiService();

  const [loading, setLoading] = useState(false);

  const [url, setUrl] = useState("");
  const [urlOK, setUrlOK] = useState("");
  const [urlError, setUrlError] = useState("");

  const handleInputUrl = useCallback(e => {
    const url = e.currentTarget.value;

    if (url.length < 1) {
      setUrl("");
      setUrlError("Campo obrigatório");
      setUrlOK("notOk");
    } else {
      setUrl(url);
      setUrlError("");
      setUrlOK("ok");
    }
  }, []);

  const [isFirstConfigured, setFirstConfigured] = useState(false);

  const [optionsStatuses, setOptionsStatuses] = useState([]);
  const [activeInsurance, setActiveInsurance] = useState(false);
  const [activeCreateOrder, setActiveCreateOrder] = useState(true);
  const [activeIntegration, setActiveIntegration] = useState(false);
  const [hasIntegration, setHasIntegration] = useState(false);

  const [sedex, setSedex] = useState("");
  const [sedexOK, setSedexOK] = useState("");

  const [pac, setPac] = useState("");
  const [pacOK, setPacOK] = useState("");

  const [mini, setMini] = useState("");
  const [miniOK, setMiniOK] = useState("");

  const [ownDelivery, setOwnDelivery] = useState("");
  const [ownDeliveryOK, setOwnDeliveryOK] = useState("");

  const [activeImportedRate, setActiveImportedRate] = useState(false);

  const [importedRate, setImportedRate] = useState("");
  const [importedRateOK, setImportedRateOK] = useState("");

  const [importedShippingType, setImportedShippingType] = useState({
    value: ShippingTypeEnum.SEDEX,
    label: "Expresso"
  });

  const [importedShippingTypeOK, setImportedShippingTypeOK] = useState("");

  const [selectedStatus, setSelectedStatus] = useState([]);
  const [selectedStatusOK, setSelectedStatusOK] = useState([]);
  const [selectedStatusError, setSelectedStatusError] = useState([]);

  const [senderName, setSenderName] = useState("");
  const [senderNameOK, setSenderNameOK] = useState("");
  const [senderNameError, setSenderNameError] = useState("");

  const selectStyle = {
    control: styles => ({
      ...styles,
      backgroundColor: "inherit",
      width: "100%",
      border: "none",
      boxShadow: "none",
      outline: "none",
      color: "#807E80",
      padding: "0",
      "&:hover": {
        borderColor: "none"
      },
      cursor: "pointer"
    }),
    container: styles => ({
      ...styles,
      width: "100%",
      outline: "none",
      border: "none",
      padding: "2px 0",
      cursor: "pointer"
    }),
    indicatorSeparator: styles => ({
      ...styles,
      display: "none",
      border: "none"
    }),
    option: (styles, { isSelected }) => ({
      ...styles,
      fontSize: "14px",
      fontFamily: "Mulish",
      fontWeight: 400,
      color: isSelected ? "#fff" : "#807E80",
      "&:hover": {
        backgroundColor: "#c1c1c1",
        color: "#fff",
        cursor: "pointer"
      },

      backgroundColor: isSelected ? "#409e6a" : "#fff"
    }),
    singleValue: styles => ({
      ...styles,
      color: "#807E80",
      fontSize: "14px"
    }),
    placeholder: styles => ({
      ...styles,
      fontSize: "14px",
      fontFamily: "Mulish",
      fontWeight: 400,
      color: "rgba(193, 193, 193, 1)",
      whiteSpace: "nowrap"
    })
  };

  const handleChangeSelectStatus = e => {
    if (e === null) {
      setSelectedStatus(e);
      setSelectedStatusOK("notOk");
      setSelectedStatusError("Selecione ao menos uma opção");
    } else {
      setSelectedStatus(e);
      setSelectedStatusOK("ok");
      setSelectedStatusError("");
    }
  };

  const handleSubmit = useCallback(
    async (key, value) => {
      api.makeHttpRequest({
        url: "/tray/config/key",
        method: "POST",
        data: {
          key: key,
          value: value
        }
      });
    },
    [api]
  );

  const handleInputSenderName = useCallback(e => {
    const name = e.currentTarget.value;

    if (name.length < 1) {
      setSenderName("");
      setSenderNameError("");
      setSenderNameOK("vazio");
    } else if (name.length < 2) {
      setSenderName(name);
      setSenderNameError("Nome do remetente deve ter pelo menos 2 caracteres");
      setSenderNameOK("notOk");
    } else {
      setSenderName(name);
      setSenderNameError("");
      setSenderNameOK("ok");
    }
  }, []);

  const handleInputSedex = useCallback(e => {
    const valueNumber = e.currentTarget.value.replace(/\D/g, "");
    if (valueNumber > 99) {
      return;
    } else if (valueNumber === "") {
      setSedex("");
      setSedexOK("vazio");
    } else {
      setSedex(valueNumber);
      setSedexOK("ok");
    }
  }, []);

  const handleInputPac = useCallback(e => {
    const valueNumber = e.currentTarget.value.replace(/\D/g, "");
    if (valueNumber > 99) {
      return;
    } else if (valueNumber === "") {
      setPac("");
      setPacOK("vazio");
    } else {
      setPac(valueNumber);
      setPacOK("ok");
    }
  }, []);

  const handleInputMini = useCallback(e => {
    const valueNumber = e.currentTarget.value.replace(/\D/g, "");
    if (valueNumber > 99) {
      return;
    } else if (valueNumber === "") {
      setMini("");
      setMiniOK("vazio");
    } else {
      setMini(valueNumber);
      setMiniOK("ok");
    }
  }, []);

  const handleInputOwnDelivery = useCallback(e => {
    const valueNumber = e.currentTarget.value.replace(/\D/g, "");
    if (valueNumber > 99) {
      return;
    } else if (valueNumber === "") {
      setOwnDelivery("");
      setOwnDeliveryOK("vazio");
    } else {
      setOwnDelivery(valueNumber);
      setOwnDeliveryOK("ok");
    }
  }, []);

  const handleInputImportedRate = useCallback(e => {
    const value = e.currentTarget.value;
    if (value.trim() === "") {
      setImportedRate("");
      setImportedRateOK("notOK");
    } else {
      setImportedRate(value);
      setImportedRateOK("ok");
    }
  }, []);

  const handleChangeImportedShippingType = e => {
    setImportedShippingType(e);
    setImportedShippingTypeOK("ok");
  };

  const sendData = async () => {
    try {
      await Promise.all([
        handleSubmit("BASE_URL", url),
        hasIntegration
          ? handleSubmit("SENDER_DEFAULT_NAME", senderName)
          : undefined,
        hasIntegration && selectedStatus.length > 0
          ? handleSubmit(
            "STATUSES_TO_ADD_ORDER",
            JSON.stringify(selectedStatus.map(status => status.value))
          )
          : undefined,
        hasIntegration ? handleSubmit("ADD_DAYS_SEDEX", sedex) : undefined,
        hasIntegration ? handleSubmit("ADD_DAYS_PAC", pac) : undefined,
        hasIntegration ? handleSubmit("ADD_DAYS_PACMINI", mini) : undefined,
        hasIntegration
          ? handleSubmit("IS_ACTIVE_INTEGRATION", activeIntegration)
          : undefined,
        hasIntegration && activeInsurance
          ? handleSubmit("IS_ACTIVE_INSURANCE", "true")
          : handleSubmit("IS_ACTIVE_INSURANCE", "false"),
        hasIntegration && activeCreateOrder
          ? handleSubmit("IS_INACTIVE_ORDER_CREATE", "false")
          : handleSubmit("IS_INACTIVE_ORDER_CREATE", "true"),
        hasIntegration
          ? handleSubmit("ADD_DAYS_OWN_DELIVERY", ownDelivery)
          : undefined,
        activeImportedRate
          ? handleSubmit("IS_ACTIVE_IMPORTED_RATE", "true")
          : handleSubmit("IS_ACTIVE_IMPORTED_RATE", "false"),
        handleSubmit("IMPORTED_RATE", importedRate),
        handleSubmit("IMPORTED_SHIPPING_TYPE", importedShippingType.value)
      ]);

      if (!hasIntegration) {
        setFirstConfigured(true);
      }

      Swal.fire({
        title: "Sucesso!",
        text: "Configurações cadastradas com sucesso.",
        icon: "success",
        showCancelButton: false,
        confirmButtonText: "Ok"
      });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Ocorreu um erro!",
        text: "Erro ao cadastrar configurações"
      });
    }
  };

  useEffect(() => {
    window.setPageTitle("Integrações | Tray");
  }, []);

  const loadingConfig = useCallback(async () => {
    await Promise.all([
      api
        .makeHttpRequest({
          method: "GET",
          url: `/tray/config/key/STORE_ID`
        })
        .then(res => {
          if (res) {
            setHasIntegration(true);
          }
        }),
      api
        .makeHttpRequest({
          method: "GET",
          url: `/tray/config/key/IS_ACTIVE_INTEGRATION`
        })
        .then(res => {
          if (res) {
            setActiveIntegration(res !== "0");
          }
        }),
      api
        .makeHttpRequest({
          method: "GET",
          url: `/tray/config/key/IS_INACTIVE_ORDER_CREATE`
        })
        .then(inactive => {
          if (inactive === "true") {
            setActiveCreateOrder(false);
          }
        }),
      api
        .makeHttpRequest({
          method: "GET",
          url: `/tray/config/key/ADD_DAYS_PACMINI`
        })
        .then(res => {
          if (res) {
            setMini(res);
            setMiniOK("ok");
          }
        }),
      api
        .makeHttpRequest({
          method: "GET",
          url: `/tray/config/key/ADD_DAYS_PAC`
        })
        .then(res => {
          if (res) {
            setPac(res);
            setPacOK("ok");
          }
        }),
      api
        .makeHttpRequest({
          method: "GET",
          url: `/tray/config/key/ADD_DAYS_SEDEX`
        })
        .then(res => {
          if (res) {
            setSedex(res);
            setSedexOK("ok");
          }
        }),
      api
        .makeHttpRequest({
          method: "GET",
          url: `/tray/config/key/BASE_URL`
        })
        .then(url => {
          if (url) {
            setUrl(url);
            setUrlOK("ok");
            setFirstConfigured(true);
          }
        }),
      api
        .makeHttpRequest({
          method: "GET",
          url: `/tray/config/key/SENDER_DEFAULT_NAME`
        })
        .then(name => {
          if (name) {
            setSenderName(name);
            setSenderNameOK("ok");
          }
        }),
      api
        .makeHttpRequest({
          method: "GET",
          url: `/tray/config/key/ADD_DAYS_OWN_DELIVERY`
        })
        .then(res => {
          if (res) {
            setOwnDelivery(res);
            setOwnDeliveryOK("ok");
          }
        }),
      api
        .makeHttpRequest({
          method: "GET",
          url: `/tray/config/key/IS_ACTIVE_IMPORTED_RATE`
        })
        .then(res => {
          res === "true"
            ? setActiveImportedRate(true)
            : setActiveImportedRate(false);
        }),
      api
        .makeHttpRequest({
          method: "GET",
          url: `/tray/config/key/IMPORTED_RATE`
        })
        .then(res => {
          if (res) {
            setImportedRate(res);
            setImportedRateOK("ok");
          }
        }),
      api
        .makeHttpRequest({
          method: "GET",
          url: `/tray/config/key/IMPORTED_SHIPPING_TYPE`
        })
        .then(res => {
          if (res) {
            setImportedShippingType({
              value: res,
              label: res === ShippingTypeEnum.PAC ? "Econômico" : "Expresso"
            });
            setImportedShippingTypeOK("ok");
          }
        })
    ]);
  }, [api]);

  useEffect(() => {
    setLoading(true);

    loadingConfig()
      .then(() => {
        console.log("Configurações ok");
      })
      .catch(() => {
        Swal.fire({
          title: "Erro!",
          text: "Não foi possível carregar as informações da loja Tray.",
          icon: "error"
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    (async () => {
      if (hasIntegration) {
        try {
          setLoading(true);
          const response = await api.makeHttpRequest({
            method: "GET",
            url: `/tray/status`
          });

          const responseHandled = response.map(status => ({
            value: status.status,
            label: status.status
          }));

          setOptionsStatuses(responseHandled);
        } catch (e) {
          if (
            e?.response?.data?.message === "User has not been integrated yet."
          ) {
            setHasIntegration(false);
          } else {
            Swal.fire({
              title: "Erro!",
              text: "Não foi possível carregar as opções de status da Tray.",
              icon: "error"
            });
          }
        } finally {
          setLoading(false);
        }
      }
    })();
  }, [hasIntegration]);

  useEffect(() => {
    (async () => {
      if (hasIntegration) {
        try {
          setLoading(true);
          const responses = await Promise.all([
            api.makeHttpRequest({
              method: "GET",
              url: `/tray/config/key/STATUSES_TO_ADD_ORDER`
            }),
            api.makeHttpRequest({
              method: "GET",
              url: `/tray/config/key/IS_ACTIVE_INSURANCE`
            })
          ]);

          const responseHandled = JSON.parse(responses[0] || "[]").map(
            status => ({
              value: status,
              label: status
            })
          );

          setSelectedStatus(responseHandled);
          setSelectedStatusOK("ok");
          setActiveInsurance(responses[1] === "true");
        } catch (e) {
          Swal.fire({
            title: "Erro!",
            text:
              "Não foi possível carregar as opções de status da Tray selecionadas.",
            icon: "error"
          });
        } finally {
          setLoading(false);
        }
      }
    })();
  }, [hasIntegration]);

  const trayBaseUrlParsed = url
    ? url.split("/").filter(e => !!e)[
    url.split("/").filter(e => !!e).length - 1
    ]
    : null;

  return (
    <>
      <Loading loading={loading} />
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.contentHeader}>
            <h2>Configurações</h2>
            <b>
              Atenção: É necessário clicar em "Salvar" no final desta página
              para confirmar a ativação/desativação.{" "}
            </b>
          </div>
          <div className={styles.contentBody}>
            <section className={styles.firstSection}>
              <div>
                <p style={{ marginTop: 0 }}>
                  É necessário ter em mãos a URL base da sua loja na Tray para
                  continuar com a integração.
                </p>
                <div className={styles.contentSection}>
                  <div className={styles.input} role={urlOK}>
                    <input
                      type="text"
                      name="url"
                      value={url}
                      onChange={handleInputUrl}
                      placeholder="Digite a URL da sua loja (ex.: http://www.minhaloja.com.br)"
                    />
                  </div>
                  {urlError && <p className={styles.error}>{urlError}</p>}
                </div>
              </div>
              {!hasIntegration && isFirstConfigured && url && (
                <div>
                  <div>
                    <h4>Desejo realizar a integração</h4>
                  </div>
                  <div className={styles.contentSection}>
                    <a
                      href={`https://${trayBaseUrlParsed}/auth.php?response_type=code&consumer_key=c2f14364aa5ee37ff94ee45b89cc43538ae75351687d06fb2af0e3959bb0bfcb&callback=http://${window.location.host}/callback/tray`}
                      className={styles.btnImport}
                      target={"_blank"}
                    >
                      Ir para Tray
                    </a>
                  </div>
                </div>
              )}
              {hasIntegration && (
                <>
                  <div>
                    <h4>Ativar / Desativar integração</h4>
                    <p>Para iniciar ou interromper o uso da integração. </p>
                    <div className={styles.contentSection}>
                      <div className={styles.switchOption}>
                        <label className={styles.switch}>
                          <input
                            type="checkbox"
                            onChange={event =>
                              setActiveIntegration(event.target.checked)
                            }
                            checked={activeIntegration}
                          />
                          <span
                            className={`${styles.slider} ${styles.round}`}
                          ></span>
                        </label>
                        {activeIntegration ? "Ativado" : "Desativado"}
                      </div>
                    </div>
                  </div>
                  <div className={styles.sectionTitle}>
                    <p style={{ marginTop: 0 }}>
                      Informe o nome de remetente que deve constar nas etiquetas
                      de envio geradas pela plataforma.
                    </p>
                    <div className={styles.contentSection}>
                      <div className={styles.divContainer}>
                        <div className={styles.input} role={senderNameOK}>
                          <input
                            onChange={e => handleInputSenderName(e)}
                            value={senderName}
                            placeholder="Digite o nome do remetente"
                            type="text"
                            name="senderName"
                          />
                        </div>
                        {senderNameError && (
                          <p className={styles.error}>{senderNameError}</p>
                        )}
                      </div>
                    </div>
                  </div>

                  <div>
                    <p>
                      Selecione abaixo o(s) status dos pedidos que deverão ser
                      importados pela integração:
                      <br />
                      Exemplo: A ENVIAR VINDI, para importar pedidos pagos e
                      pendentes de envio.
                    </p>
                    <div className={styles.contentSection}>
                      <div className={styles.input} role={selectedStatusOK}>
                        <Select
                          onChange={handleChangeSelectStatus}
                          options={optionsStatuses}
                          value={selectedStatus}
                          placeholder="Selecione o status"
                          styles={selectStyle}
                          noOptionsMessage={() =>
                            "Aguarde alguns instantes, a Tray está concluindo a configuração da integração"
                          }
                          isMulti
                        />
                      </div>
                      {selectedStatusError && (
                        <p className={styles.error}>{selectedStatusError}</p>
                      )}
                    </div>
                  </div>
                  <div>
                    <h4>Configurações adicionais de frete</h4>
                    <p>
                      Abaixo é possível indicar a quantidade de dias adicionais
                      para cada tipo de envio. Essa opção é particularmente útil
                      para <br /> refletir nas cotações o seu prazo de
                      manipulação dos pedidos.
                    </p>
                    <div className={styles.contentSection}>
                      <div className={styles.addDaysContainer}>
                        <div
                          className={`${styles.input} ${sedexOK === "notOK"
                            ? styles.formSelectError
                            : sedexOK === "ok"
                              ? styles.selectOk
                              : ""
                            }`}
                        >
                          <input
                            type="number"
                            name="sedex"
                            value={sedex}
                            onChange={handleInputSedex}
                            placeholder="Expresso"
                            min={0}
                            max={99}
                          />
                        </div>
                        <div
                          className={`${styles.input} ${pacOK === "notOK"
                            ? styles.formSelectError
                            : pacOK === "ok"
                              ? styles.selectOk
                              : ""
                            }`}
                        >
                          <input
                            type="number"
                            name="pac"
                            value={pac}
                            onChange={handleInputPac}
                            placeholder="Económico"
                            min={0}
                            max={99}
                          />
                        </div>
                        <div
                          className={`${styles.input} ${miniOK === "notOK"
                            ? styles.formSelectError
                            : miniOK === "ok"
                              ? styles.selectOk
                              : ""
                            }`}
                        >
                          <input
                            type="number"
                            name="mini"
                            value={mini}
                            onChange={handleInputMini}
                            placeholder="Mini Envios"
                            min={0}
                            max={99}
                          />
                        </div>
                        <div
                          className={`${styles.input} ${ownDeliveryOK === "notOK"
                            ? styles.formSelectError
                            : ownDeliveryOK === "ok"
                              ? styles.selectOk
                              : ""
                            }`}
                        >
                          <input
                            type="number"
                            name="ownDelivery"
                            value={ownDelivery}
                            onChange={handleInputOwnDelivery}
                            placeholder="Super Expressa"
                            min={0}
                            max={99}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className={styles.wrapper}>
                    <h4>Criação de pedido</h4>
                    <p>
                      Abaixo é possível ativar ou desatitvar a criação de pedidos.
                      <br />
                      Serviços como cotação funcionarão normalmente.
                    </p>
                    <br />
                    <div>
                      <div className={styles.switchOption}>
                        <label className={styles.switch}>
                          <input
                            type="checkbox"
                            onChange={event =>
                              setActiveCreateOrder(event.target.checked)
                            }
                            checked={activeCreateOrder}
                          />
                          <span
                            className={`${styles.slider} ${styles.round}`}
                          ></span>
                        </label>
                        {activeCreateOrder ? "Ativado" : "Desativado"}
                      </div>
                    </div>
                  </div>

                  <div>
                    <h4>Configuração de Seguro</h4>
                    <p>
                      Abaixo é possível ativar ou desatitvar a cotação de frete
                      com Seguro. Essa opção é válida para todos os fretes{" "}
                      <br /> que são cotados.
                    </p>
                    <div className={styles.contentSection}>
                      <div className={styles.switchOption}>
                        <label className={styles.switch}>
                          <input
                            type="checkbox"
                            onChange={event =>
                              setActiveInsurance(event.target.checked)
                            }
                            checked={activeInsurance}
                            disabled={
                              !(url && (!hasIntegration || selectedStatus))
                            }
                          />
                          <span
                            className={`${styles.slider} ${styles.round}`}
                          ></span>
                        </label>
                        {activeInsurance ? "Ativado" : "Desativado"}
                      </div>
                    </div>
                  </div>

                  <div>
                    <h4>
                      Importar pedidos com frete fixo, ou de outro parceiro
                      logístico
                    </h4>
                    <p>
                      Habilitando a opção abaixo será possivel importar fretes que
                      não pertençam à BRCom. O nome do frete deve ser digitado{" "}
                      <br /> idêntico ao apresentado na tela de cotação de frete
                      da sua loja.
                    </p>
                    <div className={styles.contentSection}>
                      <div className={styles.switchOption}>
                        <label className={styles.switch}>
                          <input
                            type="checkbox"
                            onChange={event =>
                              setActiveImportedRate(event.target.checked)
                            }
                            checked={activeImportedRate}
                          />
                          <span
                            className={`${styles.slider} ${styles.round}`}
                          ></span>
                        </label>
                        {activeImportedRate ? "Ativado" : "Desativado"}
                      </div>
                      <div className={styles.importedRateContainer}>
                        {activeImportedRate ? (
                          <>
                            <div
                              className={`${styles.input} ${importedRateOK === "notOK"
                                ? styles.formSelectError
                                : importedRateOK === "ok"
                                  ? styles.selectOk
                                  : ""
                                }`}
                            >
                              <input
                                type="text"
                                name="importedRate"
                                value={importedRate}
                                onChange={handleInputImportedRate}
                                placeholder="Nome do frete"
                              />
                            </div>
                            <div
                              className={`${styles.input} ${styles.select} ${importedShippingTypeOK === "notOK"
                                ? styles.formSelectError
                                : importedShippingTypeOK === "ok"
                                  ? styles.selectOk
                                  : ""
                                }`}
                            >
                              <Select
                                onChange={handleChangeImportedShippingType}
                                options={optionsShippingType}
                                value={importedShippingType}
                                placeholder="Tipo de envio"
                                styles={selectStyle}
                              />
                            </div>
                          </>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </>
              )}

              <div className={styles.btnContainer}>
                <button
                  type="submit"
                  onClick={sendData}
                  className={styles.btnSave}
                  disabled={
                    (activeImportedRate && !importedRate) || !hasIntegration
                      ? !url || urlOK === "notOk"
                      : !(url && (!hasIntegration || selectedStatus))
                  }
                  style={{ marginBottom: activeImportedRate ? "50px" : "" }}
                >
                  Salvar
                </button>
              </div>
            </section>
          </div>
        </div>
      </div>
    </>
  );
};

export { Tray };
