import React, { useState } from "react";
import { Carousel } from "react-responsive-carousel";

import arrowRightActive from "../../../../../../../assets/icons/arrow-right-active.svg";
import arrowRightDisabled from "../../../../../../../assets/icons/arrow-right-disabled.svg";
import arrowLeftDisabled from "../../../../../../../assets/icons/arrow-left-disabled.svg";
import arrowLeftActive from "../../../../../../../assets/icons/arrow-left-active.svg";

import styles from "./styles.module.scss";

const indicatorStyles = {
  background: "#409E6A",
  width: 8,
  height: 8,
  borderRadius: "50%",
  margin: "0 8px",
  cursor: "pointer"
};

const SlideCarousel = ({ steps }) => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const nextSlide = () => {
    setCurrentSlide(oldState => oldState + 1);
  };

  const prevSlide = () => {
    setCurrentSlide(oldState => oldState - 1);
  };

  const updateCurrentSlide = index => {
    if (currentSlide !== index) {
      setCurrentSlide(index);
    }
  };

  const renderIndicators = ({ image }, index) => {
    return currentSlide === index ? (
      <li
        style={{ ...indicatorStyles, background: "#F5CB2B" }}
        key={image}
        onClick={() => updateCurrentSlide(index)}
      />
    ) : (
      <li
        style={indicatorStyles}
        value={index}
        key={image}
        role="button"
        tabIndex={0}
        onClick={() => updateCurrentSlide(index)}
      />
    );
  };

  return (
    <div className={styles.outerDiv}>
      <div className={styles.innerDiv}>
        <button
          onClick={prevSlide}
          className={styles.prevButton}
          disabled={!(currentSlide > 0)}
        >
          <img
            src={currentSlide > 0 ? arrowLeftActive : arrowLeftDisabled}
            alt="Seta à Esquerda"
          />
        </button>
        <div className={styles.containerCarousel}>
          {steps?.length && (
            <Carousel
              showThumbs={false}
              showIndicators={true}
              showStatus={false}
              showArrows={false}
              renderIndicator={false}
              onChange={updateCurrentSlide}
              selectedItem={currentSlide}
            >
              {steps.map(({ image, subtitle }, key) => (
                <div key={image} className={styles.containerImage}>
                  <img src={image} alt={`Foto - ${key + 1}`} />
                  <p>{subtitle}</p>
                </div>
              ))}
            </Carousel>
          )}
        </div>
        <button
          onClick={nextSlide}
          className={styles.nextButton}
          disabled={!(currentSlide < steps.length - 1)}
        >
          <img
            src={
              currentSlide < steps.length - 1
                ? arrowRightActive
                : arrowRightDisabled
            }
            alt="Seta à Direita"
          />
        </button>
      </div>
      <ul className={styles.indicatorsContainer}>
        {steps?.length && steps.map(renderIndicators)}
      </ul>
    </div>
  );
};

export { SlideCarousel };
