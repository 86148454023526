import React from "react";
import moment from "moment";

import { FiTrash2, FiChevronUp, FiChevronDown } from "react-icons/fi";
import { FaUser } from "react-icons/fa";
import { TbSearch } from "react-icons/tb";
import { MdEvent } from "react-icons/md";
import formatCep from "../../../../utils/FormatCep";
import { momentBDays } from "../../../../utils/MomentBDay";
import styles from "./styles.module.scss";

export function PackageCard({
  shipping,
  onExpand,
  isExpand,
  onDelete,
  widthWindows,
  linkTracking = false,
  expand = true
}) {
  const addressInfoHandled = address => {
    let finalAddress = address?.street;

    if (!!address?.number) {
      finalAddress += `, ${address?.number}`;
    }

    if (!!address?.complement) {
      finalAddress += `, ${address?.complement}`;
    }

    if (!!address?.district) {
      finalAddress += `, ${address?.district}`;
    }

    return finalAddress;
  };

  const clientName = shipping?.user?.userData[1]?.Value?.split(" ", 2).join(
    " "
  );

  return (
    <>
      <div className={styles.cardWrapper}>
        {widthWindows > 1024 ? (
          <div className={styles.cardHeader}>
            <div className={styles.content}>
              {shipping?.sro !== null && !!shipping?.sro && (
                <div className={styles.groupInfo}>
                  <TbSearch color="#409E6A" size={22} />
                  <strong className={styles.titleDescription}>Código:</strong>
                  {linkTracking ? (
                    <a
                      href={`/detalhes-objeto/${shipping.id}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span className={styles.shippingInfo}>
                        <b>{shipping?.sro}</b>
                      </span>
                    </a>
                  ) : (
                    <span className={styles.shippingInfo}>{shipping?.sro}</span>
                  )}
                </div>
              )}

              {clientName && (
                <div className={styles.groupInfo}>
                  <div>
                    <FaUser color="#409E6A" size={18} />
                    <strong className={styles.titleDescription}>
                      Cliente:
                    </strong>
                  </div>
                  <span className={styles.shippingInfo}>{clientName}</span>
                </div>
              )}

              {shipping.created_at !== null && !!shipping.created_at && (
                <div className={styles.groupInfo}>
                  <MdEvent color="#409E6A" size={22} />
                  <strong className={styles.titleDescription}>Criação:</strong>
                  <span className={styles.shippingInfo}>
                    {moment(shipping?.created_at).format("DD/MM/YYYY")}
                  </span>
                </div>
              )}
            </div>
            <div className={styles.actionButtonsContainer}>
              {expand &&
                (isExpand ? (
                  <div onClick={onExpand}>
                    <FiChevronUp className={styles.iconOpenClose} />
                  </div>
                ) : (
                  <div onClick={onExpand}>
                    <FiChevronDown className={styles.iconOpenClose} />
                  </div>
                ))}
              <div onClick={onDelete}>
                <FiTrash2 className={styles.iconDelete} />
              </div>
            </div>
          </div>
        ) : (
          <div className={styles.cardHeaderMobile}>
            <div className={styles.content}>
              {shipping?.sro !== null && !!shipping?.sro && (
                <div className={styles.groupInfo}>
                  <strong className={styles.titleDescription}>Código:</strong>
                  <span className={styles.shippingInfo}>{shipping?.sro}</span>
                </div>
              )}
              {clientName && (
                <div className={styles.groupInfo}>
                  <strong className={styles.titleDescription}>Cliente:</strong>
                  <span className={styles.shippingInfo}>{clientName}</span>
                </div>
              )}
              {shipping.created_at !== null && !!shipping.created_at && (
                <div className={styles.groupInfo}>
                  <strong className={styles.titleDescription}>Criação:</strong>
                  <span className={styles.shippingInfo}>
                    {moment(shipping?.created_at).format("DD/MM/YYYY")}
                  </span>
                </div>
              )}
            </div>
            <div className={styles.actionButtonsContainer}>
              <div style={{ display: "flex", gap: "8px" }}>
                {expand &&
                  (isExpand ? (
                    <div onClick={onExpand}>
                      <FiChevronUp className={styles.iconOpenClose} />
                    </div>
                  ) : (
                    <div onClick={onExpand}>
                      <FiChevronDown className={styles.iconOpenClose} />
                    </div>
                  ))}
                <div onClick={onDelete}>
                  <FiTrash2 className={styles.iconDelete} />
                </div>
              </div>
            </div>
          </div>
        )}

        <div className={!isExpand ? styles.cardBodyHidden : styles.cardBody}>
          <div
            className={
              !isExpand ? styles.cardContentHidden : styles.cardContent
            }
          >
            <h3>REMETENTE</h3>
            <div className={styles.collectInfo}>
              <p className={styles.highlighted}>
                <span className={styles.highlighted}>Nome:</span>
                {shipping?.origin?.name}
              </p>
              <p>
                <span className={styles.highlighted}>CEP:</span>
                {formatCep(shipping?.origin?.zip)}
              </p>

              <p>
                <span className={styles.highlighted}>Endereço:</span>
                {addressInfoHandled({
                  street: shipping?.origin?.street,
                  number: shipping?.origin?.number,
                  complement: shipping?.origin?.complement,
                  district: shipping?.origin?.district
                })}
                - {`${shipping?.origin?.city}/${shipping?.origin?.state}`}
              </p>
            </div>
          </div>

          <div
            className={
              !isExpand ? styles.cardContentHidden : styles.cardContent
            }
          >
            <h3>DESTINATÁRIO</h3>
            <div className={styles.collectInfo}>
              <p className={styles.highlighted}>
                <span className={styles.highlighted}>Nome:</span>
                {shipping?.destination?.name}
              </p>
              <p>
                <span className={styles.highlighted}>CEP:</span>
                {formatCep(shipping?.destination?.zip)}
              </p>

              <p>
                <span className={styles.highlighted}>Endereço:</span>
                {addressInfoHandled({
                  street: shipping?.destination?.street,
                  number: shipping?.destination?.number,
                  complement: shipping?.destination?.complement,
                  district: shipping?.destination?.district
                })}
                -{" "}
                {`${shipping?.destination?.city}/${shipping?.destination?.state}`}
              </p>
            </div>
          </div>

          <div
            className={
              !isExpand ? styles.cardContentHidden : styles.cardContent
            }
          >
            <h3>PACOTE</h3>
            <div className={styles.collectInfo}>
              <p>
                {shipping?.identificationPackage && (
                  <>{shipping?.identificationPackage} </>
                )}
                <span className={styles.highlighted}>Peso total:</span>
                {`${String(shipping?.weight)?.replace(".", ",")}kg`}
              </p>

              <p>
                <span className={styles.highlighted}>Envio com seguro:</span>
                {shipping?.insurance ? "Sim" : "Não"}
              </p>
              <p className={styles.highlighted}>
                <span className={styles.highlighted}>Previsão de entrega:</span>
                {shipping?.previewDate
                  ? momentBDays(shipping?.previewDate).format("DD/MM/YYYY")
                  : momentBDays(momentBDays().format("YYYY-MM-DD"))
                      .businessAdd(
                        shipping?.correios?.desconto > 0
                          ? Number(shipping?.correios?.prazoEntrega) + 2
                          : Number(shipping?.correios?.prazoEntrega)
                      )
                      .format("DD/MM/YYYY")}
              </p>
              <p>
                <span className={styles.highlighted}>Peso total:</span>
                {`${String(shipping?.weight)?.replace(".", ",")}kg`}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function cardShippingAreEqual(prevShipping, nextShipping) {
  const shippingHasSameCheckEqual =
    prevShipping.isSelected === nextShipping.isSelected;

  const widthWindowIsEqual =
    prevShipping.widthWindows === nextShipping.widthWindows;

  return shippingHasSameCheckEqual && widthWindowIsEqual;
}
export const PackageCardComponent = React.memo(
  PackageCard,
  cardShippingAreEqual
);
