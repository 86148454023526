import React, { useEffect } from "react";
import styles from "./styles.module.scss";
import blockedPageImage from "../../../../assets/images/blocked-page-image.svg"
import { BrComerceApiService } from "../../../../services/BrComerceApiService";
import { WhatsApp } from "@material-ui/icons";
import store from "../../../../store/store";
import * as authDuck from "../../../../store/ducks/auth.duck";


const BlockedPage = () => {
  const api = new BrComerceApiService();

  useEffect(() => {
    window.setPageTitle("Bloqueado")
  }, []);

  const redirectToWhatsappWeb = () => {

    const text = "Olá, meu usuário foi bloqueado, como proceder?";

    const phoneNumber = "5521991829840";

    window.open(
      `https://api.whatsapp.com/send/?phone=${phoneNumber}&text=${text}`
    );
  };

  const checkUser = async () => {
    const user = await api.makeHttpRequest({
      method: "GET",
      url: "users/me"
    });

    store.dispatch(authDuck.actions.fulfillUser(user));

    if (!user.blocked) {
      window.location.replace("/");
    }
  };

  useEffect(() => {
    checkUser();
  }, []);

  return (
    <main className={styles.main}>
      <section className={styles.container}>
        <div className={styles.image}>
          <img src={blockedPageImage} />
        </div>
        <div className={styles.textContainer}>
          <p className={styles.textTitle}>
            Usuário bloqueado
          </p>
          {/* <p className={styles.textBody}>
            Lorem ipsum dolor sit amet consectetuer adipiscing elit sed diam nonummy nibh euismod tincidunt ut.Lorem ipsum dolor sit amet consectetuer adipiscing elit sed diam nonummy.
          </p> */}
          <p className={styles.textCta}>
            Entre em contato com nossa equipe de atendimento via Whatsapp para mais informações.
          </p>
        </div>
        <div className={styles.button} onClick={redirectToWhatsappWeb}>
          <WhatsApp />
          <span>
            ENTRAR EM CONTATO
          </span>
        </div>
      </section>
    </main>
  );
};

export { BlockedPage };

