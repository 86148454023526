import React, { useCallback, useState, useEffect } from "react";
import { AiOutlineCheck } from "react-icons/ai";
import { BsExclamation } from "react-icons/bs";
import { useHistory } from "react-router-dom";
import { BrComerceApiService } from "../../../../../services/BrComerceApiService";
import styles from "./styles.module.scss";
import { Loading } from "../../../../../components/Loading";
import Swal from "sweetalert2";
import Container from "../../../components/Container";

const DriversForm = () => {
  const api = new BrComerceApiService();

  const history = useHistory();

  const [driver, setDriver] = useState({});
  const [driverId, setDriverId] = useState();

  const [nameOK, setNameOK] = useState("vazio");
  const [nameError, setNameError] = useState("");
  const [name, setName] = useState("");


  const [phoneOK, setPhoneOK] = useState("vazio");
  const [phoneError, setPhoneError] = useState("");
  const [phone, setPhone] = useState("");

  const [loading, setLoading] = useState(false);


  const [isEditForm, setIsEditForm] = useState(false);


  const handleInputName = useCallback(e => {
    let value = e.currentTarget.value;
    setName(value);
    (value.length < 2 || value.length > 50) ? setNameOK("notOk") : setNameOK("ok");
    if (value.length === 0) {
      setNameError("Campo obrigatório");
    } else if (value.length === 1) {
      setNameError("Nome deve ter pelo menos 2 caracteres");
    } else if (value.length > 50) {
      setNameError("Nome não pode ter mais que 50 caracteres");
    } else {
      setNameError("");
    }
  }, []);

  const handleInputPhone = useCallback(e => {
    e.currentTarget.maxLength = 15;
    let value = e.currentTarget.value;
    value = value.replace(/\D/g, "");
    if (value.length === 11) {
      value = value.replace(/^(\d{2})(\d{5})(\d{4})/, "($1) $2-$3");
    }

    e.currentTarget.value = value;
    if (value.length > 0 && value.length < 15) {
      setPhoneError("Telefone incorreto");
      setPhoneOK("notOk");
    } else if (value.length === 0) {
      setPhoneError("Campo obrigatório");
      setPhoneOK("notOk");
    } else {
      setPhoneError("");
      setPhoneOK("ok");
    }
    setPhone(value);
  }, []);

  const handleFormSubmit = useCallback(
    async e => {
      e.preventDefault();
      if (!name || name === "" || !phone || phone === "") {
        if (!name || name === "") {
          setNameError("Campo obrigatório");
          setNameOK("notOk");
        }

        if (!phone || phone === "") {
          setPhoneError("Campo obrigatório");
          setPhoneOK("notOk");
        }
      }
      if (
        !name ||
        !phone ||
        name === "" ||
        phone === "" ||
        nameOK === "notOk" ||
        phoneOK === "notOk"
      ) {
        return;
      }

      try {
        setLoading(true);
        let data = {
          name,
          phone: "+55" + phone.replace(/\D/g, ""),
        };

        if (isEditForm) {
          await api.makeHttpRequest({
            method: "PUT",
            url: `/driver-admin/${driverId}`,
            data
          });
        } else {
          await api.makeHttpRequest({
            method: "POST",
            url: `/driver-admin`,
            data
          });
        }


        Swal.fire({
          title: "Sucesso!",
          text: isEditForm ? "Usuário editado com sucesso" : "Motorista cadastrado com sucesso",
          icon: "success",
          showCancelButton: false,
          confirmButtonText: "Ok",
          confirmButtonColor: "#409E6A"
        }).then(() => {
          history.push("/motoristas");
        });
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Erro",
          text: isEditForm ? "Não foi possível editar usuário" : "Não foi possível cadastrar o motorista",
          confirmButtonText: "Ok",
          confirmButtonColor: "#CA1818"
        });

        if (error.response.data.message === "Phone is not available") {
          setPhoneError("Este telefone não está disponível");
          setPhoneOK("notOk");
        }

      } finally {
        setLoading(false);
      }
    },
    [
      name,
      phone,
      nameOK,
      phoneOK,
      api,
      history,
      isEditForm
    ]
  );

  useEffect(() => {
    if (history.location.pathname.includes("editar")) {
      setDriverId(history.location.pathname.split("/")[2]);
      setIsEditForm(true)
    } else {
      setIsEditForm(false)
    }
  }, [history]);

  useEffect(() => {
    if (driverId && isEditForm) {
      setLoading(true);
      api
        .makeHttpRequest({
          method: "GET",
          url: `/driver-admin/${driverId}`
        })
        .then(response => {
          setDriver(response);
          setLoading(false);
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [driverId, isEditForm]);

  useEffect(() => {
    if (driver) {
      setLoading(true);
      setName(driver?.name);
      setPhone(
        driver?.phone?.substring(3, 20).replace(/^(\d{2})(\d{5})(\d{4})/, "($1) $2-$3")
      );
      setLoading(false);
    }
  }, [driver]);

  return (
    <>
      <Loading loading={loading} />
      <Container title={isEditForm ? "Editar cadastro" : "Adicionar novo motorista"}>
        <form className={styles.form} onSubmit={handleFormSubmit}>
          <div className={styles.formContent}>
            {isEditForm && <h4>Dados do motorista</h4>}
            <div className={styles.row}>
              <div className={styles.inputContainer}>
                <div className={styles.input} role={nameOK}>
                  <input
                    type="text"
                    name="name"
                    placeholder="Nome"
                    onChange={e => handleInputName(e)}
                    value={name}
                    defaultValue={name}
                  />
                  <AiOutlineCheck className={styles.check} role={nameOK} />
                  <BsExclamation className={styles.danger} role={nameOK} />
                </div>
                <p className={styles.error}>{nameError}</p>
              </div>
              <div className={`${styles.inputContainer}`}>
                <div
                  role={phoneOK}
                  style={{ backgroundColor: isEditForm ? "#d1cdcd" : "transparent"}}
                  className={`${styles.input} ${isEditForm ? styles.disabled : ""}`}
                >
                  <input
                    type="text"
                    name="phone"
                    placeholder="Telefone"
                    onChange={handleInputPhone}
                    value={phone}
                    disabled={isEditForm}
                  />
                  <AiOutlineCheck className={styles.check} role={phoneOK} />
                  <BsExclamation className={styles.danger} role={phoneOK} />
                </div>
                <p className={styles.error}>{phoneError}</p>
              </div>
            </div>
          </div>
          <div className={styles.buttonsContainer}>
            <div className={styles.buttonContainerBack}>
              <button type="button" onClick={() => history.push("/motoristas")}>
                Voltar
              </button>
            </div>
            <div className={styles.buttonContainer}>
              <button type="submit" disabled={phoneOK === "notOk" || nameOK === "notOk"}>{isEditForm ? "Atualizar" : "Cadastrar"}</button>
            </div>
          </div>
        </form>
      </Container>
    </>
  );
};

export { DriversForm };
