/* eslint-disable jsx-a11y/anchor-is-valid */
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { FiSearch, FiDownload } from "react-icons/fi";
import { FaCircleCheck, FaCircleXmark } from "react-icons/fa6";
import { TbArticle } from "react-icons/tb";
import { HiOutlineCurrencyDollar } from "react-icons/hi";
import { PiPackage } from "react-icons/pi";
import InfiniteScroll from "react-infinite-scroll-component";
import Select from "react-select";
import DatePicker from "../../../../components/Input/DatePicker";
import { Loading } from "../../../../components/Loading";
import { BrComerceApiService } from "../../../../services/BrComerceApiService";
import store from "../../../../store/store";
import debounce from "../../../../utils/debounce";
import styles from "./styles.module.scss";
import Swal from "sweetalert2";
import CsvDownload from "react-json-to-csv";
import InvoiceStatusEnum from "../../../../enums/InvoiceStatusEnum";
import LoadInfiniteScroll from "../../../../components/LoadInfiniteScroll";
import ScrollDiv from "../../components/ScrollDiv";
import InputSelectAsync, {
  selectStyle
} from "../../../../components/InputSelectAsync";
import ProfileEnum from "../../../../enums/ProfileEnum";
import Container from "../../components/Container";
import { StyledTooltip } from "../../components/Tooltip";

export function Billing() {
  const api = new BrComerceApiService();

  const { auth } = store.getState();
  const { user } = auth;
  const isAdmin = user?.profile === ProfileEnum.ADMIN.value;
  const isManager = user?.profile === ProfileEnum.MANAGER.value;

  const [selectedStatusInFilter, setSelectedStatusInFilter] = useState({
    value: null,
    label: "Todos"
  });
  const [periodInitialDate, setPeriodInitialDate] = useState(null);
  const [periodFinalDate, setPeriodFinalDate] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [billings, setBillings] = useState([]);
  const [actualPage, setActualPage] = useState(0);
  const [nPages, setNPages] = useState(0);
  const [modal, setModal] = useState(false);
  const [collects, setCollects] = useState(false);
  const [selectedBilling, setSelectedBilling] = useState({});
  const [shippingData, setShippingData] = useState({});
  const [searchClient, setSearchClient] = useState(null);

  const getOptionsStagesBilling = useMemo(() => {
    const optionsHandled = Object.entries(InvoiceStatusEnum).map(
      ([key, value]) => {
        return {
          value: value.id,
          label: value.message
        };
      }
    );

    return [{ value: "all", label: "Todos" }, ...optionsHandled];
  }, []);

  const handleOnChangeCalendarPeriod = e => {
    if (e.dateEnd) {
      const dateStart = moment(e.dateStart, "DD/MM/YYYY").format("MM/DD/YYYY");
      const dateEnd = moment(e.dateEnd, "DD/MM/YYYY").format("MM/DD/YYYY");

      setPeriodInitialDate(dateStart);
      setPeriodFinalDate(dateEnd);
    }
  };

  const handleClearPeriodFilter = () => {
    setPeriodInitialDate("");
    setPeriodFinalDate("");
  };

  const handleChangeSelectFilterByStatus = e => {
    setSelectedStatusInFilter(e);
  };

  const handleFetchData = useCallback(async () => {
    try {
      if (actualPage === nPages) {
        return;
      }

      const response = await api.makeHttpRequest({
        method: "GET",
        url: `/monthly-invoices`,
        params: {
          orderBy: "created_at",
          orderByDirection: "desc",
          resultsPerPage: 20,
          page: actualPage + 1,
          status: !Number(selectedStatusInFilter)
            ? null
            : selectedStatusInFilter,
          initialDate: periodInitialDate ? periodInitialDate : null,
          finalDate: periodFinalDate ? periodFinalDate : null,
          ...(searchClient && { text: searchClient?.label })
        }
      });

      setBillings([...billings, ...response.results]);
      setActualPage(Number(response.actualPage));
      setNPages(Number(response.nPages));
    } catch (e) {
      console.log(e);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    actualPage,
    nPages,
    selectedStatusInFilter,
    periodInitialDate,
    periodFinalDate,
    billings,
    searchClient
  ]);

  const getShippingTracking = useCallback(
    debounce(async (selectedStatusInFilter, initialDate, finalDate) => {
      setIsLoading(true);

      const response = await api.makeHttpRequest({
        method: "GET",
        url: `/monthly-invoices`,
        params: {
          orderBy: "created_at",
          orderByDirection: "desc",
          resultsPerPage: 20,
          page: 1,
          status:
            selectedStatusInFilter === "all" ? null : selectedStatusInFilter,
          initialDate: initialDate ? initialDate : null,
          finalDate: finalDate ? finalDate : null,
          ...(searchClient && { text: searchClient?.label })
        }
      });

      if (response) {
        setBillings(response.results);
        setActualPage(1);
        setNPages(Number(response.nPages));
      }
      setIsLoading(false);
    }, 500),
    [user, searchClient]
  );

  const handlePayBilling = useCallback(
    async id => {
      try {
        setIsLoading(true);
        await api.makeHttpRequest({
          method: "POST",
          url: `/monthly-invoices/${id}`
        });
        setBillings(actual =>
          actual.map(billing => {
            if (id === billing.id) {
              return {
                ...billing,
                idShippingStatus: 1,
                status: {
                  ...billing.status,
                  id: 1,
                  name: "Aprovado"
                }
              };
            } else {
              return billing;
            }
          })
        );
      } catch (e) {
        Swal.fire({
          title: "Erro",
          text: `Um erro ocorreu ao marcar como pago a fatura ${id}`,
          icon: "error",
          confirmButtonColor: "#409e6a"
        });
        console.log(e);
      } finally {
        setIsLoading(false);
      }
    },
    [api]
  );

  const handleDeleteBilling = useCallback(
    async id => {
      try {
        setIsLoading(true);
        await api.makeHttpRequest({
          method: "DELETE",
          url: `/monthly-invoices/${id}`
        });

        setBillings(billings => billings.filter(billing => billing.id !== id));
      } catch (e) {
        Swal.fire({
          title: "Erro",
          text: `Um erro ocorreu ao deletar a fatura ${id}`,
          icon: "error",
          confirmButtonColor: "#409e6a"
        });
        console.log(e);
      } finally {
        setIsLoading(false);
      }
    },
    [api]
  );

  const handleInvoiceDetails = useCallback(async billing => {
    setSelectedBilling(billing);
    try {
      setIsLoading(true);
      const shippingInvoices = await api.makeHttpRequest({
        method: "GET",
        url: `/shipping-invoices/${billing.id}`
      });

      const invoices = await api.makeHttpRequest({
        method: "GET",
        url: `/invoices/monthly-invoices/${billing.id}`
      });

      const shippings = [];
      const data = [];

      invoices.map(invoice => {
        return invoice?.collect?.shippings?.map(shipping => {
          return shippings.push(shipping);
        });
      });

      invoices.map(invoice => {
        return invoice?.collect?.shippings?.map(shipping => {
          const invoiceData = {
            Data: new Intl.DateTimeFormat("pt-br").format(
              new Date(shipping?.created_at || new Date())
            ),
            Coleta: shipping?.idCollect,
            Identificação: shipping?.sro,
            Pedido: shipping?.orderNumber,
            Origem: ` ${shipping?.origin?.zip.substring(
              0,
              5
            )}-${shipping?.origin?.zip.substring(5, 8)}`,
            Destino: ` ${shipping?.destination?.zip.substring(
              0,
              5
            )}-${shipping?.destination?.zip.substring(5, 8)}`,
            ValorFrete: formatNumber(
              shipping.insurance
                ? shipping?.price - shipping?.insuranceValue
                : shipping?.price
            ),
            ValorSeguro: formatNumber(
              shipping.insurance ? shipping?.insuranceValue : 0
            ),
            "Peso(kg)": formatNumber(shipping?.weight),
            Total: formatNumber(shipping?.price)
          };
          return data.push(invoiceData);
        });
      });

      shippingInvoices.map(shippingInvoice => {
        return shippings.push(shippingInvoice.shipping);
      });

      shippingInvoices.map(shippingInvoices => {
        const invoiceData = {
          Data: new Intl.DateTimeFormat("pt-br").format(
            new Date(shippingInvoices.shipping?.created_at || new Date())
          ),
          Coleta: shippingInvoices.shipping?.idCollect,
          Identificação: shippingInvoices.shipping?.sro,
          Pedido: shippingInvoices.shipping?.orderNumber,
          Origem: ` ${shippingInvoices.shipping?.origin?.zip.substring(
            0,
            5
          )}-${shippingInvoices.shipping?.origin?.zip.substring(5, 8)}`,
          Destino: ` ${shippingInvoices.shipping?.destination?.zip.substring(
            0,
            5
          )}-${shippingInvoices.shipping?.destination?.zip.substring(5, 8)}`,
          ValorFrete: formatNumber(
            shippingInvoices.insurance
              ? shippingInvoices.shipping?.price -
                  shippingInvoices.shipping?.insuranceValue
              : shippingInvoices.shipping?.price
          ),
          ValorSeguro: formatNumber(
            shippingInvoices.shipping?.insurance
              ? shippingInvoices.shipping?.insuranceValue
              : 0
          ),
          "Peso(kg)": formatNumber(shippingInvoices.shipping?.weight),
          Total: formatNumber(shippingInvoices.shipping?.price)
        };
        return data.push(invoiceData);
      });

      setShippingData(data);
      setCollects(shippings);

      setModal(true);
    } catch (e) {
      Swal.fire({
        title: "Erro",
        text: `Um erro ocorreu ao carregar as coletas da fatura ${billing.id}`,
        icon: "error",
        confirmButtonColor: "#409e6a"
      });
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const formatNumber = (
    amount,
    decimalCount = 2,
    decimal = ",",
    thousands = "."
  ) => {
    try {
      decimalCount = Math.abs(decimalCount);
      decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

      const negativeSign = amount < 0 ? "-" : "";

      let i = parseInt(
        (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
      ).toString();
      let j = i.length > 3 ? i.length % 3 : 0;

      return (
        negativeSign +
        (j ? i.substr(0, j) + thousands : "") +
        i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
        (decimalCount
          ? decimal +
            Math.abs(amount - i)
              .toFixed(decimalCount)
              .slice(2)
          : "")
      );
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    (() => {
      try {
        getShippingTracking(
          selectedStatusInFilter.value,
          periodInitialDate,
          periodFinalDate
        );
      } catch (e) {
        console.error(e);
      }
    })();
  }, [
    getShippingTracking,
    periodFinalDate,
    periodInitialDate,
    selectedStatusInFilter.value
  ]);

  useEffect(() => {
    window.setPageTitle("Faturamento");
  }, []);

  return (
    <>
      {modal && (
        <div className={styles.modal}>
          <div>
            <div className={styles.titleModal}>
              <h1>Detalhes da fatura</h1>
            </div>
            <div className={styles.subTitleModal}>
              <div>
                <span>
                  <TbArticle size={22} color="#409E6A" />
                  {`Fatura nº ${selectedBilling?.id}`}
                </span>
                <span>
                  <HiOutlineCurrencyDollar size={22} color="#409E6A" />

                  {`Valor Total: ${new Intl.NumberFormat("pt-br", {
                    style: "currency",
                    currency: "BRL"
                  }).format(selectedBilling?.value)}`}
                </span>
                <span>
                  <PiPackage size={22} color="#409E6A" />

                  {`${collects.length} pacotes`}
                </span>
                <span>
                  <CsvDownload data={shippingData}>
                    <FiDownload size={22} color="#409E6A" />
                  </CsvDownload>
                  <span className={styles.download}>Baixar CSV</span>
                </span>
              </div>
            </div>
            <table>
              <thead>
                <tr>
                  <th>Data</th>
                  <th>Coleta</th>
                  <th>Identificação</th>
                  <th>Pedido</th>
                  <th>Origem</th>
                  <th>Destino</th>
                  <th>Valor Frete</th>
                  <th>Valor Seguro</th>
                  <th>Dimensões</th>
                  <th>Peso</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                {collects.map((collect, n) => (
                  <tr key={collect?.id || n}>
                    <td>
                      {new Intl.DateTimeFormat("pt-br").format(
                        new Date(collect?.created_at || new Date())
                      )}
                    </td>
                    <td>
                      <a
                        href={`/detalhe-coleta/${collect?.idCollect}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {collect?.idCollect}
                      </a>
                    </td>
                    <td>
                      <a
                        href={`/detalhes-objeto/${collect?.id}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {collect?.sro}
                      </a>
                    </td>
                    <td>{collect?.orderNumber}</td>
                    <td>
                      {` ${collect?.origin?.zip.substring(
                        0,
                        5
                      )}-${collect?.origin?.zip.substring(5, 8)}`}
                    </td>
                    <td>
                      {` ${collect?.destination?.zip.substring(
                        0,
                        5
                      )}-${collect?.destination?.zip.substring(5, 8)}`}
                    </td>
                    <td>
                      {new Intl.NumberFormat("pt-BR", {
                        style: "currency",
                        currency: "BRL"
                      }).format(
                        collect?.insurance
                          ? collect?.price - collect?.insuranceValue
                          : collect?.price
                      )}
                    </td>
                    <td>
                      {new Intl.NumberFormat("pt-BR", {
                        style: "currency",
                        currency: "BRL"
                      }).format(
                        collect?.insurance ? collect?.insuranceValue : 0
                      )}
                    </td>
                    <td>
                      {`${collect?.height}x${collect?.width}x${collect?.length}cm`}
                    </td>
                    <td>{collect?.weight} (kg)</td>
                    <td>
                      {new Intl.NumberFormat("pt-br", {
                        style: "currency",
                        currency: "BRL"
                      }).format(collect?.price)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <button
              type="button"
              onClick={() => {
                setModal(false);
              }}
            >
              Fechar
            </button>
          </div>
        </div>
      )}
      <main className={styles.main}>
        <Loading loading={isLoading} />
        <Container title="Faturamento">
          <div className={styles.filtersContainer}>
            {(isAdmin || isManager) && (
              <div
                className={`${styles.inputContainer} ${styles.inputContainerSmall}`}
              >
                <InputSelectAsync
                  search={searchClient}
                  setSearch={setSearchClient}
                />
              </div>
            )}
            <div
              className={`${styles.inputContainer} ${styles.inputContainerSmall}`}
            >
              <div className={styles.input}>
                <DatePicker
                  change={handleOnChangeCalendarPeriod}
                  placeholder="Filtrar por periodo"
                  showClearButton={!!periodInitialDate && !!periodFinalDate}
                  onClearFields={handleClearPeriodFilter}
                  range
                />
              </div>
            </div>

            <div
              className={`${styles.inputContainer} ${styles.inputContainerSmall}`}
            >
              <div className={styles.formSelect}>
                <Select
                  onChange={handleChangeSelectFilterByStatus}
                  options={getOptionsStagesBilling}
                  value={
                    selectedStatusInFilter ||
                    getOptionsStagesBilling.find(item => item.value === "all")
                  }
                  placeholder="Selecione o tipo de pacote"
                  styles={selectStyle}
                />
              </div>
            </div>
          </div>

          <div className={styles.tableContent}>
            <ScrollDiv data={billings}>
              <InfiniteScroll
                dataLength={billings.length}
                next={handleFetchData}
                hasMore={nPages === 0 ? false : !(actualPage === nPages)}
                loader={<LoadInfiniteScroll />}
                scrollableTarget="scrollDiv"
                className={styles.infiniteScroll}
              >
                <div className={styles.tableContainer}>
                  <table className={styles.table}>
                    <thead className={styles.tableHead}>
                      <tr>
                        {(isAdmin || isManager) && <th>Cliente</th>}
                        <th>Fatura</th>
                        <th>Data emissão</th>
                        <th>Vencimento</th>
                        <th>Competência</th>
                        <th>Método</th>
                        <th>Valor</th>
                        <th>Status</th>
                        {user.profile === "admin" && <th width="6%">Ações</th>}
                      </tr>
                    </thead>
                    <tbody className={styles.tableBody}>
                      {billings.map(billing => (
                        <tr key={billing.id}>
                          {(isAdmin || isManager) && (
                            <td>{billing?.user?.userData?.[1]?.Value}</td>
                          )}

                          <td>
                            <a
                              onClick={() => handleInvoiceDetails(billing)}
                              rel="noopener noreferrer"
                            >
                              <p style={{ color: "#409E6A" }}>{billing.id}</p>
                            </a>
                          </td>
                          <td>
                            {moment(billing.created_at).format("DD/MM/YYYY")}
                          </td>
                          <td>
                            {moment(billing.dueDate).format("DD/MM/YYYY")}
                          </td>
                          <td>
                            {new Intl.DateTimeFormat("pt-BR", {
                              month: "short",
                              year: "numeric"
                            }).format(new Date(billing.competence))}
                          </td>
                          <td className={styles.fatured}>FATURADO</td>
                          <td>
                            {new Intl.NumberFormat("pt-BR", {
                              style: "currency",
                              currency: "BRL"
                            }).format(billing.value)}
                          </td>
                          <td className={styles.badge}>
                            <span
                              className={
                                billing.status.id === 1
                                  ? styles.approved
                                  : styles.waiting
                              }
                            >
                              {" "}
                              {billing.status.id === 1
                                ? "Pagamento Aprovado"
                                : billing.status.name}
                            </span>
                          </td>
                          {user.profile === "admin" && (
                            <td className={styles.actionsWrapper}>
                              <div className={styles.actionsList}>
                                {billing.idInvoicesStatus === 6 && (
                                  <>
                                    <StyledTooltip
                                      text="Marcar como pago"
                                      position="top"
                                      arrow
                                    >
                                      <button
                                        type="button"
                                        onClick={() => {
                                          handlePayBilling(billing?.id);
                                        }}
                                      >
                                        <FaCircleCheck
                                          size={20}
                                          color="#409e6a"
                                        />
                                      </button>
                                    </StyledTooltip>
                                    <StyledTooltip
                                      text="Cancelar"
                                      position="top"
                                      arrow
                                    >
                                      <button
                                        onClick={() =>
                                          handleDeleteBilling(billing?.id)
                                        }
                                      >
                                        <FaCircleXmark
                                          size={20}
                                          color="#FF6B71"
                                        />
                                      </button>
                                    </StyledTooltip>
                                  </>
                                )}
                                <StyledTooltip
                                  text="Detalhes"
                                  position="top"
                                  arrow
                                >
                                  <button
                                    onClick={() =>
                                      handleInvoiceDetails(billing)
                                    }
                                  >
                                    <FiSearch size={20} color="#409e6a" />
                                  </button>
                                </StyledTooltip>
                              </div>
                            </td>
                          )}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </InfiniteScroll>
            </ScrollDiv>
          </div>
        </Container>
      </main>
    </>
  );
}
