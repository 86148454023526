import styled from "styled-components";

export const Container = styled.div`
  width: 830px;
  display: flex;

  h3 {
    font-weight: 600;
    font-size: 18px;
    line-height: 120%;

    svg {
      color: #409e6a;
      margin-left: 10px;
    }
  }
`;

export const CalendarContainer = styled.div`
  width: 50%;

  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 16px 32px;

  .calendarHeader {
    width: 100%;

    h3 {
      display: flex;
    }

    p {
      font-weight: 600;
      font-size: 14px;
      line-height: 140%;
      color: #333333;
    }
  }
`;

export const RightContainer = styled.div`
  width: 50%;
  background-color: #fafafa;
  padding: 16px 32px;

  p {
    color: #807e80;
    font-weight: 400;
    font-size: 12px;
    line-height: 120%;
  }

  button {
    display: block;
    width: 100%;
    background-color: #fff;
    padding: 8px;
    margin: 16px 0;

    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    color: #409e6a;

    border: 1px solid #409e6a;
    border-radius: 4px;

    &.active {
      background-color: #409e6a;
      color: #ffffff;
    }
    &.disabled {
      border-color: #fafafa;
      background-color: #DADADA;
      color: #ffffff;
    }
  }

  .input {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-radius: 4px;
    border: 1px solid #807e80;
    padding: 12px;

    margin-top: 37px;

    label {
      position: absolute;
      top: -9px;
      left: 8px;
      color: #807e80;
      font-size: 14px;
      line-height: 18px;
      background: #fff;
      padding: 0 4px;
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: 85%;
      overflow: hidden;
      background-color: #fafafa;
    }

    textarea {
      outline: none;
      border: 0;
      width: 100%;

      width: 100%;
      resize: none;
      padding: 12px;

      border-radius: 4px;

      font-family: "Mulish", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 25px;

      background-color: #fafafa;

      &:focus {
        outline: none;
      }
    }
  }
`;

export const SendButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;

  button {
    cursor: pointer;
    font-size: 14px;
    line-height: 21px;
    font-weight: 700;
    background-color: #409e6a;
    border: 0;
    color: #ffffff;
    padding: 10px 32px;
    transition: filter 0.2s;
    border-radius: 5px;

    &:hover {
      filter: brightness(0.8);
    }

    &:disabled {
      background: #c1c1c1;
      opacity: 0.8;
      cursor: not-allowed;
    }
  }
`;

export const TooltipText = styled.div`
  cursor: pointer;
`;

export const TooltipBox = styled.div`
  position: absolute;
  top: calc(100% + 10px);
  left: 30px;
  visibility: hidden;
  width: 346px;
  padding: 16px;

  z-index: 1;

  background: #ffffff;

  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12),
    0px 1px 3px rgba(0, 0, 0, 0.2);
  border-radius: 12px;

  div {
    display: flex;
    align-items: flex-start;

    p {
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;

      margin-left: 12px;
    }

    & + div {
      margin-top: 16px;
    }
  }
`;

export const TooltipCard = styled.div`
  position: relative;

  & ${TooltipText}:hover + ${TooltipBox} {
    visibility: visible;
  }
`;
