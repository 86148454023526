import React from "react";
import { useHistory } from "react-router-dom";
import { SlideCarousel } from "./components";
import "react-responsive-carousel/lib/styles/carousel.css"; // requires a loader

import optionsIntegrations from "../../../../../assets/images/integration/optionsIntegrations.png";

import yampi1 from "../../../../../assets/images/yampi/yampi1.png";
import yampi2 from "../../../../../assets/images/yampi/yampi2.png";
import yampi3 from "../../../../../assets/images/yampi/yampi3.png";
import yampi4 from "../../../../../assets/images/yampi/yampi4.png";
import yampi5 from "../../../../../assets/images/yampi/yampi5.png";
import yampi6 from "../../../../../assets/images/yampi/yampi6.png";
import yampi7 from "../../../../../assets/images/yampi/yampi7.png";

import styles from "./styles.module.scss";

const TutorialYampi = () => {
  const history = useHistory();

  const steps1th = [
    { image: optionsIntegrations },
    { image: yampi6 },
    { image: yampi5 }
  ];

  const steps2th = [{ image: yampi1 }, { image: yampi2 }, { image: yampi3 }];

  return (
    <main className={styles.container}>
      <div className={styles.content}>
        <header className={styles.contentHeader}>
          <p>Como fazer a integração?</p>
        </header>
        <div className={styles.contentBody}>
          <section className={styles.contentSection}>
            <h4>
              Passo a passo para realizar cotações na Yampi usando nossa API:
            </h4>

            <div className={styles.bulletpoint}>
              <span>1</span>
              <p>
                Acesse as configurações da integração Yampi, insira as
                informações para a cotação, gere o <b>Token</b>, copie-o e
                clique em <b>Salvar</b>.
              </p>
            </div>

            <SlideCarousel steps={steps1th} />

            <div className={styles.bulletpoint}>
              <span>2</span>
              <p>
                Acesse sua conta da Yampi e siga os passos de como criar uma
                opção de frete para usar nossa API.
              </p>
            </div>

            <SlideCarousel steps={steps2th} />

            <div className={styles.bulletpoint}>
              <span>3</span>
              <p>Use esses dados para preencher os campos correspondentes:</p>
            </div>

            <div className={styles.subBulletpoint}>
              <span>A</span>
              <p>
                Adicione um header e preencha a CHAVE com "x-api-key" e no VALOR
                cole o TOKEN copiado no passo 1
              </p>
            </div>

            <div className={styles.imgs}>
              <img src={yampi4} alt="Yampi" className={styles.cardImage} />
            </div>

            <div className={styles.subBulletpoint}>
              <span>B</span>
              <p>
                Confira se o frete esta ativo no canto superior esquerdo e
                nomeie o frete.
              </p>
            </div>

            <div className={styles.subBulletpoint}>
              <span>C</span>
              <p>
                No campo URL, copie e cole esse link:{" "}
                <b>{process.env.REACT_APP_API_HOST}/yampi/rates</b>
              </p>
            </div>

            <div className={styles.imgs}>
              <img src={yampi7} alt="Yampi" className={styles.cardImage} />
            </div>

            <div className={styles.bulletpoint}>
              <span>4</span>
              <p>
                Clique em salvar e pronto! Agora você poderá usar nossa API para
                realizar as cotações na Yampi
              </p>
            </div>

            <button
              onClick={() => history.push("/integracoes")}
              className={`${styles.button} ${styles.btnBack}`}
            >
              Voltar
            </button>
          </section>
        </div>
      </div>
    </main>
  );
};

export { TutorialYampi };
