import React, { useCallback, useEffect, useState } from "react";
import { AiOutlineCheck } from "react-icons/ai";
import { BsExclamation } from "react-icons/bs";
import { FiCheck, FiEye, FiEyeOff, FiSquare, FiX } from "react-icons/fi";
import { BrComerceApiService } from "../../../../services/BrComerceApiService";
import { cnpjValidation } from "../../../../utils/CnpjValidation";
import { emailValidation } from "../../../../utils/EmailValidation";
import { cpfValidation } from "../../../../utils/CpfValidation";
import styles from "./styles.module.scss";
import { StatusModal } from "../../../../components/StatusModal";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { Loading } from "../../../../components/Loading";
import TextPrivacy from "../../../policy/TextPrivacy";
import TextTerms from "../../../terms/TextTerms";

const RegisterForm = () => {
  const api = new BrComerceApiService();

  const params = useParams();

  const { flagCompany } = params;

  const history = useHistory();
  const location = useLocation();

  const [loading, setLoading] = useState(false);

  const [nameOK, setNameOK] = useState("vazio");
  const [nameError, setNameError] = useState("");
  const [name, setName] = useState("");

  const [emailOK, setEmailOK] = useState("vazio");
  const [emailError, setEmailError] = useState("");
  const [email, setEmail] = useState("");

  const [cpfOK, setCpfOK] = useState("vazio");
  const [cpfError, setCpfError] = useState("");
  const [cpf, setCpf] = useState("");

  const [cnpjOK, setCnpjOk] = useState("vazio");
  const [cnpjError, setCnpjError] = useState("");
  const [cnpj, setCnpj] = useState("");

  const [phoneOK, setPhoneOK] = useState("vazio");
  const [phoneError, setPhoneError] = useState("");
  const [phone, setPhone] = useState("");

  const [passwordOK, setPasswordOK] = useState("vazio");
  const [passwordError, setPasswordError] = useState("");
  const [password, setPassword] = useState("");

  const [visible, setVisible] = useState(true);

  const [confirmPasswordOK, setConfirmPasswordOK] = useState("vazio");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [confirmationCodeOK, setConfirmationCodeOK] = useState("vazio");
  const [confirmationCodeError, setConfirmationCodeError] = useState("");
  const [confirmationCode, setConfirmationCode] = useState("");

  const [visibleConfirm, setVisibleConfirm] = useState(true);

  const [privacyChecked, setPrivacyChecked] = useState(false);

  const [privacyOpen, setPrivacyOpen] = useState(false);
  const [termsOpen, setTermsOpen] = useState(false);

  const [modalStatus, setModalStatus] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [modaltype, setModalType] = useState("info");

  const [type, setType] = useState("close");

  const [formState, setFormState] = useState("SignUp");

  const handleInputName = useCallback(e => {
    let value = e.currentTarget.value;
    setName(value);
    value.length < 2 ? setNameOK("notOk") : setNameOK("ok");
    if (value.length === 0) {
      setNameError("Campo obrigatório");
    } else if (value.length === 1) {
      setNameError("Nome deve ter pelo menos 2 caracteres");
    } else {
      setNameError("");
    }
  }, []);

  const handleInputEmail = useCallback(e => {
    let value = e.currentTarget.value;
    const emailValid = emailValidation({
      email: value,
      emptyEmail: "Campo obrigatório",
      incorrectEmail: "Email informado não é valido"
    });
    setEmailOK(emailValid.status);
    setEmailError(emailValid.message);
    setEmail(value);
  }, []);

  const handleInputCpf = useCallback(e => {
    e.currentTarget.maxLength = 14;
    let value = e.currentTarget.value;
    value = value.replace(/\D/g, "");
    value = value.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
    e.currentTarget.value = value;
    const validCPF = cpfValidation({
      cpf: value,
      emptyCpf: "Campo obrigatório",
      incorrectCpf: "CPF incorreto"
    });
    setCpfOK(validCPF.status);
    setCpfError(validCPF.message);
    setCpf(value);
  }, []);

  const handleInputCnpj = useCallback(e => {
    e.currentTarget.maxLength = 18;
    let value = e.currentTarget.value;
    value = value.replace(/\D/g, "");
    value = value.replace(
      /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
      "$1.$2.$3/$4-$5"
    );
    e.currentTarget.value = value;
    const validCnpj = cnpjValidation({
      cnpj: value,
      emptyCnpj: "Preenchimento obrigatório",
      incorrectCnpj: "Preenchimento incorreto"
    });
    setCnpjOk(validCnpj.status);
    setCnpjError(validCnpj.message);
    setCnpj(value);
  }, []);

  const handleInputPhone = useCallback(e => {
    e.currentTarget.maxLength = 15;
    let value = e.currentTarget.value;
    value = value.replace(/\D/g, "");
    if (value.length === 11) {
      value = value.replace(/^(\d{2})(\d{5})(\d{4})/, "($1) $2-$3");
    } else if (value.length === 10) {
      value = value.replace(/^(\d{2})(\d{4})(\d{4})/, "($1) $2-$3");
    }
    e.currentTarget.value = value;
    if (value.length > 0 && value.length < 14) {
      setPhoneError("Telefone incorreto");
      setPhoneOK("notOk");
    } else if (value.length === 0) {
      setPhoneError("Campo obrigatório");
      setPhoneOK("notOk");
    } else {
      setPhoneError("");
      setPhoneOK("ok");
    }
    setPhone(value);
  }, []);

  const handleInputPassword = useCallback(e => {
    let value = e.currentTarget.value;
    const regex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
    if (value.length === 0) {
      setPasswordError("Campo obrigatório");
      setPasswordOK("notOk");
    } else if (!value?.match(regex)) {
      setPasswordError(
        "Senha precisar ter no mínimo 8 caracteres, uma letra maiúscula, um número e um carácter especial"
      );
      setPasswordOK("notOk");
    } else {
      setPasswordError("");
      setPasswordOK("ok");
    }

    setPassword(value);
  }, []);

  const handleInputConfirmPassword = useCallback(
    e => {
      let value = e.currentTarget.value;
      if (value !== password) {
        setConfirmPasswordError("Não é igual a senha informada");
        setConfirmPasswordOK("notOk");
      } else if (value.length === 0) {
        setConfirmPasswordError("Campo obrigatório");
        setConfirmPasswordOK("notOk");
      } else {
        setConfirmPasswordError("");
        setConfirmPasswordOK("ok");
      }
      setConfirmPassword(value);
    },
    [password]
  );

  const handleInputCode = useCallback(e => {
    e.currentTarget.maxLength = 6;
    let value = e.currentTarget.value;
    value = value.replace(/\D/g, "");
    setConfirmationCode(value);
    value.length < 6
      ? setConfirmationCodeOK("notOk")
      : setConfirmationCodeOK("ok");
    if (value.length === 0) {
      setConfirmationCodeError("Campo obrigatório");
    } else if (value.length < 6) {
      setConfirmationCodeError("Precisa ter 6 caracteres");
    } else {
      setConfirmationCodeError("");
    }
  }, []);

  const handleFormSubmit = useCallback(
    async e => {
      e.preventDefault();

      if (!privacyChecked) {
        setModalMessage("Aceitar política de privacidade e termos de uso");
        setModalStatus(true);
        setModalType("error");
        return;
      }

      if (
        name === "" ||
        email === "" ||
        cnpj === "" ||
        phone === "" ||
        password === "" ||
        confirmPassword === ""
      ) {
        if (name === "") {
          setNameError("Campo obrigatório");
          setNameOK("notOk");
        }

        if (email === "") {
          setEmailError("Campo obrigatório");
          setEmailOK("notOk");
        }

        if (cnpj === "") {
          setCnpjError("Campo obrigatório");
          setCnpjOk("notOk");
        }

        if (cpf === "") {
          setCpfError("Campo obrigatório");
          setCpfOK("notOk");
        }

        if (phone === "") {
          setPhoneError("Campo obrigatório");
          setPhoneOK("notOk");
        }

        if (password === "") {
          setPasswordError("Campo obrigatório");
          setPasswordOK("notOk");
        }

        if (confirmPassword === "") {
          setConfirmPasswordError("Campo obrigatório");
          setConfirmPasswordOK("notOk");
        }
      }
      if (
        name === "" ||
        email === "" ||
        cnpj === "" ||
        phone === "" ||
        password === "" ||
        confirmPassword === "" ||
        nameOK === "notOk" ||
        emailOK === "notOk" ||
        cnpjOK === "notOk" ||
        cpfOK === "notOk" ||
        phoneOK === "notOk" ||
        passwordOK === "notOk" ||
        confirmPasswordOK === "notOk" ||
        !privacyChecked
      ) {
        setModalMessage("Verificar campos com erros");
        setModalStatus(true);
        setModalType("error");
        return;
      }

      try {
        setLoading(true);
        const data = {
          name,
          email,
          cnpj:
            flagCompany === "pj"
              ? cnpj.replace(/\D/g, "")
              : cpf.replace(/\D/g, ""),
          phone: "+55" + phone.replace(/\D/g, ""),
          password,
          isCompany: flagCompany === "pj"
        };

        await api.makeHttpRequest({
          method: "POST",
          url: `/users`,
          data
        });

        setModalStatus(true);
        setModalMessage(
          "Só mais um passo! Para finalizarmos o cadastro, enviamos um e-mail para você com o código de confirmação"
        );
        setModalType("email");

        setFormState("Confirm");
      } catch (error) {
        setModalStatus(true);
        setModalType("error");

        if (error.response.data.message === "Email is not available") {
          setModalMessage("Email não está disponível");
        } else if (error.response.data.validation.body.message.includes("must be a valid email")) {
          setModalMessage("Email inválido");
        } else {
          setModalMessage(error.response.data.message);
        }
      } finally {
        setLoading(false);
      }
    },
    [
      privacyChecked,
      name,
      email,
      phone,
      password,
      confirmPassword,
      nameOK,
      emailOK,
      phoneOK,
      passwordOK,
      confirmPasswordOK,
      cnpjOK,
      cnpj,
      api,
      flagCompany,
      cpf,
      cpfOK
    ]
  );

  const handleConfirmSignUp = useCallback(
    async e => {
      e.preventDefault();
      try {
        await api.makeHttpRequest({
          method: "POST",
          url: `/users/confirm`,
          data: {
            email,
            confirmationCode
          }
        });

        setModalStatus(true);
        setModalMessage(`E-Mail ${email} confirmado com sucesso`);
        setType("redirect");
      } catch (error) {
        if (
          error.response.data.message ===
          "Código de verificação inválido, tente novamente."
        ) {
          setModalStatus(true);
          setModalMessage(
            "Código enviado não é valido, tente novamente com um novo código"
          );
        } else {
          setModalStatus(true);
          setModalMessage(
            `Erro ao realizar confirmação do e-mail ${email}, tente novamente`
          );
        }
      }
    },
    [email, confirmationCode, api]
  );

  const handleResendCode = useCallback(async () => {
    try {
      await api.makeHttpRequest({
        method: "POST",
        url: `/users/resende-confirmation-code`,
        data: {
          email
        }
      });

      setModalStatus(true);
      setModalMessage(`Código de confirmação enviado para ${email}`);
      setModalType("info");
    } catch (error) {
      if (error.response.data.message === "User not found") {
        setModalStatus(true);
        setModalMessage("Email não encontrado");
        setModalType("error");
      } else {
        setModalStatus(true);
        setModalMessage(
          `Erro ao reenviar código de confirmação para ${email}, tente novamente`
        );
        setModalType("error");
      }
      console.log(error.response.data.message);
    }
  }, [email, api]);

  const handleConfirmButton = useCallback(() => {
    if (type === "redirect") {
      history.push("/auth/login");
    } else if (type === "close") {
      setModalStatus(false);
    }
  }, [history, type]);

  useEffect(() => {
    const queryEmail = location.search.split("=")[1];
    if (queryEmail !== undefined) {
      setEmail(queryEmail);
      setModalStatus(true);
      setModalMessage("Necessário confirmar email para seguir com login");
      setModalType("info");
      setFormState("Confirm");
    }
  }, [location]);

  useEffect(() => {
    if (flagCompany === "pj") {
      setCpf("notEmpty");
      setCpfOK("ok");
    } else {
      setCnpj("notEmpty");
      setCnpjOk("ok");
    }
  }, [flagCompany]);

  return (
    <>
      <Loading loading={loading} />
      <StatusModal
        isOpen={modalStatus}
        onConfirm={() => handleConfirmButton()}
        text={modalMessage}
        confirmButtonText="Continuar"
        type={modaltype}
      />
      <div className={`${styles.modal} ${privacyOpen && styles.modalOpen}`}>
        <div className={`${styles.modalContent} ${styles.modalContentTerms}`}>
          <div className={`${styles.modalTerms}`}>
            <h2>POLÍTICA DE PRIVACIDADE</h2>
            <div>
              <TextPrivacy />
            </div>
            <div className={styles.modalButton}>
              <button
                onClick={() => {
                  setPrivacyOpen(false);
                }}
              >
                Fechar
                <FiX />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className={`${styles.modal} ${termsOpen && styles.modalOpen}`}>
        <div className={`${styles.modalContent} ${styles.modalContentTerms}`}>
          <div className={`${styles.modalTerms}`}>
            <h2>TERMOS E CONDIÇÕES GERAIS DE USO</h2>
            <div>
              <TextTerms />
            </div>
            <div className={styles.modalButton}>
              <button
                onClick={() => {
                  setTermsOpen(false);
                }}
              >
                Fechar
                <FiX />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.container}>
        {formState === "SignUp" && (
          <form className={styles.form} onSubmit={handleFormSubmit}>
            <span className={styles.formTitle}>Faça o seu cadastro</span>
            <div className={styles.formContent}>
              <div className={styles.inputsContainer}>
                <div className={styles.inputContainer}>
                  <div className={styles.input} role={nameOK}>
                    <label htmlFor="name">
                      {flagCompany === "pj" ? "Razão Social" : "Nome"}
                    </label>
                    <input
                      type="text"
                      name="name"
                      placeholder={
                        flagCompany === "pj" ? "Nome da Empresa" : "Seu Nome"
                      }
                      onChange={e => handleInputName(e)}
                      value={name}
                    />
                    <AiOutlineCheck className={styles.check} role={nameOK} />
                    <BsExclamation className={styles.danger} role={nameOK} />
                  </div>
                  <p className={styles.error}>{nameError}</p>
                </div>
                <div className={styles.inputContainer}>
                  <div className={styles.input} role={emailOK}>
                    <label htmlFor="email">E-Mail</label>
                    <input
                      type="text"
                      name="email"
                      placeholder="Digite o e-mail"
                      onChange={handleInputEmail}
                      value={email}
                    />
                    <AiOutlineCheck className={styles.check} role={emailOK} />
                    <BsExclamation className={styles.danger} role={emailOK} />
                  </div>
                  <p className={styles.error}>{emailError}</p>
                </div>
                <div
                  className={`${styles.inputContainer} ${styles.inputContainerSmall}`}
                >
                  <div className={styles.input} role={cnpjOK}>
                    <label htmlFor={flagCompany === "pj" ? "cnpj" : "cpf"}>
                      {flagCompany === "pj" ? "CNPJ" : "CPF"}
                    </label>
                    <input
                      type="text"
                      name={flagCompany === "pj" ? "cnpj" : "cpf"}
                      placeholder={`Digite o ${flagCompany === "pj" ? "CNPJ" : "CPF"
                        }`}
                      onChange={
                        flagCompany === "pj" ? handleInputCnpj : handleInputCpf
                      }
                      value={flagCompany === "pj" ? cnpj : cpf}
                    />

                    {flagCompany === "pj" && (
                      <>
                        <AiOutlineCheck
                          className={styles.check}
                          role={cnpjOK}
                        />
                        <BsExclamation
                          className={styles.danger}
                          role={cnpjOK}
                        />
                      </>
                    )}

                    {flagCompany === "pf" && (
                      <>
                        <AiOutlineCheck className={styles.check} role={cpfOK} />
                        <BsExclamation className={styles.danger} role={cpfOK} />
                      </>
                    )}
                  </div>
                  <p className={styles.error}>
                    {flagCompany === "pj" ? cnpjError : cpfError}
                  </p>
                </div>
                <div
                  className={`${styles.inputContainer} ${styles.inputContainerSmall}`}
                >
                  <div className={styles.input} role={phoneOK}>
                    <label htmlFor="phone">Telefone</label>
                    <input
                      type="text"
                      name="phone"
                      placeholder="Digite o telefone"
                      onChange={handleInputPhone}
                      value={phone}
                    />
                    <AiOutlineCheck className={styles.check} role={phoneOK} />
                    <BsExclamation className={styles.danger} role={phoneOK} />
                  </div>
                  <p className={styles.error}>{phoneError}</p>
                </div>
                <div
                  className={`${styles.inputContainer} ${styles.inputContainerSmall}`}
                >
                  <div className={styles.input} role={passwordOK}>
                    <label htmlFor="password">Senha</label>
                    <input
                      type={visible ? "password" : "text"}
                      name="password"
                      placeholder="Digite sua senha"
                      value={password}
                      onChange={handleInputPassword}
                    />
                    {visible ? (
                      <FiEyeOff
                        onClick={() => {
                          setVisible(!visible);
                        }}
                        style={{ cursor: "pointer", marginRight: "8px" }}
                      />
                    ) : (
                      <FiEye
                        onClick={() => {
                          setVisible(!visible);
                        }}
                        style={{ cursor: "pointer", marginRight: "8px" }}
                      />
                    )}
                    <AiOutlineCheck
                      className={styles.check}
                      role={passwordOK}
                    />
                    <BsExclamation
                      className={styles.danger}
                      role={passwordOK}
                    />
                  </div>
                  <p className={styles.error}>{passwordError}</p>
                </div>
                <div
                  className={`${styles.inputContainer} ${styles.inputContainerSmall}`}
                >
                  <div className={styles.input} role={confirmPasswordOK}>
                    <label htmlFor="password">Confirmar senha</label>
                    <input
                      type={visibleConfirm ? "password" : "text"}
                      name="password"
                      placeholder="Digite sua senha"
                      value={confirmPassword}
                      onChange={handleInputConfirmPassword}
                    />
                    {visibleConfirm ? (
                      <FiEyeOff
                        onClick={() => {
                          setVisibleConfirm(!visibleConfirm);
                        }}
                        style={{ cursor: "pointer", marginRight: "8px" }}
                      />
                    ) : (
                      <FiEye
                        onClick={() => {
                          setVisibleConfirm(!visibleConfirm);
                        }}
                        style={{ cursor: "pointer", marginRight: "8px" }}
                      />
                    )}
                    <AiOutlineCheck
                      className={styles.check}
                      role={confirmPasswordOK}
                    />
                    <BsExclamation
                      className={styles.danger}
                      role={confirmPasswordOK}
                    />
                  </div>
                  <p className={styles.error}>{confirmPasswordError}</p>
                </div>
              </div>
            </div>
            <div className={styles.summaryTerms}>
              <div>
                {privacyChecked ? (
                  <FiCheck
                    style={{
                      background: "#409E6A",
                      color: "#fff",
                      padding: "4.5px",
                      strokeWidth: "4px",
                      borderRadius: "4px"
                    }}
                    onClick={() => setPrivacyChecked(!privacyChecked)}
                  />
                ) : (
                  <FiSquare
                    onClick={() => setPrivacyChecked(!privacyChecked)}
                  />
                )}
                <p>
                  Ao informar meus dados, eu concordo com a &nbsp;
                  <span onClick={() => setPrivacyOpen(true)}>
                    Política de Privacidade
                  </span>
                  &nbsp;e com os &nbsp;
                  <span onClick={() => setTermsOpen(true)}>Termos de Uso</span>
                </p>
              </div>
            </div>

            <div className={styles.buttonsContainer}>
              <div className={styles.buttonContainerBack}>
                <button
                  type="button"
                  onClick={() => history.push("/auth/login")}
                >
                  Voltar
                </button>
              </div>
              <div className={styles.buttonContainer}>
                <button type="submit">Cadastrar</button>
              </div>
            </div>
          </form>
        )}
        {formState === "Confirm" && (
          <form className={styles.form} onSubmit={handleConfirmSignUp}>
            <p className={styles.formTitle}>
              Realize a confirmação do cadastro
              <span className={styles.subTitle}>
                Atenção: O e-mail contendo o código de confirmação pode ter sido
                direcionado para o lixo eletrônico ou spam
              </span>
            </p>
            <div className={styles.formContent}>
              <div className={styles.inputsContainer}>
                <div className={styles.inputContainer}>
                  <div className={styles.input} role={confirmationCodeOK}>
                    <label htmlFor="code">Código de confirmação</label>
                    <input
                      type="text"
                      name="code"
                      placeholder="Digite o código de confirmação"
                      onChange={e => handleInputCode(e)}
                      value={confirmationCode}
                    />
                    <AiOutlineCheck
                      className={styles.check}
                      role={confirmationCodeOK}
                    />
                    <BsExclamation
                      className={styles.danger}
                      role={confirmationCodeOK}
                    />
                  </div>
                  <p className={styles.error}>{confirmationCodeError}</p>
                </div>
              </div>
            </div>
            <div className={styles.resendCode} onClick={handleResendCode}>
              <p>Reenviar código de confirmação</p>
            </div>
            <div className={styles.buttonsContainer}>
              <div
                className={styles.buttonContainerBack}
                onClick={() => setFormState("SignUp")}
              >
                <button type="button" onClick={() => setFormState("SignUp")}>
                  Voltar
                </button>
              </div>
              <div className={styles.buttonContainer}>
                <button type="submit">Confirmar</button>
              </div>
            </div>
          </form>
        )}
      </div>
    </>
  );
};

export { RegisterForm };
