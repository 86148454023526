import React, { useCallback, useEffect, useState } from "react";
import { FiArrowLeft, FiEdit3, FiPrinter, FiSearch } from "react-icons/fi";
import { ImBlocked, ImWarning } from "react-icons/im";
import ReactModal from "react-modal";
import { useHistory } from "react-router-dom";
import { BrComerceApiService } from "../../../../services/BrComerceApiService";
import store from "../../../../store/store";
import { CancelShipping } from "../CancelShipping";
import { ForcePrePostShipping } from "../ForcePrePostShipping";
import Swal from "sweetalert2";
import { TbDots } from "react-icons/tb";
import { FiInfo } from 'react-icons/fi';
import { AiOutlineCheck } from "react-icons/ai";
import { BsExclamation } from "react-icons/bs";

import Switch from "react-switch";
import { emailValidation } from "../../../../utils/EmailValidation";



import { RateTypeShippingEnum } from "../../../../enums/RateTypeShippingEnum";

import styles from "./styles.module.scss";
import DeliveryTypeEnum from "../../../../enums/DeliveryTypeEnum";
import { Refresh, SyncProblem } from "@material-ui/icons";
import SaraErrorModal from "../SaraErrorModal";
import { StyledTooltip } from "../../components/Tooltip";
import ProfileEnum from "../../../../enums/ProfileEnum";

const ActionsMenu = ({
  shipping,
  setFunction,
  setLoading,
  getShippingTracking,
  selectedStatusInFilter,
  search
}) => {
  const api = new BrComerceApiService();
  const history = useHistory();
  const { auth } = store.getState();
  const { user } = auth;

  const isAdmin = user?.profile === ProfileEnum.ADMIN.value;

  const activeShippingStatus = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 13, 15, 16, 17, 18];
  const isShippingActive = activeShippingStatus.includes(
    Number(shipping.idShippingStatus)
  );

  const reverseOrNormal =
    shipping?.isLogisticsReverse === true ? "reverse" : "normal";
  const hasSaraError =
    shipping?.hasSaraError === true && shipping?.isValidated === false;

  const [showCancelShippingModal, setShowCancelShippingModal] = useState(false);
  const [showForcePrePostModal, setShowForcePrePostModal] = useState(false);
  const [
    showValidateShippingWithSaraError,
    setShowValidateShippingWithSaraError
  ] = useState(false);
  const [activeShipingId, setActiveShipingId] = useState(0);
  const [showDots, setShowDots] = useState(false);
  const [showPrePostModal, setShowPrePostModal] = useState(
    false
  );
  const [newDestination, setNewDestination] = useState(false);
  const [insurance, setInsurance] = useState(false);
  const [reverseEmail, setReverseEmail] = useState('');
  const [emailOK, setEmailOK] = useState("vazio");
  const [emailError, setEmailError] = useState("");

  const modalStyle = {
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "rgba(51, 51, 51, 0.75)",
      zIndex: 999
    },
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      transform: "translate(-50%, -50%)",
      zIndex: 999,
      border: "none",
      backgroundColor: "transparent",
      padding: 0
    }
  };

  const handlePrintShippings = async id => {
    try {
      setLoading(true);

      const result = await api.makeHttpRequest({
        method: "GET",
        url: `/collects/${shipping?.idCollect}`
      });

      const idInvoicesStatus = result?.invoice?.idInvoicesStatus;

      if (idInvoicesStatus === 1 || idInvoicesStatus === 5) {
        window.localStorage.setItem("Brcom:Etiquetas", JSON.stringify([id]));
        window.open("/etiquetas", "_blank");
      } else {
        return Swal.fire({
          title: "Ops!",
          text: "Etiquetas indisponíveis",
          icon: "warning"
        });
      }
    } catch (err) {
      console.log("Erro ao buscar coleta: ", err);
      return Swal.fire({
        title: "Erro!",
        text: "Não foi possível achar a coleta",
        icon: "error"
      });
    } finally {
      setLoading(false);
    }
  };

  const handleDetailsShipping = id => {
    window.open(`/detalhes-objeto/${id}`);
  };

  const handleSaraError = () => {
    if (showValidateShippingWithSaraError) {
      setShowValidateShippingWithSaraError(false);
      setShowValidateShippingWithSaraError(true);
    }
    setShowValidateShippingWithSaraError(!showValidateShippingWithSaraError);
  };

  const handleDetailsRoute = useCallback(async () => {
    setLoading(true);
    try {
      const response = await api.makeHttpRequest({
        method: "GET",
        url: `/route-shipping-admin/${shipping?.id}`
      });

      if (response?.length) {
        const idRoute = response[0].id_route;
        window.open(`/detalhes-rota/${idRoute}`);
      } else {
        setLoading(false);
        Swal.fire({
          title: "Ops!",
          text: "O envio ainda não pertece a uma rota",
          icon: "warning"
        });
      }
    } catch (error) {
      console.log(
        "🚀 ~ file: index.jsx:76 ~ handleDetailsRoute ~ error:",
        error
      );
      setLoading(false);
      Swal.fire({
        title: "Erro",
        text:
          "Não foi possível buscar uma rota para esse envio. Tente novamente mais tarde!",
        icon: "error"
      });
    }
    setLoading(false);
  }, [api, setLoading, shipping?.id]);

  const handlePrePostar = useCallback(
    async shipping => {
      setLoading(true);
      try {
        await api.makeHttpRequest({
          method: "POST",
          url: `/shipping/${shipping?.id}/prepostar`
        });

        setLoading(false);
        Swal.fire({
          title: "Sucesso!",
          text: "Objeto enviado para a fila de pré-postagem com sucesso.",
          icon: "success",
          showCancelButton: false,
          confirmButtonText: "Ok"
        }).then(() => {
          if (window.location.href.includes("rastrear-encomenda")) {
            getShippingTracking(selectedStatusInFilter, search);
          }

          return;
        });
      } catch (e) {
        console.log(e);

        setLoading(false);
        Swal.fire({
          icon: "error",
          title: "Coletas",
          text: "Não foi possível pré-postar o objeto."
        });
      }
    },
    [api, getShippingTracking, search, selectedStatusInFilter, setLoading]
  );

  const handleUpdateTracking = useCallback(
    async shipping => {
      setLoading(true);
      try {
        await api.makeHttpRequest({
          method: "POST",
          url: `/shipping/${shipping?.id}/update-tracking`
        });

        setLoading(false);
        Swal.fire({
          title: "Sucesso!",
          text: "Objeto atualizado com sucesso.",
          icon: "success",
          showCancelButton: false,
          confirmButtonText: "Ok"
        }).then(() => {
          if (window.location.href.includes("rastrear-encomenda")) {
            getShippingTracking(selectedStatusInFilter, search);
          }

          return;
        });
      } catch (e) {
        console.log(e);

        setLoading(false);
        Swal.fire({
          icon: "error",
          title: "Rastreio",
          text: "Não foi possível atualizar o objeto."
        });
      }
    },
    [api, getShippingTracking, search, selectedStatusInFilter, setLoading]
  );

  const handleReverseShippingRequest = useCallback(
    async shipping => {
      if (shipping?.idShippingStatus < 8 || shipping?.idShippingStatus > 10) {
        return;
      }

      if (shipping?.reverseShippingId !== null) {
        history.push(`/detalhes-objeto/${shipping?.reverseShippingId}`);
        return;
      }

      try {
        setLoading(true);

        const reverseData = {
          ...(reverseEmail !== "" && { logistic_reverse_email: reverseEmail }),
          insurance: insurance ?? false
        }

        await api.makeHttpRequest({
          method: "POST",
          url: `/reverse-shipping/${shipping?.id}`,
          data: reverseData
        });

        history.push("/carrinho");
      } catch (e) {
        console.log(e);
        Swal.fire({
          title: "Erro",
          text: "Um erro ocorreu ao tentar criar a logistica reversa",
          icon: "error",
          confirmButtonColor: "#409e6a"
        });
      } finally {
        setLoading(false);
      }
    },
    [api, history, setLoading]
  );

  const handleShowDots = shipping => {
    let count = 0;

    if (isShippingActive && isAdmin) {
      count = +1;
    }

    if (
      !shipping?.prePostado &&
      user.profile === "admin" &&
      shipping?.rateType === RateTypeShippingEnum.COLLECT &&
      shipping?.type !== DeliveryTypeEnum.OWN_DELIVERY &&
      !shipping?.authorizationCode &&
      (shipping?.user?.sendToPrepost || shipping?.prePostado === false)
    ) {
      count = +1;
    }

    if (
      !shipping?.prePostado &&
      user.profile === "admin" &&
      shipping?.rateType === RateTypeShippingEnum.COLLECT &&
      shipping?.type !== DeliveryTypeEnum.OWN_DELIVERY &&
      !shipping?.authorizationCode &&
      (shipping?.user?.sendToPrepost || shipping?.prePostado === false)
    ) {
      count = +1;
    }

    if (
      shipping?.idCollect &&
      shipping?.numeroPLP > 1 &&
      shipping?.idShippingStatus !== 14 &&
      !shipping?.deliveredDate
    ) {
      count = +1;
    }

    if (
      shipping?.deliveredDate &&
      (shipping?.status?.id === 10 || shipping?.idShippingStatus === 10)
    ) {
      count = +1;
    }

    if (
      shipping?.status?.id >= 4 &&
      user.profile === "admin" &&
      shipping?.type === DeliveryTypeEnum.OWN_DELIVERY
    ) {
      count = +1;
    }

    if (hasSaraError && user?.profile === "admin") {
      count = +1;
    }

    if (count >= 1) {
      setShowDots(true);
    }
  };

  const handleConfirm = () => {
    if (newDestination) {
      handleReverseShippingRequest(shipping);
    } else {
      window.localStorage.setItem('Brcom:Reverse', JSON.stringify({ insurance, reverseEmail }))
      history.push(
        `/nova-coleta/${reverseOrNormal}-send-form?shipping=${shipping?.id}?isReverse`
      );
    }
  }

  function handleInputEmail(email) {
    handleValidationEmail(email);
    setReverseEmail(email);
  }

  const handleValidationEmail = useCallback(value => {
    const validEmail = emailValidation({
      email: value,
      emptyEmail: "",
      incorrectEmail: "Email incorreto"
    });

    setEmailOK(validEmail.status);
    setEmailError(validEmail.message);
  }, []);

  useEffect(() => {
    handleShowDots(shipping);
    setActiveShipingId(shipping?.id);
  }, [shipping]);

  return (
    <>
      <ReactModal
        isOpen={showCancelShippingModal}
        // dialogClassName="schedule-pickup-modal"
        onRequestClose={() => setShowCancelShippingModal(false)}
        style={modalStyle}
      >
        <CancelShipping
          shipping={shipping}
          setLoading={setLoading}
          setShowCancelShippingModal={setShowCancelShippingModal}
          setFunction={setFunction}
        />
      </ReactModal>
      <ReactModal
        isOpen={showForcePrePostModal}
        onRequestClose={() => setShowForcePrePostModal(false)}
        style={modalStyle}
      >
        <ForcePrePostShipping
          shipping={shipping}
          setLoading={setLoading}
          setShowForcePrePostModal={setShowForcePrePostModal}
          setFunction={setFunction}
        />
      </ReactModal>

      <ReactModal
        isOpen={showPrePostModal}
        onRequestClose={() => setShowPrePostModal(false)}
        style={modalStyle}
      >
        <div className={styles.prePostModal}>
          <FiInfo color="#F5CB2B" size={40} />
          <h3>Logistica reversa</h3>

          <div>
            <span>
              O endereço de devolução será o mesmo cadasatrado no remetente da encomenda?
            </span>

            <Switch
              checked={newDestination}
              onChange={() =>
                setNewDestination(!newDestination)
              }
              offColor="#B3B3B3"
              onColor="#409E6A"
              offHandleColor="#FFF"
              onHandleColor="#FFF"
              uncheckedIcon={false}
              checkedIcon={false}
              width={45}
              height={23}
              handleDiameter={13}
            />
          </div>

          <div style={{ width: '100%', justifyContent: 'space-between' }}>
            <span>
              Deseja habilitar seguro?
            </span>

            <Switch
              checked={insurance}
              onChange={() =>
                setInsurance(!insurance)
              }
              offColor="#B3B3B3"
              onColor="#409E6A"
              offHandleColor="#FFF"
              onHandleColor="#FFF"
              uncheckedIcon={false}
              checkedIcon={false}
              width={45}
              height={23}
              handleDiameter={13}
            />
          </div>

          <div style={{ flexDirection: 'column' }}>
            <span>
              Se desejar informe o email do remetente para o correio enviar o código de autorização
            </span>

            <div className={styles.input} role={emailOK}>
              <input
                type="text"
                name="email"
                placeholder="Digite o email"
                onChange={e => handleInputEmail(e.target.value)}
                value={reverseEmail}
              />
              <AiOutlineCheck className={styles.check} role={emailOK} />
              <BsExclamation className={styles.danger} role={emailOK} />
            </div>
            {emailError && <p className={styles.error}>{emailError}</p>}
          </div>

          <div className={styles.buttonContainer}>
            <button
              onClick={() => {
                setShowPrePostModal(false);
              }}
              style={{ backgroundColor: "#F5CB2B" }}
            >
              Cancelar
            </button>
            <button
              onClick={handleConfirm}
              style={{ backgroundColor: "#409e6a" }}
            >
              Confirmar
            </button>
          </div>
        </div>
      </ReactModal>

      <div className={styles.actionsWrapper}>
        <div className={styles.actionsList}>
          {!!shipping?.sro && (
            <StyledTooltip text="Imprimir" position="top" arrow>
              <button onClick={() => handlePrintShippings(shipping?.id)}>
                <FiPrinter size={20} color="#409E6A" />
              </button>
            </StyledTooltip>
          )}
          {(shipping?.numeroPLP <= 1 || shipping?.numeroPLP === "null") &&
            shipping?.collect?.invoice?.method === "billing" && (
              <StyledTooltip text="Editar" position="top" arrow>
                <button
                  onClick={() =>
                    history.push(
                      `/nova-coleta/${reverseOrNormal}-send-form?shipping=${shipping?.id}`
                    )
                  }
                >
                  <FiEdit3 size={20} color="#409E6A" />
                </button>
              </StyledTooltip>
            )}
          <StyledTooltip text="Detalhes" position="top" arrow>
            <button onClick={() => handleDetailsShipping(shipping?.id)}>
              <FiSearch size={20} color="#409E6A" />
            </button>
          </StyledTooltip>
        </div>
        <div className={styles.tooltipWrapper}>
          {activeShipingId === shipping?.id && (
            <SaraErrorModal
              key={activeShipingId}
              isOpen={showValidateShippingWithSaraError}
              setIsOpen={setShowValidateShippingWithSaraError}
              fromActionMenu={true}
              singleShippingId={activeShipingId}
              getShippingTracking={getShippingTracking}
              selectedStatusInFilter={selectedStatusInFilter}
              search={search}
            />
          )}

          <div id="tooltip" className={styles.tooltip}>
            {isAdmin && isShippingActive && (
              <button onClick={() => handleUpdateTracking(shipping)}>
                <Refresh />
                Atualizar rastreio
              </button>
            )}
            {!shipping?.prePostado &&
              isAdmin &&
              shipping?.rateType === RateTypeShippingEnum.COLLECT &&
              shipping?.type !== DeliveryTypeEnum.OWN_DELIVERY &&
              !shipping?.authorizationCode &&
              (shipping?.user?.sendToPrepost ||
                shipping?.prePostado === false) && (
                <button onClick={() => handlePrePostar(shipping)}>
                  <ImWarning />
                  Pré-Postar Objeto
                </button>
              )}
            {!shipping?.prePostado &&
              isAdmin &&
              shipping?.rateType === RateTypeShippingEnum.COLLECT &&
              shipping?.type !== DeliveryTypeEnum.OWN_DELIVERY &&
              !shipping?.authorizationCode &&
              (shipping?.user?.sendToPrepost ||
                shipping?.prePostado === false) && (
                <button onClick={() => setShowForcePrePostModal(true)}>
                  <ImWarning style={{ fontSize: 25 }} />
                  Forçar como Pré-Postado
                </button>
              )}
            {shipping?.idCollect &&
              shipping?.numeroPLP > 1 &&
              shipping?.idShippingStatus !== 14 &&
              !shipping?.deliveredDate && (
                <button onClick={() => setShowCancelShippingModal(true)}>
                  <ImBlocked />
                  Bloquear Objeto
                </button>
              )}
            {shipping?.deliveredDate &&
              (shipping?.status?.id === 10 ||
                shipping?.idShippingStatus === 10) && (
                <button
                  onClick={() =>
                    shipping?.reverseShippingId
                      ? history.push(
                          `/detalhes-objeto/${shipping?.reverseShippingId}`
                        )
                      :  setShowPrePostModal(true)
                  }
                  type="button"
                >
                  <FiArrowLeft />
                  {shipping?.reverseShippingId !== null
                    ? "Acompanhar Reversa"
                    : "Solicitar Reversa"}
                </button>
              )}
            {(shipping?.status?.id >= 4 || shipping?.idShippingStatus >= 4) &&
              isAdmin &&
              shipping?.type === DeliveryTypeEnum.OWN_DELIVERY && (
                <button onClick={() => handleDetailsRoute()}>
                  <FiSearch />
                  Detalhes da Rota
                </button>
              )}
            {hasSaraError && isAdmin && (
              <button onClick={() => handleSaraError()}>
                <SyncProblem />
                Validar objeto
              </button>
            )}
          </div>
          {showDots && <TbDots size={24} color="#409E6A" />}
        </div>
      </div>
    </>
  );
};

export { ActionsMenu };
