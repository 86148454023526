export const OriginEnum = Object.freeze({
    BLING: "bling",
    BLINGV3: "blingV3",
    BRCOMERCE: "brcomerce",
    NUVEMSHOP: "nuvemshop",
    TRAY: "tray",
    TRAYCORP: "traycorp",
    VTEX: "vtex",
    WOOCOMMERCE: "woocommerce",
    YAMPI: "yampi",
    APP: "app",
    INTEGRATION: "integration",
    VNDA: "vnda"
});
