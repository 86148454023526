import React from "react";
import { useHistory } from "react-router-dom";
import { BulletPoint, SlideCarousel } from "./components";
import "react-responsive-carousel/lib/styles/carousel.css"; // requires a loader

import optionsIntegrations from "../../../../../assets/images/integration/optionsIntegrations.png";

import blingV3_Card from "../../../../../assets/images/bling/blingCard.png";

import blingV3_3 from "../../../../../assets/images/blingV3/blingV3_3.png";
import blingV3_4 from "../../../../../assets/images/blingV3/blingV3_4.png";
import blingV3_5 from "../../../../../assets/images/blingV3/blingV3_5.png";
import blingV3_6 from "../../../../../assets/images/blingV3/blingV3_6.png";
import blingV3_7 from "../../../../../assets/images/blingV3/blingV3_7.png";
import blingV3_8 from "../../../../../assets/images/blingV3/blingV3_8.png";
import blingV3_9 from "../../../../../assets/images/blingV3/blingV3_9.png";
import blingV3_10 from "../../../../../assets/images/blingV3/blingV3_10.png";
import blingV3_11 from "../../../../../assets/images/blingV3/blingV3_11.png";
import blingV3_12 from "../../../../../assets/images/blingV3/blingV3_12.png";
import blingV3_13 from "../../../../../assets/images/blingV3/blingV3_13.png";
import blingV3_14 from "../../../../../assets/images/blingV3/blingV3_14.png";
import blingV3_15 from "../../../../../assets/images/blingV3/blingV3_15.png";

import brcomerceLogo from "../../../../../assets/logo/logo_brcomerce_250x250.svg";

import activeOrderCreation from "../../../../../assets/images/active-order-create.png";
import styles from "./styles.module.scss";

const TutorialBlingV3 = () => {
  const carousel1 = [
    {
      image: blingV3_3
    },
    {
      image: blingV3_4
    },
  ];

  const carousel2 = [
    {
      image: blingV3_5
    },
    {
      image: blingV3_6
    },
    {
      image: blingV3_7
    }
  ];

  const carousel3 = [
    {
      image: blingV3_8
    },
    {
      image: blingV3_9
    },
  ];

  const carousel4 = [
    {
      image: blingV3_10
    },
    {
      image: optionsIntegrations
    },
    {
      image: blingV3_Card
    },
    {
      image: blingV3_11
    },
    {
      image: blingV3_12
    },
    {
      image: blingV3_13
    },
  ];

  const history = useHistory();

  return (
    <main className={styles.container}>
      <div className={styles.content}>
        <header className={styles.contentHeader}>
          <p>Como fazer a integração?</p>
        </header>
        <div className={styles.contentBody}>
          <section className={styles.contentSection}>
            <h4>Passo a passo para conectar sua plataforma Bling V3 à BRCom</h4>

            <BulletPoint order="1">
              <p>
                O primeiro passo é cadastrar um aplicativo na <b>Bling</b>.
                <br></br>
                Para isso acesse seu painel admin da <b>Bling</b>, no canto superior direito, em Todas as configurações, aba Cadastros, Cadastro de aplicativos.
              </p>
            </BulletPoint>

            <SlideCarousel steps={carousel1} />

            <BulletPoint order="2">
              <p>
                Crie um novo aplicativo usando as seguintes informações:
              </p>
            </BulletPoint>

            <SlideCarousel steps={carousel2} />

            <p>
              Logo: <a
                download="brcomerce-logo.svg"
                href={brcomerceLogo}
                title="Logo BRComerce"
              >
                Baixar logo BRComerce
              </a>
              <br></br>
              Nome: BRComerce
              <br></br>
              {`Categoria: Gestão de Entregas > Transportadora`}
              <br></br>
              Descrição: Aplicativo para integrar a BRComerce a Bling
              <br></br>
              URL: https://app.brcomerce.com.br/callback/blingV3
            </p>

            <BulletPoint order="3">
              <p>
                Na Lista de escopos, adicione todas as opções e salve o aplicativo.
              </p>
            </BulletPoint>

            <SlideCarousel steps={carousel3} />

            <BulletPoint order="4">
              <p>
                Agora que temos o <b>Client ID</b> e <b>Client Secret</b>, acesse a integração Bling V3 e autentique o usuário.
              </p>
            </BulletPoint>

            <SlideCarousel steps={carousel4} />

            <BulletPoint order="5">
              <p>
                Preencha os campos de endereço, status de movimentação e seguro e salve.
              </p>
            </BulletPoint>

            <div className={`${styles.imgs} `}>
              <img
                src={blingV3_14}
                alt="Tutorial Bling 14"
                className={styles.cardImage}
              />
            </div>

            <BulletPoint order="6">
              <p>
                Pronto! Agora seus pedidos a importação dos pedidos está ativa manualmente e automaticamente.
              </p>
            </BulletPoint>

            <div className={`${styles.imgs} `}>
              <img
                src={blingV3_15}
                alt="Tutorial Bling 15"
                className={styles.cardImage}
              />
            </div>

            <BulletPoint order="7">
              <p>
                Caso queira desativar a criação de pedido nessa integração, vire a chave para "Desativado"
                <br></br>
                As demais funcionalidades relacionadas a essa integração continuarão ativas.
              </p>
            </BulletPoint>

            <div className={styles.imgs}>
              <img
                src={activeOrderCreation}
                alt="activeOrderCreation"
                className={styles.cardImage}
              />
            </div>


            <button
              onClick={() => history.push("/integracoes")}
              className={`${styles.button} ${styles.btnBack}`}
            >
              Voltar
            </button>
          </section>
        </div>
      </div>
    </main>
  );
};

export { TutorialBlingV3 };
