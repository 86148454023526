import React, {
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { endOfMonth, format, getMonth } from "date-fns";
import { Col, Row } from "react-bootstrap";

import InputSelect from "../../../../../../../components/InputSelect";
import InputText from "../../../../../../../components/InputText";
import { Months as monthsList } from "../../../../../../../constants/Months";
import ShippingStagesEnum from "../../../../../../../enums/ShippingStagesEnum";
import { ReactComponent as ClearSvg } from "../../../../../../../assets/icons/clear.svg";
import { ReactComponent as CalendarIcon } from "../../../../../../../assets/icons/mdi-calendar.svg";

import { FiFilter } from "react-icons/fi";
import styles from "./adminFilters.module.scss";
import { Calendar } from "../../Calendar";
import { definedsDates } from "../../Calendar/config";

const selectOptionsGranularity = [
  { value: "daily", label: "Diariamente" },
  { value: "monthly", label: "Mensalmente" },
  { value: "yearly", label: "Anualmente" }
];

const AdminFilters = ({ onResetFilter, onFilter, clientsOptions }) => {
  const actualDate = new Date();
  const calendarWrapperRef = useRef(null);

  const selectYearOptions = useMemo(() => {
    const initialSoftwareDate = 2021;
    const actualYear = new Date().getFullYear();

    const years = [];
    for (let y = initialSoftwareDate; y <= actualYear; y++) {
      years.push({
        label: y,
        value: y
      });
    }

    return years;
  }, []);

  const selectStatusOptions = useMemo(() => {
    const status = Object.entries(ShippingStagesEnum).map(([key, value]) => {
      return {
        label: value.message,
        value: value.id
      };
    });

    status.splice(0, 0, {
      label: "Todos",
      value: 0
    });

    return status;
  }, []);

  const selectMonthOptions = useMemo(() => {
    const allMonths = monthsList.map(month => ({
      value: month.id,
      label: month.label
    }));

    if (year && year.value === actualDate.getFullYear()) {
      const getMonthsOfActualYear = allMonths.filter(
        months => months.value <= getMonth(actualDate) + 1
      );

      return getMonthsOfActualYear;
    }

    return allMonths;
    // eslint-disable-next-line no-use-before-define
  }, [actualDate, year]);

  const initialFilters = {
    clientSub: [],
    year: selectYearOptions.at(-1),
    month: selectMonthOptions[getMonth(actualDate)],
    granularity: selectOptionsGranularity.at(1),
    status: selectStatusOptions.at(0),
    initialDate: definedsDates.startOfLastSevenDay,
    endDate: definedsDates.endOfToday
  };

  // Used for save actual period selected.
  const [period, setPeriod] = useState({
    string: "",
    value: [
      {
        startDate: initialFilters.initialDate,
        endDate: initialFilters.endDate,
        key: "selection"
      }
    ]
  });

  // Used for save period selected in calendar, but doesn't was submitted.
  const [periodDate, setPeriodDate] = useState([
    {
      startDate: initialFilters.initialDate,
      endDate: initialFilters.endDate,
      key: "selection"
    }
  ]);

  const [isShowCalendar, setIsShowCalendar] = useState(false);
  const [clientsSubs, setClientsSubs] = useState(initialFilters.clientSub);
  const [year, setYear] = useState(initialFilters.year);
  const [month, setMonth] = useState(initialFilters.month);
  const [granularity, setGranularity] = useState(initialFilters.granularity);
  const [status, setStatus] = useState(initialFilters.status);

  const mapperToPeriodDates = (
    dateInCalendarPeriod,
    formatDate = "MM/dd/yyyy"
  ) => {
    const { endDate, startDate } = dateInCalendarPeriod;
    const initialDateFormatted = format(startDate, formatDate);
    const endDateFormatted = format(endDate, formatDate);

    return {
      endDate: endDateFormatted,
      startDate: initialDateFormatted
    };
  };

  const mapStatesToFilters = allFilters => {
    const {
      granularity,
      month,
      status,
      year,
      endDate,
      initialDate,
      clientsSubs
    } = allFilters;

    const { endDate: endDateMapped, startDate } = mapperToPeriodDates({
      endDate,
      startDate: initialDate
    });

    const clientSubsID =
      clientsSubs && clientsSubs.length
        ? clientsSubs.map(client => client.value)
        : [];

    return {
      clientSub: clientSubsID,
      endDate: endDateMapped,
      granularity: granularity.value,
      initialDate: startDate,
      month: month.value,
      status: status.value === 0 ? null : status.value,
      year: year.value
    };
  };

  const handleFilterData = () => {
    const isDailyGranularity = granularity === "daily";

    const filters = mapStatesToFilters({
      status,
      clientsSubs,
      granularity,
      month,
      year,
      initialDate: isDailyGranularity
        ? new Date(year, month, 1)
        : period.value[0].startDate,
      endDate: isDailyGranularity
        ? new Date(year, month, endOfMonth(new Date(year, month, 1)))
        : period.value[0].endDate
    });

    onFilter(filters);
  };

  const handleResetFilterData = () => {
    const filters = mapStatesToFilters(initialFilters);
    const dates = {
      startDate: initialFilters.initialDate,
      endDate: initialFilters.endDate,
      key: "selection"
    };
    const { endDate, startDate } = mapperToPeriodDates(dates, "dd/MM/yyyy");

    setPeriod({
      string: `${startDate} - ${endDate}`,
      value: [dates]
    });
    setPeriodDate([dates]);
    setClientsSubs(initialFilters.clientSub);
    setYear(initialFilters.year);
    setMonth(initialFilters.month);
    setGranularity(initialFilters.granularity);
    setStatus(initialFilters.status);

    onResetFilter(filters);
  };

  const handleSetCalendarPeriod = () => {
    setIsShowCalendar(false);

    const { endDate, startDate } = mapperToPeriodDates(
      periodDate[0],
      "dd/MM/yyyy"
    );

    setPeriod({
      string: `${startDate} - ${endDate}`,
      value: periodDate
    });
  };

  const handleGranularity = e => {
    if (!e) {
      return setGranularity({ label: "Selecione a Granularidade", value: "" });
    }
    setGranularity(e);
  };

  const handleChangeYear = e => {
    if (!e) {
      return setYear({ label: "Selecione um ano", value: "" });
    }
    setYear(e);
  };
  const handleChangeMonth = e => {
    if (!e) {
      return setMonth({ label: "Selecione um mês", value: "" });
    }
    setMonth(e);
  };

  useEffect(() => {
    handleSetCalendarPeriod();
  }, []);

  useEffect(() => {
    if (year.value === actualDate.getFullYear()) {
      setMonth(selectMonthOptions[getMonth(actualDate)]);
    }
  }, [year]);

  useLayoutEffect(() => {
    if (isShowCalendar) {
      document.querySelector("body").style.overflow = "hidden";
      document.querySelector("#kt_content").style.paddingRight = "15px";

      const amountScrolledInDocument = document.body.scrollTop;
      calendarWrapperRef.current.style.top = `${Math.ceil(
        amountScrolledInDocument
      )}px`;
      return;
    }

    document.querySelector("body").style.overflow = "initial";
    document.querySelector("#kt_content").style.paddingRight = "inherit";
  }, [isShowCalendar]);

  useEffect(() => {
    handleFilterData();
  }, []);

  return (
    <>
      <Row className="d-flex">
        <Col xs={12} sm={4}>
          <InputSelect
            id="select_client"
            placeholder="Selecione o cliente"
            options={clientsOptions}
            onChange={option => setClientsSubs(option)}
            value={clientsSubs}
            isMulti
          />
        </Col>
        <Col xs={12} sm={2}>
          <InputSelect
            id="select_granularity"
            placeholder="Selecione a Granularidade"
            options={selectOptionsGranularity}
            isClearable={granularity.value}
            onChange={handleGranularity}
            value={granularity}
          />
        </Col>

        {granularity && granularity.value === "daily" && (
          <>
            <Col xs={12} sm={2}>
              <InputSelect
                id="select_month"
                placeholder="Selecione um mês"
                options={selectMonthOptions}
                isClearable={month.value}
                onChange={handleChangeMonth}
                value={month}
              />
            </Col>
            <Col xs={12} sm={2}>
              <InputSelect
                id="select_year"
                placeholder="Selecione um ano"
                options={selectYearOptions}
                isClearable={year.value}
                onChange={handleChangeYear}
                value={year}
              />
            </Col>
          </>
        )}
        {((granularity && granularity.value === "yearly") ||
          granularity.value === "monthly") && (
          <Col xs={12} sm={3} style={{ position: "initial" }}>
            <div className={styles.calendarInput}>
              <InputText
                id="select_calendar"
                placeholder="Selecione no calendário"
                onChange={() => {}}
                onClick={() => setIsShowCalendar(true)}
                value={period.string}
                renderIcon={() => <CalendarIcon />}
              />
            </div>
            <div
              style={{
                display: isShowCalendar ? "flex" : "none"
              }}
              ref={calendarWrapperRef}
              className={styles.boxCalendar}
              onClick={e => {
                if (e.currentTarget !== e.target) return;

                setIsShowCalendar(false);
              }}
            >
              <div className={styles.calendarWrapper}>
                <Calendar
                  onChange={item => setPeriodDate([item.selection])}
                  ranges={periodDate}
                  minDate={new Date(selectYearOptions[0].value, 0, 1)}
                />
                <div className={styles.calendarFooter}>
                  <button onClick={() => setIsShowCalendar(false)}>
                    Cancelar
                  </button>
                  <button onClick={handleSetCalendarPeriod}>Aplicar</button>
                </div>
              </div>
            </div>
          </Col>
        )}
        <Col xs={12} sm={granularity && granularity.value === "daily" ? 2 : 3}>
          <InputSelect
            id="select_status"
            placeholder="Selecione um status"
            options={selectStatusOptions}
            onChange={setStatus}
            value={status}
          />
        </Col>
      </Row>

      <Row className="d-flex align-center my-3">
        <Col xs={12} sm="auto">
          <button
            type="button"
            className={styles.filterBtn}
            onClick={handleFilterData}
          >
            <FiFilter />
            <span>Filtrar</span>
          </button>
        </Col>

        <Col xs={12} sm="auto">
          <button
            type="button"
            className={`${styles.filterBtn} ${styles.filterClearBtn}`}
            onClick={handleResetFilterData}
          >
            <ClearSvg />
            <span>Limpar filtros</span>
          </button>
        </Col>
      </Row>
    </>
  );
};

export { AdminFilters };
