import moment from "moment";
import React from "react";
import { ImCheckmark } from "react-icons/im";
import { IoMdClose } from "react-icons/io";
import { RateTypeShippingEnum } from "../../../../../enums/RateTypeShippingEnum";
import formatCep from "../../../../../utils/FormatCep";
import { formatCPF } from "../../../../../utils/validators/IsValidCpfCnpj";
import styles from "./styles.module.scss";

import { ReactComponent as Box } from "../../../../../assets/icons/box.svg";
import { ReactComponent as BoxSearch } from "../../../../../assets/icons/box-search.svg";
import DeliveryTypeEnum from "../../../../../enums/DeliveryTypeEnum";

const CardConciliation = ({ conciliation }) => {
  const addressInfoHandled = address => {
    let finalAddress = address?.street;

    if (!!address?.number) {
      finalAddress += `, ${address?.number}`;
    }

    if (!!address?.complement) {
      finalAddress += `, ${address?.complement}`;
    }

    if (!!address?.district) {
      finalAddress += `, ${address?.district}`;
    }

    return finalAddress;
  };

  let conciliationProp;
  let conciliationPropDiff;
  let oldProp;

  const conciliationProperty = type => {
    switch (type) {
      case "height":
        conciliationProp = Number(conciliation?.shipping?.height);
        conciliationPropDiff = Number(conciliation?.heightDiff);
        oldProp = conciliationPropDiff + conciliationProp;
        break;

      case "weight":
        conciliationProp = Number(conciliation?.shipping?.weight);
        conciliationPropDiff = Number(conciliation?.weightDiff);
        oldProp = (conciliationPropDiff + conciliationProp).toFixed(2);
        break;

      case "billedWeight":
        // peso declarado
        const weight = Number(conciliation?.shipping?.weight);
        const length = Number(conciliation?.shipping?.length);
        const height = Number(conciliation?.shipping?.height);
        const width = Number(conciliation?.shipping?.width);
        const cubWeight = (length * height * width) / 6000;

        const oldWeight =
          Number(conciliation?.shipping?.weight) +
          Number(conciliation?.weightDiff);
        const oldWidth =
          Number(conciliation?.shipping?.width) +
          Number(conciliation?.widthDiff);
        const oldHeight =
          Number(conciliation?.shipping?.height) +
          Number(conciliation?.heightDiff);
        const oldLength =
          Number(conciliation?.shipping?.length) +
          Number(conciliation?.lengthDiff);
        const oldCubWeight = (oldLength * oldHeight * oldWidth) / 6000;
        const oldConciliationProp =
          oldCubWeight > 5 && oldWeight < oldCubWeight
            ? oldCubWeight.toFixed(2)
            : oldWeight.toFixed(2);

        conciliationPropDiff =
          cubWeight > 5 && weight < cubWeight ? cubWeight : 0;

        conciliationProp =
          cubWeight > 5 && weight < cubWeight
            ? cubWeight.toFixed(2)
            : weight.toFixed(2);

        oldProp = oldConciliationProp;
        break;

      case "width":
        conciliationProp = Number(conciliation?.shipping?.width);
        conciliationPropDiff = Number(conciliation?.widthDiff);
        oldProp = conciliationPropDiff + conciliationProp;
        break;

      case "length":
        conciliationProp = Number(conciliation?.shipping?.length);
        conciliationPropDiff = Number(conciliation?.lengthDiff);
        oldProp = conciliationPropDiff + conciliationProp;
        break;

      case "price":
        conciliationProp = Number(conciliation?.shipping?.price);
        conciliationPropDiff = Number(conciliation?.priceDiff);
        oldProp = conciliationPropDiff + conciliationProp;
        break;

      case "discount":
        conciliationProp = Number(conciliation?.shipping?.correios?.desconto);
        conciliationPropDiff = 0;
        oldProp = conciliationProp;
        break;

      case "type":
        conciliationProp = conciliation?.shipping?.correios?.type === DeliveryTypeEnum.OWN_DELIVERY ? "Super Expressa" : conciliation?.shipping?.correios?.type;
        conciliationPropDiff = conciliation?.typeDiff === DeliveryTypeEnum.OWN_DELIVERY ? "Super Expressa" : conciliation?.typeDiff;
        break;

      case "originCity":
        conciliationProp = `${conciliation?.shipping?.origin?.city}/${conciliation?.shipping?.origin?.state}`;
        conciliationPropDiff = conciliation?.originCityDiff;
        break;

      case "rateType":
        conciliationProp =
          conciliation?.shipping?.rateType === RateTypeShippingEnum.COLLECT
            ? "Sim"
            : "Não";
        conciliationPropDiff =
          conciliation?.rateTypeDiff !== "" &&
            conciliation?.rateTypeDiff === RateTypeShippingEnum.COLLECT
            ? "Sim"
            : "Não";
        break;

      default:
        conciliationProp = conciliation?.shipping?.format;
        conciliationPropDiff = conciliation?.formatDiff;
        break;
    }
  };

  const returnInfo = ({ type, measure, name, money }) => {
    conciliationProperty(type);

    if (
      typeof conciliationPropDiff === "number" &&
      conciliationPropDiff !== 0
    ) {
      if (money) {
        return (
          <tr>
            <td className={styles.titleTable} role={"notOk"}>
              {name}
              <span>
                {`${new Intl.NumberFormat("pt-BR", {
                  style: "currency",
                  currency: "BRL"
                }).format(oldProp)}`}
              </span>
              <IoMdClose color="#CA1818" size={17} />
            </td>
            <td className={styles.titleTable} role={"ok"}>
              {name}
              <span>
                {`${new Intl.NumberFormat("pt-BR", {
                  style: "currency",
                  currency: "BRL"
                }).format(conciliationProp)}`}
              </span>
              <ImCheckmark color="#409E6A" size={15} />
            </td>
          </tr>
        );
      }

      if (name === "Peso faturado:") {
        if (oldProp !== conciliationProp) {
          return (
            <tr>
              <td className={styles.titleTable} role={"notOk"}>
                {name}
                <span>{`${String(oldProp).replace(".", ",")}${measure}`}</span>
                <IoMdClose color="#CA1818" size={17} />
              </td>
              <td className={styles.titleTable} role={"ok"}>
                {name}
                <span>
                  {`${String(conciliationProp).replace(".", ",")}${measure}`}
                </span>
                <ImCheckmark color="#409E6A" size={15} />
              </td>
            </tr>
          );
        } else {
          return (
            <tr>
              <td className={styles.titleTable}>
                {name}
                <span>{`${String(oldProp).replace(".", ",")}${measure}`}</span>
              </td>
              <td className={styles.titleTable}>
                {name}
                <span>
                  {`${String(conciliationProp).replace(".", ",")}${measure}`}
                </span>
              </td>
            </tr>
          );
        }
      }

      return (
        <tr>
          <td className={styles.titleTable} role={"notOk"}>
            {name}
            <span>{`${String(oldProp).replace(".", ",")}${measure}`}</span>
            <IoMdClose color="#CA1818" size={17} />
          </td>
          <td className={styles.titleTable} role={"ok"}>
            {name}
            <span>
              {`${String(conciliationProp).replace(".", ",")}${measure}`}
            </span>
            <ImCheckmark color="#409E6A" size={15} />
          </td>
        </tr>
      );
    } else if (
      typeof conciliationPropDiff === "string" &&
      conciliationPropDiff !== "" &&
      conciliationPropDiff !== "Não"
    ) {
      return (
        <tr role={"notOk"}>
          <td className={styles.titleTable}>
            {name}
            <span>{`${conciliationProp}${measure}`}</span>
            <IoMdClose color="#CA1818" size={17} />
          </td>
          <td className={styles.titleTable} role={"ok"}>
            {name}
            <span>{`${conciliationPropDiff}${measure}`}</span>
            <ImCheckmark color="#409E6A" size={15} />
          </td>
        </tr>
      );
    } else {
      return (
        <tr className={styles.rowTable}>
          <td className={styles.titleTable}>
            {name}
            <span>
              {money
                ? `${new Intl.NumberFormat("pt-BR", {
                  style: "currency",
                  currency: "BRL"
                }).format(Number(conciliationProp))}`
                : `${conciliationProp}${measure}`}
            </span>
          </td>
          <td className={styles.titleTable}>
            {name}
            <span>
              {money
                ? `${new Intl.NumberFormat("pt-BR", {
                  style: "currency",
                  currency: "BRL"
                }).format(Number(conciliationProp))}`
                : `${conciliationProp}${measure}`}
            </span>
          </td>
        </tr>
      );
    }
  };

  return (
    <div className={`${styles.cardWrapper} ${conciliation.blocked}`}>
      <div className={styles.cardBody}>
        <div className={styles.cardContent}>
          <h3>INFORMAÇÕES GERAIS</h3>
          <hr />
          <div className={styles.collectInfoContainer}>
            <div className={styles.collectInfo}>
              <h4>Remetente</h4>
              <p className={styles.highlighted}>
                <strong>Cod. de envio:</strong> {conciliation?.shipping?.sro}
              </p>
              <p className={styles.highlighted}>
                <strong>Prazo de entrega:</strong>{" "}
                {`${conciliation?.shipping?.correios?.prazoEntrega} ${conciliation?.shipping?.correios?.prazoEntrega > 1
                    ? "dias úteis"
                    : "dia útil"
                  }`}
              </p>
              <p className={styles.highlighted}>
                <strong>Valor segurado:</strong>{" "}
                {conciliation?.shipping?.insurance ? `${new Intl.NumberFormat("pt-BR", {
                  style: "currency",
                  currency: "BRL"
                }).format(
                  conciliation?.shipping?.correios?.valorValorDeclarado
                )}` : 'R$ 00,00'}
              </p>
              <p className={styles.highlighted}>
                <strong>A.R:</strong>{" "}
                {`${conciliation?.shipping?.correios?.valorAvisoRecebimento > 0
                    ? "Sim"
                    : "Não"
                  }`}
              </p>
              <p className={styles.highlighted}>
                <strong>M.P:</strong>{" "}
                {`${conciliation?.shipping?.correios?.valorMaoPropria > 0
                    ? "Sim"
                    : "Não"
                  }`}
              </p>
              <p className={styles.highlighted}>
                <strong>Coleta:</strong>{" "}
                {`${conciliation?.shipping?.rateType ===
                    RateTypeShippingEnum.COLLECT
                    ? "Sim"
                    : "Não"
                  }`}
              </p>
              {conciliation?.shipping?.rateType ===
                RateTypeShippingEnum.RETAIL && (
                  <p className={`${styles.highlighted} ${styles.agency}`}>
                    <strong>Agência:</strong> {conciliation.agency}
                  </p>
                )}
              <p className={styles.highlighted}>
                <strong>Pago em:</strong>{" "}
                {moment(conciliation?.shipping?.collect?.created_at).format(
                  "DD/MM/YYYY - HH:mm"
                )}
              </p>
            </div>
            <div className={styles.collectInfo}>
              <h4>Destinatário</h4>
              <p className={styles.highlighted}>
                <strong>Nome:</strong>{" "}
                {conciliation?.shipping?.destination?.name}
              </p>
              <p className={styles.highlighted}>
                <strong>
                  {conciliation?.shipping?.destination?.document === 11
                    ? "CPF:"
                    : "CNPJ:"}
                </strong>{" "}
                {formatCPF(conciliation?.shipping?.destination?.document)}
              </p>
              <p style={{ maxWidth: "264px" }} className={styles.highlighted}>
                <strong>CEP: </strong>{" "}
                {formatCep(conciliation?.shipping?.destination?.zip)}
              </p>

              <p>
                <strong>Endereço: </strong>
                {addressInfoHandled({
                  street: conciliation?.shipping?.destination?.street,
                  number: conciliation?.shipping?.destination?.number,
                  complement: conciliation?.shipping?.destination?.complement,
                  district: conciliation?.shipping?.destination?.district
                })}
                {`${conciliation?.shipping?.destination?.city}/${conciliation?.shipping?.destination?.state}`}
              </p>
            </div>
          </div>
        </div>

        <div className={styles.cardContent}>
          <h3>
            CONFERÊNCIA (realizada em{" "}
            {moment(conciliation?.created_at)
              .subtract(3, "hours")
              .format("DD/MM/YYYY - HH:mm")}
            )
          </h3>
          <hr />
          <table className={styles.table}>
            <thead>
              <tr>
                <td style={{ display: "flex", alignItems: "center", gap: 4 }}>
                  <Box />
                  <h5>Original</h5>
                </td>
                <td style={{ display: "flex", alignItems: "center", gap: 4 }}>
                  <BoxSearch />
                  <h5>Conferido</h5>
                </td>
              </tr>
            </thead>
            <tbody>
              {conciliation?.shipping?.rateType ===
                RateTypeShippingEnum.RETAIL &&
                returnInfo({
                  type: "originCity",
                  measure: "",
                  name: "De:"
                })}
              {returnInfo({
                type: "height",
                measure: "cm",
                name: "Altura:"
              })}

              {returnInfo({
                type: "width",
                measure: "cm",
                name: "Largura:"
              })}

              {returnInfo({
                type: "length",
                measure: "cm",
                name: "Comprimento:"
              })}

              {returnInfo({
                type: "format",
                measure: "",
                name: "Formato:"
              })}

              {returnInfo({
                type: "weight",
                measure: "kg",
                name: "Peso:"
              })}

              {returnInfo({
                type: "billedWeight",
                measure: "kg",
                name: "Peso faturado:"
              })}

              {returnInfo({
                type: "type",
                measure: "",
                name: "Tipo:"
              })}

              {conciliation?.shipping?.rateType ===
                RateTypeShippingEnum.RETAIL &&
                returnInfo({
                  type: "rateType",
                  measure: "",
                  name: "Coleta:"
                })}

              {returnInfo({
                type: "price",
                measure: "",
                name: "Preço:",
                money: true
              })}

              {returnInfo({
                type: "discount",
                measure: "",
                name: "Desconto:",
                money: true
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default CardConciliation;
