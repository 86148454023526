import React, { useCallback } from "react";
import ReactModal from "react-modal";
import { useHistory } from "react-router-dom";
import success from "../../assets/icons/greenCircleInfo.svg";
import { Container } from "./styles";

const SuccessModal = ({ responseCollectId, paymentMethod, isWalletDeposit = false, handleCancel = () => {} }) => {
  const history = useHistory();

  const handleRedirect = useCallback(() => {
    if (isWalletDeposit) {
      handleCancel();
    } else {
      history.push(`/detalhe-coleta/${responseCollectId}`);
    }
  }, [history, responseCollectId]);

  return (
    <>
      <ReactModal
        isOpen={true}
        shouldCloseOnOverlayClick={false}
        style={{
          overlay: {
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(51, 51, 51, 0.75)",
            zIndex: 999
          },
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            transform: "translate(-50%, -50%)",
            zIndex: 999,
            border: "none",
            backgroundColor: "transparent",
            padding: 0
          }
        }}
      >
        <Container>
          <img src={success} alt="" />
          <p>
            {paymentMethod === "pix"
              ? "Pedido criado com sucesso!"
              : "Pagamento realizado com sucesso! "}
          </p>
          <button onClick={handleRedirect}>Continuar</button>
        </Container>
      </ReactModal>
    </>
  );
};

export default SuccessModal;
