import React from "react";
import { useHistory } from "react-router-dom";
import { BulletPoint, SlideCarousel } from "./components";
import "react-responsive-carousel/lib/styles/carousel.css"; // requires a loader

import optionsIntegrations from "../../../../../assets/images/integration/optionsIntegrations.png";
import activeOrderCreation from "../../../../../assets/images/active-order-create.png";
import manualImportCard from "../../../../../assets/images/manual-import-card.png";


import vtex from "../../../../../assets/images/vtex/vtexCard.png";
import vtex1 from "../../../../../assets/images/vtex/vtex1.png";
import vtex2 from "../../../../../assets/images/vtex/vtex2.png";
import vtex3 from "../../../../../assets/images/vtex/vtex3.png";
import vtex4 from "../../../../../assets/images/vtex/vtex4.png";
import vtex5 from "../../../../../assets/images/vtex/vtex5.png";
import vtex6 from "../../../../../assets/images/vtex/vtex6.png";
import vtex7 from "../../../../../assets/images/vtex/vtex7.png";
import vtex8 from "../../../../../assets/images/vtex/vtex8.png";
import vtex9 from "../../../../../assets/images/vtex/vtex9.png";
import vtex10 from "../../../../../assets/images/vtex/vtex10.png";
import vtex11 from "../../../../../assets/images/vtex/vtex11.png";
import vtex12 from "../../../../../assets/images/vtex/vtex12.png";
import vtex13 from "../../../../../assets/images/vtex/vtex13.png";
import vtex14 from "../../../../../assets/images/vtex/vtex14.png";
import vtex15 from "../../../../../assets/images/vtex/vtex15.png";
import vtex16 from "../../../../../assets/images/vtex/vtex16.png";
import vtex17 from "../../../../../assets/images/vtex/vtex17.png";
import vtex18 from "../../../../../assets/images/vtex/vtex18.png";
import vtex19 from "../../../../../assets/images/vtex/vtex19.png";
import vtex20 from "../../../../../assets/images/vtex/vtex20.png";
import vtex21 from "../../../../../assets/images/vtex/vtex21.png";
import vtex22 from "../../../../../assets/images/vtex/vtex22.png";
import vtex23 from "../../../../../assets/images/vtex/vtex23.png";
import vtex24 from "../../../../../assets/images/vtex/vtex24.png";
import vtex25 from "../../../../../assets/images/vtex/vtex25.png";
import vtex26 from "../../../../../assets/images/vtex/vtex26.png";
import vtex27 from "../../../../../assets/images/vtex/vtex27.png";
import vtex28 from "../../../../../assets/images/vtex/vtex28.png";
import vtex29 from "../../../../../assets/images/vtex/vtex29.png";
import vtex30 from "../../../../../assets/images/vtex/vtex30.png";
import vtex31 from "../../../../../assets/images/vtex/vtex31.png";
import vtex32 from "../../../../../assets/images/vtex/vtex32.png";
import vtex33 from "../../../../../assets/images/vtex/vtex33.png";
import vtex34 from "../../../../../assets/images/vtex/vtex34.png";
import vtex35 from "../../../../../assets/images/vtex/vtex35.png";
import vtex36 from "../../../../../assets/images/vtex/vtex36.png";
import vtex37 from "../../../../../assets/images/vtex/vtex37.png";
import vtex38 from "../../../../../assets/images/vtex/vtex38.png";
import vtex39 from "../../../../../assets/images/vtex/vtex39.png";
import vtex41 from "../../../../../assets/images/vtex/vtex41.png";

import vtexManualImport1 from "../../../../../assets/images/vtex/vtex-manual-import1.png";
import vtexManualImport2 from "../../../../../assets/images/vtex/vtex-manual-import2.png";
import vtexManualImport3 from "../../../../../assets/images/vtex/vtex-manual-import3.png";

import styles from "./styles.module.scss";

const TutorialVtex = () => {
  const steps1th = [{ image: optionsIntegrations }, { image: vtex }];

  const steps2th = [
    { image: vtex2 },
    { image: vtex3 },
    { image: vtex4 },
    { image: vtex5 },
    { image: vtex6 },
    { image: vtex7 }
  ];

  const steps3th = [{ image: vtex9 }, { image: vtex10 }, { image: vtex11 }];

  const steps4th = [{ image: vtex12 }, { image: vtex13 }, { image: vtex14 }];

  const steps5th = [{ image: vtex17 }, { image: vtex18 }];

  const steps6th = [{ image: vtex37 }, { image: vtex38 }, { image: vtex39 }];

  const steps7th = [{ image: vtex22 }, { image: vtex41 }];

  const steps8th = [{ image: vtex24 }, { image: vtex25 }, { image: vtex26 }];

  const steps9th = [{ image: vtex27 }, { image: vtex28 }, { image: vtex29 }];

  const steps10th = [
    { image: vtex30 },
    { image: vtex31 },
    { image: vtex32 },
    { image: vtex33 }
  ];

  const steps11th = [{ image: vtex35 }, { image: vtex36 }];

  const steps12th = [
    { image: manualImportCard },
    { image: vtexManualImport1 },
    { image: vtexManualImport2 },
    { image: vtexManualImport3 }
  ];


  const history = useHistory();

  return (
    <main className={styles.container}>
      <div className={styles.content}>
        <header className={styles.contentHeader}>
          <p>Como fazer a integração?</p>
        </header>
        <div className={styles.contentBody}>
          <section className={styles.contentSection}>
            <h4>Passo a passo para conectar sua plataforma VTEX à BRCom</h4>

            <BulletPoint order="1">
              <p>
                Na tela de <b>Integrações</b>, clique em <b>Selecionar</b> do
                segundo card e depois clique em <b>Configurações</b> no card da
                plataforma VTEX
              </p>
            </BulletPoint>

            <SlideCarousel steps={steps1th} />

            <BulletPoint order="2">
              <p>
                Você será redirecionado para a tela de Configurações onde serão
                necessárias algumas chaves de aplicação do usuário VTEX.
              </p>
            </BulletPoint>

            <div className={styles.imgs}>
              <img
                src={vtex1}
                alt="Imagem tutorial Vtex nº 1"
                className={styles.cardImage}
              />
            </div>

            <BulletPoint order="3">
              <p>
                Para obter essas chaves de aplicação, acesse seu painel{" "}
                <b>Admin da VTEX</b> e siga os seguintes passos:
              </p>
            </BulletPoint>

            <SlideCarousel steps={steps2th} />

            <BulletPoint order="2">
              <p>
                Após gerar a chave, as informações necessárias serão exibidas
                nessa tela. <b>IMPORTANTE:</b> Salve o <b>TOKEN</b> pois ele não
                será mais exibido.
              </p>
            </BulletPoint>

            <div className={styles.imgs}>
              <img
                src={vtex8}
                alt="Imagem tutorial Vtex nº 8"
                className={styles.cardImage}
              />
            </div>

            <BulletPoint order="5">
              <p>Preencha os campos de acordo com as informações exibidas:</p>
            </BulletPoint>

            <SlideCarousel steps={steps3th} />

            <BulletPoint order="6">
              <p>
                Caso a sua conta VTEX possui mais de uma loja, vire a chave e
                insira o número da loja que deseja integrar.
                <br /> Cada usuário da BRCOM deve ser associado a uma{" "}
                <b>única</b> loja. No caso de um cliente possuir{" "}
                <b>múltiplas</b> lojas, deve-se criar <b>uma conta distinta</b>{" "}
                para cada loja, utilizando o mesmo token, com a distinção sendo
                feita através do número da loja.
              </p>
            </BulletPoint>

            <SlideCarousel steps={steps4th} />

            <BulletPoint order="7">
              <p>
                Em seguida, insira as informações de{" "}
                <b>endereço do remetente</b>. Começe pelo CEP para preencher
                automaticamente as informações do endereço.
              </p>
            </BulletPoint>

            <div className={styles.imgs}>
              <img
                src={vtex15}
                alt="Imagem tutorial Vtex nº 15"
                className={styles.cardImage}
              />
            </div>

            <BulletPoint order="8">
              <p>
                Informe o <b>nome de remetente</b> que deve constar nas
                etiquetas de envio geradas pela plataforma. Também é possivel
                ativar/desativar o seguro nos envios. Essas configurações serão
                aplicadas em todos os seus pedidos VTEX cotados pela BRComerce.
              </p>
            </BulletPoint>

            <div className={styles.imgs}>
              <img
                src={vtex16}
                alt="Imagem tutorial Vtex nº 16"
                className={styles.cardImage}
              />
            </div>

            <BulletPoint order="9">
              <p>
                Estando tudo certo o botão de <b>Salvar</b> será habilitado e
                deverá ser clicado para poder habilitar o botão de{" "}
                <b>Ativar Integração</b> que também deverá ser clicado para dar
                continuidade.
              </p>
            </BulletPoint>

            <SlideCarousel steps={steps5th} />

            <div className={styles.bulletpoint}>
              <span>10</span>
              <p>
                Nos próximos passos vamos gerar as planilhas de acordo com as
                opções de <b>frete</b> desejadas.
                É só ir no menu <b>Integrações</b> e depois no sub menu <b>Tabelas</b>.
                <br></br>
                Para gerar uma planilha clique no botão <b>Criar nova tabela</b> e preencha os campos e clique em <b>Gerar tabela</b>.
              </p>
            </div>

            <SlideCarousel steps={steps6th} />

            <div className={styles.bulletpoint}>
              <span>11</span>
              <p>
                Para cada opção de <b>frete</b> escolhida, deve ser gerada a
                estratégia de envio correspondente.
              </p>
            </div>

            <div className={styles.imgs}>
              <img
                src={vtex21}
                alt="Imagem tutorial Vtex nº 21"
                className={styles.cardImage}
              />
            </div>

            <div className={styles.bulletpoint}>
              <span>12</span>
              <p>
                Depois de gerar a planilha e estratégia de envio, aguarde o
                email confirmando a disponibilidade do download da planilha.
              </p>
            </div>

            <SlideCarousel steps={steps7th} />

            <div className={styles.bulletpoint}>
              <span>13</span>
              <p>
                Com as planilhas baixadas, acesse seu painel admin da VTEX para
                configurar o <b>frete</b>. Para cada estratégia de envio gerada
                aparecerá uma política de envio relacionada onde deverão ser
                inseridas as planilhas baixadas de acordo com a opção de frete.
              </p>
            </div>

            <SlideCarousel steps={steps8th} />

            <div className={styles.bulletpoint}>
              <span>14</span>
              <p>
                Adicione as políticas de envio da BRComerce na sua Doca de
                preferência, ou crie uma dedicada.
              </p>
            </div>

            <SlideCarousel steps={steps9th} />

            <div className={styles.bulletpoint}>
              <span>15</span>
              <p>
                Caso a Doca com a política de envio da BRComerce não esteja
                associada a um Estoque, siga os seguintes passos:
              </p>
            </div>

            <SlideCarousel steps={steps10th} />

            <div className={styles.bulletpoint}>
              <span>16</span>
              <p>
                Aguarde o processamento das informações, até as políticas de
                envio estiverem ativas
              </p>
            </div>

            <div className={styles.imgs}>
              <img
                src={vtex34}
                alt="Imagem tutorial Vtex nº 34"
                className={styles.cardImage}
              />
            </div>

            <div className={styles.bulletpoint}>
              <span>17</span>
              <p>
                Pronto! Se tudo deu certo agora você pode simular um envio e
                aproveitar nossas opções de <b>frete</b>.
              </p>
            </div>

            <SlideCarousel steps={steps11th} />

            <div className={styles.bulletpoint}>
              <span>17</span>
              <p>
                Você também tem a opção de importar os pedidos manualmente.
              </p>
            </div>

            <SlideCarousel steps={steps12th} />


            <div className={styles.bulletpoint}>
              <span>18</span>
              <p>
                Caso queira desativar a criação de pedido nessa integração, vire a chave para "Desativado"
                <br></br>
                As demais funcionalidades relacionadas a essa integração continuarão ativas.
              </p>
            </div>

            <div className={styles.imgs}>
              <img
                src={activeOrderCreation}
                alt="activeOrderCreation"
                className={styles.cardImage}
              />
            </div>

            <button
              onClick={() => history.push("/integracoes")}
              className={`${styles.button} ${styles.btnBack}`}
            >
              Voltar
            </button>
          </section>
        </div>
      </div>
    </main>
  );
};

export { TutorialVtex };
