import React from "react";
import styles from "./chartBySla.module.scss";
import { Col, Row } from "react-bootstrap";
import { ReactComponent as CurrentLocation } from "../../../../../../assets/images/driver/current-location.svg";
import { FiPackage } from "react-icons/fi";

const ChartBySla = ({ data }) => {
  return (
    <Row xs={{ cols: 2 }} lg={{ cols: 4 }} className={`${styles.cardsStatus}`}>
      <>
        <Col xs={12} lg={6}>
          <div className={`${styles.card} ${styles.cardTotal}`}>
            <div className={styles.information}>
              <h3>Total de rotas ativas</h3>
              <h1>{data?.totalActivedRoute}</h1>
            </div>
            <div className={styles.informationImage}>
              <CurrentLocation />
            </div>
          </div>
        </Col>
        <Col className={styles.statusContainer} xs={12} lg={6}>
          <div
            className={`${styles.card} ${styles.cardStatus} ${styles.onTime}`}
          >
            <div className={styles.cardStatusHeader}>
              <FiPackage size={30} color="#409e6a" />
              <p>Objetos entregue com sucesso</p>
            </div>
            <h3>{data?.DELIVERED_SUCCESSFULLY?.value}</h3>
          </div>
          <div
            className={`${styles.card} ${styles.cardStatus} ${styles.before}`}
          >
            <div className={styles.cardStatusHeader}>
              <FiPackage size={30} color="#f5cb2b" />
              <p>Objetos pendentes</p>
            </div>
            <h3>{data?.OBJECTS_ON_ACTIVE_ROUTE?.value}</h3>
          </div>
          <div
            className={`${styles.card} ${styles.cardStatus} ${styles.later}`}
          >
            <div className={styles.cardStatusHeader}>
              <FiPackage size={30} color="#FF6B71" />
              <p>Objetos não entregue</p>
            </div>
            <h3>{data?.DELIVERED_UNSUCCESSFULLY?.value}</h3>
          </div>
        </Col>
      </>
    </Row>
  );
};

export { ChartBySla };
