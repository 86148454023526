/**
 * Entry application component used to compose providers and render Routes.
 * */

import bsCustomFileInput from "bs-custom-file-input";
import { createBrowserHistory } from "history";
import React, { useCallback, useEffect, useState } from "react";
import { ClearCacheProvider, useClearCache } from "react-clear-cache";
import ReactGA from "react-ga";
import { Provider } from "react-redux";
import { Router } from "react-router-dom";
import { LastLocationProvider } from "react-router-last-location";
import { PersistGate } from "redux-persist/integration/react";
import { getUserByToken } from "./app/crud/auth.crud";
import { Routes } from "./app/router/Routes";
import { I18nProvider, LayoutSplashScreen } from "./_metronic";
import { StatusModal } from "./app/components/StatusModal";

bsCustomFileInput.init();

const history = createBrowserHistory();

function buildPath(location) {
  return `${location.pathname}${location.hash}`;
}

if (history.location) {
  if (history.location.pathname.search("integracao/nuvemshop") > -1) {
    window.localStorage.setItem(
      "Brcom:NuvemshopCode:",
      history.location.search
    );
  }
}

history.listen(location => {
  const path = buildPath(location);
  ReactGA.set({ page: path }); // Update the user's current page
  ReactGA.pageview(path); // Record a pageview for the given page
});

export default function App({ store, persistor, basename }) {
  const [isLoading, setIsLoading] = useState(true);
  const [modal, setModal] = useState(false);
  const state = store.getState();

  const { authorized, user } = state.auth;

  useEffect(() => {
    if (user) {
      setModal(!authorized);
    }
  }, [authorized, user]);

  const refreshToken = useCallback(async token => {
    if (token) {
      try {
        await getUserByToken();
      } catch (e) {
        history.push("/logout");
      }
    }
    setIsLoading(false);
  }, []);

  let auth;
  let token;
  let rememberMe;
  if (window.localStorage.getItem("persist:auth:brcom")) {
    auth = JSON.parse(window.localStorage.getItem("persist:auth:brcom"))
      ?.authData;
    if (auth && JSON.parse(auth)) {
      token = JSON.parse(auth).message.token;
      rememberMe = JSON.parse(auth)?.rememberMe;
    }
  }

  useEffect(() => {
    const path = buildPath(history.location);
    ReactGA.set({ page: path });
    ReactGA.pageview(path);
    refreshToken(token);
  }, [refreshToken, token]);

  const { isLatestVersion, emptyCacheStorage } = useClearCache();

  console.log("Is Versão atualizada: " + isLatestVersion);
  if (!isLatestVersion && emptyCacheStorage) {
    emptyCacheStorage();
  }

  const handleConfirmModalButton = useCallback(() => {
    history.push("/logout");
    setModal(false);
  }, []);

  return (
    <>
      <StatusModal
        isOpen={modal}
        text="Sessão Expirada"
        confirmButtonText="Seguir para login"
        onConfirm={handleConfirmModalButton}
        type="error"
      />
      <ClearCacheProvider auto={true}>
        <Provider store={store} loading={<LayoutSplashScreen />}>
          <PersistGate persistor={persistor}>
            <Router basename={basename} history={history}>
              <LastLocationProvider>
                <I18nProvider>{!isLoading && <Routes />}</I18nProvider>
              </LastLocationProvider>
            </Router>
          </PersistGate>
        </Provider>
      </ClearCacheProvider>
    </>
    /* Provide Redux store */
  );
}
