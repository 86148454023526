import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { MdClear } from "react-icons/md";
import { useParams } from "react-router-dom";
import { Loading } from "../../../../../components/Loading";
import ShippingStagesEnum from "../../../../../enums/ShippingStagesEnum";
import { BrComerceApiService } from "../../../../../services/BrComerceApiService";
import store from "../../../../../store/store";
import debounce from "../../../../../utils/debounce";
import useGetWindowWidth from "../../../../../utils/useGetWindowWidth";
import { CartCardShipping } from "../../../components/CartCardShipping";

import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import { PLPStatusEnum } from "../../../../../enums/PLPStatusEnum";
import styles from "./styles.module.scss";
import { PLPEnum } from "../PLPCreated";
import Container from "../../../components/Container";

const PLPDetail = () => {
  window.setPageTitle("Detalhes da PLP");

  const api = new BrComerceApiService();
  const params = useParams();
  const { auth } = store.getState();
  const { user } = auth;
  const widthWindows = useGetWindowWidth();
  const [loading, setLoading] = useState(false);
  const [plp, setPLP] = useState([]);
  const [shippings, setShippings] = useState([]);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [amount, setAmount] = useState(0);
  const [packagesExpand, setPackagesExpand] = useState([]);


  const handleClearSearchFilter = () => {
    setSearch("");
  };

  const handleFetchData = useCallback(async () => {
    setLoading(true);
    try {
      const response = await api.makeHttpRequest({
        method: "GET",
        url: `/plp/details/${params.id}`,
        params: {
          page: 1,
          resultsPerPage: 10
        }
      });
      setPLP(response.plp);
      setShippings(response?.plpShippings?.results);
      setPage(response?.plpShippings?.actualPage);
      setTotalPages(response?.plpShippings?.nPages);
      setAmount(response?.plpShippings?.amountShippings);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  }, [api, params.id]);

  const getShippings = useCallback(
    debounce(async (search, page) => {
      try {
        setLoading(true);

        await api
          .makeHttpRequest({
            method: "GET",
            url: `/plp/details/${params.id}`,
            params: {
              page: search ? 1 : page,
              resultsPerPage: 10,
              ...(search && { text: search })
            }
          })
          .then(r => {
            setShippings(r?.plpShippings?.results);
            setPage(r?.plpShippings?.actualPage);
            setTotalPages(r?.plpShippings?.nPages);
          })
          .catch(e => {
            setShippings([]);
            setPage(1);
            setTotalPages(1);
          });

        setLoading(false);
      } catch (e) {
        setLoading(false);
        console.error(e);
      }
    }, 500),
    [search, page]
  );

  const onChangePagination = type => {
    setLoading(true);

    if (type === "next") {
      setPage(page + 1);
    } else {
      setPage(page - 1);
    }
  };

  const handlePrint = () => {
    window.open(`/plp/${params.id}`, "_blank");
  };

  useEffect(() => {
    (async () => {
      await handleFetchData();
    })();
  }, []);

  useEffect(() => {
    getShippings(search, page);
  }, [getShippings, search, page]);

  const setStatus = status => {
    const enumPLP = Object.entries(PLPStatusEnum);
    const statusPLP = enumPLP.find(sc => sc[1].value === status);
    return statusPLP && statusPLP[1]?.label;
  };

  const returnErrorMessage = phrase => {
    const sentenceDebt = phrase?.split(":");
    return sentenceDebt[1];
  };

  const handlePackageExpand = useCallback(
    pack => {
      const find = packagesExpand.find(p => p.id === pack.id);
      if (find) {
        const newPacks = packagesExpand.filter(p => p.id !== pack.id);
        setPackagesExpand([...newPacks]);
      } else {
        packagesExpand.push(pack);
        setPackagesExpand([...packagesExpand]);
      }
    },
    [packagesExpand]
  );

  return (
    <main className={styles.main}>
      <Loading loading={loading} />
      <Container
        title={`PLP n° ${
          plp?.status === PLPEnum.FINISHED ? plp?.number : "--"
        }`}
      >
        <div className={styles.content}>
          <div className={styles.actionButtons}>
            <div
              className={`${styles.inputContainer} ${styles.inputContainerSmall}`}
            >
              <div className={styles.input}>
                <input
                  type="text"
                  name="search"
                  placeholder="Busque pelo código da etiqueta"
                  onChange={e => setSearch(e.target.value)}
                  value={search}
                />

                {!!search?.length && (
                  <MdClear
                    style={{ cursor: "pointer" }}
                    onClick={handleClearSearchFilter}
                  />
                )}
              </div>
            </div>
          </div>

          <div className={styles.collectWrapper}>
            {!!shippings?.length ? (
              shippings?.map(pack => (
                <CartCardShipping
                  widthWindows={widthWindows}
                  key={pack.shipping.id}
                  shipping={pack.shipping}
                  isSelected={false}
                  isCancelled={
                    pack.shipping.idShippingStatus ===
                    ShippingStagesEnum.OBJETO_CANCELADO.id
                  }
                  isExpand={packagesExpand.find(
                    packExpand => pack.id === packExpand.id
                  )}
                  onExpand={() => handlePackageExpand(pack)}
                  onClick={() => {}}
                  setLoading={setLoading}
                  removeShipping={() => {}}
                  isAdmin={user.profile === "admin"}
                  invoiceMethod={"null"}
                  collectStage={4}
                  edit={false}
                  linkTracking
                  select={false}
                />
              ))
            ) : (
              <div className={styles.notFoundOrEmptyContainer}>
                <p>
                  {!shippings?.length && !!search
                    ? "Não foram encontradas envios para essa pesquisa"
                    : "Ainda não há envios para essa PLP"}
                </p>
              </div>
            )}

            <div className={styles.pagination}>
              <h6>
                Página {page} de {totalPages}
              </h6>
              <div>
                {page > 1 && (
                  <FiChevronLeft
                    onClick={() => onChangePagination("previous")}
                    className="arrow"
                  />
                )}
                {totalPages > 1 && <h6>{page}</h6>}
                {page < totalPages && (
                  <FiChevronRight
                    onClick={() => onChangePagination("next")}
                    className="arrow"
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </Container>
      <aside className={styles.processingContainer}>
        <div className={styles.processingTitle}>
          <h2>Sobre a PLP</h2>
        </div>
        <div className={styles.aboutCollect}>
          <p>
            Criada em{" "}
            <strong>{moment(plp?.created_at).format("DD/MM/YYYY")}</strong>
          </p>
          <p>
            Contém <strong>{amount}</strong> envios
          </p>
          <p>
            Status: <strong>{setStatus(plp?.status)}</strong>
          </p>
          {plp?.status === PLPEnum.ERROR && (
            <p>
              <strong>Resposta sigep:</strong>{" "}
              {returnErrorMessage(plp?.sigepResponse)}
            </p>
          )}
        </div>
        <div
          className={`
                  ${styles.button}
                  ${plp?.status !== PLPEnum.FINISHED && styles.buttonDisabled}
                  `}
        >
          <button
            onClick={handlePrint}
            disabled={plp?.status !== PLPEnum.FINISHED}
          >
            Imprimir PLP
          </button>
        </div>
      </aside>
    </main>
  );
};

export { PLPDetail };
