import React from "react";
import "react-responsive-carousel/lib/styles/carousel.css"; // requires a loader
import { useHistory } from "react-router-dom";
import { BulletPoint, SlideCarousel } from "./components";

import activeOrderCreation from "../../../../../assets/images/active-order-create.png";
import blingCard from "../../../../../assets/images/bling/blingCard.png";
import optionsIntegrations from "../../../../../assets/images/integration/optionsIntegrations.png";
import manualImportCard from "../../../../../assets/images/manual-import-card.png";


import bling2 from "../../../../../assets/images/bling/bling2.png";
import bling3 from "../../../../../assets/images/bling/bling3.png";
import bling4 from "../../../../../assets/images/bling/bling4.png";
import bling5 from "../../../../../assets/images/bling/bling5.png";
import bling6 from "../../../../../assets/images/bling/bling6.png";

import bling7 from "../../../../../assets/images/bling/bling7.png";
import bling8 from "../../../../../assets/images/bling/bling8.png";

import bling10 from "../../../../../assets/images/bling/bling10.png";
import bling11 from "../../../../../assets/images/bling/bling11.png";
import bling12 from "../../../../../assets/images/bling/bling12.png";
import bling13 from "../../../../../assets/images/bling/bling13.png";
import bling14 from "../../../../../assets/images/bling/bling14.png";
import bling15 from "../../../../../assets/images/bling/bling15.png";

import bling16 from "../../../../../assets/images/bling/bling16.png";
import bling18 from "../../../../../assets/images/bling/bling18.png";

import bling19 from "../../../../../assets/images/bling/bling19.png";
import bling20 from "../../../../../assets/images/bling/bling20.png";
import bling22 from "../../../../../assets/images/bling/bling22.png";

import bling25 from "../../../../../assets/images/bling/bling25.png";
import bling26 from "../../../../../assets/images/bling/bling26.png";

import bling28 from "../../../../../assets/images/bling/bling28.png";
import bling29 from "../../../../../assets/images/bling/bling29.png";
import bling30 from "../../../../../assets/images/bling/bling30.png";

import blingManualImport1 from "../../../../../assets/images/bling/bling-manual-import1.png";
import blingManualImport2 from "../../../../../assets/images/bling/bling-manual-import2.png";

import styles from "./styles.module.scss";

const TutorialBling = () => {
  const steps1th = [
    {
      image: optionsIntegrations
    },
    {
      image: blingCard
    }
  ];

  const steps2th = [
    {
      image: bling2
    },
    {
      image: bling3
    },
    {
      image: bling4
    },
    {
      image: bling5
    },
    {
      image: bling6
    }
  ];

  const steps3th = [
    {
      image: bling7
    },
    {
      image: bling8
    }
  ];

  const steps4th = [
    {
      image: bling10
    },
    {
      image: bling11
    },
    {
      image: bling12
    },
    {
      image: bling13
    },
    {
      image: bling14
    },
    {
      image: bling15
    },
    {
      image: bling16
    }
  ];

  const steps5th = [
    {
      image: bling18
    },
    {
      image: bling19
    },
    {
      image: bling20
    }
  ];

  const steps6th = [
    {
      image: manualImportCard
    },
    {
      image: blingManualImport1
    },
    {
      image: blingManualImport2
    }
  ];

  const steps7th = [
    {
      image: optionsIntegrations
    },
    {
      image: bling26
    },
    {
      image: bling28
    },
    {
      image: bling29
    },
    {
      image: bling30
    }
  ];

  const history = useHistory();

  return (
    <main className={styles.container}>
      <div className={styles.content}>
        <header className={styles.contentHeader}>
          <p>Como fazer a integração?</p>
        </header>
        <div className={styles.contentBody}>
          <section className={styles.contentSection}>
            <h4>Passo a passo para conectar sua plataforma Bling à BRCom</h4>

            <BulletPoint order="1">
              <p>
                Na tela de <b>Integrações</b>, clique em <b>Selecionar</b> do
                segundo card e depois clique em <b>Configurações</b> no card da
                plataforma Bling
              </p>
            </BulletPoint>

            <SlideCarousel steps={steps1th} />

            <BulletPoint order="2">
              <p>
                Será solicitada uma Api Key, gerada no site da Bling. Para isso
                acesse o painel da Bling com seu login e senha, e clique no
                ícone de configurações. A seguir, clique em{" "}
                <b>Todas as Configurações</b> &gt; <b>Sistema</b> &gt;{" "}
                <b>Usuário e usuário API</b> &gt; <b>Incluir usuário</b>:
              </p>
            </BulletPoint>

            <SlideCarousel steps={steps2th} />

            <BulletPoint order="3">
              <p>
                Selecione a opção <b>USUÁRIO API</b> e preencha os campos
                solicitados em <b>Informações da conta</b> (nome, e-mail e
                celular). Para o nome, sugerimos <span>loja-brcomerce</span>, e
                para o e-mail, sugerimos utilizar o mesmo e-mail cadastrado na
                plataforma BRCom. A seguir, clique no botão <b>Gerar</b>, para
                gerar uma API Key e conseguir copiar o código:
              </p>
            </BulletPoint>

            <SlideCarousel steps={steps3th} />

            <BulletPoint order="4">
              <p>
                Ainda nessa tela, antes de salvar, é necessário selecionar as
                permissões desse USUÁRIO API. Em <b>Permissões</b>, nas abas:{" "}
                <b>Cadastros</b>, <b>Suprimentos</b>, <b>Vendas</b>,{" "}
                <b>Finanças</b>, <b>Serviços</b> e <b>Preferências</b>, marque
                os campos <b>Selecionar todos</b> para selecionar todas as
                opções. Feito isso, clique em <b>Salvar</b> no canto superior
                direito:
              </p>
            </BulletPoint>

            <SlideCarousel steps={steps4th} />

            <BulletPoint order="5">
              <p>
                Com a API Key copiada, retorne à plataforma BRCom e cole o
                código no campo <b>API Key</b> das configurações da Bling e, a
                seguir, clique em <b>Selecionar endereço da loja</b>. Você será
                redirecionado para a página <b>Dados do Remetente</b> da
                plataforma, onde deverá preencher um novo endereço ou selecionar
                um endereço previamente cadastrado. Após preenchido, clique em{" "}
                <b>Próximo</b>:
              </p>
            </BulletPoint>

            <SlideCarousel steps={steps5th} />

            <BulletPoint order="6">
              <p>
                Você será redirecionado para a página <b>Integrações</b> da
                plataforma. Nessa tela, selecione um ou mais status dos pedidos
                que deverão ser importados. A seguir, selecione o{" "}
                <b>Status de destino</b>, para o qual os pedidos importados
                deverão ser atualizados.
              </p>
            </BulletPoint>

            <div className={`${styles.imgs} `}>
              <img
                src={bling22}
                alt="Bling Card"
                className={styles.cardImage}
              />
            </div>

            <BulletPoint order="7">
              <p>
                Abaixo estão as <b>Configurações Adicionais</b> caso deseja
                complementar sua integração em nossa plataforma.
                <br />Para continuarmos a integração clique em{" "}
                <b>Salvar</b> no final da página e, a seguir, retorne para a
                tela <b>Integrações</b> clicando no menu lateral:
              </p>
            </BulletPoint>

            <div className={`${styles.imgs} `}>
              <img
                src={bling25}
                alt="Bling Card"
                className={styles.cardImage}
              />
            </div>

            <BulletPoint order="8">
              <p>
                Na página de <b>Integrações</b>, clique em <b>Selecionar</b> do
                segundo card e depois no card da Bling clique em{" "}
                <b>Fazer Integração</b>. Nesse momento, será criada, dentro da
                Bling, toda a estrutura da BRComerce. Acesse o painel da Bling
                novamente, e clique no ícone de configurações. A seguir, clique
                em <b>Configurações de logísticas</b> e, a seguir, clique em{" "}
                <b>Logística BRCom</b>. Clique em <b>Serviços de entrega</b>,
                onde serão mostrados todos os serviços de entrega da BrComerce:{" "}
                <span>Econômico, Expresso, Menor Custo e Mini Envios.</span>
              </p>
            </BulletPoint>

            <SlideCarousel steps={steps7th} />

            <BulletPoint order="9">
              <p>
                Você também tem a opção de importar manualmente seus pedidos.
              </p>
            </BulletPoint>

            <SlideCarousel steps={steps6th} />

            <div className={styles.bulletpoint}>
              <span>10</span>
              <p>
                Caso queira desativar a criação de pedido nessa integração, vire a chave para "Desativado"
                <br></br>
                As demais funcionalidades relacionadas a essa integração continuarão ativas.
              </p>
            </div>

            <div className={styles.imgs}>
              <img
                src={activeOrderCreation}
                alt="activeOrderCreation"
                className={styles.cardImage}
              />
            </div>

            <button
              onClick={() => history.push("/integracoes")}
              className={`${styles.button} ${styles.btnBack}`}
            >
              Voltar
            </button>
          </section>
        </div>
      </div>
    </main>
  );
};

export { TutorialBling };
