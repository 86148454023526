import React from "react";
import {
  GoogleMap,
  Marker,
  Polyline,
  MarkerClusterer,
  useJsApiLoader
} from "@react-google-maps/api";


let GoogleMapsDashboard = props => {
  const [map, setMap] = React.useState(null)
  const [initLocationRoute, setInitLocationRoute] = React.useState(null)
  const [endLocationRoute, setEndLocationRoute] = React.useState(null)

  function createKey() {
    return Math.random();
  }

  function handleZoomChange() {
    if (props?.setCenter) {
      props.setCenter({
        lat: map?.center?.lat(),
        lng: map?.center?.lng()
      })
    }
  }

  const lightThemeStyle = [
    {
      featureType: "poi",
      elementType: "labels.text",
      stylers: [
        {
          visibility: "off"
        }
      ]
    },
    {
      featureType: "poi.business",
      stylers: [
        {
          visibility: "off"
        }
      ]
    },
    {
      featureType: "road",
      elementType: "labels.icon",
      stylers: [
        {
          visibility: "off"
        }
      ]
    },
    {
      featureType: "transit",
      stylers: [
        {
          visibility: "off"
        }
      ]
    }
  ];

  const brazilCoordinates = {
    lat: -14.2400732,
    lng: -53.1805017
  };

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY
  });

  React.useEffect(() => {
    if (props.locations) {
      setInitLocationRoute(props.locations[0])
      setEndLocationRoute(props.locations[props.locations.length -1 ])
    }

  }, [])

  return (
    isLoaded && (
      <div style={{ display: "flex", width: "100%", height: "600px" }}>
        <GoogleMap
          mapContainerStyle={{
            width: "100%",
            height: "100%"
          }}
          zoom={props.zoom}
          options={{
            styles: lightThemeStyle
          }}
          center={
            props?.hasDetailsRoute ? initLocationRoute :
            props.centralizeCoordinates
              ? props.centralizeCoordinates
              : brazilCoordinates
          }
          onZoomChanged={handleZoomChange}
          onLoad={(map) => setMap(map)}
        >
          {props?.locations && props?.hasDetailsRoute ? (
            <>
              <Marker
                position={initLocationRoute}
                title="Ponto de partida"
                icon={props.iconMarker}
              />
              <Marker
                position={endLocationRoute}
                title="Ponto final"
              />
              <Polyline
                options={{
                  strokeColor: "#0F48FC",
                  fillColor: "#0F48FC",
                  strokeOpacity: 0.7,
                  strokeWeight: 6,
                  path: props.locations,
                  clickable: false,
                  draggable: false,
                  editable: false,
                  visible: true,
                  radius: 30000,
                  zIndex: 1
                  }}
                />
            </>
          ) : (
          <MarkerClusterer minimumClusterSize={1}>
            {clusterer =>
              props.locations.map(location => (
                <Marker
                  key={createKey()}
                  position={location}
                  icon={props.iconMarker ? props.iconMarker : ''}
                  title={location.driver ? location.driver : ""}
                />
              ))
            }
          </MarkerClusterer>
        )}
      </GoogleMap>
      </div>
    )
  );
};

GoogleMapsDashboard = React.memo(GoogleMapsDashboard);

export { GoogleMapsDashboard };
