import React, { useCallback } from "react";
import Swal from "sweetalert2";
import { BrComerceApiService } from "../../../../services/BrComerceApiService";
import { ButtonsContainer, Container } from "./styles";

export const ForcePrePostShipping = ({
  setShowForcePrePostModal,
  setLoading,
  shipping,
  setFunction
}) => {
  const api = new BrComerceApiService();

  const confirmForcePrePostShipping = useCallback(async () => {
    try {

      setShowForcePrePostModal(false);
      setLoading(true);
      await api.makeHttpRequest({
        method: "POST",
        url: `/shipping/${shipping.id}/force/prepostar`
      });

      Swal.fire({
        title: "Sucesso",
        text: "Forçar Pré-Postagem realizada com sucesso!",
        icon: "success",
        confirmButtonColor: "#409e6a",
      });

      setFunction(oldState => {
        const newState = oldState.slice();

        const foundItem = newState.find(item => item.id === shipping.id);

        if (foundItem) {
          foundItem.prePostado = true;
          foundItem.status.name = "Pré-Postado";
          foundItem.prepostedDate = new Date();
          foundItem.errorPLP = false;
        }

        return foundItem ? newState : oldState;
      });
    } catch (e) {
      console.log(e);
      Swal.fire({
        title: "Erro",
        text:
          e.response?.data?.message ||
          "Um erro ocorreu ao tentar Forçar Pré-Postagem",
        icon: "error",
        confirmButtonColor: "#409e6a",
        preConfirm() {
          setShowForcePrePostModal(false);
        }
      });
    } finally {
      setLoading(false);
    }
  }, [api, setFunction, setLoading, setShowForcePrePostModal, shipping.id]);

  return (
    <Container>
      <h1>Forçar Pré-Postagem</h1>
      <p>
        Atenção, ao utilizar essa opção você assume ter a certeza de que este
        objeto já está presente no Pré-Postagem e que não possui erros ou
        duplicidade. Essa ação não pode ser revertida. Deseja continuar?
      </p>
      <p>Deseja continuar?</p>
      <ButtonsContainer>
        <button onClick={() => setShowForcePrePostModal(false)}>Não</button>
        <button onClick={confirmForcePrePostShipping}>Sim</button>
      </ButtonsContainer>
    </Container>
  );
};
