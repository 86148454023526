import React, { useMemo } from "react";
import { createStaticRanges, DateRangePicker } from "react-date-range";
import { definedsDates } from "./config";
import { isAfter } from "date-fns";
import { ptBR } from "date-fns/locale";

import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";

const Calendar = ({ onChange, ranges, minDate }) => {
  const actualDate = new Date();

  const memoizedDatesStatics = useMemo(() => {
    const allStaticDates = [
      {
        label: "Hoje",
        range: () => ({
          startDate: definedsDates.startOfToday,
          endDate: definedsDates.endOfToday
        })
      },
      {
        label: "Últimos 7 Dias",
        range: () => ({
          startDate: definedsDates.startOfLastSevenDay,
          endDate: definedsDates.endOfToday
        })
      },
      {
        label: "Últimos 30 Dias",
        range: () => ({
          startDate: definedsDates.startOfLastThirtyDay,
          endDate: definedsDates.endOfToday
        })
      },
      {
        label: "Últimos 90 Dias",
        range: () => ({
          startDate: definedsDates.startOfLastNintyDay,
          endDate: definedsDates.endOfToday
        })
      },
      {
        label: "Esta Semana",
        range: () => ({
          startDate: definedsDates.startOfWeek,
          endDate: isAfter(definedsDates.endOfWeek, actualDate)
            ? actualDate
            : definedsDates.endOfWeek
        })
      },
      {
        label: "Última Semana",
        range: () => ({
          startDate: definedsDates.startOfLastWeek,
          endDate: definedsDates.endOfLastWeek
        })
      },
      {
        label: "Mês Atual",
        range: () => ({
          startDate: definedsDates.startOfMonth,
          endDate: isAfter(definedsDates.endOfMonth, actualDate)
            ? actualDate
            : definedsDates.endOfMonth
        })
      },
      {
        label: "Últimos Mês",
        range: () => ({
          startDate: definedsDates.startOfLastMonth,
          endDate: definedsDates.endOfLastMonth
        })
      },
      {
        label: "Ano Atual",
        range: () => ({
          startDate: definedsDates.startOfYear,
          endDate: isAfter(definedsDates.endOfYear, actualDate)
            ? actualDate
            : definedsDates.endOfYear
        })
      },
      {
        label: "Ano passado",
        range: () => ({
          startDate: definedsDates.startOflastYear,
          endDate: definedsDates.endOflastYear
        })
      }
    ];
    return createStaticRanges(allStaticDates);
  }, [actualDate]);

  return (
    <DateRangePicker
      direction="horizontal"
      months={2}
      onChange={onChange}
      showSelectionPreview={true}
      moveRangeOnFirstSelection={false}
      ranges={ranges}
      color="#409e6a"
      inputRanges={[]}
      rangeColors={["#409e6a"]}
      staticRanges={memoizedDatesStatics}
      minDate={minDate}
      maxDate={actualDate}
      weekdayDisplayFormat="EEEEEE"
      locale={ptBR}
    />
  );
};

export { Calendar };
