import React, { useCallback, useEffect, useMemo, useState } from "react";
import Swal from "sweetalert2";
import Select from "react-select";
import { Loading } from "../../../../components/Loading";
import { BrComerceApiService } from "../../../../services/BrComerceApiService";
import styles from "./styles.module.scss";
import { MdClear } from "react-icons/md";
import { FiSearch } from "react-icons/fi";

import InputSelect from "../../../../components/InputSelect";
import InputSelectAsync from "../../../../components/InputSelectAsync";
import store from "../../../../store/store";
import RequestInformationEnum from "../../../../enums/RequestInformationEnum";
import { capitalizeFirstLetter } from "../../../../utils/CapitalizeFirstLetter";
import moment from "moment";
import DatePicker from "../../../../components/Input/DatePicker";
import debounce from "../../../../utils/debounce";
import { RequestInfoTypeEnum } from "../../../../enums/RequestInfoTypeEnum";
import { Tooltip, withStyles } from "@material-ui/core";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import ProfileEnum from "../../../../enums/ProfileEnum";
import Container from "../../../admin/components/Container";

const CustomTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: "#fafafa",
    color: "#333",
    maxWidth: 300,
    fontSize: theme.typography.pxToRem(16),
    borderRadius: "6px",
    padding: "10px"
  }
}))(Tooltip);

const selectStyle = {
  control: styles => ({
    ...styles,
    backgroundColor: "inherit",
    width: "100%",
    border: "none",
    boxShadow: "none",
    outline: "none",
    color: "#807E80",
    padding: "0",
    "&:hover": {
      borderColor: "none"
    },
    maxHeight: "48px",
    cursor: "pointer"
  }),
  container: styles => ({
    ...styles,
    minWidth: "56px",
    outline: "none",
    borderBottom: "1px solid #4D4D4D",
    padding: "0",
    cursor: "pointer"
  }),
  indicatorSeparator: styles => ({
    ...styles,
    display: "none",
    border: "none"
  }),
  option: (styles, { isSelected }) => ({
    ...styles,
    fontSize: "14px",
    fontFamily: "Mulish",
    fontWeight: 400,
    color: isSelected ? "#fff" : "#807E80",
    "&:hover": {
      backgroundColor: "#c1c1c1",
      color: "#color: #333333;",
      cursor: "pointer"
    },

    backgroundColor: isSelected ? "#409e6a" : "#fff"
  }),
  valueContainer: styles => ({
    ...styles,
    padding: "0"
  }),
  dropdownIndicator: styles => ({
    ...styles,
    padding: "0",
    color: "#4D4D4D"
  })
};

const RequestInformation = () => {
  const api = new BrComerceApiService();

  const { auth } = store.getState();
  const { user } = auth;
  const isAdmin = user?.profile === ProfileEnum.ADMIN.value;

  const [allRequestInformation, setAllRequestInformation] = useState([]);
  const [nPages, setNPages] = useState(0);
  const [nPerPage, setNPerPage] = useState({ label: "20", value: 20 });
  const [loading, setLoading] = useState(false);

  const [search, setSearch] = useState("");
  const [searchStatus, setSearchStatus] = useState(null);
  const [searchClient, setSearchClient] = useState(null);
  const [periodInitialDate, setPeriodInitialDate] = useState(null);
  const [periodFinalDate, setPeriodFinalDate] = useState(null);

  const handleClearSearchFilter = () => {
    setSearch("");
  };

  const handleOnChangeCalendarPeriod = e => {
    if (e.dateEnd) {
      setPeriodInitialDate(e.dateStart);
      setPeriodFinalDate(e.dateEnd);
    }
  };

  const handleClearPeriodFilter = () => {
    setPeriodInitialDate("");
    setPeriodFinalDate("");
  };

  const handleFetchData = useCallback(
    async page => {
      try {
        const response = await api.makeHttpRequest({
          method: "GET",
          url: "/request-information",
          params: {
            page,
            resultsPerPage: nPerPage.value,
            initialDate: periodInitialDate ? periodInitialDate : null,
            finalDate: periodFinalDate ? periodFinalDate : null,
            ...(search && { text: search }),
            ...(searchStatus && { idStatus: searchStatus?.value }),
            ...(searchClient && { client: searchClient?.label })
          }
        });
        setAllRequestInformation(response.results);
        setActualPage(Number(response.actualPage));
        setNPages(Number(response.nPages));
      } catch (e) {
        console.log(e);
        Swal.fire({
          icon: "error",
          title: "Pedidos de Informação",
          text: "Não foi possível carregar os pedidos de informação."
        });
      } finally {
        setLoading(false);
      }
    },
    [
      allRequestInformation,
      api,
      search,
      searchClient,
      searchStatus,
      periodInitialDate,
      periodFinalDate,
      nPerPage
    ]
  );

  const getRequestInformation = useCallback(
    debounce(async (search, status, searchClient, initial, final) => {
      setLoading(true);

      try {
        const response = await api.makeHttpRequest({
          method: "GET",
          url: `/request-information`,
          params: {
            resultsPerPage: 20,
            page: 1,
            initialDate: initial ? initial : null,
            finalDate: final ? final : null,
            ...(search && { text: search }),
            ...(status && { idStatus: status?.value }),
            ...(searchClient && { client: searchClient?.label })
          }
        });

        setAllRequestInformation(response.results);
        setActualPage(Number(response.actualPage));
        setNPages(Number(response.nPages));

        setLoading(false);
      } catch (e) {
        console.log(e);
        Swal.fire({
          icon: "error",
          title: "Pedidos de Informação",
          text: "Não foi possível carregar os pedidos de informações."
        });
      } finally {
        setLoading(false);
      }
    }, 500),
    []
  );

  const [actualPage, setActualPage] = useState(1);

  const onPage = newPage => {
    setActualPage(newPage - 1);
  };

  const onNext = () => {
    setLoading(true);
    onPage(actualPage + 1);
    setActualPage(actualPage + 1);
  };

  const onPrevious = () => {
    setLoading(true);
    onPage(actualPage - 1);
    setActualPage(actualPage - 1);
  };

  const getOptionsStagesRequestInformation = useMemo(() => {
    const optionsHandled = Object.entries(RequestInformationEnum).map(
      ([key, value]) => {
        return {
          value: value.id,
          label: value.message
        };
      }
    );
    return optionsHandled;
  }, []);

  const perPageOptions = [
    {
      label: "10",
      value: 10
    },
    {
      label: "15",
      value: 15
    },
    {
      label: "20",
      value: 20
    },
    {
      label: "50",
      value: 50
    },
    {
      label: "100",
      value: 100
    }
  ];

  useEffect(() => {
    window.setPageTitle("Pedidos de Informação");
  }, []);

  useEffect(() => {
    getRequestInformation(
      search,
      searchStatus,
      searchClient,
      periodInitialDate,
      periodFinalDate
    );
  }, [periodInitialDate, periodFinalDate, search, searchClient, searchStatus]);

  useEffect(() => {
    handleFetchData(actualPage);
  }, [actualPage, nPerPage]);

  return (
    <>
      <Loading loading={loading} />
      <Container title="Pedidos de Informação">
        <div className={styles.leftContainer}>
          <div className={styles.filtersContainer}>
            <div className={`${styles.inputContainer}`}>
              <div className={styles.input}>
                <input
                  type="text"
                  name="search"
                  placeholder="Buscar por etiqueta ou tickect PI"
                  onChange={e => setSearch(e.target.value.toUpperCase())}
                  value={search}
                />
                <FiSearch size={20} color="#409e6a" />

                {!!search?.length && (
                  <MdClear
                    style={{ cursor: "pointer" }}
                    onClick={handleClearSearchFilter}
                  />
                )}
              </div>
            </div>
            {isAdmin && (
              <div className={`${styles.inputContainer}`}>
                <InputSelectAsync
                  search={searchClient}
                  setSearch={setSearchClient}
                />
              </div>
            )}

            <div className={styles.inputContainer}>
              <div className={styles.input}>
                <DatePicker
                  className={"datePickerConfig"}
                  change={handleOnChangeCalendarPeriod}
                  placeholder="Filtrar por período"
                  showClearButton={!!periodInitialDate && !!periodFinalDate}
                  onClearFields={handleClearPeriodFilter}
                  dateStart={periodInitialDate}
                  dateFinal={periodFinalDate}
                  range
                />
              </div>
            </div>

            <div className={`${styles.inputContainer}`}>
              <InputSelect
                id="searchStatus"
                placeholder="Status"
                onChange={e => setSearchStatus(e)}
                options={getOptionsStagesRequestInformation}
                value={searchStatus}
              />
            </div>
          </div>
          <div className={styles.tableContainer}>
            <table className={styles.table}>
              <thead className={styles.tableHead}>
                <tr>
                  <th>Etiqueta</th>
                  <th>Tickect PI</th>
                  <th>Tipo de Solicitação</th>
                  <th>Data Criação</th>
                  <th>Status</th>
                  {user.profile === "admin" && <th>Cliente</th>}
                </tr>
              </thead>
              <tbody className={styles.tableBody}>
                {allRequestInformation.map(ri => {
                  const postOfficeReply =
                    ri.postOfficeReply && ri.postOfficeReply.split(".")[0];

                  return (
                    <tr key={ri?.id}>
                      <td className={styles.thCodigo}>
                        <a
                          href={`detalhes-objeto/${ri?.shipping?.id}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {ri?.shipping?.sro}
                        </a>
                      </td>

                      <td className={styles.thPedido}>
                        <p>{ri?.ticket}</p>
                      </td>

                      <td className={styles.thEntrega}>
                        <p>
                          {ri?.type ===
                          RequestInfoTypeEnum.ENTREGUE_ATRASO.value
                            ? RequestInfoTypeEnum.ENTREGUE_ATRASO.message
                            : RequestInfoTypeEnum.NAO_ENTREGUE.message}
                        </p>
                      </td>

                      <td className={styles.thCriado}>
                        <p>
                          {ri?.created_at &&
                            moment(ri?.created_at).format("DD/MM/YYYY")}
                        </p>
                      </td>

                      <td className={styles.thStatus}>
                        {ri.postOfficeReply ? (
                          <CustomTooltip
                            title={`${postOfficeReply}.`}
                            placement="top-start"
                          >
                            <span
                              className={
                                ri.idRequestInformationStatus === 1
                                  ? styles.gray
                                  : ri.idRequestInformationStatus === 2
                                  ? styles.delivered
                                  : styles.error
                              }
                            >
                              {ri.requestInformationStatus.name}
                            </span>
                          </CustomTooltip>
                        ) : (
                          <span
                            className={
                              ri.idRequestInformationStatus === 1
                                ? styles.gray
                                : ri.idRequestInformationStatus === 2
                                ? styles.delivered
                                : styles.error
                            }
                          >
                            {ri.requestInformationStatus.name}
                          </span>
                        )}
                      </td>

                      {user?.profile === "admin" && (
                        <td className={styles.thClient}>
                          <p>
                            {capitalizeFirstLetter(
                              ri?.shipping?.user?.userData?.[1].Value
                            )}
                          </p>
                        </td>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </table>

            <div className={styles.paginationContainer}>
              <div className={styles.perPageContent}>
                <span>Itens por página:</span>
                <Select
                  styles={selectStyle}
                  value={nPerPage}
                  options={perPageOptions}
                  onChange={e => setNPerPage(e)}
                  menuPlacement="top"
                />
              </div>
              <h6>
                {actualPage} - {nPerPage.value} de {nPages}
              </h6>
              <div className={styles.controlPage}>
                <FiChevronLeft
                  size={25}
                  onClick={actualPage > 1 ? onPrevious : ""}
                  color={actualPage > 1 ? "#409E6A" : "#2D724C"}
                  className="arrow"
                />

                <FiChevronRight
                  size={25}
                  onClick={actualPage < nPages ? onNext : ""}
                  color={actualPage < nPages ? "#409E6A" : "#2D724C"}
                  className="arrow"
                />
              </div>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export { RequestInformation };
