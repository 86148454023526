const filtersFromQueryString = queryString => {
  const splitString = queryString.split("?");
  const params = new URLSearchParams(splitString[1]);

  const filters = [];

  params.forEach(param => filters.push(param));

  return filters;
};

export default filtersFromQueryString;
