import React from "react";
import styles from "./styles.module.scss";

const Container = ({ title, children }) => {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.contentHeader}>
          <p>{title}</p>
        </div>
        {children}
      </div>
    </div>
  );
};

export default Container;
