import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { Col, Container, Row } from "react-bootstrap";
import { BsArrowDownShort, BsFillCheckCircleFill } from "react-icons/bs";
import { FiSearch, FiSquare, FiTrash2 } from "react-icons/fi";
import { MdClear } from "react-icons/md";
import InfiniteScroll from "react-infinite-scroll-component";
import Select from "react-select";
import Swal from "sweetalert2";

import ErrorModal from "../../../../../components/ErrorModal";
import GreenClockModal from "../../../../../components/GreenClockModal";
import DatePicker from "../../../../../components/Input/DatePicker";
import InputSelectAsync, {
  selectStyle
} from "../../../../../components/InputSelectAsync";
import LoadInfiniteScroll from "../../../../../components/LoadInfiniteScroll";
import { Loading } from "../../../../../components/Loading";
import SuccessModal from "../../../../../components/SuccessModal";
import { PaymentMethodEnum } from "../../../../../enums/PaymentMethodEnum";
import ProfileEnum from "../../../../../enums/ProfileEnum";
import { RateTypeShippingEnum } from "../../../../../enums/RateTypeShippingEnum";
import { BrComerceApiService } from "../../../../../services/BrComerceApiService";
import * as authDuck from "../../../../../store/ducks/auth.duck";
import store from "../../../../../store/store";
import debounce from "../../../../../utils/debounce";
import useGetWindowWidth from "../../../../../utils/useGetWindowWidth";
import CardPayment from "../../../components/CardPayment";
import { CartCardShipping } from "../../../components/CartCardShipping";
import ScrollDiv from "../../../components/ScrollDiv";
import styles from "./styles.module.scss";
import "./styles.scss";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { TbPackageImport } from "react-icons/tb";

const Payment = () => {
  const api = new BrComerceApiService();
  const { auth } = store.getState();
  const history = useHistory();

  const { user } = auth;
  const isAdmin = user?.profile === ProfileEnum.ADMIN.value;
  const isManager = user?.profile === ProfileEnum.MANAGER.value;

  const paymentMethodsEnabled =
    process.env.REACT_APP_PAYMENT_METHODS_ENABLED === "true";

  const widthWindows = useGetWindowWidth();
  const paymentContainer = useRef(null);

  const [showHelperForPayment, setShowHelperForPayment] = useState(true);
  const [isSelectedAllCartItems, setIsSelectedAllCartItems] = useState(null);
  const [searchStringCollect, setSearchStringCollect] = useState("");
  const [searchRateType, setSearchRateType] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState(PaymentMethodEnum.BILLING);
  const [previousPaymentMethod, setPreviousPaymentMethod] = useState(
    paymentMethod
  );
  const [loading, setLoading] = useState(false);

  const [allPackages, setAllPackages] = useState([]);
  const [packagesSelected, setPackagesSelected] = useState([]);
  const [packagesExpand, setPackagesExpand] = useState([]);
  const [balance, setBalance] = useState(0);
  const [walletBalance, setWalletBalance] = useState(0);

  const [actualPage, setActualPage] = useState(0);
  const [nPages, setNPages] = useState(0);

  const [periodInitialDate, setPeriodInitialDate] = useState("");
  const [periodFinalDate, setPeriodFinalDate] = useState("");

  const [greenClockModal, setGreenClockModal] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [responseCollectId, setResponseCollectId] = useState(null);
  const [errorModal, setErrorModal] = useState(false);
  const [paymentError, setPaymentError] = useState("");
  const [paymentMessageError, setPaymentMessageError] = useState("");

  const [searchClient, setSearchClient] = useState(null);

  const [retailLimit, setRetailLimit] = useState({
    retailLimit: 0,
    unvalidatedPackages: 0,
    hasLimit: false,
    avaliableLimit: 0
  });

  const optionsCheck = [
    { value: "", label: "Todos" },
    { value: RateTypeShippingEnum.COLLECT, label: "Coleta" },
    { value: RateTypeShippingEnum.RETAIL, label: "Correios" }
  ];

  const optionSelectAll = [
    { value: RateTypeShippingEnum.COLLECT, label: "Coleta" },
    { value: RateTypeShippingEnum.RETAIL, label: "Correios" }
  ];

  const total = useMemo(() => {
    const acc = packagesSelected.reduce((a, b) => {
      const acc = a + b.price;
      return acc;
    }, 0);
    return acc;
  }, [packagesSelected]);

  const useWalletMethod = useMemo(() => {
    return total > 0 && total <= walletBalance;
  }, [walletBalance, total]);

  const goToPaymentContainer = () => {
    paymentContainer.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "start"
    });
  };

  const handleOnChangeCalendarPeriod = e => {
    if (e.dateEnd) {
      const dateStart = moment(e.dateStart, "DD/MM/YYYY").format(
        "YYYY-MM-DDTHH:mm:ss"
      );
      const dateEnd = moment(e.dateEnd, "DD/MM/YYYY").format(
        "YYYY-MM-DDTHH:mm:ss"
      );

      setPeriodInitialDate(dateStart);
      setPeriodFinalDate(dateEnd);
    }
  };

  const handleClearSearchFilter = () => {
    setSearchStringCollect("");
  };

  const handleClearPeriodFilter = () => {
    setPeriodInitialDate("");
    setPeriodFinalDate("");
  };

  const handlePackagesAction = useCallback(() => {
    if (allPackages.length === packagesExpand.length) {
      setPackagesExpand([]);
      return;
    }

    setPackagesExpand(allPackages);
  }, [packagesExpand, allPackages]);

  const handlePackageExpand = useCallback(
    pack => {
      const find = packagesExpand.find(p => p.id === pack.id);
      if (find) {
        const newPacks = packagesExpand.filter(p => p.id !== pack.id);
        setPackagesExpand([...newPacks]);
      } else {
        packagesExpand.push(pack);
        setPackagesExpand([...packagesExpand]);
      }
    },
    [packagesExpand]
  );

  const handlePackageSelection = useCallback(
    pack => {
      const find = packagesSelected.find(p => p.id === pack.id);
      const rate = packagesSelected.find(p => p.rateType !== pack.rateType);
      const isSameUser = packagesSelected.every(
        p => p.subUser === pack.subUser
      );

      if ((isAdmin || isManager) && !isSameUser) {
        Swal.fire({
          title: "Pedido inválido",
          html:
            "Não é possível criar um pedido com envios de diversos clientes. Separe os envios por cliente e tente novamente.",
          icon: "warning",
          confirmButtonText: "Ok"
        });
        return setPackagesSelected([...packagesSelected]);
      }

      if (packagesSelected.length > 0) {
        if (rate) {
          Swal.fire({
            title:
              "Não é possível realizar pagamentos com tipos de envio diferentes",
            html: `Tipo de envio adicionado ao carrinho: ${
              packagesSelected[0].rateType === RateTypeShippingEnum.RETAIL
                ? "Correios"
                : "Coleta"
            }`,
            icon: "warning",
            confirmButtonText: "Ok"
          });
          return setPackagesSelected([...packagesSelected]);
        }
      }

      let reverseLogisticsSelected;

      if (packagesSelected.length > 0) {
        reverseLogisticsSelected = packagesSelected.every(
          p => p.isLogisticsReverse
        );
      } else {
        reverseLogisticsSelected = "ALLOW BOTH";
      }

      if (find) {
        const newPacks = packagesSelected.filter(p => p.id !== pack.id);
        setPackagesSelected([...newPacks]);
      } else {
        if (reverseLogisticsSelected === "ALLOW BOTH") {
          packagesSelected.push(pack);
          setPackagesSelected([...packagesSelected]);
        } else {
          if (
            (reverseLogisticsSelected && !pack.isLogisticsReverse) ||
            (!reverseLogisticsSelected && pack.isLogisticsReverse)
          ) {
            Swal.fire({
              title: "Pedido inválido",
              text:
                "Não é possível criar um pedido com envios normais e reversos juntos. Separe seus envios por tipo e tente novamente.",
              icon: "warning"
            });
            return;
          }
          packagesSelected.push(pack);
          setPackagesSelected([...packagesSelected]);
        }
      }
    },
    [packagesSelected]
  );

  const handlePaymentButton = useCallback(async () => {
    if (!user?.canCreateCollectSchedule && !user?.isVerified) {
      setLoading(true);
      const {
        avaliableLimit,
        hasLimit,
        retailLimit
      } = await api.makeHttpRequest({
        method: "GET",
        url: `collects/retail-limit`
      });

      setLoading(false);

      if (!hasLimit) {
        Swal.fire({
          title: `Existem ${retailLimit} pacotes pendentes de envio!`,
          text: `Para prosseguir realize a postagem dos pacotes nas agências dos Correios de sua preferência`,
          icon: "error"
        });
        return;
      }

      if (
        packagesSelected[0].rateType === RateTypeShippingEnum.RETAIL &&
        packagesSelected.length > avaliableLimit
      ) {
        Swal.fire({
          title: "Limite de pacotes não postados atingido.",
          html: `Sua conta possui ${avaliableLimit} ${
            avaliableLimit > 1 ? "pacotes" : "pacote"
          } não ${
            avaliableLimit > 1 ? "postados disponíveis" : "postado disponível"
          }, desmarque alguns itens e tente novamente...`,
          icon: "warning",
          confirmButtonText: "Ok"
        });
        return;
      }

      setLoading(false);
    }

    if (total === 0) {
      Swal.fire({
        title: "Nenhum item selecionado",
        text: "Selecione itens a serem pagos.",
        icon: "warning"
      });
      return;
    }

    const hasNotBalanceForMethod = (method, methodBalance) => {
      return paymentMethod === method && Number(total) > Number(methodBalance);
    };

    if (paymentMethod !== PaymentMethodEnum.WALLET && useWalletMethod) {
      Swal.fire({
        title: "Alteração na forma de pagamento.",
        text:
          "Você possui saldo suficiente na carteira para efetuar esse pagamento.",
        icon: "info"
      });
      setPaymentMethod(PaymentMethodEnum.WALLET);
    }

    if (hasNotBalanceForMethod(PaymentMethodEnum.BILLING, balance)) {
      Swal.fire({
        title: "Saldo insuficiente",
        text: "Você não possui saldo suficiente para realizar o pagamento.",
        icon: "warning"
      });
      return;
    }

    if (hasNotBalanceForMethod(PaymentMethodEnum.WALLET, walletBalance)) {
      Swal.fire({
        title: "Saldo insuficiente.",
        text: "Você não possui saldo o suficiente na sua conta.",
        icon: "warning"
      });
      return;
    }

    try {
      setGreenClockModal(true);

      const data = {
        method: paymentMethod,
        packages: packagesSelected.map(pack => ({ id: pack.id }))
      };

      const collectResponse = await api.makeHttpRequest({
        method: "POST",
        url: "collects",
        data
      });

      if (walletBalance != 0) {
        const updatedUser = await api.makeHttpRequest({
          method: "GET",
          url: "/users/me"
        });
        store.dispatch(authDuck.actions.fulfillUser(updatedUser));
      }

      setResponseCollectId(collectResponse.id);
      setGreenClockModal(false);
      setSuccessModal(true);
    } catch (error) {
      setPaymentError(error?.response?.status);
      setPaymentMessageError(error?.response?.data?.message);
      setGreenClockModal(false);
      setErrorModal(true);
    } finally {
      setGreenClockModal(false);
    }
  }, [total, balance, walletBalance, api, packagesSelected, paymentMethod]);

  const handleRemoveShipping = useCallback(
    id => {
      setAllPackages(oldState => {
        const newState = oldState.filter(pack => pack.id !== id);
        return newState;
      });

      setPackagesSelected(oldState => {
        const newState = oldState.filter(pack => pack.id !== id);
        return newState;
      });
    },
    [allPackages, packagesSelected]
  );

  const handleToggleCheckAllCartItems = e => {
    if (e === null) {
      setIsSelectedAllCartItems(e);
      setPackagesSelected([]);
      return;
    } else {
      setIsSelectedAllCartItems(e);
      const result = allPackages.filter(i => i.rateType === e.value);
      setPackagesSelected(result);
    }
  };

  const handleDeleteMassiveCartItems = async () => {
    try {
      setLoading(true);
      const allPackagesIds = packagesSelected.map(pack => pack.id);

      const response = await api.makeHttpRequest({
        method: "DELETE",
        url: `shipping`,
        data: {
          ids: allPackagesIds
        }
      });

      const { idsOK, idsFailed } = response.reduce(
        (acc, resp) => {
          if (resp.status.toLowerCase() !== "success") {
            acc.idsFailed.push(resp.id);
            return acc;
          }

          acc.idsOK.push(resp.id);
          return acc;
        },
        {
          idsOK: [],
          idsFailed: []
        }
      );

      if (idsFailed.length) {
        Swal.fire({
          title: "Erro!",
          text: "Tivemos problemas ao deletar alguns itens.",
          icon: "error",
          confirmButtonColor: "#409e6a"
        });
      }

      setPackagesSelected([]);
      setAllPackages(allPackages.filter(pack => !idsOK.includes(pack.id)));
    } catch (e) {
      Swal.fire({
        title: "Erro!",
        text: "Tivemos problemas ao deletar.",
        icon: "error",
        confirmButtonColor: "#409e6a"
      });
    } finally {
      setLoading(false);
    }
  };

  const handleFetchData = useCallback(async () => {
    try {
      if (actualPage === nPages) {
        return;
      }
      const response = await api.makeHttpRequest({
        method: "GET",
        url: `/shipping/listCartShippings`,
        params: {
          resultsPerPage: 20,
          page: actualPage + 1,
          initialDate: periodInitialDate,
          finalDate: periodFinalDate,
          text: searchStringCollect,
          rateType: searchRateType,
          ...(searchClient && { client: searchClient?.label })
        }
      });

      setAllPackages([...allPackages, ...response.results]);
      setActualPage(Number(response.actualPage));
      setNPages(Number(response.nPages));

      setLoading(false);
    } catch (e) {
      Swal.fire({
        title: "Erro!",
        text: "Ocorreu um erro ao carregar os pacotes",
        icon: "error"
      });
    } finally {
      setLoading(false);
    }
  }, [
    api,
    allPackages,
    searchStringCollect,
    periodInitialDate,
    periodFinalDate,
    actualPage,
    nPages,
    searchRateType,
    searchClient
  ]);

  const getShippingFiltered = useCallback(
    debounce(async (string, initialDate, finalDate, rate, client) => {
      setLoading(true);
      console.log("getShippingFiltered".toUpperCase());

      try {
        const response = await api.makeHttpRequest({
          method: "GET",
          url: `/shipping/listCartShippings`,
          params: {
            resultsPerPage: 20,
            page: 1,
            initialDate: initialDate,
            finalDate: finalDate,
            text: string,
            rateType: rate?.value,
            ...(client && { client: client?.label })
          }
        });

        setAllPackages(response.results);
        setActualPage(Number(response.actualPage));
        setNPages(Number(response.nPages));

        setLoading(false);
      } catch (e) {
        setLoading(false);
        console.error(e);
      }
    }, 500),
    [
      searchStringCollect,
      periodInitialDate,
      periodFinalDate,
      searchRateType,
      searchClient
    ]
  );

  const showModalForDeleteCartItems = () => {
    if (packagesSelected.length <= 0) {
      Swal.fire({
        title: "Nada selecionado",
        text: "Por favor selecione itens a serem deletados.",
        icon: "warning",
        confirmButtonColor: "#409e6a"
      });
      return;
    }

    Swal.fire({
      title: "Você tem certeza?",
      html:
        "Deseja prosseguir com a remoção do envio?<br/>Não será possível desfazer essa ação!",
      icon: "warning",
      cancelButtonText: "Cancelar",
      showCancelButton: true,
      confirmButtonText: "Sim, remover selecionados!"
    }).then(response => {
      if (response?.value) {
        handleDeleteMassiveCartItems();
      }
    });
  };

  const onScroll = e => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;
    const totalScrolled = scrollTop + clientHeight;

    if (Number(totalScrolled.toFixed()) === scrollHeight) {
      setShowHelperForPayment(false);
    } else {
      setShowHelperForPayment(true);
    }
  };

  const renderCurrencyFormat = value => {
    return (
      <span>
        {new Intl.NumberFormat("pt-BR", {
          style: "currency",
          currency: "BRL"
        }).format(value)}
      </span>
    );
  };

  const renderWalletDischarge = () => {
    return (
      <span>
        {renderCurrencyFormat(total)}
        {walletBalance > 0 ? " - " : " + "}
        {renderCurrencyFormat(
          walletBalance > 0 ? walletBalance : walletBalance * -1
        )}
        <br />
        <p>Seu saldo será abatido nesse pagamento.</p>
      </span>
    );
  };

  useEffect(() => {
    getShippingFiltered(
      searchStringCollect,
      periodInitialDate,
      periodFinalDate,
      searchRateType,
      searchClient
    );
  }, [
    searchStringCollect,
    periodInitialDate,
    periodFinalDate,
    searchRateType,
    searchClient
  ]);

  useEffect(() => {
    window.addEventListener("scroll", onScroll, true);

    // Remove the event listener
    return () => {
      window.removeEventListener("scroll", onScroll, true);
    };
  }, []);

  useEffect(() => {
    if (
      (searchRateType?.value !== isSelectedAllCartItems?.value &&
        searchRateType?.value !== "") ||
      !packagesSelected.length
    ) {
      setIsSelectedAllCartItems(null);
      setPackagesSelected([]);
    }
  }, [searchRateType]);

  useEffect(() => {
    if (useWalletMethod) {
      setPreviousPaymentMethod(paymentMethod);
      setPaymentMethod(PaymentMethodEnum.WALLET);
    } else {
      setPaymentMethod(previousPaymentMethod);
    }
  }, [useWalletMethod]);

  const loadingConfig = useCallback(async () => {
    await Promise.all([
      api
        .makeHttpRequest({
          method: "GET",
          url: `/shipping/listCartShippings`,
          params: {
            resultsPerPage: 20,
            page: 1
          }
        })
        .then(response => {
          setAllPackages(response.results);
          setActualPage(Number(response.actualPage));
          setNPages(Number(response.nPages));
        }),
      await api
        .makeHttpRequest({
          method: "GET",
          url: `users/me`
        })
        .then(response => {
          let dataUserLocalStorage = JSON.parse(
            localStorage.getItem("persist:auth:brcom")
          );

          dataUserLocalStorage = {
            ...dataUserLocalStorage,
            user: JSON.stringify(response)
          };

          window.localStorage.setItem(
            "persist:auth:brcom",
            JSON.stringify(dataUserLocalStorage)
          );

          setBalance(response.balance);

          if (response.walletBalance) {
            setWalletBalance(response.walletBalance);
          }
        }),
      api
        .makeHttpRequest({
          method: "GET",
          url: "/collects/retail-limit"
        })
        .then(r => setRetailLimit(r))
        .catch(e => console.log(e))
    ]);
  }, []);

  useEffect(() => {
    window.setPageTitle("Meu carrinho");
    setLoading(true);

    loadingConfig()
      .catch(() => {
        Swal.fire({
          title: "Erro!",
          text:
            "Não foi possível carregar as informações. Por favor, tente novamente.",
          icon: "error"
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (user.creditLimit <= 0 && paymentMethodsEnabled) {
      setPaymentMethod(PaymentMethodEnum.PIX);
    } else {
      setPaymentMethod(PaymentMethodEnum.BILLING);
    }
  }, [user.creditLimit]);

  return (
    <>
      <Loading loading={loading} />
      {greenClockModal && <GreenClockModal />}
      {successModal && (
        <SuccessModal
          responseCollectId={responseCollectId}
          paymentMethod={paymentMethod}
        />
      )}
      {errorModal && (
        <ErrorModal
          setErrorModal={setErrorModal}
          paymentError={paymentError}
          responseCollectId={responseCollectId}
          paymentMessageError={paymentMessageError}
        />
      )}
      <Container fluid>
        <Row className={styles.main}>
          <Col
            xs={12}
            xl={packagesSelected.length >= 1 ? 9 : 12}
            className={styles.container}
          >
            <div className={styles.content}>
              <div className={styles.title}>
                <h2>Meu Carrinho</h2>
              </div>

              <div className={styles.filtersContainer}>
                <div className={styles.inputWrapper}>
                  <div
                    className={`${styles.inputContainer} ${styles.inputContainerSmall} ${packagesSelected.length <= 0 ? styles.minWidthWithPackageSelect : ""}`}
                  >
                    <div className={styles.input}>
                      <input
                        type="text"
                        name="search"
                        placeholder="Busque o que deseja"
                        onChange={e => setSearchStringCollect(e.target.value)}
                        value={searchStringCollect}
                      />

                      {!searchStringCollect?.length ? (
                        <FiSearch size={20} color="#409e6a" />
                      ) : (
                        <MdClear
                          style={{ cursor: "pointer" }}
                          onClick={handleClearSearchFilter}
                        />
                      )}
                    </div>
                  </div>
                  {(isAdmin || isManager) && (
                    <div
                      className={`${styles.inputContainer} ${styles.inputContainerSmall}`}
                    >
                      <InputSelectAsync
                        search={searchClient}
                        setSearch={setSearchClient}
                      />
                    </div>
                  )}

                  <div
                    className={`${styles.inputContainer} ${styles.inputContainerSmall}`}
                  >
                    <div className={styles.input}>
                      <DatePicker
                        className={"datePickerConfig"}
                        change={handleOnChangeCalendarPeriod}
                        placeholder="Filtrar por período"
                        showClearButton={
                          !!periodInitialDate && !!periodFinalDate
                        }
                        onClearFields={handleClearPeriodFilter}
                        range
                      />
                    </div>
                  </div>

                  <div
                    className={`${styles.inputContainer} ${styles.inputContainerSmall}`}
                  >
                    <div className={styles.selectInput}>
                      <Select
                        id="select_rate"
                        placeholder="Tipo de envio"
                        options={optionsCheck}
                        onChange={e => {
                          setSearchRateType(e);
                        }}
                        value={searchRateType}
                        styles={selectStyle}
                      />
                    </div>
                  </div>
                  {!!allPackages && allPackages.length >= 2 && (
                    <>
                      <div
                        className={`${styles.inputContainer} ${styles.inputContainerSmall}`}
                      >
                        <div className={styles.selectInput}>
                          <Select
                            options={optionSelectAll}
                            placeholder="Marcar todos"
                            onChange={e => {
                              handleToggleCheckAllCartItems(e);
                            }}
                            value={isSelectedAllCartItems}
                            styles={selectStyle}
                            isClearable
                          />
                        </div>
                      </div>
                      <div className={styles.actionsButton}>
                        <button
                          type="button"
                          onClick={handlePackagesAction}
                          className={styles.expand}
                        >
                          <BsFillCheckCircleFill size={16} />
                          <p>
                            {allPackages.length === packagesExpand.length
                              ? "Recolher todos"
                              : "Expandir todos"}
                          </p>
                        </button>
                      </div>
                      <div
                        className={`${styles.actionsButton} ${
                          !packagesSelected.length ? styles.disabled : ""
                        }`}
                      >
                        <button
                          type="button"
                          onClick={showModalForDeleteCartItems}
                          className={styles.delete}
                        >
                          <FiTrash2 size={20} />
                          <p>{`${
                            packagesSelected.length > 1
                              ? "Excluir selecionados"
                              : "Excluir selecionado"
                          }`}</p>
                        </button>
                      </div>

                      <div className={styles.actionsButton}>
                        <button
                          type="button"
                          onClick={() =>
                            history.push({
                              pathname: "/integracoes",
                              search: "?import"
                            })
                          }
                          className={styles.importIntegrations}
                        >
                          <TbPackageImport size={22} />
                          <p>Importar pedidos</p>
                        </button>
                      </div>
                    </>
                  )}
                </div>
              </div>

              <ScrollDiv data={allPackages}>
                <InfiniteScroll
                  style={{ overflow: "hidden" }}
                  dataLength={allPackages.length}
                  next={handleFetchData}
                  hasMore={nPages === 0 ? false : !(actualPage === nPages)}
                  scrollableTarget="scrollDiv"
                  loader={<LoadInfiniteScroll />}
                >
                  <div className={styles.collectWrapper}>
                    {allPackages.map(pack => (
                      <CartCardShipping
                        widthWindows={widthWindows}
                        key={pack.id}
                        shipping={pack}
                        isSelected={packagesSelected.find(
                          packSelected => pack.id === packSelected.id
                        )}
                        isExpand={packagesExpand.find(
                          packExpand => pack.id === packExpand.id
                        )}
                        onClick={() => handlePackageSelection(pack)}
                        onExpand={() => handlePackageExpand(pack)}
                        setLoading={setLoading}
                        removeShipping={handleRemoveShipping}
                        isAdmin={isAdmin}
                      />
                    ))}
                  </div>
                </InfiniteScroll>
              </ScrollDiv>
            </div>
          </Col>

          {packagesSelected.length >= 1 && (
            <Col
              xs={12}
              xl={3}
              className={styles.paymentContainer}
              ref={paymentContainer}
            >
              <div className={styles.paymentTitle}>
                <h2>Resumo da Seleção</h2>
              </div>
              <div className={styles.paymentTotal}>
                <p>
                  {packagesSelected.length > 0 ? (
                    <>
                      Você selecionou <span>{packagesSelected.length}</span>
                      {` ${packagesSelected.length > 1 ? "envios" : "envio"}`}
                    </>
                  ) : (
                    "Você não selecionou nenhum envio"
                  )}
                </p>
                <p>
                  <strong>Total:</strong>&nbsp;
                  {walletBalance != 0 && total > 0 && !useWalletMethod
                    ? renderWalletDischarge()
                    : renderCurrencyFormat(total)}
                </p>
                {!user?.canCreateCollectSchedule && !user?.isVerified && (
                  <div
                    className={`${styles.instructionRetailLimitTxt} ${
                      retailLimit.hasLimit ? `${styles.hasRetailLimit}` : ""
                    }`}
                  >
                    <p>
                      Sua conta possui{" "}
                      <b>
                        {retailLimit.unvalidatedPackages}/
                        {retailLimit.retailLimit}
                      </b>{" "}
                      objetos aguardando postagem, caso atinja o limite, poste
                      os objetos nas Agências dos Correios de sua preferência
                      para poder prosseguir com os próximos.
                    </p>
                  </div>
                )}
              </div>
              {!user?.canCreateCollectSchedule && !user?.isVerified && (
                <div
                  className={`${styles.instructionRetailLimitTxt} ${
                    retailLimit.hasLimit ? `${styles.hasRetailLimit}` : ""
                  }`}
                >
                  <p>
                    Sua conta possui{" "}
                    <b>
                      {retailLimit.unvalidatedPackages}/
                      {retailLimit.retailLimit}
                    </b>{" "}
                    objetos aguardando postagem, caso atinja o limite, poste os
                    objetos nas Agências dos Correios de sua preferência para
                    poder prosseguir com os próximos.
                  </p>
                </div>
              )}
              <div className={styles.paymentTitle}>
                <h2>Selecione a Forma de Pagamento</h2>
              </div>
              <div className={styles.paymentOpts}>
                {useWalletMethod ? (
                  <div className={styles.paymentOpt}>
                    <div
                      className={styles.check}
                      onClick={() => setPaymentMethod(PaymentMethodEnum.WALLET)}
                    >
                      <div>
                        <FiSquare />
                      </div>
                    </div>
                    <p>Carteira</p>
                  </div>
                ) : (
                  <>
                    {/* <div className={`${styles.paymentOpt} ${styles.disabled}`}>
                    <div
                      className={styles.check}
                      onClick={() => setPaymentMethod(PaymentMethodEnum.BOLETO)}
                    >
                      {!(paymentMethod === PaymentMethodEnum.BOLETO) ? (
                        <div />
                      ) : (
                        <div>
                          <FiSquare />
                        </div>
                      )}
                    </div>
                    <p>Boleto (à vista)</p>
                  </div> */}
                    {paymentMethodsEnabled && (
                      <>
                        <div className={styles.paymentOpt}>
                          <div
                            className={styles.check}
                            onClick={() =>
                              setPaymentMethod(PaymentMethodEnum.PIX)
                            }
                          >
                            {!(paymentMethod === PaymentMethodEnum.PIX) ? (
                              <div />
                            ) : (
                              <div>
                                <FiSquare />
                              </div>
                            )}
                          </div>
                          <p>PIX</p>
                        </div>
                        <div className={styles.paymentOpt}>
                          <div
                            className={styles.check}
                            onClick={() =>
                              setPaymentMethod(PaymentMethodEnum.CARTAO)
                            }
                          >
                            {!(paymentMethod === PaymentMethodEnum.CARTAO) ? (
                              <div />
                            ) : (
                              <div>
                                <FiSquare />
                              </div>
                            )}
                          </div>
                          <p>Cartão de Crédito</p>
                        </div>
                      </>
                    )}
                    {(user?.creditLimit > 0 || !paymentMethodsEnabled) && (
                      <div className={styles.paymentOpt}>
                        <div
                          className={styles.check}
                          onClick={() =>
                            setPaymentMethod(PaymentMethodEnum.BILLING)
                          }
                        >
                          {!(paymentMethod === PaymentMethodEnum.BILLING) ? (
                            <div />
                          ) : (
                            <div>
                              <FiSquare />
                            </div>
                          )}
                        </div>
                        <p>Faturado</p>
                      </div>
                    )}
                  </>
                )}
              </div>
              <div className={styles.paymentMethodDetails}>
                {paymentMethod === PaymentMethodEnum.BILLING && (
                  <div className={styles.paymentBilled}>
                    <div>
                      <p>Crédito Disponível {renderCurrencyFormat(balance)} </p>
                      <br />
                    </div>
                    <p>
                      Seu limite de crédito pré-aprovado pode ser revisado em
                      contato com nossa equipe comercial.
                    </p>
                  </div>
                )}
                {paymentMethod === PaymentMethodEnum.WALLET && (
                  <div className={styles.paymentBilled}>
                    <div>
                      <p>
                        Saldo Disponível {renderCurrencyFormat(walletBalance)}{" "}
                      </p>
                      <br />
                    </div>
                    <p>
                      Saldo gerado a partir da conciliação dos seus pacotes
                      conferidos anteriormente.
                    </p>
                    {!useWalletMethod && walletBalance != 0 && (
                      <p>
                        O saldo da carteira só será alterado após confirmação do
                        pagamento
                      </p>
                    )}
                  </div>
                )}
                {paymentMethod === PaymentMethodEnum.CARTAO && (
                  <CardPayment
                    total={
                      !useWalletMethod && total > 0
                        ? total - walletBalance
                        : total
                    }
                    setLoading={setLoading}
                    packagesSelected={packagesSelected}
                    greenClockModal={greenClockModal}
                    setGreenClockModal={setGreenClockModal}
                    successModal={successModal}
                    setSuccessModal={setSuccessModal}
                    errorModal={errorModal}
                    setErrorModal={setErrorModal}
                    setResponseCollectId={setResponseCollectId}
                    setPaymentError={setPaymentError}
                  />
                )}
                {paymentMethod === PaymentMethodEnum.PIX && (
                  <div className={styles.paymentPix}>
                    <p>
                      Siga para a próxima tela para efetuar o pagamento via QR
                      Code ou código "copia e cola" do PIX
                    </p>
                  </div>
                )}
              </div>
              {paymentMethod !== PaymentMethodEnum.CARTAO && (
                <div className={styles.buttonsContainerPayment}>
                  <button
                    className={`${styles.buttonPayment} ${!total &&
                      styles.buttonDisabled}`}
                    onClick={handlePaymentButton}
                  >
                    {total
                      ? `Pagar ${new Intl.NumberFormat("pt-BR", {
                          style: "currency",
                          currency: "BRL"
                        }).format(
                          !useWalletMethod && total > 0
                            ? total - walletBalance
                            : total
                        )}`
                      : "Você não selecionou nenhum envio"}
                  </button>
                </div>
              )}
            </Col>
          )}
        </Row>
      </Container>
      {((widthWindows <= 768 && allPackages.length >= 3) ||
        (widthWindows <= 480 && !!allPackages.length)) && (
        <div
          className={`${styles.goToPaymentContainer} ${
            !showHelperForPayment ? styles.dontShowPayment : ""
          }`}
          onClick={goToPaymentContainer}
        >
          <button type="button">
            Ir para o Pagamento <BsArrowDownShort />
          </button>
        </div>
      )}
    </>
  );
};

export { Payment };
