import React from "react";
import { components } from "react-select";
import { Tooltip } from "@mui/material";

const LastSelected = ({ value, onRemove, selectedCount, allSelected }) => {
  return (
    <>
      {selectedCount >= 0 && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            padding: "0 10px",
            borderRadius: "10px",
            backgroundColor: "#f4f4f4",
            color: "#4d4c4d",
            fontSize: "12px",
            fontWeight: "bold",
            marginRight: "5px",
            height: "30px",
            lineHeight: "30px"
          }}
        >
          <Tooltip title={value} placement="top">
            <span>{value.slice(0, 19)}</span>
          </Tooltip>

          <span
            onClick={onRemove}
            style={{
              cursor: "pointer",
              color: "#4d4c4d",
              fontSize: "12px",
              fontWeight: "bold",
              marginLeft: "5px"
            }}
          >
            x
          </span>
        </div>
      )}
      {selectedCount > 0 && (
        <Tooltip title={allSelected} placement="top">
          <div>+{selectedCount}</div>
        </Tooltip>
      )}
    </>
  );
};

const ValueContainer = ({ children, getValue, ...props }) => {
  var values = getValue();
  var valueLabel = "";

  if (values.length > 0)
    valueLabel = props.selectProps.getOptionLabel(values[values.length - 1]);

  var childsToRender = React.Children.toArray(children).filter(
    child =>
      ["Input", "DummyInput", "Placeholder"].indexOf(child.type.name) >= 0
  );

  const removeLastValue = () => {
    const newValues = [...values];
    newValues.pop();
    props.setValue(newValues);
  };

  return (
    <components.ValueContainer {...props}>
      {!props.selectProps.inputValue && (
        <LastSelected
          value={valueLabel}
          onRemove={removeLastValue}
          selectedCount={values.length - 1}
          allSelected={values
            .map(value => props.selectProps.getOptionLabel(value))
            .filter((value, index) => index !== values.length - 1)
            .join(",\n\n")}
        />
      )}
      {childsToRender}
    </components.ValueContainer>
  );
};

export { ValueContainer };
