import React, { useCallback, useEffect, useState } from "react";

import { Link, useHistory } from "react-router-dom";
import Select from "react-select";
import Swal from "sweetalert2";
import Switch from "react-switch";

import { BrComerceApiService } from "../../../../services/BrComerceApiService";
import debounce from "../../../../utils/debounce";
import formatCep from "../../../../utils/FormatCep";
import Loading from "../../../home/components/Loading";
import Container from "../../components/Container";
import store from "../../../../store/store";

import { FaCirclePlus } from "react-icons/fa6";
import {
  FiChevronLeft,
  FiChevronRight,
} from "react-icons/fi";

import styles from "./superExpress.module.scss";

const selectPageStyle = {
  control: styles => ({
    ...styles,
    backgroundColor: "inherit",
    width: "100%",
    border: "none",
    boxShadow: "none",
    outline: "none",
    color: "#807E80",
    padding: "0",
    "&:hover": {
      borderColor: "none"
    },
    maxHeight: "48px",
    cursor: "pointer"
  }),
  container: styles => ({
    ...styles,
    minWidth: "56px",
    outline: "none",
    borderBottom: "1px solid #4D4D4D",
    padding: "0",
    cursor: "pointer"
  }),
  indicatorSeparator: styles => ({
    ...styles,
    display: "none",
    border: "none"
  }),
  option: (styles, { isSelected }) => ({
    ...styles,
    fontSize: "14px",
    fontFamily: "Mulish",
    fontWeight: 400,
    color: isSelected ? "#fff" : "#807E80",
    "&:hover": {
      backgroundColor: "#c1c1c1",
      color: "#color: #333333;",
      cursor: "pointer"
    },
    backgroundColor: isSelected ? "#409e6a" : "#fff"
  }),
  valueContainer: styles => ({
    ...styles,
    padding: "0"
  }),
  dropdownIndicator: styles => ({
    ...styles,
    padding: "0",
    color: "#4D4D4D"
  })
};

const SuperExpressRangeList = () => {
  const { auth } = store.getState();
  const { user } = auth;
  const history = useHistory()

  const api = new BrComerceApiService();

  const [ranges, setRanges] = useState([]);

  const [loading, setLoading] = useState(false);
  const [actualPage, setActualPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [resultsPages, setResultsPages] = useState({ label: "10", value: 10 });

  const useZipCode = (initialState = "vazio") => {
    const [zipCodeOK, setZipCodeOK] = useState(initialState);
    const [zipCodeError, setZipCodeError] = useState("");
    const [zipCode, setZipCode] = useState("");
    const [zipCodeFilter, setZipCodeFilter] = useState("");

    const handleValidationZipCode = useCallback((value) => {
      if (value.length === 0) {
        setZipCodeOK("ok");
        setZipCodeError("");
      } else if (value.length < 9) {
        setZipCodeOK("notOk");
        setZipCodeError("Preenchimento incorreto");
      } else {
        setZipCodeOK("ok");
        setZipCodeError("");
      }
    }, []);

    const handleZipCode = useCallback(async (e) => {
      e.currentTarget.maxLength = 9;
      let value = e.currentTarget.value;

      value = value.replace(/\D/g, "");
      value = value.replace(/^(\d{5})(\d{3})/, "$1-$2");

      handleValidationZipCode(value);
      e.currentTarget.value = value;
      setZipCode(value);
    }, [handleValidationZipCode]);

    return {
      zipCodeOK,
      setZipCodeOK,
      zipCodeError,
      setZipCodeError,
      zipCode,
      setZipCode,
      zipCodeFilter,
      setZipCodeFilter,
      handleZipCode,
    };
  };

  const {
    zipCodeOK,
    zipCodeError,
    zipCode,
    zipCodeFilter,
    setZipCodeFilter,
    handleZipCode,
  } = useZipCode();

  const formatZipCode = (value) => {
    return value.replace('-', '');
  }

  const loadRanges = useCallback(
    debounce(async params => {
      try {
        setLoading(true);

        console.log({params})

        const response = await api.makeHttpRequest({
          url: '/rates',
          params: {
            page: params?.page ?? 1,
            resultsPerPage: params?.resultsPages ?? 10,
            ...(params?.zipCode && { zip: formatZipCode(params.zipCode) }),
          }
        });

        setActualPage(response.actualPage);
        setTotalPages(response.nPages);
        setRanges(response.results);
      } catch (e) {
        Swal.fire({
          text: "Erro ao carregar lista de faixas de cep",
          title: "Faixas de CEP",
          icon: "error"
        });
      } finally {
        setLoading(false);
      }
    }, 500),
    [ranges]
  );

  const handleAction = async (id, status) => {
    Swal.fire({
      title: "Você tem certeza?",
      html:
        `Deseja prosseguir com a ${status ? 'ativação' : 'desativação'} da faixa de cep?`,
      icon: "warning",
      cancelButtonText: "Cancelar",
      showCancelButton: true,
      confirmButtonText: `Sim, fazer a ${status ? 'ativação' : 'desativação'}!`
    }).then(response => {
      if (response?.value) {
        handleOnConfirmAction(id, status);
      }
    });
  };

  const handleOnConfirmAction = async (id, status) => {
    try {
      setLoading(true);

      await api.makeHttpRequest({
        method: "PUT",
        url: '/rates',
        data: {
          id, status
        }
      });
    } catch (error) {
      Swal.fire({
        text: `Erro ao ${status ? 'ativar' : 'desativar'} faixa de cep`,
        title: "Faixa de CEP",
        icon: "error"
      });
    } finally {
      setLoading(true);
      loadRanges(actualPage, resultsPages.value)
    }
  }

  const onPage = newPage => {
    setActualPage(newPage - 1);
  };

  const onNext = () => {
    setLoading(true);
    onPage(actualPage + 1);
    setActualPage(actualPage + 1);
  };

  const onPrevious = () => {
    setLoading(true);
    onPage(actualPage - 1);
    setActualPage(actualPage - 1);
  };

  const perPageOptions = [
    {
      label: "10",
      value: 10
    },
    {
      label: "15",
      value: 15
    },
    {
      label: "20",
      value: 20
    },
    {
      label: "50",
      value: 50
    },
    {
      label: "100",
      value: 100
    }
  ];

  useEffect(() => {
    if (user.profile !== "admin") {
      history.push("/");
    }
  }, []);

  useEffect(() => {
    if(zipCode.length === 9) {
      setZipCodeFilter(zipCode)
      setActualPage(1)
    } else {
      setZipCodeFilter("")
    }
  }, [zipCode]);

  useEffect(() => {
    loadRanges({
      page: actualPage,
      resultsPages: resultsPages.value,
      zipCode: zipCodeFilter,
    });
  }, [actualPage, resultsPages, zipCodeFilter]);

  useEffect(() => {
    window.setPageTitle("Faixas de Preço - Super Expresso");
  }, []);

  return (
    <>
      <Loading isLoading={loading} />
      <Container title="Faixas de CEP - Super Expresso">
        <div className={styles.contentBody}>
          <div className={styles.filters}>
            <div className={styles.filterInput} role={zipCodeOK}>
              <div className={styles.input} role={zipCodeOK}>
                <input
                  type="text"
                  name="zipCodeStart"
                  placeholder="Filtre pelo CEP. Ex: 99999-99"
                  value={zipCode}
                  onChange={e => handleZipCode(e)}
                />
              </div>
              {zipCodeError && <p className={styles.error}>{zipCodeError}</p>}
            </div>
            <Link to="/super-expresso/create-range">
              <button type="button" className={styles.btnAddRange}>
                <FaCirclePlus fill="#fff" color="#fff" />
                <span>Criar nova faixa de cep</span>
              </button>
            </Link>
          </div>
          <div className={styles.contentTable}>
            <table className={styles.tableContainer}>
              <thead>
                <tr>
                  <th width="10%">Faixa Inicial</th>
                  <th width="10%">Faixa Final</th>
                  <th width="3%">Ações</th>
                </tr>
              </thead>
              <tbody>
                {!!ranges.length &&
                  ranges.map(range => {
                    return (
                      <tr key={range.id}>
                        <td>{formatCep(range?.zipStart.toString())}</td>
                        <td>{formatCep(range?.zipEnd.toString())}</td>
                        <td className={styles.tooltipWrapper}>
                          <Switch
                            checked={range.active}
                            onChange={() =>
                              handleAction(range.id, !range.active)
                            }
                            offColor="#B3B3B3"
                            onColor="#409E6A"
                            offHandleColor="#FFF"
                            onHandleColor="#FFF"
                            uncheckedIcon={false}
                            checkedIcon={false}
                            width={45}
                            height={23}
                            handleDiameter={13}
                          />
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
            <div className={styles.paginationContainer}>
              <div className={styles.perPageContent}>
                <span>Itens por página:</span>
                <Select
                  styles={selectPageStyle}
                  value={resultsPages}
                  options={perPageOptions}
                  onChange={e => setResultsPages(e)}
                  menuPlacement="top"
                />
              </div>
              <h6>
                {actualPage} - {resultsPages.value} de {totalPages}
              </h6>
              <div className={styles.controlPage}>
                <FiChevronLeft
                  size={25}
                  onClick={actualPage > 1 ? onPrevious : ""}
                  color={actualPage > 1 ? "#409E6A" : "#2D724C"}
                  className="arrow"
                />
                <FiChevronRight
                  size={25}
                  onClick={actualPage < totalPages ? onNext : ""}
                  color={actualPage < totalPages ? "#409E6A" : "#2D724C"}
                  className="arrow"
                />
              </div>
            </div>
          </div>
        </div>
      </Container>
    </>
  )
}

export { SuperExpressRangeList }
