import React from "react";
import useGetWindowWidth from "../../../../utils/useGetWindowWidth";
import styles from "./styles.module.scss";

export default function ScrollDiv({ children, data }) {
  const widthWindows = useGetWindowWidth();

  return (
    <div
      className={styles.scrollDiv}
      id="scrollDiv"
      style={{
        height: data.length ? "70vh" : "",
        overflowY: "auto",
        overflowX: widthWindows < 1600 ? "auto" : "hidden"
      }}
    >
      {children}
    </div>
  );
}
