/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef } from "react";

// We create the useDidMountEffect hook to track whether the first render is done
const useDidMountEffect = (func, deps) => {
  const didMount = useRef(false);

  useEffect(() => {
    if (didMount.current) {
      func();
    } else {
      didMount.current = true;
    }
  }, deps);
};

export { useDidMountEffect };
