import React, { useEffect, useState, useCallback } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { TbLink } from "react-icons/tb";
import {
  AiOutlinePlus,
  AiOutlineMinus,
  AiOutlineCheckCircle,
  AiOutlineCloseCircle
} from "react-icons/ai";
import { GiPostOffice } from "react-icons/gi";
import { LiaTruckSolid, LiaQuestionCircleSolid } from "react-icons/lia";
import { VscNote } from "react-icons/vsc";
import { IoMdCalendar } from "react-icons/io";
import { FaTruckLoading, FaShare, FaRunning } from "react-icons/fa";
import { FaPersonWalkingArrowRight } from "react-icons/fa6";
import { PiArrowCounterClockwise, PiCopySimple } from "react-icons/pi";
import { TbBoxOff } from "react-icons/tb";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { Loading } from "../../../../../components/Loading";
import { BrComerceApiService } from "../../../../../services/BrComerceApiService";
import { momentBDays } from "../../../../../utils/MomentBDay";
import { CardShippingStage } from "../../../components/CardShippingStage";
import styles from "./styles.module.scss";
import { RateTypeShippingEnum } from "../../../../../enums/RateTypeShippingEnum";
import ShippingStagesEnum from "../../../../../enums/ShippingStagesEnum";
import moment from "moment";
import store from "../../../../../store/store";
import DeliveryTypeEnum from "../../../../../enums/DeliveryTypeEnum";
import { DeliveryImageModal } from "../../../../../components/DeliveryImageModal";
import { ReactComponent as Expedido } from "../../../../../assets/icons/expedido.svg";
import ReactModal from "react-modal";

const ShippingDetail = () => {
  const params = useParams();
  const api = new BrComerceApiService();
  const { auth } = store.getState();
  const { user } = auth;

  const [loading, setLoading] = useState(false);
  const [shipping, setShipping] = useState([]);
  const [trackings, setTrackings] = useState([]);
  const [timeline, setTimeline] = useState([]);
  const [lastTracking, setLastTracking] = useState({});
  const [objectsExpand, setObjectsExpand] = useState([]);

  const [showImageModal, setShowImageModal] = useState(false);
  const [dataImage, setDataImage] = useState({
    urlImage: "",
    text: ""
  });

  const [showShareLinkModal, setShowShareLinkModal] = useState(false);
  const [urlTracking, setUrlTracking] = useState("");
  const [copiedLink, setCopiedLink] = useState(false);

  const handleTrackingDescriptions = shipping => {
    if (!shipping.correiosRastreio && !shipping.sro) {
      return;
    }

    const mappedTrackingInCorreios = shipping?.correiosRastreio
      ? shipping.correiosRastreio.reduce((acc, tracking, index, array) => {
        const statusTracking = tracking[0];
        const informationsTracking = tracking[1];

        if (
          informationsTracking.descricao.toLowerCase() === "objeto postado"
        ) {
          return [
            ...acc,
            {
              status: statusTracking,
              date: shipping?.type === DeliveryTypeEnum.OWN_DELIVERY ?
                momentBDays(informationsTracking.data).format("DD/MM/YYYY - HH:mm") :
                momentBDays(informationsTracking.data).add(3, "h").format("DD/MM/YYYY - HH:mm"),
              description: informationsTracking.cidade || "",
              correiosDescription: informationsTracking?.descricao,
              address: informationsTracking?.endereco || "",
              icon: timelineCollect.find(tc => tc.value === statusTracking).icon
            }
          ];
        }

        if (
          informationsTracking.descricao.toLowerCase() ===
          "objeto em trânsito - por favor aguarde"
        ) {
          const hasNextItemInArray = array[index + 1];

          if (hasNextItemInArray) {
            return [
              ...acc,
              {
                status: statusTracking,
                date: shipping?.type === DeliveryTypeEnum.OWN_DELIVERY ?
                  momentBDays(informationsTracking.data).format("DD/MM/YYYY") :
                  momentBDays(informationsTracking.data).add(3, "h").format("DD/MM/YYYY"),
                description: `${informationsTracking.cidade ||
                  ""}/${hasNextItemInArray[1].cidade || ""}`,
                correiosDescription: informationsTracking?.descricao,
                address: informationsTracking?.endereco || "",
                icon: timelineCollect.find(tc => tc.value === statusTracking).icon
              }
            ];
          }

          return [
            ...acc,
            {
              status: statusTracking,
              date: shipping?.type === DeliveryTypeEnum.OWN_DELIVERY ?
                momentBDays(informationsTracking.data).format("DD/MM/YYYY") :
                momentBDays(informationsTracking.data).add(3, "h").format("DD/MM/YYYY"),
              description: informationsTracking.cidade || "",
              correiosDescription: informationsTracking?.descricao,
              address: informationsTracking?.endereco || "",
              icon: timelineCollect.find(tc => tc.value === statusTracking).icon
            }
          ];
        }

        if (
          informationsTracking.descricao.toLowerCase() ===
          "objeto pronto para envio" ||
          informationsTracking.descricao.toLowerCase() ===
          "objeto em trânsito"
        ) {
          return [
            ...acc,
            {
              status: "Objeto em trânsito",
              date: shipping?.type === DeliveryTypeEnum.OWN_DELIVERY ?
                momentBDays(informationsTracking.data).format("DD/MM/YYYY - HH:mm") :
                momentBDays(informationsTracking.data).add(3, "h").format("DD/MM/YYYY - HH:mm"),
              description: informationsTracking.cidade,
              correiosDescription: "Objeto em trânsito",
              address: informationsTracking?.endereco || "",
              icon: timelineCollect.find(tc => tc.value === "Objeto em trânsito").icon
            }
          ];
        }

        return [
          ...acc,
          {
            status: statusTracking,
            date: shipping?.type === DeliveryTypeEnum.OWN_DELIVERY ?
              momentBDays(informationsTracking.data).format("DD/MM/YYYY - HH:mm") :
              momentBDays(informationsTracking.data).add(3, "h").format("DD/MM/YYYY - HH:mm"),
            description: informationsTracking.cidade || "",
            correiosDescription: informationsTracking?.descricao,
            address: informationsTracking?.endereco || "",
            icon: timelineCollect.find(tc => tc.value === statusTracking).icon
          }
        ];
      }, [])
      : [];

    if (
      theTrackingDontHasAFinishedItem &&
      (shipping.numeroPLP > 0 ||
        (shipping.type === DeliveryTypeEnum.OWN_DELIVERY &&
          shipping.previewDate))
    ) {
      mappedTrackingInCorreios.push({
        status: "Entregue",
        date:
          shipping?.previewDate &&
            (shipping?.plpDate || shipping.type === DeliveryTypeEnum.OWN_DELIVERY)
            ? momentBDays(shipping?.previewDate).format("DD/MM/YYYY")
            : "Pendente",
        description: shipping.destination.city.toUpperCase(),
        type: "inactive"
      });
    }

    // Adicionando Criação no início do rastreio.
    if (shipping?.sro) {
      mappedTrackingInCorreios.splice(0, 0, {
        status: "Criado",
        date: momentBDays(shipping?.created_at).format("DD/MM/YYYY - HH:mm"),
        description: shipping.origin.city.toUpperCase(),
        icon: timelineCollect.find(tc => tc.value === "Criado").icon
      });
    }

    const itIsCollectAndHasCollectDate =
      shipping?.rateType === RateTypeShippingEnum.COLLECT &&
      shipping?.collect?.collectDate !== null;

    // if (itIsCollectAndHasCollectDate) {
    //   mappedTrackingInCorreios.splice(1, 0, {
    //     status: "Coleta agendada",
    //     date: momentBDays(shipping?.collect?.collectDate).format(
    //       "DD/MM/YYYY - HH:mm"
    //     ),
    //     description: shipping.origin.city.toUpperCase(),
    //     icon: timelineCollect.find(tc => tc.value === "Coleta agendada").icon
    //   });
    // }

    if (shipping.status.name === ShippingStagesEnum.OBJETO_CANCELADO.message) {
      mappedTrackingInCorreios.push({
        status: "Objeto Cancelado",
        date: momentBDays(shipping?.collect?.collectDate).format(
          "DD/MM/YYYY - HH:mm"
        ),
        description: shipping.origin.city.toUpperCase()
      });
    }

    if (shipping?.numeroPLP !== null && shipping?.numeroPLP !== 0) {
      if (shipping?.correiosRastreio?.length) {
        const firstRastreio = shipping.correiosRastreio[0];

        mappedTrackingInCorreios.splice(
          itIsCollectAndHasCollectDate ? 2 : 1,
          0,
          {
            status: "Expedido",
            date:
              new Date(shipping?.plpDate) > new Date(firstRastreio[1]?.data)
                ? momentBDays(firstRastreio[1]?.data).format("DD/MM/YYYY")
                : momentBDays(shipping?.plpDate).format("DD/MM/YYYY"),
            description: shipping.origin.city.toUpperCase(),
            icon: timelineCollect.find(tc => tc.value === "Expedido").icon
          }
        );
      } else {
        mappedTrackingInCorreios.splice(
          itIsCollectAndHasCollectDate ? 2 : 1,
          0,
          {
            status: "Expedido",
            date: shipping?.plpDate
              ? momentBDays(shipping?.plpDate).format("DD/MM/YYYY")
              : momentBDays(shipping?.created_at).format("DD/MM/YYYY"),
            description: shipping.origin.city.toUpperCase(),
            icon: timelineCollect.find(tc => tc.value === "Expedido").icon
          }
        );
      }
    }

    const theTrackingDontHasAFinishedItem =
      !!mappedTrackingInCorreios.length === false ||
      mappedTrackingInCorreios.find(
        item =>
          item.status.toLowerCase() === "entrega realizada" ||
          item.status.toLowerCase() === "objeto devolvido" ||
          item.status.toLowerCase() === "objeto pronto para retirada" ||
          item.status.toLowerCase() === "objeto entregue" ||
          item.status === "Expedido"
      ) === undefined;

    if (
      theTrackingDontHasAFinishedItem &&
      (shipping.numeroPLP > 0 ||
        (shipping.type === DeliveryTypeEnum.OWN_DELIVERY &&
          shipping.previewDate))
    ) {
      mappedTrackingInCorreios.push({
        status: "Entrega realizada",
        date:
          shipping?.previewDate &&
            (shipping?.plpDate || shipping.type === DeliveryTypeEnum.OWN_DELIVERY)
            ? momentBDays(shipping?.previewDate).format("DD/MM/YYYY - HH:mm")
            : "Pendente",
        description: shipping.destination.city.toUpperCase(),
        type: "inactive",
        icon: timelineCollect.find(tc => tc.value === "Entrega realizada").icon
      });
    }

    const lastStatus = mappedTrackingInCorreios.slice(-1);

    setLastTracking(lastStatus[0]);

    handleMountTime(mappedTrackingInCorreios);
    setTrackings(mappedTrackingInCorreios);
  };

  const handleCEP = value => {
    return value.replace(/\D/g, "").replace(/^(\d{5})(\d{3})/, "$1-$2");
  };

  const handlePhone = value => {
    value = value.replace(/\D/g, "");
    if (value.length === 11) {
      value = value.replace(/^(\d{2})(\d{5})(\d{4})/, "($1) $2-$3");
    } else if (value.length === 10) {
      value = value.replace(/^(\d{2})(\d{4})(\d{4})/, "($1) $2-$3");
    } else if (value.length === 13) {
      value = value.replace(/^(\d{2})(\d{2})(\d{4})(\d{4})/, "+$1 ($2) $3-$4");
    }

    return value;
  };

  const handleCPFAndCnpj = value => {
    if (value.length <= 11) {
      return value
        .replace(/\D/g, "")
        .replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
    } else {
      return value
        .replace(/\D/g, "")
        .replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
    }
  };

  const handleDataFormat = shipping => {
    if (!shipping) return;

    const shippingFormatted = {
      ...shipping,
      origin: {
        ...shipping.origin,
        zip: handleCEP(shipping.origin.zip),
        phone: handlePhone(shipping.origin.phone),
        document: handleCPFAndCnpj(shipping?.origin?.document)
      },
      destination: {
        ...shipping.destination,
        zip: handleCEP(shipping.destination.zip),
        phone: handlePhone(shipping.destination.phone),
        document: handleCPFAndCnpj(shipping?.destination?.document)
      }
    };

    setShipping(shippingFormatted);
  };

  const handleBtnShareTracking = async () => {
    if (!!shipping?.sro === false) return;

    const url = `${window.location.origin}/rastreio/${shipping?.sro}`;
    setUrlTracking(url);
    setShowShareLinkModal(true);
  };

  const handleObjectExpand = useCallback(
    object => {
      const find = objectsExpand.find(obj => obj === object);
      if (find) {
        const newObject = objectsExpand.filter(obj => obj !== object);
        setObjectsExpand([...newObject]);
      } else {
        objectsExpand.push(object);
        setObjectsExpand([...objectsExpand]);
      }
    },
    [objectsExpand]
  );

  const handleStatusColor = status => {
    const color = {
      [ShippingStagesEnum.NAO_FOI_POSSIVEL_ENTREGAR_O_OBJETO.message]:
        "#FF6B71",
      [ShippingStagesEnum.OBJETO_EXTRAVIADO.message]: "#FF6B71",
      "Objeto extraviado": "#FF6B71",
      [ShippingStagesEnum.OBJETO_NAO_FOI_ENTREGUE.message]: "#FF6B71",
      [ShippingStagesEnum.OBJETO_CANCELADO.message]: "#FF6B71",
      [ShippingStagesEnum.OBJETO_DEVOLVIDO.message]: "#FFAF52",
      "Objeto pronto para retirada": "#3C7DD9"
    };

    return color[status] && lastTracking.status === status ? color[status] : "";
  };

  const handleMountTime = track => {
    const timeline = [];

    track.forEach(item => {
      timelineCollect.find(itemTimeline => {
        if (itemTimeline.value === item.status) timeline.push(itemTimeline);
      });
    });

    const uniqueArray = [timeline[0]];

    for (let i = 1; i < timeline.length; i++) {
      if (timeline[i] !== timeline[i - 1]) {
        uniqueArray.push(timeline[i]);
      }
    }

    setTimeline(uniqueArray);
  };

  useEffect(() => {
    (async () => {
      try {
        const id = params.id;
        setLoading(true);
        const response = await api.makeHttpRequest({
          method: "GET",
          url: `shipping/${id}`
        });

        handleDataFormat(response);
        const createdIn =
          response?.rateType === RateTypeShippingEnum.COLLECT &&
            response?.collect?.collectDate
            ? response?.collect?.collectDate
            : moment(response?.collect?.created_at)
              .add(1, "days")
              .format("YYYY-MM-DDTHH:mm:ss.SSSSZ");

        handleTrackingDescriptions({
          ...response,
          collect: {
            ...response.collect,
            collectDate: createdIn
          }
        });
      } catch (e) {
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: "Rastreio",
          text: "Não encontramos informações sobre esse objeto!"
        });
        console.error(e);
      } finally {
        setLoading(false);
      }
    })();
  }, [params]);

  useEffect(() => {
    if (!copiedLink) return;

    const timeout = setTimeout(() => {
      setCopiedLink(false);
    }, 2500);

    return () => clearTimeout(timeout);
  }, [copiedLink]);

  window.setPageTitle("Detalhes do Objeto");

  const origin = shipping?.originCreation;
  const originCreationUppercase =
    origin && origin[0]?.toUpperCase() + origin?.substr(1);
  const rateType =
    shipping?.rateType === RateTypeShippingEnum.COLLECT ? "Coleta" : "Correios";
  const isLogisticReverse =
    shipping?.isLogisticsReverse === false ? "Não" : "Sim";
  const createdAt = moment(shipping?.created_at)
    .subtract(3, "hours")
    .format("DD/MM/YYYY - HH:mm");
  const plpDate = shipping?.plpDate
    ? moment(shipping?.plpDate).format("DD/MM/YYYY - HH:mm")
    : "-";
  const shippingIsOwnDelivery =
    shipping?.type === DeliveryTypeEnum.OWN_DELIVERY;

  const declarationOfContentsValue = !!shipping?.declarationOfContents?.length ?
    shipping?.declarationOfContents.reduce((a, b) => {
      let total = 0;

      if (typeof b?.valorUnitario === "string") {
        total =
          Number(
            b?.valorUnitario?.replace(/[.]/g, "").replace(/[,]/g, ".")
          ) * Number(b?.quantidade);
      } else {
        total = Number(b?.valorUnitario) * Number(b?.quantidade);
      }

      return total + a;
    }, 0)
    : 0;

  const handleCloseButton = () => {
    setDataImage({
      urlImage: "",
      text: ""
    });
    setShowImageModal(false);
  };

  const timelineCollect = [
    {
      text: "Objeto Criado",
      icon: <VscNote />,
      value: "Criado"
    },
    {
      text: "Coleta agendada",
      icon: <IoMdCalendar />,
      value: "Coleta agendada"
    },
    {
      text: "Objeto em trânsito",
      icon: <FaTruckLoading />,
      value: "Objeto pronto para envio"
    },
    {
      text: "Expedido",
      icon: <Expedido />,
      value: "Expedido"
    },
    {
      text: "Expedido",
      icon: <Expedido />,
      value: "Objeto Expedido"
    },
    {
      text: "Em trânsito",
      icon: <LiaTruckSolid />,
      value: "Objeto em trânsito"
    },
    {
      text: "Outros",
      icon: <LiaQuestionCircleSolid />,
      value: "Outros"
    },
    {
      text: "Pronta para Retirada",
      icon: <FaPersonWalkingArrowRight />,
      value: "Objeto pronto para retirada"
    },
    {
      text: "Saiu para entrega",
      icon: <FaRunning />,
      value: "Objeto saiu para entrega"
    },
    {
      text: "Erro na entrega",
      icon: <AiOutlineCloseCircle />,
      value: "Objeto extraviado"
    },
    {
      text: "Entregue",
      icon: <AiOutlineCheckCircle />,
      value: "Entrega realizada"
    },
    {
      text: "Entregue",
      icon: <AiOutlineCheckCircle />,
      value: "Objeto entregue"
    },
    {
      text: "Devolvido",
      icon: <PiArrowCounterClockwise />,
      value: "Objeto Devolvido"
    },
    {
      text: "Não entregue",
      icon: <AiOutlineCloseCircle />,
      value: "Não foi possível entregar o objeto"
    },
    {
      text: "Não entregue",
      icon: <AiOutlineCloseCircle />,
      value: "Objeto não foi entregue"
    },
    {
      text: "Cancelado",
      icon: <TbBoxOff />,
      value: "Objeto Cancelado"
    },
    {
      text: "Objeto Postado",
      icon: <GiPostOffice />,
      value: "Objeto Postado"
    }
  ];

  return (
    <>
      <Loading loading={loading} />
      <ReactModal
        isOpen={showShareLinkModal}
        onRequestClose={() => setShowShareLinkModal(false)}
        style={{
          overlay: {
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(51, 51, 51, 0.75)",
            zIndex: 999
          },
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            transform: "translate(-50%, -50%)",
            zIndex: 999,
            border: "none",
            backgroundColor: "transparent",
            padding: 0
          }
        }}
      >
        <div className={styles.shareLinkModal}>
          <FaShare color="#F5CB2B" size={40} />
          <h3>Link de Rastreio</h3>

          <span>
            O link abaixo pode ser compartilhado com qualquer pessoa, mesmo que
            não esteja cadastrada na BRCom.
          </span>

          <div
            className={styles.input}
            onClick={() => {
              navigator.clipboard.writeText(urlTracking);
              setCopiedLink(true);
            }}
          >
            <input type="text" value={urlTracking} id="inputTrackingInfo" />
            <PiCopySimple size={20} color="#409e6a" />
          </div>

          {copiedLink && (
            <p className={styles.textClipped}>Copiado com sucesso !</p>
          )}

          <div className={styles.buttonContainer}>
            <button
              onClick={() => {
                setShowShareLinkModal(false);
              }}
              style={{ backgroundColor: "#F5CB2B" }}
            >
              Fechar
            </button>
          </div>
        </div>
      </ReactModal>
      {showImageModal && (
        <DeliveryImageModal
          urlImage={dataImage.urlImage}
          text={dataImage.text}
          handleCloseButton={handleCloseButton}
          isOpen={showImageModal}
        />
      )}
      <Container fluid className={styles.container}>
        <Row className={`h-100 justify-content-between`}>
          <Col className={`col ${styles.trackingContainer}`}>
            <div className={styles.header}>
              <div className={styles.title}>
                <h2>Detalhes do objeto</h2>
              </div>
            </div>

            <div className={styles.subheader}>
              <div className={styles.code}>
                <div>
                  <h3>Código de rastreio: </h3>
                  <span>{shipping?.sro}</span>
                </div>

                {shipping?.previewDate &&
                  (shipping?.plpDate ||
                    shipping.type === DeliveryTypeEnum.OWN_DELIVERY) && (
                    <div>
                      <h3>Previsão de entrega: </h3>
                      <span>
                        {moment(shipping?.previewDate).format("DD/MM/YYYY")}
                      </span>
                    </div>
                  )}
              </div>

              <div className={styles.share}>
                <button
                  type="button"
                  onClick={handleBtnShareTracking}
                  className={styles.shareItem}
                >
                  <div className={styles.iconMail}>
                    <TbLink />
                  </div>
                  <span>Compartilhar link de rastreio</span>
                </button>
              </div>
            </div>

            {window.screen.width > 769 ?
              <div className={styles.timelineContainer}>
                {timeline.map((item, index) => {
                  return (
                    <>
                      <div className={styles.content} key={item.value}>
                        <div className={styles.statusContainer}>
                          <div
                            style={{
                              background: handleStatusColor(item.value)
                            }}
                            className={styles.status}
                          >
                            {item.icon}
                          </div>
                          <span
                            style={{
                              color: handleStatusColor(item.value)
                            }}
                          >
                            {item.text}
                          </span>
                        </div>
                        <div className={styles.dotContainer}>
                          {timeline?.length - 1 !== index && (
                            <>
                              {[...Array(3)].map((element, index) => (
                                <div key={index} className={styles.dot} />
                              ))}
                            </>
                          )}
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
              : <></>}

            {shipping?.sro ? (
              <div className={styles.cardsList}>
                {trackings?.map((tracking, index) => {
                  return (
                    <CardShippingStage
                      shipping={tracking}
                      key={index}
                      type={tracking.type}
                      last={trackings?.length - 1 === index}
                      order={index}
                    />
                  );
                })}
              </div>
            ) : (
              <div
                className={`d-flex justify-content-center align-items-center ${styles.trackingNotFound}`}
              >
                <h2>Ainda não temos informações de rastreio.</h2>
              </div>
            )}

            {shipping.deliveriesData && shippingIsOwnDelivery && (
              <div className={styles.deliveryInformation}>
                <div className={styles.header}>
                  <div className={styles.title}>
                    <h2>Detalhes da entrega</h2>
                  </div>
                </div>
                {shipping?.deliveriesData[0]?.urlImage ? (
                  <div className={styles.containerImages}>
                    {shipping?.deliveriesData?.map((data, index) => {
                      return (
                        <div>
                          <p>
                            <strong>{`${index + 1}ª Tentativa`}</strong>
                          </p>
                          <span>
                            <strong>{"Entrega com sucesso: "}</strong>
                            {data.success ? "Sim" : "Não"}
                          </span>
                          <span>
                            <strong>{"Data: "}</strong>
                            {moment(data.delivery_date).format(
                              "DD/MM/YYYY - HH:mm"
                            )}
                          </span>
                          <span>
                            <strong>{"Assinado por: "}</strong>
                            {data.success ? data.signed_by : "--"}
                          </span>
                          <span>
                            <strong>{"Documento: "}</strong>
                            {data.success ? data.document : "--"}
                          </span>
                          <img
                            src={data.urlImage}
                            alt={`Entrega ${index}`}
                            onClick={() => {
                              setDataImage({
                                urlImage: data.urlImage,
                                text: `${index + 1}ª Tentativa de entrega`
                              });
                              setShowImageModal(true);
                            }}
                          />
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <div
                    className={`d-flex justify-content-center align-items-center ${styles.trackingNotFound}`}
                  >
                    <h2>Ainda não temos informações de entrega</h2>
                  </div>
                )}
              </div>
            )}
          </Col>
          <Col xs={12} xl={5} className={`${styles.packageInfoContainer}`}>
            <div className={styles.packageTitle}>
              <h3>Informações do objeto</h3>
            </div>
            <Row className={`${styles.packageInfo} m-0`}>
              <Col xs={12} className={`${styles.contentWrapper} p-0`}>
                <div className={styles.packageHeader}>
                  <h3>DADOS DO REMETENTE</h3>
                  {objectsExpand.includes("Remetente") ? (
                    <AiOutlineMinus
                      onClick={() => handleObjectExpand("Remetente")}
                      color="#409E6A"
                      size={22}
                    />
                  ) : (
                    <AiOutlinePlus
                      onClick={() => handleObjectExpand("Remetente")}
                      color="#409E6A"
                      size={22}
                    />
                  )}
                </div>
                <div
                  className={
                    objectsExpand.includes("Remetente")
                      ? styles.packageBody
                      : styles.packageBodyHidden
                  }
                >
                  <Row>
                    <Col xs={12} md={6}>
                      <strong>Nome:</strong>
                      <p>{shipping?.origin?.name}</p>
                    </Col>
                    <Col xs={12} md={6}>
                      <strong>Endereço:</strong>
                      <p>{shipping?.origin?.street}</p>
                    </Col>
                    <Col xs={12} md={6}>
                      <strong>CPF/CNPJ:</strong>
                      <p>{shipping?.origin?.document}</p>
                    </Col>
                    <Col xs={12} md={6}>
                      <strong>Número:</strong>
                      <p>{shipping?.origin?.number}</p>
                    </Col>
                    <Col xs={12} md={6}>
                      <strong>Complemento:</strong>
                      <p>{shipping?.origin?.complement || "-"}</p>
                    </Col>
                    <Col xs={12} md={6}>
                      <strong>Telefone:</strong>
                      <p>{shipping?.origin?.phone || "-"}</p>
                    </Col>
                    <Col xs={12} md={6}>
                      <strong>Bairro:</strong>
                      <p>{shipping?.origin?.district}</p>
                    </Col>
                    <Col xs={12} md={6}>
                      <strong>CEP:</strong>
                      <p>{shipping?.origin?.zip}</p>
                    </Col>
                    <Col xs={12} md={6}>
                      <strong>Cidade:</strong>
                      <p>{shipping?.origin?.city}</p>
                      <strong>Estado:</strong>
                      <p>{shipping?.origin?.state}</p>
                    </Col>
                    <Col xs={12}>
                      <strong>Observações:</strong>
                      <p>{shipping?.origin?.observation || "-"}</p>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col xs={12} className={`${styles.contentWrapper} p-0`}>
                <div className={styles.packageHeader}>
                  <h3>DADOS DO DESTINATÁRIO</h3>
                  {objectsExpand.includes("Destinatário") ? (
                    <AiOutlineMinus
                      onClick={() => handleObjectExpand("Destinatário")}
                      color="#409E6A"
                      size={22}
                    />
                  ) : (
                    <AiOutlinePlus
                      onClick={() => handleObjectExpand("Destinatário")}
                      color="#409E6A"
                      size={22}
                    />
                  )}
                </div>
                <div
                  className={
                    objectsExpand.includes("Destinatário")
                      ? styles.packageBody
                      : styles.packageBodyHidden
                  }
                >
                  <Row>
                    <Col xs={12} md={6}>
                      <strong>Nome:</strong>
                      <p>{shipping?.destination?.name}</p>
                    </Col>
                    <Col xs={12} md={6}>
                      <strong>Endereço:</strong>
                      <p>{shipping?.destination?.street}</p>
                    </Col>
                    <Col xs={12} md={6}>
                      <strong>CPF/CNPJ:</strong>
                      <p>{shipping?.destination?.document}</p>
                    </Col>
                    <Col xs={12} md={6}>
                      <strong>Número:</strong>
                      <p>{shipping?.destination?.number}</p>
                    </Col>
                    <Col xs={12} md={6}>
                      <strong>Complemento:</strong>
                      <p>{shipping?.destination?.complement || "-"}</p>
                    </Col>
                    <Col xs={12} md={6}>
                      <strong>Telefone:</strong>
                      <p>{shipping?.destination?.phone || "-"}</p>
                    </Col>
                    <Col xs={12} md={6}>
                      <strong>Bairro:</strong>
                      <p>{shipping?.destination?.district}</p>
                    </Col>
                    <Col xs={12} md={6}>
                      <strong>CEP:</strong>
                      <p>{shipping?.destination?.zip}</p>
                    </Col>
                    <Col xs={12} md={6}>
                      <strong>Cidade:</strong>
                      <p>{shipping?.destination?.city}</p>
                      <strong>Estado:</strong>
                      <p>{shipping?.destination?.state}</p>
                    </Col>
                    <Col xs={12}>
                      <strong>Observações:</strong>
                      <p>{shipping?.destination?.observation || "-"}</p>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col xs={12} className={`${styles.contentWrapper} p-0`}>
                <div className={styles.packageHeader}>
                  <h3>DADOS DO PACOTE</h3>

                  {objectsExpand.includes("Pacote") ? (
                    <AiOutlineMinus
                      onClick={() => handleObjectExpand("Pacote")}
                      color="#409E6A"
                      size={22}
                    />
                  ) : (
                    <AiOutlinePlus
                      onClick={() => handleObjectExpand("Pacote")}
                      color="#409E6A"
                      size={22}
                    />
                  )}
                </div>
                <div
                  className={
                    objectsExpand.includes("Pacote")
                      ? styles.packageBody
                      : styles.packageBodyHidden
                  }
                >
                  <Row>
                    {shipping?.identificationPackage && (
                      <Col xs={12} md={6}>
                        <strong>Identificação:</strong>
                        <p>{shipping?.identificationPackage}</p>
                      </Col>
                    )}
                    <Col xs={12} md={6}>
                      <strong>Modalidade:</strong>
                      <p>
                        {shippingIsOwnDelivery
                          ? "Entrega Própria"
                          : shipping?.type}
                      </p>
                    </Col>
                    <Col xs={12} md={6}>
                      <strong>Tipo de Pacote:</strong>
                      <p>{shipping?.format}</p>
                    </Col>
                    <Col xs={12} md={6}>
                      <strong>Valor do Frete:</strong>
                      <p>
                        {shipping?.price &&
                          Intl.NumberFormat("pt-BR", {
                            style: "currency",
                            currency: "BRL"
                          }).format(shipping?.price)}
                      </p>
                    </Col>
                    <Col xs={12} md={6}>
                      <strong>Altura:</strong>
                      <p>{shipping?.height}cm</p>
                    </Col>
                    <Col xs={12} md={6}>
                      <strong>Valor do Seguro:</strong>
                      <p>
                        {shipping?.insurance &&
                          Intl.NumberFormat("pt-BR", {
                            style: "currency",
                            currency: "BRL"
                          }).format(shipping?.insuranceValue)}
                      </p>
                    </Col>
                    <Col xs={12} md={6}>
                      <strong>Largura:</strong>
                      <p>{shipping?.width}cm</p>
                    </Col>
                    <Col xs={12} md={6}>
                      <strong>Comprimento:</strong>
                      <p>{shipping?.length}cm</p>
                    </Col>
                    <Col xs={12} md={6}>
                      <strong>Peso:</strong>
                      <p>{shipping?.weight}kg</p>
                    </Col>
                    <Col xs={12} md={6}>
                      <strong>Valor da NF:</strong>
                      <p>
                        {(shipping?.invoiceValue || declarationOfContentsValue) &&
                          Intl.NumberFormat("pt-BR", {
                            style: "currency",
                            currency: "BRL"
                          }).format(shipping?.invoiceValue > 0 ? shipping?.invoiceValue : declarationOfContentsValue)}
                      </p>
                    </Col>
                  </Row>
                </div>
              </Col>
              {user?.profile === "admin" && (
                <Col xs={12} className={`${styles.contentWrapper} p-0`}>
                  <div className={styles.packageHeader}>
                    <h3>Informações para o Administrador</h3>
                  </div>
                  <div className={styles.packageBody}>
                    <Row>
                      <Col xs={12} md={6}>
                        <strong>Origem:</strong>
                        <p>{originCreationUppercase}</p>
                      </Col>
                      <Col xs={12} md={6}>
                        <strong>Criado em:</strong>
                        <p>{createdAt}</p>
                      </Col>
                      <Col xs={12} md={6}>
                        <strong>Tipo de postagem:</strong>
                        <p>{rateType}</p>
                      </Col>
                      <Col xs={12} md={6}>
                        <strong>Logística reversa:</strong>
                        <p>{isLogisticReverse}</p>
                      </Col>
                      <Col xs={12} md={6}>
                        <strong>PLP:</strong>
                        <p>{shipping?.numeroPLP || "-"}</p>
                      </Col>
                      <Col xs={12} md={6}>
                        <strong>Data de criação da PLP:</strong>
                        <p>{plpDate}</p>
                      </Col>
                    </Row>
                  </div>
                </Col>
              )}
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export { ShippingDetail };