import React from "react";
import { Route, Redirect } from "react-router-dom";
import { FloatingWhatsApp } from "../../pages/admin/components/FloatingWhatsApp";
import { BlockedPage } from "../../pages/admin/pages/BlockedPage/BlockedPage";

function PrivateRoute({ children, isAuthenticated, isBlocked, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isBlocked
          ? <BlockedPage />
          : isAuthenticated
            ? (
              <>
                {children}
                <FloatingWhatsApp />
              </>
            )
            : (
              <Redirect
                to={{
                  pathname: "/auth/login",
                  state: { from: location }
                }}
              />
            )
      }
    />
  );
}

export default PrivateRoute;
