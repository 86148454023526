import React from "react";
import { useHistory } from "react-router-dom";
import { BulletPoint, SlideCarousel } from "./components";
import "react-responsive-carousel/lib/styles/carousel.css"; // requires a loader

import optionsIntegrations from "../../../../../assets/images/integration/optionsIntegrations.png";
import wooCommerceCard from "../../../../../assets/images/woocommerce/wooCommerceCard.png";

import woo1 from "../../../../../assets/images/woocommerce/woo1.png";
import woo2 from "../../../../../assets/images/woocommerce/woo2.png";
import woo3 from "../../../../../assets/images/woocommerce/woo3.png";
import woo4 from "../../../../../assets/images/woocommerce/woo4.png";
import woo5 from "../../../../../assets/images/woocommerce/woo5.png";

import woo6 from "../../../../../assets/images/woocommerce/woo6.png";
import woo7 from "../../../../../assets/images/woocommerce/woo7.png";

import woo8 from "../../../../../assets/images/woocommerce/woo8.png";
import woo9 from "../../../../../assets/images/woocommerce/woo9.png";
import woo10 from "../../../../../assets/images/woocommerce/woo10.png";
import woo11 from "../../../../../assets/images/woocommerce/woo11.png";

import woo13 from "../../../../../assets/images/woocommerce/woo13.png";
import woo14 from "../../../../../assets/images/woocommerce/woo14.png";

import woo16 from "../../../../../assets/images/woocommerce/woo16.png";
import woo17 from "../../../../../assets/images/woocommerce/woo17.png";

import woo18 from "../../../../../assets/images/woocommerce/woo18.png";

import styles from "./styles.module.scss";

const TutorialWoommerce = () => {
  const steps1th = [
    {
      image: woo1
    },
    {
      image: woo2
    },
    {
      image: woo3
    },
    {
      image: woo4
    },
    {
      image: woo5
    }
  ];

  const steps2th = [
    {
      image: woo6
    },
    {
      image: woo7
    }
  ];

  const steps3th = [
    {
      image: woo8
    },
    {
      image: woo9
    },
    {
      image: woo10
    },
    {
      image: woo11
    }
  ];

  const steps4th = [
    {
      image: optionsIntegrations
    },
    {
      image: wooCommerceCard
    }
  ];

  const steps5th = [
    {
      image: woo13
    },
    {
      image: woo14
    }
  ];

  const steps6th = [
    {
      image: woo16
    },
    {
      image: woo17
    }
  ];

  const history = useHistory();

  return (
    <main className={styles.container}>
      <div className={styles.content}>
        <header className={styles.contentHeader}>
          <p>Como fazer a integração?</p>
        </header>
        <div className={styles.contentBody}>
          <section className={styles.contentSection}>
            <h4>
              Passo a passo para conectar sua plataforma WooCommerce à BRCom
            </h4>

            <BulletPoint order="1">
              <p>
                Primeiramente, faça o download do plugin da WooCommerce clicando
                no botão baixo:
              </p>
            </BulletPoint>

            <button className={`${styles.button} ${styles.pluginButton}`}>
              <a
                href="https://brcom-storage-public.s3.us-east-2.amazonaws.com/integrations/woocommerce/latest/brcom-plugin-woocommerce.zip"
                download
                className={styles.pluginDownload}
              >
                Faça aqui o download da versão mais atual do plugin
              </a>
            </button>

            <BulletPoint order="2">
              <p>
                Para realizar a instalação do plugin, acesse o painel da
                WooCommerce com seu login e senha, selecione no menu lateral a
                opção <b>Plugins</b> e selecione a opção <b>Adicionar novo</b>.
                Você será redirecionado para a tela de instalação: clique na
                opção <b>Enviar</b> para habilitar o envio de arquivo. Selecione
                o arquivo do plugin, clique em <b>Abrir</b> e, a seguir, clique
                em <b>Instalar agora:</b>
              </p>
            </BulletPoint>

            <SlideCarousel steps={steps1th} />

            <BulletPoint order="3">
              <p>
                Se tiver um plugin anterior, peça para fazer substituição do
                atual pelo enviado agora. Se não, siga com a opção que aparecer
                na tela. A seguir, clique em{" "}
                <b>Ir para o instalador de plugins:</b>
              </p>
            </BulletPoint>

            <SlideCarousel steps={steps2th} />

            <BulletPoint order="4">
              <p>
                De volta ao painel principal, procure no menu lateral a opção
                WooCommerce, e clique na opção <b>Configurações</b>. Clique na
                aba <b>Integração</b> e clique no botão escrito BRComerce. Você
                será redirecionado para preencher um formulário com todas as
                informações pertinentes à integração.
              </p>
            </BulletPoint>

            <SlideCarousel steps={steps3th} />

            <BulletPoint order="5">
              <p>
                Em outra aba ou janela do seu navegador, faça o login na
                plataforma da BRComerce e clique na opção <b>Integrações</b> no
                menu lateral. Na tela de <b>Integrações</b>, clique em{" "}
                <b>Selecionar</b> do segundo card <br /> e depois clique em{" "}
                <b>Configurações</b> no card da plataforma WooCommerce:
              </p>
            </BulletPoint>

            <SlideCarousel steps={steps4th} />

            <BulletPoint order="6">
              <p>
                Antes de gerar sua Api Key, você deve informar o site da sua
                loja (ao gerar uma nova chave, a antiga sempre será
                desabilitada, e além disso, a chave gerada abaixo é única e
                intransferível). <br />
                Clique em <b>Gerar Nova Chave</b> e copie o código gerado:
              </p>
            </BulletPoint>

            <SlideCarousel steps={steps5th} />

            <BulletPoint order="7">
              <p>
                Retorne à aba ou janela do seu navegador com o painel da
                WooCommerce aberto e cole sua API Key no primeiro campo do
                formulário. A API Key é utilizada para que as informações sejam
                autenticadas junto a API da BRComerce.
              </p>
            </BulletPoint>

            <SlideCarousel steps={steps6th} />

            <BulletPoint order="8">
              <p>
                Abaixo estão duas <b>Configurações Adicionais</b> caso deseja
                complementar sua integração em nossa plataforma.
              </p>
            </BulletPoint>

            <div className={`${styles.imgs}`}>
              <img src={woo18} alt="Config Adc." className={styles.cardImage} />
            </div>

            <button
              onClick={() => history.push("/integracoes")}
              className={`${styles.button} ${styles.btnBack}`}
            >
              Voltar
            </button>
          </section>
        </div>
      </div>
    </main>
  );
};

export { TutorialWoommerce };
