import React from "react";
import { useHistory } from "react-router-dom";
import { BulletPoint, SlideCarousel } from "./components";
import "react-responsive-carousel/lib/styles/carousel.css"; // requires a loader

import optionsIntegrations from "../../../../../assets/images/integration/optionsIntegrations.png";
import nuvemshopCard from "../../../../../assets/images/nuvemshop/nuvemshopCard.png";
import activeOrderCreation from "../../../../../assets/images/active-order-create.png";
import manualImportCard from "../../../../../assets/images/manual-import-card.png";

import nuvemShop1 from "../../../../../assets/images/nuvemshop/nuvemshop1.png";
import nuvemShop2 from "../../../../../assets/images/nuvemshop/nuvemshop2.png";
import nuvemShop3 from "../../../../../assets/images/nuvemshop/nuvemshop3.png";
import nuvemShop4 from "../../../../../assets/images/nuvemshop/nuvemshop4.png";
import nuvemShop5 from "../../../../../assets/images/nuvemshop/nuvemshop5.png";

import nuvemShop6 from "../../../../../assets/images/nuvemshop/nuvemshop6.png";
import nuvemShop7 from "../../../../../assets/images/nuvemshop/nuvemshop7.png";
import nuvemShop8 from "../../../../../assets/images/nuvemshop/nuvemshop8.png";
import nuvemShop9 from "../../../../../assets/images/nuvemshop/nuvemshop9.png";

import nuvemShop15 from "../../../../../assets/images/nuvemshop/nuvemshop15.png";

import nuvemshopManualImport1 from "../../../../../assets/images/nuvemshop/nuvemshop-manual-import1.png";
import nuvemshopManualImport2 from "../../../../../assets/images/nuvemshop/nuvemshop-manual-import2.png";

import styles from "./styles.module.scss";

const TutorialNuvemShop = () => {
  const steps1th = [{ image: optionsIntegrations }, { image: nuvemshopCard }];

  const steps2th = [
    { image: nuvemShop1 },
    { image: nuvemShop2 },
    { image: nuvemShop3 },
    { image: nuvemShop4 },
    { image: nuvemShop5 }
  ];

  const steps3th = [
    { image: nuvemShop6 },
    { image: nuvemShop7 },
    { image: nuvemShop8 },
    { image: nuvemShop9 }
  ];

  const steps4th = [
    { image: manualImportCard },
    { image: nuvemshopManualImport1 },
    { image: nuvemshopManualImport2 }
  ];

  const history = useHistory();

  return (
    <main className={styles.container}>
      <div className={styles.content}>
        <header className={styles.contentHeader}>
          <p>Como fazer a integração?</p>
        </header>
        <div className={styles.contentBody}>
          <section className={styles.contentSection}>
            <h4>
              Passo a passo para conectar sua plataforma NuvemShop à BRCom
            </h4>

            <BulletPoint order="1">
              <p>
                Na tela de <b>Integrações</b>, clique em <b>Selecionar</b> do
                segundo card e depois clique em no card da plataforma NuvemShop:
              </p>
            </BulletPoint>

            <SlideCarousel steps={steps1th} />

            <BulletPoint order="2">
              <p>
                Após realizar seu login na plataforma, você será redirecionado
                para realizar a autorização da instalação do aplicativo da
                BRCom:
              </p>
            </BulletPoint>

            <SlideCarousel steps={steps2th} />

            <BulletPoint order="3">
              <p>
                Após a instalação, você será redirecionado para a página da
                BRCom, com suas configurações de frete já atualizadas.
              </p>
            </BulletPoint>

            <BulletPoint order="4">
              <p>
                Se desejar confirmar, na plataforma da NuvemShop, em{" "}
                <b>Configurações</b>, você verá que as opções de frete da BRCom
                já estarão disponíveis:
              </p>
            </BulletPoint>

            <SlideCarousel steps={steps3th} />

            <BulletPoint order="5">
              <p>
                Retornando à plataforma da BRCom, ao clicar em{" "}
                <b>Configurações</b> no card da plataforma NuvemShop, é possível
                realizar a configuração do remetente e seu endereço. Estas
                informações serão impressas nas etiquetas. - como default, a
                plataforma utiliza o nome de sua loja cadastrada.
              </p>
            </BulletPoint>

            <SlideCarousel steps={steps3th} />

            <BulletPoint order="6">
              <p>
                Abaixo estão as <b>Configurações Adicionais</b> caso deseja
                complementar sua integração em nossa plataforma.
              </p>
            </BulletPoint>

            <div className={`${styles.imgs} `}>
              <img
                src={nuvemShop15}
                alt="NuvemShop Card"
                className={styles.cardImage}
              />
            </div>

            <BulletPoint order="7">
              <p>
                A partir daí, assim que os pedidos realizados na sua loja
                NuvemShop utilizando as opções de frete da BRCom tiverem seus
                pagamentos efetuados, eles ficarão disponíveis para pagamento e
                posterior solicitação de pedido no menu <b>Meu Carrinho</b> da
                nossa plataforma.
              </p>
            </BulletPoint>

            <BulletPoint order="8">
              <p>
                Você também tem a opção de importar os pedidos manualmente.
              </p>
            </BulletPoint>

            <SlideCarousel steps={steps4th} />

            <BulletPoint order="9">
              <p>
                Caso queira desativar a criação de pedido nessa integração, vire a chave para "Desativado"
                <br></br>
                As demais funcionalidades relacionadas a essa integração continuarão ativas.
              </p>
            </BulletPoint>

            <div className={styles.imgs}>
              <img
                src={activeOrderCreation}
                alt="activeOrderCreation"
                className={styles.cardImage}
              />
            </div>

            <button
              onClick={() => history.push("/integracoes")}
              className={`${styles.button} ${styles.btnBack}`}
            >
              Voltar
            </button>
          </section>
        </div>
      </div>
    </main>
  );
};

export { TutorialNuvemShop };
