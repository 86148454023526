import React from "react";
import { useHistory } from "react-router-dom";
import { BulletPoint, SlideCarousel } from "./components";
import "react-responsive-carousel/lib/styles/carousel.css"; // requires a loader

import optionsIntegrations from "../../../../../assets/images/integration/optionsIntegrations.png";
import activeOrderCreation from "../../../../../assets/images/active-order-create.png";
import manualImportCard from "../../../../../assets/images/manual-import-card.png";

import vnda from "../../../../../assets/images/vnda/vndaCard.png";
import vnda1 from "../../../../../assets/images/vnda/vnda1.png";
import vnda2 from "../../../../../assets/images/vnda/vnda2.png";
import vnda3 from "../../../../../assets/images/vnda/vnda3.png";
import vnda4 from "../../../../../assets/images/vnda/vnda4.png";
import vnda5 from "../../../../../assets/images/vnda/vnda5.png";
import vnda6 from "../../../../../assets/images/vnda/vnda6.png";
import vnda7 from "../../../../../assets/images/vnda/vnda7.png";
import vnda8 from "../../../../../assets/images/vnda/vnda8.png";
import vnda9 from "../../../../../assets/images/vnda/vnda9.png";
import vnda10 from "../../../../../assets/images/vnda/vnda10.png";
import vnda11 from "../../../../../assets/images/vnda/vnda11.png";
import vnda12 from "../../../../../assets/images/vnda/vnda12.png";
import vnda13 from "../../../../../assets/images/vnda/vnda13.png";
import vnda14 from "../../../../../assets/images/vnda/vnda14.png";
import vnda15 from "../../../../../assets/images/vnda/vnda15.png";
import vnda16 from "../../../../../assets/images/vnda/vnda16.png";
import vnda18 from "../../../../../assets/images/vnda/vnda18.png";
import vnda19 from "../../../../../assets/images/vnda/vnda19.png";
import vnda20 from "../../../../../assets/images/vnda/vnda20.png";
import vnda21 from "../../../../../assets/images/vnda/vnda21.png";
import vnda22 from "../../../../../assets/images/vnda/vnda22.png";


import styles from "./styles.module.scss";

const TutorialVnda = () => {
  const steps1th = [{ image: optionsIntegrations }, { image: vnda }];

  const steps2th = [
    { image: vnda2 },
    { image: vnda3 },
    { image: vnda4 },
    { image: vnda5 },
    { image: vnda6 },
    { image: vnda7 }
  ];

  const steps3th = [{ image: vnda9 }, { image: vnda10 }, { image: vnda11 }];

  const steps4th = [
    { image: vnda2 },
    { image: vnda14 },
    { image: vnda15 },
    { image: vnda16 }
  ];

  const steps5th = [{ image: vnda19 }, { image: vnda20 }, { image: vnda21 }, { image: vnda22 }];

  const steps6th = [{ image: vnda18 }, { image: vnda20 }];

  // const steps6th = [{ image: manualImportCard }, { image: vndaManualImport1 }, { image: vndaManualImport2 }];

  const history = useHistory();

  return (
    <main className={styles.container}>
      <div className={styles.content}>
        <header className={styles.contentHeader}>
          <p>Como fazer a integração?</p>
        </header>
        <div className={styles.contentBody}>
          <section className={styles.contentSection}>
            <h4>Passo a passo para conectar sua plataforma VNDA à BRCom</h4>

            <BulletPoint order="1">
              <p>
                Na tela de <b>Integrações</b>, clique em <b>Selecionar</b> do
                segundo card e depois clique em <b>Configurações</b> no card da
                plataforma VNDA
              </p>
            </BulletPoint>

            <SlideCarousel steps={steps1th} />

            <BulletPoint order="2">
              <p>
                Você será redirecionado para a tela de Configurações onde serão
                necessárias algumas chaves de aplicação do usuário VNDA.
              </p>
            </BulletPoint>

            <div className={styles.imgs}>
              <img
                src={vnda1}
                alt="Imagem tutorial vnda nº 1"
                className={styles.cardImage}
              />
            </div>

            <BulletPoint order="3">
              <p>
                Para obter essas chaves de aplicação, acesse seu painel{" "}
                <b>Admin da VNDA</b> e siga os seguintes passos:
              </p>
            </BulletPoint>

            <SlideCarousel steps={steps2th} />

            <BulletPoint order="4">
              <p>
                Acesse o email informado na criação do token e copie o token que foi gerado
              </p>
            </BulletPoint>

            <div className={styles.imgs}>
              <img
                src={vnda8}
                alt="Imagem tutorial vnda nº 8"
                className={styles.cardImage}
              />
            </div>

            <BulletPoint order="5">
              <p>
                Copie essa URL usando seu token gerado: {process.env.REACT_APP_API_HOST}/vnda/webhook/order/create?token=<b>seutoken</b>
                <br></br>
                Volte no seu painel <b>Admin da VNDA</b> para criar o webhook
              </p>
            </BulletPoint>

            <SlideCarousel steps={steps4th} />

            <BulletPoint order="6">
              <p>Preencha os campos de acordo com as informações exibidas:</p>
            </BulletPoint>

            <SlideCarousel steps={steps3th} />

            <BulletPoint order="7">
              <p>
                Em seguida, insira as informações de{" "}
                <b>endereço do remetente</b>. Começe pelo CEP para preencher
                automaticamente as informações do endereço.
              </p>
            </BulletPoint>

            <div className={styles.imgs}>
              <img
                src={vnda12}
                alt="Imagem tutorial vnda nº 12"
                className={styles.cardImage}
              />
            </div>

            <BulletPoint order="8">
              <p>
                Configure se deseja adicionar seguro aos envios, importar pedidos com fretes que não sejam Via BRCom e dias adicionais para manipulação.
                <br></br>
                Estando tudo certo clique em <b>Salvar</b>
              </p>
            </BulletPoint>

            <div className={styles.imgs}>
              <img
                src={vnda13}
                alt="Imagem tutorial vnda nº 13"
                className={styles.cardImage}
              />
            </div>

            <div className={styles.bulletpoint}>
              <span>9</span>
              <p>
                Nos próximos passos vamos gerar as planilhas de acordo com as
                opções de <b>frete</b> desejadas.
                É só ir no menu <b>Integrações</b> e depois no sub menu <b>Tabelas</b>.
                <br></br>
                Para gerar uma planilha clique no botão <b>Criar nova tabela</b> e preencha os campos e clique em <b>Gerar tabela</b>.
                <br></br>
                OBS: Para que tudo ocorra bem, aguarde o download de uma planilha ficar disponível para gerar a próxima
              </p>
            </div>

            <SlideCarousel steps={steps5th} />

            <div className={styles.bulletpoint}>
              <span>10</span>
              <p>
                Nós avisaremos por email quando estiver pronta para você baixá-las.
              </p>
            </div>

            <SlideCarousel steps={steps6th} />

            <div className={styles.bulletpoint}>
              <span>11</span>
              <p>
                Com as planilhas em mãos, envie um email para <b>ajuda@vnda.com.br</b> anexando as planilhas de frete com as seguintes informações:
                <br></br>
                <br></br>
                Assunto: Solicitação de upload de planilha
                <br></br>
                <br></br>
                Host: <b>URL da sua loja</b>
                <br></br>
                <br></br>
                Planilha Sedex
                <br></br>
                Nome: Expresso (Via BRCom)
                <br></br>
                <br></br>
                Planilha PAC
                <br></br>
                Nome: Econômico (Via BRCom)
                <br></br>
                <br></br>
                Planilha PACMINI,
                <br></br>
                Nome: Super Econômico (Via BRCom)
                <br></br>
                <br></br>
                Planilha Super Expressa,
                <br></br>
                Nome: Super Expresso (Via BRCom)
              </p>
            </div>

            <div className={styles.bulletpoint}>
              <span>12</span>
              <p>
                Pronto! Aguarde o retorno da VNDA e se tudo deu certo agora você pode aproveitar nossas opções de <b>frete</b>.
              </p>
            </div>

            <div className={styles.bulletpoint}>
              <span>13</span>
              <p>
                Você também tem a opção de importar manualmente seus pedidos.
              </p>
            </div>

            <SlideCarousel steps={steps6th} />

            <div className={styles.bulletpoint}>
              <span>14</span>
              <p>
                Caso queira desativar a criação de pedido nessa integração, vire a chave para "Desativado"
                <br></br>
                As demais funcionalidades relacionadas a essa integração continuarão ativas.
              </p>
            </div>

            <div className={styles.imgs}>
              <img
                src={activeOrderCreation}
                alt="activeOrderCreation"
                className={styles.cardImage}
              />
            </div>

            <button
              onClick={() => history.push("/integracoes")}
              className={`${styles.button} ${styles.btnBack}`}
            >
              Voltar
            </button>
          </section>
        </div>
      </div>
    </main>
  );
};

export { TutorialVnda };
