const invoiceStatus = {
  APROVADO: {
    id: 1,
    message: "Aprovado"
  },
  NEGADO: {
    id: 2,
    message: "Negado"
  },
  EM_PROCESSAMENTO: {
    id: 3,
    message: "Em Processamento"
  },
  CANCELADO: {
    id: 4,
    message: "Cancelado"
  },
  AGUARDANDO_FATURAMENTO: {
    id: 5,
    message: "Aguardando Faturamento"
  },
  AGUARDANDO_PAGAMENTO: {
    id: 6,
    message: "Aguardando Pagamento"
  },
  VENCIDA: {
    id: 7,
    message: "Vencida"
  },
  CONTESTADA: {
    id: 8,
    message: "Contestada"
  },
  PERDIDO: {
    id: 9,
    message: "Perdido"
  }
};

export default Object.freeze({
  ...invoiceStatus
});
