import React, { useRef } from 'react';
import { Autocomplete, useLoadScript } from '@react-google-maps/api';

const libraries = ['places']; // Necessário para o Autocomplete

const AutocompleteInput = ({
    placeholder,
    name,
    value,
    handleChange,
    setState
}) => {

    const autocompleteRef = useRef(null);

    const handlePlaceChanged = () => {
        if (autocompleteRef.current) {
            const place = autocompleteRef.current.getPlace();
            if (place.geometry) {

                const latLong = {
                    lat: place.geometry.location.lat(),
                    lng: place.geometry.location.lng(),
                };

                setState({
                    value: place.formatted_address,
                    latLong: latLong,
                    isOK: "ok",
                    error: '',
                });
            } else {
                setState({
                    isOK: "notOk",
                    error: 'Localização inválida',
                });
            }
        }
    };

    const { isLoaded } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
        libraries,
    });

    return isLoaded && (
        <Autocomplete
            onLoad={(autocomplete) => (autocompleteRef.current = autocomplete)}
            onPlaceChanged={handlePlaceChanged}
            options={{ componentRestrictions: { country: 'br' } }}
        >
            <input
                type="text"
                placeholder={placeholder}
                value={value}
                onChange={(e) => handleChange(e, setState)}
                style={{ width: '100%' }}
                name={name}
            />
        </Autocomplete>
    )
};

export default AutocompleteInput;