import React from "react";
import { useHistory } from "react-router-dom";
import { SlideCarousel } from "./components";
import "react-responsive-carousel/lib/styles/carousel.css"; // requires a loader

import optionsIntegrations from "../../../../../assets/images/integration/optionsIntegrations.png";
import activeOrderCreation from "../../../../../assets/images/active-order-create.png";
import manualImportCard from "../../../../../assets/images/manual-import-card.png";


import traycorpCard from "../../../../../assets/images/traycorp/traycorpCard.png";
import traycorp1 from "../../../../../assets/images/traycorp/traycorp1.png";
import traycorp2 from "../../../../../assets/images/traycorp/traycorp2.png";
import traycorp3 from "../../../../../assets/images/traycorp/traycorp3.png";
import traycorp4 from "../../../../../assets/images/traycorp/traycorp4.png";
import traycorp5 from "../../../../../assets/images/traycorp/traycorp5.png";
import traycorp6 from "../../../../../assets/images/traycorp/traycorp6.png";
import traycorp7 from "../../../../../assets/images/traycorp/traycorp7.png";
import traycorp8 from "../../../../../assets/images/traycorp/traycorp8.png";
import traycorp9 from "../../../../../assets/images/traycorp/traycorp9.png";
import traycorp10 from "../../../../../assets/images/traycorp/traycorp10.png";
import traycorp11 from "../../../../../assets/images/traycorp/traycorp11.png";
import traycorp12 from "../../../../../assets/images/traycorp/traycorp12.png";
import traycorp13 from "../../../../../assets/images/traycorp/traycorp13.png";
import traycorp14 from "../../../../../assets/images/traycorp/traycorp14.png";
import traycorp15 from "../../../../../assets/images/traycorp/traycorp15.png";
import traycorp16 from "../../../../../assets/images/traycorp/traycorp16.png";
import traycorp17 from "../../../../../assets/images/traycorp/traycorp17.png";

import traycorpManualImport1 from "../../../../../assets/images/traycorp/traycorp-manual-import1.png";
import traycorpManualImport2 from "../../../../../assets/images/traycorp/traycorp-manual-import2.png";

import styles from "./styles.module.scss";

const TutorialWakecomerce = () => {
  const history = useHistory();

  const steps1th = [
    { image: traycorp1 },
    { image: traycorp2 },
    { image: traycorp3 },
    { image: traycorp4 },
    { image: traycorp5 },
    { image: traycorp6 }
  ];

  const steps2th = [
    { image: traycorp7 },
    { image: traycorp8 },
    { image: traycorp9 },
    { image: traycorp10 }
  ];

  const steps3th = [{ image: optionsIntegrations }, { image: traycorpCard }];

  const steps4th = [
    { image: traycorp15 },
    { image: traycorp16 },
    { image: traycorp17 }
  ];

  const steps5th = [
    { image: manualImportCard },
    { image: traycorpManualImport1 },
    { image: traycorpManualImport2 }
  ];

  return (
    <main className={styles.container}>
      <div className={styles.content}>
        <header className={styles.contentHeader}>
          <p>Como fazer a integração?</p>
        </header>
        <div className={styles.contentBody}>
          <section className={styles.contentSection}>
            <h4>
              Passo a passo para conectar sua plataforma Wakecommerce à BRCom
            </h4>

            <div className={styles.bulletpoint}>
              <span>1</span>
              <p>
                No painel <b>Admin</b> da Wakecommerce, crie o <b>Token</b> para
                integração.
              </p>
            </div>

            <SlideCarousel steps={steps1th} />

            <div className={styles.bulletpoint}>
              <span>2</span>
              <p>
                Ainda no painel <b>Admin</b> da Wakecommerce, vamos criar o{" "}
                <b>Frete Custom</b> para sua loja.
              </p>
            </div>

            <SlideCarousel steps={steps2th} />

            <div className={styles.bulletpoint}>
              <span>3</span>
              <p>Siga os passos abaixo para configurá-lo corretamente:</p>
            </div>

            <div className={styles.subBulletpoint}>
              <span>A</span>

              <p>
                Cole o <b>Token</b> no campo indicado.
              </p>
            </div>

            <div className={styles.subBulletpoint}>
              <span>B</span>

              <p>
                Selecione a opção <b>Token</b> e clique em <b>Adicionar</b>.
              </p>
            </div>

            <div className={styles.subBulletpoint}>
              <span>C</span>

              <p>
                No campo indicado, cole essa URL:{" "}
                <b>{process.env.REACT_APP_API_HOST}/traycorp/rates</b>
              </p>
            </div>

            <div className={styles.subBulletpoint}>
              <span>D</span>

              <p>
                Copie novamente o <b>Token</b> para ser usado no próximo passo,{" "}
                <b>Ative</b> a chave e clique em <b>Salvar</b>.
              </p>
            </div>

            <div className={styles.imgs}>
              <img
                src={traycorp11}
                alt="Wakecommerce"
                className={styles.cardImage}
              />
            </div>

            <div className={styles.bulletpoint}>
              <span>4</span>
              <p>
                Agora na BRComerce, na tela de <b>Integrações</b>, clique em{" "}
                <b>Selecionar</b> do segundo card e depois clique em{" "}
                <b>Configurações</b> no card da plataforma Wakecommerce.
              </p>
            </div>

            <SlideCarousel steps={steps3th} />

            <div className={styles.bulletpoint}>
              <span>5</span>
              <p>
                Preencha com o <b>Token</b> gerado no painel <b>Admin</b> da
                Wakecommerce, que você copiou no passo anterior.
              </p>
            </div>

            <div className={styles.imgs}>
              <img
                src={traycorp12}
                alt="Wakecommerce"
                className={styles.cardImage}
              />
            </div>

            <div className={styles.bulletpoint}>
              <span>6</span>
              <p>
                Se caso você deseje filtar os pedidos pelo centro de
                distribuição é só ativar/desativar e depois informar o id no
                campo. <br /> Cada usuário da BRCOM deve ser associado a um{" "}
                <b>único</b> centro de distribuição. No caso de um cliente
                possuir <b>múltiplos</b> centros de distribuição, deve-se criar{" "}
                <b>uma conta distinta</b> para cada centro de distribuição,
                utilizando o mesmo token, com a distinção sendo feita através do
                número do centro de distribuição.
              </p>
            </div>

            <SlideCarousel steps={steps4th} />

            <div className={styles.bulletpoint}>
              <span>7</span>
              <p>
                Preencha com os <b>dados do remetente</b>. Comece pelo CEP para
                preencher automaticamente as informações do endereço.
              </p>
            </div>

            <div className={styles.imgs}>
              <img
                src={traycorp13}
                alt="Wakecommerce"
                className={styles.cardImage}
              />
            </div>

            <div className={styles.bulletpoint}>
              <span>8</span>
              <p>
                Configure se quer <b>Seguro</b>, qual será o{" "}
                <b>Status do pedido</b>, <b>Configuração de Frete</b> e os{" "}
                <b>Dias adicionais</b> de manipulação dos pedidos. Essas
                configurações serão aplicadas em todos os pedidos cotados pela
                BRCom.
              </p>
            </div>

            <div className={styles.imgs}>
              <img
                src={traycorp14}
                alt="Wakecommerce"
                className={styles.cardImage}
              />
            </div>

            <div className={styles.bulletpoint}>
              <span>9</span>
              <p>
                Agora é só <b>Salvar</b> a integração e aproveitar nossas opções
                de frete. <br /> <b>Atenção !</b> A Wakecommerce tem um processo interno
                que pode levar entre <b>15 minutos até 6 horas</b> para
                finalizar. Boas vendas!
              </p>
            </div>

            <div className={styles.bulletpoint}>
              <span>11</span>
              <p>
                Você também tem a opção de importar os pedidos manualmente.
              </p>
            </div>

            <SlideCarousel steps={steps5th} />

            <div className={styles.bulletpoint}>
              <span>10</span>
              <p>
                Caso queira desativar a criação de pedido nessa integração, vire a chave para "Desativado".
                <br></br>
                As demais funcionalidades relacionadas a essa integração continuarão ativas.
              </p>
            </div>

            <div className={styles.imgs}>
              <img
                src={activeOrderCreation}
                alt="activeOrderCreation"
                className={styles.cardImage}
              />
            </div>

            <button
              onClick={() => history.push("/integracoes")}
              className={`${styles.button} ${styles.btnBack}`}
            >
              Voltar
            </button>
          </section>
        </div>
      </div>
    </main>
  );
};

export { TutorialWakecomerce };
