import React from "react";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { BiMailSend } from "react-icons/bi";
import { FiChevronRight, FiChevronLeft } from "react-icons/fi";
import { MdErrorOutline } from "react-icons/md";
import { VscError } from "react-icons/vsc";
import Modal from "react-modal";
import styles from "./styles.module.scss";

export const StatusModal = props => {
  const {
    isOpen,
    text,
    confirmButtonText,
    onConfirm,
    type,
    onCancel,
    cancelButtonText
  } = props;
  return (
    <Modal
      className={styles.modal}
      isOpen={isOpen}
      preventScroll
      onRequestClose={onConfirm}
    >
      <div className={styles.wrapper}>
        <div className={styles.container}>
          <div className={styles.icon}>
            {type === "email" ? (
              <BiMailSend />
            ) : type === "error" ? (
              <VscError />
            ) : type === "success" ? (
              <AiOutlineCheckCircle />
            ) : (
              <MdErrorOutline />
            )}
          </div>
          <div
            className={styles.text}
            dangerouslySetInnerHTML={{ __html: text }}
          ></div>
          <div className={styles.btnContainer}>
            {onCancel && cancelButtonText && (
              <button
                type="button"
                className={styles.btnCancel}
                onClick={onCancel}
              >
                <FiChevronLeft />
                <span>{cancelButtonText}</span>
              </button>
            )}
            <button type="button" className={styles.btnOk} onClick={onConfirm}>
              <span>{confirmButtonText}</span>
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

if (typeof window !== "undefined") {
  Modal.setAppElement("body");
}
