import React, { useCallback, useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import ReactModal from "react-modal";

import { BiBarcodeReader } from "react-icons/bi";
import { FaPlusCircle } from "react-icons/fa";
import { PiListNumbersFill } from "react-icons/pi";

import { BrComerceApiService } from "../../../../../services/BrComerceApiService";

import useGetWindowWidth from "../../../../../utils/useGetWindowWidth";

import InputSelectAsync from "../../../../../components/InputSelectAsync";
import { Loading } from "../../../../../components/Loading";
import AutocompleteInput from "../../../components/AutoCompletInput";
import Container from "../../../components/Container";
import { PackageCard } from "../../../components/PackageCard";

import barCodeScanner from "../../../../../assets/images/Barcode-amico.svg";

import Swal from "sweetalert2";

import styles from "./styles.module.scss";


const stylesModal22 = {
    overlay: {
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "rgba(51, 51, 51, 0.75)",
        zIndex: 999
    },
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        transform: "translate(-50%, -50%)",
        zIndex: 999,
        border: "none",
        backgroundColor: "transparent",
        padding: 0,
        overflow: "visible"
    }
};

const AddRoutes = () => {

    const inputRef = useRef(null);
    const bufferRef = useRef('');

    const history = useHistory();

    const api = new BrComerceApiService();
    const widthWindows = useGetWindowWidth();

    const [loading, setLoading] = useState(false);
    const [loadingSro, setLoadingSro] = useState(false);
    const [searchDriver, setSearchDriver] = useState("");

    const [startData, setStartData] = useState({
        value: "",
        error: "",
        latLong: { lat: "", lng: "" },
        isOK: "vazio",
    });

    const [endData, setEndData] = useState({
        value: "",
        error: "",
        latLong: { lat: "", lng: "" },
        isOK: "vazio",
    });

    const handleChange = (event, setState) => {
        const { name, value } = event.target;
        setState((prevData) => ({
            ...prevData,
            [name]: value,
            error: value?.length < 3 || !value ? 'Campo obrigatório' : "",
            isOK: value?.length < 3 || !value ? 'notOk' : "vazio"
        }));
    };

    const [packages, setPackages] = useState([]);
    const [digitCodeModal, setDigitCodeModal] = useState(false);
    const [addPackageModal, setAddPackageModal] = useState(false);
    const [scannerModal, setScannerModal] = useState(false);

    const [shippingCodeData, setShippingCodeData] = useState({
        code: "",
        error: "",
        isOK: "vazio",
    })

    const [sroOk, setSroOk] = useState("vazio");

    const [packagesExpand, setPackagesExpand] = useState([]);

    const getDistance = useCallback(async (lat, lng, sro) => {
        let distanceNumber = 0;
        const service = new window.google.maps.DistanceMatrixService();

        const request = {
            origins: [{ lat: startData?.latLong?.lat, lng: startData?.latLong?.lng }],
            destinations: [{ lat, lng }],
            travelMode: 'DRIVING',
            unitSystem: window.google.maps.UnitSystem.METRIC,
        };

        await service.getDistanceMatrix(request, (response, status) => {
            if (status === 'OK') {
                const distanceText = response?.rows[0]?.elements[0]?.distance?.text;
                distanceNumber = distanceText?.split(" km")[0];

                if(distanceNumber.includes("m")){
                    distanceNumber = distanceNumber?.split(" m")[0];
                    console.log("🚀 ~ awaitservice.getDistanceMatrix ~ distanceNumber 121:", distanceNumber)
                }

            } else {
                console.error(`Error: ${status}`);

                Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: `Ocorreu um erro ao buscare a ditância do envio: ${sro}`
                });
            }
        });
        return distanceNumber;
    }, [startData.latLong.lat, startData.latLong.lng])

    const handleInputCode = useCallback(event => {
        let { name, value } = event.target;
        value = value?.trim();

        setShippingCodeData((prevData) => ({
            ...prevData,
            [name]: value,
            error: value?.length < 1 || !value ? 'Campo obrigatório' : "",
            isOK: value?.length < 1 || !value ? 'notOk' : "vazio"
        }));
    }, []);

    const searchShippingSro = useCallback(async () => {
        setLoading(true);

        if (!shippingCodeData.code) {
            setShippingCodeData((prevData) => ({
                ...prevData,
                error: "Código de rastreio é obrigatório",
                isOK: 'notOk'
            }));
            setLoading(false);
            setSroOk("vazio")
            return;
        }

        const shippingAlreadyExists = packages?.some((p) => p.sro?.toLowerCase() === shippingCodeData.code?.toLowerCase())

        if (shippingCodeData.error === "notOk") {
            return;
        }
        
        if (shippingAlreadyExists) {
            setShippingCodeData((prevData) => ({
                ...prevData,
                error: "Etiqueta já utilizada",
                isOK: 'notOk'
            }));
            setLoading(false);
            setLoadingSro(false);
            return;
        }

        await api
            .makeHttpRequest({
                method: "GET",
                url: `/route-shipping-admin/bySro/${shippingCodeData.code}`,
            })
            .then(async (response) => {
                const destination = response?.destination?.location;
                const distance = await getDistance(destination?.lat, destination?.lng, shippingCodeData.code);

                response = {
                    ...response,
                    distance
                };

                setPackages((prevState) => ([...prevState, response]));
                setShippingCodeData((prevData) => ({
                    ...prevData,
                    code: "",
                }));
                setDigitCodeModal(false);
                setScannerModal(false);
                setSroOk("vazio")
            })
            .catch(err => {
                const message = err?.response?.data?.message ?? "Falha ao buscar pacote";
                setShippingCodeData((prevData) => ({
                    ...prevData,
                    error: message,
                    isOK: 'notOk'
                }));
            })
            .finally(() => {
                setLoading(false);
                setLoadingSro(false);
            });
    }, [shippingCodeData.code]);

    useEffect(() => {
        const handleKeyPress = async (event) => {
            if (scannerModal) {
                const inputElement = inputRef.current;
                if (event.key === 'Enter') {
                    if (inputElement && bufferRef.current) {
                        setShippingCodeData((prevData) => ({
                            ...prevData,
                            code: bufferRef.current,
                            error: bufferRef.current?.length < 1 || !bufferRef.current ? 'Campo obrigatório' : "",
                            isOK: bufferRef.current?.length < 1 || !bufferRef.current ? 'notOk' : "vazio"
                        }));
                        setLoadingSro(true);
                        setSroOk("ok")
                        bufferRef.current = '';  // Limpa o buffer
                    }
                } else {
                    bufferRef.current += event.key;
                }
            }
        };

        window.addEventListener('keypress', handleKeyPress);

        // Cleanup ao fechar o modal
        return async () => {
            window.removeEventListener('keypress', handleKeyPress);
        };
    }, [scannerModal]);

    useEffect(() => {
        window.setPageTitle("Adicionar Rota");
        if (loadingSro) {
            searchShippingSro()
        }
    }, [loadingSro])


    const createNewRoute = async () => {
        setLoading(true);

        const originDestination = {
            origin: {
                latitude: startData.latLong.lat,
                longitude: startData.latLong.lng,
                address: startData.value
            },
            destination: {
                latitude: endData.latLong.lat,
                longitude: endData.latLong.lng,
                address: endData.value
            }
        }

        const shippings = packages.map((p) => {
            return {
                id_shipping: p.id,
                distance: Number(p.distance?.replace(",", ".") ?? 1)
            }
        })

        const body = {
            idDriver: searchDriver?.value,
            type: "delivery",
            originDestination,
            shippings
        }
        console.log("🚀 ~ createNewRoute ~ body:", body)

        await api
            .makeHttpRequest({
                method: "POST",
                url: `/route-shipping-admin`,
                data: body
            })
            .then(() => {
                Swal.fire({
                    icon: "success",
                    title: "Sucesso",
                    text: "Rota criado com sucesso!",
                    showCancelButton: false,
                    confirmButtonText: "Ok"
                }).then(() => {
                    history.push("/listagem-rotas");
                });
            })
            .catch(err => {
                console.log("🚀 ~ 207 createNewRoute ~ err:", err)
                Swal.fire({
                    icon: "error",
                    title: "Erro",
                    text: "Não foi possível criar a rota."
                });
            })
            .finally(() => {
                setLoading(false);
            });
    }

    const handlePackageExpand = useCallback(
        pack => {
            const find = packagesExpand.find(p => p.id === pack.id);
            if (find) {
                const newPacks = packagesExpand.filter(p => p.id !== pack.id);
                setPackagesExpand([...newPacks]);
            } else {
                packagesExpand.push(pack);
                setPackagesExpand([...packagesExpand]);
            }
        },
        [packagesExpand]
    );

    const handleRemoveShipping = useCallback(
        shippingId => {
            setPackages(prevPackages =>
                prevPackages.filter(pack => pack.id !== shippingId)
            );
        },
        [packages]
    );

    const barcodeImage = "iVBORw0KGgoAAAANSUhEUgAAAKYAAAA0CAYAAAAAJkM/AAAAHnRFWHRTb2Z0d2FyZQBid2lwLWpzLm1ldGFmbG9vci5jb21Tnbi0AAABSklEQVR4nO3SUQrDIBQEQO9/6RYKBdm4av4nUEiyrr7QGZ/FNcb4/f738/vT2vk5e7nuptPWrGbMdfPz7ntW6/KM00y7b25z7e7f7HM7Q2Y3Z93Me/MNu/9rdS3fgvk84zQTmGDWGcEEs64F87w3mGCCudkXTDDBBBPM1s0MTDDBBBPM1s0MTDDBBBPM1s0MTDDBBBPM1s0MTDDBBBPM1s0MTDDBBBPM1s0MTDDBBBPM1s0MTDDBBBPM1s0MTDDBBBPM1s0MTDDBBBPM1s0MTDDBBBPM1s0MTDDBBBPM1s0MTDDBBBPM1s0MTDDBBBPM1s0MTDDBBBPM1s0MTDDBBBPM1s0MTDDBBBPM1s0MTDDBBBPM1s0MTDDBBBPM1s0MTDDBBBPM1s0MTDDBBBPM1s0MTDDBBBPM1s0MTDDBBBPM1s0MTDDBfAnzC/ev83FTRdhTAAAAAElFTkSuQmCC";

    return (
        <>
            <Loading loading={loading} />
            <ReactModal
                isOpen={scannerModal}
                shouldCloseOnOverlayClick={true}
                onRequestClose={() => {
                    setScannerModal(false)
                    setShippingCodeData(() => ({
                        error: "",
                        isOK: 'vazio',
                        code: ""
                    }));
                    setSroOk("vazio")
                }}
                style={stylesModal22}
            >
                <div className={styles.modalBody2}>
                    <div>
                        <h6>Escanear etiqueta</h6>
                        <p>Por favor, mantenha o bip continuamente apontado para a etiqueta</p>
                        <div className={styles.imgBarcode} role={sroOk}>
                            <img
                                src={barCodeScanner}
                                alt="barcode scanner"
                            />
                        </div>
                    </div>
                    <div className={styles.input} role={sroOk}>
                        <input
                            type="text"
                            name={"code"}
                            ref={inputRef}
                        />
                        {shippingCodeData.code && (
                            <div className={styles.tracking}>
                                <img
                                    src={`data:image/jpeg;base64,${barcodeImage}`}
                                    alt="Código de Barras"
                                />
                                <span>{shippingCodeData.code}</span>
                            </div>
                        )}
                    </div>
                    {shippingCodeData.isOK === "notOk" && (
                        <p className={styles.error}>{shippingCodeData.error}</p>
                    )}
                </div>
            </ReactModal>

            <ReactModal
                isOpen={digitCodeModal}
                shouldCloseOnOverlayClick={true}
                onRequestClose={() => {
                    setDigitCodeModal(false);
                    setShippingCodeData((prevData) => ({
                        ...prevData,
                        error: "",
                        isOK: 'vazio',
                        code: ""
                    }));
                }}
                style={stylesModal22}
            >
                <div className={styles.modalBody}>
                    <h6>Digitar código manualmente</h6>
                    <span>
                        Preencha o campo abaixo com o código <br />
                        do pacote que deseja adicionar
                    </span>
                    <div className={styles.input}>
                        <input
                            placeholder="Digite o código aqui"
                            type="text"
                            onChange={handleInputCode}
                            value={shippingCodeData.code}
                            name={"code"}
                        />
                    </div>
                    {shippingCodeData.isOK === "notOk" && (
                        <p className={styles.error}>{shippingCodeData.error}</p>
                    )}

                    <div className={styles.buttonContainerModal}>
                        <button
                            onClick={() => {
                                setDigitCodeModal(false);
                                setShippingCodeData((prevData) => ({
                                    ...prevData,
                                    error: "",
                                    isOK: 'vazio',
                                    code: ""
                                }));
                            }}
                        >
                            Voltar
                        </button>
                        <button onClick={searchShippingSro}>Confirmar</button>
                    </div>
                </div>
            </ReactModal>

            <ReactModal
                isOpen={addPackageModal}
                shouldCloseOnOverlayClick={true}
                onRequestClose={() => setAddPackageModal(false)}
                style={stylesModal22}
            >
                <div className={styles.modalBody}>
                    <h6>Adicionar pacotes</h6>
                    <span>Selecione uma das opções abaixo para prosseguir:</span>
                    <div className={styles.buttonContainerAddPackageModal}>
                        <button
                            className={styles.scanner}
                            onClick={() => {
                                setAddPackageModal(false);
                                setScannerModal(true);
                            }}
                        >
                            <BiBarcodeReader color="#fff" size={24} />
                            Escanear a etiqueta do pacote
                        </button>
                    </div>
                    <div className={styles.buttonContainerAddPackageModal}>
                        <button
                            className={styles.digitCode}
                            onClick={() => {
                                setAddPackageModal(false);
                                setDigitCodeModal(true);
                            }}
                        >
                            <PiListNumbersFill color="#fff" size={20} />
                            Digitar código do pacote manualmente
                        </button>
                    </div>
                </div>
            </ReactModal>
            <Container title="Criar nova rota">
                <p className={styles.title}>Preencha os campos abaixo com as infomações da rota que deseja criar:</p>
                <Row className={styles.row}>
                    <Col className={styles.inputDriver}>
                        <InputSelectAsync
                            search={searchDriver}
                            setSearch={setSearchDriver}
                            listUserOrDriver={"driver"}
                            applyingBorders={true}
                        />
                        {searchDriver == null && <p className={styles.error}>{"Campo Obrigatório"}</p>}
                    </Col>
                </Row>
                <Row className={styles.row}>
                    <Col>
                        <p className={styles.textAddress}>Dados de enredereço</p>
                    </Col>
                </Row>
                <Row className={`${styles.row} ${styles.inputAddress}`}>
                    <Col>
                        <div className={styles.input} role={startData.isOK}>
                            <AutocompleteInput
                                placeholder={"Digite o endereço de início"}
                                name={"value"}
                                value={startData.value}
                                handleChange={handleChange}
                                setState={setStartData}
                            />
                        </div>
                        {startData.error && <p className={styles.error}>{startData.error}</p>}
                    </Col>
                    <Col>
                        <div className={styles.input} role={endData.isOK} >
                            <AutocompleteInput
                                placeholder={"Digite o endereço final"}
                                name={"value"}
                                value={endData.value}
                                handleChange={handleChange}
                                setState={setEndData}
                            />
                        </div>
                        {endData.error && <p className={styles.error}>{endData.error}</p>}
                    </Col>
                </Row>
                <Row className={styles.row}>
                    <Col>
                        <p className={styles.textPackage}>{`Pacotes ${!!packages?.length ? `(${String(packages?.length).padStart(2, '0')})` : ""}`}</p>
                    </Col>
                </Row>
                <div className={styles.actionsButton}>
                    <button
                        type="button"
                        onClick={() => setAddPackageModal(true)}
                        className={`${styles.package}`}
                        disabled={
                            !searchDriver ||
                            startData.isOK !== "ok" ||
                            endData.isOK !== "ok"
                        }
                    >
                        <FaPlusCircle size={16} color="#FFFFFF" />
                        <span>Adicionar pacotes</span>
                    </button>
                </div>
                {!!packages?.length &&
                    <div className={styles.packageContainer}>
                        <div className={styles.packagesWrapper}>
                            {packages?.map(pack => (
                                <PackageCard
                                    widthWindows={widthWindows}
                                    key={pack.id}
                                    shipping={pack}
                                    isExpand={packagesExpand.find(
                                        packExpand => pack.id === packExpand.id
                                    )}
                                    onExpand={() => handlePackageExpand(pack)}
                                    onDelete={() => handleRemoveShipping(pack.id)}
                                />
                            ))}
                        </div>
                    </div>
                }

                <div className={styles.buttonsContainer}>
                    <button
                        type="button"
                        onClick={() => createNewRoute()}
                        className={`${styles.create}`}
                        disabled={
                            !searchDriver ||
                            startData.isOK !== "ok" ||
                            endData.isOK !== "ok" ||
                            !packages?.length
                        }
                    >
                        <span>Salvar</span>
                    </button>
                </div>
            </Container>
        </>
    );
};

export { AddRoutes };

