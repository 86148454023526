import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import Header from "../../components/Header";
import store from "../../store/store";
import BannerPrincipal from "./BannerPrincipal";

const Home = () => {
  const state = store.getState();
  const { authorized } = state.auth;
  const history = useHistory();

  useEffect(() => {
    if (authorized) {
      history.push("/nova-coleta/normal-send-form");
    }
  }, []);

  return (
    <>
      <Header />
      <BannerPrincipal />
    </>
  );
};

export default Home;
