export const ShippingImportStatusEnum = {
  IN_PROGRESS: {
    label: "Em andamento",
    value: "inProgress"
  },
  DONE: {
    label: "Concluída",
    value: "done"
  },
};
