import React, { useState } from "react";

import { ModalRenderTable } from "../ModalRenderTable";

import { Modal } from "react-bootstrap";
import InfiniteScroll from "react-infinite-scroll-component";
import Swal from "sweetalert2";
import DatePicker from "../../../../components/Input/DatePicker";
import LoadInfiniteScroll from "../../../../components/LoadInfiniteScroll";
import { BrComerceApiService } from "../../../../services/BrComerceApiService";
import styles from "./styles.module.scss";

export const ModalOrdersList = ({
  setPeriodInitialDate,
  setPeriodFinalDate,
  setShowModal,
  setImportedOrders,
  setActualPageOrders,
  setNPagesOrders,
  setOrdersToImport,
  importedOrders,
  ordersToImport,
  actualPageOrders,
  nPagesOrders,
  setLoading,
  periodInitialDate,
  periodFinalDate,
  store,
  hasMoreBling = false,
  setHasMoreBling = () => {}
}) => {
  const api = new BrComerceApiService();

  const handleOnChangeCalendarPeriod = e => {
    if (e.dateEnd) {
      setPeriodInitialDate(e.dateStart);
      setPeriodFinalDate(e.dateEnd);
      setOrdersToImport([]);
    }
  };

  const handleClearPeriodFilter = () => {
    setPeriodInitialDate("");
    setPeriodFinalDate("");
  };

  const handleOrderImport = async () => {
    try {
      setLoading(true);

      await api.makeHttpRequest({
        method: "POST",
        url: `/${store}/orders/all`,
        data: {
          orders: ordersToImport
        }
      });

      Swal.fire({
        title: "Sucesso!",
        text: "Os pedidos serão importados em instantes!",
        icon: "success",
        showCancelButton: false,
        confirmButtonText: "Ok"
      });

      setShowModal(false);
      handleClearPeriodFilter();
    } catch (e) {
      Swal.fire({
        title: "Pedidos",
        text: "Erro ao importar pedido(s).",
        icon: "error"
      });
    } finally {
      setLoading(false);
    }
  };

  const handleLoadMoreOrders = async () => {
    if (
      (store === "bling" && !hasMoreBling) ||
      (store !== "bling" && actualPageOrders === nPagesOrders)
    ) {
      return;
    }

    const response = await api.makeHttpRequest({
      method: "GET",
      url: `/${store}/orders/all`,
      params: {
        resultsPerPage: 10,
        page: actualPageOrders + 1,
        date:
          periodInitialDate && periodFinalDate
            ? `${periodInitialDate},${periodFinalDate}`
            : ""
      }
    });

    setImportedOrders(prevState => {
      return [...prevState, ...response.results];
    });

    if (store === "bling") {
      setHasMoreBling(response.continue);
    }
    setActualPageOrders(Number(response.actualPage));
    setNPagesOrders(Number(response.nPages));
  };

  return (
    <Modal
      size="xl"
      show={true}
      centered
      aria-labelledby="contained-modal-title-vcenter"
      onHide={() => {
        setImportedOrders([]);
        setShowModal(false);
        handleClearPeriodFilter();
      }}
      dialogClassName={styles.modalMediaQueries}
      className={`${styles.modalContent}`}
    >
      <Modal.Header style={{ borderBottom: 0 }}>
        <Modal.Title
          style={{
            color: "#409E6A",
            fontFamily: "Mulish",
            fontSize: 18,
            fontWeight: "800"
          }}
        >
          Pedidos
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className={styles.inputContainerSmall}>
          <div className={styles.inputDatePicker}>
            <label htmlFor="period">Selecionar Período</label>

            <DatePicker
              className={"datePickerConfig"}
              change={handleOnChangeCalendarPeriod}
              placeholder="Selecione no calendário"
              showClearButton={!!periodInitialDate && !!periodFinalDate}
              onClearFields={handleClearPeriodFilter}
              range
            />
          </div>
        </div>
        <div
          id={"scrollContainerOrders"}
          className={`${styles.modalBody} ${
            importedOrders?.length > 5 ? styles.overflowActive : ""
          }`}
        >
          <InfiniteScroll
            dataLength={importedOrders?.length}
            hasMore={
              store === "bling"
                ? hasMoreBling
                : nPagesOrders === 0
                ? false
                : !(actualPageOrders === nPagesOrders)
            }
            next={handleLoadMoreOrders}
            loader={<LoadInfiniteScroll />}
            scrollableTarget="scrollContainerOrders"
          >
            {importedOrders.length === 0 ? (
              <span className={styles.noObjectWarning}>
                Não existem objetos para serem importados
              </span>
            ) : (
              <ModalRenderTable
                importedOrders={importedOrders}
                setOrdersToImport={setOrdersToImport}
                ordersToImport={ordersToImport}
                store={store}
              />
            )}{" "}
          </InfiniteScroll>
        </div>
      </Modal.Body>
      <Modal.Footer
        style={{ borderTop: 0 }}
        className={`${styles.modalFooter}`}
      >
        <button
          onClick={() => {
            setShowModal(false);
            handleClearPeriodFilter();
          }}
          className={`${styles.btnModal} ${styles.btnModalClose}`}
        >
          Fechar
        </button>
        <button
          onClick={handleOrderImport}
          className={`${styles.btnModal} ${styles.btnModalImport}`}
          disabled={ordersToImport.length === 0}
        >
          Importar
        </button>
      </Modal.Footer>
    </Modal>
  );
};
