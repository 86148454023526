import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { AiOutlineCheck } from "react-icons/ai";
import { BsExclamation } from "react-icons/bs";
import { FiCheck } from "react-icons/fi";
import { PiCaretRightBold } from "react-icons/pi";
import { useHistory, useParams } from "react-router-dom";
import Select from "react-select";
import Swal from "sweetalert2";

import { GoogleMaps } from "../../../../../components/GoogleMaps";
import { selectStyle } from "../../../../../components/InputSelectAsync";
import { Loading } from "../../../../../components/Loading";
import { useQuery } from "../../../../../hooks/useQuery";
import { BrComerceApiService } from "../../../../../services/BrComerceApiService";
import store from "../../../../../store/store";
import { cnpjValidation } from "../../../../../utils/CnpjValidation";
import { cpfValidation } from "../../../../../utils/CpfValidation";
import Steps from "../../../components/Steps";
import styles from "./styles.module.scss";

const DestinyForm = ({ step, totalSteps, isReverseLogistics }) => {
  const history = useHistory();
  const query = useQuery();
  const isReverse = window.location.href.toString().includes("isReverse");
  const withInsurance = window.location.href.toString().includes("withInsurance");

  const api = new BrComerceApiService();

  const { auth } = store.getState();

  const { user } = auth;

  const [buttonSubmitIsAble, setButtonSubmitIsAble] = useState(false);

  const [nameOK, setNameOK] = useState("vazio");
  const [nameError, setNameError] = useState("");
  const [name, setName] = useState("");

  const [cpfOK, setCpfOK] = useState("vazio");
  const [cpfError, setCpfError] = useState("");
  const [cpf, setCpf] = useState("");

  const [phoneOK, setPhoneOK] = useState("vazio");
  const [phoneError, setPhoneError] = useState("");
  const [phone, setPhone] = useState("");

  const [zipCodeOK, setZipCodeOK] = useState("vazio");
  const [zipCodeError, setZipCodeError] = useState("");
  const [zipCode, setZipCode] = useState("");

  const [addressOK, setAddressOK] = useState("vazio");
  const [addressError, setAddressError] = useState("");
  const [address, setAddress] = useState("");

  const [addressNumberOK, setAddressNumberOK] = useState("vazio");
  const [addressNumberError, setAddressNumberError] = useState("");
  const [addressNumber, setAddressNumber] = useState("");
  const [addressIsDisabled, setAddressIsDisabled] = useState(true);

  const [addressObsOK, setAddressObsOK] = useState("vazio");
  const [addressObsError, setAddressObsError] = useState("");
  const [addressObs, setAddressObs] = useState("");

  const [addressNeighborhoodOK, setAddressNeighborhoodOK] = useState("vazio");
  const [addressNeighborhoodError, setAddressNeighborhoodError] = useState("");
  const [addressNeighborhood, setAddressNeighborhood] = useState("");
  const [
    addressNeighborhoodIsDisabled,
    setAddressNeighborhoodIsDisabled
  ] = useState(true);

  const [uf, setUf] = useState("");
  const [ufStatus, setUfStatus] = useState("");

  const [city, setCity] = useState("");
  const [cityStatus, setCityStatus] = useState("");

  const [ufs, setUfs] = useState([]);
  const [cities, setCities] = useState([]);

  const [obsOK, setObsOK] = useState("vazio");
  const [obsError, setObsError] = useState("");
  const [obs, setObs] = useState("");

  const [fav, setFav] = useState(false);

  const [sendLocation, setSendLocation] = useState();
  const [destinyLocation, setDestinyLocation] = useState();

  const [zoom, setZoom] = useState(15);

  const [selectedSavedAddress, setSelectedSaveAddress] = useState([]);

  const [savedAddress, setSaveAddress] = useState([]);

  const [savedAddressOpts, setSaveAddressOpts] = useState([]);

  const [searching, setSearching] = useState(true);

  const [formState, setFormState] = useState("create");

  const [loading, setLoading] = useState(false);

  const [isFilledWithStorageData, setIsFilledWithStorageData] = useState(false);

  const getMapsInformation = async cep => {
    const newCep = cep.replace(/\D/g, "");
    const response = await axios
      .get(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${newCep}&result_type=geometry&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`
      )
      .catch(e => {
        console.error(e);
        return undefined;
      });
    setZoom(12);
    return response;
  };

  const handleCepConsult = useCallback(async cep => {
    try {
      setLoading(true);
      let response = await api.makeHttpRequest({
        method: "POST",
        url: `addressbook/getAddress`,
        data: {
          zipCode: cep
        }
      });

      if (!response) {
        setZipCodeOK("notOk");
        setZipCodeError("CEP inválido.");

        setAddress("");
        setAddressOK("vazio");
        setAddressError("");

        setAddressNeighborhood("");
        setAddressNeighborhoodOK("vazio");
        setAddressNeighborhoodError("");

        setUf("");
        setUfStatus("");
        setCity("");
        setCityStatus("");

        throw new Error("Não foi possível encontrar o CEP digitado.");
      }

      setZipCodeOK("ok");
      setZipCodeError("");

      if (!response.logradouro || response.logradouro === "undefined") {
        setAddressIsDisabled(false);
        setAddressOK("vazio");
      } else {
        setAddress(response.logradouro);
        setAddressOK("ok");
        setAddressError("");
      }

      if (!response.bairro || response.bairro === "undefined") {
        setAddressNeighborhoodIsDisabled(false);
        setAddressNeighborhoodOK("vazio");
      } else {
        setAddressNeighborhood(response.bairro);
        setAddressNeighborhoodOK("ok");
        setAddressNeighborhoodError("");
      }

      setUf({
        value: response.uf,
        label: response.uf
      });
      setUfStatus("");
      setCity({
        value: response.localidade,
        label: response.localidade
      });
      setCityStatus("");
    } catch (error) {
      console.error(error);

      Swal.fire({
        icon: "error",
        title: "CEP não encontrado",
        text: "Por favor, verifique o CEP inserido."
      });
    } finally {
      setLoading(false);
    }
  }, []);

  const handleValidationName = useCallback(value => {
    value.length < 2 ? setNameOK("notOk") : setNameOK("ok");
    if (value.length === 0) {
      setNameError("Campo obrigatório");
    } else if (value.length === 1) {
      setNameError("Nome deve ter pelo menos 2 caracteres");
    } else {
      setNameError("");
    }
  }, []);

  const handleInputName = useCallback(
    e => {
      setFormState("create");
      setSearching(true);
      let value = e.currentTarget.value;
      setName(value);
      handleValidationName(value);
    },
    [handleValidationName]
  );

  const handleValidationCPF = value => {
    const validCPF = cpfValidation({
      cpf: value,
      emptyCpf: "Campo obrigatório",
      incorrectCpf: "CPF incorreto"
    });
    setCpfOK(validCPF.status);
    setCpfError(validCPF.message);
  };

  const handleValidationCNPJ = value => {
    const validCnpj = cnpjValidation({
      cnpj: value,
      emptyCnpj: "Campo obrigatório",
      incorrectCnpj: "CNPJ incorreto"
    });
    setCpfOK(validCnpj.status);
    setCpfError(validCnpj.message);
  };

  const handleInputCpf = useCallback(e => {
    let inputValue = e.currentTarget.value.replace(/\W/gi, "");

    if (inputValue.length <= 11) {
      inputValue = inputValue.replace(/\D/g, "");
      inputValue = inputValue.replace(
        /^(\d{3})(\d{3})(\d{3})(\d{2})/,
        "$1.$2.$3-$4"
      );
      e.currentTarget.inputValue = inputValue;
      handleValidationCPF(inputValue);
      setCpf(inputValue);
    } else {
      e.currentTarget.maxLength = 18;

      inputValue = inputValue.replace(/\D/g, "");
      inputValue = inputValue.replace(
        /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
        "$1.$2.$3/$4-$5"
      );
      e.currentTarget.inputValue = inputValue;
      handleValidationCNPJ(inputValue);
      setCpf(inputValue);
    }
  }, []);

  const handleValidationPhone = value => {
    if (value.length > 0 && value.length < 14) {
      setPhoneError("Telefone incorreto");
      setPhoneOK("notOk");
    } else if (value.length === 0) {
      setPhoneError("");
      setPhoneOK("vazio");
    } else {
      setPhoneError("");
      setPhoneOK("ok");
    }
  };

  const handleInputPhone = useCallback(e => {
    e.currentTarget.maxLength = 15;
    let value = e.currentTarget.value;
    value = value.replace(/\D/g, "");
    if (value.length === 11) {
      value = value.replace(/^(\d{2})(\d{5})(\d{4})/, "($1) $2-$3");
    } else if (value.length === 10) {
      value = value.replace(/^(\d{2})(\d{4})(\d{4})/, "($1) $2-$3");
    }
    e.currentTarget.value = value;
    handleValidationPhone(value);
    setPhone(value);
  }, []);

  const handleValidationZipCode = useCallback(
    async (value, isASavedInfo = false) => {
      if (value.length === 0) {
        setZipCodeOK("notOk");
        setZipCodeError("Preenchimento obrigatório");
      } else if (value.length < 9) {
        setZipCodeOK("notOk");
        setZipCodeError("Preenchimento incorreto");
      } else {
        if (isASavedInfo) {
          setZipCodeOK("ok");
          setZipCodeError("");
        }

        // Não queremos manipular o CEP novamente,
        // se o CEP selecionado pertence a um endereço já salvo
        if (isASavedInfo === false) {
          const cep = value.substr(0, 5) + value.substr(6, 3);
          await handleCepConsult(cep);
        }
      }
    },
    [handleCepConsult]
  );

  const handleInputZipCode = useCallback(
    e => {
      e.currentTarget.maxLength = 9;
      let value = e.currentTarget.value;
      value = value.replace(/\D/g, "");
      value = value.replace(/^(\d{5})(\d{3})/, "$1-$2");

      handleValidationZipCode(value);
      e.currentTarget.value = value;
      setZipCode(value);
    },
    [handleValidationZipCode]
  );

  const handleValidationAddress = value => {
    value.length < 2 ? setAddressOK("notOk") : setAddressOK("ok");
    if (value.length === 0) {
      setAddressError("Campo obrigatório");
    } else if (value.length === 1) {
      setAddressError("Endereço deve ter pelo menos 2 caracteres");
    } else {
      setAddressError("");
    }
  };

  const handleInputAddress = useCallback(
    e => {
      if (addressIsDisabled) return;

      let value = e.currentTarget.value;
      setAddress(value);
      handleValidationAddress(value);
    },
    [addressIsDisabled]
  );

  const handleValidationAddressNumber = value => {
    if (value !== "") {
      setAddressNumberOK("ok");
    } else {
      setAddressNumberOK("vazio");
      setAddressNumberError("");
    }
  };

  const handleInputAddressNumber = useCallback(e => {
    let value = e.currentTarget.value;

    if (value !== "" && !Number(value)) {
      return;
    }

    setAddressNumber(value);
    handleValidationAddressNumber(value);
  }, []);

  const handleValidationAddressObs = value => {
    if (value?.length < 2 && value?.length > 0) {
      setAddressObsOK("notOk");
    } else if (value?.length > 2) {
      setAddressObsOK("ok");
    } else {
      setAddressObsOK("vazio");
    }

    if (value?.length === 1) {
      setAddressObsError("Complemento deve ter pelo menos 2 caracteres");
    } else {
      setAddressObsError("");
    }
  };

  const handleInputAddressObs = useCallback(e => {
    let value = e.currentTarget.value;
    setAddressObs(value);
    handleValidationAddressObs(value);
  }, []);

  const handleValidationAddressNeighborHood = value => {
    if (address?.length) return;

    value.length < 1
      ? setAddressNeighborhoodOK("notOk")
      : setAddressNeighborhoodOK("ok");
    if (value.length === 0) {
      setAddressNeighborhoodError("Campo obrigatório");
    } else {
      setAddressNeighborhoodError("");
    }
  };

  const handleInputAddressNeighborhood = useCallback(
    e => {
      if (addressNeighborhoodIsDisabled) return;
      let value = e.currentTarget.value;
      setAddressNeighborhood(value);
      handleValidationAddressNeighborHood(value);
    },
    [addressNeighborhoodIsDisabled]
  );

  const handleSetUfs = useCallback(async () => {
    try {
      const response = await (
        await axios.get(
          "https://servicodados.ibge.gov.br/api/v1/localidades/estados"
        )
      ).data;
      const ufOpts = response.map(resp => ({
        value: resp.sigla,
        label: resp.nome
      }));
      setUfs(ufOpts);
    } catch (error) {
      console.error(error);
    }
  }, []);

  const handleSetUf = useCallback(
    e => {
      if (zipCode.length) return;

      setUf(e ? e : {});
      setUfStatus("");
    },
    [zipCode]
  );

  const handleSetCity = useCallback(
    e => {
      if (zipCode.length) return;

      setCity(e ? e : {});
      setCityStatus("");
    },
    [zipCode]
  );

  const handleValidationObs = value => {
    if (value?.length < 2 && value?.length > 0) {
      setObsOK("notOk");
    } else if (value?.length > 2) {
      setObsOK("ok");
    } else {
      setObsOK("vazio");
    }

    if (value?.length === 1) {
      setObsError("Observação deve ter pelo menos 2 caracteres");
    } else if (value?.length >= 29) {
      setObsError("Limite de caracteres atingído");
    } else {
      setObsError("");
    }
  };

  const handleInputObs = useCallback(e => {
    let value = e.currentTarget.value;

    handleValidationObs(value);
    if (value) {
      value = String(value).substring(0, 29);
    }

    setObs(value);
  }, []);

  const handleSelectSavedAddress = useCallback(
    async a => {
      setLoading(true);
      setFormState("update");

      setSelectedSaveAddress(a);

      setName(a.name);
      handleValidationName(a.name);

      if (a.document.length <= 11) {
        const cpf = a.document.replace(
          /^(\d{3})(\d{3})(\d{3})(\d{2})/,
          "$1.$2.$3-$4"
        );
        setCpf(cpf);
        handleValidationCPF(cpf);
      } else {
        const cnpj = a.document.replace(
          /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
          "$1.$2.$3/$4-$5"
        );
        setCpf(cnpj);
        handleValidationCNPJ(cnpj);
      }

      const phoneFormatted =
        a.phone.length === 11
          ? a.phone.replace(/^(\d{2})(\d{5})(\d{4})/, "($1) $2-$3")
          : a.phone.replace(/^(\d{2})(\d{4})(\d{4})/, "($1) $2-$3");

      setPhone(phoneFormatted);
      handleValidationPhone(phoneFormatted);

      setPhone(phoneFormatted);
      handleValidationPhone(phoneFormatted);

      setAddress(a.street);
      handleValidationAddress(a.street);

      setAddressNumber(a.number);
      handleValidationAddressNumber(a.number);

      setAddressObs(a.complement);
      handleValidationAddressObs(a.complement);

      setAddressNeighborhood(a.district);
      handleValidationAddressNeighborHood(a.district);

      setObs(a.observation || "");
      handleValidationObs(a.observation || "");

      const zipCode = a.zip.replace(/^(\d{5})(\d{3})/, "$1-$2");
      setZipCode(zipCode);
      handleValidationZipCode(zipCode, true);

      setUf({
        value: a.state,
        label: a.state
      });
      setUfStatus("");
      setCity({
        value: a.city,
        label: a.city
      });
      setCityStatus("");

      setSearching(false);
      setLoading(false);
    },
    [handleCepConsult]
  );

  const fieldsInFormIsAbleToSubmit = useCallback(
    (activedBySideEffect = false) => {
      let result = true;

      if (name === "") {
        if (!activedBySideEffect) {
          setNameOK("notOk");
          setNameError("Campo obrigatório");
          result = false;
        } else {
          return false;
        }
      }

      if (cpf === "") {
        if (!activedBySideEffect) {
          setCpfOK("notOk");
          setCpfError("Campo obrigatório");
          result = false;
        } else {
          return false;
        }
      }

      if (zipCode === "") {
        if (!activedBySideEffect) {
          setZipCodeOK("notOk");
          setZipCodeError("Campo obrigatório");
          result = false;
        } else {
          return false;
        }
      }

      if (zipCode.length && address === "") {
        if (!activedBySideEffect) {
          setAddressOK("notOk");
          setAddressError("Campo obrigatório");
          result = false;
        } else {
          return false;
        }
      }

      if (zipCode.length && addressNeighborhood === "") {
        if (!activedBySideEffect) {
          setAddressNeighborhoodOK("notOk");
          setAddressNeighborhoodError("Campo obrigatório");
          result = false;
        } else {
          return false;
        }
      }

      if (zipCode.length && uf === "") {
        if (!activedBySideEffect) {
          setUfStatus("notOk");
          result = false;
        } else {
          return false;
        }
      }

      if (zipCode.length && city === "") {
        if (!activedBySideEffect) {
          setCityStatus("notOk");
          result = false;
        } else {
          return false;
        }
      }

      if (
        nameOK === "notOk" ||
        cpfOK === "notOk" ||
        phoneOK === "notOk" ||
        zipCodeOK === "notOk" ||
        addressOK === "notOk" ||
        addressObsOK === "notOk" ||
        addressNeighborhoodOK === "notOk" ||
        uf === "" ||
        city === "" ||
        city.value === undefined ||
        uf.value === undefined
      ) {
        return false;
      }

      return result;
    },
    [
      address,
      addressNeighborhood,
      addressNeighborhoodOK,
      addressOK,
      addressObsOK,
      city,
      cpf,
      cpfOK,
      name,
      nameOK,
      phoneOK,
      uf,
      zipCode,
      zipCodeOK
    ]
  );

  const handleSetCities = useCallback(async () => {
    try {
      const ufSelected = ufs.find(ufOpt => ufOpt.value === uf?.value);
      const response = await (
        await axios.get(
          `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${ufSelected?.value}/municipios`
        )
      ).data;
      const ufOpts = response.map(resp => ({
        value: resp.nome,
        label: resp.nome
      }));
      setCities(ufOpts);
    } catch (error) {
      console.log(error);
    }
  }, [uf, ufs]);

  const handleSubmit = useCallback(
    async e => {
      setLoading(true);
      e.preventDefault();

      const formCanSubmit = fieldsInFormIsAbleToSubmit();
      if (formCanSubmit === false) {
        Swal.fire({
          icon: "warning",
          title: "Validação",
          text: "É necessário que seja preenchidos todos os campos sinalizados."
        });
        setLoading(false);
        return;
      }

      const data = {
        name: name,
        document: cpf.replace(/\D/g, ""),
        recipient: true,
        phone: phone.replace(/\D/g, "") || "",
        zip: zipCode.replace(/\D/g, ""),
        street: address,
        number: addressNumber || "",
        complement: addressObs || "",
        district: addressNeighborhood,
        state: uf?.value,
        city: city?.value,
        observation: obs,
        favorite: fav
      };



      try {
        if (isReverse) {
          const shippingId = query.get("shipping").replace(/\D+/g, "");

          const newRecipient = await api.makeHttpRequest({
            method: "POST",
            url: `/addressbook`,
            data
          });



          const reverse = window.localStorage.getItem("Brcom:Reverse");
          const { insurance, reverseEmail } = JSON.parse(reverse);

          const reverseData = {
            newDestination: {
              name: newRecipient.name,
              address: newRecipient.street,
              number: newRecipient.number,
              complement: newRecipient.complement,
              neighborhood: newRecipient.district,
              city: newRecipient.city,
              province: newRecipient.state,
              postal_code: newRecipient.zip,
              phone: newRecipient.phone,
            },
            ...(reverseEmail !== "" && { logistic_reverse_email: reverseEmail }),
            insurance: insurance ?? false
          }

          await api.makeHttpRequest({
            method: "POST",
            url: `/reverse-shipping/${shippingId}`,
            data: reverseData
          });

          window.localStorage.removeItem("Brcom:Reverse");
          history.push(`/carrinho`);
        } else {
          const response = await api.makeHttpRequest({
            method: formState === "update" ? "PUT" : "POST",
            url:
              formState === "update"
                ? `/addressbook/${selectedSavedAddress.id}`
                : `/addressbook`,
            data
          });

          window.localStorage.setItem(
            "brcom:destiny-location-resp",
            JSON.stringify(response)
          );

          let subroute;
          if (isReverseLogistics) {
            subroute = "reverse-package-form";
          } else {
            subroute = "normal-package-form";
          }

          history.push(
            `/nova-coleta/${subroute}${history?.location?.search &&
            history?.location?.search}`
          );
        }
      } catch (error) {
        if (error?.response?.data?.message === "Validation failed") {
          Swal.fire({
            icon: "error",
            title: "Ocorreu um erro",
            text: "Verificar preenchimento dos campos."
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Ocorreu um erro",
            text: "Erro ao cadastrar remetente, tente novamente mais tarde."
          });
        }
      } finally {
        setLoading(false);
      }
    },
    [
      fieldsInFormIsAbleToSubmit,
      name,
      cpf,
      phone,
      zipCode,
      address,
      addressNumber,
      addressObs,
      addressNeighborhood,
      uf.value,
      city.value,
      obs,
      fav,
      api,
      formState,
      selectedSavedAddress.id,
      history
    ]
  );

  useEffect(() => {
    window.setPageTitle("Destinatário");
  }, []);

  useEffect(() => {
    const hasSenderInfo = localStorage.getItem("brcom:send-location-resp");

    if (!hasSenderInfo) {
      history.push("/nova-coleta/normal-send-form");
      return;
    }
  }, []);

  useEffect(() => {
    handleSetUfs();
  }, [handleSetUfs]);

  useEffect(() => {
    handleSetCities();
  }, [ufs, uf, handleSetCities]);

  useEffect(() => {
    const storage = window.localStorage.getItem("brcom:send-location");
    if (storage) {
      setSendLocation(JSON.parse(storage));
    }
  }, []);

  useEffect(() => {
    if (destinyLocation) {
      window.localStorage.setItem(
        "brcom:destiny-location",
        JSON.stringify(destinyLocation)
      );
    }
  }, [destinyLocation]);

  useEffect(() => {
    (async () => {
      try {
        const response = await api.makeHttpRequest({
          method: "GET",
          url: `addressbook`
        });

        setSaveAddress([...response.filter(opt => opt.recipient)]);
      } catch (e) {
        console.error(e);
      }
    })();
  }, []);

  useEffect(() => {
    const shippingId = query.get("shipping");

    if (!shippingId) return;
    (async () => {
      try {
        setLoading(true);
        const response = await api.makeHttpRequest({
          method: "GET",
          url: `shipping/${shippingId}`
        });

        if (isReverse) {
          handleSelectSavedAddress(response.origin);
        } else {
          handleSelectSavedAddress(response.destination);
        }
      } catch (e) {
        Swal.fire({
          icon: "error",
          title: "Remetente salvo",
          text:
            "Houve um erro ao carregar as informações do remetente, tente novamente mais tarde."
        });
      } finally {
        setLoading(false);
      }
    })();
  }, [query]);

  useEffect(() => {
    if (name === "" || !searching) {
      setSaveAddressOpts([...[]]);
      return;
    }
    const data = savedAddress.filter(
      a => a.name.toLowerCase().search(name.toLowerCase()) >= 0
    );
    setSaveAddressOpts([...data]);
  }, [name, savedAddress, searching]);

  useEffect(() => {
    if (zipCodeOK.toLowerCase() === "notok") return;

    // 9 dígitos presente no CEP formatado 00000-000
    if (zipCode && zipCode.length === 9) {
      (async () => {
        try {
          setLoading(true);
          const response = await getMapsInformation(zipCode);

          if (!response) {
            return;
          }

          if (!response?.data?.results.length) {
            throw new Error("");
          }

          setDestinyLocation(response?.data?.results?.[0]?.geometry?.location);
        } catch (e) {
          Swal.fire({
            icon: "error",
            title: "Mapa",
            text:
              "Não foi possível carregar informações de localização desse endereço."
          });
        } finally {
          setLoading(false);
        }
      })();
    }
  }, [zipCode, zipCodeOK]);

  useEffect(() => {
    const isAble = fieldsInFormIsAbleToSubmit(true);
    setButtonSubmitIsAble(isAble);
  }, [fieldsInFormIsAbleToSubmit]);

  return (
    <>
      <Loading loading={loading} />

      <main className={styles.main}>
        <div className={styles.container}>
          <div className={styles.title}>
            <h2>
              Dados do Destinatário {isReverseLogistics && " (Envio Reverso)"}
            </h2>
          </div>
          <Steps step={step} total={totalSteps} />
          <form className={styles.form} /* onSubmit={handleFormSubmit} */>
            <div className={styles.formContent}>
              <div className={styles.inputsContainer}>
                <div className={styles.inputContainer}>
                  <div className={styles.input} role={nameOK}>
                    <input
                      type="text"
                      name="name"
                      placeholder="Nome do destinatário"
                      onChange={e => handleInputName(e)}
                      value={name}
                      autoComplete="1234"
                    />
                    <AiOutlineCheck className={styles.check} role={nameOK} />
                    <BsExclamation className={styles.danger} role={nameOK} />
                  </div>
                  {nameError && <p className={styles.error}>{nameError}</p>}
                  {savedAddressOpts.length > 0 && (
                    <div className={styles.optionsContainer}>
                      {savedAddressOpts.map(a => (
                        <div
                          key={a.id}
                          className={styles.option}
                          onClick={() => handleSelectSavedAddress(a)}
                        >
                          {a.name}
                        </div>
                      ))}
                      {savedAddressOpts.some(
                        x => x.name.toLowerCase() === name.toLowerCase()
                      ) ? null : (
                        <div
                          className={styles.option}
                          onClick={() => setSearching(false)}
                        >
                          Criar: {name}
                        </div>
                      )}
                    </div>
                  )}
                </div>
                <div
                  className={`${styles.inputContainer} ${styles.inputContainerSmall}`}
                >
                  <div className={styles.input} role={cpfOK}>
                    <input
                      type="text"
                      name="cpf"
                      placeholder="CPF/CNPJ"
                      onChange={e => handleInputCpf(e)}
                      value={cpf}
                    />
                    <AiOutlineCheck className={styles.check} role={cpfOK} />
                    <BsExclamation className={styles.danger} role={cpfOK} />
                  </div>
                  {cpfError && <p className={styles.error}>{cpfError}</p>}
                </div>
                <div
                  className={`${styles.inputContainer} ${styles.inputContainerSmall}`}
                >
                  <div className={styles.input} role={phoneOK}>
                    <input
                      type="text"
                      name="phone"
                      placeholder="Telefone"
                      onChange={handleInputPhone}
                      value={phone}
                    />
                    <AiOutlineCheck className={styles.check} role={phoneOK} />
                    <BsExclamation className={styles.danger} role={phoneOK} />
                  </div>
                  {phoneError && <p className={styles.error}>{phoneError}</p>}
                </div>
                <div
                  className={`${styles.inputContainer} ${styles.inputContainerSmall} ${styles.inputContainerSmCep} `}
                >
                  <div className={styles.input} role={zipCodeOK}>
                    <input
                      type="text"
                      name="cep"
                      placeholder="CEP"
                      onChange={e => handleInputZipCode(e)}
                      value={zipCode}
                    />
                    <AiOutlineCheck className={styles.check} role={zipCodeOK} />
                    <BsExclamation className={styles.danger} role={zipCodeOK} />
                  </div>
                  {zipCodeError && (
                    <p className={styles.error}>{zipCodeError}</p>
                  )}
                  <a
                    href="https://buscacepinter.correios.com.br/app/endereco/index.php"
                    target="_blank"
                    rel="noopener noreferrer"
                    className={styles.cep}
                  >
                    Não sei o CEP
                  </a>
                </div>
                <div className={styles.mapContainerMobile}>
                  <GoogleMaps
                    sendLocation={sendLocation}
                    zoom={zoom}
                    destinyLocation={destinyLocation}
                  />
                </div>
                <div
                  className={`${styles.inputContainer} ${styles.inputContainerMd}`}
                  style={{ marginTop: "17px" }}
                >
                  <div
                    className={`${styles.input} ${addressIsDisabled ? styles.fieldDisabled : ""
                      }`}
                    role={addressOK}
                  >
                    <input
                      type="text"
                      name="address"
                      placeholder="Endereço"
                      onChange={e => handleInputAddress(e)}
                      value={address}
                    />
                    {addressError && (
                      <p className={styles.error}>{addressError}</p>
                    )}
                    <AiOutlineCheck className={styles.check} role={addressOK} />
                    <BsExclamation className={styles.danger} role={addressOK} />
                  </div>
                  {addressError && (
                    <p className={styles.error}>{addressError}</p>
                  )}
                </div>
                <div
                  className={`${styles.inputContainer} ${styles.inputContainerSm}`}
                >
                  <div
                    className={`${!!address?.length === false ? styles.fieldDisabled : ""
                      } ${styles.input}`}
                    role={addressNumberOK}
                  >
                    <input
                      type="text"
                      name="addressNumber"
                      placeholder="Número"
                      onChange={e => handleInputAddressNumber(e)}
                      value={addressNumber}
                    />
                    <AiOutlineCheck
                      className={styles.check}
                      role={addressNumberOK}
                    />
                    <BsExclamation
                      className={styles.danger}
                      role={addressNumberOK}
                    />
                  </div>
                  {addressNumberError && (
                    <p className={styles.error}>{addressNumberError}</p>
                  )}
                </div>
                <div
                  className={`${styles.inputContainer} ${styles.inputContainerSmall}`}
                >
                  <div
                    className={`${!!address?.length === false ? styles.fieldDisabled : ""
                      } ${styles.input}`}
                    role={addressObsOK}
                  >
                    <input
                      type="text"
                      name="addressObs"
                      placeholder="Complemento"
                      onChange={e => handleInputAddressObs(e)}
                      value={addressObs}
                    />
                    <AiOutlineCheck
                      className={styles.check}
                      role={addressObsOK}
                    />
                    <BsExclamation
                      className={styles.danger}
                      role={addressObsOK}
                    />
                  </div>
                  {addressObsError && (
                    <p className={styles.error}>{addressObsError}</p>
                  )}
                </div>
                <div
                  className={`${styles.inputContainer} ${styles.inputContainerSmall}`}
                >
                  <div
                    className={`${styles.input}  ${addressNeighborhoodIsDisabled ? styles.fieldDisabled : ""
                      }`}
                    role={addressNeighborhoodOK}
                  >
                    <input
                      type="text"
                      name="addressNeighborhood"
                      placeholder="Bairro"
                      onChange={e => handleInputAddressNeighborhood(e)}
                      value={addressNeighborhood}
                    />
                    <AiOutlineCheck
                      className={styles.check}
                      role={addressNeighborhoodOK}
                    />
                    <BsExclamation
                      className={styles.danger}
                      role={addressNeighborhoodOK}
                    />
                  </div>
                  {addressNeighborhoodError && (
                    <p className={styles.error}>{addressNeighborhoodError}</p>
                  )}
                </div>
                <div
                  className={`${styles.formSelect} ${ufStatus === "notOk"
                      ? styles.formSelectError
                      : ufStatus === "" && !!uf
                        ? styles.selectOk
                        : ""
                    }`}
                >
                  <Select
                    onChange={handleSetUf}
                    isDisabled={true}
                    defaultValue={uf}
                    value={uf}
                    options={ufs}
                    placeholder="Estado"
                    styles={selectStyle}
                    noOptionsMessage={({ inputValue }) =>
                      !inputValue && "Resultados não encontrados"
                    }
                  />

                  {ufStatus === "notOk" && (
                    <p className={styles.error}>Campo obrigatório</p>
                  )}
                </div>
                <div
                  className={`${styles.formSelect} ${cityStatus === "notOk"
                      ? styles.formSelectError
                      : cityStatus === "" && !!city
                        ? styles.selectOk
                        : ""
                    }`}
                >
                  <Select
                    onChange={handleSetCity}
                    isDisabled={true}
                    value={city}
                    defaultValue={city}
                    options={cities}
                    placeholder="Cidade"
                    styles={selectStyle}
                    noOptionsMessage={({ inputValue }) =>
                      !inputValue && "Resultados não encontrados"
                    }
                  />

                  {cityStatus === "notOk" && (
                    <p className={styles.error}>Campo obrigatório</p>
                  )}
                </div>
                <div className={`${styles.inputContainer}`}>
                  <p
                    className={`
                    ${styles.obsCounter}
                    ${obs.length >= 29
                        ? styles.obsCounterNotOk
                        : obs.length >= 15
                          ? styles.obsCounterWarn
                          : ""
                      }
                  `}
                  >
                    {obs.length}/29
                  </p>
                  <div
                    className={`${styles.input} ${styles.inputContainerObs}`}
                    role={obsOK}
                  >
                    <input
                      type="text"
                      name="obs"
                      placeholder="Observações"
                      onChange={e => handleInputObs(e)}
                      value={obs}
                    />
                    <AiOutlineCheck className={styles.check} role={obsOK} />
                    <BsExclamation className={styles.danger} role={obsOK} />
                  </div>
                  {obsError && <p className={styles.error}>{obsError}</p>}
                </div>
                <div
                  className={styles.errorContent}
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  {obsError && <p className={styles.error}>{obsError}</p>}

                  <p
                    className={`
                    ${styles.obsCounter}
                    ${obs.length >= 29
                        ? styles.obsCounterNotOk
                        : obs.length >= 15
                          ? styles.obsCounterWarn
                          : ""
                      }
                  `}
                  >
                    {obs.length}/29 palavras
                  </p>
                </div>
              </div>
            </div>
            <div className={styles.buttonsContainer}>
              <div className={styles.check} onClick={() => setFav(!fav)}>
                {!fav ? <div /> : <FiCheck />}
                <p>Incluir destinatário nos meu favoritos</p>
              </div>
              <div
                className={`${styles.buttonContainer} ${!buttonSubmitIsAble ? styles.inactive : ""
                  }`}
              >
                {/* <button
                type="button"
                onClick={() => history.push("/nova-coleta/package-form")}
              >
                Próximo
              </button> */}
                <button type="submit" onClick={handleSubmit}>
                  {isReverse ? "Solicitar reversa" : "Próximo passo"}
                  <PiCaretRightBold />
                </button>
              </div>
            </div>
          </form>
        </div>
        <div className={styles.mapContainer}>
          <GoogleMaps
            sendLocation={sendLocation}
            zoom={zoom}
            destinyLocation={destinyLocation}
          />
        </div>
      </main>
    </>
  );
};

export { DestinyForm };
