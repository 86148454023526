import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  withStyles
} from "@material-ui/core";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { AiOutlineWarning } from "react-icons/ai";
import { ImBlocked, ImWarning } from "react-icons/im";
import InfiniteScroll from "react-infinite-scroll-component";
import ReactModal from "react-modal";
import { Link, useHistory } from "react-router-dom";
import Select from "react-select";
import Swal from "sweetalert2";
import DatePicker from "../../../../components/Input/DatePicker";
import LoadInfiniteScroll from "../../../../components/LoadInfiniteScroll";
import { Loading } from "../../../../components/Loading";
import collectStagesEnum from "../../../../enums/CollectStagesEnum";
import ShippingStagesEnum from "../../../../enums/ShippingStagesEnum";
import { BrComerceApiService } from "../../../../services/BrComerceApiService";
import store from "../../../../store/store";
import debounce from "../../../../utils/debounce";
import flatten from "../../../../utils/flattenObject";
import formatCep from "../../../../utils/FormatCep";
import { ActionsMenu } from "../../components/ActionsMenu";
import { CardCollectComponent } from "../../components/CardCollect";
import { DateFilter } from "../../components/DateFilter";
import ScrollDiv from "../../components/ScrollDiv";
import { CardCollectDetails } from "./components/CardCollectDetails";
import { ModalButtonsContainer, ModalContainer } from "./styles";
import styles from "./styles.module.scss";
import { CancelShipping } from "../../components/CancelShipping";
import InputSelectAsync from "../../../../components/InputSelectAsync";
import ProfileEnum from "../../../../enums/ProfileEnum";
import { selectStyle } from "../../../../components/InputSelectAsync";
import Container from "../../components/Container";

const StyledTableHead = withStyles(theme => ({
  root: {
    backgroundColor: "#DEF6E7"
  }
}))(TableHead);

const StyledTableCell = withStyles(theme => ({
  head: {
    color: "#333333",
    textAlign: "center",
    fontFamily: "Mulish",
    fontWeight: 700,
    fontSize: 14,
    padding: "13.5px 0 10.5px"
  },
  body: {
    textAlign: "center",
    fontSize: 12,
    padding: 16,
    fontWeight: 500,
    paddingLeft: 18,
    paddingRight: 18,
    fontFamily: "Mulish",
    borderBottom: 0
  }
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    "&:last-child()": {
      borderBottom: "0"
    }
  }
}))(TableRow);

const Collect = ({ width: windowWidth }) => {
  const { auth } = store.getState();
  const { user } = auth;
  const isAdmin = user?.profile === ProfileEnum.ADMIN.value;
  const isManager = user?.profile === ProfileEnum.MANAGER.value;

  const api = new BrComerceApiService();
  const history = useHistory();

  const [allCollects, setAllCollects] = useState([]);
  const [collectSelected, setCollectSelected] = useState([]);

  const [actualPage, setActualPage] = useState(0);
  const [nPages, setNPages] = useState(0);
  const [actualPageShippings, setActualPageShippings] = useState(0);
  const [hasMore, setHasMore] = useState(true);

  const [collectDetail, setCollectDetail] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const [showScheduleModal, setShowScheduleModal] = useState(false);
  const [cancelScheduleModal, setCancelScheduleModal] = useState(false);
  const [scheduleCollectsIds, setScheduleCollectsIds] = useState([]);

  const [loading, setLoading] = useState(false);

  const [selectedStatusInFilter, setSelectedStatusInFilter] = useState({
    value: "",
    label: "Todos"
  });
  const [periodInitialDate, setPeriodInitialDate] = useState("");
  const [periodFinalDate, setPeriodFinalDate] = useState("");

  const [showCancelShippingModal, setShowCancelShippingModal] = useState(false);
  const [shippingInModal, setShippingInModal] = useState({});

  const [searchClient, setSearchClient] = useState(null);

  const getOptionsStagesCollect = useMemo(() => {
    const optionsHandled = Object.entries(collectStagesEnum).reduce(
      (acc, [key, value]) => {
        if (key === "PAGAMENTO") {
          const { PAGAMENTO_NEGADO, PAGAMENTO_APROVADO } = value;

          return [
            ...acc,
            { value: PAGAMENTO_NEGADO.id, label: PAGAMENTO_NEGADO.message },
            { value: PAGAMENTO_APROVADO.id, label: PAGAMENTO_APROVADO.message }
          ];
        }

        if (key === "AGENDAMENTO") {
          const { AGUARDANDO_AGENDAMENTO, COLETA_AGENDADA } = value;

          return [
            ...acc,
            {
              value: AGUARDANDO_AGENDAMENTO.id,
              label: AGUARDANDO_AGENDAMENTO.message
            },
            { value: COLETA_AGENDADA.id, label: COLETA_AGENDADA.message }
          ];
        }

        if (key === "COLETA") {
          const { COLETA_CONCLUIDA, COLETA_CANCELADA } = value;

          return [
            ...acc,
            { value: COLETA_CONCLUIDA.id, label: COLETA_CONCLUIDA.message },
            { value: COLETA_CANCELADA.id, label: COLETA_CANCELADA.message }
          ];
        }

        return [...acc, { value: value.id, label: value.message }];
      },
      []
    );

    return [...optionsHandled, { value: undefined, label: "Todos" }];
  }, []);

  const handleOnChangeCalendarPeriod = e => {
    if (e.dateEnd) {
      const dateStart = moment(e.dateStart, "DD/MM/YYYY").format(
        "YYYY-MM-DDTHH:mm:ss"
      );
      const dateEnd = moment(e.dateEnd, "DD/MM/YYYY").format(
        "YYYY-MM-DDTHH:mm:ss"
      );

      setPeriodInitialDate(dateStart);
      setPeriodFinalDate(dateEnd);
    }
  };

  const handleClearPeriodFilter = () => {
    setPeriodInitialDate("");
    setPeriodFinalDate("");
  };

  const handlePrintShippings = useCallback(async () => {
    try {
      const shippingsIds = collectSelected.reduce((acc, collect) => {
        const shippingsIdsInActualCollect = collect.shippings.map(
          shipping => shipping.id
        );

        return [...acc, ...shippingsIdsInActualCollect];
      }, []);

      setLoading(true);

      window.localStorage.setItem(
        "Brcom:Etiquetas",
        JSON.stringify(shippingsIds)
      );

      window.open("/etiquetas", "_blank");
    } catch (e) {
      Swal.fire({
        icon: "error",
        title: "Etiquetas",
        text: "Não foi possível gerar etiquetas."
      });
    } finally {
      setLoading(false);
    }
  }, [collectSelected]);

  const handleChangeSelectFilterByStatus = e => {
    setSelectedStatusInFilter(e);
  };

  const handleCheckedItemCard = (collect, statusCollect) => {
    const isSelected = collectSelected.find(item => item.id === collect.id);

    if (isSelected) {
      setCollectSelected(prev => prev.filter(item => item.id !== collect.id));
    } else {
      const collectHandled = {
        ...collect,
        statusMessage: statusCollect.message
      };

      setCollectSelected(prev => [...prev, collectHandled]);
    }
  };

  const handleLoadMoreShippings = useCallback(async () => {
    const response = await api.makeHttpRequest({
      method: "GET",
      url: `/shipping/tracking`,
      params: {
        resultsPerPage: 10,
        page: actualPageShippings + 1,
        idCollect: collectDetail.id
      }
    });

    setCollectDetail(prevState => {
      return {
        ...prevState,
        shippings: [...prevState.shippings, ...response.results]
      };
    });
    setActualPageShippings(Number(response.actualPage));

    if (response.results.length !== 10) {
      setHasMore(false);
    }
  }, [actualPageShippings]);

  const handleModalOpenDetails = async collect => {
    try {
      setLoading(true);

      const { id } = collect;

      const response = await api.makeHttpRequest({
        method: "GET",
        url: `/shipping/tracking`,
        params: {
          resultsPerPage: 10,
          page: 1,
          idCollect: id
        }
      });

      setCollectDetail({
        id,
        shippings: response.results
      });
      setActualPageShippings(Number(response.actualPage));
      setShowModal(true);
      if (response.results.length !== 10) {
        setHasMore(false);
      }
    } catch (e) {
      Swal.fire({
        title: "Envios",
        text: "Erro ao carregar os envios.",
        icon: "error"
      });
    } finally {
      setLoading(false);
    }
  };

  const handleReverseShippingRequest = useCallback(
    async shipping => {
      if (shipping.idShippingStatus < 8 || shipping.idShippingStatus > 10) {
        return;
      }

      if (shipping.reverseShippingId !== null) {
        history.push(`/detalhes-objeto/${shipping.reverseShippingId}`);
        return;
      }

      try {
        setLoading(true);
        const response = await api.makeHttpRequest({
          method: "POST",
          url: `/reverse-shipping/${shipping.id}`
        });

        history.push(`/detalhe-coleta/${response.idCollect}`);
      } catch (e) {
        console.log(e);
        Swal.fire({
          title: "Erro",
          text: "Um erro ocorreu ao tentar criar a logistica reversa",
          icon: "error",
          confirmButtonColor: "#409e6a"
        });
      } finally {
        setLoading(false);
      }
    },
    [api, history]
  );

  const handlePrePostar = useCallback(
    async shipping => {
      try {
        await api.makeHttpRequest({
          method: "POST",
          url: `/shipping/${shipping.id}/prepostar`
        });

        Swal.fire({
          title: "Sucesso!",
          text: "Objeto enviado para a fila de pré-postagem com sucesso.",
          icon: "success",
          showCancelButton: false,
          confirmButtonText: "Ok"
        });
      } catch (e) {
        console.log(e);
        Swal.fire({
          icon: "error",
          title: "Pedido",
          text: "Não foi possível pré-postar o objeto."
        });
      }
    },
    [api]
  );

  const handlePrintShippingsInTable = id => {
    window.localStorage.setItem("Brcom:Etiquetas", JSON.stringify([id]));
    window.open("/etiquetas", "_blank");
  };

  const handleDetailsShipping = id => {
    window.open(`/detalhe-coleta/${id}`);
  };

  const handleCancelShipping = useCallback(shipping => {
    setShowModal(false);
    setShowCancelShippingModal(true);
    setShippingInModal(shipping);
  }, []);

  const closeCancelShippingModal = () => {
    setShowCancelShippingModal(false);
    setShowModal(true);
  };

  const renderTableWithPackageInfo = useCallback(
    collectDetails => {
      const flattenedCollectStages = flatten(ShippingStagesEnum);

      if (windowWidth <= 1025) {
        return collectDetails?.shippings.map(row => (
          <CardCollectDetails
            key={row.id}
            shipping={row}
            collectStages={flattenedCollectStages}
          />
        ));
      }

      return (
        <Table
          size="small"
          style={{
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.10)",
            borderTopLeftRadius: "10px",
            borderTopRightRadius: "10px"
          }}
          aria-label="Tabela com informações dos pacotes."
        >
          <StyledTableHead>
            <TableRow>
              <StyledTableCell
                style={{
                  borderTopLeftRadius: "10px"
                }}
              >
                {collectDetails?.shippings?.[0]?.authorizationCode !== null
                  ? "Autorização"
                  : "Identificação"}
              </StyledTableCell>
              <StyledTableCell>Origem</StyledTableCell>
              <StyledTableCell>Destino</StyledTableCell>
              <StyledTableCell>Valor Frete</StyledTableCell>
              <StyledTableCell>Valor Seguro</StyledTableCell>
              <StyledTableCell>Dimensões</StyledTableCell>
              <StyledTableCell>Peso</StyledTableCell>
              <StyledTableCell>Status</StyledTableCell>
              <StyledTableCell
                style={{
                  borderTopRightRadius: "10px"
                }}
              >
                Ações
              </StyledTableCell>
            </TableRow>
          </StyledTableHead>
          <TableBody
            style={{
              maxHeight: 450,
              overflow: "auto"
            }}
          >
            {collectDetails?.shippings?.map(row => (
              <StyledTableRow
                key={row.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                style={{
                  backgroundColor: row.blocked
                    ? "rgb(237, 120, 120, 0.2)"
                    : row.prePostadoObject?.erro === "1" &&
                      user.profile === "admin" &&
                      "rgb(245, 203, 43, 0.2)",
                  textAlign: "center"
                }}
              >
                <StyledTableCell component="th" scope="row">
                  <Link
                    to={`/detalhes-objeto/${row.id}`}
                    style={{
                      borderBottom: "1px solid #409E6A",
                      color: "#409E6A",
                      fontWeight: 700
                    }}
                  >
                    {row.authorizationCode !== null
                      ? row.authorizationCode
                      : row.sro}
                  </Link>
                </StyledTableCell>
                <StyledTableCell align="center">
                  {`${formatCep(row?.origin?.zip)}`}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {`${formatCep(row?.destination?.zip)}`}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {new Intl.NumberFormat("pt-BR", {
                    style: "currency",
                    currency: "BRL"
                  }).format(
                    Number(row.price) - Number(row.insuranceValue) || row.price
                  )}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {new Intl.NumberFormat("pt-BR", {
                    style: "currency",
                    currency: "BRL"
                  }).format(row.insuranceValue)}
                </StyledTableCell>
                <StyledTableCell align="center">{`${row.height}x${row.width}x${row.length}cm`}</StyledTableCell>
                <StyledTableCell align="center">
                  {row.weight}(kg)
                </StyledTableCell>
                <StyledTableCell align="center">
                  <span
                    className={`${styles.statusCollect} ${
                      row.idShippingStatus === 1
                        ? styles.gray
                        : row.idShippingStatus <= 7 ||
                          row.idShippingStatus === 17
                        ? styles.going
                        : row.idShippingStatus <= 10 &&
                          row.idShippingStatus !== 17
                        ? styles.delivered
                        : styles.error
                    }`}
                  >
                    {row?.status.name === "Bloqueio Solicitado"
                      ? row.status.name
                      : Object.entries(flattenedCollectStages).map(
                          ([key, value]) => {
                            if (Number(row.idShippingStatus) === value) {
                              const stageItemsSplitted = key.split(".");
                              stageItemsSplitted.pop();

                              return flattenedCollectStages[
                                `${stageItemsSplitted.join(".")}.message`
                              ];
                            }
                          }
                        )}
                  </span>
                </StyledTableCell>

                <StyledTableCell align="center">
                  <ActionsMenu
                    shipping={row}
                    setFunction={setCollectDetail}
                    setLoading={setLoading}
                  />
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      );
    },
    [user.profile, windowWidth]
  );

  const modalDetailsOfCollect = collectDetails => {
    const body = renderTableWithPackageInfo(collectDetails);

    return (
      <Modal
        size="xl"
        show={true}
        centered
        aria-labelledby="contained-modal-title-vcenter"
        onHide={() => {
          setCollectDetail(null);
          setShowModal(false);
        }}
      >
        <Modal.Header style={{ borderBottom: 0, padding: "32px 32px 0" }}>
          <Modal.Title
            style={{
              width: "100%",
              color: "#409E6A",
              fontFamily: "Mulish",
              fontSize: 24,
              fontWeight: "700",
              borderBottom: "1px solid #C0C0C0"
            }}
          >
            Detalhes - Pedido nº {collectDetails.id}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body style={{ padding: "24px 32px" }}>
          <div
            id={"scrollContainerShippings"}
            className={`${styles.modalBody} ${
              collectDetails?.shippings.length > 7 ? styles.overflowActive : ""
            }`}
          >
            <InfiniteScroll
              dataLength={collectDetails?.shippings}
              next={handleLoadMoreShippings}
              hasMore={hasMore}
              loader={<LoadInfiniteScroll />}
              scrollableTarget="scrollContainerShippings"
            >
              {body}
            </InfiniteScroll>
          </div>
        </Modal.Body>
        <Modal.Footer style={{ borderTop: 0, paddingBottom: 32 }}>
          <button
            onClick={() => {
              setShowModal(false);
              setHasMore(true);
            }}
            className={styles.buttonModal}
          >
            Fechar
          </button>
        </Modal.Footer>
      </Modal>
    );
  };

  const schedulePickup = () => {
    return (
      <ReactModal
        isOpen={showScheduleModal}
        dialogClassName="schedule-pickup-modal"
        onRequestClose={() => setShowScheduleModal(false)}
        style={{
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)"
          }
        }}
      >
        <DateFilter
          scheduleCollectsIds={scheduleCollectsIds}
          setShowModal={setShowScheduleModal}
        />
      </ReactModal>
    );
  };

  const confirmCancelShipping = async collectSelected => {
    try {
      setLoading(true);
      collectSelected.forEach(async shipping => {
        await api.makeHttpRequest({
          url: `/collects/${shipping.id}/cancel`,
          method: "DELETE"
        });
      });

      Swal.fire({
        title: "Sucesso",
        text: "A solicitação de cancelamento foi feita com sucesso",
        icon: "success",
        confirmButtonColor: "#409e6a",
        preConfirm() {
          setCancelScheduleModal(false);
        }
      });
    } catch (e) {
      console.log(e);
      Swal.fire({
        title: "Erro",
        text:
          e.response.data.message ||
          "Um erro ocorreu ao tentar cancelar o objeto",
        icon: "error",
        confirmButtonColor: "#409e6a",
        preConfirm() {
          setCancelScheduleModal(false);
        }
      });
    } finally {
      setLoading(false);
    }
  };

  const scheduleCancel = collectSelected => {
    return (
      <ReactModal
        isOpen={cancelScheduleModal}
        dialogClassName="schedule-pickup-modal"
        onRequestClose={() => setCancelScheduleModal(false)}
        style={{
          overlay: {
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(51, 51, 51, 0.75)",
            zIndex: 999
          },
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            // marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            zIndex: 999,
            border: "none",
            backgroundColor: "transparent",
            padding: 0
          }
        }}
      >
        <ModalContainer>
          <AiOutlineWarning size={60} color="#409e6a" />
          <p style={{ width: "75%" }}>
            Deseja cancelar este pedido por completo?
          </p>
          <p style={{ width: "85%" }}>
            Confirmando esta ação{" "}
            <strong>
              todas as etiquetas do pedido serão canceladas de forma
              irreversível
            </strong>
            . Deseja continuar?
          </p>
          <ModalButtonsContainer>
            <button onClick={() => setCancelScheduleModal(false)}>
              Voltar
            </button>
            <button
              onClick={() => {
                confirmCancelShipping(collectSelected);
              }}
            >
              Confirmar
            </button>
          </ModalButtonsContainer>
        </ModalContainer>
      </ReactModal>
    );
  };

  const handleFetchData = useCallback(async () => {
    try {
      if (actualPage === nPages) {
        return;
      }

      const response = await api.makeHttpRequest({
        method: "GET",
        url: `/collects/list`,
        params: {
          resultsPerPage: 12,
          page: actualPage + 1,
          initialDate: periodInitialDate !== "" ? periodInitialDate : undefined,
          finalDate: periodFinalDate !== "" ? periodFinalDate : undefined,
          status: selectedStatusInFilter?.value,
          ...(searchClient && { text: searchClient?.label })
        }
      });
      setAllCollects([...allCollects, ...response.results]);
      setActualPage(Number(response.actualPage));
      setNPages(Number(response.nPages));
    } catch (e) {
      console.log(e);
      Swal.fire({
        icon: "error",
        title: "Pedidos",
        text: "Não foi possível carregar os pedidos."
      });
    }
  }, [
    actualPage,
    nPages,
    api,
    periodInitialDate,
    periodFinalDate,
    selectedStatusInFilter.value,
    allCollects,
    searchClient
  ]);

  useEffect(() => {
    window.setPageTitle("Meus pedidos");
  }, []);

  useEffect(() => {
    debounce(async () => {
      try {
        setLoading(true);
        const response = await api.makeHttpRequest({
          method: "GET",
          url: `/collects/list`,
          params: {
            resultsPerPage: 12,
            page: 1,
            status: selectedStatusInFilter?.value,
            initialDate:
              periodInitialDate !== "" ? periodInitialDate : undefined,
            finalDate: periodFinalDate !== "" ? periodFinalDate : undefined,
            ...(searchClient && { text: searchClient?.label })
          }
        });

        setAllCollects(response.results);
        setActualPage(Number(response.actualPage));
        setNPages(Number(response.nPages));
      } catch (e) {
        console.error(e);
        Swal.fire({
          icon: "error",
          title: "Pedidos",
          text: "Não foi possível carregar os pedidos."
        });
      } finally {
        setLoading(false);
      }
    }, 1000)();
  }, [
    selectedStatusInFilter,
    periodInitialDate,
    periodFinalDate,
    searchClient
  ]);

  const handleOpenScheduleModal = () => {
    setShowScheduleModal(true);
    setScheduleCollectsIds(collectSelected.map(collect => collect.id));
  };

  const handleCancelScheduleModal = () => {
    setCancelScheduleModal(true);
  };

  return (
    <div className={styles.main}>
      <Loading loading={loading} />
      <Container title="Meus Pedidos">
        <Row className="row-cols-1 row-cols-sm-3 g-2 g-lg-3 mr-0 ml-0 w-100">
          {(isAdmin || isManager) && (
            <Col className={`${styles.inputContainerSmall}`}>
              <InputSelectAsync
                search={searchClient}
                setSearch={setSearchClient}
              />
            </Col>
          )}
          <Col className={`${styles.inputContainerSmall}`}>
            <div className={styles.input}>
              <DatePicker
                className={"datePickerConfig"}
                change={handleOnChangeCalendarPeriod}
                placeholder="Filtrar por período"
                showClearButton={!!periodInitialDate && !!periodFinalDate}
                onClearFields={handleClearPeriodFilter}
                range
              />
            </div>
          </Col>
          <Col
            className={`${styles.selectInput} ${styles.inputContainerSmall}`}
          >
            <Select
              onChange={handleChangeSelectFilterByStatus}
              options={getOptionsStagesCollect}
              value={
                selectedStatusInFilter ||
                getOptionsStagesCollect.find(item => item.value === "all")
              }
              placeholder="Selecione o tipo de pacote"
              styles={selectStyle}
            />
          </Col>
        </Row>
        <Col className={`col ${styles.leftContainer}`}>
          <ScrollDiv data={allCollects}>
            <InfiniteScroll
              dataLength={allCollects.length}
              next={handleFetchData}
              hasMore={nPages === 0 ? false : !(actualPage === nPages)}
              loader={<LoadInfiniteScroll />}
              scrollableTarget="scrollDiv"
            >
              <div className={styles.collectWrapper}>
                {allCollects.length > 0 &&
                  allCollects.map(collect => {
                    return (
                      <CardCollectComponent
                        key={collect.id}
                        showModalDetails={() => handleModalOpenDetails(collect)}
                        setShowScheduleModal={setShowScheduleModal}
                        handleCheck={(collectActual, statusCollect) =>
                          handleCheckedItemCard(collectActual, statusCollect)
                        }
                        isChecked={collectSelected.some(
                          collectItem => collectItem.id === collect.id
                        )}
                        collect={collect}
                        setIds={setScheduleCollectsIds}
                        ids={scheduleCollectsIds}
                      />
                    );
                  })}
              </div>
            </InfiniteScroll>
          </ScrollDiv>
        </Col>
      </Container>

      {!!showModal && !!collectDetail && modalDetailsOfCollect(collectDetail)}
      {showScheduleModal && schedulePickup()}
      {cancelScheduleModal && scheduleCancel(collectSelected)}
      <ReactModal
        isOpen={showCancelShippingModal}
        // dialogClassName="schedule-pickup-modal"
        onRequestClose={() => closeCancelShippingModal()}
        style={{
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            // marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            zIndex: 999
          }
        }}
      >
        <CancelShipping
          shipping={shippingInModal}
          setLoading={setLoading}
          setShowCancelShippingModal={closeCancelShippingModal}
          setFunction={setCollectDetail}
        />
      </ReactModal>
    </div>
  );
};

export { Collect };
