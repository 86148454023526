import React, { useState } from "react";
import { BrComerceApiService } from "../../../../services/BrComerceApiService";
import { Spinner, Col, Row } from "react-bootstrap";
import { makeStyles } from "@material-ui/core/styles/";
import { Formik } from "formik";
import { injectIntl } from "react-intl";
import { IsValidPassword } from "../../../../utils/validators/IsValidPassword";
import { TextField } from "@material-ui/core";
import * as auth from "../../../../store/ducks/auth.duck";
import { connect } from "react-redux";

import "./style.scss";

function ModalAlterPassword(props) {
  const { closeModal, result } = props;
  const api = new BrComerceApiService();
  const [loading, setLoading] = useState("");
  const { intl } = props;

  const useStyles = makeStyles(theme => ({
    modalBody: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center"
    },
    message: {
      fontSize: "1rem"
    },
    spinnerBorder: {
      width: "2rem",
      height: "2rem"
    }
  }));

  const classes = useStyles();

  return (
    <>
      <div className="container-modal">
        <span className="title">Alterar Senha</span>
        {loading ? (
          <div className={classes.modalBody}>
            <Row className="show-grid">
              <Col xs={12} md={12}>
                <h3>Alterando senha...'</h3>
              </Col>
            </Row>
            <Row className="show-grid">
              <Spinner
                animation="border"
                className={classes.spinnerBorder}
                size="xs"
              />
            </Row>
          </div>
        ) : null}
        <Formik
          initialValues={{
            password: "",
            confirmPassword: "",
            newPassword: ""
          }}
          validate={values => {
            const errors = {};

            if (!values.password) {
              errors.password = intl.formatMessage({
                id: "AUTH.VALIDATION.REQUIRED_FIELD"
              });
            }

            if (!values.newPassword) {
              errors.newPassword = intl.formatMessage({
                id: "AUTH.VALIDATION.REQUIRED_FIELD"
              });
            } else if (IsValidPassword(values.newPassword) !== true) {
              errors.newPassword = "Senha não possui os requisitos mínimos:";
              IsValidPassword(values.newPassword).ERROS_FOUND.map(item => {
                errors.newPassword +=
                  " /" +
                  intl.formatMessage({
                    id: "AUTH.VALIDATION." + item
                  });
                return errors.newPassword;
              });
            }

            if (!values.confirmPassword) {
              errors.confirmPassword = intl.formatMessage({
                id: "AUTH.VALIDATION.REQUIRED_FIELD"
              });
            } else if (values.newPassword !== values.confirmPassword) {
              errors.confirmPassword =
                "Senha e confirmação de senha não conferem.";
            }

            return errors;
          }}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            setLoading(true);
            const reqBase = { method: "POST", url: "/users/change-password" };
            try {
              setLoading(true);
              const response = await api.makeHttpRequest({
                ...reqBase,
                data: {
                  email: props.email,
                  password: values.password,
                  newPassword: values.newPassword
                }
              });
              if (response) {
                setLoading(false);
                setSubmitting(false);
                resetForm({});
                result(true, "");
              }
            } catch (error) {
              setLoading(false);
              setSubmitting(false);
              if (error.response && error.response.data) {
                result(false, error.response.data);
              } else {
                result(false, "Falha ao alterar a senha");
              }
            }
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting
          }) => (
            <form
              className="col-12"
              onSubmit={handleSubmit}
              noValidate
              autoComplete="off"
            >
              <div className="form-group mb-0">
                <TextField
                  type="password"
                  margin="normal"
                  label="Senha Atual"
                  className="input-modal-update-password"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.password}
                  helperText={touched.password && errors.password}
                  error={Boolean(touched.password && errors.password)}
                />
              </div>

              <div className="form-group mb-0">
                <TextField
                  type="password"
                  margin="normal"
                  label="Nova Senha"
                  className="kt-width-full input-modal-update-password"
                  name="newPassword"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.newPassword}
                  helperText={touched.newPassword && errors.newPassword}
                  error={Boolean(touched.newPassword && errors.newPassword)}
                />
              </div>

              <div className="form-group mb-0">
                <TextField
                  type="password"
                  margin="normal"
                  label="Confirme a Senha"
                  className="kt-width-full input-modal-update-password"
                  name="confirmPassword"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.confirmPassword}
                  helperText={touched.confirmPassword && errors.confirmPassword}
                  error={Boolean(
                    touched.confirmPassword && errors.confirmPassword
                  )}
                />
              </div>
              <div className="d-flex justify-content-between pt-2" style={{marginTop: "18px"}}>
                <button
                  onClick={closeModal}
                  type="button"
                  className="btn btn-secondary"
                >
                  Cancelar
                </button>

                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="btn btn-primary text-light"
                >
                  Alterar
                </button>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </>
  );
}

const mapStoreProps = store => ({
  email: store.auth.user.email
});

export default injectIntl(
  connect(mapStoreProps, auth.actions)(ModalAlterPassword)
);
