import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import {
  Button,
  makeStyles,
  Modal,
  Backdrop,
  Fade,
  Menu,
  MenuItem
} from "@material-ui/core";

import useGetWindowWidth from "../../utils/useGetWindowWidth";
import WindowSize from "../../constants/WindowSize";
import logoBrcomerce from "../../assets/logo/logo-brcomerce.svg"
import iconMenu from "../../assets/icons/icon-menu.svg";
import iconClose from "../../assets/icons/icon-close.svg";
import store from "../../store/store";
import UserProfile from "../../../app/partials/layout/UserProfile";
import { Loading } from "../Loading";
import { RiLoginBoxLine, RiLogoutBoxRLine } from "react-icons/ri";

const Header = ({ home }) => {
  const { auth } = store.getState();
  const [showModal, setShowModal] = useState(false);
  const width = useGetWindowWidth();
  const [anchorEl, setAnchorEl] = useState(null);
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const useStyles = makeStyles({
    root: {
      borderRadius: 3,
      minWidth: 0,
      padding: 4
    },
    modal: {
      display: "block",
      position: "absolute",
      zIndex: 1,
      marginTop: width > WindowSize.MD ? 114 : 82,
      width: width > WindowSize.MD ? "30%" : "80%"
    },
    menu: {
      marginLeft: 26,
      marginTop: 10
    },
    menuItem: {
      width: 210,
      marginRight: 8,
      marginLeft: 8,
      color: "#6d6d6d",
      fontFamily: "Mulish, sans-serif"
    }
  });

  const classes = useStyles();

  const handleClick = event => {
    setAnchorEl(event?.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div id="header" className="container-header">
      <Link to="/home">
        <img src={logoBrcomerce} className="logo-header" alt="Logo Brcomerce" />
      </Link>
      {width >= WindowSize.MD && (
        <ul>
          <li>
            {home ? <a href="/home">Home</a> : <Link to="/home">Home</Link>}
          </li>
          <li>
            {home ? (
              <a href="/#">Quem Somos</a>
            ) : (
              <Link to="/#">Quem somos</Link>
            )}
          </li>
          <li>
            {home ? (
              <a href="/#">Integrações</a>
            ) : (
              <Link to="/#">Integrações</Link>
            )}
          </li>
          <li>
            <Link to="/#">Contato</Link>
          </li>
          <li>
            <Link to="/#">Como podemos te ajudar?</Link>
          </li>
          {auth.user ? (
            <li>
              <UserProfile showBadge={true} loading={loading} />
            </li>
          ) : (
            <li>
              <Link to="/auth">
                <div className="login-btn">
                  <span>Entrar</span>
                </div>
              </Link>
            </li>
          )}
        </ul>
      )}
      {/* Remover a validação da linha 77 após implementar a Área do Cliente */}
      {width < WindowSize.MD && (
        <Button
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={event =>
            width >= WindowSize.MD
              ? handleClick(event)
              : setShowModal(!showModal)
          }
          className={classes.root}
        >
          <img src={iconMenu} alt="Ícone Menu" />
        </Button>
      )}
      <Menu
        className={classes.menu}
        getContentAnchorEl={null}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          className={classes.menuItem}
          onClick={() => history.push("/auth")}
        >
          Acesso interno
        </MenuItem>
      </Menu>
      <Modal
        closeAfterTransition
        BackdropProps={{
          style: { marginTop: width > WindowSize.MD ? 114 : 82 },
          timeout: 500
        }}
        BackdropComponent={Backdrop}
        className={classes.modal}
        onClose={() => setShowModal(false)}
        open={showModal}
      >
        <Fade in={showModal}>
          <div className="container-content-modal">
            <ul>
              <li>
                <Button
                  onClick={() => setShowModal(false)}
                  className={classes.root}
                >
                  <img src={iconClose} alt="Ícone fechar menu" />
                </Button>
              </li>
              <li>
                {home ? (
                  <a href="/#" onClick={() => setShowModal(false)}>
                    Home
                  </a>
                ) : (
                  <Link to="/#">Home</Link>
                )}
              </li>
              <li>
                {home ? (
                  <a href="/#" onClick={() => setShowModal(false)}>
                    Quem somos
                  </a>
                ) : (
                  <Link to="/#">Quem somos</Link>
                )}
              </li>
              <li>
                {home ? (
                  <a href="/#" onClick={() => setShowModal(false)}>
                    Integrações
                  </a>
                ) : (
                  <Link to="/#">Integrações</Link>
                )}
              </li>
              <li>
                {home ? (
                  <a href="/#" onClick={() => setShowModal(false)}>
                    Contato
                  </a>
                ) : (
                  <Link to="/#">Contato</Link>
                )}
              </li>
              <li>
                {home ? (
                  <a href="/#" onClick={() => setShowModal(false)}>
                    Como podemos te ajudar?
                  </a>
                ) : (
                  <Link to="/#">Como podemos te ajudar?</Link>
                )}
              </li>
            </ul>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

export default Header;
