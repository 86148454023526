import React from "react";
const TextTerms = () => {
    return (
        <>
            <p>Prezado (a) Usuário (a)</p>
            <p>{"A BR COM é uma transportadora focada na realização de envios de encomendas. Atualmente, realizamos envios para todos os municípios do Brasil, e nosso objetivo é atender o cliente Pessoa Jurídica."}</p>
            <p>{"Nós fazemos a coleta na data e endereço agendados por você."}</p>
            <p>{"Atualmente, temos à disposição integrações sistêmicas com as principais plataformas e ERPs."}</p>
            <p>{"Os presentes Termos de Uso vinculam todas as atividades desenvolvidas e serviços oferecidos por meio da plataforma da BR COM, que engloba o software, o website e seus subdomínios, aplicativos, programas e demais extensões. A plataforma BR COM é de propriedade da BR COMERCE LOGISTICAS LTDA, inscrita no CPNJ sob o nº 40.572.442/0001-27, situada à Praia de Botafogo, nº 501, Bloco I, sala 101, Botafogo – Rio de Janeiro, CEP 22250-911."}</p>
            <p>{`Para utilizar os serviços da nossa Plataforma, você deverá concordar e aceitar os Termos e Condições Gerais de Uso.`}</p>

            <p>
                <b>Cláusula 1ª:</b>
                {` A BR COM é uma transportadora que fornece aos Usuários funcionalidades que permitem a contratação dos serviços de transporte para o envio de encomendas, gerenciamento da logística e o devido acompanhamento, para a realização de seus envios.`}
            </p>
            <p>
                <b>1.1:</b>
                {` O resultado da BR COM se dará mediante a cobrança da prestação do serviço de transporte, variando de acordo com o peso do envio, dimensões do objeto e endereço de destino para entrega. `}
            </p>

            <p>
                <b>Cláusula 2ª:</b>
                {` Para utilizarem a Plataforma BR COM, os Usuários ficam responsáveis pelo fornecimento de alguns dados, tais como: (i) nome do Usuário; (ii) e-mail do Usuário; (iii) endereço de coleta com CEP; (iv) endereço de destino com CEP; (v) dimensões do objeto; (vi) peso do objeto, (vii) descrição detalhada do produto, dentre outros.`}
            </p>
            <p>
                <b>2.1:</b>
                {` Eventual inconsistência nos dados acima listados, especialmente no que se refere às dimensões e peso do objeto, acarretará saldo devedor ou saldo credor ao Usuário, da seguinte forma:`}
            </p>
            <p>
                <b>2.1.1:</b>
                {` Se a diferença nos dados resultar em saldo devedor, a Plataforma BR COM fica autorizada a cobrar do Usuário, gerando um débito em seu cadastro;`}
            </p>
            <p>
                <b>2.1.2:</b>
                {` Se a diferença nos dados resultar em saldo credor, o Usuário poderá utilizar o crédito em utilização futura na Plataforma da BR COM.`}
            </p>
            <p>
                <b>2.2:</b>
                {` Em caso de informações incorretas, inverídicas ou não confirmadas, será enviada notificação ao Usuário, permitindo sua correção em tempo hábil, definido. Não havendo correção, ou envio de documentos que comprovem tal veracidade, a Plataforma BR COM reserva-se o direito de não concluir o cadastramento em curso ou, ainda, de bloquear o cadastro já existente, impedindo o Usuário de utilizar a plataforma até que a situação de anomalia seja regularizada.`}
            </p>
            <p>
                <b>2.3:</b>
                {` Nos casos em que a BR COM for responsável pela coleta, será de responsabilidade do Usuário fornecer neste ato o romaneio detalhado dos objetos coletados.`}
            </p>

            <p>
                <b>Cláusula 3ª:</b>
                {` São as responsabilidades da BR COM:`}
            </p>
            <p>
                <b>3.1:</b>
                {` Fornecer ao Usuário as cotações em tempo real dos serviços que atendam aos CEPs informados;`}
            </p>
            <p>
                <b>3.2:</b>
                {` Fornecer ao Usuário a prestação do serviço de transporte pelo preço cotado, a partir das informações coletadas, sendo de inteira responsabilidade do Usuário a exatidão das informações fornecidas;`}
            </p>
            <p>
                <b>3.3:</b>
                {` Zelar pela integridade da BR COM, fiscalizando a utilização pelos usuários, banindo ou bloqueando aqueles que apresentem qualquer risco de fraude. `}
            </p>
            <p>
                <b>3.4:</b>
                {` Proteger a confidencialidade de todas as informações dos Usuários, contudo, não responderá pela reparação de prejuízos que possam ser derivados de apreensão de dados por terceiros que, rompendo os sistemas de segurança da Plataforma, consigam acessar essas informações.`}
            </p>

            <p>
                <b>Cláusula 4ª:</b>
                {` São os deveres dos Usuários:`}
            </p>
            <p>
                <b>4.1:</b>
                {` Responsabilizar-se pela legalidade do objeto enviado.`}
            </p>
            <p>
                <b>4.2:</b>
                {` Responsabilizar-se pelo correto fornecimento dos dados tratados na cláusula 2ª;`}
            </p>
            <p>
                <b>4.3:</b>
                {` Pagar antecipadamente a totalidade do preço cotado na BR COM, de acordo com as modalidades disponibilizadas na plataforma.`}
            </p>
            <p>
                <b>4.4:</b>
                {` Respeitar os termos de uso fornecidos pela Plataforma BR COM.`}
            </p>
            <p>
                <b>4.5:</b>
                {` Utilizar seu cadastro de forma individual e intransferível, devendo a Plataforma BR COM ser imediatamente notificada sobre qualquer uso não autorizado na conta do Usuário ou qualquer outra violação identificada, não sendo a Plataforma BR COM responsável por quaisquer prejuízos ou danos oriundos de uso de login e senha por terceiros, com ou sem o consentimento do Usuário.`}
            </p>
            <p>
                <b>4.6:</b>
                {` Informar à BR COM qualquer problema na entrega dos objetos enviados, para que esta possa iniciar um procedimento de verificação apropriado.`}
            </p>
            <p>
                <b>4.7:</b>
                {` O Usuário declara, ao utilizar a Plataforma BR COM, que detém plena capacidade civil, podendo celebrar contrato e assumir obrigações, ficando ciente, desde já, que as informações cadastrais falsas ou inverídicas registradas ou fornecidas durante o processo eletrônico de contratação de quaisquer serviços constitui crime, nos termos do artigo 299 do Código Penal Brasileiro.`}
            </p>

            <p>
                <b>Cláusula 5ª:</b>
                {` Fica estabelecido que a BR COM poderá limitar o envio simultâneo de objetos aos Usuários, de acordo com o algoritmo de segurança da Plataforma.`}
            </p>
            <p>
                <b>5.1:</b>
                {` Os Usuários poderão solicitar à BR COM a análise de majoração dos limites de envios simultâneos, cabendo à BR COM a aprovação do requerimento.`}
            </p>

            <p>
                <b>Cláusula 6ª:</b>
                {` A BR COM na qualidade de transportadora se responsabiliza por eventuais atrasos ou danos que ocorram na entrega dos objetos, somente na hipótese de contratação adicional de seguro para o respectivo envio da encomenda.`}
            </p>
            <p>
                <b>6.1:</b>
                {` O pagamento de indenizações em caso de extravios, roubo ou qualquer infortúnio é vinculado exclusivamente à contratação adicional do valor de seguro.`}
            </p>
            <p>
                <b>6.2:</b>
                {` Em eventual deferimento de indenização, a BR COM realizará o lançamento de crédito, no valor da indenização, em forma de repasse no cadastro do Usuário.`}
            </p>
            <p>
                <b>6.3:</b>
                {` A Plataforma BR COM estará obrigada a repassar os valores mencionados no item anterior somente após o devido processo de averiguação da ocorrência e o deferimento da indenização.`}
            </p>

            <p>
                <b>Cláusula 7ª:</b>
                {` A BR COM reserva-se o direito de, em qualquer momento, modificar os Termos de Uso, ou mesmo alterar ou modificar a aparência ou o funcionamento de seu sistema, sem necessidade de avisos prévios e sem qualquer responsabilização perante os Usuários.`}
            </p>
            <p>
                <b>7.1:</b>
                {` Quaisquer modificações efetuadas pela BR COM na Plataforma entrarão em vigor imediatamente após sua publicação online, sendo dispensada a obrigatoriedade de envio de comunicação ao Usuário.`}
            </p>
            <p>
                <b>7.2:</b>
                {` Se qualquer alteração for inaceitável para o Usuário, sua única opção é a desativação de seu cadastro e além da não mais utilização da Plataforma.`}
            </p>
            <p>
                <b>7.3:</b>
                {` A BR COM reserva-se o direito de modificar, suspender e descontinuar, temporária ou definitivamente os serviços que presta, sem necessidade de prévia comunicação aos Usuários.`}
            </p>

            <p>
                <b>Cláusula 8ª:</b>
                {` Os Usuários, bem como a Plataforma BR COM declaram que cumprem, em sua totalidade, todas as disposições e princípios previstos no Marco Civil da Internet, Lei nº 12.965 de 2014, que trata dos direitos e deveres na utilização da internet no Brasil.`}
            </p>
            <p>
                <b>Cláusula 9ª:</b>
                {` Ao Usuário é vedado acessar as áreas de programação da Plataforma BR COM, seu banco de dados ou qualquer outro conjunto de informações da plataforma, bem como realizar qualquer tipo de engenharia reversa, tradução, cópia, modificação, reprodução, locação, publicação, divulgação, transmissão das funcionalidades da plataforma sem a prévia e expressa autorização da BR COMERCE LOGISTICAS LTDA, ficando sujeito quem o fizer às penalidades da legislação brasileira, sem prejuízo da obrigação de reparar os danos que causarem.`}
            </p>
            <p>
                <b>9.1:</b>
                {` Todos os direitos são preservados, conforme a legislação brasileira, principalmente na Lei de Direitos Autorais (regulamentada na Lei nº 9.610/18), assim como no Código Civil brasileiro (regulamentada na Lei nº 10.406/02), ou quaisquer outras legislações aplicáveis.`}
            </p>

            <p>
                <b>Cláusula 10ª:</b>
                {` Na eventualidade de descumprimento por parte do Usuário de quaisquer das cláusulas acima descritas, a BR COM pode, a qualquer momento, sem aviso prévio, interromper ou suspender, temporária ou permanentemente, o acesso do Usuário à plataforma.`}
            </p>

            <p>
                <b>Cláusula 11ª:</b>
                {` A Plataforma BR COM se reserva o direito de comunicar informação sobre os Usuários da plataforma a entidades públicas, quando houver motivos suficientes para considerar que a atividade de um Usuário seja potencialmente ilícita ou prejudicial a si ou outras pessoas. Este direito será utilizado pela Plataforma BR COM nos termos previstos na legislação brasileira, quando considerar apropriado ou necessário para manter a integridade e a segurança da Plataforma BR COM e de seus Usuários, e para fazer cumprir os presentes Termos de Uso.`}
            </p>

            <p>
                <b>Cláusula 12ª:</b>
                {` Os Termos de Uso terão início de vigência perante cada Usuário no primeiro acesso do mesmo à Plataforma BR COM e irão cessar quando este cadastro for desativado, permanecendo as responsabilidades pelo período de utilização.`}
            </p>

            <p>
                <b>Cláusula 13ª:</b>
                {` A Plataforma BR COM pode alugar ou vender espaços publicitários na plataforma, ou no site, diretamente aos anunciantes, ou através de empresas especializadas com o adsense.`}
            </p>
            <p>
                <b>13.1</b>
                {` As publicidades não significam nenhuma forma de endosso ou responsabilidade da Plataforma BR COM pelos mesmos, ficando o Usuário responsável pelas compras, visitas, acessos ou quaisquer ações referentes as estas empresas.`}
            </p>

            <p>
                <b>Cláusula 14ª:</b>
                {` Os presentes Termos de Uso são regidos pelas leis brasileiras, e fica definido o foro da Comarca da Capital do Rio de Janeiro como competente para dirimir eventuais controvérsias oriundas destes, em prejuízo de qualquer outro, por mais privilegiado que seja ou venha a ser.`}
            </p>

            <p>Atualizado em 25 de julho de 2024</p>
        </>
    )
}
export default TextTerms;
