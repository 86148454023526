const statusCollect = {
  AGUARDANDO_PAGAMENTO: {
    id: 1,
    message: "Aguardando pagamento"
  },
  PAGAMENTO: {
    PAGAMENTO_APROVADO: {
      id: 2,
      message: "Pagamento aprovado"
    },
    PAGAMENTO_NEGADO: {
      id: 3,
      message: "Pagamento reprovado"
    }
  },
  AGENDAMENTO: {
    AGUARDANDO_AGENDAMENTO: {
      id: 5,
      message: "Aguardando agendamento"
    },
    COLETA_AGENDADA: {
      id: 6,
      message: "Pedido agendado"
    }
  },
  ETIQUETAS_DISPONIVEIS: {
    id: 4,
    message: "Etiquetas disponíveis"
  },
  ESTORNO: {
    ESTORNO_PROCESSANDO: {
      id: 9,
      message: "Processando estorno"
    },
    ESTORNO_CONCLUIDO: {
      id: 10,
      message: "Estorno concluído"
    },
  },
  COLETA: {
    COLETA_CONCLUIDA: {
      id: 7,
      message: "Pedido concluído"
    },
    COLETA_CANCELADA: {
      id: 8,
      message: "Pedido cancelado"
    },
  },
};

export default Object.freeze({
  ...statusCollect
});
