import React, { useCallback, useState, useEffect } from "react";
import { AiOutlineCheck } from "react-icons/ai";
import { BsExclamation } from "react-icons/bs";
import { useHistory } from "react-router-dom";
import { BrComerceApiService } from "../../../../../services/BrComerceApiService";
import { cnpjValidation } from "../../../../../utils/CnpjValidation";
import styles from "./styles.module.scss";
import Loading from "../../../../home/components/Loading";
import { connect } from "react-redux";
import { StatusModal } from "../../../../../components/StatusModal";
import InputText from "../../../../../components/InputText";
import Swal from "sweetalert2";
import axios from "axios";

const EditAccount = props => {
  window.setPageTitle("Editar Conta");

  const api = new BrComerceApiService();
  const history = useHistory();

  const [user, setUser] = useState(null);
  const [email, setEmail] = useState("");

  const [nameOK, setNameOK] = useState("vazio");
  const [nameError, setNameError] = useState("");
  const [name, setName] = useState("");

  const [cnpjOK, setCnpjOk] = useState("vazio");
  const [cnpjError, setCnpjError] = useState("");
  const [cnpj, setCnpj] = useState("");

  const [phoneOK, setPhoneOK] = useState("vazio");
  const [phoneError, setPhoneError] = useState("");
  const [phone, setPhone] = useState("");

  const [heightOK, setHeightOK] = useState("vazio");
  const [heightError, setHeightError] = useState("");
  const [height, setHeight] = useState("");

  // const [senderNameOK, setSenderNameOK] = useState("vazio");
  // const [senderNameError, setSenderNameError] = useState("");
  // const [senderName, setSenderName] = useState("");

  const [lengthOK, setLengthOK] = useState("vazio");
  const [lengthError, setLengthError] = useState("");
  const [length, setLength] = useState("");

  const [widthOK, setWidthOK] = useState("vazio");
  const [widthError, setWidthError] = useState("");
  const [width, setWidth] = useState("");

  const [loading, setLoading] = useState(false);

  const handleInputName = useCallback(e => {
    let value = e.currentTarget.value;
    setName(value);
    value.length < 2 ? setNameOK("notOk") : setNameOK("ok");
    if (value.length === 0) {
      setNameError("Campo obrigatório");
    } else if (value.length === 1) {
      setNameError("Nome deve ter pelo menos 2 caracteres");
    } else {
      setNameError("");
    }
  }, []);

  const handleInputCnpj = useCallback(e => {
    e.currentTarget.maxLength = 18;
    let value = e.currentTarget.value;
    value = value.replace(/\D/g, "");
    value = value.replace(
      /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
      "$1.$2.$3/$4-$5"
    );
    e.currentTarget.value = value;
    const validCnpj = cnpjValidation({
      cnpj: value,
      emptyCnpj: "Preenchimento obrigatório",
      incorrectCnpj: "Preenchimento incorreto"
    });
    setCnpjOk(validCnpj.status);
    setCnpjError(validCnpj.message);
    setCnpj(value);
  }, []);

  const handleInputPhone = useCallback(e => {
    e.currentTarget.maxLength = 15;
    let value = e.currentTarget.value;
    value = value.replace(/\D/g, "");
    if (value.length === 11) {
      value = value.replace(/^(\d{2})(\d{5})(\d{4})/, "($1) $2-$3");
    } else if (value.length === 10) {
      value = value.replace(/^(\d{2})(\d{4})(\d{4})/, "($1) $2-$3");
    }
    e.currentTarget.value = value;
    if (value.length > 0 && value.length < 14) {
      setPhoneError("Telefone incorreto");
      setPhoneOK("notOk");
    } else if (value.length === 0) {
      setPhoneError("Campo obrigatório");
      setPhoneOK("notOk");
    } else {
      setPhoneError("");
      setPhoneOK("ok");
    }
    setPhone(value);
  }, []);

  const handleInputHeight = useCallback(e => {
    const MIN_HEIGHT = 1;
    let value = e.currentTarget.value;
    setHeight(value);
    if (value < MIN_HEIGHT) {
      setHeightError(`A altura mínima é ${MIN_HEIGHT}`);
      setHeightOK("notOk");
    } else {
      setHeightOK("ok");
    }
  }, []);

  const handleInputWidth = useCallback(e => {
    const MIN_WIDTH = 10;
    let value = e.currentTarget.value;
    setWidth(value);
    if (value < MIN_WIDTH) {
      setWidthError(`A largura mínima é ${MIN_WIDTH}`);
      setWidthOK("notOk");
    } else {
      setWidthOK("ok");
    }
  }, []);

  const handleInputLength = useCallback(e => {
    const MIN_LENGTH = 15;
    let value = e.currentTarget.value;
    setLength(value);
    if (value < MIN_LENGTH) {
      setLengthError(`O comprimento mínimo é ${MIN_LENGTH}`);
      setLengthOK("notOk");
    } else {
      setLengthOK("ok");
    }
  }, []);

  // const handleInputSender = useCallback(e => {
  //   let value = e.currentTarget.value;
  //   setSenderName(value);
  //   value.length < 2 ? setSenderNameOK("notOk") : setSenderNameOK("ok");
  //   if (value.length === 0) {
  //     setSenderNameError("Campo obrigatório");
  //   } else if (value.length === 1) {
  //     setSenderNameError("Nome do remetente deve ter pelo menos 2 caracteres");
  //   } else {
  //     setSenderNameError("");
  //   }
  // }, []);

  const loadUser = async () => {
    try {
      setLoading(true);
      const user = await api.makeHttpRequest({
        method: "GET",
        url: `/users/${props.userId}`
      });

      setUser({
        email: user?.email,
        phone: user?.phone,
        name: user?.name,
        cnpj: user?.cnpj,
        canPayLater: user?.canPayLater,
        profile: user?.profile,
        isIndustrial: user?.isIndustrial,
        creditLimit: user?.creditLimit,
        prePostagemUsuario: user?.prePostagemUsuario,
        prePostagemSenha: user?.prePostagemSenha,
        senderName: user?.senderName ? user?.senderName : "",
        canCreateCollectSchedule: user?.canCreateCollectSchedule,
        isCompany: user?.isCompany || true,
      });
      setEmail(user?.email);
      setName(user?.userData?.find(attr => attr.Name === "name").Value);
      setCnpj(
        user?.userData
          ?.find(attr => attr.Name === "custom:cnpj")
          .Value.replace(
            /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
            "$1.$2.$3/$4-$5"
          )
      );
      setPhone(
        user?.userData
          ?.find(attr => attr.Name === "phone_number")
          .Value.substring(3, 20)
          .replace(/^(\d{2})(\d{5})(\d{4})/, "($1) $2-$3")
      );
      setHeight(user?.defaultPackageHeight);
      setWidth(user?.defaultPackageWidth);
      setLength(user?.defaultPackageLength);
      //setSenderName(user?.senderName ? user?.senderName : "")
    } catch {
      Swal.fire({
        title: "Erro",
        text: "Erro ao carregar usuário.",
        icon: "error"
      });
    } finally {
      setLoading(false);
    }
  };

  const handleFormSubmit = async e => {
    setLoading(true);

    e.preventDefault();

    if (name === "") {
      setNameError("Campo obrigatório");
      setNameOK("notOk");
    }

    if (cnpj === "") {
      setCnpjError("Campo obrigatório");
      setCnpjOk("notOk");
    }

    if (phone === "") {
      setPhoneError("Campo obrigatório");
      setPhoneOK("notOk");
    }

    if (height === "") {
      setHeightError("Campo obrigatório");
      setHeightOK("notOk");
    }

    if (width === "") {
      setWidthError("Campo obrigatório");
      setWidthOK("notOk");
    }

    if (length === "") {
      setLengthError("Campo obrigatório");
      setLengthOK("notOk");
    }

    // if (senderName === "") {
    //   setSenderNameError("Campo obrigatório");
    //   setSenderNameOK("notOk");
    // }

    if (
      name === "" ||
      cnpj === "" ||
      phone === "" ||
      height === "" ||
      width === "" ||
      length === "" ||
      //senderName === "" ||
      nameOK === "notOk" ||
      cnpjOK === "notOk" ||
      phoneOK === "notOk" ||
      heightOK === "notOk" ||
      widthOK === "notOk" ||
      lengthOK === "notOk"
      //senderNameOK === "notOk"
    ) {
      Swal.fire({
        title: "Validação de formulário",
        text: "Por favor valide os erros indicados",
        icon: "error"
      });
      setLoading(false);
      return;
    }

    try {
      const data = {
        ...user,
        name,
        cnpj: cnpj.replace(/\D/g, ""),
        phone: "+55" + phone.replace(/\D/g, ""),
        defaultPackageHeight: height,
        defaultPackageWidth: width,
        defaultPackageLength: length,
        //senderName
      };
      await api.makeHttpRequest({
        method: "PUT",
        url: `/users`,
        data
      });

      setLoading(false);
      const response = await Swal.fire({
        title: "Conta atualizada",
        text: "Seu cadastro foi atualizado.",
        icon: "success"
      });

      if (response?.value || response?.dismiss) {
        history.push("/dashboard");
      }
    } catch (error) {
      setLoading(false);
      Swal.fire({
        title: "Conta não atualizada",
        text: "Ocorreu um erro!",
        icon: "error"
      });
    }
  };

  useEffect(() => {
    (async () => {
      await loadUser();
    })();
  }, []);

  return (
    <>
      <Loading isLoading={loading} />
      <div className={styles.container}>
        <form className={styles.form} onSubmit={handleFormSubmit}>
          <span className={styles.formTitle}>Editar conta</span>
          <div className={styles.formContent}>
            <div className={styles.inputsContainer}>
              <div className={styles.inputContainer}>
                <InputText
                  type="text"
                  name="email"
                  label="E-mail"
                  placeholder="Digite o e-mail"
                  value={email}
                  disabled
                />
              </div>

              <div className={styles.inputContainer}>
                <InputText
                  label="Razão Social"
                  type="text"
                  id="razao_social"
                  placeholder="Empresa Ltda."
                  onChange={e => handleInputName(e)}
                  value={name}
                  isValid={
                    nameOK === "notOk"
                      ? false
                      : nameOK === "vazio"
                        ? undefined
                        : true
                  }
                  errorMessage={nameError}
                  renderIcon={
                    nameOK.toLowerCase() === "ok"
                      ? () => <AiOutlineCheck className={styles.icon} />
                      : nameOK.toLowerCase() === "notok"
                        ? () => (
                          <BsExclamation
                            className={`${styles.icon} ${styles.invalid}`}
                          />
                        )
                        : null
                  }
                />
              </div>

              <div
                className={`${styles.inputContainer} ${styles.inputContainerSmall}`}
              >
                <InputText
                  label="CNPJ"
                  type="text"
                  id="cnpj"
                  placeholder="Digite o CNPJ"
                  onChange={handleInputCnpj}
                  value={cnpj}
                  isValid={
                    cnpjOK === "notOk"
                      ? false
                      : cnpjOK === "vazio"
                        ? undefined
                        : true
                  }
                  errorMessage={cnpjError}
                  renderIcon={
                    cnpjOK.toLowerCase() === "ok"
                      ? () => <AiOutlineCheck className={styles.icon} />
                      : cnpjOK.toLowerCase() === "notok"
                        ? () => (
                          <BsExclamation
                            className={`${styles.icon} ${styles.invalid}`}
                          />
                        )
                        : null
                  }
                />
              </div>

              <div
                className={`${styles.inputContainer} ${styles.inputContainerSmall}`}
              >
                <InputText
                  label="Telefone"
                  type="text"
                  id="height"
                  placeholder="Digite o telefone"
                  onChange={handleInputPhone}
                  value={phone}
                  isValid={
                    phoneOK === "notOk"
                      ? false
                      : phoneOK === "vazio"
                        ? undefined
                        : true
                  }
                  errorMessage={phoneError}
                  renderIcon={
                    phoneOK.toLowerCase() === "ok"
                      ? () => <AiOutlineCheck className={styles.icon} />
                      : phoneOK.toLowerCase() === "notok"
                        ? () => (
                          <BsExclamation
                            className={`${styles.icon} ${styles.invalid}`}
                          />
                        )
                        : null
                  }
                />
              </div>

              {/* <div className={styles.sectionTitle}>
                <h3>
                  Informe o nome de remetente que deve constar nas etiquetas de envio geradas pela plataforma.
                </h3>
              </div>
               <div className={`${styles.inputContainer}`}>
                <InputText
                  label="Nome do Remetente"
                  type="text"
                  id="sender"
                  placeholder="Digite o nome do remetente"
                  onChange={e => handleInputSender(e)}
                  value={senderName}
                  isValid={
                    senderNameOK === "notOk"
                      ? false
                      : senderNameOK === "vazio"
                        ? undefined
                        : true
                  }
                  errorMessage={senderNameError}
                  renderIcon={
                    senderNameOK.toLowerCase() === "ok"
                      ? () => <AiOutlineCheck className={styles.icon} />
                      : senderNameOK.toLowerCase() === "notok"
                        ? () => (
                          <BsExclamation
                            className={`${styles.icon} ${styles.invalid}`}
                          />
                        )
                        : null
                  }
                />
              </div>  */}

              <div className={styles.sectionTitle}>
                <h3>Embalagens padrão</h3>
              </div>
              <div
                className={`${styles.inputContainer} ${styles.inputContainerSmall}`}
              >
                <InputText
                  label="Altura"
                  type="text"
                  id="height"
                  placeholder="Altura padrão das embalagens."
                  onChange={e => handleInputHeight(e)}
                  value={height}
                  isValid={
                    heightOK === "notOk"
                      ? false
                      : heightOK === "vazio"
                        ? undefined
                        : true
                  }
                  errorMessage={heightError}
                  renderIcon={
                    heightOK.toLowerCase() === "ok"
                      ? () => <AiOutlineCheck className={styles.icon} />
                      : heightOK.toLowerCase() === "notok"
                        ? () => (
                          <BsExclamation
                            className={`${styles.icon} ${styles.invalid}`}
                          />
                        )
                        : null
                  }
                />
              </div>

              <div
                className={`${styles.inputContainer} ${styles.inputContainerSmall}`}
              >
                <InputText
                  label="Largura"
                  type="text"
                  id="width"
                  placeholder="Largura padrão das embalagens."
                  onChange={e => handleInputWidth(e)}
                  value={width}
                  isValid={
                    widthOK === "notOk"
                      ? false
                      : widthOK === "vazio"
                        ? undefined
                        : true
                  }
                  errorMessage={widthError}
                  renderIcon={
                    widthOK.toLowerCase() === "ok"
                      ? () => <AiOutlineCheck className={styles.icon} />
                      : widthOK.toLowerCase() === "notok"
                        ? () => (
                          <BsExclamation
                            className={`${styles.icon} ${styles.invalid}`}
                          />
                        )
                        : null
                  }
                />
              </div>

              <div
                className={`${styles.inputContainer} ${styles.inputContainerSmall}`}
              >
                <InputText
                  label="Comprimento"
                  id="comprimento"
                  type="text"
                  placeholder="Comprimento padrão das embalagens."
                  onChange={e => handleInputLength(e)}
                  value={length}
                  isValid={
                    lengthOK === "notOk"
                      ? false
                      : lengthOK === "vazio"
                        ? undefined
                        : true
                  }
                  errorMessage={lengthError}
                  renderIcon={
                    lengthOK.toLowerCase() === "ok"
                      ? () => <AiOutlineCheck className={styles.icon} />
                      : lengthOK.toLowerCase() === "notok"
                        ? () => (
                          <BsExclamation
                            className={`${styles.icon} ${styles.invalid}`}
                          />
                        )
                        : null
                  }
                />
              </div>
            </div>
          </div>
          <div className={styles.buttonsContainer}>
            <div className={styles.buttonContainerBack}>
              <button
                type="button"
                onClick={() => history.push("/nova-coleta")}
              >
                Voltar
              </button>
            </div>
            <div className={styles.buttonContainer}>
              <button type="submit">Atualizar</button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

const mapStoreToProps = store => ({
  userId: store.auth.user.id
});

export default connect(mapStoreToProps)(EditAccount);
