const DeliveryTypeEnum = {
    PAC: 'PAC',
    SEDEX: 'SEDEX',
    PACMINI: 'PACMINI',
    OWN_DELIVERY: 'OWN_DELIVERY',
    SEDEX_HOJE: 'SEDEX_HOJE'
};

export default Object.freeze({
    ...DeliveryTypeEnum,
});
