import React, { useCallback, useEffect, useMemo, useState } from "react";

import ReactModal from "react-modal";

import { CheckCircleOutlineSharp, Close, Person } from "@material-ui/icons";
import InputSelectAsync from "../../../../components/InputSelectAsync";
import { Loading } from "../../../../components/Loading";
import { BrComerceApiService } from "../../../../services/BrComerceApiService";
import styles from "./styles.module.scss";
import Swal from "sweetalert2";
import DeliveryTypeEnum from "../../../../enums/DeliveryTypeEnum";

const SaraErrorModal = ({ isOpen, setIsOpen, fromActionMenu = false, singleShippingId, getShippingTracking, search, selectedStatusInFilter }) => {
  const api = new BrComerceApiService();

  const [searchClient, setSearchClient] = useState(null);
  const [shippings, setShippings] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedShipping, setSelectedShipping] = useState(0);
  const [altura, setAltura] = useState(null);
  const [comprimento, setComprimento] = useState(null);
  const [largura, setLargura] = useState(null);
  const [peso, setPeso] = useState(null);

  const isWrongMeasure = useMemo(() =>
    (altura <= 0 || altura > 100)
    || (comprimento <= 0 || comprimento > 100)
    || (largura <= 0 || largura > 100)
    || (peso <= 0 || peso > 30)
  , [altura, comprimento, largura, peso])

  const getSaraErrorShippings = useCallback(async (subUser) => {
    setLoading(true);
    try {
      const response = await api.makeHttpRequest({
        method: "POST",
        url: `/shipping/saraError`,
        data: { subUser }
      })

      setShippings(response);

    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }, []);

  const handleClose = async () => {
    setAltura(null);
    setComprimento(null);
    setLargura(null);
    setPeso(null);
    setSelectedShipping(0);
    setSearchClient(null);
    setShippings([]);
    setIsOpen(false);
    await getShippingTracking(selectedStatusInFilter, search);
  }

  const handleSubmit = useCallback(async (shipping) => {
    setLoading(true);
    try {
      const validatedShipping = await api.makeHttpRequest({
        method: "POST",
        url: `/consumers/packages`,
        data: {
          idShipping: selectedShipping,
          altura: Number(altura),
          comprimento: Number(comprimento),
          largura: Number(largura),
          peso: Number(peso),
          diametro: 0,
        }
      });

      if (validatedShipping?.isValidated) {
        Swal.fire({
          title: "Sucesso!",
          text: "Objeto validado com sucesso.",
          icon: "success",
          showCancelButton: false,
          confirmButtonText: "Ok"
        });

        const index = shippings.indexOf(shipping);
        if (index !== -1) {
          setAltura(0);
          setComprimento(0);
          setLargura(0);
          setPeso(0);
          shippings.splice(index, 1);
        }
        return;
      } else {
        Swal.fire({
          icon: "error",
          title: "Erro",
          text: `${validatedShipping.type === DeliveryTypeEnum.PACMINI
            && (altura > 4 || peso > 0.3)
              ? "Para Pac Mini a altura máxima é 4 e o peso máximo é 0.3"
              : "Não foi possível validar o objeto. Verifique se o objeto pode ser validado."
          }`
        });
        return;
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }, [selectedShipping, altura, comprimento, largura, peso])

  const handleSubmitAction = useCallback(async () => {
    setIsOpen(false)
    setLoading(true);
    try {
      const validatedShipping = await api.makeHttpRequest({
        method: "POST",
        url: `/consumers/packages`,
        data: {
          idShipping: singleShippingId,
          altura: Number(altura),
          comprimento: Number(comprimento),
          largura: Number(largura),
          peso: Number(peso),
          diametro: 0,
        }
      });

      if (validatedShipping?.isValidated) {
        Swal.fire({
          title: "Sucesso!",
          text: "Objeto validado com sucesso.",
          icon: "success",
          showCancelButton: false,
          confirmButtonText: "Ok"
        }).then(() => {
          if (window.location.href.includes("rastrear-encomenda")) {
            getShippingTracking(selectedStatusInFilter, search);
          }
          return;
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Erro",
          text: `${validatedShipping.type === DeliveryTypeEnum.PACMINI
            && (altura > 4 || peso > 0.3)
              ? "Para Pac Mini a altura máxima é 4 e o peso máximo é 0.3"
              : "Não foi possível validar o objeto. Verifique se o objeto pode ser validado."
          }`
        });
        return;
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }, [altura, comprimento, largura, peso, singleShippingId, getShippingTracking, search, selectedStatusInFilter])

  const handleInputAltura = useCallback(e => {
    const valueNumber = e.currentTarget.value.replace(",", ".");
    if (valueNumber > 100) {
      return;
    } else if (valueNumber === "") {
      setAltura(null);
    } else {
      setAltura(valueNumber);
    }
  }, [altura]);

  const handleInputComprimento = useCallback(e => {
    const valueNumber = e.currentTarget.value.replace(",", ".");
    if (valueNumber > 100) {
      return;
    } else if (valueNumber === "") {
      setComprimento(null);
    } else {
      setComprimento(valueNumber);
    }
  }, [comprimento]);

  const handleInputLargura = useCallback(e => {
    const valueNumber = e.currentTarget.value.replace(",", ".");
    if (valueNumber > 100) {
      return;
    } else if (valueNumber === "") {
      setLargura(null);
    } else {
      setLargura(valueNumber);
    }
  }, [largura]);

  const handleInputPeso = useCallback(e => {
    const valueNumber = e.currentTarget.value.replace(",", ".");
    if (valueNumber > 30) {
      return;
    } else if (valueNumber === "") {
      setPeso(null);
    } else {
      setPeso(valueNumber);
    }
  }, [peso]);

  const handleSelectShipping = useCallback(async (shippingId) => {
    setAltura(0);
    setComprimento(0);
    setLargura(0);
    setPeso(0);
    if (shippingId === selectedShipping) {
      setSelectedShipping(0);
    } else {
      setSelectedShipping(shippingId);
    }
  }, [selectedShipping, altura, comprimento, largura, peso])

  useEffect(() => {
    if (searchClient) {
      getSaraErrorShippings(searchClient.sub);
    }
  }, [searchClient])

  return (
    <>
      <Loading loading={loading} />
      {fromActionMenu && isOpen ?
      <>
        <div className={styles.containerAction} onClick={() => setIsOpen(false)}>
        </div>
          <div className={styles.formRowAction}>
            <div className={styles.row}>
              <label>Altura (cm)</label>
              <input
                type="text"
                inputMode="numeric"
                name="altura"
                value={altura}
                onChange={handleInputAltura}
              />
            </div>
            <div className={styles.row}>
              <label>Comprimento (cm)</label>
              <input
                type="text"
                inputMode="numeric"
                name="comprimento"
                value={comprimento}
                onChange={handleInputComprimento}
              />
            </div>
            <div className={styles.row}>
              <label>Largura (cm)</label>
              <input
                type="text"
                inputMode="numeric"
                name="largura"
                value={largura}
                onChange={handleInputLargura}
              />
            </div>
            <div className={styles.row}>
              <label>Peso (kg)</label>
              <input
                type="text"
                inputMode="numeric"
                name="peso"
                value={peso}
                onChange={handleInputPeso}
              />
            </div>
            <button
              className={
                `${styles.buttonValidateAction}
                ${isWrongMeasure
                  ? styles.buttonDisabled
                  : ""
                }`
              }
              disabled={isWrongMeasure}
              onClick={() => handleSubmitAction()}
            >
              Validar
            </button>
            {isWrongMeasure && (
              <span>Max: A: 100, C: 100, L: 100, P: 30</span>
            )}
          </div>
        </>
        : <ReactModal
          isOpen={isOpen}
          shouldCloseOnOverlayClick={false}
          style={{
            overlay: {
              position: "fixed",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: "rgba(51, 51, 51, 0.75)",
              zIndex: 999
            },
            content: {
              top: "50%",
              left: "50%",
              right: "auto",
              bottom: "auto",
              transform: "translate(-50%, -50%)",
              zIndex: 999,
              border: "none",
              backgroundColor: "transparent",
              padding: 0
            }
          }}
        >
          <div className={styles.container}>
            <div className={styles.userSelect}>
              <div className={styles.close} onClick={handleClose}>
                <Close></Close>
              </div>
              <p>Conciliação manual</p>
              <InputSelectAsync
                search={searchClient}
                setSearch={setSearchClient}
                className={styles.userSelectInput}
              />
            </div>
            {shippings.length
              ? (
                <div className={styles.shippingsContainer}>
                  <div className={styles.headerRow}>
                    <p>Etiqueta</p>
                    <p>Pedido</p>
                    <p>Ação</p>
                  </div>
                  {
                    shippings.map((shipping) => (
                      <>
                        <div className={styles.shippingRow} key={shipping?.id}>
                          <p>{shipping?.sro}</p>
                          <p>{shipping?.name || shipping?.orderNumber}</p>
                          <p onClick={() => handleSelectShipping(shipping?.id)}>{shipping.id === selectedShipping ? "Recolher" : "Validar"}</p>
                        </div>
                        {selectedShipping === shipping?.id && (
                          <div className={styles.formRow}>
                            <div className={styles.row}>
                              <label>Altura (cm)</label>
                              <input
                                type="text"
                                inputMode="numeric"
                                name="altura"
                                value={altura}
                                onChange={handleInputAltura}
                              />
                            </div>
                            <div className={styles.row}>
                              <label>Comprimento (cm)</label>
                              <input
                                type="text"
                                inputMode="numeric"
                                name="comprimento"
                                value={comprimento}
                                onChange={handleInputComprimento}
                              />
                            </div>
                            <div className={styles.row}>
                              <label>Largura (cm)</label>
                              <input
                                type="text"
                                inputMode="numeric"
                                name="largura"
                                value={largura}
                                onChange={handleInputLargura}
                              />
                            </div>
                            <div className={styles.row}>
                              <label>Peso (kg)</label>
                              <input
                                type="text"
                                inputMode="numeric"
                                name="peso"
                                value={peso}
                                onChange={handleInputPeso}
                              />
                            </div>
                            <button
                              className={
                                `${styles.buttonValidate}
                                ${isWrongMeasure
                                  ? styles.buttonDisabled
                                  : ""
                                }`
                              }
                              disabled={isWrongMeasure}
                              onClick={() => handleSubmit(shipping)}
                            >
                              Validar
                            </button>
                            {isWrongMeasure && (
                              <span>Medidas máximas: A: 100, C: 100, L: 100, P: 30</span>
                            )}
                          </div>
                        )}
                      </>
                    ))
                  }
                </div>
              )
              : searchClient && !loading ? (
                <>
                  <div className={styles.noShippings}>
                    <CheckCircleOutlineSharp />
                    <p>{searchClient?.label || "Esse usuário"} não possui envios com erro no SARA</p>
                  </div>
                </>
              )
                : (
                  <>
                    <div className={styles.noShippingsNoUsers}>
                      <Person />
                      <p>Escolha um usuário</p>
                    </div>
                  </>
                )
            }
          </div>
        </ReactModal>
      }
    </>
  );
};

export default SaraErrorModal;
