import React, { useEffect, useRef, useState, useMemo } from "react";
import Swal from "sweetalert2";
import styles from "./styles.module.scss";
import { useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { BsArrowDownShort } from "react-icons/bs";
import { Plp } from "../../../../../components/Plp";
import { Loading } from "../../../../../components/Loading";
import { BrComerceApiService } from "../../../../../services/BrComerceApiService";
import Container from "../../../components/Container";

const PrintPLP = ({ width }) => {
  const api = new BrComerceApiService();
  const params = useParams();
  const { id } = params;

  const componentRef = useRef();
  const containerForPrintTags = useRef(null);

  const [tag, setTag] = useState({});
  const [loading, setLoading] = useState(false);
  const [showHelperForPrint, setShowHelperForPrint] = useState(true);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    pageStyle: () => `
      @page {
        size: A4 !important;
        padding: 0 !important;
        margin: 8mm !important;
      }
    `
  });

  const handlePrinterButton = () => {
    handlePrint();
  };

  const goToPrintContainer = () => {
    containerForPrintTags.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "start"
    });
  };

  useEffect(() => {
    setLoading(true);
    api
      .makeHttpRequest({
        method: "post",
        url: "/tag/plp",
        data: { id }
      })
      .then(async response => {
        setTag(response);
      })
      .catch(error => {
        console.log(error);
        Swal.fire({
          icon: "error",
          title: "Etiquetas",
          text: "Não foi possível gerar PLP."
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id]);

  const summary = useMemo(() => {
    return tag?.plpShipping?.reduce(
      (a, b) => {
        const sedexCount =
          a.expressos + (b?.shipping?.type === "SEDEX" ? 1 : 0);
        const pacCount = a.economicos + (b?.shipping?.type === "PAC" ? 1 : 0);
        const pacMiniCount =
          a.pacmini + (b?.shipping?.type === "PACMINI" ? 1 : 0);
        return {
          expressos: sedexCount,
          economicos: pacCount,
          pacmini: pacMiniCount
        };
      },
      { expressos: 0, economicos: 0, pacmini: 0 }
    );
  }, [tag]);

  const onScroll = e => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;
    const totalScrolled = scrollTop + clientHeight;

    if (Number(totalScrolled.toFixed()) === scrollHeight) {
      setShowHelperForPrint(false);
    } else {
      setShowHelperForPrint(true);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", onScroll, true);

    // Remove the event listener
    return () => {
      window.removeEventListener("scroll", onScroll, true);
    };
  }, []);

  return (
    <main className={styles.main}>
      <Loading loading={loading} />
      <Container title="Impressão de PLP">
        <div ref={componentRef} className={styles.content}>
          <div className={styles.plpTags}>
            <Plp
              plpNumber={tag?.plpNumber}
              cartaoPostagem={tag?.cartaoPostagem}
              cartaoPostagemBarcodeBase64={tag?.cartaoPostagemBarcodeBase64}
              codigoAdministrativo={tag?.codigoAdministrativo}
              numeroContrato={tag?.numeroContrato}
              plpShippings={tag?.plpShipping}
              userPlpType={tag?.userPlpType}
            />
          </div>
        </div>
      </Container>

      <aside className={styles.aside}>
        <div ref={containerForPrintTags} className={styles.summary}>
          <h2>Resumo da impressão</h2>

          <div className={styles.summaryResume}>
            <div>
              <strong>PLP:</strong>

              <span>{tag?.plpNumber}</span>
            </div>

            <div>
              <strong>Objetos:</strong>

              {!!summary?.expressos && (
                <span>{summary?.expressos} Expressos</span>
              )}
              {!!summary?.economicos && (
                <span>{summary?.economicos} Econômico</span>
              )}
              {!!summary?.pacmini && (
                <span>{summary?.pacmini} Super Econômico</span>
              )}
            </div>
          </div>
        </div>
        <div className={styles.button}>
          <button onClick={handlePrinterButton}>Imprimir PLP</button>
        </div>
      </aside>

      {showHelperForPrint && width <= 880 && (
        <div
          className={`${styles.goToPrintContainer}`}
          onClick={goToPrintContainer}
        >
          <button type="button">
            Imprimir PLP <BsArrowDownShort />
          </button>
        </div>
      )}
    </main>
  );
};

export { PrintPLP };
