import React from "react";
import styles from "./inputText.module.scss";

const InputText = ({
  id,
  label,
  disabled,
  errorMessage,
  onClick,
  renderIcon = null,
  isValid,
  ...rest
}) => {
  const icon = renderIcon && renderIcon();

  return (
    <div
      onClick={onClick ? onClick : () => {}}
      className={`
      ${disabled ? styles.textDisabled : ""}
      ${styles.inputWrapper} ${isValid === false ? styles.notValid : ""}
      ${isValid === true ? styles.valid : ""}
    `}
    >
      <label htmlFor={id}>{label}</label>

      <input type="text" disabled={disabled} {...rest} />
      {icon && icon}
      {!!errorMessage && <p className={styles.errorMessage}>{errorMessage}</p>}
    </div>
  );
};

export default InputText;
