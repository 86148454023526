import React, { useMemo } from "react";

import useGetWindowWidth from "../../../../utils/useGetWindowWidth";

import { BsCheck, BsX } from "react-icons/bs";
import { CgArrowRight } from "react-icons/cg";
import styles from "./styles.module.scss";

export function CardShippingStage({ shipping, type, last, order }) {
  const widthWindows = useGetWindowWidth();

  const isMobile = widthWindows < 769;

  const description =
    shipping?.description?.split("/") ||
    shipping.correiosDescription?.split("/");

  const handleStatusColor = (status = null) => {
    const color = {
      "Não foi possível entregar o objeto": "#FF6B71",
      "Objeto extraviado": "#FF6B71",
      "Objeto não foi entregue": "#FF6B71",
      "Objeto Cancelado": "#FF6B71",
      "Objeto Devolvido": "#FFAF52",
      "Objeto pronto para retirada": "#3C7DD9"
    };

    if (color[status] && last) {
      return color[status];
    }

    return last ? "#409e6a" : "#B3B3B3";
  };

  const handleErrorStatus = status => {
    const statusError = [
      "Não foi possível entregar o objeto",
      "Objeto extraviado",
      "Objeto não foi entregue",
      "Objeto Cancelado"
    ];

    return statusError.includes(status) ? true : false;
  };

  return (
    <>
      <div
        className={`${styles.cardObject} ${type === "inactive" ? styles.inactive : ""
          }`}
      >
        <div
          style={{ backgroundColor: handleStatusColor(shipping.status) }}
          className={styles.iconTimeline}
        >
          {handleErrorStatus(shipping.status) && last
            ? <BsX />
            : isMobile && shipping.icon
              ? shipping.icon
              : <BsCheck />
          }
          {isMobile && !last &&
            <>
              <div className={styles.dot}></div>
              <div className={styles.dot2}></div>
              <div className={styles.dot3}></div>
            </>
          }
        </div>
        {!last && <span className={`${styles.timeline}`}></span>}
        <div
          style={{ color: last ? "" : "#B3B3B3" }}
          className={styles.cardContent}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
              flexWrap: "wrap",
              gap: "10px"
            }}
          >
            <p
              style={{ color: handleStatusColor(shipping.status) }}
              className={styles.status}
            >
              {shipping.status}
            </p>
            {window.screen.width > 769 && "-"}
            <p
              style={{
                color:
                  shipping.status === "Não foi possível entregar o objeto" &&
                    last
                    ? "#FF6B71"
                    : ""
              }}
            >
              {shipping.date}
            </p>
            -
            {description.length > 1 ? (
              <div>
                <div
                  style={{
                    color:
                      shipping.status ===
                        "Não foi possível entregar o objeto" && last
                        ? "#FF6B71"
                        : ""
                  }}
                  className={styles.description}
                >
                  <p>{description[0]}</p>
                  <CgArrowRight className={styles.descriptionSvg} />
                  <p>{description[1]}</p>
                </div>
              </div>
            ) : (
              <div
                style={{
                  color:
                    shipping.status === "Não foi possível entregar o objeto" &&
                      last
                      ? "#FF6B71"
                      : ""
                }}
                className={styles.description}
              >
                <p>{description[0] || shipping.correiosDescription}</p>
              </div>
            )}
          </div>
          {shipping.status === "Outros" && (
            <div className={styles.address}>
              <span>{shipping?.correiosDescription}</span>
            </div>
          )}
          {shipping?.address !== "" && shipping?.address !== undefined && (
            <div className={styles.address}>
              <span>Aguardando retirada em:</span>
              <br />
              <span>{shipping?.address}</span>
            </div>
          )}
          {shipping.status === "Não foi possível entregar o objeto" && (
            <div className={styles.address}>
              <span
                style={{
                  color:
                    shipping.status === "Não foi possível entregar o objeto" &&
                      last
                      ? "#FF6B71"
                      : ""
                }}
              >
                {shipping?.correiosDescription}
              </span>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
