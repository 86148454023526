import ReactModal from "react-modal";
import styles from "./styles.module.scss";
import React from "react";

export default function MessageModal({
  isOpen,
  setIsOpen,
  children,
  icon,
  onClose,
  title,
  text,
  buttonStyle,
  onButtonClick,
  buttonText
}) {
  const modalStyle = {
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "rgba(51, 51, 51, 0.75)",
      zIndex: 999
    },
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      transform: "translate(-50%, -50%)",
      zIndex: 999,
      border: "none",
      backgroundColor: "transparent",
      padding: 0
    }
  };
  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onClose}
      style={modalStyle}
    >
      <div className={styles.messageModalContainer}>
        {icon}
        <h2 className={styles.title}>{title}</h2>
        <p className={styles.text}>{text}</p>

        {children}
        <div className={styles.actionsButtons}>
          <button onClick={() => {
            onButtonClick
              ? onButtonClick()
              : setIsOpen(!isOpen)
          }} style={buttonStyle}>{buttonText ?? "Fechar"}</button>
        </div>
      </div>
    </ReactModal>
  )
}
