import React, { useLayoutEffect, useRef, useState } from "react";
import styles from "./tableCompanies.module.scss";
import { FaMinusCircle, FaPlusCircle } from "react-icons/fa";

const TableCompanies = ({ tableByCompanies }) => {
  const table = useRef(null);
  const [isScrolledTable, setIsScrolledTable] = useState(false);

  const [toggleItemsInTableCompany, setToggleItemsInTableCompany] = useState(
    []
  );

  const handleToggleCompanies = companyTitle => {
    const companyIsToggled = toggleItemsInTableCompany.includes(companyTitle);

    if (companyIsToggled) {
      setToggleItemsInTableCompany(prevState =>
        prevState.filter(company => company !== companyTitle)
      );
      return;
    }

    setToggleItemsInTableCompany(prevState => [...prevState, companyTitle]);
  };

  const handleTypeShipping = (type = "pac") => {
    const shipping = {
      sedex: "Expressa",
      pac: "Econômica",
      pacmini: "Super Econômica",
      own_delivery: "Entrega Própria"
    };

    return shipping[type.toLowerCase()];
  };

  useLayoutEffect(() => {
    if (table?.current) {
      const tableHeight = table.current.clientHeight;
      const MAX_HEIGHT_TABLE = 484;

      if (tableHeight > MAX_HEIGHT_TABLE) {
        setIsScrolledTable(true);
      } else {
        setIsScrolledTable(false);
      }
    }
  }, [table, tableByCompanies, toggleItemsInTableCompany]);

  return (
    <table
      ref={table}
      className={`${styles.tableCompany} ${
        isScrolledTable ? styles.greaterThanMaxHeight : ""
      }`}
    >
      <thead>
        <tr>
          <th>Nome</th>
          <th>Total Pacotes</th>
          <th>Total Frete</th>
          <th>Total Desconto</th>
          <th>Prazo Médio</th>
        </tr>
      </thead>
      <tbody>
        {tableByCompanies.map(company => {
          if (company.title.toLowerCase() === "total") {
            return <React.Fragment key={company.title}></React.Fragment>;
          }

          const dontShowActualCompany = toggleItemsInTableCompany.includes(
            company.title
          );
          return (
            <React.Fragment key={company.title}>
              <tr className={styles.companyInTable}>
                <td
                  onClick={() => handleToggleCompanies(company.title)}
                  className={styles.companyToggleable}
                >
                  <span className={styles.title}>
                    {dontShowActualCompany ? (
                      <FaPlusCircle size={14} color="#409E6A" />
                    ) : (
                      <FaMinusCircle size={14} color="#409E6A" />
                    )}
                    {company.title}
                  </span>
                </td>
                <td>{company.totalPackages}</td>
                <td>
                  {new Intl.NumberFormat("pt-BR", {
                    style: "currency",
                    currency: "BRL"
                  }).format(company.totalPrice)}
                </td>
                <td>
                  {new Intl.NumberFormat("pt-BR", {
                    style: "currency",
                    currency: "BRL"
                  }).format(company.totalDiscount)}
                </td>
                <td>{Number(company.totalAverageDeliveryDate)}</td>
              </tr>
              {!dontShowActualCompany &&
                !!company.valuesByType.length &&
                company.valuesByType.map(eachType => (
                  <tr key={eachType.type}>
                    <td className={styles.typeCompany}>
                      {handleTypeShipping(eachType.type)}
                    </td>
                    <td>{eachType.packages}</td>
                    <td>
                      {new Intl.NumberFormat("pt-BR", {
                        style: "currency",
                        currency: "BRL"
                      }).format(eachType.price)}
                    </td>
                    <td>
                      {new Intl.NumberFormat("pt-BR", {
                        style: "currency",
                        currency: "BRL"
                      }).format(eachType.discount)}
                    </td>
                    <td>{Number(eachType.averageDeliveryDate)}</td>
                  </tr>
                ))}
            </React.Fragment>
          );
        })}
      </tbody>
      <tfoot>
        <tr>
          {tableByCompanies
            .filter(company => company.title.toLowerCase() === "total")
            .map(company => {
              return (
                <React.Fragment key={company.title}>
                  <th>
                    <span className={styles.title}>{company.title}</span>
                  </th>
                  <th>{company.totalPackages}</th>
                  <th>
                    {new Intl.NumberFormat("pt-BR", {
                      style: "currency",
                      currency: "BRL"
                    }).format(company.totalPrice)}
                  </th>
                  <th>
                    {new Intl.NumberFormat("pt-BR", {
                      style: "currency",
                      currency: "BRL"
                    }).format(company.totalDiscount)}
                  </th>
                  <th>{Number(company.totalAverageDeliveryDate)}</th>
                </React.Fragment>
              );
            })}
        </tr>
      </tfoot>
    </table>
  );
};

function tableCompaniesAreEqual(prevCompanies, nextCompanies) {
  return (
    JSON.stringify(prevCompanies?.tableByCompanies) ===
    JSON.stringify(nextCompanies?.tableByCompanies)
  );
}

const TableCompaniesMemoized = React.memo(
  TableCompanies,
  tableCompaniesAreEqual
);

export { TableCompanies, TableCompaniesMemoized };
