import momentBDays from "moment-business-days";

momentBDays.locale("pt-br");

const localStorageKey = "brcom:Holidays";

const addHolidaysInMoment = holidays => {
  momentBDays.updateLocale("pt-br", {
    holidays: holidays,
    holidayFormat: "YYYY-MM-DD"
  });
};

const loadAllHolidaysAndIncludeInMoment = async () => {
  const actualYear = momentBDays().year();
  const hasHolidaysInfo = localStorage.getItem(localStorageKey);

  if (hasHolidaysInfo) {
    const { year, allHolidays } = JSON.parse(hasHolidaysInfo);

    if (Number(year) === Number(actualYear)) {
      addHolidaysInMoment(allHolidays);
      return;
    }
  }

  const response = await fetch(
    `https://brasilapi.com.br/api/feriados/v1/${actualYear}`
  );

  const holidays = await response.json();
  const allHolidaysHandled = holidays.map(holiday => holiday.date);

  localStorage.setItem(
    localStorageKey,
    JSON.stringify({ allHolidays: allHolidaysHandled, year: actualYear })
  );

  addHolidaysInMoment(allHolidaysHandled);
};

loadAllHolidaysAndIncludeInMoment();

export { momentBDays };
