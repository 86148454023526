import React from "react";
import styles from "./styles.module.scss";
import correiosLogo from "../../assets/logo/correios-color.png";
import formatCep from "../../utils/FormatCep";
import { ShippingTypeEnum } from "../../enums/ShippingTypeEnum";
import PlpTypeEnum from "../../enums/PlpTypeEnum";

const Plp = ({
  plpNumber,
  cartaoPostagem,
  cartaoPostagemBarcodeBase64,
  codigoAdministrativo,
  numeroContrato,
  plpShippings,
  userPlpType
}) => {
  const returnBilledWeight = shipping => {
    const length = shipping?.length;
    const height = shipping?.height;
    const width = shipping?.width;
    const weight = shipping?.weight;

    const cubWeight = (length * height * width) / 6000;

    const response =
      cubWeight > 5 && weight < cubWeight
        ? cubWeight.toFixed(3)
        : weight.toFixed(3);
    return response;
  };

  const returnCode = (userPLPType, type) => {
    let code;
    if (userPLPType === PlpTypeEnum.RETAIL.value) {
      switch (type) {
        case ShippingTypeEnum.SEDEX:
          code = 3220;
          break;
        case ShippingTypeEnum.PAC:
          code = 3298;
          break;
        default:
          code = 4227;
          break;
      }
    } else {
      switch (type) {
        case ShippingTypeEnum.SEDEX:
          code = 3280;
          break;
        case ShippingTypeEnum.PAC:
          code = 3336;
          break;
        default:
          code = 4391;
          break;
      }
    }
    return code;
  };

  return (
    <>
      <table className={styles.tablePLP}>
        <thead>
          <tr className={styles.tableTH}>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
          </tr>
          <tr>
            <td colspan="2">
              <img src={correiosLogo} alt="LOGO CORREIOS" />
            </td>
            <td colspan="5" className={styles.title}>
              LISTA DE POSTAGEM - ENCOMENDA A FATURAR
            </td>
          </tr>
          <tr>
            <td colspan="3">
              <p>
                <strong>CONTRATANTE</strong>
              </p>
              <p> BR COMERCE LOGÍSTICAS</p>
            </td>
            <td colspan="2">
              <p>
                <strong>CARTÃO DE POSTAGEM </strong>
              </p>
              <p>{cartaoPostagem}</p>
            </td>
            <td>
              <p>
                <strong>COD. ADMINISTRATIVO</strong>
              </p>
              <p>{codigoAdministrativo}</p>
            </td>
            <td>
              <p>
                <strong>Nº DO CONTRATO</strong>
              </p>
              <p>{numeroContrato}</p>
            </td>
          </tr>

          <tr>
            <td rowspan="3" colspan="2">
              <p>
                <strong>Numero PLP</strong>
              </p>
              <div className={styles.image}>
                <p>{plpNumber}</p>
                <img
                  src={`data:image/jpeg;base64,${cartaoPostagemBarcodeBase64}`}
                  alt={`CODIGO DE BARRAS ${plpNumber}`}
                />
              </div>
            </td>
            <td>
              <p>
                <strong>DR DO CONTRATO</strong>
              </p>
              <p>RJ</p>
            </td>
            <td>
              {" "}
              <strong>DR DE POSTAGEM</strong>{" "}
            </td>
            <td colspan="3">
              <strong>ÓRGÃO CREDENCIADO RESPONSÁVEL PELA POSTAGEM</strong>
            </td>
          </tr>

          <tr>
            <td>
              <p>
                <strong>LOCALIDADE</strong>
              </p>
            </td>
            <td>
              <p>
                <strong>UNID. DE POSTAGEM</strong>{" "}
              </p>
            </td>
            <td colspan="2">
              <p>
                <strong>CÓD. DA UNIDADE DE POSTAGEM</strong>
              </p>
            </td>
            <td>
              <p>
                <strong>DATA DE POSTAGEM</strong>{" "}
              </p>
            </td>
          </tr>
        </thead>
      </table>
      <br />

      <table className={`${styles.tablePLP} ${styles.table2}`}>
        <tbody>
          <tr>
            <th>NÚMERO DO OBJETO</th>
            <th>CÓDIGO DO SERVIÇO</th>
            <th>CEP DE DESTINO</th>
            <th>PESO TARIFADO</th>
            <th>COD SERVIÇOS ADICIONAIS</th>
            <th>VALOR DECLARADO DO OBJETO</th>
            <th>VALOR A COBRAR DO DESTINATÁRIO (SEDEX A COBRAR)</th>
          </tr>

          {plpShippings?.map(ps => {
            return (
              <tr className={styles.shippings} key={ps?.id}>
                <td>{ps?.shipping?.sro}</td>
                <td>{returnCode(userPlpType, ps.shipping.type)}</td>
                <td>{formatCep(ps?.shipping?.destination?.zip)}</td>
                <td>{`${returnBilledWeight(ps?.shipping)}kg`}</td>
                <td></td>
                <td>
                  {ps?.insurance
                    ? Intl.NumberFormat("pt-BR", {
                        style: "currency",
                        currency: "BRL"
                      }).format(ps?.shipping?.correios?.valorValorDeclarado)
                    : "R$ 0,00"}
                </td>
                <td></td>
              </tr>
            );
          })}
        </tbody>
      </table>

      <table
        className={`${styles.tablePLP} ${styles.table3} ${styles.pageBreak}`}
      >
        <tbody>
          <tr>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
          </tr>
          <tr>
            <td colspan="5">NOME DO REMETENTE</td>
            <td colspan="2" rowspan="3">
              CARIMBO DA UNIDADE DE POSTAGEM
            </td>
          </tr>
          <tr>
            <td colspan="5">ASSINATURA DO REMETENTE</td>
          </tr>
          <tr>
            <td colspan="5">ASSINATURA E MATRÍCULA DO EMPREGADO DA ECT</td>
          </tr>
        </tbody>
      </table>

      <br />

      <table className={`${styles.tablePLP} ${styles.pageBreak}`}>
        <tbody className={styles.information}>
          <tr>
            <strong>
              <u>
                <p>INFORMAÇÕES:</p>
              </u>
            </strong>
          </tr>
          <tr>
            {
              "1) Os dados contidos neste documento serão confirmados após a conferência dos objetos quando do recebimento da carga na Unidade da ECT"
            }
          </tr>
          <tr>
            {
              "2) Em caso de divergência entre a quantidade de objetos apresentados e os dados informados neste documento, o cliente será imediatamente comunicado pela ECT e deverá comparecer a Unidade para substituição da Lista de Postagem;"
            }
          </tr>
          <tr>
            {
              "3) Caso o cliente não compareça no mesmo dia, os objetos serão liberados para encaminhamento, depois de anotadas as divergências identificadas;"
            }
          </tr>
          <tr>
            {
              "4) Se o cliente não comparecer para substituição da Lista de Postagem, no prazo de 48 horas após comunicação da ECT, o documento será encaminhado para faturamento com as divergências devidamente registradas."
            }
          </tr>
          <tr className={styles.atencion}>
            <p>
              {
                "ATENÇÃO! Quando da utilização do serviço SEDEX a Cobrar, o valor a ser cobrado do destinatário não poderá ser inferior ao valor dos preços postais e nem superior à soma do Valor Declarado e dos preços postais pagos"
              }
            </p>
          </tr>
        </tbody>
      </table>

      <br />

      <table className={`${styles.tablePLP} ${styles.pageBreak}`}>
        <thead>
          <tr className={styles.additionalServices}>
            <td colspan="7">CÓDIGOS DE SERVIÇOS ADICIONAIS</td>
          </tr>
        </thead>
        <tbody>
          <tr className={styles.services}>
            <td colspan="2">{"(01) Aviso de Recebimento – AR"}</td>
            <td>{"(02) Mão Própria – MP"}</td>
            <td>{"(19) Valor Declarado – VD"}</td>
            <td colspan="2">{"(4209-9) Coleta Programada Normal"}</td>
            <td>{"(4210-2) Coleta"}</td>
          </tr>
        </tbody>
      </table>

      <div className={styles.printingRoules}>
        <p>{"1ª VIA: Centro de Tratamento de Encomendas – CTE "}</p>
        <p>{"2ª VIA: Agência de Origem"}</p>
      </div>
    </>
  );
};

export { Plp };
