const IntegrationsKeysEnum = {
  API_KEY: "Chave API",
  CODE: "Código",
  API_ADDRESS: "Endereço da API",
  STORE_ID: "ID da Loja",
  BASE_URL: "URL Base",
  ADD_DAYS_PACMINI: "Dias adicionais (Pacmini)",
  ADD_DAYS_SEDEX: "Dias adicionais (Sedex)",
  ADD_DAYS_PAC: "Dias adicionais (Pac)",
  STATUSES_TO_ADD_ORDER: "Status para importar",
  BRCOM_COMPANY_ID: "ID da empresa na BRCom",
  SEDEX_OPTION_ID: "ID Sedex",
  PAC_OPTION_ID: "ID Pac",
  MINI_OPTION_ID: "ID Pacmini",
  SENDER_DEFAULT_NAME: "Nome do remetente",
  IS_ACTIVE_INTEGRATION: "Integração ativa",
  rateType: "Tipo de cotação",
  active: "Ativo",
  store: "Loja",
  apiKey: "Chave API",
  zipcode: "CEP",
  apiToken: "Token API",
  IS_ACTIVE_INSURANCE: "Seguro",
  SENDER_DEFAULT_ADDRESS: "Endereço remetente",
  storeNumber: "Número da loja",
  IS_ACTIVE_STORES: "Loja ativa",
  ZIP_CODE: "CEP",
  WEBHOOK_URL: "URL do Webhook",
  HAS_IMPORTED_SHIPPING_TYPE: "Tem tipo de frete custom",
  IMPORTED_SHIPPING_TYPE: "Frete cusom",
  IS_ACTIVE_PAC: "Pac ativo",
  IS_ACTIVE_SEDEX: "Sedex ativo",
  IS_ACTIVE_PACMINI: "Pacmini ativo",
  ADD_DAYS_OWN_DELIVERY: "Dias adicionais (Super Expressa)",
  USER_API_KEY: "Chave API do usuário",
  STORE_ADDRESSBOOK_ID: "ID do endereço da loja",
  STATUS_AFTER_CREATE_ORDER: "Status depois de importar",
  SEDEX_SERVICE_ID: "ID Sedex",
  LOGISTIC_ID: "ID Logística",
  PAC_SERVICE_ID: "ID Pac",
  PACMINI_SERVICE_ID: "ID Pacmini",
  OWN_DELIVERY_SERVICE_ID: "ID Super Expressa",
  AUTOMATIC_SERVICE_ID: "ID Menor custo",
  STATUS_MOVEMENT_AFTER_CREATE_ORDER: "Status depois de importar",
  CD_FILTER_ACTIVE: "Filtro por CD ativo",
  API_TOKEN: "Token API",
  IS_ACTIVE_OWN_DELIVERY: "Super Expressa ativo",
  CD_ID: "ID do Centro Distribuição",
  STATUS_TO_CREATE: "Status para importar",
  HAS_IMPORTED_RATE: "Tem frete custom",
  FREE_RATE_TYPE: "Tipo do frete grátis",
  ACTIVE_FREE_RATE: "Frete grátis ativo",
  STATUS_AFTER_COLLECT: "Status depois de pago",
  PAC_TAG: "Marcador Pac",
  IS_ACTIVE_PAC_TAG: "Marcador Pac ativo",
  SEDEX_TAG: "Marcador Sedex",
  CUSTOM_RATE: "Frete custom",
  IS_INACTIVE_ORDER_CREATE: "Inativar criação de pedido",
  WEBHOOK_URL_VALIDATED_PACKAGES: "URL do Webhook de validação",
  CUSTOM_RATE_TYPE: "Tipo do frete custom",
  CLIENT_ID: "ID do cliente",
  CLIENT_SECRET: "Senha do cliente",
  BASE_64_CREDENTIALS: "Credenciais criptografada",
  STATUS_AFTER_CREATE_COLLECT: "Status depois de pago",
  REFRESH_TOKEN: "Token de atualizar",
  EXPIRES_IN: "Expira em",
  ACCESS_TOKEN: "Token de acesso",
  ACTIVE_CUSTOM_RATE: "Frete custom ativo",
  STORE_HOST: "Domínio da Loja",
  IS_ACTIVE_IMPORTED_RATE: "Frete custom ativo",
  IMPORTED_RATE: "Frete custom",
  IS_ACTIVE_SEDEX_TAG: "Marcador Sedex ativo",
  STATUS_AFTER_CREATE: "Status depois de importar",
  STORE_URL: "URL da Loja",
  APP_TOKEN: "Token do APP",
  IMPORTED_RATES: "Fretes custom",
  OWN_DELIVERY_OPTION_ID: "ID Super Expressa",
};

export default Object.freeze({
  ...IntegrationsKeysEnum,
});
