/* eslint-disable jsx-a11y/alt-text */
import React, { useLayoutEffect, useState } from "react";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import ReactModal from "react-modal";
import styles from "./styles.module.scss";


export const DeliveryImageModal = ({
    urlImage,
    text,
    isOpen,
    handleCloseButton,
    isPublic = false
}) => {
    const [size, setSize] = useState({
        width: 0,
        height: 0
    });
    
    useLayoutEffect(() => {
        function updateSize() {
            setSize({ width: window.innerWidth, height: window.innerHeight });
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);

    return (
        <div className={styles.container}>
            <ReactModal
                isOpen={isOpen}
                dialogClassName="schedule-pickup-modal"
                onRequestClose={() => handleCloseButton()}
                className={styles.modal}
                style={{
                    content: {
                        marginLeft: (isPublic && size.width > 800) && "27%"
                    },
                    overlay: {
                        zIndex: 3
                    }
                }}
            >
                <button
                    className={styles.closeButton}
                    onClick={() => handleCloseButton()}
                >
                    X
                </button>
                <div className={styles.containerImage}>
                    <p>{text}</p>
                    < div className={styles.image}>
                        <TransformWrapper>
                            <TransformComponent>
                                <img
                                    src={urlImage}
                                    alt="test"
                                />
                            </TransformComponent>
                        </TransformWrapper>
                    </div>
                </div>
            </ReactModal>
        </div>
    );
};
