import React from "react";
import styles from "./styles.module.scss";
import correios from "../../assets/logo/correios.png";

const ContentDeclaration = ({ shipping }) => {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <img src={correios} alt="logo correios" />
        <p>Declaração de Conteúdo</p>
      </div>
      <div className={styles.origin}>
        <p>REMETENTE: &nbsp;{shipping?.origin?.name}</p>
        <p>CPF/CNPJ:&nbsp; {shipping?.origin?.document}</p>
        <p>
          ENDEREÇO:&nbsp; {shipping?.origin?.street},{shipping?.origin?.number},
          {shipping?.origin?.complement}
        </p>
        <p>
          CIDADE/UF:&nbsp; {shipping?.origin?.city}/{shipping?.origin?.state}
          <span>CEP:&nbsp;{shipping?.origin?.zip}</span>
        </p>
      </div>
      <div className={styles.destination}>
        <p>DESTINATARIO: &nbsp;{shipping?.destination?.name}</p>
        <p>CPF/CNPJ:&nbsp; {shipping?.destination?.document}</p>
        <p>
          ENDEREÇO:&nbsp; {shipping?.destination?.street},
          {shipping?.destination?.number},{shipping?.destination?.complement}
        </p>
        <p>
          CIDADE/UF:&nbsp; {shipping?.destination?.city}/
          {shipping?.destination?.state}
          <span>CEP:&nbsp;{shipping?.destination?.zip}</span>
        </p>
      </div>
      <div className={styles.itens}>
        <div className={styles.itensHeader}>IDENTIFICAÇÃO DOS BENS</div>
        <table>
          <thead>
            <tr>
              <th>DISCRIMINAÇÃO DO CONTEÚDO</th>
              {
                shipping?.originCreation === "tray" &&
                <>
                  <th>TAMANHO</th>
                  <th>COR</th>
                </>
              }
              <th>QUANTIDADE</th>
              <th>VALOR</th>
            </tr>
          </thead>
          <tbody>
            {shipping?.declarationOfContents?.map((dc, i) => (
              <tr key={i}>
                <th>{dc.item || dc.nome}</th>
                {
                  shipping?.originCreation === "tray" &&
                  <>
                    <th>{dc.tamanho || ""}</th>
                    <th>{dc.cor || ""}</th>
                  </>
                }
                <th>{dc.quantidade}</th>
                <th>R$ {dc.valorUnitario || dc.valor || "0,00"}</th>
              </tr>
            ))}
            <tr className={styles.total}>
              <th>Total</th>
              {
                shipping?.originCreation === "tray" &&
                <>
                  <th>{"-"}</th>
                  <th>{"-"}</th>
                </>
              }
              <th>
                {shipping?.declarationOfContents?.reduce((a, b) => {
                  return Number(b.quantidade) + a;
                }, 0)}
              </th>
              <th>
                {new Intl.NumberFormat("pt-BR", {
                  style: "currency",
                  currency: "BRL"
                }).format(
                  shipping?.declarationOfContents?.reduce((a, b) => {
                    return b?.valorUnitario
                      ? Number(
                        (Number(String(b?.valorUnitario)?.replace(",", ".")) * Number(b?.quantidade)) ||
                        b.valor ||
                        0
                      ) + a
                      : 0 + a;
                  }, 0)
                )}
              </th>
            </tr>
          </tbody>
        </table>
      </div>
      <div className={styles.statment}>
        <div className={styles.statmentHeader}>DECLARAÇÃO</div>
        <div
          className={styles.statmentContent}
          // role
          dangerouslySetInnerHTML={{
            __html: `&nbsp; &nbsp; Declaro, não ser pessoa física ou jurídica, que realize, com habitualidade ou em volume que caracterize intuito comercial, operações de circulação de mercadoria, ainda que estas se iniciem no exterior, que o conteúdo declarado não está sujeito à tributação, e que sou o único responsável por eventuais penalidades ou danos decorrentes de informações inverídicas. <br/>
            &nbsp; &nbsp; Declaro ainda que não estou postando conteúdo inflamável, explosivo, causador de combustão espontânea,tóxico, corrosivo, gás ou qualquer outro conteúdo que constitua perigo, conforme o art. 13 da Lei Postal nº 6.538/78.<br/>
            _______________________, ______ de _____________ de _____ <br/><br/>_______________________________________<br/> Assinatura do Declarante/Remetente
            `
          }}
        />
      </div>
      <div className={styles.warning}>
        <p>
          Atenção:
          <span>
            O declarante/remetente é responsável exclusivamente pelas
            informações declaradas.
          </span>{" "}
        </p>
      </div>
      <div className={styles.obs}>
        <p>OBSERVAÇÕES:</p>
        <span>
          I. É Contribuinte de ICMS qualquer pessoa física ou jurídica, que
          realize, com habitualidade ou em volume que caracterize intuito
          comercial, operações de circulação de mercadoria ou prestações de
          serviços de transportes interestadual e intermunicipal e de
          comunicação, ainda que as operações e prestações se iniciem no
          exterior (Lei iComplementar nº 87/96 Art. 4º).
        </span>
        <span>
          II. Constitui crime contra a ordem tributária suprimir ou reduzir
          tributo, ou contribuição social e qualquer acessório (Lei 8.137/90
          Art. 1º, V).
        </span>
      </div>
    </div>
  );
};

export { ContentDeclaration };
