import React, { useCallback, useEffect, useState } from "react";
import moment from "moment";
import Swal from "sweetalert2";
import styles from "./styles.module.scss";
import { FiPrinter, FiSearch } from "react-icons/fi";
import { MdClear } from "react-icons/md";
import debounce from "../../../../../utils/debounce";
import ScrollDiv from "../../../components/ScrollDiv";
import { Loading } from "../../../../../components/Loading";
import InputText from "../../../../../components/InputText";
import InfiniteScroll from "react-infinite-scroll-component";
import DatePicker from "../../../../../components/Input/DatePicker";
import LoadInfiniteScroll from "../../../../../components/LoadInfiniteScroll";
import { BrComerceApiService } from "../../../../../services/BrComerceApiService";
import { PLPStatusEnum } from "../../../../../enums/PLPStatusEnum";
import Container from "../../../components/Container";
import { StyledTooltip } from "../../../components/Tooltip";

export const PLPEnum = {
  PENDING: "pending",
  FINISHED: "finished",
  ERROR: "error"
};

const PLPCreated = () => {
  const api = new BrComerceApiService();
  const [search, setSearch] = useState(null);
  const [plps, setPLPs] = useState([]);
  const [nPages, setNPages] = useState(0);
  const [loading, setLoading] = useState(false);
  const [actualPage, setActualPage] = useState(0);
  const [periodInitialDate, setPeriodInitialDate] = useState(null);
  const [periodFinalDate, setPeriodFinalDate] = useState(null);
  const [loadingInfinit, setLoadingInfinit] = useState(false);
  const [hasMore, setHasMore] = useState(false);

  const handleOnChangeCalendarPeriod = e => {
    if (e.dateEnd) {
      const dateStart = moment(e.dateStart, "DD/MM/YYYY").format("MM/DD/YYYY");
      const dateEnd = moment(e.dateEnd, "DD/MM/YYYY").format("MM/DD/YYYY");

      setPeriodInitialDate(dateStart);
      setPeriodFinalDate(dateEnd);
    }
  };

  const handleClearPeriodFilter = () => {
    setPeriodInitialDate("");
    setPeriodFinalDate("");
  };

  const handleFetchData = useCallback(async () => {
    try {
      if (actualPage === nPages) {
        return;
      }

      setLoadingInfinit(true);

      const response = await api.makeHttpRequest({
        method: "GET",
        url: "/plp",
        params: {
          page: actualPage + 1,
          resultsPerPage: 20,
          ...(periodInitialDate && {
            periodInitialDate: periodInitialDate
          }),
          ...(periodFinalDate && {
            periodFinalDate: periodFinalDate
          }),
          ...(search && { text: search })
        }
      });
      setPLPs(previousState => [...previousState, ...response.results]);
      setActualPage(Number(response.actualPage));
      setNPages(Number(response.nPages));
    } catch (e) {
      console.log(e);
      Swal.fire({
        icon: "error",
        title: "Acompanhamento da PLP",
        text: "Não foi possível carregar os pedidos."
      });
    } finally {
      setLoadingInfinit(false);
    }
  }, [actualPage, api, periodFinalDate, periodInitialDate, search, nPages]);

  const getPLPs = useCallback(
    debounce(async (search, initial, final) => {
      setLoading(true);

      try {
        const response = await api.makeHttpRequest({
          method: "GET",
          url: "/plp",
          params: {
            resultsPerPage: 20,
            page: 1,
            ...(initial && {
              periodInitialDate: initial
            }),
            ...(final && {
              periodFinalDate: final
            }),
            ...(search && { text: search })
          }
        });

        setPLPs(response.results);
        setActualPage(Number(response.actualPage));
        setNPages(Number(response.nPages));

        setLoading(false);

        if (response.results.length === 20) {
          setHasMore(true);
        } else {
          setHasMore(false);
        }
      } catch (e) {
        console.log(e);
        Swal.fire({
          icon: "error",
          title: "Acompanhamento da PLP",
          text: "Não foi possível carregar os pedidos."
        });
      } finally {
        setLoading(false);
      }
    }, 500),
    []
  );

  useEffect(() => {
    window.setPageTitle("Acompanhamento da PLP");
  }, []);

  useEffect(() => {
    getPLPs(search, periodInitialDate, periodFinalDate);
  }, [periodInitialDate, periodFinalDate, search]);

  const setStatus = status => {
    const enumPLP = Object.entries(PLPStatusEnum);
    const statusPLP = enumPLP.find(sc => sc[1].value === status);
    return statusPLP && statusPLP[1]?.label;
  };

  return (
    <>
      {<Loading loading={loading} />}
      <Container title="Acompanhamento da PLP">
        <div className={styles.filtersContainer}>
          <div className={`${styles.inputContainer}`}>
            <InputText
              id="buscar"
              placeholder="Buscar pelo número da PLP"
              value={search}
              onChange={e => setSearch(e.target.value)}
              renderIcon={() =>
                !search?.length ? (
                  <FiSearch size={20} color="#409e6a" />
                ) : (
                  <MdClear
                    style={{ cursor: "pointer" }}
                    onClick={() => setSearch("")}
                  />
                )
              }
            />
          </div>
          <div className={`${styles.inputContainer}`}>
            <div className={styles.input}>
              <DatePicker
                id="date"
                className={`datePickerConfig`}
                change={handleOnChangeCalendarPeriod}
                placeholder="Filtrar por período"
                showClearButton={!!periodInitialDate && !!periodFinalDate}
                onClearFields={handleClearPeriodFilter}
                range
              />
            </div>
          </div>
        </div>

        <div className={styles.tableContainer}>
          <ScrollDiv data={plps}>
            <InfiniteScroll
              dataLength={plps.length}
              next={handleFetchData}
              hasMore={hasMore}
              loader={loadingInfinit && <LoadInfiniteScroll />}
              scrollableTarget="scrollDiv"
              className={styles.infiniteScroll}
            >
              <table className={styles.table}>
                <thead className={styles.tableHead}>
                  <tr>
                    <th>PLP</th>
                    <th>Nº PLP</th>
                    <th>Data Criação</th>
                    <th>Quantidade de Envios</th>
                    <th>Status</th>
                    <th>Imprimir PLP</th>
                  </tr>
                </thead>
                <tbody className={styles.tableBody}>
                  {plps.map(plp => {
                    return (
                      <tr key={plp?.id}>
                        <td className={styles.thCodigo}>
                          <a
                            href={`detalhes-plp/${plp?.id}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {plp?.id}
                          </a>
                        </td>
                        <td className={styles.thCodigo}>
                          {plp?.status === PLPEnum.FINISHED
                            ? plp?.number
                            : "--"}
                        </td>
                        <td>
                          <p>
                            {plp?.created_at &&
                              moment(plp?.created_at).format("DD/MM/YYYY")}
                          </p>
                        </td>
                        <td>
                          {" "}
                          <p>{plp?.plpShipping?.length}</p>{" "}
                        </td>
                        <td className={styles.thStatus}>
                          <span
                            className={
                              plp?.status === PLPEnum.PENDING
                                ? styles.gray
                                : plp?.status === PLPEnum.FINISHED
                                ? styles.delivered
                                : styles.error
                            }
                            style={{
                              borderRadius: "4px",
                              padding: "5px 4px"
                            }}
                          >
                            {setStatus(plp?.status)}
                          </span>
                        </td>
                        <td className={styles.thCodigo}>
                          {plp?.status === PLPEnum.FINISHED ? (
                            <StyledTooltip text="Imprimir" position="top" arrow>
                              <a
                                href={`plp/${plp?.id}`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <FiPrinter size={20} color="#409E6A" />
                              </a>
                            </StyledTooltip>
                          ) : (
                            <p>Aguardando</p>
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </InfiniteScroll>
          </ScrollDiv>
        </div>
      </Container>
    </>
  );
};

export { PLPCreated };
