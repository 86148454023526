import React, { useState, useCallback } from 'react';

import { ErrorRequest } from './styles';

export const useMessage = (time) => {

  const [showError, setShowError] = useState(false);

  const handleShowError = useCallback(() => {
    setShowError(true);
    setTimeout(() => setShowError(false), (time * 1000));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const Message = ({ message, type, linkOnClick, linkLabel }) => {
    return (
      showError && <ErrorRequest data={{ type: type, time: time }}>
        <p>{message}
        {linkLabel && <span onClick={linkOnClick}>{linkLabel}</span>}</p>
      </ErrorRequest>
    );
  };

  return { Message, handleShowError };
}
