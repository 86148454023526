import React from "react";
import Select from "react-select";

import styles from "./inputSelect.module.scss";
import { selectStyle } from "../InputSelectAsync";

const InputSelect = ({
  id,
  label,
  disabled,
  hasError,
  options,
  errorMessage,
  ...rest
}) => {
  return (
    <div
      className={`
      ${disabled ? styles.selectDisabled : ""}
      ${styles.selectWrapper} ${hasError === true ? styles.notValid : ""}
      ${hasError === false ? styles.valid : ""}
    `}
    >
      <label htmlFor={id}>{label}</label>
      <Select
        isClearable
        id={id}
        isDisabled={disabled}
        options={options}
        placeholder=""
        styles={selectStyle}
        noOptionsMessage={({ inputValue }) =>
          !inputValue && "Resultados não encontrados"
        }
        {...rest}
      />

      {hasError && <p className={styles.errorMessage}>{errorMessage}</p>}
    </div>
  );
};

export default InputSelect;
