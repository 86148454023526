const cpfValidation = ({ cpf, emptyCpf, incorrectCpf }) => {
    let value = cpf.replace(/[^\w]/g, "");
    let Soma;
    let Resto;
    let i;
    Soma = 0;
    if (value === "00000000000")
        return {
            message: emptyCpf,
            status: "notOk"
        };

    for (i = 1; i <= 9; i++)
        Soma = Soma + parseInt(value.substring(i - 1, i)) * (11 - i);
    Resto = (Soma * 10) % 11;

    if (Resto === 10 || Resto === 11) Resto = 0;
    if (Resto !== parseInt(value.substring(9, 10)))
        return {
            message: incorrectCpf,
            status: "notOk"
        };

    Soma = 0;
    for (i = 1; i <= 10; i++)
        Soma = Soma + parseInt(value.substring(i - 1, i)) * (12 - i);
    Resto = (Soma * 10) % 11;

    if (Resto === 10 || Resto === 11) Resto = 0;
    if (Resto !== parseInt(value.substring(10, 11)))
        return {
            message: incorrectCpf,
            status: "notOk"
        };
    return {
        message: "",
        status: "ok"
    };
};

export { cpfValidation };