import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { BrComerceApiService } from "../../../../services/BrComerceApiService";
import store from "../../../../store/store";
import { Loading } from "../../../../components/Loading";

const NuvemshopCallback = () => {
  const history = useHistory();
  const api = new BrComerceApiService();

  const { auth } = store.getState();

  const { user } = auth;

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    window.localStorage.removeItem("Brcom:NuvemshopCode:");

    const code = history.location.search.split("=")[1];
    setLoading(true);
    api
      .makeHttpRequest({
        method: "POST",
        url: `/nuvemshop/users`,
        data: {
          code,
          id: user.id
        }
      })
      .then(response => {
        history.push("/integracao/nuvemshop");
      })
      .catch(e => console.log(e.response))
      .finally(() => setLoading(false));
  }, [history.location.search]);
  return (
    <>
      <Loading loading={loading} />
    </>
  );
};

export { NuvemshopCallback };
