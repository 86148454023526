import React, { useCallback, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Loading } from "../../../../../components/Loading";
import Select from "react-select";
import styles from "./styles.module.scss";
import { BrComerceApiService } from "../../../../../services/BrComerceApiService.js";
import Swal from "sweetalert2";

const Bling = () => {
  const api = new BrComerceApiService();

  const history = useHistory();

  const [loading, setLoading] = useState(false);

  const [userApiKey, setUserApiKey] = useState("");
  const [userApiKeyOK, setUserApiKeyOK] = useState("");
  const [userApiKeyError, setUserApiKeyError] = useState("");

  const [hasUserApiKey, setHasUserApiKey] = useState(false);

  const [selectedStatus, setSelectedStatus] = useState([]);
  const [selectedStatusOK, setSelectedStatusOK] = useState("");
  const [selectedStatusError, setSelectedStatusError] = useState("");

  const [selectedDestinyStatus, setSelectedDestinyStatus] = useState(null);
  const [selectedDestinyStatusOK, setSelectedDestinyStatusOK] = useState("");

  const [selectedStatusCreated, setSelectedStatusCreated] = useState({
    value: 15,
    label: "Em andamento"
  });
  const [selectedStatusCreatedOK, setSelectedStatusCreatedOK] = useState("");
  const [selectedStatusCreatedError, setSelectedStatusCreatedError] = useState(
    ""
  );

  const [optionsStatuses, setOptionsStatuses] = useState([]);
  const [activeInsurance, setActiveInsurance] = useState(false);
  const [activeCreateOrder, setActiveCreateOrder] = useState(true);
  const [senderName, setSenderName] = useState("");
  const [senderNameOK, setSenderNameOK] = useState("");
  const [senderNameError, setSenderNameError] = useState("");

  const selectStyle = {
    control: styles => ({
      ...styles,
      backgroundColor: "inherit",
      width: "100%",
      border: "none",
      boxShadow: "none",
      outline: "none",
      color: "#807E80",
      padding: "0",
      "&:hover": {
        borderColor: "none"
      },
      cursor: "pointer"
    }),
    container: styles => ({
      ...styles,
      width: "100%",
      outline: "none",
      border: "none",
      padding: "2px 0",
      cursor: "pointer"
    }),
    indicatorSeparator: styles => ({
      ...styles,
      display: "none",
      border: "none"
    }),
    option: (styles, { isSelected }) => ({
      ...styles,
      fontSize: "14px",
      fontFamily: "Mulish",
      fontWeight: 400,
      color: isSelected ? "#fff" : "#807E80",
      "&:hover": {
        backgroundColor: "#c1c1c1",
        color: "#fff",
        cursor: "pointer"
      },

      backgroundColor: isSelected ? "#409e6a" : "#fff"
    }),
    singleValue: styles => ({
      ...styles,
      color: "#807E80",
      fontSize: "14px"
    }),
    placeholder: styles => ({
      ...styles,
      fontSize: "14px",
      fontFamily: "Mulish",
      fontWeight: 400,
      color: "rgba(193, 193, 193, 1)",
      whiteSpace: "nowrap"
    })
  };

  const handleInputSenderName = useCallback(e => {
    const name = e.currentTarget.value;

    if (name.length < 1) {
      setSenderName("");
      setSenderNameError("");
      setSenderNameOK("vazio");
    } else if (name.length < 2) {
      setSenderName(name);
      setSenderNameError("Nome do remetente deve ter pelo menos 2 caracteres");
      setSenderNameOK("notOk");
    } else {
      setSenderName(name);
      setSenderNameError("");
      setSenderNameOK("ok");
    }
  }, []);

  const handleChangeSelectStatus = e => {
    if (e === null) {
      setSelectedStatus(e);
      setSelectedStatusOK("notOk");
      setSelectedStatusError("Selecione ao menos uma opção");
    } else {
      setSelectedStatus(e);
      setSelectedStatusOK("ok");
      setSelectedStatusError("");
    }
  };

  const handleChangeSelectDestinyStatus = e => {
    setSelectedDestinyStatus(e);
    setSelectedDestinyStatusOK("ok");
  };

  const handleChangeSelectCreatedStatus = e => {
    if (e === null) {
      setSelectedStatusCreated(e);
      setSelectedStatusCreatedOK("notOk");
      setSelectedStatusCreatedError("Selecione uma opção");
    } else {
      setSelectedStatusCreated(e);
      setSelectedStatusCreatedOK("ok");
      setSelectedStatusCreatedError("");
    }
  };

  const handleSubmit = useCallback(
    async (key, value) => {
      api.makeHttpRequest({
        url: "/bling/config/key",
        method: "POST",
        data: {
          key: key,
          value: value
        }
      });
    },
    [api]
  );

  const handleChangeUserApiKey = e => {
    let value = e.currentTarget.value;

    if (value.length < 2) {
      setUserApiKey(value);
      setUserApiKeyOK("notOk");
      setUserApiKeyError("Campo obrigatório");
    } else {
      setUserApiKey(value);
      setUserApiKeyOK("ok");
      setUserApiKeyError("");
    }
  };

  const sendData = async () => {
    const selectedDestinyStatusValue = selectedDestinyStatus?.value;
    const selectedStatusCreatedValue = selectedStatusCreated?.value;
    const hasDuplicatedStatus =
      selectedStatus?.length &&
      (selectedStatus.filter(r => r.value === selectedDestinyStatusValue)
        .length > 0 ||
        selectedStatus.filter(r => r.value === selectedStatusCreatedValue)
          .length > 0);

    if (
      hasDuplicatedStatus ||
      selectedDestinyStatusValue === selectedStatusCreatedValue
    ) {
      return Swal.fire({
        title: "Status duplicado",
        text: "Cada etapa deve ter status diferentes",
        icon: "warning"
      });
    }

    try {
      await Promise.all([
        handleSubmit("USER_API_KEY", userApiKey),
        senderName
          ? handleSubmit("SENDER_DEFAULT_NAME", senderName)
          : undefined,
        selectedStatus.length > 0
          ? handleSubmit(
            "STATUSES_TO_ADD_ORDER",
            JSON.stringify(selectedStatus)
          )
          : undefined,
        selectedDestinyStatus
          ? handleSubmit(
            "STATUS_AFTER_CREATE_ORDER",
            JSON.stringify(selectedDestinyStatus)
          )
          : undefined,
        activeInsurance
          ? handleSubmit("IS_ACTIVE_INSURANCE", JSON.stringify("true"))
          : handleSubmit("IS_ACTIVE_INSURANCE", JSON.stringify("false")),
        activeCreateOrder
          ? handleSubmit("IS_INACTIVE_ORDER_CREATE", "false")
          : handleSubmit("IS_INACTIVE_ORDER_CREATE", "true"),
        selectedStatusCreated
          ? handleSubmit(
            "STATUS_MOVEMENT_AFTER_CREATE_ORDER",
            JSON.stringify(selectedStatusCreated)
          )
          : undefined
      ]);

      Swal.fire({
        title: "Sucesso!",
        text: "Configurações cadastradas com sucesso.",
        icon: "success",
        showCancelButton: false,
        confirmButtonText: "Ok",
        preConfirm: () => {
          Swal.close();
          setLoading(true);
          getConfigs();
          loadingConfig()
            .then(() => {
              console.log("Configurações ok");
            })
            .catch(() => {
              Swal.fire({
                title: "Erro!",
                text:
                  "Não foi possível carregar as informações da loja da Bling.",
                icon: "error"
              });
            })
            .finally(() => {
              setHasUserApiKey(true);
              setLoading(false);
            });
        }
      });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Ocorreu um erro!",
        text: "Erro ao cadastrar configurações"
      });
    }
  };

  useEffect(() => {
    window.setPageTitle("Integrações | Bling");
  }, []);

  const getConfigs = async () => {
    try {
      setLoading(true);
      const response = await api.makeHttpRequest({
        method: "GET",
        url: `/bling/status`
      });

      const responseHandled = response.map(status => ({
        value: status.id,
        label: status.nome
      }));

      setOptionsStatuses(responseHandled);
    } catch (e) {
      Swal.fire({
        title: "Erro!",
        text: "Não foi possível carregar as opções de status da Bling.",
        icon: "error"
      });
    }
  };

  useEffect(() => {
    (async () => {
      if (hasUserApiKey || userApiKey) {
        getConfigs();
        setLoading(false);
      }
    })();
  }, [hasUserApiKey, userApiKey]);

  const loadingConfig = useCallback(async () => {
    await Promise.all([
      api
        .makeHttpRequest({
          method: "GET",
          url: `/bling/config/key/STATUSES_TO_ADD_ORDER`
        })
        .then(res => {
          if (res) {
            const statusToAddHandled = JSON.parse(res || "[]");
            setSelectedStatus(statusToAddHandled);
            setSelectedStatusOK("ok");
          }
        }),
      api
        .makeHttpRequest({
          method: "GET",
          url: `/bling/config/key/STATUS_AFTER_CREATE_ORDER`
        })
        .then(res => {
          if (res) {
            const statusAfterCreateHandled = JSON.parse(res || "null");
            setSelectedDestinyStatus(statusAfterCreateHandled);
            setSelectedDestinyStatusOK("ok");
          }
        }),
      api
        .makeHttpRequest({
          method: "GET",
          url: `/bling/config/key/STATUS_MOVEMENT_AFTER_CREATE_ORDER`
        })
        .then(res => {
          if (res) {
            const statusMovementAfterCreateHandled = JSON.parse(res || "null");
            setSelectedStatusCreated(statusMovementAfterCreateHandled);
            setSelectedStatusCreatedOK("ok");
          }
        }),
      api
        .makeHttpRequest({
          method: "GET",
          url: `/bling/config/key/IS_ACTIVE_INSURANCE`
        })
        .then(active => {
          if (active.includes("true")) {
            setActiveInsurance(true);
          }
        }),
      api
        .makeHttpRequest({
          method: "GET",
          url: `/bling/config/key/IS_INACTIVE_ORDER_CREATE`
        })
        .then(inactive => {
          if (inactive === "true") {
            setActiveCreateOrder(false);
          }
        }),
      api
        .makeHttpRequest({
          method: "GET",
          url: `/bling/config/key/USER_API_KEY`
        })
        .then(userApiKey => {
          if (userApiKey) {
            setUserApiKey(userApiKey);
            setUserApiKeyOK("ok");
            setHasUserApiKey(true);
          }
        }),
      api
        .makeHttpRequest({
          method: "GET",
          url: `/bling/config/key/SENDER_DEFAULT_NAME`
        })
        .then(name => {
          if (name) {
            setSenderName(name);
            setSenderNameOK("ok");
          }
        })
    ]);
  }, [api]);

  useEffect(() => {
    setLoading(true);

    loadingConfig()
      .then(() => {
        console.log("Configurações ok");
      })
      .catch(() => {
        Swal.fire({
          title: "Erro!",
          text: "Não foi possível carregar as informações da loja da Bling.",
          icon: "error"
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <>
      <Loading loading={loading} />
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.contentHeader}>
            <h2>Configurações</h2>
          </div>
          <div className={styles.contentBody}>
            <section className={styles.firstSection}>
              <div>
                <p>Informe a API Key de usuário da Bling.</p>
                <div className={styles.contentSection}>
                  <div className={styles.input} role={userApiKeyOK}>
                    <input
                      type="text"
                      name="userApiKey"
                      value={userApiKey}
                      onChange={handleChangeUserApiKey}
                      placeholder="Digite a API Key"
                    />
                  </div>
                  {userApiKeyError && (
                    <p className={styles.error}>{userApiKeyError}</p>
                  )}
                </div>
              </div>
              {hasUserApiKey && (
                <>
                  <div>
                    <p>
                      Selecione abaixo o(s) status dos pedidos que deverão ser
                      importados pela integração:
                    </p>
                    <div className={styles.contentSection}>
                      <div className={styles.input} role={selectedStatusOK}>
                        <Select
                          onChange={handleChangeSelectStatus}
                          options={optionsStatuses}
                          value={selectedStatus}
                          placeholder="Selecione o status"
                          styles={selectStyle}
                          isMulti
                        />
                      </div>
                      {selectedStatusError && (
                        <p className={styles.error}>{selectedStatusError}</p>
                      )}
                    </div>
                  </div>
                  <div>
                    <p>
                      Selecione abaixo para qual status os pedidos deverão ser
                      atualizados depois de importados:
                    </p>
                    <div className={styles.contentSection}>
                      <div
                        className={styles.input}
                        role={selectedDestinyStatusOK}
                      >
                        <Select
                          onChange={handleChangeSelectDestinyStatus}
                          options={optionsStatuses}
                          value={selectedDestinyStatus}
                          placeholder="Selecione o status"
                          styles={selectStyle}
                        />
                      </div>
                    </div>
                  </div>
                  <div className={styles.sectionTitle}>
                    <p>
                      Informe o nome de remetente que deve constar nas etiquetas
                      de envio geradas pela plataforma.
                    </p>
                    <div className={styles.contentSection}>
                      <div className={styles.divContainer}>
                        <div className={styles.input} role={senderNameOK}>
                          <input
                            onChange={handleInputSenderName}
                            value={senderName}
                            placeholder="Digite o nome do remetente"
                            type="text"
                            name="senderName"
                          />
                        </div>
                        {senderNameError && (
                          <p className={styles.error}>{senderNameError}</p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div>
                    <p>
                      Selecione abaixo o status dos pedidos que deverão ser
                      movidos após a criação do pedido/coleta:
                    </p>
                    <div className={styles.contentSection}>
                      <div
                        className={styles.input}
                        role={selectedStatusCreatedOK}
                      >
                        <Select
                          onChange={handleChangeSelectCreatedStatus}
                          options={optionsStatuses}
                          value={selectedStatusCreated}
                          placeholder="Selecione o status"
                          styles={selectStyle}
                        />
                      </div>
                      {selectedStatusCreatedError && (
                        <p className={styles.error}>
                          {selectedStatusCreatedError}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className={styles.wrapper}>
                    <h4>Criação de pedido</h4>
                    <br />
                    <p>
                      Abaixo é possível ativar ou desatitvar a criação de pedidos.
                      <br />
                      Serviços como cotação funcionarão normalmente.
                    </p>
                    <br />
                    <div>
                      <div className={styles.switchOption}>
                        <label className={styles.switch}>
                          <input
                            type="checkbox"
                            onChange={event =>
                              setActiveCreateOrder(event.target.checked)
                            }
                            checked={activeCreateOrder}
                          />
                          <span
                            className={`${styles.slider} ${styles.round}`}
                          ></span>
                        </label>
                        {activeCreateOrder ? "Ativado" : "Desativado"}
                      </div>
                    </div>
                  </div>

                  <div>
                    <h4>Configuração de Seguro</h4>
                    <p style={{ marginTop: "16px" }}>
                      Abaixo é possível ativar ou desatitvar a cotação de frete
                      com Seguro. Essa opção é válida para todos os fretes{" "}
                      <br /> que são cotados.
                    </p>
                    <div className={styles.contentSection}>
                      <div className={styles.switchOption}>
                        <label className={styles.switch}>
                          <input
                            type="checkbox"
                            onChange={event =>
                              setActiveInsurance(event.target.checked)
                            }
                            checked={activeInsurance}
                            disabled={
                              !(
                                userApiKey &&
                                selectedStatus &&
                                selectedDestinyStatus
                              ) ||
                              senderNameOK === "notOk" ||
                              userApiKeyOK === "notOk"
                            }
                          />
                          <span
                            className={`${styles.slider} ${styles.round}`}
                          ></span>
                        </label>
                        {activeInsurance ? "Ativado" : "Desativado"}
                      </div>
                    </div>
                  </div>
                </>
              )}
              <div>
                <h4>Desejo selecionar endereço da Loja</h4>
                <button
                  onClick={async () => {
                    await handleSubmit("USER_API_KEY", userApiKey);
                    history.push(
                      "/nova-coleta/normal-send-form?action=choose-bling-address"
                    );
                  }}
                  className={styles.btnImport}
                  disabled={!userApiKey}
                >
                  Selecionar Endereço
                </button>
              </div>
              <div className={styles.btnContainer}>
                <button
                  type="submit"
                  onClick={sendData}
                  className={styles.btnSave}
                  disabled={
                    !userApiKey ||
                    (userApiKey &&
                      selectedStatusOK !== "ok" &&
                      selectedDestinyStatusOK !== "ok") ||
                    senderNameOK === "notOk" ||
                    userApiKeyOK === "notOk"
                  }
                >
                  Salvar
                </button>
              </div>
            </section>
          </div>
        </div>
      </div>
    </>
  );
};

export { Bling };
