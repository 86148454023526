import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Col, Row } from "react-bootstrap";
import InfiniteScroll from "react-infinite-scroll-component";
import Swal from "sweetalert2";
import LoadInfiniteScroll from "../../../../components/LoadInfiniteScroll";
import { Loading } from "../../../../components/Loading";
import { BrComerceApiService } from "../../../../services/BrComerceApiService";
import Accordion from "../../components/Conciliation/Accordion";
import ScrollDiv from "../../components/ScrollDiv";
import styles from "./styles.module.scss";
import ShippingStagesEnum from "../../../../enums/ShippingStagesEnum";
import { MdClear } from "react-icons/md";
import InputSelect from "../../../../components/InputSelect";
import { RateTypeShippingEnum } from "../../../../enums/RateTypeShippingEnum";
import InputSelectAsync from "../../../../components/InputSelectAsync";
import store from "../../../../store/store";
import ProfileEnum from "../../../../enums/ProfileEnum";

const Conciliation = () => {
  const api = new BrComerceApiService();

  const { auth } = store.getState();
  const { user } = auth;
  const isAdmin = user?.profile === ProfileEnum.ADMIN.value;
  const isManager = user?.profile === ProfileEnum.MANAGER.value;

  const [allConciliation, setAllConciliation] = useState([]);
  const [actualPage, setActualPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [loadingInfinit, setLoadingInfinit] = useState(false);

  const [search, setSearch] = useState("");
  const [searchRateType, setSearchRateType] = useState(null);
  const [searchStatus, setSearchStatus] = useState(null);
  const [searchClient, setSearchClient] = useState(null);

  const [hasMore, setHasMore] = useState(true);

  const optionsCheck = [
    { value: RateTypeShippingEnum.COLLECT, label: "Coleta" },
    { value: RateTypeShippingEnum.RETAIL, label: "Correios" }
  ];

  const handleClearSearchFilter = () => {
    setSearch("");
  };

  const handleFetchData = useCallback(async () => {
    setLoadingInfinit(true);
    try {

      const response = await api.makeHttpRequest({
        method: "GET",
        url: `/shippingConciliation`,
        params: {
          resultPerPage: 20,
          page: actualPage + 1,
          ...(search && { text: search }),
          ...(searchStatus && { idStatus: searchStatus?.value }),
          ...(searchClient && { client: searchClient?.label }),
          ...(searchRateType && { rateType: searchRateType?.value })
        }
      });
      setAllConciliation([...allConciliation, ...response.results]);
      setActualPage(Number(response.actualPage));

      if (response.results.length === 20) {
        setHasMore(true);
      } else {
        setHasMore(false);
      }
    } catch (e) {
      console.log(e);
      Swal.fire({
        icon: "error",
        title: "Conciliação",
        text: "Não foi possível carregar as conciliações."
      });
    } finally {
      setLoadingInfinit(false);
    }
  }, [
    actualPage,
    allConciliation,
    api,
    search,
    searchClient,
    searchRateType,
    searchStatus
  ]);

  const getConciliation = useCallback(async () => {
    setLoading(true);
    try {
      const response = await api.makeHttpRequest({
        method: "GET",
        url: `/shippingConciliation`,
        params: {
          resultPerPage: 20,
          page: 1,
          ...(search && { text: search }),
          ...(searchStatus && { idStatus: searchStatus?.value }),
          ...(searchClient && { client: searchClient?.label }),
          ...(searchRateType && { rateType: searchRateType?.value })
        }
      });
      setAllConciliation(response.results);
      setActualPage(Number(response.actualPage));

      if (response.results.length === 20) {
        setHasMore(true);
      } else {
        setHasMore(false);
      }
    } catch (e) {
      console.log(e);
      Swal.fire({
        icon: "error",
        title: "Conciliação",
        text: "Não foi possível carregar as conciliações."
      });
    } finally {
      setLoading(false);
    }
  }, [search, searchClient?.label, searchRateType, searchStatus]);

  const getOptionsStagesCollect = useMemo(() => {
    const optionsHandled = Object.entries(ShippingStagesEnum).reduce(
      (acc, [key, value]) => {
        if (key === "PAGAMENTO") {
          const { PAGAMENTO_NEGADO, PAGAMENTO_APROVADO } = value;

          return [
            ...acc,
            { value: PAGAMENTO_NEGADO.id, label: PAGAMENTO_NEGADO.message },
            { value: PAGAMENTO_APROVADO.id, label: PAGAMENTO_APROVADO.message }
          ];
        }

        if (key === "AGENDAMENTO") {
          const { AGUARDANDO_AGENDAMENTO, COLETA_AGENDADA } = value;

          return [
            ...acc,
            {
              value: AGUARDANDO_AGENDAMENTO.id,
              label: AGUARDANDO_AGENDAMENTO.message
            },
            { value: COLETA_AGENDADA.id, label: COLETA_AGENDADA.message }
          ];
        }

        if (key === "COLETA") {
          const { COLETA_CONCLUIDA, COLETA_CANCELADA } = value;

          return [
            ...acc,
            { value: COLETA_CONCLUIDA.id, label: COLETA_CONCLUIDA.message },
            { value: COLETA_CANCELADA.id, label: COLETA_CANCELADA.message }
          ];
        }

        return [...acc, { value: value.id, label: value.message }];
      },
      []
    );

    return [{ value: undefined, label: "Todos" }, ...optionsHandled];
  }, []);

  useEffect(() => {
    window.setPageTitle("Conciliação");
    getConciliation();
  }, [getConciliation]);

  return (
    <>
      {<Loading loading={loading} />}
      <Row className={styles.container}>
        <Col className={`col ${styles.leftContainer}`}>
          <div className={styles.header}>
            <h1 className={styles.title}>Conciliação</h1>
          </div>

          <div className={styles.information}>
            <p>
              A Conciliação consiste na comparação entre as informações do frete
              contratado e do objeto coletado ou postado em agência. Em caso de
              divergência, o ajuste pode tanto ocorrer para menor, gerando
              estorno em créditos, quanto para maior, gerando cobrança
              adicional. Esse processo é automático e não demanda qualquer ação
              manual do ciente.
            </p>
          </div>

          <div className={styles.filtersContainer}>
            {(isAdmin || isManager) && (
              <div className={`${styles.inputContainer}`}>
                <InputSelectAsync
                  search={searchClient}
                  setSearch={setSearchClient}
                />
              </div>
            )}
            <div className={`${styles.inputContainer}`}>
              <div className={styles.input}>
                <input
                  type="text"
                  name="search"
                  placeholder="Digite o código da etiqueta"
                  onChange={e => setSearch(e.target.value.toUpperCase())}
                  value={search}
                />

                {!!search?.length && (
                  <MdClear
                    style={{ cursor: "pointer" }}
                    onClick={handleClearSearchFilter}
                  />
                )}
              </div>
            </div>

            <div className={`${styles.inputContainer}`}>
              <InputSelect
                id="select_rate"
                placeholder="Tipo de envio"
                options={optionsCheck}
                onChange={e => {
                  setSearchRateType(e);
                }}
                value={searchRateType}
              />
            </div>

            <div className={`${styles.inputContainer}`}>
              <InputSelect
                id="searchStatus"
                placeholder="Selecione o status"
                onChange={e => setSearchStatus(e)}
                options={getOptionsStagesCollect}
                value={
                  searchStatus ||
                  getOptionsStagesCollect.find(item => item.value === "all")
                }
              />
            </div>
          </div>

          <ScrollDiv data={allConciliation}>
            <InfiniteScroll
              dataLength={allConciliation.length}
              next={handleFetchData}
              hasMore={hasMore}
              loader={loadingInfinit && <LoadInfiniteScroll />}
              scrollableTarget="scrollDiv"
              className={styles.infiniteScroll}
            >
              <div className={styles.collectWrapper}>
                <Accordion
                  conciliation={allConciliation}
                  setAllConciliation={setAllConciliation}
                  setSearch={setSearch}
                  setLoading={setLoading}
                  search={search}
                />
              </div>
            </InfiniteScroll>
          </ScrollDiv>
        </Col>
      </Row>
    </>
  );
};

export { Conciliation };
