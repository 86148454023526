export function capitalizeFirstLetter(phrase) {
  const words = phrase?.split(" ");

  const wordsWithFirstLetterCapitalized = words?.map(word => {
    const firstLetterUpperCase = word[0]?.toUpperCase() ?? "";
    const string = word?.toLowerCase()?.slice(1) ?? "";

    return firstLetterUpperCase + string;
  })

  return wordsWithFirstLetterCapitalized?.join(" ");
}
