import axios from "axios";
import { isExpirationDateValid } from "creditcard.js";
import React, { useCallback, useEffect, useState } from "react";
import { AiOutlineCheck } from "react-icons/ai";
import { BsExclamation } from "react-icons/bs";
import ReactModal from "react-modal";
import Select from "react-select";
import Swal from "sweetalert2";
import { ReactComponent as SvgChecked } from "../../../../assets/icons/checked.svg";
import { generateCardToken } from "../../../../crud/external.crud";

import greenBin from "../../../../assets/icons/greenBin.svg";
import greenCircleInfo from "../../../../assets/icons/greenCircleInfo.svg";
import LoadInfiniteScroll from "../../../../components/LoadInfiniteScroll";
import { BrComerceApiService } from "../../../../services/BrComerceApiService";
import { cpfValidation } from "../../../../utils/CpfValidation";
import { getCardFlag } from "../../../../utils/CreditCardFlags";
import CardFlag from "../CardFlag";
import { ButtonsContainer, Container } from "./styles";

import { RateTypeShippingEnum } from "../../../../enums/RateTypeShippingEnum";
import store from "../../../../store/store";
import styles from "./styles.module.scss";

const CardPayment = ({
  setLoading,
  packagesSelected,
  setGreenClockModal,
  setSuccessModal,
  setErrorModal,
  setResponseCollectId,
  total,
  setPaymentError,
  collectId = null,
  setGoToPayment,
  handleCollectSelection
}) => {
  const selectStyle = {
    control: styles => ({
      ...styles,
      backgroundColor: "inherit",
      width: "100%",
      border: "none",
      boxShadow: "none",
      outline: "none",
      color: "#807E80",
      padding: "0",
      "&:hover": {
        borderColor: "none"
      },
      maxHeight: "48px",
      cursor: "pointer"
    }),
    container: styles => ({
      ...styles,
      width: "100%",
      outline: "none",
      border: "none",
      padding: "0",
      cursor: "pointer"
    }),
    indicatorSeparator: styles => ({
      ...styles,
      display: "none",
      border: "none"
    }),
    option: (styles, { isSelected }) => ({
      ...styles,
      fontSize: "14px",
      fontFamily: "Mulish",
      fontWeight: 400,
      color: isSelected ? "#fff" : "#807E80",
      "&:hover": {
        backgroundColor: "#c1c1c1",
        color: "#color: #333333;",
        cursor: "pointer"
      },

      backgroundColor: isSelected ? "#409e6a" : "#fff"
    }),
    singleValue: styles => ({
      ...styles,
      color: "#333333",
      fontSize: "14px",
      top: "60%"
    }),
    placeholder: styles => ({
      ...styles,
      fontSize: "14px",
      fontFamily: "Mulish",
      fontWeight: 400,
      color: "#c1c1c1",
      whiteSpace: "nowrap"
    })
  };

  const api = new BrComerceApiService();

  const { auth: { user } } = store.getState();

  const [cardLoading, setCardLoading] = useState(true);

  const [cardSelect, setCardSelect] = useState({
    label: "Novo Cartão",
    value: "new"
  });

  const [cardOptions, setCardOptions] = useState([
    {
      label: "Novo Cartão",
      value: "new"
    }
  ]);

  const [cardNumber, setCardNumber] = useState("");
  const [cardNumberOK, setCardNumberOK] = useState("vazio");
  const [cardNumberError, setCardNumberError] = useState("");

  const [name, setName] = useState("");
  const [nameOK, setNameOK] = useState("vazio");
  const [nameError, setNameError] = useState("");

  const [cpfOK, setCpfOK] = useState("vazio");
  const [cpfError, setCpfError] = useState("");
  const [cpf, setCpf] = useState("");

  const [cardSecurityCode, setCardSecurityCode] = useState("");
  const [securityCodeOK, setSecurityCodeOK] = useState("vazio");
  const [securityCodeError, setSecurityCodeError] = useState("");

  const [expiresCardDateOK, setExpiresCardDateOK] = useState("vazio");
  const [expiresCardDateError, setExpiresCardDateError] = useState("");
  const [expiresCardDate, setExpiresCardDate] = useState("");

  const [saveCard, setSaveCard] = useState(false);

  const [zipCodeOK, setZipCodeOK] = useState("vazio");
  const [zipCodeError, setZipCodeError] = useState("");
  const [zipCode, setZipCode] = useState("");

  const [addressOK, setAddressOK] = useState("vazio");
  const [addressError, setAddressError] = useState("");
  const [address, setAddress] = useState("");

  const [addressNumberOK, setAddressNumberOK] = useState("vazio");
  const [addressNumberError, setAddressNumberError] = useState("");

  const [addressNumber, setAddressNumber] = useState("");
  const [addressIsDisabled, setAddressIsDisabled] = useState(true);

  const [addressObs, setAddressObs] = useState("");
  const [addressObsOk, setAddressObsOk] = useState("vazio");
  const [addressObserror, setAddressObsError] = useState("");

  const [addressNeighborhoodOK, setAddressNeighborhoodOK] = useState("vazio");
  const [addressNeighborhoodError, setAddressNeighborhoodError] = useState("");
  const [addressNeighborhood, setAddressNeighborhood] = useState("");
  const [
    addressNeighborhoodIsDisabled,
    setAddressNeighborhoodIsDisabled
  ] = useState(true);

  const [uf, setUf] = useState("");
  const [ufStatus, setUfStatus] = useState("");
  const [ufs, setUfs] = useState([]);
  const [ufIsDisabled, setUfIsDisabled] = useState(true);

  const [city, setCity] = useState("");
  const [cityStatus, setCityStatus] = useState("");
  const [cities, setCities] = useState([]);
  const [cityIsDisabled, setCityIsDisabled] = useState(true);

  const [country, setCountry] = useState({ label: "Brasil", value: "BR" });
  const [countryOk, setCountryStatus] = useState("ok");

  const [cardFlag, setCardFlag] = useState(null);

  const [showDeleteCardModal, setShowDeleteCardModal] = useState(false);

  const handleCardSelect = useCallback(e => {
    setCardNumber("");
    setName("");
    setCpf("");
    setExpiresCardDate("");
    setCardSecurityCode("");
    setSaveCard(false);

    setZipCode("");
    setAddress("");
    setAddressNumber("");
    setAddressObs("");
    setAddressNeighborhood("");
    setUf("");
    setCity("");

    if (e.value.brand) {
      setCardFlag(e.value.brand.toLowerCase());
    } else {
      setCardFlag("");
    }

    setCardSelect(e);
  }, []);

  const handleSaveCard = useCallback(() => {
    setSaveCard(!saveCard);
  }, [saveCard]);

  const handleCepConsult = useCallback(
    async cep => {
      try {
        setLoading(true);
        let response = await api.makeHttpRequest({
          method: 'POST',
          url: `/addressbook/getAddress`,
          data: {
            zipCode: cep
          }
        })

        if (response?.erro) {
          setZipCodeOK("notOk");
          setZipCodeError("CEP inválido.");

          setAddress("");
          setAddressOK("vazio");
          setAddressError("");

          setAddressNeighborhood("");
          setAddressNeighborhoodOK("vazio");
          setAddressNeighborhoodError("");

          setUf("");
          setUfStatus("");
          setCity("");
          setCityStatus("");
          setCountry("");

          throw new Error("Não foi possível encontrar o CEP digitado.");
        }

        setZipCodeOK("ok");
        setZipCodeError("");

        if (!response.logradouro || response.logradouro === "undefined") {
          setAddressIsDisabled(false);
          setAddressOK("notOk");
        } else {
          setAddress(response.logradouro);
          setAddressOK("ok");
          setAddressError("");
        }

        if (!response.bairro || response.bairro === "undefined") {
          setAddressNeighborhoodIsDisabled(false);
          setAddressNeighborhoodOK("notOk");
        } else {
          setAddressNeighborhood(response.bairro);
          setAddressNeighborhoodOK("ok");
          setAddressNeighborhoodError("");
        }

        setUf({
          value: response.uf,
          label: response.uf
        });
        setUfStatus("ok");

        setCity({
          value: response.localidade,
          label: response.localidade
        });
        setCityStatus("ok");

        setCountry({ value: "BR", label: "Brasil" });
        setCountryStatus("ok");
      } catch (error) {
        console.error(error);

        Swal.fire({
          icon: "error",
          title: "CEP não encontrado",
          text: "Por favor, verifique o CEP inserido."
        });
      } finally {
        setLoading(false);
      }
    },
    [setLoading]
  );

  const handleValidationZipCode = useCallback(
    async (value, isASavedInfo = false) => {
      if (value.length === 0) {
        setZipCodeOK("notOk");
        setZipCodeError("Preenchimento obrigatório");
      } else if (value.length < 9) {
        setZipCodeOK("notOk");
        setZipCodeError("Preenchimento incorreto");
      } else {
        if (isASavedInfo) {
          setZipCodeOK("ok");
          setZipCodeError("");
        }

        // Não queremos manipular o CEP novamente,
        // se o CEP selecionado pertence a um endereço já salvo
        if (isASavedInfo === false) {
          const cep = value.substr(0, 5) + value.substr(6, 3)
          await handleCepConsult(cep);
        }
      }
    },
    [handleCepConsult]
  );

  const handleInputZipCode = useCallback(
    e => {
      e.currentTarget.maxLength = 9;
      let value = e.currentTarget.value;
      value = value.replace(/\D/g, "");
      value = value.replace(/^(\d{5})(\d{3})/, "$1-$2");

      handleValidationZipCode(value);
      e.currentTarget.value = value;
      setZipCode(value);
    },
    [handleValidationZipCode]
  );

  const handleValidationAddress = useCallback(value => {
    value.length < 2 ? setAddressOK("notOk") : setAddressOK("ok");
    if (value.length === 0) {
      setAddressError("Campo obrigatório");
    } else if (value.length === 1) {
      setAddressError("Endereço deve ter pelo menos 2 caracteres");
    } else {
      setAddressError("");
    }
  }, []);

  const handleInputAddress = useCallback(
    e => {
      if (addressIsDisabled) return;

      let value = e.currentTarget.value;
      setAddress(value);
      handleValidationAddress(value);
    },
    [addressIsDisabled, handleValidationAddress]
  );

  const handleValidationAddressNeighborHood = useCallback(value => {
    value.length < 1
      ? setAddressNeighborhoodOK("notOk")
      : setAddressNeighborhoodOK("ok");
    if (value.length === 0) {
      setAddressNeighborhoodError("Campo obrigatório");
    } else {
      setAddressNeighborhoodError("");
    }
  }, []);

  const handleInputAddressNeighborhood = useCallback(
    e => {
      if (addressNeighborhoodIsDisabled) return;

      let value = e.currentTarget.value;
      setAddressNeighborhood(value);
      handleValidationAddressNeighborHood(value);
    },
    [addressNeighborhoodIsDisabled, handleValidationAddressNeighborHood]
  );

  const handleSetUfs = useCallback(async () => {
    try {
      const response = await (
        await axios.get(
          "https://servicodados.ibge.gov.br/api/v1/localidades/estados"
        )
      ).data;
      const ufOpts = response.map(resp => ({
        value: resp.sigla,
        label: resp.nome
      }));
      setUfs(ufOpts);
    } catch (error) {
      console.error(error);
    }
  }, []);

  const handleSetCities = useCallback(async () => {
    try {
      const ufSelected = ufs.find(ufOpt => ufOpt.value === uf?.value);
      const response = await (
        await axios.get(
          `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${ufSelected?.value}/municipios`
        )
      ).data;
      const ufOpts = response.map(resp => ({
        value: resp.nome,
        label: resp.nome
      }));
      setCities(ufOpts);
    } catch (error) {
      console.log(error);
    }
  }, [uf, ufs]);

  const handleSetUf = useCallback(
    e => {
      if (zipCode.length) return;

      setUf(e ? e : {});
      setUfStatus("");
    },
    [zipCode]
  );

  const handleSetCity = useCallback(
    e => {
      if (zipCode.length) return;

      setCity(e ? e : {});
      setCityStatus("");
    },
    [zipCode]
  );

  const handleValidationAddressObs = useCallback(value => {
    if (value?.length < 2 && value?.length > 0) {
      setAddressObsOk("notOk");
    } else if (value?.length >= 2) {
      setAddressObsOk("ok");
    } else {
      setAddressObsOk("vazio");
    }

    if (value?.length === 1) {
      setAddressObsError("Complemento deve ter pelo menos 2 caracteres");
    } else {
      setAddressObsError("");
    }
  }, []);

  const handleInputAddressObs = useCallback(
    e => {
      let value = e.currentTarget.value;
      setAddressObs(value);
      handleValidationAddressObs(value);
    },
    [handleValidationAddressObs]
  );

  const handleValidationAddressNumber = useCallback(value => {
    if (value !== "") {
      setAddressNumberOK("ok");
    } else {
      setAddressNumberOK("vazio");
      setAddressNumberError("");
    }
  }, []);

  const handleInputAddressNumber = useCallback(
    e => {
      let value = e.currentTarget.value;

      if (value !== "" && !Number(value)) {
        return;
      }

      setAddressNumber(value);
      handleValidationAddressNumber(value);
    },
    [handleValidationAddressNumber]
  );

  const handleValidationSecurityCode = useCallback(value => {
    if (value.length < 3) {
      setSecurityCodeOK("notOk");
    } else if (value.length >= 3) {
      setSecurityCodeOK("ok");
    } else {
      setSecurityCodeOK("vazio");
    }

    if (value.length === 0) {
      setSecurityCodeError("Campo obrigatório");
      setSecurityCodeOK("notOk");
      // random card number 9999999999999999
    } else if (value.length < 3) {
      setSecurityCodeError("3 ou 4 dígitos");
    } else {
      setSecurityCodeError("");
    }
  }, []);

  const handleInputSecurityCode = useCallback(
    e => {
      let value = e.currentTarget.value;

      if (value.length > 4) return;

      setCardSecurityCode(!isNaN(value) ? value : "");
      handleValidationSecurityCode(!isNaN(value) ? value : "");
    },
    [handleValidationSecurityCode]
  );

  const handleValidationCardExpiresDate = useCallback(value => {
    const date = value.split("");

    value.length <= 4
      ? setExpiresCardDateOK("notOk")
      : setExpiresCardDateOK("ok");

    if (value.length === 0) {
      setExpiresCardDateError("Campo obrigatório");
    } else if (value.length < 4) {
      setExpiresCardDateError("São necessários 4 dígitos");
    } else if (
      !isExpirationDateValid(`${date[0] + date[1]}`, `${date[2] + date[3]}`)
    ) {
      setExpiresCardDateError("Número inválido");
      setExpiresCardDateOK("notOk");
    } else {
      setExpiresCardDateError("");
      setExpiresCardDateOK("ok");
    }
  }, []);

  const handleInputCardValidDate = useCallback(
    e => {
      let inputValue = e.currentTarget.value.replace(/\W/gi, "");

      if (inputValue.length <= 4) {
        inputValue = inputValue.replace(/\D/g, "");
        inputValue = inputValue.replace(/^(\d{2})(\d{2})/, "$1/$2");

        handleValidationCardExpiresDate(e.currentTarget.value);
        setExpiresCardDate(inputValue);
      }
    },
    [handleValidationCardExpiresDate]
  );

  const handleValidationCPF = useCallback(value => {
    const validCPF = cpfValidation({
      cpf: value,
      emptyCpf: "Campo obrigatório",
      incorrectCpf: "CPF incorreto"
    });

    setCpfOK(validCPF.status);
    setCpfError(validCPF.message);
  }, []);

  const handleInputCpf = useCallback(
    e => {
      let inputValue = e.currentTarget.value.replace(/\W/gi, "");

      if (inputValue.length <= 11) {
        inputValue = inputValue.replace(/\D/g, "");
        inputValue = inputValue.replace(
          /^(\d{3})(\d{3})(\d{3})(\d{2})/,
          "$1.$2.$3-$4"
        );
        e.currentTarget.inputValue = inputValue;
        handleValidationCPF(inputValue);
        setCpf(inputValue);
      }
    },
    [handleValidationCPF]
  );

  const handleValidationName = useCallback(value => {
    value.length < 2 ? setNameOK("notOk") : setNameOK("ok");
    if (value.length === 0) {
      setNameError("Campo obrigatório");
    } else if (value.length === 1) {
      setNameError("Deve ser igual ao impresso no cartão");
    } else {
      setNameError("");
    }
  }, []);

  const handleInputName = useCallback(
    e => {
      let value = e.currentTarget.value;
      setName(value);
      handleValidationName(value);
    },
    [handleValidationName]
  );

  const handleValidationCardNumber = useCallback(value => {
    value.length < 15 ? setCardNumberOK("notOk") : setCardNumberOK("ok");

    if (value.length === 0) {
      setCardNumberError("Campo obrigatório");
    } else if (value.length < 15) {
      setCardNumberError("Preenchimento incorreto");
    } else {
      setCardNumberError("");
      setCardNumberOK("ok");
    }
  }, []);

  const handleInputCardNumber = useCallback(
    e => {
      let inputValue = e.currentTarget.value.replace(/\W/gi, "");

      if (inputValue.length <= 16) {
        inputValue = inputValue.replace(/\D/g, "");
        inputValue = inputValue.replace(
          /^(\d{4})(\d{4})(\d{4})(\d{4})/,
          "$1 $2 $3 $4"
        );

        handleValidationCardNumber(e.currentTarget.value);
        setCardNumber(inputValue);
        setCardFlag(getCardFlag(e.currentTarget.value));
      }
    },
    [handleValidationCardNumber]
  );

  const formatSavedCards = useCallback(
    cards =>
      cards?.map(card => {
        console.log(card);

        return {
          label: `xxxx xxxx xxxx ${card.last_four_digits}`,
          value: {
            brand: card.brand,
            cardId: card.externalId,
            billingAddress: {
              street: card.billing_address.line_1.split(",")[1],
              neighborhood: card.billing_address.line_1.split(",")[2],
              number: card.billing_address.line_1.split(",")[0],
              complement: "",
              zipCode: card.billing_address.zip_code,
              city: card.billing_address.city,
              state: card.billing_address.state,
              country: card.billing_address.country
            }
          }
        };
      }),
    []
  );

  const loadSavedCards = useCallback(async () => {
    try {
      const response = await api.makeHttpRequest({
        method: "GET",
        url: `payments/cards`
      });

      if (response.length === 0) {
        return;
      }

      const savedCards = formatSavedCards(response);

      console.log(savedCards);

      setCardOptions(prevState => {
        return [...prevState, ...savedCards];
      });

      setCardSelect(savedCards[0]);
      setCardFlag(response[0]?.brand?.toLowerCase());
    } catch (e) {
      Swal.fire({
        title: "Erro ao carregar!",
        text: "Houve um erro ao carregar os cartões salvos da sua conta",
        icon: "error"
      });
    } finally {
      setCardLoading(false);
    }
  }, [api, formatSavedCards]);

  const fieldsInFormIsAbleToSubmit = useCallback(
    (activedBySideEffect = false) => {
      let result = true;

      if (cardNumber === "") {
        if (!activedBySideEffect) {
          setCardNumberOK("notOk");
          setCardNumberError("Campo obrigatório");
          result = false;
        } else {
          return false;
        }
      }

      if (name === "") {
        if (!activedBySideEffect) {
          setNameOK("notOk");
          setNameError("Campo obrigatório");
          result = false;
        } else {
          return false;
        }
      }

      if (cpf === "") {
        if (!activedBySideEffect) {
          setCpfOK("notOk");
          setCpfError("Campo obrigatório");
          result = false;
        } else {
          return false;
        }
      }

      if (expiresCardDate === "") {
        if (!activedBySideEffect) {
          setExpiresCardDateOK("notOk");
          setExpiresCardDateError("Campo obrigatório");
          result = false;
        } else {
          return false;
        }
      }

      if (cardSecurityCode === "") {
        if (!activedBySideEffect) {
          setSecurityCodeOK("notOk");
          setSecurityCodeError("Campo obrigatório");
          result = false;
        } else {
          return false;
        }
      }

      if (zipCode === "") {
        if (!activedBySideEffect) {
          setZipCodeOK("notOk");
          setZipCodeError("Campo obrigatório");
          result = false;
        } else {
          return false;
        }
      }

      if (zipCode.length && address === "") {
        if (!activedBySideEffect) {
          setAddressOK("notOk");
          setAddressError("Campo obrigatório");
          result = false;
        } else {
          return false;
        }
      }

      if (zipCode.length && addressNeighborhood === "") {
        if (!activedBySideEffect) {
          setAddressNeighborhoodOK("notOk");
          setAddressNeighborhoodError("Campo obrigatório");
          result = false;
        } else {
          return false;
        }
      }

      if (zipCode.length && uf === "") {
        if (!activedBySideEffect) {
          setUfStatus("notOk");
          result = false;
        } else {
          return false;
        }
      }

      if (zipCode.length && city === "") {
        if (!activedBySideEffect) {
          setCityStatus("notOk");
          result = false;
        } else {
          return false;
        }
      }

      if (
        cardNumberOK === "notOk" ||
        nameOK === "notOk" ||
        expiresCardDateOK === "notOk" ||
        securityCodeOK === "notOk" ||
        cpfOK === "notOk" ||
        zipCodeOK === "notOk" ||
        addressOK === "notOk" ||
        addressNeighborhoodOK === "notOk" ||
        uf === "" ||
        city === ""
      ) {
        return false;
      }

      return result;
    },
    [
      address,
      addressNeighborhood,
      addressNeighborhoodOK,
      addressOK,
      city,
      cpf,
      cpfOK,
      name,
      nameOK,
      uf,
      zipCode,
      zipCodeOK,
      cardSecurityCode,
      securityCodeOK,
      expiresCardDate,
      expiresCardDateOK,
      cardNumber,
      cardNumberOK
    ]
  );

  const handleCardPaymentButton = useCallback(
    async e => {
      e.preventDefault();

      if (!user?.canCreateCollectSchedule && !user?.isVerified) {
        try {
          setLoading(true);
          const { avaliableLimit, hasLimit, unvalidatedPackages } = await api.makeHttpRequest({
            method: "GET",
            url: `collects/retail-limit`
          });
          setLoading(false);

          if (!hasLimit) {
            Swal.fire({
              title: `Existem ${unvalidatedPackages} pacotes pendentes de envio!`,
              text: `Para prosseguir realize a postagem dos pacotes nas agências dos Correios de sua preferência`,
              icon: "error"
            });
            return;
          }

          if (packagesSelected[0].rateType === RateTypeShippingEnum.RETAIL
            && packagesSelected.length > avaliableLimit) {
            Swal.fire({
              title:
                "Limite de pacotes não postados atingido.",
              html: `Sua conta possui ${avaliableLimit} ${avaliableLimit > 1 ? "pacotes" : "pacote"} não ${avaliableLimit > 1 ? "postados disponíveis" : "postado disponível"}, desmarque alguns itens e tente novamente...`,
              icon: "warning",
              confirmButtonText: "Ok"
            });
            return;
          };
        } catch (err) {
          console.log(err);
        } finally {
          setLoading(false);
        }
      }

      if (packagesSelected.length === 0) {
        Swal.fire({
          title: "Nenhum item selecionado",
          text: "Selecione itens a serem pagos.",
          icon: "warning"
        });
        return;
      }

      if (cardSelect.value === "new") {
        const formCanSubmit = fieldsInFormIsAbleToSubmit();

        if (formCanSubmit === false) {
          Swal.fire({
            icon: "warning",
            title: "Validação",
            text:
              "É necessário que seja preenchidos todos os campos sinalizados."
          });
          return;
        }
      }

      try {
        setGreenClockModal(true);
        const data = {
          method: "credit_card",
          paymentInfo: {
            canSaveCard: saveCard,
            cvv: cardSecurityCode || undefined,
            billingAddress: {
              street: address,
              neighborhood: addressNeighborhood,
              number: addressNumber || "",
              complement: addressObs || "",
              zipCode: zipCode.replace(/\D/g, ""),
              city: city?.value,
              state: uf?.value,
              country: country?.value
            }
          },
          packages: packagesSelected.map(pack => ({ id: pack.id }))
        };

        if (cardSelect.value === "new") {
          data.paymentInfo.cardToken = await generateCardToken({
            number: cardNumber.replace(/\D/g, ""),
            holder_name: name,
            exp_month: expiresCardDate.split("/")[0],
            exp_year: expiresCardDate.split("/")[1],
            cvv: cardSecurityCode,
            label: ""
          });
        } else {
          data.paymentInfo.cardId = cardSelect.value.cardId;
          data.paymentInfo.billingAddress = cardSelect.value.billingAddress;
        }

        if (collectId) {
          const newData = {
            method: data.method,
            paymentInfo: data.paymentInfo
          };

          const retryCollectResponse = await api.makeHttpRequest({
            method: "PUT",
            url: `/collects/${collectId}/retry-payment`,
            data: newData
          });

          await handleCollectSelection(retryCollectResponse);

          setGoToPayment(false);

          setResponseCollectId(retryCollectResponse.id);
        } else {
          const collectResponse = await api.makeHttpRequest({
            method: "POST",
            url: "collects",
            data
          });
          setResponseCollectId(collectResponse.id);
        }

        setGreenClockModal(false);
        setSuccessModal(true);
      } catch (error) {
        console.error(error);
        console.log(error?.response?.data?.id);
        setPaymentError(error?.response?.status);
        setResponseCollectId(error?.response?.data?.id);
        setErrorModal(true);
      } finally {
        setGreenClockModal(false);
      }
    },
    [
      packagesSelected,
      cardSelect.value,
      fieldsInFormIsAbleToSubmit,
      setGreenClockModal,
      saveCard,
      cardSecurityCode,
      address,
      addressNeighborhood,
      addressNumber,
      addressObs,
      zipCode,
      city.value,
      uf.value,
      country.value,
      collectId,
      setSuccessModal,
      cardNumber,
      name,
      expiresCardDate,
      api,
      setGoToPayment,
      setResponseCollectId,
      setPaymentError,
      setErrorModal,
      handleCollectSelection
    ]
  );

  const confirmDeleteCreditCard = useCallback(async () => {
    try {
      setLoading(true);
      await api.makeHttpRequest({
        url: `payments/cards/${cardSelect.value.cardId}`,
        method: "DELETE"
      });

      setCardOptions(previousState =>
        previousState.filter(
          previousCard => previousCard.value.cardId !== cardSelect.value.cardId
        )
      );

      setCardSelect({
        label: "Novo Cartão",
        value: "new"
      });

      setCardFlag("");

      Swal.fire({
        title: "Sucesso",
        icon: "success",
        text: "A deleção do cartão foi feita com sucesso!",
        confirmButtonColor: "#409e6a",
        preConfirm() {
          setShowDeleteCardModal(false);
        }
      });
    } catch (e) {
      console.log(e);
      Swal.fire({
        title: "Erro",
        text:
          e.response.data.message ||
          "Um erro ocorreu ao tentar deletar o cartão",
        icon: "error",
        confirmButtonColor: "#409e6a",
        preConfirm() {
          setShowDeleteCardModal(false);
        }
      });
    } finally {
      setLoading(false);
    }
  }, [api, cardSelect.value.cardId, setLoading]);

  const closeDeleteCardModal = () => {
    setShowDeleteCardModal(false);
  };

  useEffect(() => {
    handleSetUfs();
  }, [handleSetUfs]);

  useEffect(() => {
    if (uf) {
      handleSetCities();

    }
  }, [ufs, uf]);

  useEffect(() => {
    loadSavedCards();
  }, []);

  return cardLoading ? (
    <LoadInfiniteScroll />
  ) : (
    <>
      <div className={styles.cardPaymentContainer}>
        <div className={styles.formGroupCard}>
          <div className={styles.title}>
            <h2>Dados do Cartão</h2>
          </div>
          <div className={`${styles.formSelect} ${styles.select}`} role="ok">
            <label htmlFor="card select">Selecione um cartão</label>
            <Select
              onChange={handleCardSelect}
              options={cardOptions}
              value={cardSelect}
              placeholder="Selecione"
              styles={selectStyle}
            />

            {cardSelect?.value !== "new" && (
              <div className={styles.cardFlag}>
                <CardFlag cardFlag={cardFlag} />
              </div>
            )}
          </div>

          {cardSelect?.value === "new" && (
            <>
              <div className={styles.input} role={cardNumberOK}>
                <label htmlFor="card number">Número do cartão</label>
                <input
                  type="text"
                  name="card number"
                  placeholder="Digite o número do cartão"
                  value={cardNumber}
                  onChange={handleInputCardNumber}
                />
                {cardFlag ? (
                  <CardFlag cardFlag={cardFlag} />
                ) : (
                  <AiOutlineCheck
                    className={styles.check}
                    role={cardNumberOK}
                  />
                )}
                <BsExclamation className={styles.danger} role={cardNumberOK} />
              </div>
              {cardNumberOK && (
                <p className={styles.error}>{cardNumberError}</p>
              )}
              <div className={styles.input} role={nameOK}>
                <label htmlFor="card name">Nome impresso no cartão</label>
                <input
                  type="text"
                  name="card name"
                  placeholder="Digite o nome"
                  value={name}
                  onChange={handleInputName}
                />
                <AiOutlineCheck className={styles.check} role={nameOK} />
                <BsExclamation className={styles.danger} role={nameOK} />
              </div>
              {nameOK && <p className={styles.error}>{nameError}</p>}
              <div className={styles.input} role={cpfOK}>
                <label htmlFor="cpf">CPF do titular</label>
                <input
                  type="text"
                  name="cpf"
                  placeholder="Digite o CPF"
                  value={cpf}
                  onChange={handleInputCpf}
                />
                <AiOutlineCheck className={styles.check} role={cpfOK} />
                <BsExclamation className={styles.danger} role={cpfOK} />
              </div>
              {cpfOK && <p className={styles.error}>{cpfError}</p>}
              <div className={styles.dateAndCodeContainer}>
                <div className={styles.subDateAndCodeContainer}>
                  <div
                    className={`${styles.input} ${styles.date}`}
                    role={expiresCardDateOK}
                  >
                    <label htmlFor="valid date">Data de Validade</label>
                    <input
                      type="text"
                      name="valid date"
                      placeholder="mm/aa"
                      value={expiresCardDate}
                      onChange={handleInputCardValidDate}
                    />
                    <AiOutlineCheck
                      className={styles.check}
                      role={expiresCardDateOK}
                    />
                    <BsExclamation
                      className={styles.danger}
                      role={expiresCardDateOK}
                    />
                  </div>
                  {expiresCardDateOK && (
                    <p className={styles.error}>{expiresCardDateError}</p>
                  )}
                </div>
                <div className={styles.subDateAndCodeContainer}>
                  <div
                    className={`${styles.input} ${styles.date}`}
                    role={securityCodeOK}
                  >
                    <label htmlFor="security code">Cód. de Segurança</label>
                    <input
                      type="text"
                      name="security code"
                      placeholder="Digite o código"
                      value={cardSecurityCode}
                      onChange={handleInputSecurityCode}
                    />
                    <AiOutlineCheck
                      className={styles.check}
                      role={securityCodeOK}
                    />
                    <BsExclamation
                      className={styles.danger}
                      role={securityCodeOK}
                    />
                  </div>
                  {securityCodeOK && (
                    <p className={styles.error}>{securityCodeError}</p>
                  )}
                </div>
              </div>
            </>
          )}

          {cardSelect.value === "new" && (
            <>
              <div className={styles.checkBoxContainer}>
                {saveCard ? (
                  <div
                    className={styles.containerCheck}
                    onClick={handleSaveCard}
                  >
                    <SvgChecked />
                  </div>
                ) : (
                  <div
                    className={styles.containerCheck}
                    onClick={handleSaveCard}
                  >
                    <div />
                  </div>
                )}
                <span>Salvar esse cartão</span>
              </div>
            </>
          )}

          {cardSelect.value !== "new" && (
            <>
              <div className={styles.containerTrashCan}>
                <button onClick={() => setShowDeleteCardModal(true)}>
                  <img src={greenBin} alt="Ícone de Lixeira" />
                  <span>Deletar esse cartão</span>
                </button>
              </div>
            </>
          )}
        </div>

        {cardSelect.value === "new" && (
          <div className={styles.formGroupAddress}>
            <div className={styles.title}>
              <h2>Endereço de Cobrança</h2>
            </div>
            <div className={styles.cepContainer}>
              <div className={styles.input} role={zipCodeOK}>
                <label htmlFor="cep">CEP</label>
                <input
                  type="text"
                  name="cep"
                  placeholder="Digite o CEP"
                  onChange={e => handleInputZipCode(e)}
                  value={zipCode}
                />
                <AiOutlineCheck className={styles.check} role={zipCodeOK} />
                <BsExclamation className={styles.danger} role={zipCodeOK} />
              </div>
              <a
                href="https://buscacepinter.correios.com.br/app/endereco/index.php"
                target="_blank"
                rel="noopener noreferrer"
                className={styles.cep}
              >
                Não sei o CEP
              </a>
            </div>
            {zipCodeError && <p className={styles.error}>{zipCodeError}</p>}
            <div
              className={`${styles.input} ${styles.address}`}
              role={addressOK}
            >
              <label htmlFor="address">Endereço</label>
              <input
                type="text"
                name="address"
                placeholder="Digite o Endereço"
                value={address}
                disabled={addressIsDisabled}
                onChange={e => handleInputAddress(e)}
              />
              <AiOutlineCheck className={styles.check} role={addressOK} />
              <BsExclamation className={styles.danger} role={addressOK} />
            </div>
            {addressError && <p className={styles.error}>{addressError}</p>}

            <div className={styles.complementContainer}>
              <div className={styles.subComplementContainer}>
                <div
                  className={`${styles.input} ${styles.complement}`}
                  role={addressNumberOK}
                >
                  <label htmlFor="number">Número</label>
                  <input
                    type="text"
                    name="number"
                    placeholder="Digite o número"
                    value={addressNumber}
                    onChange={handleInputAddressNumber}
                  />
                  <AiOutlineCheck
                    className={styles.check}
                    role={addressNumberOK}
                  />
                  <BsExclamation
                    className={styles.danger}
                    role={addressNumberOK}
                  />
                </div>
              </div>

              <div className={styles.subComplementContainer}>
                <div
                  className={`${styles.input} ${styles.complement} ${styles.obs}`}
                  role={addressObsOk}
                >
                  <label htmlFor="address">Complemento</label>
                  <input
                    type="text"
                    name="address"
                    placeholder="Digite o complemento"
                    value={addressObs}
                    onChange={handleInputAddressObs}
                  />
                  <AiOutlineCheck
                    className={styles.check}
                    role={addressObsOk}
                  />
                  <BsExclamation
                    className={styles.danger}
                    role={addressObsOk}
                  />
                </div>
                {addressObsOk && (
                  <p className={styles.error}>{addressObserror}</p>
                )}
              </div>
            </div>
            <div
              className={`${styles.input} ${styles.address}`}
              role={addressNeighborhoodOK}
            >
              <label htmlFor="neighborhood">Bairro</label>
              <input
                type="text"
                name="neighborhood"
                placeholder="Digite o Bairro"
                value={addressNeighborhood}
                disabled={addressNeighborhoodIsDisabled}
                onChange={e => handleInputAddressNeighborhood(e)}
              />
              <AiOutlineCheck
                className={styles.check}
                role={addressNeighborhoodOK}
              />
              <BsExclamation
                className={styles.danger}
                role={addressNeighborhoodOK}
              />
            </div>
            {addressNeighborhoodError && (
              <p className={styles.error}>{addressNeighborhoodError}</p>
            )}
            <div
              className={`${styles.formSelect} ${styles.select}`}
              role={countryOk}
            >
              <label htmlFor="country">País</label>
              <Select
                name="country"
                value={country}
                placeholder="Selecione o País"
                styles={selectStyle}
                isDisabled={true}
              />
            </div>
            <div
              className={`${styles.formSelect} ${styles.select}`}
              role={ufStatus}
            >
              <label htmlFor="state">Estado</label>
              <Select
                onChange={handleSetUf}
                options={ufs}
                name="state"
                value={uf}
                placeholder="Selecione o Estado"
                styles={selectStyle}
                isDisabled={ufIsDisabled}
                noOptionsMessage={({ inputValue }) =>
                  !inputValue && "Resultados não encontrados"
                }
              />
            </div>
            {cityStatus === "notOk" && (
              <p className={styles.error}>Campo obrigatório</p>
            )}
            <div
              className={`${styles.formSelect} ${styles.select}`}
              role={cityStatus}
            >
              <label htmlFor="city">Cidade</label>
              <Select
                onChange={handleSetCity}
                options={cities}
                name="city"
                value={city}
                placeholder="Selecione a Cidade"
                styles={selectStyle}
                isDisabled={cityIsDisabled}
                noOptionsMessage={({ inputValue }) =>
                  !inputValue && "Resultados não encontrados"
                }
              />
            </div>
            {cityStatus === "notOk" && (
              <p className={styles.error}>Campo obrigatório</p>
            )}
          </div>
        )}
      </div>
      <div className={styles.buttonsContainerPayment}>
        <button
          className={`${styles.buttonPayment} ${
            !!packagesSelected.length ? "" : styles.buttonDisabled
            }`}
          onClick={e => handleCardPaymentButton(e)}
        >
          {total
            ? `Pagar ${new Intl.NumberFormat("pt-BR", {
              style: "currency",
              currency: "BRL"
            }).format(total)}`
            : "Você não selecionou nenhum envio"}
        </button>
      </div>

      <ReactModal
        isOpen={showDeleteCardModal}
        onRequestClose={() => closeDeleteCardModal()}
        style={{
          overlay: {
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(51, 51, 51, 0.75)",
            zIndex: 999
          },
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            // marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            zIndex: 999,
            border: "none",
            backgroundColor: "transparent",
            padding: 0
          }
        }}
      >
        <Container>
          <img src={greenCircleInfo} alt="Ícone Informação" />
          <p>Deseja deletar as informações salvas deste cartão?</p>

          <ButtonsContainer>
            <button onClick={() => setShowDeleteCardModal(false)}>
              Cancelar
            </button>
            <button onClick={() => confirmDeleteCreditCard()}>Confirmar</button>
          </ButtonsContainer>
        </Container>
      </ReactModal>
    </>
  );
};

export default CardPayment;
