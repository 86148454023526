import styled from "styled-components";

export const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0px 0px 20px 0px;
  min-height: 56px;
  padding: 0px 10px;
  background-color: #ffffff;
  border: 1px solid #031d3d;

  :hover {
    transition: border 0.4s;
    border: ${({ errorProps }) =>
      !errorProps ? "1px solid #00A4B2" : "1px solid #E60023"};
  }

  i {
    font-size: 16px;
    color: #031d3d;
  }

  > div {
    flex: 1;
    position: relative;

    small {
      font-size: 12px;
      font-family: "Mulish", sans-serif;
      flex: 1;
      color: #fff;
      padding-top: 1px;
      padding-bottom: 0;
      padding-left: 3px;
      padding-right: 3px;
      z-index: 10;
      margin-left: -27px;
      top: calc(50% - 36px);
      background-color: #e60023;
      position: absolute;
    }

    > input {
      flex: 1;
      border: 0;
      height: 40px;
      background-color: #ffffff;
      color: #031d3d;
      font-family: "Mulish", sans-serif;
      font-size: 16px;
      margin: 0px 10px;
      box-shadow: 0 0 0 0;
      border: 0 none;
      outline: 0;
    }

    input::placeholder {
      font-family: "Mulish", sans-serif;
    }

    input:-ms-input-placeholder {
      font-family: "Mulish", sans-serif;
    }

    input::-ms-input-placeholder {
      font-family: "Mulish", sans-serif;
    }
  }
`;
