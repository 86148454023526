import React, { useEffect, useState } from "react";

import { Col, Container, Row } from "react-bootstrap";
import { Loading } from "../../../../../components/Loading";
import { CardShippingStage } from "../../../components/CardShippingStage";

import {
  AiOutlineCheckCircle,
  AiOutlineCloseCircle
} from "react-icons/ai";
import { VscNote } from "react-icons/vsc";
import { IoMdCalendar } from "react-icons/io";
import { FaTruckLoading, FaRunning } from "react-icons/fa";
import { ReactComponent as Expedido } from "../../../../../assets/icons/expedido.svg";
import { LiaTruckSolid, LiaQuestionCircleSolid } from "react-icons/lia";
import { FaPersonWalkingArrowRight } from "react-icons/fa6";
import { PiArrowCounterClockwise } from "react-icons/pi";
import { TbBoxOff } from "react-icons/tb";

import { momentBDays } from "../../../../../utils/MomentBDay";

import styles from "./trackingDetails.module.scss";
import { useParams } from "react-router-dom";
import { BrComerceApiService } from "../../../../../services/BrComerceApiService";
import Swal from "sweetalert2";
import { RateTypeShippingEnum } from "../../../../../enums/RateTypeShippingEnum";
import moment from "moment";
import { DeliveryImageModal } from "../../../../../components/DeliveryImageModal";
import DeliveryTypeEnum from "../../../../../enums/DeliveryTypeEnum";
import ShippingStagesEnum from "../../../../../enums/ShippingStagesEnum";
import { GiPostOffice } from "react-icons/gi";

export const TrackingDetails = () => {
  const params = useParams();
  const api = new BrComerceApiService();
  const [trackingResponse, setTrackingResponse] = useState();

  const [loading, setLoading] = useState(false);
  const [trackings, setTrackings] = useState([]);
  const [timeline, setTimeline] = useState([]);
  const [lastTracking, setLastTracking] = useState({});
  const [companyName, setCompanyName] = useState("");
  const [sro, setSro] = useState("");

  const [showImageModal, setShowImageModal] = useState(false);
  const [dataImage, setDataImage] = useState({
    urlImage: "",
    text: ""
  });

  const handleCloseButton = () => {
    setDataImage({
      urlImage: "",
      text: ""
    });
    setShowImageModal(false);
  };

  const handleTrackingDescriptions = shipping => {
    const mappedTrackingInCorreios = shipping?.correiosRastreio
      ? shipping.correiosRastreio.reduce((acc, tracking, index, array) => {
        const statusTracking = tracking[0];
        const informationsTracking = tracking[1];

        if (
          informationsTracking.descricao.toLowerCase() === "objeto postado"
        ) {
          return [
            ...acc,
            {
              status: statusTracking,
              date: shipping?.type === DeliveryTypeEnum.OWN_DELIVERY ?
                momentBDays(informationsTracking.data).format("DD/MM/YYYY") :
                momentBDays(informationsTracking.data).add(3, "h").format("DD/MM/YYYY"),
              description: informationsTracking.cidade,
              correiosDescription: informationsTracking?.descricao,
              address: informationsTracking?.endereco || "",
              icon: timelineCollect.find(tc => tc.value === statusTracking).icon
            }
          ];
        }

        if (
          informationsTracking.descricao.toLowerCase() ===
          "objeto em trânsito - por favor aguarde"
        ) {
          const hasNextItemInArray = array[index + 1];

          if (hasNextItemInArray) {
            return [
              ...acc,
              {
                status: statusTracking,
                date: shipping?.type === DeliveryTypeEnum.OWN_DELIVERY ?
                  momentBDays(informationsTracking.data).format("DD/MM/YYYY") :
                  momentBDays(informationsTracking.data).add(3, "h").format("DD/MM/YYYY"),
                description: `${informationsTracking.cidade}/${hasNextItemInArray[1].cidade}`,
                correiosDescription: informationsTracking?.descricao,
                address: informationsTracking?.endereco || "",
                icon: timelineCollect.find(tc => tc.value === statusTracking).icon
              }
            ];
          }

          return [
            ...acc,
            {
              status: statusTracking,
              date: shipping?.type === DeliveryTypeEnum.OWN_DELIVERY ?
                momentBDays(informationsTracking.data).format("DD/MM/YYYY") :
                momentBDays(informationsTracking.data).add(3, "h").format("DD/MM/YYYY"),
              description: informationsTracking.cidade,
              correiosDescription: informationsTracking?.descricao,
              address: informationsTracking?.endereco || "",
              icon: timelineCollect.find(tc => tc.value === statusTracking).icon
            }
          ];
        }

        if (
          informationsTracking.descricao.toLowerCase() === "objeto pronto para envio" ||
          informationsTracking.descricao.toLowerCase() === "objeto em trânsito"
        ) {
          return [
            ...acc,
            {
              status: "Objeto em trânsito",
              date: shipping?.type === DeliveryTypeEnum.OWN_DELIVERY ?
                momentBDays(informationsTracking.data).format("DD/MM/YYYY - HH:mm") :
                momentBDays(informationsTracking.data).add(3, "h").format("DD/MM/YYYY - HH:mm"),
              description: informationsTracking.cidade,
              correiosDescription: "Objeto em trânsito",
              address: informationsTracking?.endereco || "",
              icon: timelineCollect.find(tc => tc.value === "Objeto em trânsito").icon
            }
          ];
        }

        return [
          ...acc,
          {
            status: statusTracking,
            date: shipping?.type === DeliveryTypeEnum.OWN_DELIVERY ?
              momentBDays(informationsTracking.data).format("DD/MM/YYYY - HH:mm") :
              momentBDays(informationsTracking.data).add(3, "h").format("DD/MM/YYYY - HH:mm"),
            description: informationsTracking.cidade,
            correiosDescription: informationsTracking?.descricao,
            address: informationsTracking?.endereco || "",
            icon: timelineCollect.find(tc => tc.value === statusTracking).icon
          }
        ];
      }, [])
      : [];

    mappedTrackingInCorreios.splice(0, 0, {
      status: "Criado",
      date: momentBDays(shipping?.created_at).format("DD/MM/YYYY"),
      description: shipping.origin.city.toUpperCase(),
      icon: timelineCollect.find(tc => tc.value === "Criado").icon
    });

    const itIsCollectAndHasCollectDate =
      shipping?.rateType === RateTypeShippingEnum.COLLECT &&
      shipping?.collect?.collectDate;

    // if (itIsCollectAndHasCollectDate) {
    //   mappedTrackingInCorreios.splice(1, 0, {
    //     status: "Coleta agendada",
    //     date: momentBDays(shipping?.collect?.collectDate).format("DD/MM/YYYY"),
    //     description: shipping.origin.city.toUpperCase(),
    //     icon: timelineCollect.find(tc => tc.value === "Coleta agendada").icon
    //   });
    // }

    // Adicionando Criação no início do rastreio.
    if (shipping?.numeroPLP !== null && shipping?.numeroPLP !== 0) {
      if (shipping?.correiosRastreio?.length) {
        const firstRastreio = shipping.correiosRastreio[0];

        mappedTrackingInCorreios.splice(
          itIsCollectAndHasCollectDate ? 2 : 1,
          0,
          {
            status: "Expedido",
            date:
              new Date(shipping?.plpDate) > new Date(firstRastreio[1]?.data)
                ? momentBDays(firstRastreio[1]?.data).format("DD/MM/YYYY")
                : momentBDays(shipping?.plpDate).format("DD/MM/YYYY"),
            description: shipping.origin.city.toUpperCase(),
            icon: timelineCollect.find(tc => tc.value === "Expedido").icon
          }
        );
      } else {
        mappedTrackingInCorreios.splice(
          itIsCollectAndHasCollectDate ? 2 : 1,
          0,
          {
            status: "Expedido",
            date: shipping?.plpDate
              ? momentBDays(shipping?.plpDate).format("DD/MM/YYYY")
              : momentBDays(shipping?.created_at).format("DD/MM/YYYY"),
            description: shipping.origin.city.toUpperCase(),
            icon: timelineCollect.find(tc => tc.value === "Expedido").icon
          }
        );
      }
    }

    const theTrackingDontHasAFinishedItem =
      !!mappedTrackingInCorreios.length === false ||
      mappedTrackingInCorreios.find(
        item =>
          item.status.toLowerCase() === "entrega realizada" ||
          item.status.toLowerCase() === "objeto devolvido" ||
          item.status.toLowerCase() === "objeto pronto para retirada" ||
          item.status.toLowerCase() === "objeto entregue" ||
          item.status === "Expedido"
      ) === undefined;

    if (
      theTrackingDontHasAFinishedItem &&
      (shipping.numeroPLP > 0 ||
        (shipping.type === DeliveryTypeEnum.OWN_DELIVERY &&
          shipping.previewDate))
    ) {
      mappedTrackingInCorreios.push({
        status: "Entrega realizada",
        date:
          shipping?.previewDate &&
            (shipping?.plpDate || shipping.type === DeliveryTypeEnum.OWN_DELIVERY)
            ? momentBDays(shipping?.previewDate).format("DD/MM/YYYY - HH:mm")
            : "Pendente",
        description: shipping.destination.city.toUpperCase(),
        type: "inactive",
        icon: timelineCollect.find(tc => tc.value === "Entrega realizada").icon
      });
    }

    const lastStatus = mappedTrackingInCorreios.slice(-1);
    setLastTracking(lastStatus[0]);

    handleMountTime(mappedTrackingInCorreios);
    setTrackings(mappedTrackingInCorreios);
  };

  const handleStatusColor = status => {
    const color = {
      [ShippingStagesEnum.NAO_FOI_POSSIVEL_ENTREGAR_O_OBJETO.message]:
        "#FF6B71",
      [ShippingStagesEnum.OBJETO_EXTRAVIADO.message]: "#FF6B71",
      "Objeto extraviado": "#FF6B71",
      [ShippingStagesEnum.OBJETO_NAO_FOI_ENTREGUE.message]: "#FF6B71",
      [ShippingStagesEnum.OBJETO_CANCELADO.message]: "#FF6B71",
      [ShippingStagesEnum.OBJETO_DEVOLVIDO.message]: "#FFAF52",
      "Objeto pronto para retirada": "#3C7DD9"
    };

    return color[status] && lastTracking?.status === status
      ? color[status]
      : "";
  };

  const handleMountTime = track => {
    const timeline = [];

    track.forEach(item => {
      timelineCollect.find(itemTimeline => {
        if (itemTimeline.value === item.status) timeline.push(itemTimeline);
      });
    });

    const uniqueArray = [timeline[0]];

    for (let i = 1; i < timeline.length; i++) {
      if (timeline[i] !== timeline[i - 1]) {
        uniqueArray.push(timeline[i]);
      }
    }

    setTimeline(uniqueArray);
  };

  useEffect(() => {
    (async () => {
      try {
        const rastreio = params?.rastreio;
        if (!rastreio) {
          throw new Error("Rastreio não informado.");
        }

        setSro(rastreio);

        setLoading(true);
        const response = await api.makeHttpRequest({
          method: "GET",
          url: `tracking/${rastreio}`
        });

        if (response.length === 0) {
          Swal.fire({
            icon: "warning",
            title: "Rastreio",
            text: "Não encontramos informações sobre esse objeto!"
          });
          return;
        }

        setCompanyName(response.companyName);
        setTrackingResponse(response);

        const createdIn =
          response?.rateType === RateTypeShippingEnum.COLLECT &&
            response?.collect?.collectDate
            ? response?.collect?.collectDate
            : moment(response?.collect?.created_at)
              .add(1, "days")
              .format("YYYY-MM-DDTHH:mm:ss.SSSSZ");

        handleTrackingDescriptions({
          ...response,
          collect: {
            ...response.collect,
            collectDate: createdIn
          }
        });
      } catch (e) {
        console.error(e);
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: "Rastreio",
          text: "Não encontramos informações sobre esse objeto!"
        });
        console.error(e);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  const timelineCollect = [
    {
      text: "Objeto Criado",
      icon: <VscNote />,
      value: "Criado"
    },
    {
      text: "Coleta agendada",
      icon: <IoMdCalendar />,
      value: "Coleta agendada"
    },
    {
      text: "Objeto em trânsito",
      icon: <FaTruckLoading />,
      value: "Objeto pronto para envio"
    },
    {
      text: "Expedido",
      icon: <Expedido />,
      value: "Expedido"
    },
    {
      text: "Expedido",
      icon: <Expedido />,
      value: "Objeto Expedido"
    },
    {
      text: "Em trânsito",
      icon: <LiaTruckSolid />,
      value: "Objeto em trânsito"
    },
    {
      text: "Outros",
      icon: <LiaQuestionCircleSolid />,
      value: "Outros"
    },
    {
      text: "Pronta para Retirada",
      icon: <FaPersonWalkingArrowRight />,
      value: "Objeto pronto para retirada"
    },
    {
      text: "Saiu para entrega",
      icon: <FaRunning />,
      value: "Objeto saiu para entrega"
    },
    {
      text: "Erro na entrega",
      icon: <AiOutlineCloseCircle />,
      value: "Objeto extraviado"
    },
    {
      text: "Entregue",
      icon: <AiOutlineCheckCircle />,
      value: "Entrega realizada"
    },
    {
      text: "Entregue",
      icon: <AiOutlineCheckCircle />,
      value: "Objeto entregue"
    },
    {
      text: "Devolvido",
      icon: <PiArrowCounterClockwise />,
      value: "Objeto Devolvido"
    },
    {
      text: "Não entregue",
      icon: <AiOutlineCloseCircle />,
      value: "Não foi possível entregar o objeto"
    },
    {
      text: "Não entregue",
      icon: <AiOutlineCloseCircle />,
      value: "Objeto não foi entregue"
    },
    {
      text: "Cancelado",
      icon: <TbBoxOff />,
      value: "Objeto Cancelado"
    },
    {
      text: "Objeto Postado",
      icon: <GiPostOffice />,
      value: "Objeto Postado"
    }
  ];

  return (
    <div className={styles.container}>
      <Loading loading={loading} />
      {showImageModal && (
        <DeliveryImageModal
          urlImage={dataImage.urlImage}
          text={dataImage.text}
          handleCloseButton={handleCloseButton}
          isOpen={showImageModal}
        />
      )}
      <Container fluid className={styles.container}>
        <Row className={`h-100 justify-content-between`}>
          <Col className={`col ${styles.trackingContainer}`}>
            <div className={styles.header}>
              <div className={styles.title}>
                <h2>
                  Detalhes do objeto {companyName ? `- ${companyName}` : ""}
                </h2>
              </div>
            </div>

            <div className={styles.subheader}>
              <div className={styles.info}>
                <div className={styles.code}>
                  <h3>Código de rastreio: </h3>
                  <span>{sro}</span>
                </div>
                {trackingResponse?.previewDate &&
                  (trackingResponse?.plpDate ||
                    trackingResponse?.type ===
                    DeliveryTypeEnum.OWN_DELIVERY) && (
                    <div className={styles.code}>
                      <h3>Previsão de entrega: </h3>
                      <span>
                        {moment(trackingResponse?.previewDate).format(
                          "DD/MM/YYYY"
                        )}
                      </span>
                    </div>
                  )}
              </div>

              <div className={styles.share}>
                <div className={styles.containerDetail}>
                  {trackingResponse?.lastUpdate && (
                    <p>
                      Ultima atualização em:{" "}
                      {new Intl.DateTimeFormat("pt-BR", {
                        dateStyle: "medium",
                        timeStyle: "medium"
                      }).format(new Date(trackingResponse?.lastUpdate))}
                    </p>
                  )}
                </div>
              </div>
            </div>
            {window.screen.width > 769 ?

              <div className={styles.timelineContainer}>
                {timeline?.map((item, index) => {
                  return (
                    <>
                      <div className={styles.content} key={item.value}>
                        <div className={styles.statusContainer}>
                          <div
                            style={{
                              background: handleStatusColor(item.value)
                            }}
                            className={styles.status}
                          >
                            {item.icon}
                          </div>
                          <span
                            style={{
                              color: handleStatusColor(item.value)
                            }}
                          >
                            {item.text}
                          </span>
                        </div>
                        <div className={styles.dotContainer}>
                          {timeline?.length - 1 !== index && (
                            <>
                              {[...Array(3)].map((element, index) => (
                                <div key={index} className={styles.dot} />
                              ))}
                            </>
                          )}
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
            : <></>}

            {!!trackings?.length ? (
              <div className={styles.cardsList}>
                {trackings.map((tracking, index) => {
                  return (
                    <CardShippingStage
                      shipping={tracking}
                      key={index}
                      type={tracking.type}
                      last={trackings.length - 1 === index}
                      order={index}
                    />
                  );
                })}
              </div>
            ) : (
              <div
                className={`d-flex justify-content-center align-items-center ${styles.trackingNotFound}`}
              >
                <h2>Ainda não temos informações de rastreio.</h2>
                <span>
                  <strong>Objeto:</strong> {params?.rastreio}
                </span>
              </div>
            )}

            {trackingResponse?.deliveriesData && (
              <div className={styles.deliveryInformation}>
                <div className={styles.header}>
                  <div className={styles.title}>
                    <h2>Detalhes da Entrega</h2>
                  </div>
                </div>

                {trackingResponse?.deliveriesData[0]?.urlImage ? (
                  <div className={styles.containerImages}>
                    {trackingResponse?.deliveriesData?.map((data, index) => {
                      const last4Digits = data?.document
                        ?.replace(/\D/g, "")
                        ?.slice(-3);
                      const maskedNumber = last4Digits?.padStart(
                        data?.document?.length,
                        "*"
                      );
                      return (
                        <div key={index}>
                          <p>
                            <strong>{`${index + 1}ª Tentativa`}</strong>
                          </p>
                          <span>
                            <strong>{"Entrega com sucesso: "}</strong>
                            {data.success ? "Sim" : "Não"}
                          </span>
                          <span>
                            <strong>{"Data: "}</strong>
                            {moment(data.delivery_date).format(
                              "DD/MM/YYYY - HH:mm"
                            )}
                          </span>
                          <span>
                            <strong>{"Assinado por: "}</strong>
                            {data.success ? data.signed_by : "--"}
                          </span>
                          <span>
                            <strong>{"Documento: "}</strong>
                            {data.success ? maskedNumber : "--"}
                          </span>
                          <img
                            src={data.urlImage}
                            alt={`Entrega ${index}`}
                            onClick={() => {
                              setDataImage({
                                urlImage: data.urlImage,
                                text: `${index + 1}ª Tentativa de entrega`
                              });
                              setShowImageModal(true);
                            }}
                          />
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <div
                    className={`d-flex justify-content-center align-items-center ${styles.trackingNotFound}`}
                  >
                    <h2>Ainda não temos informações de entrega</h2>
                  </div>
                )}
              </div>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
};
