import WalletHistoryTypeEnum from "../../../../enums/WalletHistoryTypeEnum";

export const options = [
  {
    label: "Todos",
    value: ""
  },
  {
    label: WalletHistoryTypeEnum.CREDIT.label,
    value: WalletHistoryTypeEnum.CREDIT.value
  },
  {
    label: WalletHistoryTypeEnum.DEBIT.label,
    value: WalletHistoryTypeEnum.DEBIT.value
  },
  {
    label: WalletHistoryTypeEnum.DISCHARGE.label,
    value: WalletHistoryTypeEnum.DISCHARGE.value
  },
  {
    label: WalletHistoryTypeEnum.REFUND.label,
    value: WalletHistoryTypeEnum.REFUND.value
  }
];
