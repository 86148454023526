import React, { useCallback, useState, useEffect } from "react";
import { Loading } from "../../../../../components/Loading";
import Select from "react-select";
import styles from "./styles.module.scss";
import { BrComerceApiService } from "../../../../../services/BrComerceApiService.js";
import Swal from "sweetalert2";
import debounce from "../../../../../utils/debounce";
import { useDidMountEffect } from "../../../../../hooks/useDidMountEffect";
import { selectStyle } from "./selectStyle.js";
import { orderStatuses } from "./orderStatuses.js";
import { TinyModalOrders } from "./TinyModalOrders/index.jsx";

const Tiny = () => {
  const api = new BrComerceApiService();

  const [loading, setLoading] = useState(false);

  const [apiToken, setApiToken] = useState("");
  const [apiTokenOK, setApiTokenOK] = useState("");
  const [apiTokenError, setApiTokenError] = useState("");

  const [selectedToCreateStatus, setSelectedToCreateStatus] = useState({
    label: "Em aberto",
    value: "aberto"
  });

  const [selectedAfterCreateStatus, setSelectedAfterCreateStatus] = useState({
    label: "Preparando envio",
    value: "preparando_envio"
  });

  const [selectedAfterCollectStatus, setSelectedAfterCollectStatus] = useState({
    label: "Pronto para envio",
    value: "pronto_envio"
  });

  const [activeSedexTag, setActiveSedexTag] = useState(false);
  const [sedexTag, setSedexTag] = useState("");
  const [sedexTagOK, setSedexTagOK] = useState("");
  const [sedexTagError, setSedexTagError] = useState("");

  const [activePacTag, setActivePacTag] = useState(false);
  const [pacTag, setPacTag] = useState("");
  const [pacTagOK, setPacTagOK] = useState("");
  const [pacTagError, setPacTagError] = useState("");

  const [activeInsurance, setActiveInsurance] = useState(false);

  const [activeCreateOrder, setActiveCreateOrder] = useState(true);


  const [showModal, setShowModal] = useState(false);

  const [importedOrders, setImportedOrders] = useState([]);

  const [periodInitialDate, setPeriodInitialDate] = useState("");
  const [periodFinalDate, setPeriodFinalDate] = useState("");

  const [ordersToImport, setOrdersToImport] = useState([]);

  const [actualPageOrders, setActualPageOrders] = useState(0);
  const [nPagesOrders, setNPagesOrders] = useState(0);

  const [hasMore, setHasMore] = useState(false);

  const [senderName, setSenderName] = useState("");
  const [senderNameOK, setSenderNameOK] = useState("");
  const [senderNameError, setSenderNameError] = useState("");

  const [zipOriginOK, setZipOriginOK] = useState("vazio");
  const [zipOriginError, setZipOriginError] = useState("");
  const [zipOrigin, setZipOrigin] = useState("");

  const [addressOK, setAddressOK] = useState("vazio");
  const [addressError, setAddressError] = useState("");
  const [address, setAddress] = useState("");

  const [addressObsOK, setAddressObsOK] = useState("vazio");
  const [addressObsError, setAddressObsError] = useState("");
  const [addressObs, setAddressObs] = useState("");

  const [addressNumberOK, setAddressNumberOK] = useState("vazio");
  const [addressNumber, setAddressNumber] = useState("");

  const [addressNeighborhoodOK, setAddressNeighborhoodOK] = useState("vazio");
  const [addressNeighborhoodError, setAddressNeighborhoodError] = useState("");
  const [addressNeighborhood, setAddressNeighborhood] = useState("");

  const [uf, setUf] = useState("");
  const [ufOK, setUfOK] = useState("");

  const [city, setCity] = useState("");
  const [cityOK, setCityOK] = useState("");

  const handleInputSenderName = useCallback(e => {
    const name = e.currentTarget.value;

    if (name.length < 1) {
      setSenderName("");
      setSenderNameError("");
      setSenderNameOK("vazio");
    } else if (name.length < 2) {
      setSenderName(name);
      setSenderNameError("Nome do remetente deve ter pelo menos 2 caracteres");
      setSenderNameOK("notOk");
    } else {
      setSenderName(name);
      setSenderNameError("");
      setSenderNameOK("ok");
    }
  }, []);

  const handleChangeSelectToCreateStatus = e => {
    setSelectedToCreateStatus(e);
  };

  const handleChangeSelectAfterCreateStatus = e => {
    setSelectedAfterCreateStatus(e);
  };

  const handleChangeSelectAfterCollectStatus = e => {
    setSelectedAfterCollectStatus(e);
  };

  const handleSubmit = useCallback(
    async (key, value) => {
      api.makeHttpRequest({
        url: "/tiny/config/key",
        method: "POST",
        data: {
          key: key,
          value: value
        }
      });
    },
    [api]
  );

  const handleChangeApiToken = e => {
    let value = e.currentTarget.value;

    if (value.length < 2) {
      setApiToken(value);
      setApiTokenOK("notOk");
      setApiTokenError("Campo obrigatório");
    } else {
      setApiToken(value);
      setApiTokenOK("ok");
      setApiTokenError("");
    }
  };

  const handleChangeSedexTag = e => {
    let value = e.currentTarget.value;

    if (value.length < 1 && activeSedexTag) {
      setSedexTag(value);
      setSedexTagOK("notOk");
      setSedexTagError("Campo deve estar preenchido");
    } else {
      setSedexTag(value);
      setSedexTagOK("ok");
      setSedexTagError("");
    }
  };

  const handleChangePacTag = e => {
    let value = e.currentTarget.value;

    if (value.length < 1 && activePacTag) {
      setPacTag(value);
      setPacTagOK("notOk");
      setPacTagError("Campo deve estar preenchido");
    } else {
      setPacTag(value);
      setPacTagOK("ok");
      setPacTagError("");
    }
  };

  const sendData = async () => {
    if (
      selectedAfterCollectStatus.value === selectedAfterCreateStatus.value ||
      selectedToCreateStatus.value === selectedAfterCreateStatus.value ||
      selectedToCreateStatus.value === selectedAfterCollectStatus.value
    ) {
      return Swal.fire({
        title: "Status duplicado",
        text: "Cada etapa deve ter status diferentes",
        icon: "warning"
      });
    }

    const zipOriginNumber = zipOrigin.replace(/\D/g, "");

    const completeAddress = {
      zip: zipOriginNumber,
      street: address,
      number: addressNumber,
      complement: addressObs,
      district: addressNeighborhood,
      city: city,
      state: uf
    };

    try {
      setLoading(true);

      const hasStructure = await api.makeHttpRequest({
        method: "GET",
        url: `/tiny/config/key/LOGISTIC_ID`
      });

      if (!hasStructure) {
        const checkStrucuture = await api
          .makeHttpRequest({
            method: "POST",
            url: `/tiny/config/structure`,
            data: {
              token: apiToken
            }
          })
          .catch(() => {
            setLoading(false);
            return false;
          });

        if (!checkStrucuture) {
          return Swal.fire({
            title: "Erro!",
            text:
              "Erro ao salvar a forma de envio na Tiny, revise o token e tente novamente",
            icon: "error"
          });
        }
      }

      setLoading(false);

      await Promise.all([
        handleSubmit("API_TOKEN", apiToken),
        handleSubmit("SENDER_DEFAULT_NAME", senderName),
        handleSubmit("SENDER_DEFAULT_ADDRESS", JSON.stringify(completeAddress)),
        handleSubmit("STATUS_TO_CREATE", selectedToCreateStatus.value),
        handleSubmit("STATUS_AFTER_CREATE", selectedAfterCreateStatus.value),
        handleSubmit("STATUS_AFTER_COLLECT", selectedAfterCollectStatus.value),
        handleSubmit("SEDEX_TAG", sedexTag),
        handleSubmit("PAC_TAG", pacTag),
        activeInsurance
          ? handleSubmit("IS_ACTIVE_INSURANCE", "true")
          : handleSubmit("IS_ACTIVE_INSURANCE", "false"),
        activeCreateOrder
          ? handleSubmit("IS_INACTIVE_ORDER_CREATE", "false")
          : handleSubmit("IS_INACTIVE_ORDER_CREATE", "true"),
        activeSedexTag
          ? handleSubmit("IS_ACTIVE_SEDEX_TAG", "true")
          : handleSubmit("IS_ACTIVE_SEDEX_TAG", "false"),
        activePacTag
          ? handleSubmit("IS_ACTIVE_PAC_TAG", "true")
          : handleSubmit("IS_ACTIVE_PAC_TAG", "false")
      ]);

      Swal.fire({
        title: "Sucesso!",
        text: "Configurações cadastradas com sucesso.",
        icon: "success",
        showCancelButton: false,
        confirmButtonText: "Ok",
        preConfirm: () => {
          Swal.close();
          setLoading(true);
          loadingConfig()
            .then(() => {
              console.log("Configurações ok");
            })
            .catch(() => {
              Swal.fire({
                title: "Erro!",
                text:
                  "Não foi possível carregar as informações da loja da Tiny.",
                icon: "error"
              });
            })
            .finally(() => {
              setLoading(false);
            });
        }
      });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Ocorreu um erro!",
        text: "Erro ao cadastrar configurações"
      });
    }
  };

  const handleCepConsult = useCallback(async cep => {
    try {
      setLoading(true);
      let response = await api.makeHttpRequest({
        method: "POST",
        url: `/addressbook/getAddress`,
        data: {
          zipCode: cep
        }
      });

      if (response?.erro === true) {
        setZipOriginOK("notOk");
        setZipOriginError("CEP inválido.");

        setAddress("");
        setAddressOK("vazio");
        setAddressError("");

        setAddressNeighborhood("");
        setAddressNeighborhoodOK("vazio");
        setAddressNeighborhoodError("");

        setUf("");
        setUfOK("");
        setCity("");
        setCityOK("");

        throw new Error("Não foi possível encontrar o CEP digitado.");
      }

      setZipOriginOK("ok");
      setZipOriginError("");

      if (!response.logradouro || response.logradouro === "undefined") {
        setAddressOK("vazio");
      } else {
        setAddress(response.logradouro);
        setAddressOK("ok");
        setAddressError("");
      }

      if (!response.bairro || response.bairro === "undefined") {
        setAddressNeighborhoodOK("vazio");
      } else {
        setAddressNeighborhood(response.bairro);
        setAddressNeighborhoodOK("ok");
        setAddressNeighborhoodError("");
      }

      setUf(response.uf);
      setUfOK("ok");
      setCity(response.localidade);

      setCityOK("ok");
    } catch (error) {
      console.error(error);

      Swal.fire({
        icon: "error",
        title: "CEP não encontrado",
        text: "Por favor, verifique o CEP inserido"
      });
    } finally {
      setLoading(false);
    }
  }, []);

  const handleValidationAddressObs = value => {
    if (value?.length < 2 && value?.length > 0) {
      setAddressObsOK("notOk");
    } else if (value?.length > 2) {
      setAddressObsOK("ok");
    } else {
      setAddressObsOK("vazio");
    }

    if (value?.length === 1) {
      setAddressObsError("Complemento deve ter pelo menos 2 caracteres");
    } else {
      setAddressObsError("");
    }
  };

  const handleInputAddressObs = useCallback(e => {
    let value = e.currentTarget.value;
    setAddressObs(value);
    handleValidationAddressObs(value);
  }, []);

  const handleInputAddress = useCallback(e => {
    let value = e.currentTarget.value;
    setAddress(value);
    handleValidationAddress(value);
  }, []);

  const handleValidationAddress = value => {
    value.length < 2 ? setAddressOK("notOk") : setAddressOK("ok");
    if (value.length === 0) {
      setAddressError("Campo obrigatório");
    } else if (value.length === 1) {
      setAddressError("Endereço deve ter pelo menos 2 caracteres");
    } else {
      setAddressError("");
    }
  };

  const handleValidationZipOrigin = useCallback(
    async (value, isASavedInfo = false) => {
      if (!value.length) {
        setZipOriginOK("notOk");
        setZipOriginError("Preenchimento obrigatório");
      } else if (value.length < 8) {
        setZipOriginOK("notOk");
        setZipOriginError("Preenchimento incorreto");
      } else {
        if (isASavedInfo) {
          setZipOriginOK("ok");
          setZipOriginError("");
        }

        // Não queremos manipular o CEP novamente,
        // se o CEP selecionado pertence a um endereço já salvo
        if (isASavedInfo === false) {
          const cep = value.substr(0, 5) + value.substr(6, 3);
          await handleCepConsult(cep);
        }
      }
    },
    [handleCepConsult]
  );

  const handleInputZipOrigin = useCallback(
    e => {
      let value = e;
      value = value.replace(/^(\d{5})(\d{3})/, "$1-$2");

      handleValidationZipOrigin(value);
      setZipOrigin(value);
    },
    [handleValidationZipOrigin]
  );

  const handleValidationAddressNeighborHood = value => {
    if (address?.length) return;

    value.length < 1
      ? setAddressNeighborhoodOK("notOk")
      : setAddressNeighborhoodOK("ok");
    if (value.length === 0) {
      setAddressNeighborhoodError("Campo obrigatório");
    } else {
      setAddressNeighborhoodError("");
    }
  };

  const handleInputAddressNeighborhood = useCallback(e => {
    let value = e.currentTarget.value;
    setAddressNeighborhood(value);
    handleValidationAddressNeighborHood(value);
  }, []);

  const handleValidationAddressNumber = value => {
    if (value !== "") {
      setAddressNumberOK("ok");
    } else {
      setAddressNumberOK("vazio");
    }
  };

  const handleInputAddressNumber = useCallback(e => {
    let value = e.currentTarget.value;

    if (value !== "" && !Number(value)) {
      return;
    }

    setAddressNumber(value);
    handleValidationAddressNumber(value);
  }, []);

  useEffect(() => {
    window.setPageTitle("Integrações | Tiny");
  }, []);

  const loadingConfig = useCallback(async () => {
    await Promise.all([
      api
        .makeHttpRequest({
          method: "GET",
          url: `/tiny/config/key/STATUS_TO_CREATE`
        })
        .then(res => {
          if (res) {
            const statusToCreateHandled = orderStatuses.find(
              s => s.value === res
            );
            if (statusToCreateHandled) {
              setSelectedToCreateStatus(statusToCreateHandled);
            }
          }
        }),
      api
        .makeHttpRequest({
          method: "GET",
          url: `/tiny/config/key/STATUS_AFTER_CREATE`
        })
        .then(res => {
          if (res) {
            const statusAfterCreateHandled = orderStatuses.find(
              s => s.value === res
            );
            if (statusAfterCreateHandled) {
              setSelectedAfterCreateStatus(statusAfterCreateHandled);
            }
          }
        }),
      api
        .makeHttpRequest({
          method: "GET",
          url: `/tiny/config/key/STATUS_AFTER_COLLECT`
        })
        .then(res => {
          if (res) {
            const statusAfterCollectHandled = orderStatuses.find(
              s => s.value === res
            );
            if (statusAfterCollectHandled) {
              setSelectedAfterCollectStatus(statusAfterCollectHandled);
            }
          }
        }),
      api
        .makeHttpRequest({
          method: "GET",
          url: `/tiny/config/key/IS_ACTIVE_INSURANCE`
        })
        .then(active => {
          if (active.includes("true")) {
            setActiveInsurance(true);
          }
        }),
      api
        .makeHttpRequest({
          method: "GET",
          url: `/tiny/config/key/IS_INACTIVE_ORDER_CREATE`
        })
        .then(inactive => {
          if (inactive === "true") {
            setActiveCreateOrder(false);
          }
        }),
      api
        .makeHttpRequest({
          method: "GET",
          url: `/tiny/config/key/IS_ACTIVE_SEDEX_TAG`
        })
        .then(active => {
          if (active.includes("true")) {
            setActiveSedexTag(true);
          }
        }),
      api
        .makeHttpRequest({
          method: "GET",
          url: `/tiny/config/key/IS_ACTIVE_PAC_TAG`
        })
        .then(active => {
          if (active.includes("true")) {
            setActivePacTag(true);
          }
        }),
      api
        .makeHttpRequest({
          method: "GET",
          url: `/tiny/config/key/SEDEX_TAG`
        })
        .then(tag => {
          if (tag) {
            setSedexTag(tag);
            setSedexTagOK("ok");
          }
        }),
      api
        .makeHttpRequest({
          method: "GET",
          url: `/tiny/config/key/PAC_TAG`
        })
        .then(tag => {
          if (tag) {
            setPacTag(tag);
            setPacTagOK("ok");
          }
        }),
      api
        .makeHttpRequest({
          method: "GET",
          url: `/tiny/config/key/API_TOKEN`
        })
        .then(apiToken => {
          if (apiToken) {
            setApiToken(apiToken);
            setApiTokenOK("ok");
          }
        }),
      api
        .makeHttpRequest({
          method: "GET",
          url: `/tiny/config/key/SENDER_DEFAULT_NAME`
        })
        .then(name => {
          if (name) {
            setSenderName(name);
            setSenderNameOK("ok");
          }
        }),
      api
        .makeHttpRequest({
          method: "GET",
          url: `/tiny/config/key/SENDER_DEFAULT_ADDRESS`
        })
        .then(res => {
          if (res) {
            const address = JSON.parse(res || "[]");
            setZipOrigin(address?.zip?.replace(/^(\d{5})(\d{3})/, "$1-$2"));
            setZipOriginOK("ok");

            setAddress(address.street);
            setAddressOK("ok");

            setAddressNumber(Number(address.number) || "");
            if (address.number) {
              setAddressNumberOK("ok");
            }

            setAddressObs(address.complement);
            if (address.complement) {
              setAddressObsOK("ok");
            }

            setAddressNeighborhood(address.district);
            setAddressNeighborhoodOK("ok");

            setUf(address.state);
            setUfOK("ok");

            setCity(address.city);
            setCityOK("ok");
          }
        })
    ]);
  }, [api]);

  useEffect(() => {
    setLoading(true);

    loadingConfig()
      .then(() => {
        console.log("Configurações ok");
      })
      .catch(() => {
        Swal.fire({
          title: "Erro!",
          text: "Não foi possível carregar as informações da loja da Tiny.",
          icon: "error"
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useDidMountEffect(() => {
    if (showModal) {
      loadOrdersToImport([periodInitialDate, periodFinalDate]);
    }
  }, [periodInitialDate, periodFinalDate]);

  const loadOrdersToImport = useCallback(
    debounce((dateRange, source) => {
      handleModalOpenOrders(dateRange, source);
    }, 500),
    []
  );

  const handleModalOpenOrders = async (dateRange, source = "") => {
    try {
      setLoading(true);

      const response = await api.makeHttpRequest({
        method: "GET",
        url: `/tiny/orders/all`,
        params: {
          page: 1,
          date: dateRange.join(",") === "," ? "" : dateRange.join(","),
          source
        }
      });

      setHasMore(response.nPages > response.actualPage);
      setImportedOrders(response.results);
      setActualPageOrders(Number(response.actualPage));
      setNPagesOrders(Number(response.nPages));
      setShowModal(true);
    } catch (e) {
      console.log("erro", e);
      Swal.fire({
        title: "Pedidos",
        text: "Erro ao carregar os pedidos.",
        icon: "error"
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Loading loading={loading} />
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.contentHeader}>
            <h2>Configurações</h2>
          </div>
          <div className={styles.contentBody}>
            <section className={styles.firstSection}>
              <div>
                <p>Informe a API Token de usuário da Tiny.</p>
                <div className={styles.contentSection}>
                  <div className={styles.input} role={apiTokenOK}>
                    <input
                      type="text"
                      name="apiToken"
                      value={apiToken}
                      onChange={handleChangeApiToken}
                      placeholder="Digite a API Token"
                    />
                  </div>
                  {apiTokenError && (
                    <p className={styles.error}>{apiTokenError}</p>
                  )}
                </div>
              </div>
              <div className={styles.sectionTitle}>
                <p>
                  Informe os dados do remetente que serão usados no calculo do
                  frete e impressão de etiquetas.
                </p>
                <form>
                  <div className={`${styles.inputContainer} `}>
                    <div className={styles.divContainer}>
                      <div className={styles.input} role={senderNameOK}>
                        <input
                          onChange={e => handleInputSenderName(e)}
                          value={senderName}
                          placeholder="Digite o nome do remetente"
                          type="text"
                          name="senderName"
                        />
                      </div>
                      {senderNameError && (
                        <p className={styles.error}>{senderNameError}</p>
                      )}
                    </div>
                  </div>
                  <div className={`${styles.inputContainer} `}>
                    <div className={` ${styles.divContainer}`}>
                      <div className={` ${styles.inputContainerSmCep} `}>
                        <div className={styles.input} role={zipOriginOK}>
                          <input
                            type="text"
                            name="cep"
                            placeholder="Digite o CEP"
                            onChange={e =>
                              handleInputZipOrigin(e.currentTarget.value)
                            }
                            value={zipOrigin}
                            maxLength={8}
                          />
                          <a
                            href="https://buscacepinter.correios.com.br/app/endereco/index.php"
                            target="_blank"
                            rel="noopener noreferrer"
                            className={styles.cepLink}
                          >
                            Não sei o CEP
                          </a>
                        </div>
                      </div>
                    </div>
                    {zipOriginError && (
                      <p className={styles.error}>{zipOriginError}</p>
                    )}
                  </div>
                  <div
                    className={`${styles.inputContainer} ${styles.inputContainerAddress}`}
                  >
                    <div
                      className={` ${styles.divContainer} ${styles.inputContainerMd}`}
                    >
                      <div className={` ${styles.input} `} role={addressOK}>
                        <input
                          onChange={e => handleInputAddress(e)}
                          value={address}
                          placeholder="Digite o endereço"
                          type="text"
                          name="address"
                        />
                      </div>
                      {addressError && (
                        <p className={styles.error}>{addressError}</p>
                      )}
                    </div>

                    <div className={` ${styles.divContainer}`}>
                      <div className={styles.input} role={addressNumberOK}>
                        <input
                          onChange={e => handleInputAddressNumber(e)}
                          value={addressNumber}
                          placeholder="Digite o número"
                          type="text"
                          name="addressNumber"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className={`${styles.inputContainer} ${styles.inputContainerAddress}`}
                  >
                    <div className={` ${styles.divContainer}`}>
                      <div className={styles.input} role={addressObsOK}>
                        <input
                          onChange={e => handleInputAddressObs(e)}
                          value={addressObs}
                          placeholder="Digite o complemento"
                          type="text"
                          name="addressObs"
                        />
                      </div>
                      {addressObsError && (
                        <p className={styles.error}>{addressObsError}</p>
                      )}
                    </div>

                    <div className={` ${styles.divContainer}`}>
                      <div
                        className={styles.input}
                        role={addressNeighborhoodOK}
                      >
                        <input
                          onChange={e => handleInputAddressNeighborhood(e)}
                          value={addressNeighborhood}
                          placeholder="Digite o bairro"
                          type="text"
                          name="addressNeighborhood"
                        />
                      </div>
                      {addressNeighborhoodError && (
                        <p className={styles.error}>
                          {addressNeighborhoodError}
                        </p>
                      )}
                    </div>
                  </div>
                  <div
                    className={`${styles.inputContainer} ${styles.inputContainerAddress}`}
                  >
                    <div className={styles.divContainer}>
                      <div className={styles.input} role={ufOK}>
                        <input
                          onChange={() => { }}
                          value={uf}
                          type="text"
                          name="uf"
                        />
                      </div>
                    </div>

                    <div className={styles.divContainer}>
                      <div className={` ${styles.divContainer}`}>
                        <div className={styles.input} role={cityOK}>
                          <input
                            onChange={() => { }}
                            value={city}
                            type="text"
                            name="city"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>

              <div>
                <p>
                  Selecione abaixo o status que o pedido deve estar para ser
                  importado:
                </p>
                <div className={styles.contentSection}>
                  <div className={styles.input}>
                    <Select
                      onChange={handleChangeSelectToCreateStatus}
                      options={orderStatuses.filter(
                        r =>
                          r.value !== selectedAfterCollectStatus.value &&
                          r.value !== selectedAfterCreateStatus.value
                      )}
                      value={selectedToCreateStatus}
                      placeholder="Selecione o status"
                      styles={selectStyle}
                    />
                  </div>
                </div>
              </div>
              <div>
                <p>
                  Selecione abaixo para qual status os pedidos deverão ser
                  atualizados depois de importados:
                </p>
                <div className={styles.contentSection}>
                  <div className={styles.input}>
                    <Select
                      onChange={handleChangeSelectAfterCreateStatus}
                      options={orderStatuses.filter(
                        r =>
                          r.value !== selectedAfterCollectStatus.value &&
                          r.value !== selectedToCreateStatus.value
                      )}
                      value={selectedAfterCreateStatus}
                      placeholder="Selecione o status"
                      styles={selectStyle}
                    />
                  </div>
                </div>
              </div>
              <div>
                <p>
                  Selecione abaixo o status dos pedidos que deverão ser movidos
                  após a criação do pedido/coleta:
                </p>
                <div className={styles.contentSection}>
                  <div className={styles.input}>
                    <Select
                      onChange={handleChangeSelectAfterCollectStatus}
                      options={orderStatuses.filter(
                        r =>
                          r.value !== selectedAfterCreateStatus.value &&
                          r.value !== selectedToCreateStatus.value
                      )}
                      value={selectedAfterCollectStatus}
                      placeholder="Selecione o status"
                      styles={selectStyle}
                    />
                  </div>
                </div>
              </div>

              <div className={styles.wrapper}>
                <h4>Criação de pedido</h4>
                <br />
                <p>
                  Abaixo é possível ativar ou desatitvar a criação de pedidos.
                  <br />
                  Serviços como cotação funcionarão normalmente.
                </p>
                <br />
                <div>
                  <div className={styles.switchOption}>
                    <label className={styles.switch}>
                      <input
                        type="checkbox"
                        onChange={event =>
                          setActiveCreateOrder(event.target.checked)
                        }
                        checked={activeCreateOrder}
                      />
                      <span
                        className={`${styles.slider} ${styles.round}`}
                      ></span>
                    </label>
                    {activeCreateOrder ? "Ativado" : "Desativado"}
                  </div>
                </div>
              </div>

              <div>
                <h4>Configuração de Seguro</h4>
                <p style={{ marginTop: "16px" }}>
                  Abaixo é possível ativar ou desatitvar a cotação de frete com Seguro.
                  <br /> Essa opção é válida para todos os fretes que são cotados.
                </p>
                <div className={styles.contentSection}>
                  <div className={styles.switchOption}>
                    <label className={styles.switch}>
                      <input
                        type="checkbox"
                        onChange={event =>
                          setActiveInsurance(event.target.checked)
                        }
                        checked={activeInsurance}
                      />
                      <span
                        className={`${styles.slider} ${styles.round}`}
                      ></span>
                    </label>
                    {activeInsurance ? "Ativado" : "Desativado"}
                  </div>
                </div>
                <br></br>
                <br></br>
                <h4>Importar por Marcador</h4>
                <p style={{ marginTop: "16px" }}>
                  Ative os marcadores para permitir a importação manual dos pedidos com o mesmos
                  <br /> marcadores nos pedidos da Tiny, de acordo com a opção de frete desejada.
                </p>
                <p style={{ marginTop: "20px" }}>
                  Os pedidos com esse marcador serão importados como Expressa.
                </p>
                <div className={styles.contentSection}>
                  <div className={styles.switchOption}>
                    <label className={styles.switch}>
                      <input
                        type="checkbox"
                        onChange={event =>
                          setActiveSedexTag(event.target.checked)
                        }
                        checked={activeSedexTag}
                      />
                      <span
                        className={`${styles.slider} ${styles.round}`}
                      ></span>
                    </label>
                    {activeSedexTag ? "Ativado" : "Desativado"}
                  </div>
                </div>
                <br></br>
                {activeSedexTag &&
                  <div>
                    <div className={styles.contentSection}>
                      <div className={styles.input} role={sedexTagOK}>
                        <input
                          type="text"
                          name="sedexTag"
                          value={sedexTag}
                          onChange={handleChangeSedexTag}
                          placeholder="Digite o marcador para Expressa"
                        />
                      </div>
                      {sedexTagError && (
                        <p className={styles.error}>{sedexTagError}</p>
                      )}
                    </div>
                    <br></br>
                  </div>

                }
                <p style={{ marginTop: "16px" }}>
                  Os pedidos com esse marcador serão importados como Econômica.
                </p>
                <div className={styles.contentSection}>
                  <div className={styles.switchOption}>
                    <label className={styles.switch}>
                      <input
                        type="checkbox"
                        onChange={event =>
                          setActivePacTag(event.target.checked)
                        }
                        checked={activePacTag}
                      />
                      <span
                        className={`${styles.slider} ${styles.round}`}
                      ></span>
                    </label>
                    {activePacTag ? "Ativado" : "Desativado"}
                  </div>
                </div>
                <br></br>
                {activePacTag &&
                  <div>
                    <div className={styles.contentSection}>
                      <div className={styles.input} role={pacTagOK}>
                        <input
                          type="text"
                          name="pacTag"
                          value={pacTag}
                          onChange={handleChangePacTag}
                          placeholder="Digite o marcador para Econômica"
                        />
                      </div>
                      {pacTagError && (
                        <p className={styles.error}>{pacTagError}</p>
                      )}
                    </div>
                    <br></br>
                    <br></br>
                  </div>
                }
              </div>

              <div className={styles.btnContainer}>
                <button
                  type="submit"
                  onClick={sendData}
                  className={styles.btnSave}
                  disabled={
                    !apiToken ||
                    addressOK !== "ok" ||
                    senderNameOK !== "ok" ||
                    apiTokenOK === "notOk" ||
                    (activeSedexTag && sedexTagOK !== "ok") ||
                    (activePacTag && pacTagOK !== "ok")
                  }
                >
                  Salvar
                </button>
              </div>
            </section>
          </div>
        </div>
      </div>
      {!!showModal && (
        <TinyModalOrders
          setShowModal={setShowModal}
          setImportedOrders={setImportedOrders}
          setPeriodInitialDate={setPeriodInitialDate}
          setPeriodFinalDate={setPeriodFinalDate}
          setActualPageOrders={setActualPageOrders}
          setNPagesOrders={setNPagesOrders}
          setLoading={setLoading}
          importedOrders={importedOrders}
          ordersToImport={ordersToImport}
          actualPageOrders={actualPageOrders}
          nPagesOrders={nPagesOrders}
          periodInitialDate={periodInitialDate}
          periodFinalDate={periodFinalDate}
          setOrdersToImport={setOrdersToImport}
          store="tiny"
          hasMore={hasMore}
          setHasMore={setHasMore}
          token={apiToken}
        />
      )}
    </>
  );
};

export { Tiny };
