import { subHours } from "date-fns";
import React, { useCallback, useEffect, useState } from "react";
import { FiEdit3, FiTrash2, FiChevronUp, FiChevronDown } from "react-icons/fi";
import { FaUser } from "react-icons/fa";
import { FaCheckToSlot } from "react-icons/fa6";
import { TbSearch } from "react-icons/tb";
import { RiMoneyDollarCircleFill } from "react-icons/ri";
import { PiNoteFill } from "react-icons/pi";
import { ImBlocked } from "react-icons/im";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import { ReactComponent as SvgChecked } from "../../../../assets/icons/checked.svg";
import { RateTypeShippingEnum } from "../../../../enums/RateTypeShippingEnum";
import { BrComerceApiService } from "../../../../services/BrComerceApiService";
import store from "../../../../store/store";
import formatCep from "../../../../utils/FormatCep";
import { momentBDays } from "../../../../utils/MomentBDay";
import styles from "./styles.module.scss";
import ProfileEnum from "../../../../enums/ProfileEnum";
import DeliveryTypeEnum from "../../../../enums/DeliveryTypeEnum";
import ShippingStagesEnum from "../../../../enums/ShippingStagesEnum";
import { StyledTooltip } from "../../components/Tooltip";

export function CartCardShipping({
  shipping,
  onExpand,
  onClick,
  isSelected,
  isExpand,
  setLoading,
  removeShipping,
  widthWindows,
  isCancelled,
  isAdmin = false,
  invoiceMethod,
  collectStage,
  edit = true,
  linkTracking = false,
  select = true,
  expand = true,
  dashDrivers = false
}) {
  const api = new BrComerceApiService();
  const { auth } = store.getState();
  const userIsAdmin = auth?.user?.profile === ProfileEnum.ADMIN.value;
  const userIsManager = auth?.user?.profile === ProfileEnum.MANAGER.value;
  const history = useHistory();

  const userHasAcess = userIsAdmin || userIsManager;

  const [deleteButton, setDeleteButton] = useState(true);

  const handleOnConfirmDelete = useCallback(() => {
    setLoading(true);
    api
      .makeHttpRequest({
        method: "DELETE",
        url: shipping.idCollect ? `shipping/${shipping.id}/cancel` : `shipping`,
        data: {
          ids: [shipping.id]
        }
      })
      .then(response => {
        removeShipping(shipping?.id);
      })
      .catch(e => {
        Swal.fire({
          icon: "error",
          title: "Ocorreu um erro!",
          text: "Não foi possível fazer a deleção do envio."
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [api, removeShipping, setLoading, shipping.id, shipping.idCollect]);

  const handleDeleteShipping = useCallback(() => {
    Swal.fire({
      title: "Você tem certeza?",
      html:
        "Deseja prosseguir com a remoção do envio?<br>Não será possível desfazer essa ação",
      icon: "warning",
      cancelButtonText: "Cancelar",
      showCancelButton: true,
      confirmButtonText: "Sim, fazer a deleção!"
    }).then(response => {
      if (response?.value) {
        handleOnConfirmDelete();
      }
    });
  }, [handleOnConfirmDelete]);

  const addressInfoHandled = address => {
    let finalAddress = address?.street;

    if (!!address?.number) {
      finalAddress += `, ${address?.number}`;
    }

    if (!!address?.complement) {
      finalAddress += `, ${address?.complement}`;
    }

    if (!!address?.district) {
      finalAddress += `, ${address?.district}`;
    }

    return finalAddress;
  };

  const handleStatusColor = status => {
    const color = {
      [ShippingStagesEnum.ENTREGA_REALIZADA.id]: "#409E6A",
      [ShippingStagesEnum.OBJETO_EM_TRANSITO.id]: "#F5CB2B",
      [ShippingStagesEnum.OBJETO_SAIU_PARA_ENTREGA.id]: "#F5CB2B",
      [ShippingStagesEnum.OBJETO_NAO_FOI_ENTREGUE.id]: "#CA1818"
    };

    return color[status];
  };

  useEffect(() => {
    if (
      collectStage >= 2 &&
      collectStage !== 3 &&
      invoiceMethod !== "billing"
    ) {
      setDeleteButton(false);
    }
  }, [invoiceMethod, collectStage]);

  const clientName = shipping?.user?.userData[1]?.Value?.split(" ", 2).join(
    " "
  );

  return (
    <>
      <div
        className={`${styles.cardWrapper}
      ${shipping.blocked && styles.blockedCard} ${isCancelled &&
          styles.cancelledCard}`}
      >
        {widthWindows > 1024 ? (
          <div
            className={`${styles.cardHeader}
            ${shipping.blocked && styles.blockedShipping}`}
          >
            {shipping.blocked ? (
              <ImBlocked
                color="#CA1818"
                style={{ minWidth: "22px", minHeight: "22px" }}
              />
            ) : (
              select &&
              (isSelected ? (
                <div className={styles.containerCheck} onClick={onClick}>
                  <SvgChecked />
                </div>
              ) : (
                <div
                  className={styles.containerCheck}
                  onClick={isCancelled ? undefined : onClick}
                >
                  <div />
                </div>
              ))
            )}

            {dashDrivers && (
              <div
                style={{
                  backgroundColor: handleStatusColor(shipping.idShippingStatus)
                }}
                className={styles.dotStatus}
              />
            )}

            <div className={styles.content}>
              {dashDrivers &&
                shipping?.orderNumber !== null &&
                shipping?.orderNumber > 0 && (
                  <div className={styles.groupInfo}>
                    <div>
                      <PiNoteFill color="#409E6A" size={22} />
                      <strong className={styles.titleDescription}>
                        PEDIDO:
                      </strong>
                    </div>
                    <span className={styles.shippingInfo}>
                      {shipping?.orderNumber}
                    </span>
                  </div>
                )}

              {userIsAdmin || userIsManager ? (
                <div className={styles.usersGroupInfo}>
                  {(userIsAdmin || userIsManager) && clientName && (
                    <div className={styles.groupInfo}>
                      <div>
                        <FaUser color="#409E6A" size={18} />
                        <strong className={styles.titleDescription}>
                          CLIENTE:
                        </strong>
                      </div>
                      <span className={styles.shippingInfo}>{clientName}</span>
                    </div>
                  )}

                  {shipping?.destination.name !== null && (
                    <div className={styles.groupInfo}>
                      <div>
                        <FaUser color="#409E6A" size={18} />
                        <strong className={styles.titleDescription}>
                          DESTINATÁRIO:
                        </strong>
                      </div>
                      <span className={styles.shippingInfo}>
                        {shipping?.destination.name}
                      </span>
                    </div>
                  )}

                  <strong>
                    {`${shipping?.correios?.nome?.replace("Entrega", "")}
                  ${shipping?.isLogisticsReverse ? " (Reversa)" : ""}
                  ${isCancelled ? " (Cancelado)" : ""}
                  `}
                  </strong>
                </div>
              ) : (
                <>
                  {shipping?.destination.name !== null && (
                    <div className={styles.groupInfo}>
                      <div>
                        <FaUser color="#409E6A" size={18} />
                        <strong className={styles.titleDescription}>
                          DESTINATÁRIO:
                        </strong>
                      </div>
                      <span className={styles.shippingInfo}>
                        {shipping?.destination.name}
                      </span>
                    </div>
                  )}
                </>
              )}

              {shipping?.orderNumber !== null && shipping?.orderNumber > 0 && (
                <div className={styles.groupInfo}>
                  <div>
                    <PiNoteFill color="#409E6A" size={22} />
                    <strong className={styles.titleDescription}>PEDIDO:</strong>
                  </div>
                  <span className={styles.shippingInfo}>
                    {shipping?.orderNumber}
                  </span>
                </div>
              )}

              {shipping?.sro !== null && !!shipping?.sro && (
                <div className={styles.groupInfo}>
                  <TbSearch color="#409E6A" size={22} />
                  <strong className={styles.titleDescription}>CÓDIGO:</strong>
                  {linkTracking ? (
                    <a
                      href={`/detalhes-objeto/${shipping.id}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span className={styles.shippingInfo}>
                        <b>{shipping?.sro}</b>
                      </span>
                    </a>
                  ) : (
                    <span className={styles.shippingInfo}>{shipping?.sro}</span>
                  )}
                </div>
              )}
              {/* {shipping?.invoiceNumber !== null &&
                !!shipping?.invoiceNumber &&
                Number(shipping?.invoiceNumber) >= 1 && (
                  <div className={styles.groupInfo}>
                    <strong className={styles.titleDescription}>
                      NOTA FISCAL:
                    </strong>
                    <span className={styles.shippingInfo}>
                      {shipping?.invoiceNumber}
                    </span>
                  </div>
                )} */}
              {/* {shipping?.rateType !== null && (
                <div className={styles.groupInfo}>
                  <strong className={styles.titleDescription}>POSTAGEM:</strong>
                  <span className={styles.shippingInfo}>
                    {shipping?.rateType === RateTypeShippingEnum.RETAIL
                      ? "Correios"
                      : "Coleta"}
                  </span>
                </div>
              )} */}


              {shipping?.authorizationCode &&
                shipping?.authorizationCode !== null && (
                  <div className={styles.groupInfo}>
                    <div>
                      <FaCheckToSlot color="#409E6A" size={22} />
                      <strong className={styles.titleDescription}>
                        AUTORIZAÇÃO:
                      </strong>
                    </div>
                    {linkTracking ? (
                      <a
                        href={`/detalhes-objeto/${shipping.id}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span className={styles.shippingInfo}>
                          <b>{shipping?.authorizationCode}</b>
                        </span>
                      </a>
                    ) : (
                      <span className={styles.shippingInfo}>
                        {shipping?.authorizationCode}
                      </span>
                    )}
                  </div>
                )}

              <div className={styles.groupInfo}>
                <div>
                  <RiMoneyDollarCircleFill color="#409E6A" size={22} />
                  <strong className={styles.titleDescription}>VALOR:</strong>
                </div>
                <span className={styles.shippingInfo}>
                  {new Intl.NumberFormat("pt-BR", {
                    style: "currency",
                    currency: "BRL"
                  }).format(shipping?.price)}
                </span>
              </div>

              {shipping?.name ? (
                <div className={styles.groupInfo}>
                  <div>
                    <PiNoteFill color="#409E6A" size={22} />
                    <strong className={styles.titleDescription}>Nome:</strong>
                  </div>
                  <span className={styles.shippingInfo}>
                    {shipping?.name}
                  </span>
                </div>
              ) : <></>}

              {!userHasAcess && (
                <strong>
                  {`${shipping?.correios?.nome?.replace("Entrega", "")}
                ${shipping?.isLogisticsReverse ? " (Reversa)" : ""}
                ${isCancelled ? " (Cancelado)" : ""}
                `}
                </strong>
              )}
            </div>
            <div className={styles.actionButtonsContainer}>
              {expand &&
                (isExpand ? (
                  <div onClick={onExpand}>
                    <FiChevronUp />
                  </div>
                ) : (
                  <div onClick={onExpand}>
                    <FiChevronDown />
                  </div>
                ))}
              {edit &&
                (shipping?.numeroPLP <= 1 ||
                  shipping?.numeroPLP === "null") && (
                  <StyledTooltip text="Editar" position="top" arrow>
                    <FiEdit3
                      onClick={() => {
                        if (!isCancelled) {
                          history.push(
                            `/nova-coleta/${shipping.isLogisticsReverse === true
                              ? "reverse"
                              : "normal"
                            }-send-form?shipping=${shipping?.id}`
                          );
                        }
                      }}
                    />

                  </StyledTooltip>
                )}
              {deleteButton &&
                (isAdmin ||
                  (shipping?.idCollect
                    ? !shipping?.numeroPLP && shipping?.idShippingStatus <= 2
                    : true)) && (
                  <StyledTooltip text="Deletar" position="top" arrow>
                    <FiTrash2
                      className={styles.iconDelete}
                      onClick={handleDeleteShipping}
                    />
                  </StyledTooltip>
                )}
            </div>
          </div>
        ) : (
          <div
            className={`${styles.cardHeaderMobile}
            ${shipping.blocked && styles.blockedShipping} ${isCancelled &&
              styles.cancelledCard}`}
          >
            {shipping.blocked ? (
              <ImBlocked
                color="#CA1818"
                style={{ minWidth: "22px", minHeight: "22px" }}
              />
            ) : (
              select &&
              (isSelected ? (
                <div className={styles.containerCheck} onClick={onClick}>
                  <SvgChecked />
                </div>
              ) : (
                <div
                  className={styles.containerCheck}
                  onClick={isCancelled ? undefined : onClick}
                >
                  <div />
                </div>
              ))
            )}
            <div className={styles.content}>
              {shipping?.orderNumber !== null && shipping?.orderNumber > 0 && (
                <div className={styles.groupInfo}>
                  <strong className={styles.titleDescription}>PEDIDO:</strong>
                  <span className={styles.shippingInfo}>
                    {shipping?.orderNumber}
                  </span>
                </div>
              )}
              {shipping?.name !== null && (
                <div className={styles.groupInfo}>
                  <strong className={styles.titleDescription}>NOME:</strong>
                  <span className={styles.shippingInfo}>{shipping?.name}</span>
                </div>
              )}
              {shipping?.invoiceNumber !== null &&
                Number(shipping?.invoiceNumber) >= 1 &&
                !!shipping?.invoiceNumber && (
                  <div className={styles.groupInfo}>
                    <strong className={styles.titleDescription}>
                      NOTA FISCAL:
                    </strong>
                    <span className={styles.shippingInfo}>
                      {shipping?.invoiceNumber}
                    </span>
                  </div>
                )}
              <div className={styles.groupInfo}>
                <strong className={styles.titleDescription}>VALOR:</strong>
                <span className={styles.shippingInfo}>
                  {new Intl.NumberFormat("pt-BR", {
                    style: "currency",
                    currency: "BRL"
                  }).format(shipping?.price)}
                </span>
              </div>
            </div>
            <div className={styles.actionButtonsContainer}>
              <strong className={styles.shippingType}>
                {`${shipping?.correios?.nome?.replace("Entrega", "")}${isCancelled ? " (Cancelado)" : ""
                  }`}
              </strong>
              <div style={{ display: "flex", gap: "8px" }}>
                {expand &&
                  (isExpand ? (
                    <div onClick={onExpand}>
                      <FiChevronUp className={styles.iconOpenClose} />
                    </div>
                  ) : (
                    <div onClick={onExpand}>
                      <FiChevronDown className={styles.iconOpenClose} />
                    </div>
                  ))}
                {(shipping?.numeroPLP <= 1 ||
                  shipping?.numeroPLP === "null") && (
                    <StyledTooltip text="Editar" position="top" arrow>
                      <FiEdit3
                        className={styles.iconEdit}
                        onClick={() => {
                          if (!isCancelled) {
                            history.push(
                              `/nova-coleta/send-form?shipping=${shipping?.id}`
                            );
                          }
                        }}
                      />
                    </StyledTooltip>
                  )}
                {deleteButton &&
                  (isAdmin ||
                    (shipping?.idCollect
                      ? !shipping?.numeroPLP && shipping?.idShippingStatus <= 2
                      : true)) &&
                  invoiceMethod === "billing" && (
                    <StyledTooltip text="Deletar" position="top" arrow>
                      <FiTrash2
                        className={styles.iconDelete}
                        onClick={handleDeleteShipping}
                      />
                    </StyledTooltip>
                  )}
              </div>
            </div>
          </div>
        )}

        <div className={!isExpand ? styles.cardBodyHidden : styles.cardBody}>
          <div
            className={
              !isExpand ? styles.cardContentHidden : styles.cardContent
            }
          >
            <h3>REMETENTE</h3>
            <div className={styles.collectInfo}>
              <p className={styles.highlighted}>
                <span className={styles.highlighted}>Nome:</span>
                {shipping?.origin?.name}
              </p>
              <p>
                <span className={styles.highlighted}>CEP:</span>
                {formatCep(shipping?.origin?.zip)}
              </p>

              <p>
                <span className={styles.highlighted}>Endereço:</span>
                {addressInfoHandled({
                  street: shipping?.origin?.street,
                  number: shipping?.origin?.number,
                  complement: shipping?.origin?.complement,
                  district: shipping?.origin?.district
                })}
                - {`${shipping?.origin?.city}/${shipping?.origin?.state}`}
              </p>
            </div>
          </div>

          <div
            className={
              !isExpand ? styles.cardContentHidden : styles.cardContent
            }
          >
            <h3>DESTINATÁRIO</h3>
            <div className={styles.collectInfo}>
              <p className={styles.highlighted}>
                <span className={styles.highlighted}>Nome:</span>
                {shipping?.destination?.name}
              </p>
              <p>
                <span className={styles.highlighted}>CEP:</span>
                {formatCep(shipping?.destination?.zip)}
              </p>

              <p>
                <span className={styles.highlighted}>Endereço:</span>
                {addressInfoHandled({
                  street: shipping?.destination?.street,
                  number: shipping?.destination?.number,
                  complement: shipping?.destination?.complement,
                  district: shipping?.destination?.district
                })}
                -{" "}
                {`${shipping?.destination?.city}/${shipping?.destination?.state}`}
              </p>
            </div>
          </div>

          <div
            className={
              !isExpand ? styles.cardContentHidden : styles.cardContent
            }
          >
            <h3>PACOTE</h3>
            <div className={styles.collectInfo}>
              <p>
                {shipping?.identificationPackage && (
                  <>{shipping?.identificationPackage} </>
                )}
                <span className={styles.highlighted}>Peso total:</span>
                {`${String(shipping?.weight)?.replace(".", ",")}kg`}
              </p>

              <p>
                <span className={styles.highlighted}>Envio com seguro:</span>
                {shipping?.insurance ? "Sim" : "Não"}
              </p>
              <p className={styles.highlighted}>
                <span className={styles.highlighted}>Previsão de entrega:</span>
                {shipping?.previewDate
                  ? momentBDays(shipping?.previewDate).format("DD/MM/YYYY")
                  : momentBDays(momentBDays().format("YYYY-MM-DD"))
                    .businessAdd(
                      shipping?.correios?.desconto > 0
                        ? Number(shipping?.correios?.prazoEntrega) + 2
                        : Number(shipping?.correios?.prazoEntrega)
                    )
                    .format("DD/MM/YYYY")}
              </p>

              <p>
                <span className={styles.highlighted}>Postagem:</span>
                {shipping?.rateType === RateTypeShippingEnum.RETAIL
                  ? "Correios"
                  : "Coleta"}
                {shipping?.identificationPackage && (
                  <>
                    {shipping?.identificationPackage} <br />
                  </>
                )}
              </p>
              <p>
                <span className={styles.highlighted}>Peso total:</span>
                {`${String(shipping?.weight)?.replace(".", ",")}kg`}
              </p>
            </div>
          </div>

          {/* <div
            className={
              !isExpand ? styles.cardContentHidden : styles.cardContent
            }
          >
            <h3>INFORMAÇÕES ADICIONAIS</h3>
            <div className={styles.collectInfo}>
              <span className={styles.highlighted}>Nota fiscal:</span>
              <span className={styles.handleInvoice}>Gerar nota fiscal</span>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
}

function cardShippingAreEqual(prevShipping, nextShipping) {
  const shippingHasSameCheckEqual =
    prevShipping.isSelected === nextShipping.isSelected;

  const widthWindowIsEqual =
    prevShipping.widthWindows === nextShipping.widthWindows;

  return shippingHasSameCheckEqual && widthWindowIsEqual;
}
export const CartCardShippingComponent = React.memo(
  CartCardShipping,
  cardShippingAreEqual
);
