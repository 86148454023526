import styled from "styled-components";

export const TooltipText = styled.div`
  cursor: pointer;
`;

export const TooltipBox = styled.div`
  position: absolute;
  top: 100%;
  left: 30px;
  visibility: hidden;
  width: 300px;
  padding: 16px;

  z-index: 1;

  background: #ffffff;

  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12),
    0px 1px 3px rgba(0, 0, 0, 0.2);
  border-radius: 12px;

  div {
    text-align: center;
    display: flex;
    align-items: flex-start;

    font-weight: 400;
    font-size: 12px;
    line-height: 15px;

    margin-left: 12px;

    & + div {
      margin-top: 16px;
    }
  }
`;

export const TooltipCard = styled.div`
  position: relative;

  & ${TooltipText}:hover + ${TooltipBox} {
    visibility: visible;
  }
`;


export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 488px;
  gap: 32px;
  padding: 32px 0;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12),
    0px 1px 3px rgba(0, 0, 0, 0.2);
  border-radius: 12px;
  background-color: #ffffff;

  p {
    font-family: "Mulish";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21.6px;
    text-align: center;
    margin-bottom: 0px;
    width: 256px;
  }
`;

export const ModalButtonsContainer = styled.div`
  display: flex;
  gap: 24px;

  button {
    border-radius: 5px;
    width: 200px;
    height: 48px;
    cursor: pointer;
    transition: filter 0.2s;

    font-weight: 700;
    font-size: 18px;
    line-height: 150%;

    color: #409e6a;
    border: 2px solid #409e6a;
    background-color: #fafafa;

    &:hover {
      filter: brightness(0.8);
    }

    & + button {
      color: #ffffff;
      background-color: #409e6a;
      border: 0;
    }
  }
`;
