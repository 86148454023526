import React, { useCallback, useState, useEffect } from "react";
import { Loading } from "../../../../../components/Loading";
import styles from "./styles.module.scss";
import { BrComerceApiService } from "../../../../../services/BrComerceApiService.js";
import Swal from "sweetalert2";
import { PiCopySimple } from "react-icons/pi";
import { FaArrowRotateLeft } from "react-icons/fa6";

const Woocommerce = () => {
  const api = new BrComerceApiService();

  const [loading, setLoading] = useState(false);
  const [copiedApiKey, setCopiedApiKey] = useState(false);
  const [activeInsurance, setActiveInsurance] = useState(false);

  const [generatedApiKey, setGeneratedApiKey] = useState("");
  const [generatedApiKeyOK, setGeneratedApiKeyOK] = useState("");

  const [url, setUrl] = useState("");
  const [urlOK, setUrlOK] = useState("");
  const [urlError, setUrlError] = useState("");

  const [senderName, setSenderName] = useState("");
  const [senderNameOK, setSenderNameOK] = useState("");
  const [senderNameError, setSenderNameError] = useState("");

  const handleCopyApiKey = () => {
    navigator.clipboard.writeText(generatedApiKey);
    setCopiedApiKey(true);
  };

  const handleSubmit = useCallback(
    async (key, value) => {
      api.makeHttpRequest({
        url: "/woocommerce/config",
        method: "POST",
        data: {
          key: key,
          value: value
        }
      });
    },
    [api]
  );

  const sendData = async () => {
    try {
      await Promise.all([
        handleSubmit("BASE_URL", url),
        handleSubmit("SENDER_DEFAULT_NAME", senderName),
        activeInsurance
          ? handleSubmit("IS_ACTIVE_INSURANCE", "true")
          : handleSubmit("IS_ACTIVE_INSURANCE", "false")
      ]);

      Swal.fire({
        title: "Sucesso!",
        text: "Configurações cadastradas com sucesso.",
        icon: "success",
        showCancelButton: false,
        confirmButtonText: "Ok"
      });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Ocorreu um erro!",
        text: "Não foi possível fazer a deleção do envio."
      });
    }
  };

  const handleGenerateApiKey = async () => {
    try {
      setLoading(true);
      const response = await api.makeHttpRequest({
        method: "POST",
        url: `/woocommerce`
      });

      setGeneratedApiKey(response.value);
      setGeneratedApiKeyOK("ok");
    } catch (e) {
      Swal.fire({
        title: "Erro!",
        text: "Não foi possível gerar a API Key.",
        icon: "error"
      });
    } finally {
      setLoading(false);
    }
  };

  const handleInputUrl = useCallback(e => {
    const url = e.currentTarget.value;

    if (url.length < 1) {
      setUrl("");
      setUrlError("Campo obrigatório");
      setUrlOK("notOk");
    } else {
      setUrl(url);
      setUrlError("");
      setUrlOK("ok");
    }
  }, []);

  const handleInputSenderName = useCallback(e => {
    const name = e.currentTarget.value;

    if (name.length < 1) {
      setSenderName("");
      setSenderNameError("");
      setSenderNameOK("vazio");
    } else if (name.length < 2) {
      setSenderName(name);
      setSenderNameError("Nome do remetente deve ter pelo menos 2 caracteres");
      setSenderNameOK("notOk");
    } else {
      setSenderName(name);
      setSenderNameError("");
      setSenderNameOK("ok");
    }
  }, []);

  useEffect(() => {
    window.setPageTitle("Integrações | Woocommerce");
  }, []);

  useEffect(() => {
    if (!copiedApiKey) return;

    const timeout = setTimeout(() => {
      setCopiedApiKey(false);
    }, 2500);

    return () => clearTimeout(timeout);
  }, [copiedApiKey]);

  const loadingConfig = useCallback(async () => {
    await Promise.all([
      api
        .makeHttpRequest({
          method: "GET",
          url: `/woocommerce/config/API_KEY`
        })
        .then(res => {
          if (res) {
            setGeneratedApiKey(res);
            setGeneratedApiKeyOK("ok");
          }
        }),
      api
        .makeHttpRequest({
          method: "GET",
          url: `/woocommerce/config/IS_ACTIVE_INSURANCE`
        })
        .then(active => {
          if (active === "true") {
            setActiveInsurance(true);
          }
        }),
      await api
        .makeHttpRequest({
          method: "GET",
          url: `/woocommerce/config/BASE_URL`
        })
        .then(url => {
          if (url) {
            setUrl(url);
            setUrlOK("ok");
          }
        }),
      api
        .makeHttpRequest({
          method: "GET",
          url: `/woocommerce/config/SENDER_DEFAULT_NAME`
        })
        .then(name => {
          if (name) {
            setSenderName(name);
            setSenderNameOK("ok");
          }
        })
    ]);
  }, [api]);

  useEffect(() => {
    setLoading(true);

    loadingConfig()
      .then(() => {
        console.log("Configurações ok");
      })
      .catch(() => {
        Swal.fire({
          title: "Erro!",
          text: "Não foi possível carregar as informações da loja Woocommerce.",
          icon: "error"
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <>
      <Loading loading={loading} />
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.contentHeader}>
            <h2>Configurações</h2>
            <p>
              É necessária a base URL da sua loja na WooCommerce para continuar
              com a integração.
            </p>
          </div>
          <div className={styles.contentBody}>
            <section>
              <div className={styles.contentSection}>
                <div className={styles.input} role={urlOK}>
                  <input
                    type="text"
                    name="url"
                    value={url}
                    onChange={handleInputUrl}
                    placeholder="URL da loja (ex.: http://www.minhaloja.com.br)"
                  />
                </div>
                {urlError && <p className={styles.error}>{urlError}</p>}
              </div>
              <div className={styles.wrapper}>
                <p>
                  A chave gerada abaixo é única e intransferível. Lembre-se: ao
                  gerar uma nova chave, a antiga sempre será desabilitada.
                </p>
                <div className={styles.contentSection}>
                  <div
                    className={styles.inputCustom}
                    onClick={handleCopyApiKey}
                    role={generatedApiKeyOK}
                  >
                    <input
                      type="text"
                      placeholder=""
                      value={generatedApiKey}
                      disabled
                    />
                    <PiCopySimple />
                    <div>
                      <p className={styles.textClipped}>
                        {copiedApiKey && !!generatedApiKey
                          ? "Copiado com sucesso!"
                          : ""}
                      </p>
                    </div>
                  </div>
                  <button
                    className={styles.generateBtn}
                    type="button"
                    onClick={handleGenerateApiKey}
                  >
                    <FaArrowRotateLeft size={18} />
                    <span>Gerar nova chave</span>
                  </button>
                </div>
              </div>
              <div className={styles.wrapper}>
                <p>
                  Informe o nome de remetente que deve constar nas etiquetas de
                  envio geradas pela plataforma.
                </p>
                <div className={styles.divContainer}>
                  <div className={styles.input} role={senderNameOK}>
                    <input
                      onChange={e => handleInputSenderName(e)}
                      value={senderName}
                      placeholder="Digite o nome do remetente"
                      type="text"
                      name="senderName"
                    />
                  </div>
                  {senderNameError && (
                    <p className={styles.error}>{senderNameError}</p>
                  )}
                </div>
              </div>
              <div className={styles.wrapper}>
                <h4>Configuração de Seguro</h4>
                <p>
                  Abaixo é possível ativar ou desatitvar a cotação de frete com
                  Seguro. Essa opção é válida para todos os fretes <br /> que
                  são cotados.
                </p>
                <div className={styles.contentSection}>
                  <div className={styles.switchOption}>
                    <label className={styles.switch}>
                      <input
                        type="checkbox"
                        onChange={event =>
                          setActiveInsurance(event.target.checked)
                        }
                        checked={activeInsurance}
                      />
                      <span
                        className={`${styles.slider} ${styles.round}`}
                      ></span>
                    </label>
                    {activeInsurance ? "Ativado" : "Desativado"}
                  </div>
                </div>
              </div>
              <div className={styles.buttonWrapper}>
                <button
                  type="submit"
                  onClick={sendData}
                  className={styles.button}
                  disabled={
                    !(url && generatedApiKey) || senderNameOK === "notOk"
                  }
                >
                  Salvar
                </button>
              </div>
            </section>
          </div>
        </div>
      </div>
    </>
  );
};

export { Woocommerce };
