import React, { useCallback } from "react";

import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  withStyles
} from "@material-ui/core";
import moment from "moment";
import styles from "./styles.module.scss";
import { OriginEnum } from "../../../../enums/OriginEnum";

export const ModalRenderTable = ({
  importedOrders,
  setOrdersToImport,
  ordersToImport,
  store
}) => {
  const StyledTableCell = withStyles(theme => ({
    head: {
      backgroundColor: "#fff",
      color: "#807E80",
      textAlign: "center",
      fontFamily: "Mulish"
    },
    body: {
      textAlign: "center",
      fontSize: 13,
      padding: 16,
      paddingLeft: 0,
      paddingRight: 0,
      fontFamily: "Mulish",
      "&:last-child": {
        paddingRight: 0
      }
    }
  }))(TableCell);

  const StyledTableRow = withStyles(theme => ({
    root: {
      "&:last-child()": {
        borderBottom: "0"
      }
    }
  }))(TableRow);

  const renderTable = useCallback(importedOrders => {
    return (
      <Table
        size="small"
        aria-label="Tabela com informações dos pedidos."
        className={styles.modalTable}
      >
        <TableHead>
          <TableRow>
            <StyledTableCell></StyledTableCell>
            <StyledTableCell>Número</StyledTableCell>
            <StyledTableCell>Data de Criação</StyledTableCell>
            <StyledTableCell>Valor</StyledTableCell>
            <StyledTableCell>Status</StyledTableCell>
            <StyledTableCell>Situação</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody
          style={{
            maxHeight: 450,
            overflow: "auto"
          }}
        >
          {importedOrders?.map((row, index) => (
            <StyledTableRow
              key={index}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 }
              }}
              style={{
                textAlign: "center",
                backgroundColor: row?.already_created && "rgba(230, 230, 230)"
              }}
            >
              <StyledTableCell align="center">
                <label className={styles.tdCheckbox}>
                  <input
                    type="checkbox"
                    onChange={e => {
                      e.persist();
                      setOrdersToImport(prevState => {
                        const order = store === OriginEnum.VTEX ? row?.OrderId : row?.orderNumber;
                        const storeId = store === OriginEnum.VTEX ? ({ Origin: row?.Origin }) : row.storeId;

                        return e.target?.checked
                          ? [
                            ...prevState,
                            {
                              orderId: order,
                              storeId
                            }
                          ]
                          : prevState.filter(
                            el => el.orderId !== order
                          );
                      });
                    }}
                    value={ordersToImport.some(
                      order => order.orderId === order
                    )}
                    disabled={row?.already_created}
                  />
                  <span></span>
                </label>
              </StyledTableCell>
              <StyledTableCell align="center">
                {`${store === OriginEnum.VTEX ? row?.OrderId : row?.orderNumber}`}
              </StyledTableCell>
              <StyledTableCell align="center">
                {moment(row.date).format("DD/MM/YYYY")}
              </StyledTableCell>
              <StyledTableCell align="center">
                {new Intl.NumberFormat("pt-BR", {
                  style: "currency",
                  currency: "BRL"
                }).format(row?.value)}
              </StyledTableCell>
              <StyledTableCell align="center">{row?.status}</StyledTableCell>
              <StyledTableCell align="center">
                <span
                  className={styles.tdStatus}
                  style={{
                    backgroundColor: row?.already_created
                      ? "#409E6A"
                      : "#ED7878"
                  }}
                >
                  {row?.already_created
                    ? "Objeto Importado"
                    : "Objeto Pendente"}
                </span>
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    );
  }, []);

  return renderTable(importedOrders);
};
