import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { FiSearch, FiDownload, FiEdit3, FiTrash2 } from "react-icons/fi";
import InfiniteScroll from "react-infinite-scroll-component";
import CsvDownload from "react-json-to-csv";
import Select from "react-select";
import DatePicker from "../../../../../components/Input/DatePicker";
import LoadInfiniteScroll from "../../../../../components/LoadInfiniteScroll";
import { Loading } from "../../../../../components/Loading";
import { RouteStageEnum } from "../../../../../enums/RouteStageEnum";
import { BrComerceApiService } from "../../../../../services/BrComerceApiService";
import store from "../../../../../store/store";
import { routesCSVFormat } from "../../../../../utils/CSVFormatter";
import { capitalizeFirstLetter } from "../../../../../utils/CapitalizeFirstLetter";
import debounce from "../../../../../utils/debounce";
import filtersFromQueryString from "../../../../../utils/parserQueryString";
import ScrollDiv from "../../../components/ScrollDiv";
import styles from "./styles.module.scss";
import InputSelectAsync, {
  selectStyle
} from "../../../../../components/InputSelectAsync";
import { MdClear } from "react-icons/md";
import Container from "../../../components/Container";
import { StyledTooltip } from "../../../components/Tooltip";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";

const RouteList = () => {
  const api = new BrComerceApiService();

  const { auth } = store.getState();
  const { user } = auth;
  const history = useHistory();

  const getOptionsStagesCollect = useMemo(() => {
    const optionsHandled = Object.entries(RouteStageEnum).reduce(
      (acc, [key, value]) => {
        return [...acc, { value: value.id, label: value.message }];
      },
      []
    );

    return [{ value: undefined, label: "Todos" }, ...optionsHandled];
  }, []);

  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [routes, setRoutes] = useState([]);
  const [routesNotFormatted, setRoutesNotFormatted] = useState([]);
  const [actualPage, setActualPage] = useState(0);
  const [routesExportData, setRoutesExportData] = useState({});
  const [loadingInfinit, setLoadingInfinit] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  const [selectedStatusInFilter, setSelectedStatusInFilter] = useState({
    value: "",
    label: "Todos"
  });

  const [filterType, setFilterType] = useState({
    label: "Selecione",
    value: ""
  });

  const [alReadyDidIt, setAlreadyDidIt] = useState(false);

  const [periodInitialDate, setPeriodInitialDate] = useState(null);
  const [periodFinalDate, setPeriodFinalDate] = useState(null);
  console.log("🚀 ~ RouteList ~ periodInitialDate:", periodInitialDate);
  console.log("🚀 ~ RouteList ~ periodFinalDate:", periodFinalDate);

  const [searchDriver, setSearchDriver] = useState(null);

  const handleChangeSelectFilterByStatus = e => {
    setSelectedStatusInFilter(e);
  };

  const handleParseRoute = useCallback(
    (route) => {

      let totalValue = 0;
      let totalValueDelivered = 0;

      route.routeShipping.forEach((rs) => {
        totalValue += rs?.shipping?.price;

        if (rs.id_own_delivery_status === 11) {
          totalValueDelivered += rs?.shipping?.price;
        }
      })

      return {
        ...route,
        created_at: moment(route.created_at)
          .subtract(3, "hours")
          .format("DD/MM/YYYY"),
        date: moment(route.date)
          .subtract(3, "hours")
          .format("DD/MM/YYYY"),
        count: {
          totalRouteShipping: route?.routeShipping?.length || 0,
          totalDelivery: route?.routeShipping?.filter(
            x => x.id_own_delivery_status === 11
          )?.length
        },
        totalValue,
        totalValueDelivered
      }
    },
    [user.profile]
  );

  const handleFetchData = useCallback(async () => {
    setLoadingInfinit(true);
    try {
      /* if (actualPage === nPages) {
        return;
      } */
      const response = await api.makeHttpRequest({
        method: "GET",
        url: `/route-admin`,
        params: {
          resultsPerPage: 20,
          page: actualPage + 1,

          ...(selectedStatusInFilter?.value && {
            status: selectedStatusInFilter?.value
          }),
          ...(periodInitialDate && {
            initialDate: periodInitialDate
          }),
          ...(periodFinalDate && {
            finalDate: periodFinalDate
          }),
          ...(search && { text: search }),
          ...(searchDriver?.label && { driver: searchDriver?.label })
        }
      });

      const parsedRoute = response.results.map(route =>
        handleParseRoute(route)
      );

      setRoutes(previousState => [...previousState, ...parsedRoute]);
      setRoutesNotFormatted(previousState => [
        ...previousState,
        ...response.results
      ]);
      setRoutesExportData(routesCSVFormat(routesNotFormatted));
      //setRoutesWithoutFilter([...routes, ...response.results]);
      setActualPage(Number(response.actualPage));
      //setNPages(Number(response.nPages));
    } catch (e) {
      console.log(e);
    } finally {
      setLoadingInfinit(false);
      setLoading(false);
    }
  }, [
    actualPage,
    api,
    handleParseRoute,
    periodFinalDate,
    periodInitialDate,
    routesNotFormatted,
    search,
    searchDriver,
    selectedStatusInFilter.value
  ]);

  const getRoutes = useCallback(
    debounce(async (status, searchParam, initial, final, searchDriver) => {
      setLoading(true);

      try {
        const response = await api.makeHttpRequest({
          method: "GET",
          url: `/route-admin`,
          params: {
            resultsPerPage: 20,
            page: 1,
            ...(status && { status }),
            ...(initial && {
              initialDate: initial
            }),
            ...(final && { finalDate: final }),
            ...(searchParam && { text: searchParam }),
            ...(searchDriver?.label && { driver: searchDriver.label })
          }
        });

        const parsedRoute = response.results.map(route =>
          handleParseRoute(route)
        );

        setRoutes(parsedRoute);
        setRoutesNotFormatted(response.results);
        setRoutesExportData(routesCSVFormat(response.results));
        setActualPage(1);

        if (response.results.length === 20) {
          setHasMore(true);
        } else {
          setHasMore(false);
        }

        setLoading(false);
      } catch (e) {
        setLoading(false);
        console.error(e);
      }
    }, 500),
    [user, filterType.value, periodInitialDate, periodFinalDate]
  );

  const handleOnChangeCalendarPeriod = e => {
    if (e.dateEnd) {
      const dateStart = moment(e.dateStart, "DD/MM/YYYY").format("MM/DD/YYYY");
      const dateEnd = moment(e.dateEnd, "DD/MM/YYYY").format("MM/DD/YYYY");

      setPeriodInitialDate(dateStart);
      setPeriodFinalDate(dateEnd);
    }
  };

  const handleClearPeriodFilter = () => {
    setPeriodInitialDate("");
    setPeriodFinalDate("");
  };

  const handleDetailsRoute = id => {
    window.open(`/detalhes-rota/${id}`);
  };

  const handleEditRoute = id => {
    history.push(`/editar-rota/${id}`);
  };

  const handleRemoveRoute = id => {
    Swal.fire({
      title: "Deseja deletar a rota?",
      text: "Esta ação não poderá ser revertida.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Deletar",
      cancelButtonText: "Cancelar"
    }).then(async (res) => {
      try {
        if (res?.value) {
          setLoading(true)
          await api.makeHttpRequest({
            method: "DELETE",
            url: `/route-admin/${id}`,
          });

          Swal.fire({
            title: "Sucesso!",
            text: "A rota foi deletada com sucesso!",
            icon: "success",
            confirmButtonText: "Fechar",
          }).then(() => {
            getRoutes(
              selectedStatusInFilter.value,
              search,
              periodInitialDate,
              periodFinalDate,
              searchDriver
            );
          })
        }
      } catch (error) {
        console.log({ error })
        Swal.fire({
          title: "Erro!",
          text: "Não foi deletar a rota!",
          icon: "error"
        });
        setLoading(false)
      } finally {
        setLoading(false)
      }
    });
  };

  useEffect(() => {
    window.setPageTitle("Entrega Própria");
  }, []);

  useEffect(() => {
    if (!alReadyDidIt && window.location.href.includes("value")) {
      const [value, label, initial, final] = filtersFromQueryString(
        window.location.href
      );
      setSelectedStatusInFilter({ label, value: parseInt(value) });
      handleOnChangeCalendarPeriod({ dateStart: initial, dateEnd: final });
      setAlreadyDidIt(true);
    } else if (!alReadyDidIt && window.location.href.includes("status")) {
      const [status, label, initial, final] = filtersFromQueryString(
        window.location.href
      );
      setFilterType({ label, value: status });
      handleOnChangeCalendarPeriod({ dateStart: initial, dateEnd: final });
      setAlreadyDidIt(true);
    } else {
      getRoutes(
        selectedStatusInFilter.value,
        search,
        periodInitialDate,
        periodFinalDate,
        searchDriver
      );
    }
  }, [
    getRoutes,
    selectedStatusInFilter.value,
    search,
    periodInitialDate,
    periodFinalDate,
    alReadyDidIt,
    searchDriver
  ]);

  return (
    <>
      <Loading loading={loading} />
      <Container title="Entrega Própria">
        <div className={styles.contentBody}>
          <div className={styles.filterers}>
            <div className={`${styles.inputContainer}`}>
              <div className={styles.input}>
                <input
                  type="text"
                  name="search"
                  placeholder="Buscar por código da rota"
                  value={search}
                  onChange={e => setSearch(e.currentTarget.value)}
                />
                {!search.length && <FiSearch size={20} color="#409e6a" />}

                {!!search?.length && (
                  <MdClear
                    style={{ cursor: "pointer" }}
                    onClick={() => setSearch("")}
                  />
                )}
              </div>
            </div>

            <div className={styles.inputSelectAsync}>
              <InputSelectAsync
                search={searchDriver}
                setSearch={setSearchDriver}
                listUserOrDriver={"driver"}
              />
            </div>

            <div
              style={{
                width: "100%"
              }}
            >
              <div className={styles.input}>
                <DatePicker
                  className={"datePickerConfig"}
                  change={handleOnChangeCalendarPeriod}
                  placeholder="Filtrar por período"
                  showClearButton={!!periodInitialDate && !!periodFinalDate}
                  onClearFields={handleClearPeriodFilter}
                  range
                />
              </div>
            </div>

            <div className={styles.formSelect}>
              <Select
                onChange={handleChangeSelectFilterByStatus}
                options={getOptionsStagesCollect}
                value={
                  selectedStatusInFilter ||
                  getOptionsStagesCollect.find(item => item.value === "all")
                }
                placeholder="Status"
                styles={selectStyle}
              />
            </div>
            <CsvDownload
              data={routesExportData}
              className={styles.btnExportCSV}
            >
              <FiDownload size={24} color="#fff" />
              CSV
            </CsvDownload>
          </div>
          <ScrollDiv data={routes}>
            <InfiniteScroll
              dataLength={routes?.length}
              next={handleFetchData}
              hasMore={hasMore}
              loader={loadingInfinit && <LoadInfiniteScroll />}
              scrollableTarget="scrollDiv"
              className={styles.infiniteScroll}
            >
              <div className={styles.tableContainer}>
                <table className={styles.table}>
                  <thead className={styles.tableHead}>
                    <tr>
                      <th>Rota</th>
                      <th>Motorista</th>
                      <th>Objetos Entregues</th>
                      <th>Data Criação</th>
                      <th>Ínicio</th>
                      <th>Fim</th>
                      <th>Kms Percorrido</th>
                      <th>Valor Total</th>
                      <th>Valor Total Entregue</th>
                      <th>Status</th>
                      <th>Ações</th>
                    </tr>
                  </thead>
                  <tbody className={styles.tableBody}>
                    {routes.map(route => {
                      return (
                        <tr key={route.id}>
                          <td className={styles.thCodigo}>
                            <a
                              href={`detalhes-rota/${route?.id}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <p>{route.id}</p>
                            </a>
                          </td>
                          <td className={styles.thDriver}>
                            <p>
                              {capitalizeFirstLetter(route?.driver?.name) ||
                                "--"}
                            </p>
                          </td>
                          <td>
                            {`${route.count.totalDelivery} de ${route.count.totalRouteShipping}`}
                          </td>
                          <td className={styles.thCriado}>
                            <p>{route.created_at} </p>
                          </td>
                          <td className={styles.thCriado}>
                            <p>{
                              route?.started_at ?
                                moment(route?.started_at)
                                  .format("DD/MM/YYYY - HH:mm")
                                : "--"
                            }</p>
                          </td>
                          <td className={styles.thCriado}>
                            <p>{
                              route?.finalized_at ?
                                moment(route?.finalized_at)
                                  .format("DD/MM/YYYY - HH:mm")
                                : "--"
                            }</p>
                          </td>
                          <td className={styles.thCriado}>
                            <p>{route?.kms_traccar ? `~ ${route?.kms_traccar?.toFixed(2)} km` : "--"} </p>
                          </td>
                          <td className={styles.thCriado}>
                            <p>{new Intl.NumberFormat("pt-BR", {
                              style: "currency",
                              currency: "BRL"
                            }).format(route?.totalValue)} </p>
                          </td>
                          <td className={styles.thCriado}>
                            <p>{
                              route?.finalized_at ?
                                new Intl.NumberFormat("pt-BR", {
                                  style: "currency",
                                  currency: "BRL"
                                }).format(route?.totalValueDelivered)
                                : "--"
                            }</p>
                          </td>
                          <td className={styles.thStatus}>
                            <span
                              className={
                                route?.status?.id === 1
                                  ? styles.gray
                                  : route?.status?.id === 2
                                    ? styles.going
                                    : route?.status?.id === 3
                                      ? styles.delivered
                                      : styles.error
                              }
                            >
                              {route?.status?.name}
                            </span>
                          </td>
                          <td className={styles.tooltipWrapper}>
                            {(route?.status?.id === 1 && user.profile === "admin") && <StyledTooltip text="Editar" position="top" arrow>
                              <div style={{ cursor: "pointer" }}>
                                <FiEdit3
                                  size={20}
                                  color="#409e6a"
                                  onClick={() => handleEditRoute(route?.id)}
                                />
                              </div>
                            </StyledTooltip>}
                            <StyledTooltip text="Detalhes" position="top" arrow>
                              <div style={{ cursor: "pointer" }}>
                                <FiSearch
                                  size={20}
                                  color="#409e6a"
                                  onClick={() => handleDetailsRoute(route?.id)}
                                />
                              </div>
                            </StyledTooltip>
                            {(route?.status?.id === 1 && user.profile === "admin") && <StyledTooltip text="Remover" position="top" arrow>
                              <div style={{ cursor: "pointer" }}>
                                <FiTrash2
                                  size={20}
                                  color="#409e6a"
                                  onClick={() => handleRemoveRoute(route?.id)}
                                />
                              </div>
                            </StyledTooltip>}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </InfiniteScroll>
          </ScrollDiv>
        </div>
      </Container>
    </>
  );
};

export { RouteList };
