import React, { useCallback, useEffect } from "react";
import {
  GoogleMap,
  Marker,
  Polyline,
  useJsApiLoader
} from "@react-google-maps/api";

import sendIcon from "../../assets/icons/iconeRemetente.svg";
import destinyIcon from "../../assets/icons/iconeDestinatário.svg";

let GoogleMaps = props => {
  const lightThemeStyle = [
    {
      featureType: "poi",
      elementType: "labels.text",
      stylers: [
        {
          visibility: "off"
        }
      ]
    },
    {
      featureType: "poi.business",
      stylers: [
        {
          visibility: "off"
        }
      ]
    },
    {
      featureType: "road",
      elementType: "labels.icon",
      stylers: [
        {
          visibility: "off"
        }
      ]
    },
    {
      featureType: "transit",
      stylers: [
        {
          visibility: "off"
        }
      ]
    }
  ];

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY
  });

  const center = {
    lat: -3.745,
    lng: -38.523
  };

  const [map, setMap] = React.useState(null);

  const onLoad = useCallback(
    map => {
      const path = [
        props.sendLocation,
        props.destinyLocation ? props.destinyLocation : props.sendLocation
      ];

      if (map) {
        const bounds = new window.google.maps.LatLngBounds();

        if (bounds) {
          if (path.length > 0)
            path.map(position => {
              return bounds.extend(position);
            });
          map.fitBounds(bounds);
        }

        setMap(map);
      }
    },
    [props.sendLocation, props.destinyLocation]
  );

  const onUnmount = useCallback(map => {
    setMap(null);
  }, []);

  useEffect(() => {
    if (map) {
      const path = [
        props.sendLocation,
        props.destinyLocation ? props.destinyLocation : props.sendLocation
      ];

      const bounds = new window.google.maps.LatLngBounds();

      if (bounds) {
        if (path.length > 0)
          path.map(position => {
            return bounds.extend(position);
          });
        map.fitBounds(bounds);
      }
    }
  }, [props.sendLocation, props.destinyLocation, map]);

  return (
    isLoaded && (
      <GoogleMap
        mapContainerStyle={{
          display: "flex",
          flex: "1"
        }}
        zoom={10}
        options={{
          styles: lightThemeStyle
        }}
        onLoad={onLoad}
        onUnmount={onUnmount}
      >
        <Marker
          position={props.sendLocation}
          title="Remetente"
          icon={{
            url: sendIcon,
            scaledSize: {
              height: 50,
              width: 50
            }
          }}
        />
        {props.destinyLocation && (
          <>
            <Marker
              position={props.destinyLocation}
              title="Destinatário"
              icon={{
                url: destinyIcon,
                scaledSize: {
                  height: 50,
                  width: 50
                }
              }}
            />
            <Polyline
              options={{
                strokeColor: "#409E6A",
                fillColor: "#409E6A",
                strokeOpacity: 1,
                strokeWeight: 4,
                path: [props.sendLocation, props.destinyLocation],
                clickable: false,
                draggable: false,
                editable: false,
                visible: true,
                radius: 30000,
                zIndex: 1
              }}
              path={[props.sendLocation, props.destinyLocation]}
            />
          </>
        )}
      </GoogleMap>
    )
  );
};

GoogleMaps = React.memo(GoogleMaps);

export { GoogleMaps };
