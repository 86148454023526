import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import { AiOutlineCheck } from "react-icons/ai";
import { BsExclamation } from "react-icons/bs";
import {
  FiAlertCircle,
  FiCheck,
  FiSquare
} from "react-icons/fi";
import { MdAddCircle } from "react-icons/md";
import { PiCaretRightBold } from "react-icons/pi";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import Swal from "sweetalert2";
import closeCircle from "../../../../../assets/icons/icon-close-circle.svg";
import caixa from "../../../../../assets/images/modelo-caixa.svg";
import cilindro from "../../../../../assets/images/modelo-cilindro.png";
import envelope from "../../../../../assets/images/modelo-envelope.svg";
import { selectStyle } from "../../../../../components/InputSelectAsync";
import { Loading } from "../../../../../components/Loading";
import ProfileEnum from "../../../../../enums/ProfileEnum";
import { RateTypeShippingEnum } from "../../../../../enums/RateTypeShippingEnum";
import { useQuery } from "../../../../../hooks/useQuery";
import { BrComerceApiService } from "../../../../../services/BrComerceApiService";
import store from "../../../../../store/store";
import { formatMoney } from "../../../../../utils/FormatMoney";
import Steps from "../../../components/Steps";
import { DeclaracaoConteudo } from "./DeclaracaoConteudo";
import styles from "./styles.module.scss";
import { MaxInvoiceValueEnum } from "../../../../../enums/InvoiceValueEnum";
import DeliveryTypeEnum from "../../../../../enums/DeliveryTypeEnum";

const dcLinha = {
  id: Math.random(),
  item: "",
  valorUnitario: "",
  quantidade: ""
};

const PackageForm = ({ step, totalSteps, isReverseLogistics }) => {
  const history = useHistory();
  const api = new BrComerceApiService();
  const { auth } = store.getState();
  const { user } = auth;
  const query = useQuery();
  const isAdmin = user?.profile === ProfileEnum.ADMIN.value;
  const isReverse = window.location.href.toString().includes("reverse");

  const unverifiedRetail =
    selectedDeliveryType === RateTypeShippingEnum.RETAIL && !user.isVerified;

  const standardPackages = useMemo(() => {
    return [
      {
        name: "Caixa Pequena (P) - 1",
        width: 12,
        length: 16,
        height: 4,
        diameter: 0,
        type: "caixa"
      },
      {
        name: "Caixa Comprida (C) - 1",
        width: 12,
        length: 32,
        height: 8,
        diameter: 0,
        type: "caixa"
      },
      {
        name: "Caixa Comprida (C) - 2",
        width: 12,
        length: 32,
        height: 12,
        diameter: 0,
        type: "caixa"
      },
      {
        name: "Caixas Médias (M) - 1",
        width: 24,
        length: 16,
        height: 8,
        diameter: 0,
        type: "caixa"
      },
      {
        name: "Caixas Médias (M) - 2",
        width: 24,
        length: 16,
        height: 12,
        diameter: 0,
        type: "caixa"
      },
      {
        name: "Caixas Grandes (G) - 1",
        width: 32,
        length: 24,
        height: 8,
        diameter: 0,
        type: "caixa"
      },
      {
        name: "Caixas Grandes (G) - 2",
        width: 32,
        length: 24,
        height: 12,
        diameter: 0,
        type: "caixa"
      },
      {
        name: "Caixas Grandes (G) - 3",
        width: 32,
        length: 24,
        height: 16,
        diameter: 0,
        type: "caixa"
      },
      {
        name: "Caixas Grandes (G) - 4",
        width: 32,
        length: 24,
        height: 20,
        diameter: 0,
        type: "caixa"
      },
      {
        name: "Caixas Super (S) - 1",
        width: 38,
        length: 32,
        height: 4,
        diameter: 0,
        type: "caixa"
      },
      {
        name: "Caixas Super (S) - 2",
        width: 38,
        length: 32,
        height: 8,
        diameter: 0,
        type: "caixa"
      },
      {
        name: "Caixas Super (S) - 3",
        width: 38,
        length: 32,
        height: 12,
        diameter: 0,
        type: "caixa"
      },
      {
        name: "Caixas Super (S) - 4",
        width: 38,
        length: 32,
        height: 16,
        diameter: 0,
        type: "caixa"
      },
      {
        name: "Caixas Super (S) - 5",
        width: 38,
        length: 32,
        height: 20,
        diameter: 0,
        type: "caixa"
      },
      {
        name: "Caixas Super (S) - 6",
        width: 38,
        length: 32,
        height: 24,
        diameter: 0,
        type: "caixa"
      },
      {
        name: "Caixas Super (S) - 7",
        width: 38,
        length: 32,
        height: 28,
        diameter: 0,
        type: "caixa"
      },
      {
        name: "Caixas Hiper (H) - 1",
        width: 48,
        length: 64,
        height: 12,
        diameter: 0,
        type: "caixa"
      },
      {
        name: "Caixas Hiper (H) - 2",
        width: 48,
        length: 64,
        height: 20,
        diameter: 0,
        type: "caixa"
      },
      {
        name: "Caixas Hiper (H) - 3",
        width: 48,
        length: 64,
        height: 28,
        diameter: 0,
        type: "caixa"
      },
      {
        name: "Caixas Hiper (H) - 4",
        width: 48,
        length: 64,
        height: 32,
        diameter: 0,
        type: "caixa"
      },
      {
        name: "Caixas Hiper (H) - 5",
        width: 48,
        length: 64,
        height: 48,
        diameter: 0,
        type: "caixa"
      },
      {
        name: "Envelope médio",
        width: 25,
        length: 35.3,
        height: 0,
        diameter: 0,
        type: "envelope"
      },
      {
        name: "Envelope grande",
        width: 30,
        length: 43,
        height: 0,
        diameter: 0,
        type: "envelope"
      }
    ];
  }, []);

  const typeOptions = useMemo(() => {
    return [
      { label: "Caixa", value: "caixa" },
      { label: "Envelope", value: "envelope" },
      { label: "Cilindro", value: "cilindro" }
    ];
  }, []);

  const [selectedSavedMeasure, setSelectedSavedMeasure] = useState("");
  const [selectedSavedMeasureOk, setSelectedSavedMeasureOk] = useState("");
  const [selectedSavedMeasureError, setSelectedSavedMeasureError] = useState(
    ""
  );

  const [packageIdentification, setPackageIdentification] = useState("");
  const [packageIdentificationOK, setPackageIdentificationOK] = useState(
    "vazio"
  );
  const [packageIdentificationError, setPackageIdentificationError] = useState(
    ""
  );

  const [namePackage, setNamePackage] = useState("");
  const [namePackageOK, setNamePackageOK] = useState("vazio");
  const [namePackageError, setNamePackageError] = useState("");

  const [packageNumber, setPackageNumber] = useState("");
  const [packageNumberOK, setPackageNumberOK] = useState("vazio");
  const [packageNumberError, setPackageNumberError] = useState("");

  const [identificacaoOK, setIdentificacaoOK] = useState("vazio");
  const [identificacaoError, setIdentificacaoError] = useState("");
  const [identificacao, setIdentificacao] = useState("");

  const [valorDaNfOK, setValorDaNfOK] = useState("vazio");
  const [valorDaNfError, setValorDaNfError] = useState("");
  const [valorDaNf, setValorDaNf] = useState("");

  const [alturaOK, setAlturaOK] = useState("vazio");
  const [alturaError, setAlturaError] = useState("");
  const [altura, setAltura] = useState("");

  const [larguraOK, setLarguraOK] = useState("vazio");
  const [larguraError, setLarguraError] = useState("");
  const [largura, setLargura] = useState("");

  const [comprimentoOK, setComprimentoOK] = useState("vazio");
  const [comprimentoError, setComprimentoError] = useState("");
  const [comprimento, setComprimento] = useState("");

  const [pesoOK, setPesoOK] = useState("vazio");
  const [pesoError, setPesoError] = useState("");
  const [peso, setPeso] = useState("");

  const [diametroOK, setDiametroOK] = useState("vazio");
  const [diametroError, setDiametroError] = useState("");
  const [diametro, setDiametro] = useState("");

  /*  const [fileOK, setFileOK] = useState("vazio"); //anexar arquivo XML desabilitado por enquanto
  const [fileError, setFileError] = useState("");
  const [file, setFile] = useState(""); */

  const [nFNumberOK, setNFNumberOK] = useState("vazio");
  const [nFNumberError, setNFNumberError] = useState("");
  const [nFNumber, setNFNumber] = useState("");

  const [listaDc, setListaDc] = useState([dcLinha]);
  const [errorInNFOrDC, setErrorInNFOrDC] = useState("");
  const [errorInDC, setErrorInDC] = useState("vazio");
  const [errorInDCMessage, setErrorInDCMessage] = useState("");

  const [tabNf, setTabNf] = useState(true);

  const [openFileModal, setOpenFileModal] = useState(false);

  const [newMeasure, setNewMeasure] = useState(false);

  const [insurance, setInsurance] = useState(false);

  const [saveMeasurements, setSaveMeasurements] = useState(false);

  const [openInfoMobile, setOpenInfoMobile] = useState(false);

  const [type, setType] = useState({ label: "Caixa", value: "caixa" });
  const [typeStatus, setTypeStatus] = useState("");

  const [heightMaxLimit, setHeightMaxLimit] = useState(0);
  const [widthMaxLimit, setWidthMaxLimit] = useState(0);
  const [lengthMaxLimit, setLengthMaxLimit] = useState(0);
  const [diameterMaxLimit, setDiameterMaxLimit] = useState(0);
  const [weightMaxLimit, setWeightMaxLimit] = useState(30);

  const [heightMinLimit, setHeightMinLimit] = useState(0);
  const [widthMinLimit, setWidthMinLimit] = useState(0);
  const [lengthMinLimit, setLengthMinLimit] = useState(0);
  const [diameterMinLimit, setDiameterMinLimit] = useState(0);
  const [weightMinLimit, setWeightMinLimit] = useState(0);

  const [sendLocation, setSendLocation] = useState({});
  const [destinyLocation, setDestinyLocation] = useState({});

  const [sendOpts, setSendOpts] = useState([]);
  const [sendOpt, setSendOpt] = useState();

  const [loading, setLoading] = useState(false);

  const [buttonSubmitIsAble, setButtonSubmitIsAble] = useState(false);

  const [formState, setFormState] = useState("Calculate");

  const [formState2, setFormState2] = useState("Calculate");

  const [packagesOpts, setPackagesOpts] = useState([
    { label: "Carregando..." }
  ]);

  const [userPackages, setUserPackages] = useState([]);

  const [updatedShipping, setUpdatedShipping] = useState();

  const [lastShippingPackageLoaded, setLastShippingPackageLoaded] = useState({
    value: "",
    label: ""
  });

  const [validationListDc, setValidationListDc] = useState(false);

  const [selectedDeliveryType, setSelectedDeliveryType] = useState();

  const [seekMeasures, setSeekMeasures] = useState(false);

  const isRetail = !user?.canCreateCollectSchedule || isReverseLogistics;

  const optionsCheck = [
    {
      value: RateTypeShippingEnum.COLLECT,
      label: "Desejo que o objeto seja coletado"
    },
    {
      value: RateTypeShippingEnum.RETAIL,
      label: "Desejo postar o objeto nos correios"
    }
  ];

  const handleRemoveItemListDC = id => {
    if (listaDc.length > 1) {
      setListaDc(prevProps => prevProps.filter(item => item.id !== id));
    } else {
      setListaDc([{ ...dcLinha, id: Math.random() }]);
    }
  };

  const handleChangeListDC = useCallback(
    values => {
      const listHandled = listaDc.map((item, i) => {
        if (item.id === values.id) {
          return values;
        }

        return item;
      });

      setListaDc(listHandled);

      const hasInvalidItems = listHandled.some(item => item.isInvalid);

      setErrorInDC(hasInvalidItems ? "notOk" : "");
      setErrorInDCMessage("");
      if (!hasInvalidItems) {
        setErrorInNFOrDC("");
      }
    },
    [listaDc]
  );

  const handleNamePackage = useCallback(
    event => {
      const value = event.target.value;
      const valueParse = value.replace(/[^a-zA-Z0-9 ]+/g, "");
      setNamePackage(valueParse);

      const hasErrorInPackageNumber =
        packageNumberError.length && packageNumberOK === "notOk";

      if (value.length === 0) {
        setNamePackageError("");
        setNamePackageOK("vazio");
      }

      if (hasErrorInPackageNumber && value) {
        setNamePackageError("");
        setNamePackageOK("ok");

        // Limpando o erro do campo de NUMERO_DO_PEDIDO
        setPackageNumberError("");
        setPackageNumberOK("vazio");
      } else if (value) {
        setNamePackageError("");
        setNamePackageOK("ok");
      }
    },
    [packageNumberError, packageNumberOK]
  );

  const handlePackageNumber = useCallback(
    event => {
      const value = event.target.value.replace(/\D/g, "");
      setPackageNumber(value);

      const hasErrorInPackageName =
        namePackageError.length && namePackageOK === "notOk";

      if (value.length === 0) {
        setPackageNumberError("");
        setPackageNumberOK("vazio");
      }

      if (hasErrorInPackageName && value) {
        setPackageNumberError("");
        setPackageNumberOK("ok");

        // Limpando o erro do campo de NOME_DO_PACOTE
        setNamePackageError("");
        setNamePackageOK("vazio");
      } else if (value) {
        setPackageNumberError("");
        setPackageNumberOK("ok");
      }
    },
    [namePackageError, namePackageOK]
  );

  const formatterReplaceForDecimals = (match, group1, group2) => {
    if (group1) {
      return `${group1},${group2}`;
    }

    return `${group2}`;
  };

  const handleInputIdentificacao = useCallback(e => {
    let value = e.currentTarget.value;
    setIdentificacao(value);
    value.length < 2 ? setIdentificacaoOK("notOk") : setIdentificacaoOK("ok");
    if (value.length === 0) {
      setIdentificacaoError("Campo obrigatório");
    } else if (value.length === 1) {
      setIdentificacaoError("Identificação deve ter pelo menos 2 caracteres");
    } else {
      setIdentificacaoError("");
    }
  }, []);

  const handleInputValorDaNf = useCallback(
    valueInput => {
      let value = formatMoney(valueInput);

      setValorDaNf(value);

      if (Number(value.replace(/,/g, ".")) < 1) {
        setValorDaNfOK("notOk");
        setValorDaNfError("Valor mínimo R$ 1,00");
      } else {
        setValorDaNfOK("vazio");

        if (value) {
          setValorDaNfOK("ok");
          if (nFNumber) {
            setErrorInNFOrDC("");
          }
        }
        setValorDaNfError("");
      }
    },
    [nFNumber]
  );

  const handleValidationAltura = useCallback(
    value => {
      if (!heightMaxLimit && !heightMinLimit) {
        setAlturaError("");
        setAlturaOK("vazio");
        setAltura("0");
        return;
      }

      const valueNumber = Number(value);

      value.length < 1 ||
      valueNumber > heightMaxLimit ||
      valueNumber < heightMinLimit
        ? setAlturaOK("notOk")
        : setAlturaOK("ok");
      if (value.length === 0) {
        setAlturaError("Campo obrigatório");
      } else if (valueNumber > heightMaxLimit) {
        setAlturaError("Altura excedida");
      } else if (valueNumber < heightMinLimit) {
        setAlturaError("Altura mínima não atingida");
      } else {
        setAlturaError("");
      }
    },
    [heightMaxLimit, heightMinLimit]
  );

  const handleInputAltura = useCallback(
    e => {
      /* const valueCleared = e.currentTarget.value.replace(valueInput);
      let valueHandledForInput = valueCleared;

      if (
        Number(valueHandledForInput) >= 1 &&
        valueHandledForInput !== "" &&
        valueHandledForInput.length < 2
      ) {
        valueHandledForInput = valueHandledForInput.padStart(2, "0");
      }

      if (valueHandledForInput !== "" && valueHandledForInput.length === 3) {
        if (valueHandledForInput.startsWith("0")) {
          const arrayNumbers = valueHandledForInput.split("");
          arrayNumbers.splice(0, 1);

          valueHandledForInput = arrayNumbers.join("");
        }
      }

      const valueFormatted = String(valueHandledForInput).replace(
        /(\d{0,3}?)(\d{2}$)/g,
        formatterReplaceForDecimals
      );

      const valueInNumberFormat = valueFormatted.replace(",", ".");
      const MAX_LENGTH = 6;
      if (valueInNumberFormat.length >= MAX_LENGTH) {
        return;
      } */

      setAltura(e.currentTarget.value);
      handleValidationAltura(e.currentTarget.value);
    },
    [handleValidationAltura]
  );

  const handleValidationLargura = useCallback(
    value => {
      if (!widthMaxLimit && !widthMinLimit) {
        setLarguraError("");
        setLarguraOK("vazio");
        setLargura("0");
        return;
      }

      const valueInNumber = Number(value);

      value.length < 1 ||
      valueInNumber > widthMaxLimit ||
      valueInNumber < widthMinLimit
        ? setLarguraOK("notOk")
        : setLarguraOK("ok");
      if (value.length === 0) {
        setLarguraError("Campo obrigatório");
      } else if (valueInNumber > widthMaxLimit) {
        setLarguraError("Largura excedida");
      } else if (valueInNumber < widthMinLimit) {
        setLarguraError("Largura mínima não atingida");
      } else {
        setLarguraError("");
      }
    },
    [widthMaxLimit, widthMinLimit]
  );

  const handleInputLargura = useCallback(
    e => {
      /* const valueCleared = e.currentTarget.value.replace(/\D/g, "");
      let valueHandledForInput = valueCleared;

      if (
        Number(valueHandledForInput) >= 1 &&
        valueHandledForInput !== "" &&
        valueHandledForInput.length < 2
      ) {
        valueHandledForInput = valueHandledForInput.padStart(2, "0");
      }

      if (valueHandledForInput !== "" && valueHandledForInput.length === 3) {
        if (valueHandledForInput.startsWith("0")) {
          const arrayNumbers = valueHandledForInput.split("");
          arrayNumbers.splice(0, 1);

          valueHandledForInput = arrayNumbers.join("");
        }
      }

      const valueFormatted = String(valueHandledForInput).replace(
        /(\d{0,3}?)(\d{2}$)/g,
        formatterReplaceForDecimals
      );

      const valueInNumberFormat = valueFormatted.replace(",", ".");
      const MAX_LENGTH = 6;
      if (valueInNumberFormat.length >= MAX_LENGTH) {
        return;
      } */

      setLargura(e.currentTarget.value);
      handleValidationLargura(e.currentTarget.value);
    },
    [handleValidationLargura]
  );

  const handleValidationComprimento = useCallback(
    value => {
      if (!lengthMaxLimit && !lengthMinLimit) {
        setComprimento("0");
        setComprimentoError("");
        setComprimentoOK("vazio");
        return;
      }

      const valueNumber = Number(value);

      value.length < 1 ||
      valueNumber > lengthMaxLimit ||
      valueNumber < lengthMinLimit
        ? setComprimentoOK("notOk")
        : setComprimentoOK("ok");
      if (value.length === 0) {
        setComprimentoError("Campo obrigatório");
      } else if (valueNumber > lengthMaxLimit) {
        setComprimentoError("Comprimento excedido");
      } else if (valueNumber < lengthMinLimit) {
        setComprimentoError("Comprimento mínimo não atingido");
      } else {
        setComprimentoError("");
      }
    },
    [lengthMaxLimit, lengthMinLimit]
  );

  const handleInputComprimento = useCallback(
    e => {
      /* const valueCleared = e.currentTarget.value.replace(/\D/g, "");
      let valueHandledForInput = valueCleared;

      if (
        Number(valueHandledForInput) >= 1 &&
        valueHandledForInput !== "" &&
        valueHandledForInput.length < 2
      ) {
        valueHandledForInput = valueHandledForInput.padStart(2, "0");
      }

      if (valueHandledForInput !== "" && valueHandledForInput.length === 3) {
        if (valueHandledForInput.startsWith("0")) {
          const arrayNumbers = valueHandledForInput.split("");
          arrayNumbers.splice(0, 1);

          valueHandledForInput = arrayNumbers.join("");
        }
      }

      const valueFormatted = String(valueHandledForInput).replace(
        /(\d{0,3}?)(\d{2}$)/g,
        formatterReplaceForDecimals
      );

      const valueInNumberFormat = valueFormatted.replace(",", ".");
      const MAX_LENGTH = 6;
      if (valueInNumberFormat.length >= MAX_LENGTH) {
        return;
      } */

      setComprimento(e.currentTarget.value);
      handleValidationComprimento(e.currentTarget.value);
    },
    [handleValidationComprimento]
  );

  const handleValidationDiametro = useCallback(
    value => {
      if (!diameterMaxLimit && !diameterMinLimit) {
        setDiametro("0");
        setDiametroError("");
        setDiametroOK("vazio");
        return;
      }

      const valueNumber = Number(value);

      value.length < 1 ||
      valueNumber > diameterMaxLimit ||
      valueNumber < diameterMinLimit
        ? setDiametroOK("notOk")
        : setDiametroOK("ok");
      if (value.length === 0) {
        setDiametroError("Campo obrigatório");
      } else if (valueNumber > diameterMaxLimit) {
        setDiametroError("Diâmetro excedido");
      } else if (valueNumber < diameterMinLimit) {
        setDiametroError("Diâmetro mínimo não atingido");
      } else {
        setDiametroError("");
      }
    },
    [diameterMaxLimit, diameterMinLimit]
  );

  const handleInputDiametro = useCallback(
    e => {
      /* const valueCleared = e.currentTarget.value.replace(/\D/g, "");
      let valueHandledForInput = valueCleared;

      if (
        Number(valueHandledForInput) >= 1 &&
        valueHandledForInput !== "" &&
        valueHandledForInput.length < 2
      ) {
        valueHandledForInput = valueHandledForInput.padStart(2, "0");
      }

      if (valueHandledForInput !== "" && valueHandledForInput.length === 3) {
        if (valueHandledForInput.startsWith("0")) {
          const arrayNumbers = valueHandledForInput.split("");
          arrayNumbers.splice(0, 1);

          valueHandledForInput = arrayNumbers.join("");
        }
      }

      const valueFormatted = String(valueHandledForInput).replace(
        /(\d{0,3}?)(\d{2}$)/g,
        formatterReplaceForDecimals
      );

      const valueInNumberFormat = valueFormatted.replace(",", ".");
      const MAX_LENGTH = 6;
      if (valueInNumberFormat.length >= MAX_LENGTH) {
        return;
      } */

      setDiametro(e.currentTarget.value);
      handleValidationDiametro(e.currentTarget.value);
    },
    [handleValidationDiametro]
  );

  const handleValidationPeso = useCallback(
    value => {
      if (
        value === "00,000" ||
        (weightMaxLimit === 0 && weightMinLimit === 0)
      ) {
        setPesoError("");
        setPesoOK("vazio");
        setPeso("0");
        return;
      }

      const valueNumber = Number(value?.replace(/,/g, "."));

      value?.length < 2 ||
      valueNumber > weightMaxLimit ||
      valueNumber < weightMinLimit
        ? setPesoOK("notOk")
        : setPesoOK("ok");
      if (value?.length === 0) {
        setPesoError("Campo obrigatório");
      } else if (value.length === 1) {
        setPesoError("Necessário pelo menos uma casa decimal");
      } else if (valueNumber > weightMaxLimit) {
        setPesoError("Peso excedido");
      } else if (valueNumber < weightMinLimit) {
        setPesoError("Peso mínimo 0.3kg");
      } else {
        setPesoError("");
      }
    },
    [weightMaxLimit, weightMinLimit]
  );

  const handleInputPeso = useCallback(
    e => {
      let value = e.currentTarget.value;
      let valueNumber = value?.replace(/\D/g, "");

      if (value !== "00,00" && !Number(valueNumber)) {
        return;
      }

      if (valueNumber.length > 2 && valueNumber.charAt() === "0") {
        valueNumber = valueNumber.substr(1, 5);
      }

      // Número tratado, pós o substr.
      if (valueNumber.length >= 6) {
        return;
      }

      valueNumber = valueNumber.padStart(5, "0");
      value = valueNumber.replace(/(\d{2})(\d{3})/, "$1,$2");

      setPeso(value);
      handleValidationPeso(value);
    },
    [handleValidationPeso]
  );

  const handleSubmitDeclarationContent = useCallback(() => {
    if (
      !listaDc.every(
        item => !!item.item && !!item.quantidade && !!item.valorUnitario
      )
    ) {
      setValidationListDc(true);
      //setErrorInDCMessage("Todos campos são obrigatórios");
      return;
    } else {
      setErrorInDCMessage("");
      setValidationListDc(false);
    }

    if (errorInDC.toLowerCase() === "notok") {
      return;
    }

    setOpenFileModal(false);
  }, [errorInDC, listaDc]);

  const handleSubmitNF = () => {
    let isValid = true;
    if (nFNumber === "") {
      setNFNumberOK("notOk");
      setNFNumberError("Campo obrigatório");
      isValid = false;
    }

    if (valorDaNf === "") {
      setValorDaNfOK("notOk");
      setValorDaNfError("Campo obrigatório");
      isValid = false;
    }

    if (nFNumberError || valorDaNfError) {
      isValid = false;
    }

    if (isValid === false) {
      return;
    }

    setOpenFileModal(false);
  };

  const handleInputNFNumber = useCallback(
    e => {
      e.currentTarget.maxLength = 7;
      let value = e.currentTarget.value;
      value = value?.replace(/\D/g, "");
      setNFNumber(value);

      if (valorDaNf && value) {
        setErrorInNFOrDC("");
      }

      if (value === "") {
        setNFNumberOK("vazio");
        setNFNumberError("");
      } else {
        setNFNumberOK("ok");
        setNFNumberError("");
      }
    },
    [valorDaNf]
  );

  const handleSetType = useCallback(e => {
    setTypeStatus("");
    setType(e);

    if (e.value === "caixa") {
      setHeightMaxLimit(100);
      setWidthMaxLimit(100);
      setLengthMaxLimit(100);
      setDiameterMaxLimit(0);
      setWeightMaxLimit(30);

      setHeightMinLimit(1);
      setWidthMinLimit(10);
      setLengthMinLimit(15);
      setDiameterMinLimit(0);
      setWeightMinLimit(0);
    }
    if (e.value === "envelope") {
      setHeightMaxLimit(0);
      setWidthMaxLimit(60);
      setLengthMaxLimit(60);
      setDiameterMaxLimit(0);
      setWeightMaxLimit(30);

      setHeightMinLimit(0);
      setWidthMinLimit(11);
      setLengthMinLimit(16);
      setDiameterMinLimit(0);
      setWeightMinLimit(0);
    }
    if (e.value === "cilindro") {
      setHeightMaxLimit(0);
      setWidthMaxLimit(0);
      setLengthMaxLimit(100);
      setDiameterMaxLimit(91);
      setWeightMaxLimit(30);

      setHeightMinLimit(0);
      setWidthMinLimit(0);
      setLengthMinLimit(18);
      setDiameterMinLimit(5);
      setWeightMinLimit(0);
    }
  }, []);

  const handleSelectSavedPackages = useCallback(
    async a => {
      setIdentificacao(a.name);
      setLoading(true);

      setSelectedSavedMeasureOk("");
      setSelectedSavedMeasureError("");

      handleSetType({
        label: a.type.charAt(0).toUpperCase() + a.type.substr(1, a.type.length),
        value: a.type.toLowerCase()
      });

      setLargura(String(a.width).replace(/\./g, ","));
      setLarguraError("");
      setLarguraOK("ok");
      setComprimento(String(a.length).replace(/\./g, ","));
      setComprimentoError("");
      setComprimentoOK("ok");
      setDiametro(String(a.diameter).replace(/\./g, ","));
      setDiametroError("");
      setDiametroOK("ok");
      setAltura(String(a.height).replace(/\./g, ","));
      setAlturaError("");
      setAlturaOK("ok");

      const timeout = setTimeout(() => {
        handleValidationAltura(a.height);
        handleValidationLargura(a.width);
        handleValidationComprimento(a.length);
        handleValidationDiametro(a.diameter);
      }, 1000);

      clearTimeout(timeout);

      setLoading(false);
    },
    [
      handleSetType,
      handleValidationAltura,
      handleValidationLargura,
      handleValidationComprimento,
      handleValidationDiametro
    ]
  );

  const handleChangeMedidasSalvas = useCallback(
    e => {
      if (!!userPackages.length === false) return;
      setSelectedSavedMeasure(e);

      const findPackage = userPackages.find(
        stdPackage => stdPackage.name === e.label
      );

      if (e.value === "createPack") {
        setNewMeasure(true);
        setIdentificacao("");
        return;
      }

      handleSelectSavedPackages(findPackage);
    },
    [handleSelectSavedPackages, userPackages]
  );

  const fieldsFormIsAbleToSubmit = useCallback(
    (activedBySideEffect = false) => {
      let result = true;

      // Informações que ambas abas (MEDIDAS_SALVAS, NOVAS_MEDIDAS) tem em comum
      if (packageIdentification === "") {
        if (!activedBySideEffect) {
          setPackageIdentificationOK("notOk");
          setPackageIdentificationError("Campo obrigatório");
          result = false;
        } else {
          return false;
        }
      } else {
        const regex = /^[0-9]+$/;
        if (regex.test(packageIdentification)) {
          setPackageNumber(packageIdentification);
          setNamePackage("");
        } else {
          setNamePackage(packageIdentification);
          setPackageNumber("");
        }
      }

      if (type === "") {
        if (!activedBySideEffect) {
          setTypeStatus("notOk");
          result = false;
        } else {
          return false;
        }
      }

      if (peso === "" && weightMaxLimit > 0) {
        if (!activedBySideEffect) {
          setPesoOK("notOk");
          setPesoError("Campo obrigatório");
          result = false;
        } else {
          return false;
        }
      }

      const declarationContentValue =
        errorInDC !== "notOk"
          ? listaDc.reduce((a, b) => {
              let total = 0;

              if (typeof b?.valorUnitario === "string") {
                total =
                  Number(
                    b?.valorUnitario?.replace(/[.]/g, "").replace(/[,]/g, ".")
                  ) * Number(b?.quantidade);
              } else {
                total = Number(b?.valorUnitario) * Number(b?.quantidade);
              }

              return total + a;
            }, 0)
          : 0;

      const nfValue =
        valorDaNfOK !== "notOk"
          ? Number(valorDaNf?.replace(/[.]/g, "").replace(/[,]/g, "."))
          : 0;

      const hasValueInNForDC = nfValue || declarationContentValue || 0;

      if (!hasValueInNForDC) {
        if (!activedBySideEffect) {
          setErrorInNFOrDC("Campo obrigatório");
          result = false;
        } else {
          return false;
        }
      }

      // Se não for uma nova medida
      if (newMeasure === false) {
        if (selectedSavedMeasure === "") {
          if (!activedBySideEffect) {
            setSelectedSavedMeasureOk("notOk");
            setSelectedSavedMeasureError("Campo obrigatório");
            result = false;
          } else {
            return false;
          }
        }

        if (result === false) {
          return false;
        }
      } else {
        if (identificacao === "") {
          if (!activedBySideEffect) {
            setIdentificacaoOK("notOk");
            setIdentificacaoError("Campo obrigatório");
            result = false;
          } else {
            return false;
          }
        }

        if (altura === "" && heightMaxLimit > 0) {
          if (!activedBySideEffect) {
            setAlturaOK("notOk");
            setAlturaError("Campo obrigatório");
            result = false;
          } else {
            return false;
          }
        }

        if (largura === "" && widthMaxLimit > 0) {
          if (!activedBySideEffect) {
            setLarguraOK("notOk");
            setLarguraError("Campo obrigatório");
            result = false;
          } else {
            return false;
          }
        }

        if (comprimento === "" && lengthMaxLimit > 0) {
          if (!activedBySideEffect) {
            setComprimentoOK("notOk");
            setComprimentoError("Campo obrigatório");
            result = false;
          } else {
            return false;
          }
        }

        if (diametro === "" && diameterMaxLimit > 0) {
          if (!activedBySideEffect) {
            setDiametroOK("notOk");
            setDiametroError("Campo obrigatório");
            result = false;
          } else {
            return false;
          }
        }

        if (result === false) {
          return result;
        }
      }

      if (newMeasure === false) {
        if (
          selectedSavedMeasureOk === "notOk" ||
          pesoOK === "notOk" ||
          (errorInNFOrDC === "notOk" &&
            valorDaNfOK === "notOk" &&
            errorInDC === "notOk")
        ) {
          return false;
        }
      } else if (
        (errorInNFOrDC === "notOk" &&
          valorDaNfOK === "notOk" &&
          errorInDC === "notOk") ||
        identificacaoOK === "notOk" ||
        alturaOK === "notOk" ||
        larguraOK === "notOk" ||
        comprimentoOK === "notOk" ||
        pesoOK === "notOk" ||
        diametroOK === "notOk" ||
        typeStatus === "notOk"
      ) {
        return false;
      }

      return result;
    },
    [
      altura,
      alturaOK,
      comprimento,
      comprimentoOK,
      diameterMaxLimit,
      diametro,
      diametroOK,
      errorInDC,
      errorInNFOrDC,
      heightMaxLimit,
      identificacao,
      identificacaoOK,
      largura,
      larguraOK,
      lengthMaxLimit,
      listaDc,
      namePackage,
      newMeasure,
      packageNumber,
      peso,
      pesoOK,
      selectedSavedMeasure,
      selectedSavedMeasureOk,
      type,
      typeStatus,
      valorDaNf,
      valorDaNfOK,
      weightMaxLimit,
      widthMaxLimit,
      packageIdentification,
      packageIdentificationError,
      packageIdentificationOK
    ]
  );

  const handleSubmitCalculate = useCallback(
    async e => {
      e.preventDefault();
      setLoading(true);

      const formCanSubmit = fieldsFormIsAbleToSubmit();
      if (formCanSubmit === false) {
        Swal.fire({
          title: "Validação",
          text: "Verificar preenchimento dos campos destacados",
          icon: "error"
        });
        setLoading(false);
        return;
      }

      try {
        if (newMeasure && saveMeasurements) {
          await api.makeHttpRequest({
            method: "POST",
            url: `/packages`,
            data: {
              name: identificacao,
              type: type.value,
              height: Number(String(altura)?.replace(/,/g, ".")) || 0,
              width: Number(String(largura)?.replace(/,/g, ".")) || 0,
              length: Number(String(comprimento)?.replace(/,/g, ".")) || 0,
              diameter: Number(String(diametro)?.replace(/,/g, ".")) || 0
            }
          });
        }

        const declarationContentsValue = listaDc.reduce((a, b) => {
          let total = 0;

          if (typeof b?.valorUnitario === "string") {
            total =
              Number(
                b?.valorUnitario?.replace(/[.]/g, "").replace(/[,]/g, ".")
              ) * Number(b?.quantidade);
          } else {
            total = Number(b?.valorUnitario) * Number(b?.quantidade);
          }

          return total + a;
        }, 0);

        const nfValue = Number(
          valorDaNf?.replace(/[.]/g, "").replace(/[,]/g, ".")
        );

        const invoiceValue = nfValue || declarationContentsValue || 0;

        const price = await api.makeHttpRequest({
          method: "POST",
          url: `/prices`,
          data: {
            zipOrigin: sendLocation.zip,
            zipDestination: destinyLocation.zip,
            format: type.value,
            length: Number(String(comprimento).replace(/,/g, ".")) || 0,
            width: Number(String(largura).replace(/,/g, ".")) || 0,
            height: Number(String(altura).replace(/,/g, ".")) || 0,
            weight:
              (Number(peso.replace(/,/g, ".")) < 0.3
                ? 0.3
                : Number(peso.replace(/,/g, "."))) || 0,
            diameter: Number(String(diametro).replace(/,/g, ".")) || 0,
            invoiceValue,
            isLogisticsReverse: isReverse ? true : false
          }
        });

        if (!price.prices.collect.length && !price.prices.retail.length) {
          return Swal.fire({
            title: "Erro",
            text: "Sem opções de frete disponível.",
            icon: "error"
          });
        }

        if (isRetail) {
          setSelectedDeliveryType(RateTypeShippingEnum.RETAIL);
        } else {
          setSelectedDeliveryType(RateTypeShippingEnum.COLLECT);
        }

        const isCollectAndHasNotLimit = !isRetail && user?.creditLimit < 1;

        if (isCollectAndHasNotLimit) {
          return Swal.fire({
            title: "Erro",
            text:
              "Seu usuário não possui limite de crédito. Entre em contato com nosso suporte.",
            icon: "error"
          });
        }

        setSendOpts(price.prices);

        setFormState("Create");
        setFormState2("Create");
      } catch (error) {
        console.log("error", error);
        if (error?.response?.data?.message === "Validation failed") {
          Swal.fire({
            title: "Erro",
            text:
              error?.response?.data?.validation?.body?.message ||
              "Ocorreu um erro!",
            icon: "error"
          });
        } else {
          Swal.fire({
            title: "Frete",
            text: "Erro ao calcular frete, tente novamente",
            icon: "error"
          });
        }
      } finally {
        setLoading(false);
      }
    },
    [
      altura,
      api,
      comprimento,
      destinyLocation.zip,
      diametro,
      fieldsFormIsAbleToSubmit,
      identificacao,
      largura,
      listaDc,
      newMeasure,
      peso,
      saveMeasurements,
      sendLocation.zip,
      type.value,
      valorDaNf,
      selectedDeliveryType
    ]
  );

  const handleSubmitCreate = useCallback(
    async e => {
      e.preventDefault();

      setLoading(true);

      if (!sendOpt) {
        Swal.fire({
          title: "Frete",
          text: "Selecione a opção de frete",
          icon: "error"
        });
        setLoading(false);
        return;
      }

      const formCanSubmit = fieldsFormIsAbleToSubmit();
      if (formCanSubmit === false) {
        Swal.fire({
          title: "Validação",
          text: "Verificar preenchimento dos campos destacados",
          icon: "error"
        });
        setLoading(false);
        return;
      }

      const declarationContentsValue = listaDc.reduce((a, b) => {
        const total =
          Number(
            String(b)
              ?.valorUnitario?.replace(/[.]/g, "")
              .replace(/[,]/g, ".")
          ) * Number(String(b)?.quantidade?.replace(/,/g, "."));

        return total + a;
      }, 0);

      const nfValue = Number(
        valorDaNf?.replace(/[.]/g, "").replace(/[,]/g, ".")
      );

      const invoiceValue = nfValue || declarationContentsValue || 0;

      const logisticsReverseEmail = window.localStorage.getItem(
        "brcom:email-logistics-reverse"
      );

      const isLogisticsReverse = !!logisticsReverseEmail && isReverseLogistics;

      const packageShipping = {
        name: namePackage ? namePackage : packageNumber,
        orderNumber: packageNumber ? packageNumber : 0,
        identificationPackage: identificacao,
        idOrigin: sendLocation.id,
        idDestination: destinyLocation.id,
        format: type.value,
        length: Number(String(comprimento).replace(/,/g, ".")) || 0,
        width: Number(String(largura).replace(/,/g, ".")) || 0,
        height: Number(String(altura).replace(/,/g, ".")) || 0,
        diameter: Number(String(diametro).replace(/,/g, ".")) || 0,
        weight: Number(peso.replace(/,/g, ".")) || 0,
        invoiceValue: invoiceValue,
        type: sendOpt.type,
        price:
          sendOpt.valorComDesconto +
          (insurance ? sendOpt.valorValorDeclarado : 0),
        insurance: insurance,
        insuranceValue: sendOpt.valorValorDeclarado,
        correios: sendOpt,
        declarationOfContents: !nFNumber ? listaDc : [],
        invoiceNumber: nFNumber,
        subUser: user?.sub,
        isLogisticsReverse,
        logisticsReverseEmail: logisticsReverseEmail || "",
        rateType: selectedDeliveryType
      };

      if (updatedShipping) {
        if (updatedShipping?.deliveriesData) {
          delete updatedShipping?.deliveriesData;
        }
        try {
          setLoading(true);
          await api.makeHttpRequest({
            method: "PUT",
            url: `/shipping/${updatedShipping.id}`,
            data: {
              ...updatedShipping,
              ...packageShipping,
              origin: {
                id: sendLocation.id
              },
              destination: {
                id: destinyLocation.id
              },
              logisticsReverseEmail: isAdmin
                ? updatedShipping.logisticsReverseEmail
                : logisticsReverseEmail
            }
          });

          localStorage.removeItem("brcom:destiny-location");
          localStorage.removeItem("brcom:destiny-location-resp");
          history.push("/carrinho");
        } catch (error) {
          Swal.fire({
            title: "Edição do envio",
            text: "Erro ao editar envio, tente novamente",
            icon: "error"
          });
        } finally {
          setLoading(false);
        }
      } else {
        try {
          setLoading(true);
          await api.makeHttpRequest({
            method: "POST",
            url: `/shipping`,
            data: packageShipping
          });

          localStorage.removeItem("brcom:destiny-location");
          localStorage.removeItem("brcom:destiny-location-resp");
          history.push("/carrinho");
        } catch (error) {
          Swal.fire({
            title: "Criação de envio",
            text: "Erro ao criar envio, tente novamente",
            icon: "error"
          });
        } finally {
          setLoading(false);
        }
      }
    },
    [
      altura,
      api,
      comprimento,
      destinyLocation.id,
      diametro,
      fieldsFormIsAbleToSubmit,
      history,
      identificacao,
      insurance,
      largura,
      listaDc,
      nFNumber,
      namePackage,
      packageNumber,
      peso,
      sendLocation.id,
      sendOpt,
      type.value,
      updatedShipping,
      user.sub,
      valorDaNf,
      isReverseLogistics,
      selectedDeliveryType
    ]
  );

  const hasInsurance = useMemo(() => {
    return sendOpt?.valorValorDeclarado > 0;
  }, [sendOpt]);

  // Coloca o título na página
  useEffect(() => {
    window.setPageTitle("Dados do pacote");
  }, []);

  // Reseta os erros da NF e Valor da NF caso n tenha valores.
  useEffect(() => {
    if (nFNumber === "") {
      setNFNumberOK("vazio");
      setNFNumberError("");
    }

    if (valorDaNf === "") {
      setValorDaNfOK("vazio");
      setValorDaNfError("");
    }
  }, [openFileModal]);

  // Redireciona o usuário caso n tenha preenchido
  // Informações de remetente/destinatário.
  useEffect(() => {
    const hasSenderInfo = localStorage.getItem("brcom:send-location-resp");
    const hasDestinyInfo = localStorage.getItem("brcom:destiny-location-resp");

    if (!hasSenderInfo) {
      history.push("/nova-coleta/normal-send-form");
      return;
    }

    if (!hasDestinyInfo) {
      history.push("/nova-coleta/destiny-form");
      return;
    }
  }, []);

  // Valida as informações dos inputs
  // E habilita/desabilita o botão de submit.
  useEffect(() => {
    const isAble = fieldsFormIsAbleToSubmit(true);
    setButtonSubmitIsAble(isAble);
  }, [fieldsFormIsAbleToSubmit]);

  // Set localização do remetente.
  useEffect(() => {
    const storage = window.localStorage.getItem("brcom:send-location-resp");
    if (storage) {
      setSendLocation(JSON.parse(storage));
    }
  }, []);

  // Set localização do destinatário.
  useEffect(() => {
    const storage = window.localStorage.getItem("brcom:destiny-location-resp");
    if (storage) {
      setDestinyLocation(JSON.parse(storage));
    }
  }, []);

  // Reseta o frete para quando há mudança nos campos
  // Que influenciam o valor final.
  useEffect(() => {
    setSendOpts([]);
    setSendOpt(null);
    setInsurance(false);
    setFormState("Calculate");
    setFormState2("Calculate");
  }, [valorDaNf, altura, largura, comprimento, peso, diametro, listaDc]);

  // Gerenciar a validação e necessidade dos inputs
  // relacionados as medidas do pacote
  useEffect(() => {
    if (!newMeasure) {
      return;
    }

    if (altura !== "") {
      handleValidationAltura(altura);
    }

    if (largura !== "") {
      handleValidationLargura(largura);
    }

    if (comprimento !== "") {
      handleValidationComprimento(comprimento);
    }

    if (diametro !== "") {
      handleValidationDiametro(diametro);
    }

    if (peso !== "") {
      handleValidationPeso(peso);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, newMeasure]);

  // Carrega todas opções de Formato
  useEffect(() => {
    async function getPackages() {
      let response = await api.makeHttpRequest({
        method: "GET",
        url: `packages`
      });

      const opts = response.map(userPackage => {
        return {
          label: userPackage.name,
          value: userPackage.id
        };
      });

      const optsStd = standardPackages.map(staPackage => {
        return {
          label: staPackage.name,
          value: staPackage.name
        };
      });

      const newOpts = opts.concat(optsStd);

      newOpts.unshift({
        label: "Criar novo pacote",
        value: "createPack"
      });

      response = response.concat(standardPackages);

      setPackagesOpts(newOpts);
      setUserPackages(response);
    }
    getPackages();
  }, [standardPackages, user]);

  // Selecionar o pacote padrão
  useEffect(() => {
    const isANewMeasure = newMeasure === true;
    const hasShippingId = !!query.get("shipping");
    const hasUnsavedPackage =
      packagesOpts.find(
        option => option.label === selectedSavedMeasure.label
      ) === null;

    if (isANewMeasure || hasUnsavedPackage || hasShippingId) {
      return;
    }

    const defaultPackageSelected = packagesOpts.find(
      option => option.label === "Caixa Pequena (P) - 1"
    );

    if (defaultPackageSelected) {
      handleChangeMedidasSalvas(defaultPackageSelected);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [packagesOpts, newMeasure, userPackages]);

  // Resgatar shipping passado por ID
  useEffect(() => {
    /* if (
      selectedSavedMeasure !== "" &&
      lastShippingPackageLoaded.value !== selectedSavedMeasure.value &&
      !newMeasure
    ) {
      const defaultPackageSelected = packagesOpts.find(
        option => option.label === selectedSavedMeasure.label
      );

      if (defaultPackageSelected) {
        handleChangeMedidasSalvas(defaultPackageSelected);
      }
      return;
    }

    if (newMeasure) {
      return;
    } */

    const shippingId = query.get("shipping");

    if (!shippingId || !!newMeasure) {
      return;
    }

    setLoading(true);

    api
      .makeHttpRequest({
        method: "GET",
        url: `shipping/${shippingId}`
      })
      .then(response => {
        handleSetType(typeOptions.find(type => type.value === response.format));

        setLastShippingPackageLoaded({
          value: response.identificationPackage,
          label: response.identificationPackage
        });

        setSelectedSavedMeasure({
          value: response.identificationPackage,
          label: response.identificationPackage
        });

        setIdentificacao(response.identificationPackage);

        handleInputPeso({
          currentTarget: {
            value: "0" + String(response.weight * 1000)
          }
        });

        setValorDaNf(
          response.invoiceValue.toLocaleString("pt-BR", {
            minimumFractionDigits: 2
          })
        );

        setUpdatedShipping(response);
        setNamePackage(response.name);
        setPackageNumber(response.orderNumber || "");
        setListaDc([...response?.declarationOfContents]);
        setNFNumber(response?.invoiceNumber || 0);

        handleInputAltura({
          currentTarget: {
            value: response.height
          }
        });

        handleInputLargura({
          currentTarget: {
            value: response.width
          }
        });

        handleInputComprimento({
          currentTarget: {
            value: response.length
          }
        });

        handleInputDiametro({
          currentTarget: {
            value: response.diameter
          }
        });
      })
      .catch(e => {
        Swal.fire({
          title: "Erro!",
          type: "error",
          text: "Não foi possível resgatar informações do pacote editado"
        });
      })
      .finally(() => {
        setLoading(false);
        setSeekMeasures(true);
      });
  }, [seekMeasures, !newMeasure]);

  // Invalidar o seguro se a opção selecionada for MINI ENVIOS, com valores de declaração > 100
  useEffect(() => {
    if (!!sendOpt && sendOpt.type === DeliveryTypeEnum.PACMINI) {
      const declaredContent =
        listaDc.reduce((a, b) => {
          const total =
            Number(
              String(b)
                ?.valorUnitario?.replace(/[.]/g, "")
                .replace(/[,]/g, ".")
            ) * Number(String(b)?.quantidade?.replace(/,/g, "."));

          return total + a;
        }, 0) >= MaxInvoiceValueEnum.PACMINI;

      const declaredNF =
        Number(valorDaNf?.replace(/[.]/g, "").replace(/[,]/g, ".")) >= MaxInvoiceValueEnum.PACMINI;

      const hasDeclaredValuePlusMax = declaredNF || declaredContent;

      if (!hasDeclaredValuePlusMax) {
        return;
      }

      setInsurance(false);
    }
  }, [listaDc, sendOpt, valorDaNf]);

  const mapOptionsCheck = state => {
    return (
      <div className={styles.shippingOpts}>
        {state?.map(opt => (
          <div className={styles.shippingOpt} key={opt.value}>
            <div className={styles.check}>
              {!(selectedDeliveryType && opt.value === selectedDeliveryType) ? (
                <div
                  onClick={() => {
                    setSelectedDeliveryType(opt.value);
                    setSendOpt(undefined);
                    setInsurance(false);
                  }}
                />
              ) : (
                <div>
                  <FiSquare />
                </div>
              )}
            </div>
            <p> {opt.label} </p>
          </div>
        ))}
      </div>
    );
  };

  const mapOptionsPrices = state => {
    return (
      <div className={styles.shippingOpts}>
        {state?.map(opt => (
          <div className={styles.shippingOpt} key={opt.codigo}>
            <div className={styles.check}>
              {!(sendOpt && opt.codigo === sendOpt.codigo) ? (
                <div onClick={() => setSendOpt(opt)} />
              ) : (
                <div>
                  <FiSquare />
                </div>
              )}
            </div>
            <p>
              {`${opt.nome} (até ${opt.prazoEntrega} ${
                opt.prazoEntrega > 1 ? "dias úteis" : "dia útil"
              }) - `}
              <span>
                {new Intl.NumberFormat("pt-BR", {
                  style: "currency",
                  currency: "BRL"
                }).format(opt.valorComDesconto)}
              </span>
            </p>
          </div>
        ))}
        {!user?.canCreateCollectSchedule && !user?.isVerified && (
          <p>
            * prazos estimados contam a partir do dia da postagem nos Correios
          </p>
        )}
      </div>
    );
  };

  return (
    <>
      <Loading loading={loading} />
      <div
        className={`${styles.container} ${
          openInfoMobile ? styles.handleContainerForModal : ""
        }`}
      >
        <div className={styles.formWrapper}>
          <div className={styles.formContainer}>
            <div className={styles.title}>
              <h2>
                Dados do Pacote {isReverseLogistics && " (Envio Reverso)"}
              </h2>
            </div>
            <Steps step={step} total={totalSteps} />

            <div className={styles.instructionTxt}>
              <p>
                Estamos quase lá! Agora vamos identificar este pacote para
                facilitar sua visualização na lista de coletas e rastreamento,
                além de ajudá-lo em pesquisas futuras.
              </p>
            </div>
            <form
              className={styles.form}
              onSubmit={
                formState === "Calculate"
                  ? handleSubmitCalculate
                  : handleSubmitCreate
              }
            >
              <div className={styles.inputsPackageIdentificationContainer}>
                <div className={`${styles.inputContainer} `}>
                  <div className={styles.input} role={packageIdentificationOK}>
                    <input
                      type="text"
                      name="packageIdentification"
                      placeholder="Nome ou Número do pedido"
                      onChange={e => setPackageIdentification(e.target.value)}
                      value={packageIdentification}
                      autoComplete="1234"
                    />
                    <p className={styles.error}>{packageIdentificationError}</p>
                  </div>
                  {/* <div className={styles.input} role={namePackageOK}>
                    <input
                      type="text"
                      name="packageName"
                      placeholder="Nome"
                      onChange={e => handleNamePackage(e)}
                      value={namePackage}
                      autoComplete="1234"
                    />
                    <a className={styles.tooltip}>
                      <IoIosInformationCircleOutline
                        color="#409E6A"
                        className={styles.svgInfo}
                      />
                      <span className={styles.tooltiptext}>
                        Este campo não aceita caracteres especiais
                      </span>
                    </a>

                    <BsExclamation
                      className={styles.danger}
                      role={namePackageOK}
                    />
                  </div>
                  <p className={styles.error}>{namePackageError}</p> */}
                </div>

                {/* <div className={styles.highlightedInfo}>
                  <p>ou</p>
                </div> */}

                {/* <div
                  className={`${styles.inputContainer} ${styles.inputContainerSmall} ${styles.inputContainerSm}`}
                >
                  <div className={styles.input} role={packageNumberOK}>
                    <input
                      type="text"
                      name="packageNumber"
                      placeholder="Número do pedido"
                      onChange={e => handlePackageNumber(e)}
                      value={packageNumber}
                      autoComplete="1234"
                    />
                    <AiOutlineCheck
                      className={styles.check}
                      role={packageNumberOK}
                    />
                    <BsExclamation
                      className={styles.danger}
                      role={packageNumberOK}
                    />
                  </div>
                  <p className={styles.error}>{packageNumberError}</p>
                </div> */}
              </div>
              {/* <div className={styles.instructionTxt}>
                <p>
                  Para sua comodidade, já abastecemos sua conta com as medidas
                  dos principais formatos de embalagem, inclusive das caixas
                  padrões dos Correios. Caso utilize embalagens com medidas
                  personalizadas, não se preocupe: você pode salvá-las para
                  poupar tempo nas próximas coletas!
                </p>
              </div> */}
              <div className={styles.instructionMeasuresTxt}>
                <FiAlertCircle />
                <p>
                  As medidas e peso do pacote serão conferidas no processo de
                  postagem, informe-os com precisão para evitar divergências no
                  valor do frete!
                </p>
              </div>
              {/* {!user?.canCreateCollectSchedule && !user?.isVerified && (
              )} */}
              {/* <div className={styles.switch}>
                <div
                  className={`${styles.switchContent} ${
                    newMeasure ? "" : styles.active
                  }`}
                  onClick={() => setNewMeasure(false)}
                >
                  <div className={styles.switchOptTitle}>
                    <p>Medidas Salvas</p>
                  </div>
                </div>
                <div
                  className={`${styles.switchContent} ${
                    newMeasure ? styles.active : ""
                  }`}
                  onClick={() => setNewMeasure(true)}
                >
                  <div className={styles.switchOptTitle}>
                    <p>Novas Medidas</p>
                  </div>
                </div>
                </div> */}
              <div className={styles.formContent}>
                <div
                  style={{ marginTop: "20px" }}
                  className={styles.inputsContainer}
                >
                  {newMeasure === false ? (
                    <div
                      style={{ maxWidth: "100%" }}
                      className={`${styles.formSelect} ${
                        selectedSavedMeasureOk === "notOk"
                          ? styles.formSelectError
                          : selectedSavedMeasureOk === "" &&
                            !!selectedSavedMeasure
                          ? styles.selectOk
                          : ""
                      }`}
                    >
                      <Select
                        name="medidasSalvas"
                        onChange={handleChangeMedidasSalvas}
                        options={packagesOpts}
                        value={selectedSavedMeasure}
                        placeholder="Pesquise ou selecione na lista"
                        styles={{
                          ...selectStyle,
                          placeholder: styles => ({
                            ...styles,
                            color: `${
                              selectedSavedMeasureOk === "notOk"
                                ? "#ca1818"
                                : `#807e80`
                            }`,
                            whiteSpace: "nowrap"
                          })
                        }}
                      />
                      <p className={styles.error}>
                        {selectedSavedMeasureError}
                      </p>
                    </div>
                  ) : (
                    <div className={`${styles.inputContainer}`}>
                      <div className={styles.input} role={identificacaoOK}>
                        <input
                          type="text"
                          name="identificacao"
                          placeholder="Nome do formato"
                          onChange={e => handleInputIdentificacao(e)}
                          value={identificacao}
                          autoComplete="1234"
                        />
                        <AiOutlineCheck
                          className={styles.check}
                          role={identificacaoOK}
                        />
                        <BsExclamation
                          className={styles.danger}
                          role={identificacaoOK}
                        />
                      </div>
                      <p className={styles.error}>{identificacaoError}</p>
                    </div>
                  )}
                </div>
                <div className={styles.inputsContainer}>
                  <div
                    className={`${styles.formSelect} ${
                      typeStatus === "notOk"
                        ? styles.formSelectError
                        : typeStatus === "" && !!type
                        ? styles.selectOk
                        : ""
                    }`}
                  >
                    <Select
                      onChange={e => handleSetType(e)}
                      isDisabled={newMeasure === false}
                      value={type}
                      defaultValue={type}
                      options={typeOptions}
                      placeholder="Pesquise ou selecione na lista"
                      styles={{
                        ...selectStyle,
                        placeholder: styles => ({
                          ...styles,
                          color: `${
                            typeStatus === "notOk" ? "#ca1818" : `#807e80`
                          }`,
                          whiteSpace: "nowrap"
                        })
                      }}
                    />
                    {typeStatus === "notOk" && (
                      <p className={styles.error}>Campo obrigatório</p>
                    )}
                  </div>

                  <div
                    className={`${styles.inputContainer} ${styles.inputContainerSmall} `}
                  >
                    <div
                      className={`${styles.input} ${(newMeasure === false ||
                        !heightMaxLimit ||
                        type?.value === undefined) &&
                        styles.fieldDisabled}`}
                      role={alturaOK}
                    >
                      <input
                        type="number"
                        name="altura"
                        placeholder="Altura (cm)"
                        onChange={e => handleInputAltura(e)}
                        value={altura}
                        disabled={
                          newMeasure === false ||
                          !heightMaxLimit ||
                          type?.value === undefined
                        }
                      />
                      {altura ? (
                        <span
                          style={{
                            display: "flex",
                            left: 16 + altura.length * 8 + "px",
                            fontSize: "14px",
                            position: "absolute"
                          }}
                        >
                          {" "}
                          cm{" "}
                        </span>
                      ) : null}
                      <AiOutlineCheck
                        className={styles.check}
                        role={alturaOK}
                      />
                      <BsExclamation
                        className={styles.danger}
                        role={alturaOK}
                      />
                    </div>
                    <p className={styles.error}>{alturaError}</p>
                  </div>

                  <div
                    className={`${styles.inputContainer} ${styles.inputContainerSmall} `}
                  >
                    <div
                      className={`${styles.input} ${(newMeasure === false ||
                        !widthMaxLimit ||
                        type?.value === undefined) &&
                        styles.fieldDisabled}`}
                      role={larguraOK}
                    >
                      <input
                        type="number"
                        name="largura"
                        placeholder="Largura (cm)"
                        onChange={e => handleInputLargura(e)}
                        value={largura}
                        disabled={
                          newMeasure === false ||
                          !widthMaxLimit ||
                          type?.value === undefined
                        }
                      />
                      {largura ? (
                        <span
                          style={{
                            display: "flex",
                            left: 16 + largura.length * 8 + "px",
                            fontSize: "14px",
                            position: "absolute"
                          }}
                        >
                          {" "}
                          cm{" "}
                        </span>
                      ) : null}
                      <AiOutlineCheck
                        className={styles.check}
                        role={larguraOK}
                      />
                      <BsExclamation
                        className={styles.danger}
                        role={larguraOK}
                      />
                    </div>
                    <p className={styles.error}>{larguraError}</p>
                  </div>

                  <div
                    className={`${styles.inputContainer} ${styles.inputContainerSmall} `}
                  >
                    <div
                      className={`${styles.input} ${(newMeasure === false ||
                        type?.value === undefined) &&
                        styles.fieldDisabled}`}
                      role={comprimentoOK}
                    >
                      <input
                        type="number"
                        name="comprimento"
                        placeholder="Comprimento (cm)"
                        onChange={e => handleInputComprimento(e)}
                        value={comprimento}
                        disabled={
                          newMeasure === false || type?.value === undefined
                        }
                      />
                      {comprimento ? (
                        <span
                          style={{
                            display: "flex",
                            left: 16 + comprimento.length * 8 + "px",
                            fontSize: "14px",
                            position: "absolute"
                          }}
                        >
                          {" "}
                          cm{" "}
                        </span>
                      ) : null}
                      <AiOutlineCheck
                        className={styles.check}
                        role={comprimentoOK}
                      />
                      <BsExclamation
                        className={styles.danger}
                        role={comprimentoOK}
                      />
                    </div>
                    <p className={styles.error}>{comprimentoError}</p>
                  </div>

                  <div
                    className={`${styles.inputContainer} ${styles.inputContainerSmall} `}
                  >
                    <div
                      className={`${styles.input} ${(newMeasure === false ||
                        !diameterMaxLimit ||
                        type?.value === undefined) &&
                        styles.fieldDisabled}`}
                      role={diametroOK}
                    >
                      <input
                        type="number"
                        name="diametro"
                        placeholder="Diâmetro (cm)"
                        onChange={e => handleInputDiametro(e)}
                        value={diametro}
                        disabled={
                          newMeasure === false ||
                          !diameterMaxLimit ||
                          type?.value === undefined
                        }
                      />
                      {diametro ? (
                        <span
                          style={{
                            display: "flex",
                            left: 16 + diametro.length * 8 + "px",
                            fontSize: "14px",
                            position: "absolute"
                          }}
                        >
                          {" "}
                          cm{" "}
                        </span>
                      ) : null}
                      <AiOutlineCheck
                        className={styles.check}
                        role={diametroOK}
                      />
                      <BsExclamation
                        className={styles.danger}
                        role={diametroOK}
                      />
                    </div>
                    <p className={styles.error}>{diametroError}</p>
                  </div>

                  <div
                    className={`${styles.inputContainer} ${styles.inputContainerSmall} `}
                  >
                    <div
                      className={`${styles.input} ${(type?.value ===
                        undefined ||
                        !weightMaxLimit) &&
                        styles.fieldDisabled}`}
                      role={pesoOK}
                    >
                      <input
                        type="text"
                        name="peso"
                        placeholder="Peso (kg)"
                        onChange={e => handleInputPeso(e)}
                        value={peso}
                        disabled={!weightMaxLimit || type?.value === undefined}
                      />
                      {peso ? (
                        <span
                          style={{
                            display: "flex",
                            left: 16 + peso.length * 8 + "px",
                            fontSize: "14px",
                            position: "absolute"
                          }}
                        >
                          {" "}
                          Kg{" "}
                        </span>
                      ) : null}
                      <AiOutlineCheck className={styles.check} role={pesoOK} />
                      <BsExclamation className={styles.danger} role={pesoOK} />
                    </div>
                    <p className={styles.error}>{pesoError}</p>
                  </div>

                  <div className={styles.inputFileContainer}>
                    <div
                      className={`${styles.inputFile} ${
                        valorDaNfOK !== "notOk" &&
                        !errorInNFOrDC &&
                        nFNumber &&
                        valorDaNf
                          ? styles.tabNF
                          : !errorInNFOrDC &&
                            errorInDC !== "notOk" &&
                            listaDc.length &&
                            listaDc.every(
                              item =>
                                !!item.item &&
                                !!item.quantidade &&
                                !!item.valorUnitario
                            )
                          ? styles.tabDC
                          : ""
                      }`}
                      onClick={() => {
                        setOpenFileModal(true);
                        if (!errorInNFOrDC && nFNumber && valorDaNf) {
                          setTabNf(true);
                        } else if (
                          !errorInNFOrDC &&
                          listaDc.length &&
                          listaDc.every(
                            item =>
                              !!item.item &&
                              !!item.quantidade &&
                              !!item.valorUnitario
                          )
                        ) {
                          setTabNf(false);
                        }
                      }}
                    >
                      <span>
                        {!errorInNFOrDC &&
                        nFNumber &&
                        valorDaNf &&
                        valorDaNfOK !== "notOk"
                          ? "Alterar Nota Fiscal Inserida"
                          : !errorInNFOrDC &&
                            errorInDC !== "notOk" &&
                            listaDc.length &&
                            listaDc.every(
                              item =>
                                !!item.item &&
                                !!item.quantidade &&
                                !!item.valorUnitario
                            )
                          ? "Alterar Declaração de Conteúdo Inserida"
                          : "Inserir Nota Fiscal ou Declaração de Conteúdo"}
                      </span>
                    </div>
                    {!!errorInNFOrDC && (
                      <p className={styles.error}>{errorInNFOrDC}</p>
                    )}
                  </div>
                </div>
              </div>

              {/* Mobile */}
              {(sendOpts?.retail?.length || sendOpts?.collect?.length) && (
                <div
                  className={`${styles.insuranceAndShippingOptContainer} ${styles.mobile}`}
                >
                  {(sendOpts?.retail[0]?.msgErro ||
                    sendOpts?.collect[0]?.msgErro) && (
                    <div
                      className="alert alert-danger mt-4 mb-n1 d-flex d-flex align-items-center"
                      role="alert"
                    >
                      <p className="mt-1 mb-1">
                        {sendOpts.retail[0].msgErro ||
                          sendOpts.collect[0].msgErro}
                      </p>
                    </div>
                  )}

                  {isAdmin &&
                  !isReverseLogistics &&
                  sendOpts.retail.length &&
                  sendOpts.collect.length ? (
                    <>
                      <div className={styles.insuranceAndShippingOptTitle}>
                        <h3>Tipo de serviço</h3>
                      </div>

                      <div className={styles.insuranceAndShippingOptTxt}>
                        <p>Selecione uma das opções de postagem:</p>
                      </div>
                      {mapOptionsCheck(optionsCheck)}
                    </>
                  ) : null}

                  <>
                    <div className={styles.insuranceAndShippingOptTitle}>
                      <h3>Opções de envio</h3>
                    </div>
                    <div className={styles.insuranceAndShippingOptTxt}>
                      <p>
                        Selecione uma das opções de envio disponíveis para o CEP
                        informado:
                      </p>
                    </div>
                  </>

                  {selectedDeliveryType === RateTypeShippingEnum.COLLECT
                    ? mapOptionsPrices(sendOpts.collect)
                    : mapOptionsPrices(sendOpts.retail)}

                  {!!sendOpt && hasInsurance ? (
                    <>
                      <div className={styles.insuranceAndShippingOptTitle}>
                        <h3>Seguro</h3>
                      </div>

                      <div className={styles.insuranceAndShippingOptTxt}>
                        <p>
                          Por um valor simbólico, você pode proteger seu pacote
                          e ser indenizado tanto em caso de atraso na entrega
                          quanto extravio. <span>Saiba mais</span>
                        </p>
                      </div>
                    </>
                  ) : null}

                  {!!sendOpt &&
                  hasInsurance &&
                  sendOpt.type === DeliveryTypeEnum.PACMINI &&
                  (listaDc.reduce((a, b) => {
                    const total = b.valorUnitario * b.quantidade;

                    return total + a;
                  }, 0) >= MaxInvoiceValueEnum.PACMINI ||
                    valorDaNf >= MaxInvoiceValueEnum.PACMINI) ? (
                    <p
                      style={{
                        color: "red",
                        fontSize: 12
                      }}
                    >
                      Infelizmente, a opção "Mini Envios" não é assegurada para
                      valores superiores a <strong>R${MaxInvoiceValueEnum.PACMINI.toFixed(2).replace(".", ",")}</strong>.
                    </p>
                  ) : !!sendOpt && hasInsurance ? (
                    <div
                      className={styles.checkSeguro}
                      onClick={() => setInsurance(!insurance)}
                    >
                      {!insurance ? <div /> : <FiCheck />}
                      <p>
                        Desejo proteger meu pacote por apenas &nbsp;
                        <span>
                          {new Intl.NumberFormat("pt-BR", {
                            style: "currency",
                            currency: "BRL"
                          }).format(
                            !!sendOpt ? sendOpt.valorValorDeclarado : 0
                          )}
                        </span>
                      </p>
                    </div>
                  ) : null}

                  {!!sendOpt ? (
                    <>
                      <div className={styles.insuranceAndShippingOptTitle}>
                        <h3>Resumo</h3>
                      </div>

                      <div className={styles.resume}>
                        {!!sendOpt && (
                          <p className={styles.description}>
                            {sendOpt.nome} -{" "}
                            <span>
                              {new Intl.NumberFormat("pt-BR", {
                                style: "currency",
                                currency: "BRL"
                              }).format(sendOpt.valorComDesconto)}
                            </span>
                          </p>
                        )}
                        {!!insurance && !!sendOpt && (
                          <p className={styles.description}>
                            Seguro -{" "}
                            <span>
                              {new Intl.NumberFormat("pt-BR", {
                                style: "currency",
                                currency: "BRL"
                              }).format(sendOpt.valorValorDeclarado)}
                            </span>
                          </p>
                        )}
                        <p className={styles.totalValue}>
                          Total -{" "}
                          <span>
                            {new Intl.NumberFormat("pt-BR", {
                              style: "currency",
                              currency: "BRL"
                            }).format(
                              !!sendOpt
                                ? insurance
                                  ? sendOpt.valorComDesconto +
                                    sendOpt.valorValorDeclarado
                                  : sendOpt.valorComDesconto
                                : 0
                            )}
                          </span>
                        </p>
                        {sendOpt != null && (
                          <div className={styles.buttonsContainer}>
                            <div className={styles.buttonContainer}>
                              <button
                                type="button"
                                onClick={handleSubmitCreate}
                              >
                                {updatedShipping ? "Editar" : "Avançar"}
                              </button>
                            </div>
                          </div>
                        )}
                      </div>
                    </>
                  ) : null}
                </div>
              )}

              <div className={styles.buttonsContainer}>
                <div className={styles.buttonContainer}>
                  {newMeasure ? (
                    <div
                      className={styles.checkSaveMeasurements}
                      onClick={() => setSaveMeasurements(!saveMeasurements)}
                    >
                      {!saveMeasurements ? <div /> : <FiCheck />}
                      <p>Quero salvar este formato para usar no futuro</p>
                    </div>
                  ) : null}
                  {formState === "Calculate" && (
                    <button
                      className={`${
                        buttonSubmitIsAble === false ? `${styles.disabled}` : ""
                      }`}
                      type="button"
                      onClick={handleSubmitCalculate}
                    >
                      Calcular Frete
                      <PiCaretRightBold />
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>

        <div
          className={`${styles.packagesCardsContainer} ${
            openInfoMobile ? styles.openInfo : ""
          }`}
          onClick={e => {
            if (!openInfoMobile) return;
            setOpenInfoMobile(false);
          }}
        >
          <div className={styles.wrapperPackages}>
            <div className={styles.packagesCardsContent}>
              <div className={styles.close}>
                <img
                  alt="Fechar"
                  src={closeCircle}
                  onClick={() => setOpenInfoMobile(false)}
                />
              </div>

              {/*  Desktop*/}
              <div className={styles.measuresCardsContainer}>
                {type.value === "caixa" && (
                  <div
                    className={`${styles.packageContainer} ${formState2 ===
                      "Create" && styles.packageCaixa} ${formState2 ===
                      "Calculate" &&
                      formState === "Create" &&
                      styles.packageCaixaStandardPosition}`}
                  >
                    <div className={styles.txts}>
                      <h3>Caixa</h3>
                      <p>
                        <span>Comprimento (C):</span> de 15 cm até 100 cm
                      </p>
                      <p>
                        <span>Largura (L):</span> de 10 cm até 100 cm
                      </p>
                      <p>
                        <span>Altura (A):</span> de 1 cm até 100 cm
                      </p>
                      <p>
                        <span>Peso:</span> máximo 30 kg
                      </p>
                      <p>
                        <span>Soma das dimensões:</span> de 26 cm até 200 cm
                      </p>
                    </div>
                    <div className={styles.image}>
                      <img alt="Caixa" src={caixa} />
                    </div>
                  </div>
                )}
                {type.value === "envelope" && (
                  <div
                    className={`${styles.packageContainer} ${formState2 ===
                      "Create" && styles.packageEnvelope} ${formState2 ===
                      "Calculate" &&
                      formState === "Create" &&
                      styles.packageEnvelopeStandardPosition}`}
                  >
                    <div className={styles.txts}>
                      <h3>Envelope</h3>
                      <p>
                        <span>Comprimento (C):</span> de 16 cm até 60 cm
                      </p>
                      <p>
                        <span>Largura (L):</span> de 11 cm até 60 cm
                      </p>
                      <p>
                        <span>Peso:</span> máximo 30 kg
                      </p>
                    </div>
                    <div className={styles.image}>
                      <img alt="Envelope" src={envelope} />
                    </div>
                  </div>
                )}
                {type.value === "cilindro" && (
                  <div
                    className={`${styles.packageContainer} ${formState2 ===
                      "Create" && styles.packageCilindro} ${formState2 ===
                      "Calculate" &&
                      formState === "Create" &&
                      styles.packageCilindroStandardPosition}`}
                  >
                    <div className={styles.txts}>
                      <h3>Cilindro</h3>
                      <p>
                        <span>Comprimento (C):</span> de 18 cm até 100 cm
                      </p>
                      <p>
                        <span>Diâmetro (D):</span> de 5 cm até 91 cm
                      </p>
                      <p>
                        <span>Peso:</span> máximo 30 kg
                      </p>
                      <p>
                        <span>Soma das dimensões:</span> de 26 cm até 200 cm
                      </p>
                    </div>
                    <div className={styles.image}>
                      <img alt="Cilindro" src={cilindro} />
                    </div>
                  </div>
                )}
              </div>
              {sendOpts &&
                formState2 === "Create" &&
                (sendOpts?.collect?.length > 0 ||
                  sendOpts?.retail?.length > 0) && (
                  <div
                    className={`${styles.insuranceAndShippingOptContainer} ${
                      styles.desktop
                    } ${formState2 === "Calculate" &&
                      formState === "Create" &&
                      styles.insuranceAndShippingOptContainerNone}`}
                  >
                    {(sendOpts?.retail[0]?.msgErro ||
                      sendOpts?.collect[0]?.msgErro) && (
                      <div
                        className="alert alert-danger mb-4 d-flex d-flex align-items-center"
                        role="alert"
                      >
                        <p className="mt-1 mb-1">
                          {sendOpts.retail[0].msgErro ||
                            sendOpts.collect[0].msgErro}
                        </p>
                      </div>
                    )}

                    {isAdmin &&
                    !isReverseLogistics &&
                    sendOpts.retail.length &&
                    sendOpts.collect.length ? (
                      <>
                        <div className={styles.insuranceAndShippingOptTitle}>
                          <h3>Tipo de serviço</h3>
                        </div>

                        <div className={styles.insuranceAndShippingOptTxt}>
                          <p>Selecione uma das opções de postagem:</p>
                        </div>
                        {mapOptionsCheck(optionsCheck)}
                      </>
                    ) : null}

                    <>
                      <div className={styles.insuranceAndShippingOptTitle}>
                        <h3>Opções de envio</h3>
                      </div>

                      <div className={styles.insuranceAndShippingOptTxt}>
                        <p>
                          Selecione uma das opções de envio disponíveis para o
                          CEP informado:
                        </p>
                      </div>

                      {selectedDeliveryType === RateTypeShippingEnum.COLLECT
                        ? mapOptionsPrices(sendOpts.collect)
                        : mapOptionsPrices(sendOpts.retail)}
                    </>

                    {!!sendOpt && hasInsurance ? (
                      <>
                        <div className={styles.insuranceAndShippingOptTitle}>
                          <h3>Seguro</h3>
                        </div>
                        <div className={styles.insuranceAndShippingOptTxt}>
                          <p>
                            Por um valor simbólico, você pode proteger seu
                            pacote e ser indenizado tanto em caso de atraso na
                            entrega quanto extravio. <span>Saiba mais</span>
                          </p>
                        </div>
                      </>
                    ) : null}

                    {!!sendOpt &&
                    hasInsurance &&
                    sendOpt.type === DeliveryTypeEnum.PACMINI &&
                    (listaDc.reduce((a, b) => {
                      const total =
                        Number(
                          String(b)
                            ?.valorUnitario?.replace(/[.]/g, "")
                            .replace(/[,]/g, ".")
                        ) * Number(String(b)?.quantidade?.replace(/,/g, "."));

                      return total + a;
                    }, 0) >= MaxInvoiceValueEnum.PACMINI ||
                      Number(
                        valorDaNf?.replace(/[.]/g, "").replace(/[,]/g, ".")
                      ) >= MaxInvoiceValueEnum.PACMINI) ? (
                      <p
                        style={{
                          color: "red",
                          fontSize: 12
                        }}
                      >
                        Infelizmente, a opção "Mini Envios" não é assegurada
                        para valores superiores a <strong>R${MaxInvoiceValueEnum.PACMINI.toFixed(2).replace(".", ",")}</strong>.
                      </p>
                    ) : !!sendOpt && hasInsurance ? (
                      <div
                        className={styles.checkSeguro}
                        onClick={() => setInsurance(!insurance)}
                      >
                        {!insurance ? <div /> : <FiCheck />}
                        <p>
                          Desejo proteger meu pacote por apenas &nbsp;
                          <span>
                            {new Intl.NumberFormat("pt-BR", {
                              style: "currency",
                              currency: "BRL"
                            }).format(
                              !!sendOpt ? sendOpt.valorValorDeclarado : 0
                            )}
                          </span>
                        </p>
                      </div>
                    ) : null}

                    {!!sendOpt ? (
                      <>
                        <div className={styles.insuranceAndShippingOptTitle}>
                          <h3>Resumo</h3>
                        </div>
                        <div className={styles.resume}>
                          {!!sendOpt && (
                            <p className={styles.description}>
                              {sendOpt.nome} -{" "}
                              <span>
                                {new Intl.NumberFormat("pt-BR", {
                                  style: "currency",
                                  currency: "BRL"
                                }).format(sendOpt.valorComDesconto)}
                              </span>
                            </p>
                          )}
                          {!!insurance && !!sendOpt && hasInsurance ? (
                            <p className={styles.description}>
                              Seguro -{" "}
                              <span>
                                {new Intl.NumberFormat("pt-BR", {
                                  style: "currency",
                                  currency: "BRL"
                                }).format(sendOpt.valorValorDeclarado)}
                              </span>
                            </p>
                          ) : null}
                          <p className={styles.totalValue}>
                            Total -{" "}
                            <span>
                              {new Intl.NumberFormat("pt-BR", {
                                style: "currency",
                                currency: "BRL"
                              }).format(
                                !!sendOpt
                                  ? Number(
                                      insurance
                                        ? sendOpt.valorComDesconto +
                                            sendOpt.valorValorDeclarado
                                        : sendOpt.valorComDesconto
                                    )
                                  : 0
                              )}
                            </span>
                          </p>
                        </div>
                      </>
                    ) : null}
                    {sendOpt != null && (
                      <div className={styles.buttonsContainer}>
                        <div className={styles.buttonContainer}>
                          <button type="button" onClick={handleSubmitCreate}>
                            {updatedShipping ? "Editar" : "Avançar"}
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                )}
            </div>
          </div>
        </div>
      </div>
      {openFileModal && (
        <Modal
          className={styles.fileModal}
          show={openFileModal}
          onHide={() => {
            setListaDc([dcLinha]);
            setNFNumber("");
            setValorDaNf("");
            setValorDaNfOK("vazio");
            setNFNumberOK("vazio");
            setValidationListDc(false);
            setOpenFileModal(false);
          }}
          centered
          size="lg"
        >
          <Modal.Header className={styles.headerModal}>
            <div
              className={`${styles.nfHeader} ${tabNf ? styles.ativo : ""}`}
              onClick={() => {
                setListaDc([dcLinha]);
                setTabNf(true);
              }}
            >
              <h2>Nota Fiscal</h2>
            </div>
            <div
              className={`${styles.dcHeader} ${tabNf ? "" : styles.ativo}`}
              onClick={() => {
                setNFNumber("");
                setValorDaNf("");
                setValorDaNfOK("vazio");
                setNFNumberOK("vazio");
                setTabNf(false);
              }}
            >
              <h2>Declaração de Conteúdo</h2>
            </div>
          </Modal.Header>
          <Modal.Body className={styles.bodyModal}>
            {tabNf && (
              <>
                <div
                  className={`${styles.bodyContentNF} ${styles.bodyContent}`}
                >
                  <div className={styles.inputContainerModal}>
                    <div className={styles.input} role={nFNumberOK}>
                      <input
                        type="text"
                        name="numeroNF"
                        placeholder="Número da nota fiscal"
                        onChange={handleInputNFNumber}
                        value={nFNumber}
                      />
                      <AiOutlineCheck
                        className={styles.check}
                        role={nFNumberOK}
                      />
                      <BsExclamation
                        className={styles.danger}
                        role={nFNumberOK}
                      />
                    </div>
                    <p className={styles.error}>{nFNumberError}</p>
                  </div>

                  <div className={styles.inputContainerModal}>
                    <div className={styles.input} role={valorDaNfOK}>
                      <input
                        type="text"
                        name="numeroNF"
                        placeholder="Valor"
                        onChange={e =>
                          handleInputValorDaNf(e.currentTarget.value)
                        }
                        value={valorDaNf}
                      />
                      <AiOutlineCheck
                        className={styles.check}
                        role={valorDaNfOK}
                      />
                      <BsExclamation
                        className={styles.danger}
                        role={valorDaNfOK}
                      />
                    </div>
                    <p className={styles.error}>{valorDaNfError}</p>
                  </div>

                  {/* <div className={styles.file}>
                  <label htmlFor="fileInput">ANEXAR XML</label>
                  <input
                    style={{ display: "none" }}
                    disabled
                    type="file"
                    id="fileInput"
                    name="file"
                    onChange={e => setFile(e.target.value)}
                    value={file}
                  />
                </div> */}
                </div>
              </>
            )}
            {!tabNf && (
              <>
                <div className="py-1">
                  <p className={styles.error} style={{ position: "initial" }}>
                    {errorInDCMessage}
                  </p>
                </div>
                <div className={styles.tabContentDeclaration}>
                  {listaDc?.length > 0 &&
                    listaDc.map(list => (
                      <div key={list.id}>
                        <DeclaracaoConteudo
                          id={list.id}
                          handleLineChange={handleChangeListDC}
                          data={list}
                          handleRemoveItemListDC={handleRemoveItemListDC}
                          validationParam={validationListDc}
                        />
                      </div>
                    ))}
                </div>

                <div className={styles.modalActionButtons}>
                  <div
                    onClick={() =>
                      setListaDc(prevProps => [
                        ...prevProps,
                        { ...dcLinha, id: Math.random() }
                      ])
                    }
                    className={styles.wrapperAddDc}
                  >
                    <MdAddCircle />
                    <span>Adicionar mais um produto</span>
                  </div>
                  <div
                    className={`${styles.buttonContainer} ${styles.buttonContainerMobile} ${styles.btnModalWrapper}`}
                  >
                    <button
                      type="submit"
                      onClick={handleSubmitDeclarationContent}
                    >
                      Salvar
                    </button>
                  </div>
                </div>
              </>
            )}
            {tabNf && (
              <div>
                <div
                  className={`${styles.buttonContainer} ${styles.buttonContainerMobile} ${styles.btnModalWrapper}`}
                >
                  <button type="button" onClick={handleSubmitNF}>
                    Salvar
                  </button>
                </div>
              </div>
            )}
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

export { PackageForm };

