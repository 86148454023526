import React, { useEffect, useState } from "react";
import Select from 'react-select';
import ErrorModal from "../../../../components/ErrorModal";
import GreenClockModal from "../../../../components/GreenClockModal";
import { Loading } from "../../../../components/Loading";
import SuccessModal from "../../../../components/SuccessModal";
import PaymentMethodEnum from "../../../../enums/PaymentMethodEnum";
import store from "../../../../store/store";
import CreditCardDeposit from "../../components/CreditCardDeposit";
import { methodOptions, selectStyle, valueOptions } from "./select";
import styles from "./styles.module.scss";
import * as authDuck from "../../../../store/ducks/auth.duck";
import { BrComerceApiService } from "../../../../services/BrComerceApiService";
import Swal from "sweetalert2";
import { WalletDepositPix } from "../../components/WalletDepositPix";
import depositImage from "../../../../assets/images/wallet-deposit-image.svg"
import { useHistory } from "react-router-dom";


const api = new BrComerceApiService();

export const WalletDeposit = ({handleCancel}) => {


  const { auth: { user } } = store.getState();
  const [loading, setLoading] = useState(false);

  const [greenClockModal, setGreenClockModal] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [paymentError, setPaymentError] = useState("");
  const [invoiceId, setInvoiceId] = useState(0);
  const [invoiceStatus, setInvoiceStatus] = useState(0);
  const [pixGenerated, setPixGenerated] = useState(false);
  const [showGenerated, setShowGenerated] = useState(false);

  const [paymentMethod, setPaymentMethod] = useState(
    {
      value: PaymentMethodEnum.PIX,
      label: "PIX"
    });
  const [depositValue, setDepositValue] = useState(
    {
      value: 10,
      label: "R$10,00"
    }
  );

  const history = useHistory();

  const isCreditCard = paymentMethod.value === PaymentMethodEnum.CREDIT_CARD;

  const handleOnChangeValue = (e) => {
    setDepositValue(e);

    if (pixGenerated) {
      setInvoiceId(0);
      setInvoiceStatus(0);
      setPixGenerated(false);
    }
  };

  const handleOnChangeMethod = (e) => {
    setPaymentMethod(e);
  };

  const handlePaymentButton = async () => {
    try {
      setLoading(true)
      const payment = await api.makeHttpRequest({
        method: 'POST',
        url: 'walletCredit',
        data: {
          depositValue: depositValue.value,
          paymentMethod: paymentMethod.value
        }
      });

      if (paymentMethod.value === PaymentMethodEnum.PIX) {
        setInvoiceId(Number(payment.invoiceId));
        setInvoiceStatus(6);
        setPixGenerated(true);
        setShowGenerated(true);
        return;
      }

      const updatedUser = await api.makeHttpRequest({
        method: 'GET',
        url: '/users/me'
      });

      store.dispatch(authDuck.actions.fulfillUser(updatedUser));

      setSuccessModal(true)

    } catch (err) {
      Swal.fire({
        icon: "error",
        title: "Erro no pagamento",
        text: "Revise os dados e tente novamente."
      })
    } finally {
      setLoading(false)
    }
  }


  useEffect(() => {
    if (pixGenerated && invoiceId > 0) {
      const interval = setInterval(async () => {
        if (invoiceStatus === 6) {
          const invoice = await api.makeHttpRequest({
            method: "GET",
            url: `invoices/${invoiceId}`
          });

          console.log("Checking pix");

          setInvoiceStatus(invoice?.idInvoicesStatus);

          const updatedUser = await api.makeHttpRequest({
            method: 'GET',
            url: '/users/me'
          });

          store.dispatch(authDuck.actions.fulfillUser(updatedUser));

          if (invoice?.idInvoicesStatus === 1) {
            (async () => {
              await clearInterval(interval);
            })();

          }
        }
      }, 2000);
    }

    if (invoiceStatus === 1) {
      Swal.fire({
        icon: "success",
        title: "Sucesso!",
        text: "O pagamento PIX foi confirmado",
        onClose: () => {
          const pathname = window?.location?.pathname?.toLocaleLowerCase() || "";

          if (pathname.includes("dashboard")) {
            window.location.reload();
          }
        }
      });
    }
  }, [invoiceStatus, invoiceId, paymentMethod, pixGenerated]);

  return (
    <div className={styles.content}>
      <Loading loading={loading} />
      {greenClockModal && <GreenClockModal />}
      {successModal && (
        <SuccessModal
          isWalletDeposit={true}
          paymentMethod={paymentMethod}
          handleCancel={handleCancel}
        />
      )}
      {errorModal && (
        <ErrorModal
          setErrorModal={setErrorModal}
          paymentError={paymentError}
        />
      )}
      <div className={styles.title}>
        Fazer depósito na conta
      </div>
      <div className={styles.wrapper}>
        <img src={depositImage} className={styles.image} />
        <div className={styles.container}>
          <div className={styles.input}>
            <Select
              onChange={handleOnChangeMethod}
              options={methodOptions}
              value={paymentMethod}
              styles={selectStyle}
            />
          </div>
          <br />
          <div className={styles.input}>
            <Select
              onChange={handleOnChangeValue}
              options={valueOptions}
              value={depositValue}
              styles={selectStyle}
            />
          </div>
          <br />
          {isCreditCard && (
            <CreditCardDeposit
              depositValue={depositValue.value}
              setLoading={setLoading}
              greenClockModal={greenClockModal}
              setGreenClockModal={setGreenClockModal}
              successModal={successModal}
              setSuccessModal={setSuccessModal}
              errorModal={errorModal}
              setErrorModal={setErrorModal}
              paymentError={paymentError}
              setPaymentError={setPaymentError}
              handleCancel={handleCancel}
            />
          )}
          {
            !isCreditCard && (
              <div className={styles.btnContainer}>
                {!pixGenerated
                  ? (<button type="button" className={styles.btnOk} onClick={handlePaymentButton} disabled={pixGenerated}>
                    <span>{`Gerar QrCode no valor de ${depositValue.label}`}</span>
                  </button>
                  )
                  : <button type="button" className={styles.btnOk} onClick={() => setShowGenerated(true)}>
                    <span>Ver QRCode</span>
                  </button>
                }
                <br />
                <button type="button" className={styles.btnCancel} onClick={handleCancel}>
                  <span>Cancelar</span>
                </button>
              </div>
            )
          }
        </div>
      </div>
      {!isCreditCard && pixGenerated && showGenerated && (
        <WalletDepositPix
          invoiceId={invoiceId}
          setShowGenerated={setShowGenerated}
        />
      )}
    </div>
  );
};
