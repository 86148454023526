import React, { useMemo } from "react";
import logoTag from "../../assets/logo/logoTag.png";
import sedex from "../../assets/logo/sedex.png";
import sedexHoje from "../../assets/logo/sedex-hoje.svg";
import pac from "../../assets/logo/pac.png";
import correios from "../../assets/logo/correios.png";
import miniEnvios from "../../assets/logo/mini-envios.jpg";

import styles from "./styles.module.scss";
import { OriginEnum } from "../../enums/OriginEnum";
import DeliveryTypeEnum from "../../enums/DeliveryTypeEnum";

const Tag = ({
  contratoId,
  origin,
  destination,
  tagCode,
  vd,
  shipping,
  destinationCepBarcode,
  tagBarCode,
  dataMatrix,
  senderDefaultName,
  invoiceNumber
}) => {

  const integrationToDisplayName = useMemo(() => {
    return [
      OriginEnum.VNDA,
      OriginEnum.BLINGV3
    ]
  }, []);

  return (
    <div className={styles.etiqueta}>
      <div className={`${styles.head}`}>
        <div>
          <img src={logoTag} id="img-logo" alt="logo empresa" />
        </div>
        <div>
          <img
            src={`data:image/jpeg;base64,${dataMatrix}`}
            id="img-datamatrix"
            alt="datamatrix"
          />
        </div>
        <div>
          <img
            src={
              shipping?.type?.toLowerCase() === "sedex"
                ? sedex
                : shipping?.type?.toLowerCase() === "pac"
                  ? pac
                  : shipping?.type?.toLowerCase() === "sedex_hoje"
                    ? sedexHoje
                    : miniEnvios
            }
            id="img-chancela"
            alt="img-chancela"
          />
        </div>
      </div>
      <div className={styles.headInfo}>
        <div className={styles["label-nf"]}>
          <span>{invoiceNumber && `NF: ${invoiceNumber}`}</span>
          <span>
            {shipping?.orderNumber && `Pedido: ${integrationToDisplayName.includes(shipping?.originCreation) ? shipping?.name : shipping?.orderNumber || shipping?.orderNumber}`}
          </span>
        </div>
        <div className={styles["label-contrato"]}>
          <span>
            Contrato: <strong>{contratoId ? contratoId : ""}</strong>
          </span>
          <span>
            <strong>{shipping.type === DeliveryTypeEnum.SEDEX_HOJE ? "SEDEX HOJE" : shipping.type}</strong>
          </span>
        </div>
        <div className={styles["label-peso"]}>
          <span>Volume: 1/1</span>
          <span>
            Peso (g): <strong>{shipping.weight * 1000}</strong>
          </span>
        </div>
      </div>
      <div className={styles.tracking}>
        <div className={styles.rastreio}>
          <span>
            {tagCode?.slice(0, 2)} {tagCode?.slice(2, 5)} {tagCode?.slice(5, 8)}
            {tagCode?.slice(8, 11)} {tagCode?.slice(11, 13)}
          </span>
          <div>
            <div>
              <img
                src={`data:image/jpeg;base64,${tagBarCode}`}
                alt="CODIGO DE BARRAS SL999999999BR"
              />
            </div>
            <span>{vd}</span>
          </div>
        </div>
      </div>
      <div className={styles.recebedor}>
        <p>
          Recebedor: <span></span>
        </p>
        <div>
          <p>
            Assinatura: <span></span>
          </p>
          <p>
            Documento: <span></span>
          </p>
        </div>
      </div>
      <div className={styles.destinatario}>
        <div className={styles.destinatarioHead}>
          <span>&nbsp;DESTINATÁRIO&nbsp;</span>
          <img src={correios} alt="logo correios" />
        </div>
        <div className={styles.destinatarioBody}>
          <div className={`${styles["dados-destinatario"]}`}>
            <span>{destination?.name}</span>
            <span>
              {destination?.street}, {destination?.number} -
              {destination?.complement}
            </span>
            <span>{destination?.district}</span>
            <span className={styles["label-cep"]}>
              <strong>{destination?.zip}</strong> {destination?.city} -{" "}
              {destination?.state}
            </span>
          </div>
          <div className={styles.cepContainer}>
            <div className={styles.cepContainerImg}>
              <img
                src={`data:image/jpeg;base64,${destinationCepBarcode}`}
                alt="cep"
              />
            </div>
            <div className={styles.obs}>Obs: {destination?.observation}</div>
          </div>
        </div>
      </div>

      <div className={styles.destinatarioHead}>
        <span>&nbsp;REMETENTE&nbsp;</span>
      </div>
      <div className={styles.remetente}>
        <span>
          {senderDefaultName ? senderDefaultName : origin?.name}
        </span>
        <span>
          {origin?.street}, {origin?.number} - {origin?.complement}
        </span>
        <span>{origin?.district}</span>
        <span>
          {origin?.zip} {origin?.city} - {origin?.state}
        </span>
      </div>
    </div>
  );
};

export { Tag };
