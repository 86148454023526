// Função debounce. Utilizada para atrasar chamadas seguidas de uma função.
// Caso seja necessário enviar parâmetros para função externa utilize:
// debounce(foo, 1000)(Param1, Param2, Param3)

export default function debounce(callback, delay = 1000) {
  let timer;
  return (...args) => {
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      callback(...args);
      timer = null;
    }, delay);
  };
}
