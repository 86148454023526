import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { MdClear } from "react-icons/md";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { ReactComponent as Package } from "../../../../../assets/images/route-detail/package.svg";
import { Loading } from "../../../../../components/Loading";
import ShippingStagesEnum from "../../../../../enums/ShippingStagesEnum";
import { BrComerceApiService } from "../../../../../services/BrComerceApiService";
import store from "../../../../../store/store";
import debounce from "../../../../../utils/debounce";
import useGetWindowWidth from "../../../../../utils/useGetWindowWidth";
import { CartCardShipping } from "../../../components/CartCardShipping";
import Container from "../../../components/Container";

import { FiChevronLeft, FiChevronRight, FiSearch } from "react-icons/fi";
import { GoogleMapsDashboard } from "../../../../../components/GoogleMapsDashboard";
import pinTruck from "../../../../../assets/icons/pin-truck.svg";
import styles from "./styles.module.scss";

const RouteDetail = () => {
  window.setPageTitle("Detalhes da Rota");

  const api = new BrComerceApiService();
  const params = useParams();
  const { auth } = store.getState();
  const { user } = auth;
  const widthWindows = useGetWindowWidth();
  const [loading, setLoading] = useState(false);
  const [route, setRoute] = useState([]);
  const [shippings, setShippings] = useState([]);
  const [shippingsExpand, setShippingsExpand] = useState([]);
  const [search, setSearch] = useState("");
  const [amountRouteShippings, setAmountRouteShippings] = useState(0);

  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const [locations, setLocations] = useState([]);

  const coordinatesRJ = {
    lat: -22.9035,
    lng: -43.2096
  };


  const handleClearSearchFilter = () => {
    setSearch("");
  };

  const handleFetchData = useCallback(async () => {
    setLoading(true);
    try {
      const response = await api.makeHttpRequest({
        method: "GET",
        url: `route-admin/${params.id}`,
        params: {
          page: 1,
          resultsPerPage: 7
        }
      });
      setRoute(response.route);
      setShippings(response?.routeShippings?.results);
      setPage(response?.routeShippings?.actualPage);
      setTotalPages(
        response?.routeShippings?.nPage === 0
          ? 1
          : response?.routeShippings?.nPage
      );
      setAmountRouteShippings(response?.routeShippings?.amountRouteShippings)
      handleLocations(response?.listLocation);
    } catch (e) {
      console.log(e);
      Swal.fire({
        icon: "error",
        title: "Erro",
        text: "Houve um erro ao carregar a página, tente novamente mais tarde."
      });
    } finally {
      setLoading(false);
    }
  }, [api, params.id]);

  const loadShippingsByRoute = useCallback(
    debounce(async (search, page) => {
      try {
        setLoading(true);
        const response = await api.makeHttpRequest({
          method: "GET",
          url: `route-admin/${params.id}`,
          params: {
            page: search ? 1 : page,
            resultsPerPage: 7,
            ...(search && { text: search })
          }
        });

        setShippings(response?.routeShippings?.results);
        setPage(response?.routeShippings?.actualPage);
        setTotalPages(
          response?.routeShippings?.nPage === 0
            ? 1
            : response?.routeShippings?.nPage
        );
        setAmountRouteShippings(response?.routeShippings?.amountRouteShippings);
        handleLocations(response?.listLocation);
      } catch (e) {
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: "Erro",
          text:
            "Houve um erro ao carregar a página, tente novamente mais tarde."
        });
        console.error("Houve um erro ao carregar a página", JSON.stringify(e));
      } finally {
        setLoading(false);
      }
    }, 1800),
    [search, page]
  );

  const onChangePagination = type => {
    setLoading(true);

    if (type === "next") {
      setPage(page + 1);
    } else {
      setPage(page - 1);
    }
  };

  const handleShippingExpand = useCallback(
    pack => {
      const find = shippingsExpand.find(p => p.id === pack.id);
      if (find) {
        const newPacks = shippingsExpand.filter(p => p.id !== pack.id);
        setShippingsExpand([...newPacks]);
      } else {
        shippingsExpand.push(pack);
        setShippingsExpand([...shippingsExpand]);
      }
    },
    [shippingsExpand]
  );

  const handleStatusColor = value => {
    const status = {
      "A Fazer": "#C1C1C1",
      "Em Andamento": "#F5CB2B",
      Concluída: "#409e6a"
    };

    return status[value];
  };

  const handleLocations = value => {
    const handledGeolocations = [];
    if (value?.length) {
      value.forEach(item => {
        handledGeolocations.push({
          lat: Number(item?.latitude),
          lng: Number(item?.longitude),
        });
      });
    }
    setLocations(handledGeolocations);
  };

  useEffect(() => {
    (async () => {
      await loadShippingsByRoute(search, page);
    })();
  }, [search, page, loadShippingsByRoute]);

  useEffect(() => {
    (async () => {
      await handleFetchData();
    })();
  }, []);

  const displayMap = !loading && !!locations?.length;

  return (
    <>
      <Loading loading={loading} />
      <Container title={`Rota n° ${route?.id ?? "--"}`}>
        <div className={`${styles.inputContainer}`}>
          <div className={styles.input}>
            <input
              type="text"
              name="search"
              placeholder="Busque pelo código da etiqueta, nome do remetente ou destinatário"
              onChange={e => setSearch(e.target.value)}
              value={search}
            />
            {!search.length && <FiSearch size={20} color="#409e6a" />}
            {!!search?.length && (
              <MdClear
                style={{ cursor: "pointer" }}
                onClick={handleClearSearchFilter}
              />
            )}
          </div>
        </div>
        {window.screen.width > 480 ?
          <Row style={{ margin: "32px 0", width: "100%" }} className={styles.cardInfo}>
            <Col xs={6} className="pl-0">
              <div className={`${styles.card} ${styles.cardTotal}`}>
                <div className={styles.information}>
                  <h3>Total de objetos ativos</h3>
                  <h1>{shippings.length} {shippings.length > 1 ? "envios" : "envio"}</h1>
                </div>
                <div className={styles.informationImage}>
                  <Package />
                </div>
              </div>
            </Col>
            <Col xs={6} className="pr-0">
              <div className={`${styles.card} ${styles.cardRoute}`}>
                <div className={styles.cardHeader}>
                  <h4>SOBRE A ROTA</h4>
                </div>
                <div className={styles.cardBody}>
                  <div>
                    <span>
                      Criada em{" "}
                      <label>
                        {moment(route?.created_at).format("DD/MM/YYYY")}
                      </label>
                    </span>
                  </div>
                  <div>
                    <span>
                      Contém <label>{amountRouteShippings} envios</label>
                    </span>
                  </div>
                  <div>
                    <span>
                      Status:{" "}
                      <label
                        style={{ color: handleStatusColor(route?.status?.name) }}
                      >
                        {route?.status?.name || "--"}
                      </label>
                    </span>
                  </div>
                  <div>
                    <span>
                      Motorista: <label>{route?.driver?.name || "--"}</label>
                    </span>
                  </div>
                  <div>
                    <span>
                      Início: <label>{
                        route?.started_at ?
                          moment(route?.started_at).format("DD/MM/YYYY - HH:mm")
                          : "--"
                      }</label>
                    </span>
                  </div>
                  <div>
                    <span>
                      Fim: <label>{
                        route?.finalized_at ?
                          moment(route?.finalized_at).format("DD/MM/YYYY - HH:mm")
                          : "--"
                      }</label>
                    </span>
                  </div>
                  <div>
                    <span>
                      Kms Percorrido: <label>{route?.kms_traccar ? `~ ${route?.kms_traccar?.toFixed(2)} km` : "--" }</label>
                    </span>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          :
          <Row style={{
            display: "flex",
            alignSelf: "center",
            margin: "32px 0",
            width: "90%",
            gap: "12px"
          }} className={styles.cardInfo}>
            <Row xs={1} className="pl-0">
              <div className={`${styles.card} ${styles.cardTotal}`}>
                <div className={styles.information}>
                  <h3>Total de objetos ativos</h3>
                  <h1>{shippings.length} {shippings.length > 1 ? "envios" : "envio"}</h1>
                </div>
                <div className={styles.informationImage}>
                  <Package />
                </div>
              </div>
            </Row>
            <Row xs={1} className="pr-0">
              <div className={`${styles.card} ${styles.cardRoute}`}>
                <div className={styles.cardHeader}>
                  <h4>SOBRE A ROTA</h4>
                </div>
                <div className={styles.cardBody}>
                  <div>
                    <span>
                      Criada em{" "}
                      <label>
                        {moment(route?.created_at).format("DD/MM/YYYY")}
                      </label>
                    </span>
                  </div>
                  <div>
                    <span>
                      Contém <label>{amountRouteShippings} envios</label>
                    </span>
                  </div>
                  <div>
                    <span>
                      Status:{" "}
                      <label
                        style={{ color: handleStatusColor(route?.status?.name) }}
                      >
                        {route?.status?.name || "--"}
                      </label>
                    </span>
                  </div>
                  <div>
                    <span>
                      Motorista: <label>{route?.driver?.name || "--"}</label>
                    </span>
                  </div>
                  <div>
                    <span>
                      Início: <label>{
                        route?.started_at ?
                          moment(route?.started_at).format("DD/MM/YYYY - HH:mm")
                          : "--"
                      }</label>
                    </span>
                  </div>
                  <div>
                    <span>
                      Fim: <label>{
                        route?.finalized_at ?
                          moment(route?.finalized_at).format("DD/MM/YYYY - HH:mm")
                          : "--"
                      }</label>
                    </span>
                  </div>
                  <div>
                    <span>
                      Kms Percorrido: <label>{route?.kms_traccar ? `~ ${route?.kms_traccar?.toFixed(2)} km` : "--" }</label>
                    </span>
                  </div>
                </div>
              </div>
            </Row>
          </Row>
        }
        {displayMap &&
          <Row style={{ margin: "32px 0", width: "100%" }}>
            <Col className={styles.mapWrapper} xs={12}>
              <GoogleMapsDashboard
                zoom={15}
                centralizeCoordinates={coordinatesRJ}
                locations={locations}
                iconMarker={pinTruck}
                hasDetailsRoute
              />
            </Col>
          </Row>
        }
        <div className={styles.collectWrapper}>
          {!!shippings?.length ? (
            shippings?.map(pack => {
              return (
                <CartCardShipping
                  widthWindows={widthWindows}
                  key={pack?.shipping?.id}
                  shipping={pack?.shipping}
                  isSelected={false}
                  isCancelled={
                    pack?.shipping?.idShippingStatus ===
                    ShippingStagesEnum.OBJETO_CANCELADO.id
                  }
                  isExpand={shippingsExpand.find(
                    shipExpand => pack.id === shipExpand.id
                  )}
                  onExpand={() => handleShippingExpand(pack)}
                  onClick={() => { }}
                  setLoading={setLoading}
                  removeShipping={() => { }}
                  isAdmin={user.profile === "admin"}
                  invoiceMethod={"null"}
                  collectStage={4}
                  edit={false}
                  linkTracking
                  select={false}
                  delivery={pack?.delivery ?? {}}
                  status={true}
                />
              );
            })
          ) : (
            <div className={styles.notFoundOrEmptyContainer}>
              <p>
                {!shippings?.length && !!search
                  ? "Não foram encontradas envios para essa pesquisa."
                  : "Ainda não há envios para essa Rota."}
              </p>
            </div>
          )}

          <div className={styles.pagination} style={{ marginBottom: 60 }}>
            <h6>
              Página {page} de {totalPages}
            </h6>
            <div>
              {page > 1 && (
                <FiChevronLeft
                  onClick={() => onChangePagination("previous")}
                  className="arrow"
                />
              )}
              {totalPages > 1 && <h6>{page}</h6>}
              {page < totalPages && (
                <FiChevronRight
                  onClick={() => onChangePagination("next")}
                  className="arrow"
                />
              )}
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export { RouteDetail };
