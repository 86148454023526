import React, { useEffect, useState } from "react";
import ShippingStagesEnum from "../../../../../../enums/ShippingStagesEnum";
import styles from "./tableStatusShippings.module.scss";

const TableStatusShippings = ({
  statusTable,
  initialDateToRedirect,
  endDateToRedirect
}) => {
  const [statusTableWithId, setStatusTableWithId] = useState([]);

  useEffect(() => {
    const mappingStatusTableId = [];

    Object.values(ShippingStagesEnum).forEach(value => {
      let matchingItem = statusTable.find(
        item => item.statusName.toLowerCase() === value.message.toLowerCase()
      );

      if (matchingItem) {
        mappingStatusTableId.push({
          statusName: matchingItem.statusName,
          items: matchingItem.items,
          value: value.id
        });
      } else {
        mappingStatusTableId.push({
          statusName: value.message,
          items: 0,
          value: value.id
        });
      }
    });

    mappingStatusTableId.push({
      statusName: "Total",
      items: statusTable[statusTable.length - 1].items
    });

    setStatusTableWithId(mappingStatusTableId);
  }, [initialDateToRedirect, endDateToRedirect, statusTable]);

  const handleRedirect = (value, label) => {
    const [iDay, iMonth, iYear] = initialDateToRedirect.split("/");
    const [fDay, fMonth, fYear] = endDateToRedirect.split("/");

    const initialDateFormated = `${iMonth}/${iDay}/${iYear}`;
    const finalDateFormated = `${fMonth}/${fDay}/${fYear}`;

    window.open(
      `/rastrear-encomenda?value=${value}&label=${label}&initialDate=${initialDateFormated}&finalDate=${finalDateFormated}`,
      "_blank"
    );
  };

  return (
    <table className={styles.tableStatus}>
      <thead>
        <tr>
          <th>Status</th>
          <th>Quantidade</th>
        </tr>
      </thead>
      <tbody>
        {statusTableWithId
          .filter(status => status.statusName !== "Total")
          .map(item => {
            return (
              <tr
                key={item.statusName}
                onClick={() => handleRedirect(item.value, item.statusName)}
                className={styles.hoverSign}
              >
                <td>{item.statusName}</td>
                <td>{item.items}</td>
              </tr>
            );
          })}
      </tbody>
      <tfoot>
        {statusTableWithId
          .filter(status => status.statusName === "Total")
          .map(item => {
            return (
              <tr
                key={item.statusName}
                className={styles.hoverSign}
                onClick={() => handleRedirect("", "Todos")}
              >
                <th>{item.statusName}</th>
                <th>{item.items}</th>
              </tr>
            );
          })}
      </tfoot>
    </table>
  );
};

export { TableStatusShippings };
