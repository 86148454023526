const emailValidation = ({ email, emptyEmail, incorrectEmail }) => {
    if (email === "") {
        return {
            status: "notOk",
            message: emptyEmail
        };
    }
    const test = email.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g);
    if (test) {
        return {
            status: "ok",
            message: ""
        };
    } else {
        return {
            status: "notOk",
            message: incorrectEmail
        };
    }
};

export { emailValidation };