import React from "react";
import { useHistory } from "react-router-dom";
import { BulletPoint, SlideCarousel } from "./components";
import "react-responsive-carousel/lib/styles/carousel.css"; // requires a loader

import optionsIntegrations from "../../../../../assets/images/integration/optionsIntegrations.png";
import activeOrderCreation from "../../../../../assets/images/active-order-create.png";
import manualImportCard from "../../../../../assets/images/manual-import-card.png";


import tray from "../../../../../assets/images/tray/trayCard.png";

import tray1 from "../../../../../assets/images/tray/tray1.png";
import tray2 from "../../../../../assets/images/tray/tray2.png";
import tray3 from "../../../../../assets/images/tray/tray3.png";
import tray4 from "../../../../../assets/images/tray/tray4.png";
import tray5 from "../../../../../assets/images/tray/tray5.png";
import tray6 from "../../../../../assets/images/tray/tray6.png";
import tray7 from "../../../../../assets/images/tray/tray7.png";
import tray8 from "../../../../../assets/images/tray/tray8.png";
import tray9 from "../../../../../assets/images/tray/tray9.png";
import tray15 from "../../../../../assets/images/tray/tray15.png";

import trayManualImport1 from "../../../../../assets/images/tray/tray-manual-import1.png";
import trayManualImport2 from "../../../../../assets/images/tray/tray-manual-import2.png";


import styles from "./styles.module.scss";

const TutorialTray = () => {
  const steps1th = [{ image: optionsIntegrations }, { image: tray }];

  const steps2th = [{ image: tray1 }, { image: tray2 }, { image: tray3 }];

  const steps3th = [
    {
      image: tray5
    },
    {
      image: tray6
    }
  ];

  const steps4th = [
    {
      image: tray7
    },
    {
      image: tray8
    }
  ];

  const steps5th = [
    {
      image: manualImportCard
    },
    {
      image: trayManualImport1
    },
    {
      image: trayManualImport2
    }
  ];

  const history = useHistory();

  return (
    <main className={styles.container}>
      <div className={styles.content}>
        <header className={styles.contentHeader}>
          <p>Como fazer a integração?</p>
        </header>
        <div className={styles.contentBody}>
          <section className={styles.contentSection}>
            <h4>Passo a passo para conectar sua plataforma Tray à BRCom</h4>

            <BulletPoint order="1">
              <p>
                Na tela de <b>Integrações</b>, clique em <b>Selecionar</b> do
                segundo card e depois clique em <b>Configurações</b> no card da
                plataforma Tray
              </p>
            </BulletPoint>

            <SlideCarousel steps={steps1th} />

            <BulletPoint order="2">
              <p>
                Na tela de configurações preencha o campo com o link da sua loja
                e depois clique em <b>Salvar</b>.
              </p>
            </BulletPoint>

            <SlideCarousel steps={steps2th} />

            <BulletPoint order="3">
              <p>
                Após salvar clique em <b>Ir para tray</b> para ser redirecionado
                a página de <b>Configurações Adicionais de Integração</b> da
                Tray, enquanto isso, a estrutura inicial de integração entre
                Brcomerce e Tray <br /> estará sendo finalizada.
              </p>
            </BulletPoint>

            <BulletPoint order="4" style={{ marginTop: 16 }}>
              <p>
                A primeira <b>Configuração Adicional</b> é alternar entre Ativar
                ou Desativar a Integração entre a BRComerce e a Tray. E também o
                nome remetente que irá constar nas etiquetas
              </p>
            </BulletPoint>

            <div className={`${styles.imgs} `}>
              <img src={tray4} alt="switch tray" className={styles.cardImage} />
            </div>

            <BulletPoint order="5">
              <p>
                A segunda <b>Configuração Adicional</b> é selecionar os Status
                para criação dos pedidos na BRComerce. Você Lojista vai nos
                informar a partir de qual ou quais status esses pedidos devem
                ser criados na Brcom
              </p>
            </BulletPoint>

            <SlideCarousel steps={steps3th} />

            <BulletPoint order="6">
              <p>
                A terceira <b>Configuração Adicional</b> é referente ao frete,
                você pode adicionar uma quantidade específica de dias para cada
                tipo de frete: Expresso, Econômico, e Mini Envios.
              </p>
            </BulletPoint>

            <SlideCarousel steps={steps4th} />

            <BulletPoint order="7">
              <p>
                A quarta e última <b>Configuração Adicional</b> é referente a
                opção de Seguro, você pode alternar entre Ativado ou Desativado
                para todas as suas vendas na BRComerce. Não esqueça de clicar em{" "}
                <b>Salvar</b> no final.
              </p>
            </BulletPoint>

            <div className={`${styles.imgs} `}>
              <img src={tray9} alt="switch tray" className={styles.cardImage} />
            </div>

            <BulletPoint order="8">
              <p>
                Você também tem a opção de importar manualmente seus pedidos.
              </p>
            </BulletPoint>

            <SlideCarousel steps={steps5th} />

            <BulletPoint order="9">
              <p>
                Perceba, logo abaixo na foto, que opções de frete agora estão
                disponíveis. Porém nem todas as opções ficam disponíveis assim
                que a Integração é realizada. A Tray tem um processo interno que
                pode levar de 15 minutos até 6h para finalizar. Pronto, agora
                sua loja está 100% integrada com a a Brcomerce, boas vendas!!
              </p>
            </BulletPoint>

            <div
              style={{
                margin: "0 auto"
              }}
            >
              <img
                src={tray15}
                alt="Tray"
                className={styles.cardImage}
                style={{
                  width: "800px",
                  height: "420px",
                  objectFit: "contain"
                }}
              />
            </div>

            <div className={styles.bulletpoint}>
              <span>10</span>
              <p>
                Caso queira desativar a criação de pedido nessa integração, vire a chave para "Desativado"
                <br></br>
                As demais funcionalidades relacionadas a essa integração continuarão ativas.
              </p>
            </div>

            <div className={styles.imgs}>
              <img
                src={activeOrderCreation}
                alt="activeOrderCreation"
                className={styles.cardImage}
              />
            </div>

            <button
              onClick={() => history.push("/integracoes")}
              className={`${styles.button} ${styles.btnBack}`}
            >
              Voltar
            </button>
          </section>
        </div>
      </div>
    </main>
  );
};

export { TutorialTray };
