import { format } from "date-fns";
import { ptBR } from "date-fns/locale";
import React, { useState } from "react";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import { TbFileExport } from "react-icons/tb";
import { IoMdInformation } from "react-icons/io";
import CsvDownload from "react-json-to-csv";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";

import { GoogleMapsDashboard } from "../../../../../../components/GoogleMapsDashboard";
import { Loading } from "../../../../../../components/Loading";
import { useDidMountEffect } from "../../../../../../hooks/useDidMountEffect";
import { BrComerceApiService } from "../../../../../../services/BrComerceApiService";
import * as authDuck from "../../../../../../store/ducks/auth.duck";
import store from "../../../../../../store/store";
import { ChartBySla } from "../../partials/ChartBySla";
import { ClientFilters } from "../../partials/Filters/ClientFilters";
import { TableCustomerShipping } from "../../partials/TableCustomerShipping";
import { TableStatusShippings } from "../../partials/TableStatusShippings";
import styles from "./dashboardClient.module.scss";
import { StyledTooltip } from "../../../../components/Tooltip";

const DashboardClient = () => {
  const api = new BrComerceApiService();

  const { auth } = store.getState();
  const { user } = auth;

  const [isLoading, setIsLoading] = useState(false);
  const [filters, setFilters] = useState(null);

  const [statusTable, setStatusTable] = useState([]);
  const [chartBySLA, setChartBySLA] = useState(null);
  const [chartLocations, setChartLocations] = useState([]);
  const [tableTrackingIndicator, setTableTrackingIndicator] = useState([]);
  const [objectsWithError, setObjectsWithError] = useState(0);

  const [
    tableReverseLogisticIndicator,
    setTableReverseLogisticIndicator
  ] = useState([]);

  const [userWalletBalance, setUserWalletBalance] = useState(0);
  const [userBalance, setUserBalance] = useState(0);

  const handleLoadTableStatus = response => {
    setStatusTable(response);
  };

  const handleLoadCharts = ({
    tableReverseLogistic,
    chartBySLA,
    chartGeolocation,
    tableTracking
  }) => {
    setTableReverseLogisticIndicator(tableReverseLogistic);
    setChartBySLA(chartBySLA);
    setTableTrackingIndicator(tableTracking);

    const handledGeolocations = [];
    if (chartGeolocation.length) {
      chartGeolocation.forEach(item => {
        if (item?.amount > 1) {
          for (let i = 1; i <= item?.amount; i++) {
            handledGeolocations.push({
              lat: Number(item?.latitude),
              lng: Number(item?.longitude)
            });
          }
        } else {
          handledGeolocations.push({
            lat: Number(item?.latitude),
            lng: Number(item?.longitude)
          });
        }
      });
    }

    setChartLocations(handledGeolocations);
  };

  const loadCharts = async allFilters => {
    console.log(
      "🚀 ~ file: index.jsx ~ line 77 ~ loadCharts ~ allFilters",
      allFilters
    );
    const listErrors = [];

    const loadTableStatusPromise = api.makeHttpRequest({
      method: "PUT",
      url: "/dashboard/shippingStatus/",
      data: allFilters
    });

    console.log(
      "🚀 ~ file: index.jsx ~ line 88 ~ loadCharts ~ loadTableStatusPromise",
      loadTableStatusPromise
    );

    const loadCustomerIndicatorsPromise = api.makeHttpRequest({
      method: "PUT",
      url: "/dashboard/customerIndicators/",
      data: allFilters
    });

    console.log(
      "🚀 ~ file: index.jsx ~ line 99 ~ loadCharts ~ loadCustomerIndicatorsPromise",
      loadCustomerIndicatorsPromise
    );

    const [
      loadedTableStatus,
      loadCustomerIndicators
    ] = await Promise.allSettled([
      loadTableStatusPromise,
      loadCustomerIndicatorsPromise
    ]);

    console.log(
      "🚀 ~ file: index.jsx ~ line 129 ~ loadCharts ~ loadedTableStatus",
      loadedTableStatus
    );

    console.log(
      "🚀 ~ file: index.jsx ~ line 108 ~ loadCharts ~ loadCustomerIndicators",
      loadCustomerIndicators
    );

    if (loadCustomerIndicators?.status === "fulfilled") {
      handleLoadCharts(loadCustomerIndicators?.value);
    } else {
      listErrors.push("Erro ao carregar indicadores");
    }

    if (loadedTableStatus.status === "fulfilled") {
      handleLoadTableStatus(loadedTableStatus?.value);
    } else {
      listErrors.push("Erro ao carregar tabela com status das entregas");
    }

    if (!!listErrors?.length) {
      throw listErrors;
    }
  };

  const loadUserBalance = async () => {
    try {
      const result = await api.makeHttpRequest({
        method: "GET",
        url: "/users/me/"
      });

      store.dispatch(authDuck.actions.fulfillUser(result));

      if (result.walletBalance) {
        setUserWalletBalance(Number(result.walletBalance));
      }
      if (result.balance) {
        setUserBalance(Number(result.balance));
      }
    } catch (e) {
      throw new Error(["Ocorreu um erro ao carregar usuários"]);
    }
  };

  // const loadCountObjectsWithError = async () => {
  //   try {
  //     const result = await api.makeHttpRequest({
  //       method: "GET",
  //       url: "/dashboard/indicator/count-process-error",
  //     })
  //     setObjectsWithError(result)
  //   } catch (error) {
  //     throw new Error(["Ocorreu um erro ao carregar objetos com error"]);
  //   }
  // }

  useDidMountEffect(() => {
    (async () => {
      try {
        setIsLoading(true);

        // await loadCountObjectsWithError();
        await loadCharts(filters);
        await loadUserBalance();
      } catch (e) {
        console.error(e);
        if (e.length) {
          Swal.fire({
            icon: "error",
            title: "Dashboard",
            text: `Ocorreu um erro ao carregar:\n ${e.join(", ")}`
          });
          return;
        }
        Swal.fire({
          icon: "error",
          title: "Dashboard",
          text: `Ocorreu um erro ao carregar o dashboard`
        });
      } finally {
        setIsLoading(false);
      }
    })();
  }, [filters]);

  return (
    <section className={styles.main}>
      <Loading loading={isLoading} />

      <span>
        {format(new Date(), "EEEE, dd 'de' MMMM 'de' yyyy", {
          locale: ptBR
        })}
      </span>
      <h1>Bem vindo(a){user?.name ? `, ${user.name}` : "."}</h1>
      <div className={styles.walletBalance}>
        {userBalance > 0 ? (
          <StyledTooltip
            text="Seu crédito pré-aprovado junto à BRCom para contratar fretes de forma faturada. Modalidade voltada para clientes
            com grande volume e recorrência de uso"
            position="bottom"
            arrow
          >
            <span style={{ cursor: "default" }}>
              Seu crédito faturado:
              <b>
                {userBalance !== null
                  ? `${
                      userBalance < 0
                        ? `-${Intl.NumberFormat("pt-BR", {
                            style: "currency",
                            currency: "BRL"
                          }).format(Number(userBalance * -1))}`
                        : `${Intl.NumberFormat("pt-BR", {
                            style: "currency",
                            currency: "BRL"
                          }).format(Number(userBalance))}`
                    }`
                  : "R$ 0.00"}
              </b>
            </span>
          </StyledTooltip>
        ) : (
          <StyledTooltip
            text="Depositado para contratar fretes de forma avulsa. Voltado para clientes que ainda estão escalando seu volume,
            ou fazem envios esporádicos."
            position="bottom"
            arrow
          >
            <span style={{ cursor: "default" }}>
              Seu saldo:
              <b>
                {userWalletBalance !== null
                  ? `${
                      userWalletBalance < 0
                        ? `-${Intl.NumberFormat("pt-BR", {
                            style: "currency",
                            currency: "BRL"
                          }).format(Number(userWalletBalance * -1))}`
                        : `${Intl.NumberFormat("pt-BR", {
                            style: "currency",
                            currency: "BRL"
                          }).format(Number(userWalletBalance))}`
                    }`
                  : "R$ 0.00"}
              </b>
            </span>
          </StyledTooltip>
        )}

        <Link to={"extrato-conta"}>Ver extrato</Link>
      </div>
      <Container fluid className="p-0">
        {chartBySLA ? (
          <ChartBySla
            data={chartBySLA}
            initialDateToRedirect={filters.initialDate}
            endDateToRedirect={filters.endDate}
            countObjectsWithError={objectsWithError}
          />
        ) : (
          <Row className={`${styles.cardsStatus}`}>
            <Col xs={12} className={styles.anErrorOcurred}>
              {isLoading ? (
                <Spinner animation="border" role="status">
                  <span style={{ visibility: "hidden" }}>Loading...</span>
                </Spinner>
              ) : (
                <p>Ocorreu um erro ao carregar indicadores.</p>
              )}
            </Col>
          </Row>
        )}
      </Container>

      <Container as="section" fluid className={styles.indicatorsCharts}>
        <ClientFilters onFilter={filters => setFilters(filters)} />

        <Row>
          <Col xs={4} style={{ marginTop: 16, marginBottom: 16 }}>
            {!!statusTable.length && statusTable.length > 1 ? (
              <TableStatusShippings
                statusTable={statusTable}
                initialDateToRedirect={filters.initialDate}
                endDateToRedirect={filters.endDate}
              />
            ) : (
              <div className={styles.anErrorOcurred}>
                {isLoading ? (
                  <Spinner animation="border" role="status">
                    <span style={{ visibility: "hidden" }}>Loading...</span>
                  </Spinner>
                ) : (
                  <p>Não foram encontrado dados para estes filtro.</p>
                )}
              </div>
            )}
          </Col>

          <Col xs={8} className={styles.mapWrapper}>
            {!isLoading ? (
              <GoogleMapsDashboard zoom={4} locations={chartLocations} />
            ) : (
              <div className={styles.anErrorOcurred}>
                <Spinner animation="border" role="status">
                  <span style={{ visibility: "hidden" }}>Loading...</span>
                </Spinner>
              </div>
            )}
          </Col>

          <Col xs={12} style={{ marginTop: 16 }}>
            <h2 className={styles.title}>
              Rastreamento
              {filters && (
                <CsvDownload
                  data={tableTrackingIndicator}
                  filename={`planilha_rastreamento_${format(
                    new Date(filters.initialDate),
                    "dd/MM/yyyy"
                  )}-${format(new Date(filters.endDate), "dd/MM/yyyy")}.csv`}
                >
                  <TbFileExport size={25} color="#409E6A" />{" "}
                  <span>Exportar</span>
                </CsvDownload>
              )}
            </h2>
            <TableCustomerShipping
              trackerTable
              shippings={tableTrackingIndicator}
            />
          </Col>

          <Col xs={12} style={{ marginTop: 10 }}>
            <h2 className={styles.title}>
              Logística Reversa
              {filters && (
                <CsvDownload
                  data={tableReverseLogisticIndicator}
                  filename={`planilha_logistica_reversa_${format(
                    new Date(filters.initialDate),
                    "dd/MM/yyyy"
                  )}-${format(new Date(filters.endDate), "dd/MM/yyyy")}.csv`}
                >
                  <TbFileExport size={25} color="#409E6A" />{" "}
                  <span>Exportar</span>
                </CsvDownload>
              )}
            </h2>

            <TableCustomerShipping
              shippings={tableReverseLogisticIndicator}
              isLogisticReverse
            />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export { DashboardClient };
