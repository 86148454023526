import { Checkbox } from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import Swal from "sweetalert2";
import checkedIcon from "../../../../../assets/icons/icon-checked.svg";
import notCheckedIcon from "../../../../../assets/icons/icon-not-checked.svg";
import { Loading } from "../../../../../components/Loading";
import { BrComerceApiService } from "../../../../../services/BrComerceApiService.js";
import store from "../../../../../store/store";
import { StyledFormControlLabel } from "../../../../auth/Components/Input";
import styles from "./styles.module.scss";
import { typeSelect } from "./typeSelect.jsx";
import Select from "react-select";
import DeliveryTypeEnum from "../../../../../enums/DeliveryTypeEnum.js";

const Shopify = () => {
  const api = new BrComerceApiService();

  const { auth: { user } } = store.getState();

  const [appToken, setAppToken] = useState("");
  const [appTokenOK, setAppTokenOK] = useState("");
  const [appTokenError, setAppTokenError] = useState("");

  const [storeUrl, setStoreUrl] = useState("");
  const [storeUrlOK, setStoreUrlOK] = useState("");
  const [storeUrlError, setStoreUrlError] = useState("");

  const [activeCustomRate, setActiveCustomRate] = useState(false);
  const [customRateType, setCustomRateType] = useState({
    value: DeliveryTypeEnum.PAC,
    label: "Econômico"
  });

  const [customRate, setCustomRate] = useState("");
  const [customRateOK, setCustomRateOK] = useState("");
  const [customRateError, setCustomRateError] = useState("");

  const [senderName, setSenderName] = useState("");
  const [senderNameOK, setSenderNameOK] = useState("");
  const [senderNameError, setSenderNameError] = useState("");

  const [loading, setLoading] = useState(false);
  const [activeInsurance, setActiveInsurance] = useState(false);

  const [activeCreateOrder, setActiveCreateOrder] = useState(true);

  const [activeOwnDelivery, setActiveOwnDelivery] = useState(true);
  const [activeSedex, setActiveSedex] = useState(true);
  const [activePac, setActivePac] = useState(true);
  const [activePacMini, setActivePacMini] = useState(true);

  const [hasToken, setHasToken] = useState(false);

  const [zipOriginOK, setZipOriginOK] = useState("vazio");
  const [zipOriginError, setZipOriginError] = useState("");
  const [zipOrigin, setZipOrigin] = useState("");

  const [addressOK, setAddressOK] = useState("vazio");
  const [addressError, setAddressError] = useState("");
  const [address, setAddress] = useState("");

  const [addressObsOK, setAddressObsOK] = useState("vazio");
  const [addressObsError, setAddressObsError] = useState("");
  const [addressObs, setAddressObs] = useState("");

  const [addressNumberOK, setAddressNumberOK] = useState("vazio");
  const [addressNumber, setAddressNumber] = useState("");

  const [addressNeighborhoodOK, setAddressNeighborhoodOK] = useState("vazio");
  const [addressNeighborhoodError, setAddressNeighborhoodError] = useState("");
  const [addressNeighborhood, setAddressNeighborhood] = useState("");

  const [sedex, setSedex] = useState("");
  const [sedexOK, setSedexOK] = useState("");

  const [pac, setPac] = useState("");
  const [pacOK, setPacOK] = useState("");

  const [mini, setMini] = useState("");
  const [miniOK, setMiniOK] = useState("");

  const [ownDelivery, setOwnDelivery] = useState("");
  const [ownDeliveryOK, setOwnDeliveryOK] = useState("");

  const [uf, setUf] = useState("");
  const [ufOK, setUfOK] = useState("");

  const [city, setCity] = useState("");
  const [cityOK, setCityOK] = useState("");


  const canCollect = Boolean(user.canCreateCollectSchedule);

  const handleInputActiveOwnDelivery = () => {
    setActiveOwnDelivery(!activeOwnDelivery);
  };

  const handleInputActiveSedex = () => {
    setActiveSedex(!activeSedex);
  };

  const handleInputActivePac = () => {
    setActivePac(!activePac);
  };

  const handleInputActivePacMini = () => {
    setActivePacMini(!activePacMini);
  };

  const handleSubmit = useCallback(
    async (key, value) => {
      api.makeHttpRequest({
        url: "/shopify/config",
        method: "POST",
        data: {
          key: key,
          value: value
        }
      });
    },
    [api]
  );

  const activeIntegration = useCallback(async () => {
    try {
      setLoading(true);

      await api.makeHttpRequest({
        url: "/shopify/webhook/active",
        method: "POST",
        data: {
          appToken,
          storeUrl
        }
      });

      setHasToken(true);

      return Swal.fire({
        icon: "success",
        title: "Concluído",
        text: "Sua conta foi integrada a Shopify com sucesso"
      })
    } catch (err) {
      return Swal.fire({
        icon: "error",
        title: "Erro",
        text: "Não foi possível integrar a Shopify, revise as informações e tente novamente"
      })
    } finally {
      setLoading(false);
    }

  }, [api, appToken, storeUrl]);

  const handleInputAppToken = useCallback(
    e => {
      if (e.length < 1) {
        setAppToken(e);
        setAppTokenError("Campo obrigatório");
        setAppTokenOK("notOk");
      } else {
        setAppToken(e);
        setAppTokenError("");
        setAppTokenOK("ok");
      }
    },
    []
  );

  const handleInputStoreUrl = useCallback(
    e => {
      if (e.length < 1) {
        setStoreUrl(e);
        setStoreUrlError("Campo obrigatório");
        setStoreUrlOK("notOk");
      } else {
        setStoreUrl(e);
        setStoreUrlError("");
        setStoreUrlOK("ok");
      }
    },
    []
  );

  const handleInputCustomRate = useCallback(
    e => {
      if (e.length < 1) {
        setCustomRate(e);
        setCustomRateError("Campo obrigatório");
        setCustomRateOK("notOk");
      } else {
        setCustomRate(e);
        setCustomRateError("");
        setCustomRateOK("ok");
      }
    },
    []
  );

  const handleInputSenderName = useCallback(e => {
    const name = e.currentTarget.value;

    if (name.length < 1) {
      setSenderName("");
      setSenderNameError("");
      setSenderNameOK("vazio");
    } else if (name.length < 2) {
      setSenderName(name);
      setSenderNameError("Nome do remetente deve ter pelo menos 2 caracteres");
      setSenderNameOK("notOk");
    } else {
      setSenderName(name);
      setSenderNameError("");
      setSenderNameOK("ok");
    }
  }, []);

  const handleInputSedex = useCallback(e => {
    const valueNumber = e.currentTarget.value.replace(/\D/g, "");
    if (valueNumber > 99) {
      return;
    } else if (valueNumber === "") {
      setSedex("");
      setSedexOK("vazio");
    } else {
      setSedex(valueNumber);
      setSedexOK("ok");
    }
  }, []);

  const handleInputPac = useCallback(e => {
    const valueNumber = e.currentTarget.value.replace(/\D/g, "");
    if (valueNumber > 99) {
      return;
    } else if (valueNumber === "") {
      setPac("");
      setPacOK("vazio");
    } else {
      setPac(valueNumber);
      setPacOK("ok");
    }
  }, []);

  const handleInputMini = useCallback(e => {
    const valueNumber = e.currentTarget.value.replace(/\D/g, "");
    if (valueNumber > 99) {
      return;
    } else if (valueNumber === "") {
      setMini("");
      setMiniOK("vazio");
    } else {
      setMini(valueNumber);
      setMiniOK("ok");
    }
  }, []);

  const handleInputOwnDelivery = useCallback(e => {
    const valueNumber = e.currentTarget.value.replace(/\D/g, "");
    if (valueNumber > 99) {
      return;
    } else if (valueNumber === "") {
      setOwnDelivery("");
      setOwnDeliveryOK("vazio");
    } else {
      setOwnDelivery(valueNumber);
      setOwnDeliveryOK("ok");
    }
  }, []);

  const sendData = async () => {
    const zipOriginNumber = zipOrigin.replace(/\D/g, "");

    const addressComplet = {
      zip: zipOriginNumber,
      street: address,
      number: addressNumber,
      complement: addressObs,
      district: addressNeighborhood,
      city: city,
      state: uf
    };

    console.log(
      "🚀 ~ file: index.jsx:179 ~ sendData ~ addressComplet:",
      addressComplet
    );

    try {
      setLoading(true);

      await Promise.all([
        handleSubmit("APP_TOKEN", appToken),
        handleSubmit("STORE_URL", storeUrl),
        handleSubmit("SENDER_DEFAULT_NAME", senderName),
        handleSubmit("SENDER_DEFAULT_ADDRESS", JSON.stringify(addressComplet)),
        handleSubmit("ADD_DAYS_SEDEX", sedex),
        handleSubmit("ADD_DAYS_PAC", pac),
        handleSubmit("ADD_DAYS_PACMINI", mini),
        handleSubmit("ADD_DAYS_OWN_DELIVERY", ownDelivery),
        handleSubmit("CUSTOM_RATE_TYPE", customRateType.value),
        handleSubmit("CUSTOM_RATE", customRate),
        activeCustomRate
          ? handleSubmit("ACTIVE_CUSTOM_RATE", "true")
          : handleSubmit("ACTIVE_CUSTOM_RATE", "false"),
        activeInsurance
          ? handleSubmit("IS_ACTIVE_INSURANCE", "true")
          : handleSubmit("IS_ACTIVE_INSURANCE", "false"),
        activeCreateOrder
          ? handleSubmit("IS_INACTIVE_ORDER_CREATE", "false")
          : handleSubmit("IS_INACTIVE_ORDER_CREATE", "true"),
        activeOwnDelivery && canCollect
          ? handleSubmit("IS_ACTIVE_OWN_DELIVERY", "true")
          : handleSubmit("IS_ACTIVE_OWN_DELIVERY", "false"),
        activeSedex
          ? handleSubmit("IS_ACTIVE_SEDEX", "true")
          : handleSubmit("IS_ACTIVE_SEDEX", "false"),
        activePac
          ? handleSubmit("IS_ACTIVE_PAC", "true")
          : handleSubmit("IS_ACTIVE_PAC", "false"),
        activePacMini
          ? handleSubmit("IS_ACTIVE_PACMINI", "true")
          : handleSubmit("IS_ACTIVE_PACMINI", "false")
      ]);

      Swal.fire({
        title: "Sucesso!",
        text: "Configurações cadastradas com sucesso.",
        icon: "success",
        showCancelButton: false,
        confirmButtonText: "Ok"
      });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Ocorreu um erro!",
        text: "Erro ao cadastrar configurações"
      });
    } finally {
      setLoading(false);
    }
  };

  const handleCepConsult = useCallback(async cep => {
    try {
      setLoading(true);
      let response = await api.makeHttpRequest({
        method: "POST",
        url: `/addressbook/getAddress`,
        data: {
          zipCode: cep
        }
      });

      if (response?.erro === true) {
        setZipOriginOK("notOk");
        setZipOriginError("CEP inválido.");

        setAddress("");
        setAddressOK("vazio");
        setAddressError("");

        setAddressNeighborhood("");
        setAddressNeighborhoodOK("vazio");
        setAddressNeighborhoodError("");

        setUf("");
        setUfOK("");
        setCity("");
        setCityOK("");

        throw new Error("Não foi possível encontrar o CEP digitado.");
      }

      setZipOriginOK("ok");
      setZipOriginError("");

      if (!response.logradouro || response.logradouro === "undefined") {
        setAddressOK("vazio");
      } else {
        setAddress(response.logradouro);
        setAddressOK("ok");
        setAddressError("");
      }

      if (!response.bairro || response.bairro === "undefined") {
        setAddressNeighborhoodOK("vazio");
      } else {
        setAddressNeighborhood(response.bairro);
        setAddressNeighborhoodOK("ok");
        setAddressNeighborhoodError("");
      }

      setUf(response.uf);
      setUfOK("ok");
      setCity(response.localidade);

      setCityOK("ok");
    } catch (error) {
      console.error(error);

      Swal.fire({
        icon: "error",
        title: "CEP não encontrado",
        text: "Por favor, verifique o CEP inserido"
      });
    } finally {
      setLoading(false);
    }
  }, []);

  const handleValidationAddressObs = value => {
    if (value?.length < 2 && value?.length > 0) {
      setAddressObsOK("notOk");
    } else if (value?.length > 2) {
      setAddressObsOK("ok");
    } else {
      setAddressObsOK("vazio");
    }

    if (value?.length === 1) {
      setAddressObsError("Complemento deve ter pelo menos 2 caracteres");
    } else {
      setAddressObsError("");
    }
  };

  const handleInputAddressObs = useCallback(e => {
    let value = e.currentTarget.value;
    setAddressObs(value);
    handleValidationAddressObs(value);
  }, []);

  const handleInputAddress = useCallback(e => {
    let value = e.currentTarget.value;
    setAddress(value);
    handleValidationAddress(value);
  }, []);

  const handleValidationAddress = value => {
    value.length < 2 ? setAddressOK("notOk") : setAddressOK("ok");
    if (value.length === 0) {
      setAddressError("Campo obrigatório");
    } else if (value.length === 1) {
      setAddressError("Endereço deve ter pelo menos 2 caracteres");
    } else {
      setAddressError("");
    }
  };

  const handleValidationZipOrigin = useCallback(
    async (value, isASavedInfo = false) => {
      if (!value.length) {
        setZipOriginOK("notOk");
        setZipOriginError("Preenchimento obrigatório");
      } else if (value.length < 8) {
        setZipOriginOK("notOk");
        setZipOriginError("Preenchimento incorreto");
      } else {
        if (isASavedInfo) {
          setZipOriginOK("ok");
          setZipOriginError("");
        }

        // Não queremos manipular o CEP novamente,
        // se o CEP selecionado pertence a um endereço já salvo
        if (isASavedInfo === false) {
          const cep = value.substr(0, 5) + value.substr(6, 3);
          await handleCepConsult(cep);
        }
      }
    },
    [handleCepConsult]
  );

  const handleInputZipOrigin = useCallback(
    e => {
      let value = e;
      value = value.replace(/^(\d{5})(\d{3})/, "$1-$2");

      handleValidationZipOrigin(value);
      setZipOrigin(value);
    },
    [handleValidationZipOrigin]
  );

  const handleValidationAddressNeighborHood = value => {
    if (address?.length) return;

    value.length < 1
      ? setAddressNeighborhoodOK("notOk")
      : setAddressNeighborhoodOK("ok");
    if (value.length === 0) {
      setAddressNeighborhoodError("Campo obrigatório");
    } else {
      setAddressNeighborhoodError("");
    }
  };

  const handleInputAddressNeighborhood = useCallback(e => {
    let value = e.currentTarget.value;
    setAddressNeighborhood(value);
    handleValidationAddressNeighborHood(value);
  }, []);

  const handleValidationAddressNumber = value => {
    if (value !== "") {
      setAddressNumberOK("ok");
    } else {
      setAddressNumberOK("vazio");
    }
  };

  const handleInputAddressNumber = useCallback(e => {
    let value = e.currentTarget.value;

    if (value !== "" && !Number(value)) {
      return;
    }

    setAddressNumber(value);
    handleValidationAddressNumber(value);
  }, []);

  const handleChangeSelectCustomRate = e => {
    if (e === null) {
      setCustomRateType(e);
      setCustomRateOK("notOk");
      setCustomRateError("Selecione uma opção");
    } else {
      setCustomRateType(e);
      setCustomRateOK("ok");
      setCustomRateError("");
    }
  };

  useEffect(() => {
    window.setPageTitle("Integrações | Shopify");
  }, []);

  const loadingConfig = useCallback(async () => {
    try {
      await Promise.all([
        api
          .makeHttpRequest({
            method: "GET",
            url: `/shopify/config/ACTIVE_CUSTOM_RATE`
          })
          .then(active => {
            if (active === "true") {
              setActiveCustomRate(true);
            }
          }),
        api
          .makeHttpRequest({
            method: "GET",
            url: `/shopify/config/IS_ACTIVE_INSURANCE`
          })
          .then(active => {
            if (active === "true") {
              setActiveInsurance(true);
            }
          }),
        api
          .makeHttpRequest({
            method: "GET",
            url: `/shopify/config/IS_INACTIVE_ORDER_CREATE`
          })
          .then(inactive => {
            if (inactive === "true") {
              setActiveCreateOrder(false);
            }
          }),
        api
          .makeHttpRequest({
            method: "GET",
            url: `/shopify/config/IS_ACTIVE_OWN_DELIVERY`
          })
          .then(active => {
            if (active === "false") {
              setActiveOwnDelivery(false);
            }
          }),
        api
          .makeHttpRequest({
            method: "GET",
            url: `/shopify/config/IS_ACTIVE_SEDEX`
          })
          .then(active => {
            if (active === "false") {
              setActiveSedex(false);
            }
          }),
        api
          .makeHttpRequest({
            method: "GET",
            url: `/shopify/config/IS_ACTIVE_PAC`
          })
          .then(active => {
            if (active === "false") {
              setActivePac(false);
            }
          }),
        api
          .makeHttpRequest({
            method: "GET",
            url: `/shopify/config/IS_ACTIVE_PACMINI`
          })
          .then(active => {
            if (active === "false") {
              setActivePacMini(false);
            }
          }),
        api
          .makeHttpRequest({
            method: "GET",
            url: `/shopify/config/CUSTOM_RATE_TYPE`
          })
          .then(type => {
            if (type) {
              setCustomRateType({
                value: type,
                label: typeSelect.find(s => s.value === type)?.label
              });
            }
          }),
        api
          .makeHttpRequest({
            method: "GET",
            url: `/shopify/config/CUSTOM_RATE`
          })
          .then(customRate => {
            if (customRate) {
              setCustomRate(customRate);
              setCustomRateOK("ok");
            }
          }),
        api
          .makeHttpRequest({
            method: "GET",
            url: `/shopify/config/APP_TOKEN`
          })
          .then(token => {
            if (token) {
              setHasToken(true);
              setAppToken(token);
              setAppTokenOK("ok");
            }
          }),
        api
          .makeHttpRequest({
            method: "GET",
            url: `/shopify/config/STORE_URL`
          })
          .then(storeUrl => {
            if (storeUrl) {
              setStoreUrl(storeUrl);
              setStoreUrlOK("ok");
            }
          }),
        api
          .makeHttpRequest({
            method: "GET",
            url: `/shopify/config/ADD_DAYS_PACMINI`
          })
          .then(res => {
            if (res) {
              setMini(res);
              setMiniOK("ok");
            }
          }),
        api
          .makeHttpRequest({
            method: "GET",
            url: `/shopify/config/ADD_DAYS_PAC`
          })
          .then(res => {
            if (res) {
              setPac(res);
              setPacOK("ok");
            }
          }),
        api
          .makeHttpRequest({
            method: "GET",
            url: `/shopify/config/ADD_DAYS_SEDEX`
          })
          .then(res => {
            if (res) {
              setSedex(res);
              setSedexOK("ok");
            }
          }),
        api
          .makeHttpRequest({
            method: "GET",
            url: `/shopify/config/ADD_DAYS_OWN_DELIVERY`
          })
          .then(res => {
            if (res) {
              setOwnDelivery(res);
              setOwnDeliveryOK("ok");
            }
          }),
        api
          .makeHttpRequest({
            method: "GET",
            url: `/shopify/config/SENDER_DEFAULT_NAME`
          })
          .then(name => {
            if (name) {
              setSenderName(name);
              setSenderNameOK("ok");
            }
          }),
        api
          .makeHttpRequest({
            method: "GET",
            url: `/shopify/config/SENDER_DEFAULT_ADDRESS`
          })
          .then(res => {
            if (res) {
              const address = JSON.parse(res || "[]");
              setZipOrigin(address?.zip?.replace(/^(\d{5})(\d{3})/, "$1-$2"));
              setZipOriginOK("ok");

              setAddress(address.street);
              setAddressOK("ok");

              setAddressNumber(Number(address.number) || "");
              if (address.number) {
                setAddressNumberOK("ok");
              }

              setAddressObs(address.complement);
              if (address.complement) {
                setAddressObsOK("ok");
              }

              setAddressNeighborhood(address.district);
              setAddressNeighborhoodOK("ok");

              setUf(address.state);
              setUfOK("ok");

              setCity(address.city);
              setCityOK("ok");
            }
          })
      ]);
    } catch (error) {
      console.log("🚀 ~ file: index.jsx:522 ~ loadingConfig ~ error:", error);
    }
  }, [api, user.canCreateCollectSchedule]);

  useEffect(() => {
    setLoading(true);

    loadingConfig()
      .then(() => {
        console.log("Configurações ok");
      })
      .catch(() => {
        Swal.fire({
          title: "Erro!",
          text:
            "Não foi possível carregar as informações da loja da Shopify.",
          icon: "error"
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const selectStyle = {
    control: styles => ({
      ...styles,
      backgroundColor: "inherit",
      width: "100%",
      border: "none",
      boxShadow: "none",
      outline: "none",
      color: "#807E80",
      padding: "0",
      "&:hover": {
        borderColor: "none"
      },
      cursor: "pointer"
    }),
    container: styles => ({
      ...styles,
      width: "100%",
      outline: "none",
      border: "none",
      padding: "2px 0",
      cursor: "pointer"
    }),
    indicatorSeparator: styles => ({
      ...styles,
      display: "none",
      border: "none"
    }),
    option: (styles, { isSelected }) => ({
      ...styles,
      fontSize: "14px",
      fontFamily: "Mulish",
      fontWeight: 400,
      color: isSelected ? "#fff" : "#807E80",
      "&:hover": {
        backgroundColor: "#c1c1c1",
        color: "#fff",
        cursor: "pointer"
      },

      backgroundColor: isSelected ? "#409e6a" : "#fff"
    }),
    singleValue: styles => ({
      ...styles,
      color: "#807E80",
      fontSize: "14px"
    }),
    placeholder: styles => ({
      ...styles,
      fontSize: "14px",
      fontFamily: "Mulish",
      fontWeight: 400,
      color: "#807E80",
      whiteSpace: "nowrap"
    })
  };

  return (
    <>
      <Loading loading={loading} />
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.contentHeader}>
            <h2>Configurações</h2>
          </div>
          <div className={styles.contentBody}>
            <section>
              {!hasToken ? <>
                <div className={styles.wrapper}>
                  <p>
                    Crie o token para essa integração na plataforma admin da
                    Shopify
                  </p>
                  <div className={`${styles.inputContainer} `}>
                    <div className={` ${styles.divContainer}`}>
                      <div className={styles.input} role={appTokenOK}>
                        <input
                          type="text"
                          name="appToken"
                          value={appToken}
                          onChange={e =>
                            handleInputAppToken(e.currentTarget.value)
                          }
                          placeholder="Token de acesso da API Admin"
                        />
                      </div>
                      {appTokenError && (
                        <p className={styles.error}>{appTokenError}</p>
                      )}
                    </div>
                  </div>
                  <div className={`${styles.inputContainer} `}>
                    <div className={` ${styles.divContainer}`}>
                      <div className={styles.input} role={storeUrl}>
                        <input
                          type="text"
                          name="storeUrl"
                          value={storeUrl}
                          onChange={e =>
                            handleInputStoreUrl(e.currentTarget.value)
                          }
                          placeholder="Link da loja. (ex: minhaloja.myshopify.com)"
                        />
                      </div>
                      {storeUrlError && (
                        <p className={styles.error}>{storeUrlError}</p>
                      )}
                    </div>
                  </div>
                  <div className={styles.buttonWrapper}>
                    <button
                      type="submit"
                      onClick={activeIntegration}
                      className={styles.button}
                      disabled={appTokenOK !== "ok" || storeUrlOK !== "ok"}
                    >
                      Ativar
                    </button>
                  </div>
                </div>
              </> :
                <>

                  <div className={styles.sectionTitle}>
                    <p>
                      Informe os dados do remetente que serão usados no calculo do
                      frete e impressão de etiquetas.
                    </p>
                    <form>
                      <div className={`${styles.inputContainer} `}>
                        <div className={styles.divContainer}>
                          <div className={styles.input} role={senderNameOK}>
                            <input
                              onChange={e => handleInputSenderName(e)}
                              value={senderName}
                              placeholder="Digite o nome do remetente"
                              type="text"
                              name="senderName"
                            />
                          </div>
                          {senderNameError && (
                            <p className={styles.error}>{senderNameError}</p>
                          )}
                        </div>
                      </div>
                      <div className={`${styles.inputContainer} `}>
                        <div className={` ${styles.divContainer}`}>
                          <div className={` ${styles.inputContainerSmCep} `}>
                            <div className={styles.input} role={zipOriginOK}>
                              <input
                                type="text"
                                name="cep"
                                placeholder="Digite o CEP"
                                onChange={e =>
                                  handleInputZipOrigin(e.currentTarget.value)
                                }
                                value={zipOrigin}
                                maxLength={8}
                              />
                              <a
                                href="https://buscacepinter.correios.com.br/app/endereco/index.php"
                                target="_blank"
                                rel="noopener noreferrer"
                                className={styles.cepLink}
                              >
                                Não sei o CEP
                              </a>
                            </div>
                          </div>
                        </div>
                        {zipOriginError && (
                          <p className={styles.error}>{zipOriginError}</p>
                        )}
                      </div>
                      <div
                        className={`${styles.inputContainer} ${styles.inputContainerAddress}`}
                      >
                        <div
                          className={` ${styles.divContainer} ${styles.inputContainerMd}`}
                        >
                          <div className={` ${styles.input} `} role={addressOK}>
                            <input
                              onChange={e => handleInputAddress(e)}
                              value={address}
                              placeholder="Digite o endereço"
                              type="text"
                              name="address"
                            />
                          </div>
                          {addressError && (
                            <p className={styles.error}>{addressError}</p>
                          )}
                        </div>

                        <div className={` ${styles.divContainer}`}>
                          <div className={styles.input} role={addressNumberOK}>
                            <input
                              onChange={e => handleInputAddressNumber(e)}
                              value={addressNumber}
                              placeholder="Digite o número"
                              type="text"
                              name="addressNumber"
                            />
                          </div>
                        </div>
                      </div>
                      <div
                        className={`${styles.inputContainer} ${styles.inputContainerAddress}`}
                      >
                        <div className={` ${styles.divContainer}`}>
                          <div className={styles.input} role={addressObsOK}>
                            <input
                              onChange={e => handleInputAddressObs(e)}
                              value={addressObs}
                              placeholder="Digite o complemento"
                              type="text"
                              name="addressObs"
                            />
                          </div>
                          {addressObsError && (
                            <p className={styles.error}>{addressObsError}</p>
                          )}
                        </div>

                        <div className={` ${styles.divContainer}`}>
                          <div
                            className={styles.input}
                            role={addressNeighborhoodOK}
                          >
                            <input
                              onChange={e => handleInputAddressNeighborhood(e)}
                              value={addressNeighborhood}
                              placeholder="Digite o bairro"
                              type="text"
                              name="addressNeighborhood"
                            />
                          </div>
                          {addressNeighborhoodError && (
                            <p className={styles.error}>
                              {addressNeighborhoodError}
                            </p>
                          )}
                        </div>
                      </div>
                      <div
                        className={`${styles.inputContainer} ${styles.inputContainerAddress}`}
                      >
                        <div className={styles.divContainer}>
                          <div className={styles.input} role={ufOK}>
                            <input
                              onChange={() => { }}
                              value={uf}
                              type="text"
                              name="uf"
                            />
                          </div>
                        </div>

                        <div className={styles.divContainer}>
                          <div className={` ${styles.divContainer}`}>
                            <div className={styles.input} role={cityOK}>
                              <input
                                onChange={() => { }}
                                value={city}
                                type="text"
                                name="city"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>

                  <div className={styles.wrapper}>
                    <h4>Criação de pedido</h4>
                    <p>
                    Abaixo é possível ativar ou desatitvar a criação de pedidos.
                      <br />
                      Serviços como cotação funcionarão normalmente
                    </p>
                    <div>
                      <div className={styles.switchOption}>
                        <label className={styles.switch}>
                          <input
                            type="checkbox"
                            onChange={event =>
                              setActiveCreateOrder(event.target.checked)
                            }
                            checked={activeCreateOrder}
                          />
                          <span
                            className={`${styles.slider} ${styles.round}`}
                          ></span>
                        </label>
                        {activeCreateOrder ? "Ativado": "Desativado"}
                      </div>
                    </div>
                  </div>

                  <div className={styles.wrapper}>
                    <h4>Configuração de Seguro</h4>
                    <p>
                      Abaixo é possível ativar ou desatitvar a cotação de frete com
                      Seguro. Essa opção é válida para todos os fretes <br /> que
                      são cotados.
                    </p>
                    <div>
                      <div className={styles.switchOption}>
                        <label className={styles.switch}>
                          <input
                            type="checkbox"
                            onChange={event =>
                              setActiveInsurance(event.target.checked)
                            }
                            checked={activeInsurance}
                          />
                          <span
                            className={`${styles.slider} ${styles.round}`}
                          ></span>
                        </label>
                        {activeInsurance ? "Ativado" : "Desativado"}
                      </div>
                    </div>
                  </div>

                  <div className={styles.wrapper}>
                    <h4>Configuração de Frete</h4>
                    <p>
                      Abaixo é possível habilitar ou desabilitar as opções de frete.
                    </p>
                    {canCollect && (
                      <>
                        <div className={styles.inputContainer}>
                          <StyledFormControlLabel
                            control={
                              <Checkbox
                                name="activeOwnDelivery"
                                checked={activeOwnDelivery}
                                onChange={handleInputActiveOwnDelivery}
                                icon={<img src={notCheckedIcon} alt="" />}
                                checkedIcon={<img src={checkedIcon} alt="" />}
                                label=""
                              />
                            }
                            label="Super Expressa"
                          />
                        </div>
                      </>
                    )}
                    <div className={styles.inputContainer}>
                      <StyledFormControlLabel
                        control={
                          <Checkbox
                            name="activeSedex"
                            checked={activeSedex}
                            onChange={handleInputActiveSedex}
                            icon={<img src={notCheckedIcon} alt="" />}
                            checkedIcon={<img src={checkedIcon} alt="" />}
                            label=""
                          />
                        }
                        label="Expresso"
                      />
                    </div>
                    <div className={styles.inputContainer}>
                      <StyledFormControlLabel
                        control={
                          <Checkbox
                            name="activePac"
                            checked={activePac}
                            onChange={handleInputActivePac}
                            icon={<img src={notCheckedIcon} alt="" />}
                            checkedIcon={<img src={checkedIcon} alt="" />}
                            label=""
                          />
                        }
                        label="Econômico"
                      />
                    </div>
                    <div className={styles.inputContainer}>
                      <StyledFormControlLabel
                        control={
                          <Checkbox
                            name="activePacMini"
                            checked={activePacMini}
                            onChange={handleInputActivePacMini}
                            icon={<img src={notCheckedIcon} alt="" />}
                            checkedIcon={<img src={checkedIcon} alt="" />}
                            label=""
                          />
                        }
                        label="Super Econômico"
                      />
                    </div>

                    <div className={styles.inputContainer}>
                      <br></br>
                      <p>Importar pedidos com frete customizado</p>
                      <div className={styles.contentSectionCD}>
                        <div className={styles.switchOption}>
                          <label className={styles.switch}>
                            <input
                              type="checkbox"
                              onChange={event => {
                                setActiveCustomRate(event.target.checked);
                                setCustomRateOK("ok");
                              }}
                              checked={activeCustomRate}
                            />
                            <span
                              className={`${styles.slider} ${styles.round}`}
                            ></span>
                          </label>
                          {activeCustomRate ? "Ativado" : "Desativado"}
                        </div>
                      </div>
                      <br></br>
                    </div>
                    {activeCustomRate && (
                      <div className={styles.wrapper}>
                        <p>
                          Digite o frete customizado exatamente como aparece no checkout da sua loja.
                        </p>
                        <div className={`${styles.inputContainer} `}>
                          <div className={` ${styles.divContainer}`}>
                            <div className={styles.input} role={customRateOK}>
                              <input
                                type="text"
                                name="customRate"
                                value={customRate}
                                onChange={e => handleInputCustomRate(e.currentTarget.value)}
                                placeholder="Digite o nome do frete customizado"
                              />
                            </div>
                            {customRateError && (
                              <p className={styles.error}>{customRateError}</p>
                            )}
                          </div>
                        </div>
                        <p>
                          Selecione o tipo de envio para o frete customizado
                        </p>
                        <div className={styles.input} role={customRateOK}>
                          <Select
                            onChange={handleChangeSelectCustomRate}
                            options={typeSelect}
                            value={customRateType}
                            placeholder="Selecione o tipo"
                            styles={selectStyle}
                          />
                        </div>
                        {customRateError && (
                          <p className={styles.error}>{customRateError}</p>
                        )}
                      </div>
                    )}
                  </div>

                  <div className={styles.wrapper}>
                    <h4>Configurações adicionais de frete</h4>
                    <p>
                      Abaixo é possível indicar a quantidade de dias adicionais para
                      cada tipo de envio.
                      <br />
                      Essa opção é particularmente útil para refletir nas cotações o
                      seu prazo de manipulação dos pedidos.
                    </p>
                    <div style={{ marginTop: 0 }} className={styles.contentSection}>
                      <div className={styles.addDaysContainer}>
                        {canCollect && (
                          <div
                            className={`${styles.input} ${ownDeliveryOK === "notOK"
                              ? styles.formSelectError
                              : ownDeliveryOK === "ok"
                                ? styles.selectOk
                                : ""
                              }`}
                          >
                            <input
                              type="number"
                              name="ownDelivery"
                              value={ownDelivery}
                              onChange={handleInputOwnDelivery}
                              placeholder="Super Expressa"
                              min={0}
                              max={99}
                            />
                          </div>
                        )}
                        <div
                          className={`${styles.input} ${sedexOK === "notOK"
                            ? styles.formSelectError
                            : sedexOK === "ok"
                              ? styles.selectOk
                              : ""
                            }`}
                        >
                          <input
                            type="number"
                            name="sedex"
                            value={sedex}
                            onChange={handleInputSedex}
                            placeholder="Expresso"
                            min={0}
                            max={99}
                          />
                        </div>
                        <div
                          className={`${styles.input} ${pacOK === "notOK"
                            ? styles.formSelectError
                            : pacOK === "ok"
                              ? styles.selectOk
                              : ""
                            }`}
                        >
                          <input
                            type="number"
                            name="pac"
                            value={pac}
                            onChange={handleInputPac}
                            placeholder="Econômico"
                            min={0}
                            max={99}
                          />
                        </div>
                        <div
                          className={`${styles.input} ${miniOK === "notOK"
                            ? styles.formSelectError
                            : miniOK === "ok"
                              ? styles.selectOk
                              : ""
                            }`}
                        >
                          <input
                            type="number"
                            name="mini"
                            value={mini}
                            onChange={handleInputMini}
                            placeholder="Super Econômico"
                            min={0}
                            max={99}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.buttonWrapper}>
                    <button
                      type="submit"
                      onClick={sendData}
                      className={styles.button}
                      disabled={
                        appTokenOK !== "ok" ||
                        storeUrlOK !== "ok" ||
                        senderNameOK !== "ok" ||
                        addressOK !== "ok" ||
                        (activeCustomRate && customRateOK !== "ok")
                      }
                    >
                      Salvar
                    </button>
                  </div>
                </>
              }
            </section>
          </div>
        </div>
      </div>
    </>
  );
};

export { Shopify };
