export const RequestInfoTypeEnum = {
  ENTREGUE_ATRASO: {
    value: "ENTREGUE_ATRASO",
    message: "Entregue com atraso"
  },

  NAO_ENTREGUE: {
    value: "NAO_ENTREGUE",
    message: "Não entregue"
  },
};
