import React from "react";

import ReactModal from "react-modal";

import { Container } from "./styles";

import { ClockLoader } from "react-spinners";

const GreenClockModal = () => {
  return (
    <>
      <ReactModal
        isOpen={true}
        shouldCloseOnOverlayClick={false}
        style={{
          overlay: {
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(51, 51, 51, 0.75)",
            zIndex: 999
          },
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            transform: "translate(-50%, -50%)",
            zIndex: 999,
            border: "none",
            backgroundColor: "transparent",
            padding: 0
          }
        }}
      >
        <Container>
          <ClockLoader size={48} color="#fff" speedMultiplier={0.15} />
          <p>
            Aguarde!
            <br />
            Estamos processando sua solicitação
          </p>
        </Container>
      </ReactModal>
    </>
  );
};

export default GreenClockModal;
