import React, { useCallback, useEffect, useState } from "react";
import { BrComerceApiService } from "../../../../../services/BrComerceApiService";
import styles from "./styles.module.scss";

import { Check, Close } from "@material-ui/icons";
import { FiUpload } from "react-icons/fi";
import { RiLoader2Fill } from "react-icons/ri";
import InfiniteScroll from "react-infinite-scroll-component";
import CsvDownload from "react-json-to-csv";
import Swal from "sweetalert2";
import filesIcon from "../../../../../assets/icons/files.svg";
import tooltipIcon from "../../../../../assets/icons/tooltip-info.svg";
import banner from "../../../../../assets/images/shipping-import.svg";
import LoadInfiniteScroll from "../../../../../components/LoadInfiniteScroll";
import { Loading } from "../../../../../components/Loading";
import { ShippingImportOriginEnum } from "../../../../../enums/ShippingImportOriginEnum";
import { ShippingImportStatusEnum } from "../../../../../enums/ShippingImportStatusEnum";
import { DragAndDrop } from "../../../components/DragAndDrop";
import ScrollDiv from "../../../components/ScrollDiv";
import { shippingImportModel } from "./shippingImportModel";
import { format } from "date-fns";

const ImportShipping = () => {
  const api = new BrComerceApiService();

  const [loading, setLoading] = useState(false);
  const [loadingInfinit, setLoadingInfinit] = useState(false);

  const [uploadModal, setUploadModal] = useState(false);
  const [tooltip, setTooltip] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  const [table, setTable] = useState(ShippingImportStatusEnum.DONE.value);
  const [uploadFile, setUploadFile] = useState();
  const [importStatusInProgress, setImportStatusInProgress] = useState([]);
  const [importStatusDone, setImportStatusDone] = useState([]);
  const [page, setPage] = useState(0);

  const handleUpload = useCallback((file) => {
    setUploadFile(file);
  }, []);

  const handleClear = useCallback(() => {
    setUploadFile(null);
  }, []);

  const handleOpenModal = useCallback(() => {
    setUploadModal(true);
  }, []);

  const handleCloseModal = useCallback(() => {
    setUploadModal(false);
    setUploadFile(null);
  }, []);

  const handleShowTooltip = useCallback(() => {
    setTooltip(true);
  }, []);

  const handleHideTooltip = useCallback(() => {
    setTooltip(false);
  }, []);

  const handleChangeTable = useCallback(() => {
    if (table === ShippingImportStatusEnum.DONE.value) {
      setTable(ShippingImportStatusEnum.IN_PROGRESS.value);
    } else {
      setTable(ShippingImportStatusEnum.DONE.value);
    }
  }, [table]);

  const fetchFirstData = useCallback(async () => {
    setLoading(true);
    try {
      const results = await api.makeHttpRequest({
        method: "GET",
        url: "/shipping-import-status",
        params: {
          status: ShippingImportStatusEnum.IN_PROGRESS.value,
          origin: ShippingImportOriginEnum.IMPORT_SHIPPING
        }
      });

      const importStatusArray = [];

      if (results?.length > 0) {

        for await (const result of results) {

          const shippings = result?.shippings?.length || 0;
          const errors = result?.errors?.length || 0;
          const processed = shippings + errors;
          const reachTotal = result?.total <= processed;

          if (reachTotal) {
            importStatusArray.push({ ...result, status: ShippingImportStatusEnum.DONE.value });
          } else {
            importStatusArray.push(result);
          }
        }

        setImportStatusInProgress(importStatusArray);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  }, []);

  const fetchDataDone = useCallback(async () => {
    setLoadingInfinit(true);
    try {
      const results = await api.makeHttpRequest({
        method: "GET",
        url: "/shipping-import-status",
        params: {
          page: page + 1,
          perPage: 20,
          status: ShippingImportStatusEnum.DONE.value,
          origin: ShippingImportOriginEnum.IMPORT_SHIPPING
        }
      });

      if (results?.length === 20) {
        setPage(page + 1)
      } else {
        setHasMore(false);
      }

      const resultsDeduplicated = [];

      for await (const result of results) {
        const duplicated = importStatusDone.find(i => i?.id === result?.id) || "";

        if (!duplicated) {
          resultsDeduplicated.push(result)
        }
      }

      setImportStatusDone((previsouState) => [...previsouState, ...resultsDeduplicated]);

    } catch (e) {
      console.log(e);
    } finally {
      setLoadingInfinit(false);
    }
  }, [api, page, importStatusDone]);

  const fetchDataInProgress = useCallback(async () => {
    const interval = setInterval(async () => {
      try {
        const results = await api.makeHttpRequest({
          method: "GET",
          url: "/shipping-import-status",
          params: {
            status: ShippingImportStatusEnum.IN_PROGRESS.value,
            origin: ShippingImportOriginEnum.IMPORT_SHIPPING
          }
        });

        const importStatusArray = [];

        if (results?.length > 0) {

          for await (const result of results) {

            const shippings = result?.shippings?.length || 0;
            const errors = result?.errors?.length || 0;
            const processed = shippings + errors;
            const reachTotal = result?.total <= processed;

            if (reachTotal) {
              importStatusArray.push({ ...result, status: ShippingImportStatusEnum.DONE.value });
            } else {
              importStatusArray.push(result);
            }
          }

          setImportStatusInProgress(importStatusArray);

        } else {
          setImportStatusInProgress([]);
          (async () => {
            await clearInterval(interval);
          })();
        }
      } catch (e) {
        console.log(e);
      }
    }, 5000);
  }, []);

  const handleSubmit = useCallback(async () => {

    setLoading(true);

    if (uploadFile) {

      let data = new FormData();

      data.append("sheet", uploadFile);
      data.append("origin", ShippingImportOriginEnum.IMPORT_SHIPPING);

      try {

        await api.makeHttpRequest({
          method: "POST",
          url: `shipping-import-status/upload`,
          data,
        });

        setUploadModal(false);

        Swal.fire({
          title: 'Sucesso!',
          text: 'Planilha atualizada com sucesso.',
          icon: 'success'
        });

        if (!importStatusInProgress.length > 0) {
          fetchDataInProgress()
        }
      } catch (e) {
        console.log(JSON.parse(JSON.stringify(e)));
        return Swal.fire({
          title: 'Ops!',
          text: 'Erro ao fazer upload da planilha para importação, tente novamente mais tarde',
          icon: 'error'
        });
      } finally {
        setLoading(false);
      }
    } else {
      setLoading(false);
      return Swal.fire({
        title: 'Planilha não encontrada!',
        text: 'Selecione o arquivo e tente novamente',
        icon: 'error'
      });
    }
  }, [uploadFile, api, importStatusInProgress, fetchDataInProgress]);

  useEffect(() => {
    fetchFirstData();
    fetchDataInProgress();
    fetchDataDone();
  }, [])

  useEffect(() => {
    window.setPageTitle("Importações");
  }, [])

  return (
    <>
      {uploadModal && (
        <>
          <div className={styles.uploadBackground} onClick={handleCloseModal} />
          <div className={styles.uploadModal}>
            <div className={styles.close}>
              <Close onClick={handleCloseModal} />
            </div>
            <span>Faça o upload do arquivo abaixo:</span>
            <div className={styles.dragAndDrop}>
              <DragAndDrop
                onUpload={handleUpload}
                onClear={handleClear}
                fileExtension="csv"
              />
            </div>
            <button
              type="button"
              className={`${styles.btnSubmit} ${!uploadFile ? styles.disabled : ""}`}
              onClick={handleSubmit}
              disabled={!uploadFile}
            >
              <span>Enviar</span>
            </button>
          </div>
        </>
      )
      }
      <Loading loading={loading} />
      <div className={styles.container}>
        <div className={styles.column}>

          <h1 className={styles.title}>Importar Envios</h1>

          <div className={styles.row}>

            <div className={styles.btnContainer}>
              <CsvDownload
                data={shippingImportModel}
                className={`${styles.btnImport} ${styles.blue}`}
                filename="Importação de envios (BRComerce).csv"
                onMouseLeave={handleHideTooltip}
              >
                <img src={filesIcon} alt="Ícone de arquivos" />
                <span>Baixar modelo</span>
                <div className={styles.tooltipIcon}>
                  <img src={tooltipIcon} alt="Ícone de dica" onMouseEnter={handleShowTooltip} />
                  {tooltip && (
                    <>
                      <div className={`${styles.tooltip} ${styles.sbdown}`}>
                        <p>
                          <b>Atenção</b>: Evite usar acentos e caracteres especiais e separe os números decimais sempre com ponto.
                          <br />
                          <br />
                          Exemplos: Jardim Caicara, Sao Paulo, 1.2, 0.3
                        </p>
                      </div>
                    </>
                  )}
                </div>
              </CsvDownload>

              <button
                type="button"
                className={styles.btnImport}
                onClick={handleOpenModal}
              >
                <FiUpload />
                <span>Enviar arquivo</span>
              </button>
            </div>
            <div className={`${styles.btnContainer} ${styles.flexEnd}`}>
              <button
                type="button"
                className={`${styles.btnImport} ${styles.btnSwap}`}
                onClick={handleChangeTable}
              >
                {
                  table === ShippingImportStatusEnum.DONE.value ?
                    <>
                      <RiLoader2Fill />
                      <span>Ver em andamento</span>
                    </>
                    :
                    <>
                      <Check />
                      <span>Ver concluídas</span>
                    </>
                }
              </button>
            </div>
          </div>
        </div>
        {
          table === ShippingImportStatusEnum.IN_PROGRESS.value ?
            <ScrollDiv data={importStatusInProgress}>
              <InfiniteScroll
                dataLength={importStatusInProgress.length}
                next={() => { return }}
                hasMore={false}
                scrollableTarget="scrollDiv"
                className={styles.infiniteScroll}
              >
                <div className={styles.tableContainer}>
                  <table className={styles.table}>
                    <thead className={styles.tableHead}>
                      <tr>
                        <th>
                          ID
                        </th>
                        <th>
                          Status
                        </th>
                        <th>
                          Progresso
                        </th>
                        <th>
                          Criado em
                        </th>
                        <th>
                          Erros
                        </th>
                        <th>
                          Ações
                        </th>
                      </tr>
                    </thead>
                    <tbody className={styles.tableBody}>
                      {importStatusInProgress.map((is, i) => (
                        <tr key={i}>
                          <td>
                            {is?.id}
                          </td>
                          <td>
                            {is?.status === ShippingImportStatusEnum.IN_PROGRESS.value ? ShippingImportStatusEnum.IN_PROGRESS.label : is?.errors?.length > 0 ? "Com erro" : ShippingImportStatusEnum.DONE.label}
                          </td>
                          <td>
                            {
                              is?.shippings?.length + is?.errors?.length || 0
                            } / {
                              is?.status === ShippingImportStatusEnum.IN_PROGRESS.value
                                ? is?.total || 0
                                : is?.shippings?.length + is?.errors?.length || 0
                            }
                          </td>
                          <td>
                            {is?.created_at ? `${format(new Date(is?.created_at), "dd/MM/yyyy")}` : "-"}
                          </td>
                          <td>
                            {is?.errors?.length || 0}
                          </td>
                          <td>
                            {is?.errors?.length
                              ? <CsvDownload
                                data={is?.errors.map(i => {
                                  return {
                                    ...i?.object,
                                    Error: String(i?.errorObject?.error?.message)?.replace(",", "_")
                                      || "Erro não identificado, entre em contato com o suporte"
                                  }
                                })}
                                className={styles.errorImport}
                                filename={`Erros Planilha - ${is?.id || ""}.csv`}
                              >
                                <span>Rever erros</span>
                              </CsvDownload>
                              : <b>-</b>}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  {!loading && importStatusInProgress.length === 0 &&
                    <div className={styles.banner}>
                      <img
                        src={banner}
                        alt="Um desenho de uma figura feminina com um papel na mão a esquerda com telas de diversos dispostivos ao fundo na direita"
                      />
                      <span>Faça a importação	dos seus envios</span>
                    </div>
                  }
                </div>
              </InfiniteScroll>
            </ScrollDiv>
            :
            <ScrollDiv data={importStatusDone}>
              <InfiniteScroll
                dataLength={importStatusDone.length}
                next={fetchDataDone}
                hasMore={hasMore}
                loader={loadingInfinit && !loading && <LoadInfiniteScroll />}
                scrollableTarget="scrollDiv"
                className={styles.infiniteScroll}
              >
                <div className={styles.tableContainer}>
                  <table className={styles.table}>
                    <thead className={styles.tableHead}>
                      <tr>
                        <th>
                          ID
                        </th>
                        <th>
                          Status
                        </th>
                        <th>
                          Progresso
                        </th>
                        <th>
                          Criado em
                        </th>
                        <th>
                          Erros
                        </th>
                        <th>
                          Ações
                        </th>
                      </tr>
                    </thead>
                    <tbody className={styles.tableBody}>
                      {importStatusDone.map((is, i) => (
                        <tr key={i}>
                          <td>
                            {is?.id}
                          </td>
                          <td>
                            {is?.errors?.length > 0 ? "Com erro" : ShippingImportStatusEnum.DONE.label}
                          </td>
                          <td>
                            {is?.shippings?.length + is?.errors?.length || 0} / {is?.total || 0}
                          </td>
                          <td>
                            {is?.created_at ? `${format(new Date(is?.created_at), "dd/MM/yyyy")}` : "-"}
                          </td>
                          <td>
                            {is?.errors?.length || 0}
                          </td>
                          <td>
                            {is?.errors?.length
                              ? <CsvDownload
                                data={is?.errors.map(i => {
                                  return {
                                    ...i?.object,
                                    Error: String(i?.errorObject?.error?.message)?.replace(",", "_")
                                      || "Erro não identificado, entre em contato com o suporte"
                                  }
                                })}
                                className={styles.errorImport}
                                filename={`Erros Planilha - ${is?.id || ""}.csv`}
                              >
                                <span>Rever erros</span>
                              </CsvDownload>
                              : <b>-</b>}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  {!loading && !loadingInfinit && importStatusDone.length === 0 &&
                    <div className={styles.banner}>
                      <img
                        src={banner}
                        alt="Um desenho de uma figura feminina com um papel na mão a esquerda com telas de diversos dispostivos ao fundo na direita"
                      />
                      <span>Faça a importação	dos seus envios</span>
                    </div>
                  }
                </div>
              </InfiniteScroll>
            </ScrollDiv>
        }
      </div>
    </>
  );
};

export { ImportShipping };
