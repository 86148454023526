import { format } from "date-fns";
import { ptBR } from "date-fns/locale";
import React, { useState } from "react";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { IoMdInformation } from "react-icons/io";

import { GoogleMapsDashboard } from "../../../../../../components/GoogleMapsDashboard";
import { Loading } from "../../../../../../components/Loading";
import { useDidMountEffect } from "../../../../../../hooks/useDidMountEffect";
import { BrComerceApiService } from "../../../../../../services/BrComerceApiService";
import * as authDuck from "../../../../../../store/ducks/auth.duck";
import store from "../../../../../../store/store";
import { BarChart } from "../../partials/BarChart";
import { ChartBySla } from "../../partials/ChartBySla";
import { AdminFilters } from "../../partials/Filters/AdminFilters";
import { TableCompaniesMemoized } from "../../partials/TableCompanies";
import { TableStatusShippings } from "../../partials/TableStatusShippings";
import styles from "./dashboardAdmin.module.scss";
import { StyledTooltip } from "../../../../components/Tooltip";

const DashboardAdmin = () => {
  const api = new BrComerceApiService();
  const { auth } = store.getState();
  const { user } = auth;

  const [isLoading, setIsLoading] = useState(false);
  const [filters, setFilters] = useState(null);

  const [clientsOptions, setClientsOptions] = useState([]);

  const [statusTable, setStatusTable] = useState([]);
  const [barChart, setBarChart] = useState([]);
  const [chartBySLA, setChartBySLA] = useState(null);
  const [chartLocations, setChartLocations] = useState([]);
  const [tableByCompanies, setTableByCompanies] = useState([]);
  const [objectsWithError, setObjectsWithError] = useState(0);

  const [userWalletBalance, setUserWalletBalance] = useState(null);
  const [userBalance, setUserBalance] = useState(null);

  const handleLoadCharts = ({
    barChartByGranularity,
    chartBySLA,
    chartGeolocation,
    tableChartByCompanies
  }) => {
    setBarChart(barChartByGranularity);
    setChartBySLA(chartBySLA);
    setTableByCompanies(tableChartByCompanies);

    const handledGeolocations = [];
    if (chartGeolocation.length) {
      chartGeolocation.forEach(item => {
        if (item.amount > 1) {
          for (let i = 1; i <= item.amount; i++) {
            handledGeolocations.push({
              lat: Number(item.latitude),
              lng: Number(item.longitude)
            });
          }
        } else {
          handledGeolocations.push({
            lat: Number(item.latitude),
            lng: Number(item.longitude)
          });
        }
      });
    }

    setChartLocations(handledGeolocations);
  };

  const handleLoadTableStatus = response => {
    setStatusTable(response);
  };

  const loadCharts = async allFilters => {
    const listErrors = [];

    const loadChartsIndicatorsPromise = api.makeHttpRequest({
      method: "PUT",
      url: "/dashboard/adminIndicators/",
      data: allFilters
    });

    const statusTableFilters = {
      initialDate: allFilters.initialDate,
      endDate: allFilters.endDate,
      clientSub: allFilters.clientSub,
      status: allFilters.status
    };

    const loadTableStatusPromise = api.makeHttpRequest({
      method: "PUT",
      url: "/dashboard/shippingStatus/",
      data: statusTableFilters
    });

    const [
      loadedTableStatus,
      loadedChartsIndicators
    ] = await Promise.allSettled([
      loadTableStatusPromise,
      loadChartsIndicatorsPromise
    ]);

    if (loadedTableStatus.status === "fulfilled") {
      handleLoadTableStatus(loadedTableStatus.value);
    } else {
      listErrors.push("Erro ao carregar tabela com status das entregas");
    }
    if (loadedChartsIndicators.status === "fulfilled") {
      handleLoadCharts(loadedChartsIndicators.value);
    } else {
      listErrors.push("Erro ao carregar indicadores");
    }

    if (!!listErrors.length) {
      throw listErrors;
    }
  };

  const loadAllCompanies = async () => {
    try {
      const loadCompanies = await api.makeHttpRequest({
        method: "GET",
        url: "/users/list-companies/"
      });

      setClientsOptions(
        loadCompanies.map(user => ({
          label: user.name,
          value: user?.id
        }))
      );
    } catch (e) {
      throw new Error(["Ocorreu um erro ao carregar usuários"]);
    }
  };

  const loadUserBalance = async () => {
    try {
      const result = await api.makeHttpRequest({
        method: "GET",
        url: "/users/me/"
      });

      store.dispatch(authDuck.actions.fulfillUser(result));

      if (result.walletBalance) {
        setUserWalletBalance(Number(result.walletBalance));
      }
      if (result.balance) {
        setUserBalance(Number(result.balance));
      }
    } catch (e) {
      throw new Error(["Ocorreu um erro ao carregar usuários"]);
    }
  };

  // const loadCountObjectsWithError = async () => {
  //   try {
  //     const result = await api.makeHttpRequest({
  //       method: "GET",
  //       url: "/dashboard/indicator/count-process-error",
  //     })
  //     setObjectsWithError(result)
  //   } catch (error) {
  //     throw new Error(["Ocorreu um erro ao carregar objetos com error"]);
  //   }
  // }

  useDidMountEffect(() => {
    (async () => {
      try {
        setIsLoading(true);

        // await loadCountObjectsWithError();
        await loadCharts(filters);
        await loadAllCompanies();
        await loadUserBalance();
      } catch (e) {
        console.error(e);
        if (e.length) {
          Swal.fire({
            icon: "error",
            title: "Dashboard",
            text: `Ocorreu um erro ao carregar:\n ${e.join(", ")}`
          });
          return;
        }
        Swal.fire({
          icon: "error",
          title: "Dashboard",
          text: `Ocorreu um erro ao carregar o dashboard`
        });
      } finally {
        setIsLoading(false);
      }
    })();
  }, [filters]);

  return (
    <section className={styles.main}>
      <Loading loading={isLoading} />
      <span>
        {format(new Date(), "EEEE, dd 'de' MMMM 'de' yyyy", {
          locale: ptBR
        })}
      </span>
      <h1>Bem vindo(a){user?.name ? `, ${user.name}` : "."}</h1>
      <div className={styles.walletBalance}>
        {userBalance > 0 ? (
          <StyledTooltip
            text="Seu crédito pré-aprovado junto à BRCom para contratar fretes de forma faturada. Modalidade voltada para clientes
            com grande volume e recorrência de uso"
            position="bottom"
            arrow
          >
            <span style={{ cursor: "default" }}>
              Seu crédito faturado:
              <b>
                {userBalance !== null
                  ? `${
                      userBalance < 0
                        ? `-${Intl.NumberFormat("pt-BR", {
                            style: "currency",
                            currency: "BRL"
                          }).format(Number(userBalance * -1))}`
                        : `${Intl.NumberFormat("pt-BR", {
                            style: "currency",
                            currency: "BRL"
                          }).format(Number(userBalance))}`
                    }`
                  : "R$ 0.00"}
              </b>
            </span>
          </StyledTooltip>
        ) : (
          <StyledTooltip
            text="Depositado para contratar fretes de forma avulsa. Voltado para clientes que ainda estão escalando seu volume,
            ou fazem envios esporádicos."
            position="bottom"
            arrow
          >
            <span style={{ cursor: "default" }}>
              Seu saldo:
              <b>
                {userWalletBalance !== null
                  ? `${
                      userWalletBalance < 0
                        ? `-${Intl.NumberFormat("pt-BR", {
                            style: "currency",
                            currency: "BRL"
                          }).format(Number(userWalletBalance * -1))}`
                        : `R$ ${Intl.NumberFormat("pt-BR", {
                            style: "currency",
                            currency: "BRL"
                          }).format(Number(userWalletBalance))}`
                    }`
                  : "R$ 0.00"}
              </b>
            </span>
          </StyledTooltip>
        )}

        <Link to={"extrato-conta"}>Ver extrato</Link>
      </div>
      <Container fluid className="p-0">
        {chartBySLA ? (
          <ChartBySla
            data={chartBySLA}
            initialDateToRedirect={filters.initialDate}
            endDateToRedirect={filters.endDate}
            countObjectsWithError={objectsWithError}
          />
        ) : (
          <Row className={`${styles.cardsStatus}`}>
            <Col xs={12} className={styles.anErrorOcurred}>
              {isLoading ? (
                <Spinner animation="border" role="status">
                  <span style={{ visibility: "hidden" }}>Loading...</span>
                </Spinner>
              ) : (
                <p>Ocorreu um erro ao carregar indicadores.</p>
              )}
            </Col>
          </Row>
        )}
      </Container>

      <Container as="section" fluid className={styles.indicatorsCharts}>
        <AdminFilters
          onFilter={newFilters => {
            setFilters(newFilters);
          }}
          onResetFilter={oldFilters => setFilters(oldFilters)}
          clientsOptions={clientsOptions}
        />

        <Row>
          <Col xs={12} sm={4} style={{ marginTop: 16, marginBottom: 16 }}>
            {!!statusTable.length && statusTable.length > 1 ? (
              <TableStatusShippings
                statusTable={statusTable}
                initialDateToRedirect={filters.initialDate}
                endDateToRedirect={filters.endDate}
              />
            ) : (
              <div className={styles.anErrorOcurred}>
                {isLoading ? (
                  <Spinner animation="border" role="status">
                    <span style={{ visibility: "hidden" }}>Loading...</span>
                  </Spinner>
                ) : (
                  <p>Não foram encontrado dados para estes filtro.</p>
                )}
              </div>
            )}
          </Col>

          <Col xs={12} sm={8} style={{ marginTop: 16, marginBottom: 16 }}>
            <div className={styles.barChartWrapper}>
              {!!barChart.length ? (
                <BarChart
                  data={barChart}
                  monthSelected={filters && filters.month}
                  granularitySelected={filters && filters.granularity}
                />
              ) : (
                <div className={styles.anErrorOcurred}>
                  {isLoading ? (
                    <Spinner animation="border" role="status">
                      <span style={{ visibility: "hidden" }}>Loading...</span>
                    </Spinner>
                  ) : (
                    <p>Ocorreu um erro ao carregar o gráfico.</p>
                  )}
                </div>
              )}
            </div>
          </Col>
        </Row>
      </Container>

      <Container fluid className={styles.indicatorsCharts}>
        <Row>
          <Col xs={12} sm={4} className={styles.mapWrapper}>
            {!isLoading ? (
              <GoogleMapsDashboard zoom={4} locations={chartLocations} />
            ) : (
              <div className={styles.anErrorOcurred}>
                <Spinner animation="border" role="status">
                  <span style={{ visibility: "hidden" }}>Loading...</span>
                </Spinner>
              </div>
            )}
          </Col>

          <Col xs={12} sm={8} className={styles.tableWrapper}>
            {!!tableByCompanies.length ? (
              <TableCompaniesMemoized tableByCompanies={tableByCompanies} />
            ) : (
              <div className={styles.anErrorOcurred}>
                {isLoading ? (
                  <Spinner animation="border" role="status">
                    <span style={{ visibility: "hidden" }}>Loading...</span>
                  </Spinner>
                ) : (
                  <p>Não foram encontrado dados para estes filtro.</p>
                )}
              </div>
            )}
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export { DashboardAdmin };
