export const orderStatuses = [
  {
    label: "Em aberto",
    value: "aberto",
  },
  {
    label: "Aprovado",
    value: "aprovado"
  },
  {
    label: "Preparando envio",
    value: "preparando_envio"
  },
  {
    label: "Faturado (atendido)",
    value: "faturado"
  },
  {
    label: "Pronto para envio",
    value: "pronto_envio"
  }
]
