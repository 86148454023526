import React, { useCallback, useEffect, useState } from "react";
import { FiSquare } from "react-icons/fi";
import Swal from "sweetalert2";

import ErrorModal from "../../../../components/ErrorModal";
import GreenClockModal from "../../../../components/GreenClockModal";
import SuccessModal from "../../../../components/SuccessModal";
import { PaymentMethodEnum } from "../../../../enums/PaymentMethodEnum";
import { RateTypeShippingEnum } from "../../../../enums/RateTypeShippingEnum";
import { BrComerceApiService } from "../../../../services/BrComerceApiService";
import * as authDuck from "../../../../store/ducks/auth.duck";
import store from "../../../../store/store";
import CardPayment from "../../components/CardPayment";
import styles from "./styles.module.scss";

const RetryPayment = ({
  packagesSelected,
  total,
  collectId,
  setLoading,
  setGoToPayment,
  handleCollectSelection,
}) => {
  const api = new BrComerceApiService();
  const { auth: { user } } = store.getState();

  const [paymentMethod, setPaymentMethod] = useState("");
  const [greenClockModal, setGreenClockModal] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [responseCollectId, setResponseCollectId] = useState(null);
  const [errorModal, setErrorModal] = useState(false);
  const [paymentError, setPaymentError] = useState(null);
  const [balance, setBalance] = useState(0);
  const [walletBalance, setWalletBalance] = useState(0);
  const [retailLimit, setRetailLimit] = useState({
    retailLimit: 0,
    unvalidatedPackages: 0,
    hasLimit: false,
    avaliableLimit: 0
  });

  const paymentMethodsEnabled = process.env.REACT_APP_PAYMENT_METHODS_ENABLED === "true";

  const useWalletMethod = total > 0 && total <= walletBalance;

  const handlePaymentButton = useCallback(async () => {

    if (!user?.canCreateCollectSchedule && !user?.isVerified) {
      try {
        setLoading(true)
        const { avaliableLimit, hasLimit, unvalidatedPackages } = await api.makeHttpRequest({
          method: "GET",
          url: `collects/retail-limit`
        });

        setLoading(false)

        if (!hasLimit) {
          Swal.fire({
            title: `Existem ${unvalidatedPackages} pacotes pendentes de envio!`,
            text: `Para prosseguir realize a postagem dos pacotes nas agências dos Correios de sua preferência`,
            icon: "error"
          });
          return;
        }

        if (packagesSelected[0].rateType === RateTypeShippingEnum.RETAIL
          && packagesSelected.length > avaliableLimit) {
          Swal.fire({
            title:
              "Limite de pacotes não postados atingido.",
            html: `Sua conta possui ${avaliableLimit} ${avaliableLimit > 1 ? "pacotes" : "pacote"} não ${avaliableLimit > 1 ? "postados disponíveis" : "postado disponível"}, desmarque alguns itens e tente novamente...`,
            icon: "warning",
            confirmButtonText: "Ok"
          });
          return;
        };
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    }

    if (total === 0) {
      Swal.fire({
        title: "Nenhum item selecionado",
        text: "Selecione itens a serem pagos.",
        icon: "warning"
      });
      return;
    }

    const hasNotBalanceForMethod = (method, methodBalance) => {
      return paymentMethod === method && Number(total) > Number(methodBalance);
    }

    if (hasNotBalanceForMethod(PaymentMethodEnum.BILLING, balance)) {
      Swal.fire({
        title: "Saldo insuficiente.",
        text: "Você não possui saldo o suficiente na sua conta.",
        icon: "warning"
      });
      return;
    }

    if (hasNotBalanceForMethod(PaymentMethodEnum.WALLET, walletBalance)) {
      Swal.fire({
        title: "Saldo insuficiente.",
        text: "Você não possui saldo o suficiente na sua conta.",
        icon: "warning"
      });
      return;
    }

    if (paymentMethod !== PaymentMethodEnum.WALLET && useWalletMethod) {
      Swal.fire({
        title: "Alteração na forma de pagamento.",
        text: "Você possui saldo suficiente na carteira para efetuar esse pagamento.",
        icon: "info"
      });
      setPaymentMethod(PaymentMethodEnum.WALLET);
    }

    if (packagesSelected.length === 0) {
      Swal.fire({
        title: "Nenhum item selecionado",
        text: "Selecione itens a serem pagos.",
        icon: "warning"
      });
      return;
    }

    try {
      setGreenClockModal(true);

      const data = {
        method: paymentMethod
      };

      await api.makeHttpRequest({
        method: "PUT",
        url: `/collects/${collectId}/retry-payment`,
        data
      });

      const collectResponse = await api.makeHttpRequest({
        method: "GET",
        url: `/collects/${collectId}`
      });

      if (walletBalance != 0) {
        const updatedUser = await api.makeHttpRequest({
          method: 'GET',
          url: '/users/me'
        });
        store.dispatch(authDuck.actions.fulfillUser(updatedUser));
      };

      await handleCollectSelection(collectResponse);

      setGoToPayment(false);
    } catch (error) {
      setPaymentError(error?.response?.status);
      setErrorModal(true);
    } finally {
      setGreenClockModal(false);
    }
  }, [
    total,
    balance,
    paymentMethod,
    walletBalance,
    packagesSelected.length,
    api,
    collectId,
    handleCollectSelection,
    setGoToPayment
  ]);

  const renderCurrencyFormat = (value) => {
    return (
      <span>
        {new Intl.NumberFormat("pt-BR", {
          style: "currency",
          currency: "BRL"
        }).format(value)}
      </span>
    )
  }

  const renderWalletDischarge = () => {
    return (
      <span>
        {renderCurrencyFormat(total)}
        {walletBalance > 0 ? " - " : " + "}
        {renderCurrencyFormat(walletBalance > 0 ? walletBalance : walletBalance * -1)}
        <br />
        <p>Seu saldo será abatido nesse pagamento.</p>
      </span>
    )
  }

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const response = await api.makeHttpRequest({
          method: "GET",
          url: `collects/${collectId}`
        });

        const user = await api.makeHttpRequest({
          method: "GET",
          url: `users/me`
        });

        await api.makeHttpRequest({
          method: "GET",
          url: "/collects/retail-limit"
        }).then(r => setRetailLimit(r)).catch(e => console.log(e))

        setBalance(user.balance);

        if(user.walletBalance !== null) {
          setWalletBalance(user.walletBalance);
        }

        let method

        if (user.walletBalance >= total) {
          method = PaymentMethodEnum.WALLET;
        } else {
          switch (response?.invoice?.method) {
            case PaymentMethodEnum.CREDIT_CARD:
              method = PaymentMethodEnum.CARTAO;
              break;

            case PaymentMethodEnum.PIX:
              method = PaymentMethodEnum.PIX;
              break;

            default:
              method = PaymentMethodEnum.BILLING;
          }
        }

        setPaymentMethod(method);
      } catch (e) {
        Swal.fire({
          title: "Erro ao carregar!",
          text: "Houve um erro ao carregar o crédito disponível para sua conta",
          icon: "error"
        });
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  return (
    <>
      {greenClockModal && <GreenClockModal />}

      {successModal && (
        <SuccessModal
          responseCollectId={responseCollectId}
          paymentMethod={paymentMethod}
        />
      )}

      {errorModal && (
        <ErrorModal
          setErrorModal={setErrorModal}
          collectResponseId={responseCollectId}
          paymentError={paymentError}
        />
      )}

      <div className={styles.paymentTitle}>
        <h2>Resumo da seleção</h2>
      </div>
      <div className={styles.paymentTotal}>
        <p>
          {packagesSelected.length > 0 ? (
            <>
              Você tem <span>{packagesSelected.length}</span>
              {` ${packagesSelected.length > 1 ? "envios" : "envio"}`}
            </>
          ) : (
            "Você não selecionou nenhum envio"
          )}
        </p>
        <p>
          <strong>Total:</strong>&nbsp;
          {walletBalance != 0
            && total > 0
            && walletBalance < total
              ? renderWalletDischarge()
              : renderCurrencyFormat(total)
          }
        </p>
        {!user?.canCreateCollectSchedule && !user?.isVerified && (
          <div className={`${styles.instructionRetailLimitTxt} ${retailLimit.hasLimit ? `${styles.hasRetailLimit}` : ""}`}>
            <p>
              Sua conta possui <b>{retailLimit.unvalidatedPackages}/{retailLimit.retailLimit}</b> objetos aguardando postagem, caso atinja o limite, poste os objetos nas Agências dos Correios de sua preferência para poder prosseguir com os próximos.
            </p>
          </div>
        )}
      </div>
      <div className={styles.paymentTitle}>
        <h2>Selecione a forma de pagamento</h2>
      </div>
      <div className={styles.paymentOpts}>
        {useWalletMethod ?
            <div className={styles.paymentOpt}>
              <div className={styles.check} onClick={() => setPaymentMethod(PaymentMethodEnum.WALLET)}>
                {!(paymentMethod === PaymentMethodEnum.WALLET) ? (
                  <div />
                  ) : (
                    <div>
                    <FiSquare />
                  </div>
                )}
              </div>
              <p>Carteira</p>
            </div>
          : <>
            {/* <div className={`${styles.paymentOpt} ${styles.disabled}`}>
              <div
                className={styles.check}
                onClick={() => setPaymentMethod(PaymentMethodEnum.BOLETO)}
              >
                {!(paymentMethod === PaymentMethodEnum.BOLETO) ? (
                  <div />
                ) : (
                  <div>
                    <FiSquare />
                  </div>
                )}
              </div>
              <p>Boleto (à vista)</p>
            </div> */}
            {paymentMethodsEnabled && (
              <>
                <div className={styles.paymentOpt}>
                  <div className={styles.check} onClick={() => setPaymentMethod(PaymentMethodEnum.PIX)}>
                    {!(paymentMethod === PaymentMethodEnum.PIX) ? (
                      <div />
                    ) : (
                      <div>
                        <FiSquare />
                      </div>
                    )}
                  </div>
                  <p>PIX</p>
                </div>

                <div className={styles.paymentOpt}>
                  <div className={styles.check} onClick={() => setPaymentMethod(PaymentMethodEnum.CARTAO)}>
                    {!(paymentMethod === PaymentMethodEnum.CARTAO) ? (
                      <div />
                    ) : (
                      <div>
                        <FiSquare />
                      </div>
                    )}
                  </div>
                  <p>Cartão de Crédito</p>
                </div>
              </>
            )}
            {
              (user?.creditLimit > 0 || !paymentMethodsEnabled) && (
                <div className={styles.paymentOpt}>
                  <div
                    className={styles.check}
                    onClick={() => setPaymentMethod(PaymentMethodEnum.BILLING)}
                  >
                    {!(paymentMethod === PaymentMethodEnum.BILLING) ? (
                      <div />
                    ) : (
                      <div>
                        <FiSquare />
                      </div>
                    )}
                  </div>
                  <p>Faturado</p>
                </div>
              )
            }
          </>
        }
      </div>
      <div className={styles.paymentMethodDetails}>
        {paymentMethod === PaymentMethodEnum.BILLING && (
          <div className={styles.paymentBilled}>
            <div>
              <p>
                Crédito Disponível{" "}
                {renderCurrencyFormat(balance)}{" "}
              </p>
            </div>
            <p>
              Seu limite de crédito pré-aprovado pode ser revisado em contato
              com nossa equipe comercial.
            </p>
          </div>
        )}
        {paymentMethod === PaymentMethodEnum.WALLET && (
          <div className={styles.paymentBilled}>
            <div>
              <p>
                Saldo Disponível{" "}
                {renderCurrencyFormat(walletBalance)}{" "}
              </p>
              <br />
            </div>
            <p>
              Saldo gerado a partir da conciliação dos seus pacotes conferidos anteriormente.
            </p>
          </div>
        )}
        {paymentMethod === PaymentMethodEnum.CARTAO && (
          <CardPayment
            total={walletBalance != 0 && !useWalletMethod ? total - walletBalance : total}
            setLoading={setLoading}
            packagesSelected={packagesSelected}
            greenClockModal={greenClockModal}
            setGreenClockModal={setGreenClockModal}
            successModal={successModal}
            setSuccessModal={setSuccessModal}
            errorModal={errorModal}
            setErrorModal={setErrorModal}
            collectId={collectId}
            setGoToPayment={setGoToPayment}
            handleCollectSelection={handleCollectSelection}
            setPaymentError={setPaymentError}
            setResponseCollectId={setResponseCollectId}
          />
        )}
        {paymentMethod === PaymentMethodEnum.PIX && (
          <div className={styles.paymentPix}>
            <p>
              Siga para a próxima tela para copiar o código Pix ou para efetuar
              o pagamento via QR Code.
            </p>
            {!useWalletMethod && walletBalance != 0 && (
              <p>
                O saldo da carteira só será alterado após confirmação de pagamento
              </p>
            )}
          </div>
        )}
        {paymentMethod !== PaymentMethodEnum.CARTAO && (
          <div className={styles.buttonsContainerPayment}>
            <button
              className={`${styles.buttonPayment} ${!total &&
                styles.buttonDisabled}`}
              onClick={handlePaymentButton}
            >
              {total
                ? `Pagar ${new Intl.NumberFormat("pt-BR", {
                    style: "currency",
                    currency: "BRL"
                  }).format(walletBalance != 0 && !useWalletMethod ? total - walletBalance : total)}`
                : "Você não selecionou nenhum envio"}
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default RetryPayment;
