import React, { useCallback, useState, useEffect } from "react";
import { AiOutlineCheck } from "react-icons/ai";
import { BsExclamation } from "react-icons/bs";
import { useHistory } from "react-router-dom";
import { BrComerceApiService } from "../../../../../services/BrComerceApiService";
import styles from "./styles.module.scss";
import { Loading } from "../../../../../components/Loading";
import Swal from "sweetalert2";
import Container from "../../../components/Container";
import InputSelectAsync from "../../../../../components/InputSelectAsync";

const EditRoutes = () => {
  const api = new BrComerceApiService();
  const history = useHistory();
  window.setPageTitle("Editar Rota");

  const [route, setRoute] = useState({});
  const [routeId, setRouteId] = useState();

  const [driver, setDriver] = useState();
  const [selectedDriver, setSelectedDriver] = useState(null);
  const [selectedDriverOK, setSelectedDriverOK] = useState("vazio");
  const [selectedDriverError, setSelectedDriverError] = useState("");

  const [loading, setLoading] = useState(false);

  const handleFormSubmit = useCallback(
    async e => {
      e.preventDefault();
      if (!selectedDriver || !selectedDriver?.value) {
        setSelectedDriverError("Campo obrigatório");
        setSelectedDriverOK("notOk");
      }
      if (
        selectedDriverOK === "notOk"
      ) {
        return;
      }

      try {
        setLoading(true);
        let data = {
          driverSub: selectedDriver?.sub,
        };

        await api.makeHttpRequest({
          method: "PUT",
          url: `/route-admin/${routeId}`,
          data
        });

        Swal.fire({
          title: "Sucesso!",
          text: "Rota editada com sucesso!",
          icon: "success",
          showCancelButton: false,
          confirmButtonText: "Ok",
          confirmButtonColor: "#409E6A"
        }).then(() => {
          history.push("/listagem-rotas");
        });
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Erro",
          text: "Não foi possível editar a rota!",
          confirmButtonText: "Ok",
          confirmButtonColor: "#CA1818"
        });
      } finally {
        setLoading(false);
      }
    },
    [
      selectedDriver,
      selectedDriverOK,
      api,
      history,
    ]
  );

  useEffect(() => {
    if (routeId) {
      setLoading(true);
      api
        .makeHttpRequest({
          method: "GET",
          url: `route-admin/${routeId}`,
          params: {
            page: 1,
            resultsPerPage: 0,
          }
        })
        .then(response => {
          setRoute(response?.route);
          setLoading(false);
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [routeId]);

  useEffect(() => {
    setRouteId(history.location.pathname.split("/")[2]);
  }, [history]);

  useEffect(() => {
    if (route) {
      if (route?.status?.id && route?.status?.id !== 1) {
        Swal.fire({
          icon: "error",
          title: "Erro",
          text: "A rota não pode ser alterada após ser iniciada!",
          confirmButtonText: "Ok",
          confirmButtonColor: "#CA1818"
        }).then(() => history.push("/listagem-rotas"));
      }
      setLoading(true);
      const driver = route?.driver

      if (driver) {
        setDriver({
          label: driver?.name,
          sub: driver?.sub,
          value: driver?.id,
        })
        setSelectedDriverError("")
        setSelectedDriverOK("ok")
      }

      setLoading(false);
    }
  }, [route]);

  useEffect(() => {
    if (selectedDriver) {
      setSelectedDriverOK("ok")
      setSelectedDriverError("")
    } else {
      setSelectedDriverOK("vazio")
    }
  }, [selectedDriver])

  return (
    <>
      <Loading loading={loading} />

      <Container title={"Editar rota"}>

        <form className={styles.form} onSubmit={handleFormSubmit}>
          <div className={styles.formContent}>

            <h4>Rota n° {routeId}</h4>

            {route && <div className={styles.row}>
              <div className={styles.infoCard}>
                <div className={styles.shortInfos}>
                  {route?.status?.name && <div className={styles.info}>
                    <strong>Status:</strong>
                    <span>{route?.status?.name ?? ""}</span>
                  </div>}
                  {route?.routing?.orderShippings?.length && <div className={styles.info}>
                    <strong>Pacotes:</strong>
                    <span>{route?.routing?.orderShippings?.length ?? 0}</span>
                  </div>}
                </div>
                <div className={styles.shortInfos}>
                  <div className={styles.inputContainer}>
                    <strong>Origem</strong>
                    <div
                      className={`${styles.input} ${styles.disabled}`}
                    >
                      <input
                        type="text"
                        name="origin"
                        placeholder="Origem"
                        value={route?.origin_destination?.origin?.address ?? ""}
                        defaultValue={""}
                        disabled
                      />
                    </div>
                  </div>
                  <div className={styles.inputContainer}>
                    <strong>Destino</strong>
                    <div
                      className={`${styles.input} ${styles.disabled}`}
                    >
                      <input
                        type="text"
                        name="destination"
                        placeholder="Destino"
                        value={route?.origin_destination?.destination?.address ?? ""}
                        defaultValue={""}
                        disabled
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>}

            <div className={`${styles.row} ${styles.driverInput}`}>
              <div className={`${styles.inputContainer}`}>
              <strong>Motorista</strong>
                <div
                  role={selectedDriverOK}
                  className={`${styles.selectInput}`}
                >
                  <InputSelectAsync
                    userParenting={driver}
                    search={selectedDriver}
                    setSearch={setSelectedDriver}
                    listUserOrDriver={"driver"}
                    placeholder={"Selecione o motorista"}
                  />
                  <BsExclamation className={styles.danger} role={selectedDriverOK} />
                </div>
                <p className={styles.error}>{selectedDriverError}</p>
              </div>
            </div>

          </div>
          <div className={styles.buttonsContainer}>
            <div className={styles.buttonContainerBack}>
              <button type="button" onClick={() => history.push("/listagem-rotas")}>
                Voltar
              </button>
            </div>
            <div className={styles.buttonContainer}>
              <button type="submit" disabled={selectedDriverOK === "notOk" || !selectedDriver}>{"Atualizar"}</button>
            </div>
          </div>
        </form>
      </Container>
    </>
  );
};
export { EditRoutes };



