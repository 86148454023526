import React, { useState, useMemo, useCallback, useEffect } from "react";
import Select from "react-select";
import InfiniteScroll from "react-infinite-scroll-component";
import Switch from "react-switch";
import Swal from "sweetalert2";

import { FaPlusCircle } from "react-icons/fa";
import { FiEdit3 } from "react-icons/fi";

import { Link, useHistory } from "react-router-dom";

import { UFsEnum } from "../../../../enums/UFsEnum";
import { Loading } from "../../../../components/Loading";
import Container from "../../components/Container";
import ScrollDiv from "../../components/ScrollDiv";
import LoadInfiniteScroll from "../../../../components/LoadInfiniteScroll";
import { BrComerceApiService } from "../../../../services/BrComerceApiService";
import debounce from "../../../../utils/debounce";
import styles from "./styles.module.scss";
import { selectStyle } from "./selectStyle";
import { StyledTooltip } from "../../components/Tooltip";

const PricePolicy = () => {
  const api = new BrComerceApiService();
  const history = useHistory();

  const [rateType, setRateType] = useState("");
  const [shippingType, setShippingType] = useState("");
  const [ufOrigin, setUfOrigin] = useState("");
  const [ufDestination, setUfDestination] = useState("");

  const [loading, setLoading] = useState(false);
  const [loadingInfinit, setLoadingInfinit] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [actualPage, setActualPage] = useState(0);
  const [policies, setPolicies] = useState([]);

  const getOptionsUFs = useMemo(() => {
    const optionsHandled = Object.entries(UFsEnum).map(([key, value]) => {
      return {
        value: value,
        label: value
      };
    });
    return optionsHandled;
  }, []);

  const getPolicies = useCallback(
    debounce(
      async (
        ufOrigin,
        ufDestination,
        rateType,
        shippingType,
        loading = true
      ) => {
        setLoading(loading);
        try {
          const response = await api.makeHttpRequest({
            method: "GET",
            url: "/price-policy",
            params: {
              resultsPerPage: 10,
              page: 1,
              ufOrigin: ufOrigin?.value,
              ufDestination: ufDestination?.value,
              shippingType: shippingType?.valeu,
              rateType: rateType?.value
            }
          });
          setPolicies(response.results);
          setActualPage(response.actualPage);
        } catch (e) {
          console.log(e);
        } finally {
          setLoading(false);
        }
      },
      [
        api,
        ufOrigin?.value,
        ufDestination?.value,
        rateType?.value,
        shippingType?.value
      ]
    )
  );

  const handleFetchData = useCallback(
    debounce(async () => {
      setLoadingInfinit(true);
      try {
        const response = await api.makeHttpRequest({
          method: "GET",
          url: "/price-policy",
          params: {
            resultsPerPage: 10,
            page: actualPage + 1,
            ufOrigin: ufOrigin?.value,
            ufDestination: ufDestination?.value,
            shippingType: shippingType?.value,
            rateType: rateType?.value
          }
        });
        setPolicies([...policies, ...response?.results]);
        setActualPage(response.actualPage);
      } catch (e) {
        console.log(e);
      } finally {
        setLoadingInfinit(false);
        setLoading(false);
      }
    }, [api, actualPage, ufOrigin, ufDestination, rateType, shippingType])
  );

  const handleAction = useCallback(
    debounce(
      async (policy, action) => {
        setLoading(true);

        try {
          await api.makeHttpRequest({
            method: "PUT",
            url: `/price-policy/active/${policy.id}`
          });
          Swal.fire({
            icon: "success",
            title: "Sucesso",
            text: `${action ? "Desativado" : "Ativado"} com sucesso`
          });
        } catch (e) {
          setLoading(false);
          Swal.fire({
            icon: "error",
            title: "Erro",
            text: e.response.data.message.message
          });
          console.log(e);
        } finally {
          setLoading(false);
          getPolicies(ufOrigin, ufDestination, rateType, shippingType, false);
        }
      },
      [api]
    )
  );

  const shippingTypeOptions = [
    {
      label: "Normal",
      value: "normal"
    },
    {
      label: "Reverso",
      value: "reverse"
    }
  ];
  const rateTypeOptions = [
    {
      label: "Industrial",
      value: "industrial"
    },
    {
      label: "Varejo",
      value: "retail"
    }
  ];

  useEffect(() => {
    window.setPageTitle("Política de Preço");
  }, []);

  useEffect(() => {
    getPolicies(ufOrigin, ufDestination, rateType, shippingType);
  }, [
    ufOrigin?.value,
    ufDestination?.value,
    rateType?.value,
    shippingType?.value
  ]);

  return (
    <>
      <Loading loading={loading} />
      <Container title="Política de Preço">
        <div className={styles.filtersWrapper}>
          <div className={styles.filterInput}>
            <Select
              onChange={e => setUfOrigin(e)}
              options={getOptionsUFs}
              value={ufOrigin}
              isClearable
              placeholder="Filtrar por UF de origem"
              styles={selectStyle}
            />
          </div>
          <div className={styles.filterInput}>
            <Select
              onChange={e => setUfDestination(e)}
              options={getOptionsUFs}
              value={ufDestination}
              isClearable
              placeholder="Filtrar por UF de destino"
              styles={selectStyle}
            />
          </div>
          <div className={styles.filterInput}>
            <Select
              onChange={e => setShippingType(e)}
              options={shippingTypeOptions}
              value={shippingType}
              isClearable
              placeholder="Filtrar por tipo de envio"
              styles={selectStyle}
            />
          </div>
          <div className={styles.filterInput}>
            <Select
              onChange={e => setRateType(e)}
              options={rateTypeOptions}
              value={rateType}
              isClearable
              placeholder="Filtrar por tipo de cotação"
              styles={selectStyle}
            />
          </div>
          <Link to="/politica-de-preco/create">
            <button type="button" className={styles.filterBtn}>
              <FaPlusCircle size={16} color="#FFFFFF" />
              <span>Adicionar nova política</span>
            </button>
          </Link>
        </div>
        <div className={styles.tableWrapper}>
          <ScrollDiv data={policies}>
            <InfiniteScroll
              dataLength={policies.length}
              next={handleFetchData}
              hasMore={hasMore}
              loader={loadingInfinit && <LoadInfiniteScroll />}
              scrollableTarget="scrollDiv"
            >
              <div className={styles.tableContainer}>
                <table className={styles.table}>
                  <thead className={styles.tableHead}>
                    <tr>
                      <th>Ações</th>
                      <th>Abrangência</th>
                      <th>Origem</th>
                      <th>Destino</th>
                      <th>Tipo de envio</th>
                      <th>Tipo de cotação</th>
                      <th>Coleta</th>
                      <th>Valor (%)</th>
                      <th>Tipo</th>
                    </tr>
                  </thead>
                  <tbody className={styles.tableBody}>
                    {policies.map(policy => (
                      <tr key={policy.id}>
                        <td>
                          <div className={styles.actions}>
                            <Switch
                              checked={policy.active}
                              onChange={() =>
                                handleAction(policy, policy.active)
                              }
                              offColor="#B3B3B3"
                              onColor="#409E6A"
                              offHandleColor="#FFF"
                              onHandleColor="#FFF"
                              uncheckedIcon={false}
                              checkedIcon={false}
                              width={45}
                              height={23}
                              handleDiameter={13}
                            />
                            <StyledTooltip text="Editar" position="top" arrow>
                              <button
                                onClick={() =>
                                  history.push(
                                    `/politica-de-preco/${policy.id}`
                                  )
                                }
                              >
                                <FiEdit3 size={20} color="#409E6A" />
                              </button>
                            </StyledTooltip>
                            {/* <StyledTooltip text="Deletar" position="top" arrow>
                              <button
                                onClick={() => handleDeletePolicy(policy.id)}
                              >
                                <RiDeleteBinLine size={22} color="#409E6A" />
                              </button>
                            </StyledTooltip>  */}
                          </div>
                        </td>
                        <td>
                          {policy.scope === "specific"
                            ? "Específico"
                            : "Global"}
                        </td>
                        <td>{policy.uf_origin || "--"}</td>
                        <td>{policy.uf_destination || "--"}</td>
                        <td>
                          {policy.shipping_type === "normal"
                            ? "Normal"
                            : "Reverso"}
                        </td>
                        <td>
                          {policy.rate_type === "industrial"
                            ? "Industrial"
                            : "Varejo"}
                        </td>
                        <td>{policy.is_collect ? "Sim" : "Não"}</td>
                        <td>{`${policy.percentage}%`}</td>
                        <td>
                          {policy.type === "increase"
                            ? "Acréscimo"
                            : "Desconto"}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </InfiniteScroll>
          </ScrollDiv>
        </div>
      </Container>
    </>
  );
};
export { PricePolicy };
