export const selectStyle = {
  control: (styles, { isDisabled }) => ({
    ...styles,
    backgroundColor: isDisabled ? "#F2F2F2" : "#fff",
    width: "100%",
    border: "1px solid #c2c6ca",
    boxShadow: "none",
    outline: "none",
    color: "#4d4c4d",
    padding: "0 10px",
    "&:hover": {
      borderColor: "none"
    },
    maxHeight: "51px",
    minHeight: "51px",
    borderRadius: "12px",
    cursor: "pointer"
  }),
  container: styles => ({
    ...styles,
    width: "100%",
    outline: "none",
    border: "none",
    padding: "0",
    cursor: "pointer"
  }),
  indicatorSeparator: styles => ({
    ...styles,
    display: "none",
    border: "none"
  }),
  option: (styles, { isSelected }) => ({
    ...styles,
    fontSize: "14px",
    fontFamily: "Mulish",
    fontWeight: 400,
    color: isSelected ? "#fff" : "#807E80",
    paddingLeft: "20px",
    "&:hover": {
      backgroundColor: "#EFEFEF",
      color: "#333333",
      cursor: "pointer"
    },
    "&:focus": {
      backgroundColor: "#EFEFEF",
      color: "#333333",
      cursor: "pointer"
    },
    backgroundColor: isSelected ? "#409e6a" : "#fff"
  }),
  singleValue: styles => ({
    ...styles,
    color: "#807E80",
    fontSize: "14px",
    fontWeight: 400
  }),
  placeholder: styles => ({
    ...styles,
    fontSize: "14px",
    fontFamily: "Mulish",
    fontWeight: 400,
    color: "#807E80",
    whiteSpace: "nowrap"
  }),
  dropdownIndicator: styles => ({
    ...styles,
    color: "#333"
  }),
  menu: styles => ({
    ...styles,
    paddingTop: "3px",
    paddingRight: "5px",

    borderRadius: "12px",
    marginTop: "15px"
  }),
  menuList: styles => ({
    ...styles,

    "::-webkit-scrollbar": {
      width: "7px"
    },
    "::-webkit-scrollbar-track": {
      background: "#FFF",
      margin: "15px 0 15px 10px"
    },
    "::-webkit-scrollbar-thumb": {
      background: "#B3B3B3",
      borderRadius: "7px"
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: "#979797"
    }
  })
};
