import styled from "styled-components";

export const TooltipText = styled.div`
  cursor: pointer;
`;

export const TooltipBox = styled.div`
  position: absolute;
  top: 100%;
  left: 30px;
  visibility: hidden;
  width: 320px;
  padding: 16px;

  z-index: 1;

  background: #ffffff;

  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12),
    0px 1px 3px rgba(0, 0, 0, 0.2);
  border-radius: 12px;

  div {
    display: flex;
    align-items: flex-start;

    p {
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;

      margin-left: 12px;
    }

    & + div {
      margin-top: 16px;
    }
  }
`;

export const TooltipCard = styled.div`
  position: relative;

  & ${TooltipText}:hover + ${TooltipBox} {
    visibility: visible;
  }
`;

export const ButtonExport = styled.button`
  width: 107px;
  padding: 13px;
  border-radius: 12px;
  background-color: #409e6a;
  color: #f3f3f3;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.2s all;
  gap: 5px;
  span {
    font-family: "Mulish";
    font-weight: 700;
    font-size: 14px;
  }
  svg {
    color: #ffffff !important;
    width: 24px !important;
    height: 24px !important;
  }
  &:hover {
    opacity: 0.9;
  }
`;
