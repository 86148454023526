export const selectStyle = {
  control: styles => ({
    ...styles,
    backgroundColor: "inherit",
    width: "100%",
    border: "none",
    boxShadow: "none",
    outline: "none",
    color: "#807E80",
    padding: "0",
    "&:hover": {
      borderColor: "none"
    },
    cursor: "pointer"
  }),
  container: styles => ({
    ...styles,
    width: "100%",
    outline: "none",
    border: "none",
    padding: "2px 0",
    cursor: "pointer"
  }),
  indicatorSeparator: styles => ({
    ...styles,
    display: "none",
    border: "none"
  }),
  option: (styles, { isSelected }) => ({
    ...styles,
    fontSize: "14px",
    fontFamily: "Mulish",
    fontWeight: 400,
    color: isSelected ? "#fff" : "#807E80",
    "&:hover": {
      backgroundColor: "#c1c1c1",
      color: "#fff",
      cursor: "pointer"
    },

    backgroundColor: isSelected ? "#409e6a" : "#fff"
  }),
  singleValue: styles => ({
    ...styles,
    color: "#807E80",
    fontSize: "14px"
  }),
  placeholder: styles => ({
    ...styles,
    fontSize: "14px",
    fontFamily: "Mulish",
    fontWeight: 400,
    color: "rgba(193, 193, 193, 1)",
    whiteSpace: "nowrap"
  })
};




