import React, { useCallback, useState, useEffect } from "react";
import { Loading } from "../../../../../components/Loading";
import styles from "./styles.module.scss";
import { BrComerceApiService } from "../../../../../services/BrComerceApiService.js";
import Swal from "sweetalert2";
import { PiCopySimple } from "react-icons/pi";
import { FaArrowRotateLeft } from "react-icons/fa6";

const Yampi = () => {
  const api = new BrComerceApiService();

  const [loading, setLoading] = useState(false);

  const [copiedApiKey, setCopiedApiKey] = useState(false);
  const [generatedApiKey, setGeneratedApiKey] = useState("");
  const [generatedApiKeyOK, setGeneratedApiKeyOK] = useState("");

  const [zipCode, setZipCode] = useState("");
  const [zipCodeOK, setZipCodeOK] = useState("");
  const [zipCodeError, setZipCodeError] = useState("");

  const [activeInsurance, setActiveInsurance] = useState(false);

  const handleCopyApiKey = () => {
    navigator.clipboard.writeText(generatedApiKey);
    setCopiedApiKey(true);
  };

  const handleSubmit = useCallback(
    async (key, value) => {
      api.makeHttpRequest({
        url: "/yampi/config",
        method: "POST",
        data: {
          key: key,
          value: value
        }
      });
    },
    [api]
  );

  const sendData = async () => {
    try {
      await Promise.all([
        handleSubmit("ZIP_CODE", zipCode),
        activeInsurance
          ? handleSubmit("IS_ACTIVE_INSURANCE", "true")
          : handleSubmit("IS_ACTIVE_INSURANCE", "false")
      ]);

      Swal.fire({
        title: "Sucesso!",
        text: "Configurações cadastradas com sucesso.",
        icon: "success",
        showCancelButton: false,
        confirmButtonText: "Ok"
      });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Ocorreu um erro!",
        text: "Não foi possível fazer a deleção do envio."
      });
    }
  };

  const handleGenerateApiKey = async () => {
    try {
      setLoading(true);
      const response = await api.makeHttpRequest({
        method: "POST",
        url: `/yampi`
      });

      setGeneratedApiKey(response.value);
      setGeneratedApiKeyOK("ok");
    } catch (e) {
      Swal.fire({
        title: "Erro!",
        text: "Não foi possível gerar a API Key.",
        icon: "error"
      });
    } finally {
      setLoading(false);
    }
  };

  const loadingConfig = useCallback(async () => {
    await Promise.all([
      api
        .makeHttpRequest({
          method: "GET",
          url: `/yampi/config/API_KEY`
        })
        .then(res => {
          if (res) {
            setGeneratedApiKey(res);
            setGeneratedApiKeyOK("ok");
          }
        }),
      api
        .makeHttpRequest({
          method: "GET",
          url: `/yampi/config/ZIP_CODE`
        })
        .then(zipCode => {
          if (zipCode) {
            setZipCode(zipCode);
            setZipCodeOK("ok");
          }
        }),
      api
        .makeHttpRequest({
          method: "GET",
          url: `/yampi/config/IS_ACTIVE_INSURANCE`
        })
        .then(active => {
          if (active === "true") {
            setActiveInsurance(true);
          }
        })
    ]);
  }, [api]);

  useEffect(() => {
    if (!copiedApiKey) return;

    const timeout = setTimeout(() => {
      setCopiedApiKey(false);
    }, 2500);

    return () => clearTimeout(timeout);
  }, [copiedApiKey]);

  useEffect(() => {
    setLoading(true);

    loadingConfig()
      .then(() => {
        console.log("Configurações ok");
      })
      .catch(() => {
        Swal.fire({
          title: "Erro!",
          text: "Não foi possível carregar as informações da loja Yampi.",
          icon: "error"
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const handleInputZipCode = useCallback(e => {
    const regEx = /[^0-9]/;
    const zipCode = e.currentTarget.value.replace(regEx, "").trim();

    if (zipCode.length != 8) {
      setZipCode(zipCode);
      setZipCodeError("CEP inválido");
      setZipCodeOK("vazio");
    } else {
      const parsedZipCode = `${zipCode.substr(0, 5)}-${zipCode.substr(5, 3)}`;
      setZipCode(parsedZipCode);
      setZipCodeError("");
      setZipCodeOK("ok");
    }
  }, []);

  useEffect(() => {
    window.setPageTitle("Integrações | Yampi");
  }, []);

  return (
    <>
      <Loading loading={loading} />
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.contentHeader}>
            <h2>Configurações</h2>
          </div>
          <div className={styles.contentBody}>
            <section>
              <div>
                <p>
                  A chave gerada abaixo é única e intransferível. Lembre-se: ao
                  gerar uma nova chave, a antiga sempre será desabilitada.
                </p>
                <div className={styles.contentSection}>
                  <div
                    className={styles.inputCustom}
                    onClick={handleCopyApiKey}
                    role={generatedApiKeyOK}
                  >
                    <input
                      type="text"
                      placeholder=""
                      value={generatedApiKey}
                      disabled
                    />
                    <PiCopySimple />
                    <div>
                      <p className={styles.textClipped}>
                        {copiedApiKey && !!generatedApiKey
                          ? "Copiado com sucesso!"
                          : ""}
                      </p>
                    </div>
                  </div>
                  <button
                    className={styles.generateBtn}
                    type="button"
                    onClick={handleGenerateApiKey}
                  >
                    <FaArrowRotateLeft size={18} />
                    <span>Gerar Nova Chave</span>
                  </button>
                </div>
              </div>

              <div>
                <h4>Informe o CEP do remetente.</h4>
                <div className={styles.contentSection}>
                  <div className={styles.input} role={zipCodeOK}>
                    <input
                      type="text"
                      name="zipCode"
                      value={zipCode}
                      onChange={handleInputZipCode}
                      placeholder="Digite o CEP do remetente"
                    />
                  </div>
                  {zipCodeError && (
                    <p className={styles.error}>{zipCodeError}</p>
                  )}
                </div>
              </div>
              <div>
                <h4>Configuração de Seguro</h4>
                <p style={{ marginTop: "16px" }}>
                  Abaixo é possível ativar ou desatitvar a cotação de frete com
                  Seguro. Essa opção é válida para todos os fretes <br /> que
                  são cotados.
                </p>
                <div className={styles.contentSection}>
                  <div className={styles.switchOption}>
                    <label className={styles.switch}>
                      <input
                        type="checkbox"
                        onChange={event =>
                          setActiveInsurance(event.target.checked)
                        }
                        checked={activeInsurance}
                        disabled={!generatedApiKey}
                      />
                      <span
                        className={`${styles.slider} ${styles.round}`}
                      ></span>
                    </label>
                    {activeInsurance ? "Ativado" : "Desativado"}
                  </div>
                </div>
              </div>
              <div className={styles.buttonWrapper}>
                <button
                  type="submit"
                  onClick={sendData}
                  className={styles.button}
                  disabled={!generatedApiKey || zipCodeOK !== "ok"}
                >
                  Salvar
                </button>
              </div>
            </section>
          </div>
        </div>
      </div>
    </>
  );
};

export { Yampi };
