import React from "react";
import styles from "./styles.module.scss";

const BulletPoint = ({ order, children }) => {
  return (
    <div className={styles.container}>
      <div className={styles.bulletPoint}>
        <p>{order}</p>
      </div>
      {children}
    </div>
  );
};

export { BulletPoint };
