import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FiDownloadCloud } from 'react-icons/fi';
import { RiDeleteBin2Line, RiFileExcel2Line } from 'react-icons/ri';
import Swal from 'sweetalert2';
import styles from "./styles.module.scss";

const DragAndDrop = ({ onUpload, onClear, fileExtension = "csv" }) => {

  const drop = useRef(null);

  const [dragging, setDragging] = useState(false);
  const [uploadedFile, setUploadedFile] = useState();

  const handleFileInput = () => {
    const fileInput = document.getElementById('fileBtn');
    const file = fileInput?.files || "";

    if (!file) {
      return;
    }

    if (!file[0]?.name?.toLowerCase().endsWith(fileExtension)) {
      return Swal.fire({
        title: "Arquivo inválido!",
        text: `Extensão experada: ${fileExtension}`,
        icon: "warning"
      })
    }

    setUploadedFile(file[0]);
    onUpload(file[0]);

    fileInput.type = "text";
    fileInput.type = "file";
  }

  const handleDragOver = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  const handleDrop = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();

    const file = [...e.dataTransfer.files];

    if (file?.length > 1) {
      setDragging(false);
      return Swal.fire({
        title: "Ops!",
        text: "Faça o upload de um arquivo de cada vez",
        icon: "warning"
      })
    }

    if (!file?.some(f => f.name.toLowerCase().endsWith(fileExtension))) {
      setDragging(false);
      return Swal.fire({
        title: "Arquivo inválido!",
        text: `Extensão experada: ${fileExtension}`,
        icon: "warning"
      })
    }

    onUpload(file[0]);
    setUploadedFile(file[0]);
    setDragging(false);
  }, [fileExtension, onUpload]);

  const handleDragEnter = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();

    setDragging(true);
  }, []);

  const handleDragLeave = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();

    setDragging(false);
  }, []);

  const handleClear = useCallback(() => {
    onClear();
    setUploadedFile(null);
  }, [onClear]);

  useEffect(() => {

    const current = drop.current;
    current.addEventListener('dragover', handleDragOver);
    current.addEventListener('drop', handleDrop);
    current.addEventListener('dragenter', handleDragEnter);
    current.addEventListener('dragleave', handleDragLeave);

    return () => {
      current.removeEventListener('dragover', handleDragOver);
      current.removeEventListener('drop', handleDrop);
      current.removeEventListener('dragenter', handleDragEnter);
      current.removeEventListener('dragleave', handleDragLeave);
    };
  }, [handleDragEnter, handleDragLeave, handleDrop, handleDragOver]);

  return (
    <>
      <div ref={drop} className={`${styles.container} ${dragging || uploadedFile ? styles.dragging : ""}`}>

        {uploadedFile ? (
          <>
            <RiFileExcel2Line />
            <span>{uploadedFile?.name}</span>
          </>
        ) : dragging ? (
          <>
            <FiDownloadCloud />
            <span>Solte</span>
          </>
        ) : (
          <>
            <input
              id="fileBtn"
              className={styles.inputFile}
              type="file"
              accept={`.${fileExtension}`}
              onChange={handleFileInput}
            />
            <label htmlFor="fileBtn" className={styles.btnUpload}>
              <FiDownloadCloud />
              <span>
                Escolha o arquivo
              </span>
            </label>
          </>
        )}
      </div >

      <button
        type='button'
        className={`${styles.clearButton} ${!uploadedFile ? styles.disabled : ""}`}
        onClick={handleClear}
        disabled={!uploadedFile}
      >
        <RiDeleteBin2Line />
        <span>
          {" Remover"}
        </span>
      </button>

    </>

  );
}

export { DragAndDrop };
