import React, {
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { format } from "date-fns";
import { Col, Row } from "react-bootstrap";

import InputSelect from "../../../../../../../components/InputSelect";
import InputText from "../../../../../../../components/InputText";
import ShippingStagesEnum from "../../../../../../../enums/ShippingStagesEnum";
import { ReactComponent as CalendarIcon } from "../../../../../../../assets/icons/mdi-calendar.svg";

import { FiFilter } from "react-icons/fi";
import styles from "./clientFilters.module.scss";
import { Calendar } from "../../Calendar";
import { definedsDates } from "../../Calendar/config";

const ClientFilters = ({ onFilter }) => {
  const calendarWrapperRef = useRef(null);

  const selectStatusOptions = useMemo(() => {
    const status = Object.entries(ShippingStagesEnum).map(([key, value]) => {
      return {
        label: value.message,
        value: value.id
      };
    });

    status.splice(0, 0, {
      label: "Todos",
      value: 0
    });

    return status;
  }, []);

  const initialFilters = {
    status: selectStatusOptions.at(0),
    search: "",
    initialDate: definedsDates.startOfLastSevenDay,
    endDate: definedsDates.endOfToday
  };

  // Used for save actual period selected.
  const [period, setPeriod] = useState({
    string: "",
    value: [
      {
        startDate: initialFilters.initialDate,
        endDate: initialFilters.endDate,
        key: "selection"
      }
    ]
  });

  // Used for save period selected in calendar, but doesn't was submitted.
  const [periodDate, setPeriodDate] = useState([
    {
      startDate: initialFilters.initialDate,
      endDate: initialFilters.endDate,
      key: "selection"
    }
  ]);

  const [isShowCalendar, setIsShowCalendar] = useState(false);
  const [status, setStatus] = useState(initialFilters.status);
  const [search, setSearch] = useState(initialFilters.search);

  const mapperToPeriodDates = (
    dateInCalendarPeriod,
    formatDate = "MM/dd/yyyy"
  ) => {
    const { endDate, startDate } = dateInCalendarPeriod;
    const initialDateFormatted = format(startDate, formatDate);
    const endDateFormatted = format(endDate, formatDate);

    return {
      endDate: endDateFormatted,
      startDate: initialDateFormatted
    };
  };

  const handleFilterData = () => {
    const { endDate, startDate } = period.value[0];

    const {
      endDate: endDateMapped,
      startDate: startDateMapped
    } = mapperToPeriodDates({
      endDate,
      startDate
    });

    const filters = {
      status: status.value === 0 ? null : status.value,
      initialDate: startDateMapped,
      endDate: endDateMapped,
      search
    };

    onFilter(filters);
  };

  const handleSetCalendarPeriod = () => {
    setIsShowCalendar(false);

    const { endDate, startDate } = mapperToPeriodDates(
      periodDate[0],
      "dd/MM/yyyy"
    );

    setPeriod({
      string: `${startDate} - ${endDate}`,
      value: periodDate
    });
  };

  useEffect(() => {
    handleSetCalendarPeriod();
  }, []);

  useLayoutEffect(() => {
    if (isShowCalendar) {
      document.querySelector("body").style.overflow = "hidden";
      document.querySelector("#kt_content").style.paddingRight = "15px";

      const amountScrolledInDocument = document.body.scrollTop;
      calendarWrapperRef.current.style.top = `${Math.ceil(
        amountScrolledInDocument
      )}px`;
      return;
    }

    document.querySelector("body").style.overflow = "initial";
    document.querySelector("#kt_content").style.paddingRight = "inherit";
  }, [isShowCalendar]);

  useEffect(() => {
    handleFilterData();
  }, []);

  return (
    <>
      <Row className="d-flex align-items-end">
        <Col xs={5}>
          <InputText
            id="search"
            placeholder="Buscar por código de etiqueta, número de coleta ou nome"
            onChange={e => setSearch(e.target.value)}
            value={search}
          />
        </Col>

        <Col style={{ position: "initial" }}>
          <InputText
            id="select_calendar"
            placeholder="Selecione no calendário"
            onChange={() => {}}
            onClick={() => setIsShowCalendar(true)}
            value={period.string}
            renderIcon={() => <CalendarIcon />}
          />
          <div
            style={{
              display: isShowCalendar ? "flex" : "none"
            }}
            ref={calendarWrapperRef}
            className={styles.boxCalendar}
            onClick={e => {
              if (e.currentTarget !== e.target) return;

              setIsShowCalendar(false);
            }}
          >
            <div className={styles.calendarWrapper}>
              <Calendar
                onChange={item => setPeriodDate([item.selection])}
                ranges={periodDate}
                minDate={new Date(2021, 0, 1)}
              />
              <div className={styles.calendarFooter}>
                <button onClick={() => setIsShowCalendar(false)}>
                  Cancelar
                </button>
                <button onClick={handleSetCalendarPeriod}>Aplicar</button>
              </div>
            </div>
          </div>
        </Col>
        <Col>
          <InputSelect
            id="select_status"
            placeholder="Selecione um status"
            options={selectStatusOptions}
            onChange={setStatus}
            value={status}
          />
        </Col>

        <Col xs="auto">
          <button
            type="button"
            className={styles.filterBtn}
            onClick={handleFilterData}
          >
            <FiFilter />
            <span>Filtrar</span>
          </button>
        </Col>
      </Row>
    </>
  );
};

export { ClientFilters };
