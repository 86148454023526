import { Checkbox, IconButton, InputAdornment, Modal } from "@material-ui/core";
import { VisibilityOffOutlined, VisibilityOutlined } from "@material-ui/icons";
import clsx from "clsx";
import { Formik } from "formik";
import queryString from "querystring";
import React, { useCallback, useEffect, useState } from "react";
import { HiCheckCircle, HiExclamationCircle } from "react-icons/hi";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect, shallowEqual, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";
import checkedIcon from "../../assets/icons/icon-checked.svg";
import notCheckedIcon from "../../assets/icons/icon-not-checked.svg";
import Header from "../../components/Header";
import { login } from "../../crud/auth.crud";
import * as auth from "../../store/ducks/auth.duck";
import Loading from "../home/components/Loading";
import { StyledFormControlLabel, StyledTextField } from "./Components/Input";
/* import Facebook from "./Components/Facebook";
import Google from "./Components/Google"; */
import ForgotPassword from "./ForgotPassword";
import * as routerHelpers from "../../router/RouterHelpers";

const ModalContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: fit-content;
  height: 100%;

  position: relative;
  margin: auto;
  outline: none;
`;

function Login(props) {
  const { intl } = props;

  const { authUser, userLastLocation } = useSelector(
    ({ auth, builder: { menuConfig } }) => ({
      userLastLocation: routerHelpers.getLastLocation(),
      authUser: auth.user
    }),
    shallowEqual
  );

  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [forgetPassModal, setForgetPassModal] = useState(false);
  const [error, setError] = useState(null);
  const [loadingButtonStyle, setLoadingButtonStyle] = useState({
    paddingRight: "2.5rem"
  });

  const [passwordVisibility, setPasswordVisibility] = useState(false);

  const handleClickShowPassword = () => {
    setPasswordVisibility(!passwordVisibility);
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  const enableLoading = () => {
    setLoading(true);
    setIsLoading(true);
    setLoadingButtonStyle({ paddingRight: "3.5rem" });
  };

  const disableLoading = () => {
    setLoading(false);
    setIsLoading(false);
    setLoadingButtonStyle({ paddingRight: "2.5rem" });
  };

  const isLoginExpired = () => {
    const query = queryString.parse(window.location.search.replace("?", ""));
    return query && query.hasOwnProperty("expired");
  };

  const handleUserLoggedIn = useCallback(() => {
    const isAdmin = authUser.profile === "admin";

    if (isAdmin) {
      const lastLocation =
        userLastLocation !== "/" && userLastLocation !== "/home"
          ? userLastLocation
          : "/carrinho";
      history.push(lastLocation);
      return;
    }

    history.push("/dashboard");
  }, [authUser, userLastLocation, history]);


  useEffect(() => {
    if (authUser) {
      handleUserLoggedIn();
    }
  }, [authUser, handleUserLoggedIn]);

  window.setPageTitle("Login");

  return (
    <>
      <div className="header">
        <Header />
      </div>
      <Loading isLoading={isLoading} />
      <div className="kt-login__body">
        <div className="kt-login__form">
          <div className="kt-login__title">
            <h3>
              <FormattedMessage id="AUTH.LOGIN.TITLE" />
            </h3>
          </div>

          <Formik
            initialValues={{
              email: "",
              password: "",
              rememberMe: false
            }}
            validate={values => {
              const errors = {};

              if (!values.email) {
                errors.email = intl.formatMessage({
                  id: "AUTH.VALIDATION.REQUIRED_FIELD"
                });
              } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
              ) {
                errors.email = intl.formatMessage({
                  id: "AUTH.VALIDATION.INVALID_FIELD"
                });
              }

              if (!values.password) {
                errors.password = intl.formatMessage({
                  id: "AUTH.VALIDATION.REQUIRED_FIELD"
                });
              }

              return errors;
            }}
            onSubmit={async (values, { setStatus, setSubmitting }) => {
              enableLoading();
              try {
                const authData = await login(values.email, values.password);
                authData.rememberMe = values.rememberMe;
                disableLoading();
                props.login(authData);
              } catch (e) {
                disableLoading();
                setSubmitting(false);
                console.error(e);
                if (e?.response?.data?.message?.name === "UserNotConfirmedException") {
                  props.history.push(
                    `/auth/Registration/pj?email=${values.email}`
                  );
                } else if (e?.response?.data?.message.includes("User is not confirmed.")) {
                  const isCompany = e?.response?.data?.message.replace("User is not confirmed. ", '')

                  if (isCompany.includes('true')) {
                    props.history.push(
                      `/auth/Registration/pj?email=${values.email}`
                    );
                  } else {
                    props.history.push(
                      `/auth/Registration/pf?email=${values.email}`
                    );
                  }

                } else if (e?.response?.data?.message === "User not found") {
                  setStatus("Usuário não cadastrado");
                } else {
                  setStatus("E-mail ou senha incorretos");
                }
                /* setStatus(
                    e.response && e.response.data && e.response.data.message
                      ? e.response.data.message
                      : intl.formatMessage({
                          id: "AUTH.VALIDATION.INVALID_LOGIN"
                        })
                  ); */
              }
            }}
          >
            {({
              values,
              status,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting
            }) => (
              <form
                noValidate={true}
                autoComplete="on"
                className="kt-form"
                onSubmit={handleSubmit}
              >
                {(status || isLoginExpired()) && (
                  <div role="alert" className="alert alert-danger">
                    <div className="alert-text">
                      {status ||
                        "Sua sessão expirou, faça login para continuar"}
                    </div>
                  </div>
                )}

                {error && (
                  <div role="alert" className="alert alert-danger">
                    <div className="alert-text">{error}</div>
                  </div>
                )}

                <div className="form-group">
                  <StyledTextField
                    variant="outlined"
                    type="email"
                    label="E-mail"
                    margin="normal"
                    className="kt-width-full"
                    name="email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.email}
                    helperText={touched.email && errors.email}
                    error={Boolean(touched.email && errors.email)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton edge="end">
                            {touched.email ? (
                              !!errors.email ? (
                                <HiExclamationCircle color="#CA1818" />
                              ) : (
                                <HiCheckCircle color="#409E6A" />
                              )
                            ) : null}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                </div>

                <div className="form-group">
                  <StyledTextField
                    variant="outlined"
                    type={passwordVisibility ? "text" : "password"}
                    margin="normal"
                    label="Senha"
                    className="kt-width-full"
                    name="password"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.password}
                    helperText={touched.password && errors.password}
                    error={Boolean(touched.password && errors.password)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="Alterar visibilidade da senha"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {passwordVisibility ? (
                              <VisibilityOffOutlined />
                            ) : (
                              <VisibilityOutlined />
                            )}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                </div>

                <div className="containerButtons">
                  <div className="remindMeAndForgot">
                    <div className="remindMe">
                      <StyledFormControlLabel
                        control={
                          <Checkbox
                            name="rememberMe"
                            value={values.rememberMe}
                            onChange={handleChange}
                            icon={<img src={notCheckedIcon} alt="" />}
                            checkedIcon={<img src={checkedIcon} alt="" />}
                            label=""
                          />
                        }
                        label="Lembrar-me"
                      />
                    </div>

                    <div className="kt-login__actions  justify-content-center">
                      <div
                        className="kt-link kt-login__link-forgot"
                        onClick={() => setForgetPassModal(true)}
                      >
                        <FormattedMessage id="AUTH.GENERAL.FORGOT_BUTTON" />
                      </div>
                      <Modal
                        open={forgetPassModal}
                        onClose={() => setForgetPassModal(false)}
                        aria-labelledby="simple-modal-title"
                        aria-describedby="simple-modal-description"
                      >
                        <ModalContainer>
                          <ForgotPassword
                            bg="white"
                            closeModal={() => setForgetPassModal(false)}
                          />
                        </ModalContainer>
                      </Modal>
                    </div>
                  </div>

                  <div className="kt-login__actions mb-0 pb-0">
                    <button
                      id="kt_login_signin_submit"
                      type="submit"
                      disabled={isSubmitting}
                      className={`btn1 btn btn-elevate kt-login__btn-primary ${clsx(
                        {
                          "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": loading
                        }
                      )}`}
                      style={loadingButtonStyle}
                    >
                      ENTRAR
                    </button>
                  </div>

                  <p className="naoPossuiConta">OU</p>

                  <div className="kt-login__actions mb-0 pb-0">
                    <Link
                      to="/auth/Registration"
                      className="kt-login__link-no-account"
                    >
                      <button
                        type="button"
                        className={`btn2 btn btn-elevate kt-login__btn-primary `}
                      >
                        CADASTRE-SE AGORA
                      </button>
                    </Link>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
}

export default injectIntl(connect(null, auth.actions)(Login));
