// import { Tooltip, styled } from "@material-ui/core";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import React from "react";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "#4D4D4D",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.15)",
    borderRadius: 6,
    minHeight: 27,
    display: "flex",
    alignItems: "center",
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: 12
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.white
  }
}));

export const StyledTooltip = ({ text, position, arrow, children }) => (
  <LightTooltip title={text} placement={position} arrow={arrow}>
    {children}
  </LightTooltip>
);
