const PlpTypeEnum = {
    INDUSTRIAL: {
        value: "industrial",
        label: "Industrial"
    },
    RETAIL: {
        value: "retail",
        label: "Correios"
    }
};

export default Object.freeze({
    ...PlpTypeEnum,
});