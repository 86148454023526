import React, { useCallback, useEffect, useState } from "react";
import Select from "react-select";
import Swal from "sweetalert2";
import { Loading } from "../../../../../components/Loading";
import { OriginEnum } from "../../../../../enums/OriginEnum.js";
import { useDidMountEffect } from "../../../../../hooks/useDidMountEffect";
import { BrComerceApiService } from "../../../../../services/BrComerceApiService.js";
import store from "../../../../../store/store.js";
import debounce from "../../../../../utils/debounce";
import { ModalOrdersList } from "../../../components/ModalOrdersList";
import styles from "./styles.module.scss";

const BlingV3 = () => {
  const api = new BrComerceApiService();

  const { auth } = store.getState();

  const { user } = auth;

  const [loading, setLoading] = useState(false);

  const [hasAccessToken, setHasAccessToken] = useState(false);

  const [selectedStatusesToAddOrder, setSelectedStatusesToAddOrder] = useState([]);
  const [selectedStatusesToAddOrderOK, setSelectedStatusesToAddOrderOK] = useState("");
  const [selectedStatusesToAddOrderError, setSelectedStatusesToAddOrderError] = useState("");

  const [selectedStatusAfterCreateOrder, setSelectedStatusAfterCreateOrder] = useState(null);
  const [selectedStatusAfterCreateOrderOK, setSelectedStatusAfterCreateOrderOK] = useState("");

  const [selectedStatusAfterCreateCollect, setSelectedStatusAfterCreateCollect] = useState({
    value: 15,
    label: "Em andamento"
  });
  const [selectedStatusAfterCreateCollectOK, setSelectedStatusAfterCreateCollectOK] = useState("");
  const [selectedStatusAfterCreateCollectError, setSelectedStatusAfterCreateCollectError] = useState(
    ""
  );

  const [optionsStatuses, setOptionsStatuses] = useState([]);

  const [activeInsurance, setActiveInsurance] = useState(false);

  const [clientId, setClientId] = useState("");

  const [clientIdError, setClientIdError] = useState("");
  const [clientIdOK, setClientIdOK] = useState("");

  const [clientSecret, setClientSecret] = useState("");
  const [clientSecretError, setClientSecretError] = useState("");
  const [clientSecretOK, setClientSecretOK] = useState("");

  const [senderName, setSenderName] = useState("");
  const [senderNameOK, setSenderNameOK] = useState("");
  const [senderNameError, setSenderNameError] = useState("");

  const [zipOriginOK, setZipOriginOK] = useState("vazio");
  const [zipOriginError, setZipOriginError] = useState("");
  const [zipOrigin, setZipOrigin] = useState("");

  const [addressOK, setAddressOK] = useState("vazio");
  const [addressError, setAddressError] = useState("");
  const [address, setAddress] = useState("");

  const [addressObsOK, setAddressObsOK] = useState("vazio");
  const [addressObsError, setAddressObsError] = useState("");
  const [addressObs, setAddressObs] = useState("");

  const [addressNumberOK, setAddressNumberOK] = useState("vazio");
  const [addressNumber, setAddressNumber] = useState("");

  const [addressNeighborhoodOK, setAddressNeighborhoodOK] = useState("vazio");
  const [addressNeighborhoodError, setAddressNeighborhoodError] = useState("");
  const [addressNeighborhood, setAddressNeighborhood] = useState("");

  const [uf, setUf] = useState("");
  const [ufOK, setUfOK] = useState("");

  const [city, setCity] = useState("");
  const [cityOK, setCityOK] = useState("");

  const [activeCreateOrder, setActiveCreateOrder] = useState(true);

  const selectStyle = {
    control: styles => ({
      ...styles,
      backgroundColor: "inherit",
      width: "100%",
      border: "none",
      boxShadow: "none",
      outline: "none",
      color: "#807E80",
      padding: "0",
      "&:hover": {
        borderColor: "none"
      },
      cursor: "pointer"
    }),
    container: styles => ({
      ...styles,
      width: "100%",
      outline: "none",
      border: "none",
      padding: "2px 0",
      cursor: "pointer"
    }),
    indicatorSeparator: styles => ({
      ...styles,
      display: "none",
      border: "none"
    }),
    option: (styles, { isSelected }) => ({
      ...styles,
      fontSize: "14px",
      fontFamily: "Mulish",
      fontWeight: 400,
      color: isSelected ? "#fff" : "#807E80",
      "&:hover": {
        backgroundColor: "#c1c1c1",
        color: "#fff",
        cursor: "pointer"
      },

      backgroundColor: isSelected ? "#409e6a" : "#fff"
    }),
    singleValue: styles => ({
      ...styles,
      color: "#807E80",
      fontSize: "14px"
    }),
    placeholder: styles => ({
      ...styles,
      fontSize: "14px",
      fontFamily: "Mulish",
      fontWeight: 400,
      color: "rgba(193, 193, 193, 1)",
      whiteSpace: "nowrap"
    })
  };

  const handleCepConsult = useCallback(async cep => {
    try {
      setLoading(true);
      let response = await api.makeHttpRequest({
        method: "POST",
        url: `/addressbook/getAddress`,
        data: {
          zipCode: cep
        }
      });

      if (response?.erro === true) {
        setZipOriginOK("notOk");
        setZipOriginError("CEP inválido.");

        setAddress("");
        setAddressOK("vazio");
        setAddressError("");

        setAddressNeighborhood("");
        setAddressNeighborhoodOK("vazio");
        setAddressNeighborhoodError("");

        setUf("");
        setUfOK("");
        setCity("");
        setCityOK("");

        throw new Error("Não foi possível encontrar o CEP digitado.");
      }

      setZipOriginOK("ok");
      setZipOriginError("");

      if (!response.logradouro || response.logradouro === "undefined") {
        setAddressOK("vazio");
      } else {
        setAddress(response.logradouro);
        setAddressOK("ok");
        setAddressError("");
      }

      if (!response.bairro || response.bairro === "undefined") {
        setAddressNeighborhoodOK("vazio");
      } else {
        setAddressNeighborhood(response.bairro);
        setAddressNeighborhoodOK("ok");
        setAddressNeighborhoodError("");
      }

      setUf(response.uf);
      setUfOK("ok");
      setCity(response.localidade);

      setCityOK("ok");
    } catch (error) {
      console.error(error);

      Swal.fire({
        icon: "error",
        title: "CEP não encontrado",
        text: "Por favor, verifique o CEP inserido"
      });
    } finally {
      setLoading(false);
    }
  }, []);

  const handleValidationAddressObs = value => {
    if (value?.length < 2 && value?.length > 0) {
      setAddressObsOK("notOk");
    } else if (value?.length > 2) {
      setAddressObsOK("ok");
    } else {
      setAddressObsOK("vazio");
    }

    if (value?.length === 1) {
      setAddressObsError("Complemento deve ter pelo menos 2 caracteres");
    } else {
      setAddressObsError("");
    }
  };

  const handleInputAddressObs = useCallback(e => {
    let value = e.currentTarget.value;
    setAddressObs(value);
    handleValidationAddressObs(value);
  }, []);

  const handleInputAddress = useCallback(e => {
    let value = e.currentTarget.value;
    setAddress(value);
    handleValidationAddress(value);
  }, []);

  const handleValidationAddress = value => {
    value.length < 2 ? setAddressOK("notOk") : setAddressOK("ok");
    if (value.length === 0) {
      setAddressError("Campo obrigatório");
    } else if (value.length === 1) {
      setAddressError("Endereço deve ter pelo menos 2 caracteres");
    } else {
      setAddressError("");
    }
  };

  const handleValidationZipOrigin = useCallback(
    async (value, isASavedInfo = false) => {
      if (!value.length) {
        setZipOriginOK("notOk");
        setZipOriginError("Preenchimento obrigatório");
      } else if (value.length < 8) {
        setZipOriginOK("notOk");
        setZipOriginError("Preenchimento incorreto");
      } else {
        if (isASavedInfo) {
          setZipOriginOK("ok");
          setZipOriginError("");
        }

        // Não queremos manipular o CEP novamente,
        // se o CEP selecionado pertence a um endereço já salvo
        if (isASavedInfo === false) {
          const cep = value.substr(0, 5) + value.substr(6, 3);
          await handleCepConsult(cep);
        }
      }
    },
    [handleCepConsult]
  );

  const handleInputZipOrigin = useCallback(
    e => {
      let value = e;
      value = value.replace(/^(\d{5})(\d{3})/, "$1-$2");

      handleValidationZipOrigin(value);
      setZipOrigin(value);
    },
    [handleValidationZipOrigin]
  );

  const handleValidationAddressNeighborHood = value => {
    if (address?.length) return;

    value.length < 1
      ? setAddressNeighborhoodOK("notOk")
      : setAddressNeighborhoodOK("ok");
    if (value.length === 0) {
      setAddressNeighborhoodError("Campo obrigatório");
    } else {
      setAddressNeighborhoodError("");
    }
  };

  const handleInputAddressNeighborhood = useCallback(e => {
    let value = e.currentTarget.value;
    setAddressNeighborhood(value);
    handleValidationAddressNeighborHood(value);
  }, []);

  const handleValidationAddressNumber = value => {
    if (value !== "") {
      setAddressNumberOK("ok");
    } else {
      setAddressNumberOK("vazio");
    }
  };

  const handleInputAddressNumber = useCallback(e => {
    let value = e.currentTarget.value;

    if (value !== "" && !Number(value)) {
      return;
    }

    setAddressNumber(value);
    handleValidationAddressNumber(value);
  }, []);

  const handleClientId = useCallback(
    e => {
      if (e.length < 1) {
        setClientId(e);
        setClientIdError("Campo obrigatório");
        setClientIdOK("notOk");
      } else {
        setClientId(e);
        setClientIdError("");
        setClientIdOK("ok");
      }
    },
    []
  );

  const handleClientSecret = useCallback(
    e => {
      if (e.length < 1) {
        setClientSecret(e);
        setClientSecretError("Campo obrigatório");
        setClientSecretOK("notOk");
      } else {
        setClientSecret(e);
        setClientSecretError("");
        setClientSecretOK("ok");
      }
    },
    []
  );

  const handleAuth = useCallback(async () => {

    await Promise.all([
      clientId ?
        handleSubmit("CLIENT_ID", clientId)
        : undefined,
      clientSecret ?
        handleSubmit("CLIENT_SECRET", clientSecret)
        : undefined,
    ])

    const timestamp = Date.now();

    Swal.fire({
      title: "Autenticação em andamento",
      html: "Aguarde o redirecionamento para confirmamos as credenciais e gerar o token de acesso",
      icon: "success",
      onClose: () => {
        window.open(`https://www.bling.com.br/b/Api/v3/oauth/authorize?response_type=code&client_id=${clientId}&state=${user?.sub}-${timestamp}`, "_blank");
      }
    })
  }, [clientId, clientSecret])



  const handleInputSenderName = useCallback(e => {
    const name = e.currentTarget.value;

    if (name.length < 1) {
      setSenderName("");
      setSenderNameError("");
      setSenderNameOK("vazio");
    } else if (name.length < 2) {
      setSenderName(name);
      setSenderNameError("Nome do remetente deve ter pelo menos 2 caracteres");
      setSenderNameOK("notOk");
    } else {
      setSenderName(name);
      setSenderNameError("");
      setSenderNameOK("ok");
    }
  }, []);

  const handleChangeSelectStatusesToAddOrder = e => {
    if (e === null) {
      setSelectedStatusesToAddOrder(e);
      setSelectedStatusesToAddOrderOK("notOk");
      setSelectedStatusesToAddOrderError("Selecione ao menos uma opção");
    } else {
      setSelectedStatusesToAddOrder(e);
      setSelectedStatusesToAddOrderOK("ok");
      setSelectedStatusesToAddOrderError("");
    }
  };

  const handleChangeSelectStatusAfterCreateOrder = e => {
    setSelectedStatusAfterCreateOrder(e);
    setSelectedStatusAfterCreateOrderOK("ok");
  };

  const handleChangeSelectStatusAfterCreateCollect = e => {
    if (e === null) {
      setSelectedStatusAfterCreateCollect(e);
      setSelectedStatusAfterCreateCollectOK("notOk");
      setSelectedStatusAfterCreateCollectError("Selecione uma opção");
    } else {
      setSelectedStatusAfterCreateCollect(e);
      setSelectedStatusAfterCreateCollectOK("ok");
      setSelectedStatusAfterCreateCollectError("");
    }
  };

  const handleSubmit = useCallback(
    async (key, value) => {
      api.makeHttpRequest({
        url: "/blingV3/config/key",
        method: "POST",
        data: {
          key: key,
          value: value
        }
      });
    },
    [api]
  );

  const sendData = async () => {
    const selectedStatusAfterCreateOrderValue = selectedStatusAfterCreateOrder?.value;
    const selectedStatusAfterCreateCollectValue = selectedStatusAfterCreateCollect?.value;
    const hasDuplicatedStatus =
      selectedStatusesToAddOrder?.length &&
      (selectedStatusesToAddOrder.filter(r => r.value === selectedStatusAfterCreateOrderValue)
        .length > 0 ||
        selectedStatusesToAddOrder.filter(r => r.value === selectedStatusAfterCreateCollectValue)
          .length > 0);

    if (
      hasDuplicatedStatus ||
      selectedStatusAfterCreateOrderValue === selectedStatusAfterCreateCollectValue
    ) {
      return Swal.fire({
        title: "Status duplicado",
        text: "Cada etapa deve ter status diferentes",
        icon: "warning"
      });
    }

    const zipOriginNumber = zipOrigin.replace(/\D/g, "");

    const completeAddress = {
      zip: zipOriginNumber,
      street: address,
      number: addressNumber,
      complement: addressObs,
      district: addressNeighborhood,
      city: city,
      state: uf
    };

    try {
      await Promise.all([
        handleSubmit("SENDER_DEFAULT_NAME", senderName),
        handleSubmit("SENDER_DEFAULT_ADDRESS", JSON.stringify(completeAddress)),
        selectedStatusesToAddOrder.length > 0
          ? handleSubmit(
            "STATUSES_TO_ADD_ORDER",
            JSON.stringify(selectedStatusesToAddOrder)
          )
          : undefined,
        selectedStatusAfterCreateOrder
          ? handleSubmit(
            "STATUS_AFTER_CREATE_ORDER",
            JSON.stringify(selectedStatusAfterCreateOrder)
          )
          : undefined,
        activeInsurance
          ? handleSubmit("IS_ACTIVE_INSURANCE", JSON.stringify("true"))
          : handleSubmit("IS_ACTIVE_INSURANCE", JSON.stringify("false")),
        selectedStatusAfterCreateCollect
          ? handleSubmit(
            "STATUS_AFTER_CREATE_COLLECT",
            JSON.stringify(selectedStatusAfterCreateCollect)
          )
          : undefined,
        activeCreateOrder
          ? handleSubmit("IS_INACTIVE_ORDER_CREATE", "false")
          : handleSubmit("IS_INACTIVE_ORDER_CREATE", "true"),
      ]);

      Swal.fire({
        title: "Sucesso!",
        text: "Configurações cadastradas com sucesso.",
        icon: "success",
        showCancelButton: false,
        confirmButtonText: "Ok",
        preConfirm: () => {
          Swal.close();
          setLoading(true);
          getConfigs();
          loadingConfig()
            .then(() => {
              console.log("Configurações ok");
            })
            .catch(() => {
              Swal.fire({
                title: "Erro!",
                text: "Não foi possível carregar as informações da loja da Bling.",
                icon: "error"
              });
            })
            .finally(() => {
              setLoading(false);
            });
        }
      });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Ocorreu um erro!",
        text: "Erro ao cadastrar configurações"
      });
    }
  };

  useEffect(() => {
    window.setPageTitle("Integrações | Bling");
  }, []);

  const getConfigs = async () => {
    try {
      setLoading(true);
      const response = await api.makeHttpRequest({
        method: "GET",
        url: `/blingV3/status`
      });

      const responseHandled = response.map(status => ({
        value: status.id,
        label: status.nome
      }));

      setOptionsStatuses(responseHandled);
    } catch (e) {
      Swal.fire({
        title: "Erro!",
        text: "Não foi possível carregar as opções de status da Bling.",
        icon: "error"
      });
    }
  }

  useEffect(() => {
    (async () => {
      if (hasAccessToken) {
        getConfigs()
        setLoading(false);
      }
    })();
  }, [hasAccessToken]);

  const loadingConfig = useCallback(async () => {
    await Promise.all([
      api
        .makeHttpRequest({
          method: "GET",
          url: `/blingV3/config/key/ACCESS_TOKEN`
        })
        .then(accessToken => {
          if (accessToken) {
            setHasAccessToken(true);
          }
        }),
      api
        .makeHttpRequest({
          method: "GET",
          url: `/blingV3/config/key/STATUSES_TO_ADD_ORDER`
        })
        .then(res => {
          if (res) {
            const statusesToAddHandled = JSON.parse(res || "[]");
            setSelectedStatusesToAddOrder(statusesToAddHandled);
            setSelectedStatusesToAddOrderOK("ok");
          }
        }),
      api
        .makeHttpRequest({
          method: "GET",
          url: `/blingV3/config/key/STATUS_AFTER_CREATE_ORDER`
        })
        .then(res => {
          if (res) {
            const statusAfterCreateOrderHandled = JSON.parse(res || "null");
            setSelectedStatusAfterCreateOrder(statusAfterCreateOrderHandled);
            setSelectedStatusAfterCreateOrderOK("ok");
          }
        }),
      api
        .makeHttpRequest({
          method: "GET",
          url: `/blingV3/config/key/STATUS_AFTER_CREATE_COLLECT`
        })
        .then(res => {
          if (res) {
            const statusAfterCreateCollectHandled = JSON.parse(res || "null");
            setSelectedStatusAfterCreateCollect(statusAfterCreateCollectHandled);
            setSelectedStatusAfterCreateCollectOK("ok");
          }
        }),
      api
        .makeHttpRequest({
          method: "GET",
          url: `/blingV3/config/key/IS_ACTIVE_INSURANCE`
        })
        .then(active => {
          if (active.includes("true")) {
            setActiveInsurance(true);
          }
        }),
      api
        .makeHttpRequest({
          method: "GET",
          url: `/blingV3/config/key/IS_INACTIVE_ORDER_CREATE`
        })
        .then(inactive => {
          if (inactive === "true") {
            setActiveCreateOrder(false);
          }
        }),
      api
        .makeHttpRequest({
          method: "GET",
          url: `/blingV3/config/key/SENDER_DEFAULT_NAME`
        })
        .then(name => {
          if (name) {
            setSenderName(name);
            setSenderNameOK("ok");
          }
        }),
      api
        .makeHttpRequest({
          method: "GET",
          url: `/blingV3/config/key/SENDER_DEFAULT_ADDRESS`
        })
        .then(res => {
          if (res) {
            const address = JSON.parse(res || "[]");
            setZipOrigin(address?.zip?.replace(/^(\d{5})(\d{3})/, "$1-$2"));
            setZipOriginOK("ok");

            setAddress(address.street);
            setAddressOK("ok");

            setAddressNumber(Number(address.number) || "");
            if (address.number) {
              setAddressNumberOK("ok");
            }

            setAddressObs(address.complement);
            if (address.complement) {
              setAddressObsOK("ok");
            }

            setAddressNeighborhood(address.district);
            setAddressNeighborhoodOK("ok");

            setUf(address.state);
            setUfOK("ok");

            setCity(address.city);
            setCityOK("ok");
          }
        })
    ]);
  }, [api]);

  useEffect(() => {
    setLoading(true);

    loadingConfig()
      .then(() => {
        console.log("Configurações ok");
      })
      .catch(() => {
        Swal.fire({
          title: "Erro!",
          text: "Não foi possível carregar as informações da loja da Bling.",
          icon: "error"
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <>
      <Loading loading={loading} />
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.contentHeader}>
            <h2>Configurações</h2>
          </div>
          <div className={styles.contentBody}>

            <section className={styles.firstSection}>
              {!hasAccessToken && !loading && (
                <div>
                  <p>Informe as credenciais do aplicativo criado na Bling.</p>
                  <div className={styles.contentSection}>
                    <div className={styles.input} role={clientId}>
                      <input
                        type="text"
                        name="clientId"
                        value={clientId}
                        onChange={e =>
                          handleClientId(e.currentTarget.value)
                        }
                        placeholder="Client ID"
                      />
                    </div>
                    {clientIdError && (
                      <p className={styles.error}>{clientIdError}</p>
                    )}
                  </div>
                  <div className={styles.contentSection}>
                    <div className={styles.input} role={clientSecret}>
                      <input
                        type="text"
                        name="clientSecret"
                        value={clientSecret}
                        onChange={e => handleClientSecret(e.currentTarget.value)}
                        placeholder="Client Secret"
                      />
                    </div>
                    {clientSecretError && (
                      <p className={styles.error}>{clientSecretError}</p>
                    )}
                  </div>
                  <div className={styles.btnContainer}>
                    <button
                      type="submit"
                      onClick={() => handleAuth()}
                      className={styles.btnSave}
                      disabled={!clientId || !clientSecret || clientIdOK === "notOk" || clientSecretOK === "notOk"}
                    >
                      Autenticar
                    </button>
                  </div>
                </div>)}
              {hasAccessToken && (
                <>
                  <div className={styles.sectionTitle}>
                    <p>
                      Informe os dados do remetente que serão usados no calculo do
                      frete e impressão de etiquetas.
                    </p>
                    <form>
                      <div className={`${styles.inputContainer} `}>
                        <div className={styles.divContainer}>
                          <div className={styles.input} role={senderNameOK}>
                            <input
                              onChange={e => handleInputSenderName(e)}
                              value={senderName}
                              placeholder="Digite o nome do remetente"
                              type="text"
                              name="senderName"
                            />
                          </div>
                          {senderNameError && (
                            <p className={styles.error}>{senderNameError}</p>
                          )}
                        </div>
                      </div>
                      <div className={`${styles.inputContainer} `}>
                        <div className={` ${styles.divContainer}`}>
                          <div className={` ${styles.inputContainerSmCep} `}>
                            <div className={styles.input} role={zipOriginOK}>
                              <input
                                type="text"
                                name="cep"
                                placeholder="Digite o CEP"
                                onChange={e =>
                                  handleInputZipOrigin(e.currentTarget.value)
                                }
                                value={zipOrigin}
                                maxLength={8}
                              />
                              <a
                                href="https://buscacepinter.correios.com.br/app/endereco/index.php"
                                target="_blank"
                                rel="noopener noreferrer"
                                className={styles.cepLink}
                              >
                                Não sei o CEP
                              </a>
                            </div>
                          </div>
                        </div>
                        {zipOriginError && (
                          <p className={styles.error}>{zipOriginError}</p>
                        )}
                      </div>
                      <div
                        className={`${styles.inputContainer} ${styles.inputContainerAddress}`}
                      >
                        <div
                          className={` ${styles.divContainer} ${styles.inputContainerMd}`}
                        >
                          <div className={` ${styles.input} `} role={addressOK}>
                            <input
                              onChange={e => handleInputAddress(e)}
                              value={address}
                              placeholder="Digite o endereço"
                              type="text"
                              name="address"
                            />
                          </div>
                          {addressError && (
                            <p className={styles.error}>{addressError}</p>
                          )}
                        </div>

                        <div className={` ${styles.divContainer}`}>
                          <div className={styles.input} role={addressNumberOK}>
                            <input
                              onChange={e => handleInputAddressNumber(e)}
                              value={addressNumber}
                              placeholder="Digite o número"
                              type="text"
                              name="addressNumber"
                            />
                          </div>
                        </div>
                      </div>
                      <div
                        className={`${styles.inputContainer} ${styles.inputContainerAddress}`}
                      >
                        <div className={` ${styles.divContainer}`}>
                          <div className={styles.input} role={addressObsOK}>
                            <input
                              onChange={e => handleInputAddressObs(e)}
                              value={addressObs}
                              placeholder="Digite o complemento"
                              type="text"
                              name="addressObs"
                            />
                          </div>
                          {addressObsError && (
                            <p className={styles.error}>{addressObsError}</p>
                          )}
                        </div>

                        <div className={` ${styles.divContainer}`}>
                          <div
                            className={styles.input}
                            role={addressNeighborhoodOK}
                          >
                            <input
                              onChange={e => handleInputAddressNeighborhood(e)}
                              value={addressNeighborhood}
                              placeholder="Digite o bairro"
                              type="text"
                              name="addressNeighborhood"
                            />
                          </div>
                          {addressNeighborhoodError && (
                            <p className={styles.error}>
                              {addressNeighborhoodError}
                            </p>
                          )}
                        </div>
                      </div>
                      <div
                        className={`${styles.inputContainer} ${styles.inputContainerAddress}`}
                      >
                        <div className={styles.divContainer}>
                          <div className={styles.input} role={ufOK}>
                            <input
                              onChange={() => { }}
                              value={uf}
                              type="text"
                              name="uf"
                            />
                          </div>
                        </div>

                        <div className={styles.divContainer}>
                          <div className={` ${styles.divContainer}`}>
                            <div className={styles.input} role={cityOK}>
                              <input
                                onChange={() => { }}
                                value={city}
                                type="text"
                                name="city"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div>
                    <p>
                      Selecione abaixo o(s) status dos pedidos que deverão ser
                      importados pela integração:
                    </p>
                    <div className={styles.contentSection}>
                      <div className={styles.input} role={selectedStatusesToAddOrderOK}>
                        <Select
                          onChange={handleChangeSelectStatusesToAddOrder}
                          options={optionsStatuses}
                          value={selectedStatusesToAddOrder}
                          placeholder="Selecione o status"
                          styles={selectStyle}
                          isMulti
                        />
                      </div>
                      {selectedStatusesToAddOrderError && (
                        <p className={styles.error}>{selectedStatusesToAddOrderError}</p>
                      )}
                    </div>
                  </div>
                  <div>
                    <p>
                      Selecione abaixo para qual status os pedidos deverão ser
                      atualizados depois de importados:
                    </p>
                    <div className={styles.contentSection}>
                      <div className={styles.input} role={selectedStatusAfterCreateOrderOK}>
                        <Select
                          onChange={handleChangeSelectStatusAfterCreateOrder}
                          options={optionsStatuses}
                          value={selectedStatusAfterCreateOrder}
                          placeholder="Selecione o status"
                          styles={selectStyle}
                        />
                      </div>
                    </div>
                  </div>
                  <div>
                    <p>
                      Selecione abaixo o status dos pedidos que deverão ser
                      movidos após a criação do pedido/coleta:
                    </p>
                    <div className={styles.contentSection}>
                      <div
                        className={styles.input}
                        role={selectedStatusAfterCreateCollectOK}
                      >
                        <Select
                          onChange={handleChangeSelectStatusAfterCreateCollect}
                          options={optionsStatuses}
                          value={selectedStatusAfterCreateCollect}
                          placeholder="Selecione o status"
                          styles={selectStyle}
                        />
                      </div>
                      {selectedStatusAfterCreateCollectError && (
                        <p className={styles.error}>
                          {selectedStatusAfterCreateCollectError}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className={styles.wrapper}>
                    <h4>Criação de pedido</h4>
                    <br />
                    <p>
                      Abaixo é possível ativar ou desatitvar a criação de pedidos
                      <br />
                      Serviços como cotação funcionarão normalmente
                    </p>
                    <br />
                    <div>
                      <div className={styles.switchOption}>
                        <label className={styles.switch}>
                          <input
                            type="checkbox"
                            onChange={event =>
                              setActiveCreateOrder(event.target.checked)
                            }
                            checked={activeCreateOrder}
                          />
                          <span
                            className={`${styles.slider} ${styles.round}`}
                          ></span>
                        </label>
                        {activeCreateOrder ? "Ativado" : "Desativado"}
                      </div>
                    </div>
                  </div>
                  <div>
                    <h4>Configuração de Seguro</h4>
                    <p style={{ marginTop: "16px" }}>
                      Abaixo é possível ativar ou desatitvar a cotação de frete
                      com Seguro. Essa opção é válida para todos os fretes{" "}
                      <br /> que são cotados.
                    </p>
                    <div className={styles.contentSection}>
                      <div className={styles.switchOption}>
                        <label className={styles.switch}>
                          <input
                            type="checkbox"
                            onChange={event =>
                              setActiveInsurance(event.target.checked)
                            }
                            checked={activeInsurance}
                            disabled={
                              !hasAccessToken ||
                              !selectedStatusesToAddOrder?.length ||
                              !selectedStatusAfterCreateOrder ||
                              senderNameOK === "notOk"
                            }
                          />
                          <span
                            className={`${styles.slider} ${styles.round}`}
                          ></span>
                        </label>
                        {activeInsurance ? "Ativado" : "Desativado"}
                      </div>
                    </div>
                  </div>
                  <div className={styles.btnContainer}>
                    <button
                      type="submit"
                      onClick={sendData}
                      className={styles.btnSave}
                      disabled={
                        !hasAccessToken ||
                        selectedStatusesToAddOrderOK !== "ok" ||
                        selectedStatusAfterCreateOrderOK !== "ok" ||
                        senderNameOK === "notOk"
                      }
                    >
                      Salvar
                    </button>
                  </div>
                </>
              )}
            </section>
          </div >
        </div >
      </div >
    </>
  );
};

export { BlingV3 };
