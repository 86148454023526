import styled from "styled-components";
import windowSize from "../../../constants/WindowSize";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 100%;
  padding: 20px 0 0 0;
  background-size: cover;
  background-color: #ffffff;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  margin: ${({ width }) => (width >= windowSize.SM ? "40px auto" : 0)};
  max-width: 450px;
`;

export const ContainerWithIcon = styled.div`
  width: 100%;
  padding: ${({ width }) => (width >= windowSize.SM ? `60px 60px` : `0 20px`)};
  border: ${({ width }) => (width >= windowSize.SM ? `1px solid #00A4B2` : 0)};
`;

export const ContainerInputs = styled.div`
  > h1 {
    color: #031d3d;
    margin-bottom: 20px;
    font-family: "Mulish", sans-serif;
    font-weight: 600;
    text-align: center;
  }

  > h3 {
    color: #031d3d;
    text-align: center;
    margin-bottom: 20px;
    font-family: "Mulish", sans-serif;
    font-weight: 600;
  }

  p {
    text-align: center;
    color: #031d3d;
    font-size: 14px;
    font-family: "Mulish", sans-serif;
    font-weight: 500;
  }

  small {
    color: #031d3d;
    font-family: "Mulish", sans-serif;
  }

  button {
    margin-top: 50px;
  }

  a {
    display: flex;
    flex: 1;
    padding: 15px 0;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    border: none;
    color: #031d3d;
    border-radius: 12px;
    font-size: 18px;
    font-weight: 600;
    font-family: "Mulish", sans-serif;

    :hover {
      transition: opacity 0.4s;
      opacity: 0.8;
      text-decoration: underline !important;
    }
  }
`;

export const ContainerReCaptcha = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
`;
