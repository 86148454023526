import React, { useEffect, useState } from "react";
import { FaArrowRotateLeft } from "react-icons/fa6";
import { PiCopySimple } from "react-icons/pi";
import { HiArrowCircleRight } from "react-icons/hi";
import { MdChevronLeft } from "react-icons/md";

import styles from "./styles.module.scss";
import one from "../../../../assets/images/integration/integration-one.svg";
import two from "../../../../assets/images/integration/integration-two.svg";
import three from "../../../../assets/images/integration/integration-three.svg";
import nuvemshop from "../../../../assets/logo/logo-nuvemshop.svg";
import woocommerce from "../../../../assets/logo/logo-woocommerce-2.svg";
import tray from "../../../../assets/logo/logo-tray.svg";
import bling from "../../../../assets/logo/logo-bling.svg";
import blingV3 from "../../../../assets/logo/logo-bling-v3.svg";
import vtex from "../../../../assets/logo/logo-vtex.svg";
import wbuy from "../../../../assets/logo/logo-wbuy.svg";
import wx3 from "../../../../assets/logo/logo-wx3.svg";
import yampi from "../../../../assets/logo/logo-yampi.svg";
import wakecomerce from "../../../../assets/logo/logo-traycorp.svg";
import tiny from "../../../../assets/logo/logo-tiny.svg";
import vnda from "../../../../assets/logo/logo-vnda.svg";
import shopify from "../../../../assets/logo/logo-shopify.svg";

import { BrComerceApiService } from "../../../../services/BrComerceApiService";
import { Loading } from "../../../../components/Loading";
import Swal from "sweetalert2";
import { ImportOrders } from "./ImportOrders";
import { useLocation } from "react-router-dom";

const Integrations = () => {
  const [generatedApiKey, setGeneratedApiKey] = useState("");
  const [copiedApiKey, setCopiedApiKey] = useState(false);
  const [loading, setLoading] = useState(false);
  const [blingUserApiKey, setBlingUserApiKey] = useState(null);
  const [blingStoreAddressBookId, setBlingStoreAddressBookId] = useState(null);
  const [blingLogisticId, setBlingLogisticId] = useState("logisticId");
  const [modal, setModal] = useState(false);
  const [choiceTypeIntegration, setChoiceTypeIntegration] = useState(true);
  const [integrationWithBrcom, setIntegrationWithBrcom] = useState(false);
  const [showImportPage, setShowImportPage] = useState(false);
  const location = useLocation();
  const { search } = location;

  const api = new BrComerceApiService();

  const handleGenerateApiKey = async () => {
    try {
      setLoading(true);
      const response = await api.makeHttpRequest({
        method: "POST",
        url: `/integration`
      });

      setGeneratedApiKey(response.value);
    } catch (e) {
      Swal.fire({
        title: "Erro!",
        text: "Não foi possível gerar a API Key.",
        icon: "error"
      });
    } finally {
      setLoading(false);
    }
  };

  const handleCopyApiKey = () => {
    navigator.clipboard.writeText(generatedApiKey);
    setCopiedApiKey(true);
  };

  const handleConfig = name => {
    Swal.fire({
      title: "Integração",
      showConfirmButton: false,
      html: `<p>Para integrar entrar em contato direto com o suporte da <b>${name}</b>.</p>`,
      showCloseButton: true
    });
  };

  useEffect(() => {
    window.setPageTitle("Integrações");
  }, []);

  useEffect(() => {
    if (!copiedApiKey) return;

    const timeout = setTimeout(() => {
      setCopiedApiKey(false);
    }, 2500);

    return () => clearTimeout(timeout);
  }, [copiedApiKey]);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);

        await Promise.all([
          api
            .makeHttpRequest({
              method: "GET",
              url: `/integration`
            })
            .then(res => {
              if (res) {
                setGeneratedApiKey(res.integration);
              }
            })
        ]);
      } catch (e) {
        Swal.fire({
          title: "Erro!",
          text: "Não foi possível carregar as informações do usuário.",
          icon: "error"
        });
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const response = await api.makeHttpRequest({
          method: "GET",
          url: `/bling/config/key/USER_API_KEY`
        });

        setBlingUserApiKey(response);
      } catch (e) {
        Swal.fire({
          title: "Erro!",
          text: "Não foi possível carregar a API Key da loja da Bling.",
          icon: "error"
        });
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const response = await api.makeHttpRequest({
          method: "GET",
          url: `/bling/config/key/STORE_ADDRESSBOOK_ID`
        });

        setBlingStoreAddressBookId(response);
      } catch (e) {
        Swal.fire({
          title: "Erro!",
          text: "Não foi possível carregar o endereço da loja da Bling.",
          icon: "error"
        });
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const response = await api.makeHttpRequest({
          method: "GET",
          url: `/bling/config/key/LOGISTIC_ID`
        });

        setBlingLogisticId(response);
      } catch (e) {
        Swal.fire({
          title: "Erro!",
          text: "Não foi possível carregar o endereço da loja da Bling.",
          icon: "error"
        });
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    if (search && search.includes("import")) {
      setChoiceTypeIntegration(false);
      setShowImportPage(true);
    }
  }, []);

  function checkUrl(string) {
    try {
      const url = new URL(string);
      return false;
    } catch (error) {
      return true;
    }
  }

  const integrations = [
    {
      image: nuvemshop,
      alt: "nuvemshop",
      helpLink: "/integracao/ajuda-nuvemshop",
      linkConfig: "/integracao/nuvemshop",
      hrefIntegration: "https://www.nuvemshop.com.br/apps/4068/authorize",
      target: "_blank"
    },
    {
      image: woocommerce,
      alt: "woocommerce",
      helpLink: "/integracao/ajuda-woocommerce",
      linkConfig: "/integracao/woocommerce"
    },
    {
      image: tray,
      alt: "tray",
      helpLink: "/integracao/ajuda-tray",
      linkConfig: "/integracao/tray"
    },
    {
      image: bling,
      alt: "bling",
      helpLink: "/integracao/ajuda-bling",
      linkConfig: "/integracao/bling",
      hrefIntegration:
        blingUserApiKey && blingStoreAddressBookId
          ? `http://${window.location.host}/callback/bling`
          : "",
      target: blingUserApiKey && blingStoreAddressBookId ? "_blank" : "_self"
    },
    {
      image: vtex,
      alt: "vtex",
      helpLink: "/integracao/ajuda-vtex",
      linkConfig: "/integracao/vtex",
      // hrefIntegration:
      //   blingUserApiKey && blingStoreAddressBookId
      //     ? `http://${window.location.host}/callback/vtex`
      //     : "",
      target: blingUserApiKey && blingStoreAddressBookId ? "_blank" : "_self"
    },
    {
      image: wbuy,
      alt: "wbuy",
      handleConfig: true
    },
    {
      image: wx3,
      alt: "wx3",
      handleConfig: true
    },
    {
      image: yampi,
      alt: "yampi",
      helpLink: "/integracao/ajuda-yampi",
      linkConfig: "/integracao/yampi"
    },
    {
      image: wakecomerce,
      alt: "wakecomerce",
      helpLink: "/integracao/ajuda-wakecomerce",
      linkConfig: "/integracao/wakecomerce"
    },
    {
      image: tiny,
      alt: "tiny",
      helpLink: "/integracao/ajuda-tiny",
      linkConfig: "/integracao/tiny"
    },
    {
      image: vnda,
      alt: "VNDA",
      helpLink: "/integracao/ajuda-vnda",
      linkConfig: "/integracao/vnda"
    },
    {
      image: shopify,
      alt: "shopify",
      helpLink: "/integracao/ajuda-shopify",
      linkConfig: "/integracao/shopify"
    },
    {
      image: blingV3,
      alt: "blingV3",
      helpLink: "/integracao/ajuda-blingV3",
      linkConfig: "/integracao/blingV3"
    },
  ];

  return (
    <>
      {modal && (
        <div className={styles.modal}>
          <div>
            <div className={styles.title}>
              <h1>Chave de Integração</h1>
            </div>
            <div className={styles.subTitle}>
              <div>
                <span>
                  A chave gerada abaixo é única e intransferível. Lembre-se: ao
                  gerar uma nova chave, a antiga sempre será desabilitada.
                </span>
              </div>
            </div>

            <div className={styles.inputCustom} onClick={handleCopyApiKey}>
              <input
                type="text"
                placeholder=""
                value={generatedApiKey}
                disabled
              />
              <PiCopySimple />
            </div>

            <div
              style={{
                display: copiedApiKey && !!generatedApiKey ? "flex" : "none",
                justifyContent: "center"
              }}
            >
              <p className={styles.textClipped}>Copiado com sucesso!</p>
            </div>

            <div className={styles.buttonContainer}>
              <button
                style={{ backgroundColor: "#3C7DD9" }}
                type="button"
                onClick={handleGenerateApiKey}
              >
                <FaArrowRotateLeft size={18} />
                Gerar nova chave
              </button>
              <button
                style={{ backgroundColor: "#B3B3B3" }}
                type="button"
                onClick={() => {
                  setModal(false);
                }}
              >
                Voltar
              </button>
            </div>
          </div>
        </div>
      )}
      <div className={styles.container}>
        <Loading loading={loading} />
        <div className={styles.content}>
          <div className={styles.contentHeader}>
            {showImportPage ? (
              <>
                <span>
                  <MdChevronLeft
                    size={30}
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setShowImportPage(false);
                      setChoiceTypeIntegration(true);
                    }}
                  />
                  Importação Manual
                </span>
              </>
            ) : (
              <>
                  <span>
                    {
                      integrationWithBrcom && (
                        <MdChevronLeft
                          size={30}
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setIntegrationWithBrcom(false);
                            setChoiceTypeIntegration(true);
                          }}
                        />

                      )
                    }
                  Integrações
                </span>
                <p>
                  {integrationWithBrcom
                    ? "Escolha uma das integrações abaixo para conectar a BRCom como opção de frete em sua loja virtual:"
                    : "Selecione a opção correspondente ao tipo de integração que deseja:"}
                </p>
              </>
            )}
          </div>
          <div className={styles.contentBody}>
            <>
              {choiceTypeIntegration && (
                <>
                  <section>
                    <div>
                      <img src={one} alt="first-image" />
                      <h2>
                        Gerencio minha própria plataforma e preciso de uma chave
                        para integração
                      </h2>
                      <button onClick={() => setModal(true)}>Selecionar</button>
                    </div>
                  </section>
                  <section>
                    <div>
                      <img src={two} alt="two-image" />
                      <h2>
                        Meu e-commerce possui um aplicativo de <br /> integração
                        com a <b>BRCom</b>
                      </h2>
                      <button
                        onClick={() => {
                          setIntegrationWithBrcom(true);
                          setChoiceTypeIntegration(false);
                        }}
                      >
                        Selecionar
                      </button>
                    </div>
                  </section>
                  <section>
                    <div>
                      <img
                        src={three}
                        alt="three-image"
                      />
                      <h2>
                        Estou integrado com a BRCom, mas alguns pedidos não
                        entraram no meu carrinho.
                      </h2>
                      <button
                        onClick={() => {
                          setChoiceTypeIntegration(false);
                          setShowImportPage(true);
                        }}
                      >
                        Selecionar
                      </button>
                    </div>
                  </section>
                </>
              )}
              {integrationWithBrcom && (
                <>
                  <div className={styles.containerSection}>
                    {integrations.map(item => (
                      <div className={styles.integrationCard}>
                        <div>
                          <img src={item.image} alt={item.alt} />
                        </div>
                        <div className={styles.actionLinks}>
                          <div>
                            {item.helpLink && (
                              <a
                                style={{ backgroundColor: "#4BBBDD" }}
                                href={item.helpLink}
                                target='_blank'
                              >
                                Ver passo a passo
                              </a>
                            )}
                            <a
                              style={{ backgroundColor: "#3C7DD9" }}
                              href={item.linkConfig}
                              onClick={() =>
                                item?.handleConfig
                                  ? handleConfig(item?.alt?.toLocaleUpperCase())
                                  : ""
                              }
                            >
                              Configurações
                            </a>
                          </div>
                          {item.hrefIntegration && (
                            <a
                              style={{ backgroundColor: "#409E6A" }}
                              href={item.hrefIntegration}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Fazer integração
                              <HiArrowCircleRight size={22} color="#fff" />
                            </a>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                </>
              )}
              {showImportPage && <ImportOrders />}
            </>
          </div>
        </div>
      </div>
    </>
  );
};

export { Integrations };
