import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { BrComerceApiService } from "../../../../services/BrComerceApiService";
import store from "../../../../store/store";
import { Loading } from "../../../../components/Loading";

const TrayCallback = () => {
  const history = useHistory();
  const api = new BrComerceApiService();

  const { auth } = store.getState();

  const { user } = auth;

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    window.localStorage.removeItem("Brcom:TrayCode:");

    const params = Object.fromEntries(new URLSearchParams(history.location.search.split("?")[1]));

    setLoading(true);
    api
      .makeHttpRequest({
        method: "POST",
        url: `/tray/users`,
        data: {
          code: params.code,
          api_address: params.api_address,
          store_id: params.store,
        }
      })
      .then(response => {
        history.push("/integracao/tray");
      })
      .catch(e => console.log(e.response))
      .finally(() => setLoading(false));
  }, [history.location.search]);
  return (
    <>
      <Loading loading={loading} />
    </>
  );
};

export { TrayCallback };
