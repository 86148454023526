import DeliveryTypeEnum from "../../../../../enums/DeliveryTypeEnum";
export const typeSelect = [
  {
    value: DeliveryTypeEnum.PAC,
    label: "Econômico"
  },
  {
    value: DeliveryTypeEnum.SEDEX,
    label: "Expresso"
  }
];
