/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, {useState} from "react";
import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import { connect } from "react-redux";
import HeaderDropdownToggle from "../content/CustomDropdowns/HeaderDropdownToggle";
import { Modal } from "@material-ui/core";
import ModalAlterPassword from "./components/ModalAlterPassword/index";
import styled from "styled-components";
import Swal from "sweetalert2";
import "./style.scss";
import { withRouter } from "react-router-dom";
import iconUser from "../../assets/icons/icon-user.svg";
import iconEnter from "../../assets/icons/icon-enter-outline.svg";
import iconConfig from "../../assets/icons/icon-configs.svg";
import iconSenha from "../../assets/icons/icon-cadeado.svg";
import iconTrash from "../../assets/icons/trash.svg";
import iconSair from "../../assets/icons/icon-sair.svg";
import chevron from "../../assets/icons/icon-chevron-right.svg";
import { BrComerceApiService } from "../../services/BrComerceApiService";
import { Loading } from "../../components/Loading";

class UserProfile extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      loading: false,
      statusModal: false,
      statusDeleteModal: false,
      viewModal: false,
      sucess: false,
      disabledClickOut: false
    };

  }


  showMensage(sucess, error) {
    if (error) {
      Swal.fire(
        "Erro!",
        error ? error : "Erro ao alterar o status, tente novamente mais tarde.",
        "error"
      );
    } else if (sucess) {
      Swal.fire({
        title: "Sucesso!",
        text: "Senha alterada com sucesso.",
        icon: "success",
        showCancelButton: false,
        confirmButtonText: "Ok",
        cancelButtonText: "Sair"
      });
    }
  }


  render() {
    const { user, showHi, showBadge, history } = this.props;
    const api = new BrComerceApiService();
    const ModalContainer = styled.div`
      display: flex;
      align-items: center;
      justify-content: center;

      width: fit-content;
      height: 100%;
      margin: auto;
      outline: none;
    `;
    const handleOnConfirmDelete = async () => {
      this.setState({loading: true});
      await api
        .makeHttpRequest({
          method: "DELETE",
          url: `/users/0`,
        })
        .then(response => {
          Swal.fire({
            title: "Sucesso!",
            text: "O usuário foi deletado com sucesso.",
            icon: "success",
            showCancelButton: false,
            confirmButtonText: "Ok"
          });
          history.push("/Logout");
        })
        .catch(e => {
          Swal.fire({
            icon: "error",
            title: "Ocorreu um erro!",
            text: "Não foi possível fazer a deleção do usuário."
          });
        })
        .finally(() => {
          this.setState({loading: false});
        });
      };

      const handleDeleteUser = async () => {
        Swal.fire({
          title: "Você tem certeza?",
          html:
            "Deseja prosseguir com a deleção da sua conta?<br>Não será possível desfazer essa ação",
          icon: "warning",
          cancelButtonText: "Cancelar",
          showCancelButton: true,
          confirmButtonText: "Sim, fazer a deleção!"
        }).then(response => {
          if (response?.value) {
            handleOnConfirmDelete();
          }
        });
      };


    return (
      <>
        <Loading loading={this.state.loading} />
        <Modal
          open={this.state.statusModal}
          onClose={() => this.setState({ statusModal: false })}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <ModalContainer>
            <ModalAlterPassword
              closeModal={() => this.setState({ statusModal: false })}
              bg="white"
              result={(sucess, error) => {
                this.showMensage(sucess, error);
                this.setState({ statusModal: false });
              }}
            />
          </ModalContainer>

        </Modal>


        <Dropdown
          className="kt-header__topbar-item kt-header__topbar-item--user"
          drop="down"
          alignRight
        >
          <Dropdown.Toggle
            as={HeaderDropdownToggle}
            id="dropdown-toggle-user-profile"
          >
            <div className="kt-header__topbar-user">
              {showHi && (
                <span className="kt-header__topbar-welcome kt-hidden-mobile">
                  Hi,
                </span>
              )}

              {showHi && (
                <span className="kt-header__topbar-username kt-hidden-mobile">
                  {user?.name}
                </span>
              )}

              {showBadge && (
                <span className="kt-badge kt-badge--username kt-badge--unified-primaryGreen kt-badge--lg kt-badge--rounded kt-badge--bold" style={{textTransform:"capitalize"}}>
                  {user && user?.name[0]}
                </span>
              )}
            </div>
          </Dropdown.Toggle>
          <Dropdown.Menu
            className="dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl"
            style={{
              boxShadow:
                "0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2)",
              borderRadius: "4px"
            }}
          >
            {/** ClassName should be 'dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl' */}

            <div className="userProfile">
              <div className="userLogo">
                <img alt="logo user" src={iconUser} />
              </div>
              <div className="userName">
                <span>Olá, {user?.name}</span>
              </div>
            </div>

            <div className="kt-notification">
              <>
                {!history.location.pathname.includes("/#") && (
                  <>
                    <div className="dropdown-divider m-0" />
                    <div
                      className="option-user-profile"
                      onClick={() => history.push("/#")}
                    >
                      <div className="subMenu subMenuColetas" style={{padding:"14.5px 33px 14.5px 22px"}}>
                        <div className="iconSubMenu iconColetas">
                          <img alt="" src={iconEnter} />
                        </div>
                        <div className="txtsSubMenu">
                          <strong>Minhas coletas</strong>
                          <p>Visualizar minhas coletas</p>
                        </div>
                        <div className="chevronSubMenu">
                          <img alt="" src={chevron} />
                        </div>
                      </div>
                    </div>
                  </>
                )}
                <div className="dropdown-divider m-0" />
                <div
                  className="option-user-profile"
                  onClick={() => history.push("/editar-conta")}
                >
                  <div className="subMenu">
                    <div className="iconSubMenu">
                      <img alt="" src={iconConfig} />
                    </div>
                    <div className="txtsSubMenu">
                      <strong>Editar conta</strong>
                      <p>Alterar informações da conta</p>
                    </div>
                    <div className="chevronSubMenu">
                      <img alt="" src={chevron} />
                    </div>
                  </div>
                </div>
              </>
              <div className="dropdown-divider m-0" />
              <div
                className="option-user-profile"
                onClick={() => this.setState({statusModal: true})}
              >
                <div className="subMenu">
                  <div className="iconSubMenu" style={{width:"21px"}}>
                    <img alt="" src={iconSenha} />
                  </div>
                  <div className="txtsSubMenu">
                    <strong>Minha senha</strong>
                    <p>Alterar a senha de acesso</p>
                  </div>
                  <div className="chevronSubMenu">
                    <img alt="" src={chevron} />
                  </div>
                </div>
              </div>
              <div className="dropdown-divider m-0" />
              <div
                className="option-user-profile"
                onClick={() => handleDeleteUser()}
              >
                <div className="subMenu">
                  <div className="iconSubMenu" style={{width:"21px"}}>
                    <img alt="" src={iconTrash} />
                  </div>
                  <div className="txtsSubMenu">
                    <strong>Deletar conta</strong>
                    <p>Deletar conta de acesso</p>
                  </div>
                  <div className="chevronSubMenu">
                    <img alt="" src={chevron} />
                  </div>
                </div>
              </div>
              <div className="dropdown-divider m-0" />
              <div className="sair">
                <Link to="/logout">
                  <img alt="sair" src={iconSair} />
                  SAIR
                </Link>
              </div>
            </div>
          </Dropdown.Menu>
        </Dropdown>
      </>
    );
  }
}

const mapStateToProps = ({ auth: { user } }) => ({
  user
});

export default withRouter(connect(mapStateToProps)(UserProfile));
