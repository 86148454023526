import styled from 'styled-components';

export const Container = styled.button`
 display: flex;
 height: 56px;
 width: 100%;
 background-color: #FFFFFF;
 border: 1px solid #031D3D;
 color: #031D3D;
 font-family: "Mulish", sans-serif;
 font-weight: 600;
 font-size: 18px;
 font-weight: 600;
 align-items: center;
 justify-content: center;
 margin-bottom: ${({marginBottom}) => marginBottom ? marginBottom : 0};

 svg {
	transform: rotate(90deg);
 }

 :hover {
   transition: ${({isLoading}) => isLoading ? 0 :  '0.3s'};
   opacity: ${({isLoading}) => isLoading ? 1 :  '0.8'};
   border: 1px solid #ffff;
   border: ${({isLoading}) => isLoading && 0};
   color: #fff;
   background: #00A4B2;
  }
`;
