import React from "react";
import { useHistory } from "react-router-dom";
import { SlideCarousel } from "./components";
import "react-responsive-carousel/lib/styles/carousel.css"; // requires a loader

import optionsIntegrations from "../../../../../assets/images/integration/optionsIntegrations.png";
import activeOrderCreation from "../../../../../assets/images/active-order-create.png";

import tinyCard from "../../../../../assets/images/tiny/tinyCard.png";
import tiny1 from "../../../../../assets/images/tiny/tiny1.png";
import tiny2 from "../../../../../assets/images/tiny/tiny2.png";
import tiny3 from "../../../../../assets/images/tiny/tiny3.png";
import tiny4 from "../../../../../assets/images/tiny/tiny4.png";
import tiny5 from "../../../../../assets/images/tiny/tiny5.png";
import tiny6 from "../../../../../assets/images/tiny/tiny6.png";
import tinyManualImportCard from "../../../../../assets/images/manual-import-card.png";
import tinyManualImport1 from "../../../../../assets/images/tiny/tiny-manual-import-1.png";
import tinyManualImport2 from "../../../../../assets/images/tiny/tiny-manual-import-2.png";

import styles from "./styles.module.scss";

const TutorialTiny = () => {
  const history = useHistory();

  const steps1th = [
    { image: tiny1 },
    { image: tiny2 },
  ];

  const steps2th = [
    { image: optionsIntegrations },
    { image: tinyCard }
  ];

  const steps3th = [
    { image: tinyManualImportCard },
    { image: tinyManualImport1 },
    { image: tinyManualImport2 },
  ];


  return (
    <main className={styles.container}>
      <div className={styles.content}>
        <header className={styles.contentHeader}>
          <p>Como fazer a integração?</p>
        </header>
        <div className={styles.contentBody}>
          <section className={styles.contentSection}>
            <h4>
              Passo a passo para conectar sua plataforma Tiny à BRCom
            </h4>

            <div className={styles.bulletpoint}>
              <span>1</span>
              <p>
                No painel <b>Admin</b> da Tiny, gere o <b>Token</b> para
                integração.
              </p>
            </div>

            <SlideCarousel steps={steps1th} />

            <div className={styles.bulletpoint}>
              <span>2</span>
              <p>
                Agora na BRComerce, na tela de <b>Integrações</b>, clique em{" "}
                <b>Selecionar</b> do segundo card e depois clique em{" "}
                <b>Configurações</b> no card da plataforma Tiny.
              </p>
            </div>

            <SlideCarousel steps={steps2th} />

            <div className={styles.bulletpoint}>
              <span>3</span>
              <p>
                Cole o <b>Token</b> que você copiou no passo anterior no campo API Token.
              </p>
            </div>

            <div className={styles.imgs}>
              <img
                src={tiny3}
                alt="Tiny"
                className={styles.cardImage}
              />
            </div>

            <div className={styles.bulletpoint}>
              <span>4</span>
              <p>
                Preencha com os <b>dados do remetente</b>. Comece pelo CEP para
                preencher automaticamente as informações do endereço.
              </p>
            </div>

            <div className={styles.imgs}>
              <img
                src={tiny4}
                alt="Tiny"
                className={styles.cardImage}
              />
            </div>

            <div className={styles.bulletpoint}>
              <span>5</span>
              <p>
                Configure qual será o <b>status do pedido na Tiny</b>, após importação do pedido e criação da coleta.
                <br></br>
                <br></br>
                Os status escolhidos nesse passo não aparecerão para serem importados manualmente.
              </p>
            </div>

            <div className={styles.imgs}>
              <img
                src={tiny5}
                alt="Tiny"
                className={styles.cardImage}
              />
            </div>

            <div className={styles.bulletpoint}>
              <span>6</span>
              <p>
                Configure se quer ativar o <b>Seguro</b> e o <b>Filtro por Marcador</b> e clique em <b>Salvar</b>.
                <br></br>
              </p>
            </div>

            <div className={styles.imgs}>
              <img
                src={tiny6}
                alt="Tiny"
                className={styles.cardImage}
              />
            </div>

            <div className={styles.bulletpoint}>
              <span>7</span>
              <p>
                Pronto! Agora a BRCom esta disponível como forma de envio para seus pedidos.
                <br></br>
                <br></br>
                Importe-os manualmente de acordo com o status, período e caso esteja ativo, o marcardor.
              </p>
            </div>

            <SlideCarousel steps={steps3th} />

            <div className={styles.bulletpoint}>
              <span>8</span>
              <p>
                Caso queira desativar a criação de pedido nessa integração, vire a chave para "Desativado"
                <br></br>
                As demais funcionalidades relacionadas a essa integração continuarão ativas.
              </p>
            </div>

            <div className={styles.imgs}>
              <img
                src={activeOrderCreation}
                alt="activeOrderCreation"
                className={styles.cardImage}
              />
            </div>


            <button
              onClick={() => history.push("/integracoes")}
              className={`${styles.button} ${styles.btnBack}`}
            >
              Voltar
            </button>
          </section>
        </div>
      </div>
    </main>
  );
};

export { TutorialTiny };
