import React from "react";
import { useHistory } from "react-router-dom";
import { SlideCarousel } from "./components";
import "react-responsive-carousel/lib/styles/carousel.css"; // requires a loader

import optionsIntegrations from "../../../../../assets/images/integration/optionsIntegrations.png";
import activeOrderCreation from "../../../../../assets/images/active-order-create.png";

import shopifyCard from "../../../../../assets/images/shopify/shopifyCard.png";
import shopify1 from "../../../../../assets/images/shopify/shopify1.png";
import shopify2 from "../../../../../assets/images/shopify/shopify2.png";
import shopify3 from "../../../../../assets/images/shopify/shopify3.png";
import shopify4 from "../../../../../assets/images/shopify/shopify4.png";
import shopify5 from "../../../../../assets/images/shopify/shopify5.png";
import shopify6 from "../../../../../assets/images/shopify/shopify6.png";
import shopify7 from "../../../../../assets/images/shopify/shopify7.png";
import shopify8 from "../../../../../assets/images/shopify/shopify8.png";
import shopify9 from "../../../../../assets/images/shopify/shopify9.png";
import shopify10 from "../../../../../assets/images/shopify/shopify10.png";
import shopify11 from "../../../../../assets/images/shopify/shopify11.png";
import shopify13 from "../../../../../assets/images/shopify/shopify13.png";
import shopify14 from "../../../../../assets/images/shopify/shopify14.png";
import shopify15 from "../../../../../assets/images/shopify/shopify15.png";
import shopify16 from "../../../../../assets/images/shopify/shopify16.png";

import styles from "./styles.module.scss";

const TutorialShopify = () => {
  const history = useHistory();

  const steps1th = [
    { image: shopify1 },
    { image: shopify2 },
    { image: shopify3 },
    { image: shopify4 },
  ];

  const steps2th = [
    { image: shopify5 },
    { image: shopify6 },
    { image: shopify7 },
    { image: shopify8 },

  ];

  const steps3th = [
    { image: optionsIntegrations },
    { image: shopifyCard },
    { image: shopify9 }
  ];

  const steps4th = [
    { image: shopify11 },
    { image: shopify10 },
    { image: shopify13 }
  ];

  const steps5th = [
    { image: shopify14 },
    { image: shopify15 },
    { image: shopify16 },
  ];

  return (
    <main className={styles.container}>
      <div className={styles.content}>
        <header className={styles.contentHeader}>
          <p>Como fazer a integração?</p>
        </header>
        <div className={styles.contentBody}>
          <section className={styles.contentSection}>
            <h4>
              Passo a passo para conectar sua plataforma Shopify à BRCom
            </h4>

            <div className={styles.bulletpoint}>
              <span>1</span>
              <p>
                No painel <b>Admin</b> da Shopify, crie um <b>App</b> para
                integração.
              </p>
            </div>

            <SlideCarousel steps={steps1th} />

            <div className={styles.bulletpoint}>
              <span>2</span>
              <p>
                Marque todas as caixinhas do escopo, salve e instale o app, agora temos o token de acesso a API, copie-o.
              </p>
            </div>

            <SlideCarousel steps={steps2th} />

            <div className={styles.bulletpoint}>
              <span>3</span>
              <p>Agora na plataforma da BRCom, vamos colar o token de acesso no campo indicado:</p>
            </div>

            <SlideCarousel steps={steps3th} />

            <div className={styles.bulletpoint}>
              <span>4</span>
              <p>
                Agora vamos copiar a URL da loja exibido na plataforma da Shopify e colar no campo indicado e ative.
              </p>
            </div>

            <SlideCarousel steps={steps4th} />

            <div className={styles.bulletpoint}>
              <span>5</span>
              <p>
                Configure os dados do remetente, se deseja seguro.
                <br></br>
                O frete customizado serve para importar pedidos que não foram cotados previamente pela BRCom.
                <br></br>
                Essas configurações serão aplicadas em todos os pedidos importados pela BRCom.
              </p>
            </div>

            <SlideCarousel steps={steps5th} />

            <div className={styles.bulletpoint}>
              <span>6</span>
              <p>
                Pronto agora é só <b>Salvar</b> a integração e aproveitar nossas opções
                de frete. <br />
              </p>
            </div>

            <div className={styles.bulletpoint}>
              <span>7</span>
              <p>
                Caso queira desativar a criação de pedido nessa integração, vire a chave para "Desativado"
                <br></br>
                As demais funcionalidades relacionadas a essa integração continuarão ativas.
              </p>
            </div>

            <div className={styles.imgs}>
              <img
                src={activeOrderCreation}
                alt="activeOrderCreation"
                className={styles.cardImage}
              />
            </div>

            <button
              onClick={() => history.push("/integracoes")}
              className={`${styles.button} ${styles.btnBack}`}
            >
              Voltar
            </button>
          </section>
        </div>
      </div>
    </main>
  );
};

export { TutorialShopify };
