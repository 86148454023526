import styled from "styled-components";

export const Container = styled.div`
  width: 100%;

  h1 {
    margin-bottom: 32px;
  }

  p {
    font-weight: 600;
    font-size: 14px;
    line-height: 140%;
  }

  p + p {
    margin-bottom: 32px;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  gap: 24px;

  button {
    border-radius: 5px;
    width: 100%;
    cursor: pointer;
    padding: 12.5px 12px;
    transition: filter 0.2s;

    font-weight: 700;
    font-size: 18px;
    line-height: 150%;

    color: #409e6a;
    border: 2px solid #409e6a;
    background-color: #fafafa;

    &:hover {
      filter: brightness(0.8);
    }

    & + button {
      color: #ffffff;
      background-color: #409e6a;
      border: 0;
    }
  }
`;
