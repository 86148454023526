const MinInvoiceValueEnum = {
  SEDEX: 25.63,
  PAC: 25.63,
  PACMINI: 12.82,
};

const MaxInvoiceValueEnum = {
  SEDEX: 35571.17,
  PAC: 4184.84,
  PACMINI: 104.62,
};

export { MinInvoiceValueEnum, MaxInvoiceValueEnum };
