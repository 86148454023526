import React, { useCallback, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { AiOutlineCheck } from "react-icons/ai";
import { BiTrash } from "react-icons/bi";
import { BsExclamation } from "react-icons/bs";
import { formatMoney } from "../../../../../../utils/FormatMoney";
import styles from "./styles.module.scss";

const DeclaracaoConteudo = ({
  id,
  handleLineChange,
  data,
  handleRemoveItemListDC,
  validationParam
}) => {
  const [itemOK, setItemOK] = useState("vazio");
  const [itemError, setItemError] = useState("");
  const [item, setItem] = useState("");

  const [valorUnitarioOK, setValorUnitarioOK] = useState("vazio");
  const [valorUnitarioError, setValorUnitarioError] = useState("");
  const [valorUnitario, setValorUnitario] = useState("");

  const [quantidadeOK, setQuantidadeOK] = useState("vazio");
  const [quantidadeError, setQuantidadeError] = useState("");
  const [quantidade, setQuantidade] = useState("");

  const [validation, setValidation] = useState(false);

  useEffect(() => {
    const isInvalid =
      itemOK === "notOk" ||
      valorUnitarioOK === "notOk" ||
      quantidadeOK === "notOk";

    handleLineChange({ id, item, valorUnitario, quantidade, isInvalid });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, item, quantidade, valorUnitario]);

  useEffect(() => {
    setValidation(validationParam);
  }, [validationParam]);

  useEffect(() => {
    if (data?.item) {
      setItem(data?.item || "");
      handleValidationInputItem(data?.item || "");
    }
    if (data?.valorUnitario) {
      setValorUnitario(
        data?.valorUnitario.toLocaleString("pt-BR", {
          minimumFractionDigits: 2
        })
      );
    }
    if (data?.quantidade) {
      handleInputQuantidade(data?.quantidade || 0);
    }
  }, []);

  const handleValidationInputItem = value => {
    value.length <= 1 ? setItemOK("notOk") : setItemOK("ok");
    if (value.length === 0) {
      setItemError("Campo obrigatório");
    } else if (value.length === 1) {
      setItemError("Identificação deve ter pelo menos 1 caractere");
    } else {
      setItemError("");
    }
  };

  const handleInputItem = useCallback(e => {
    let value = e.currentTarget.value;

    if (!value) {
      setItem("");
      setItemError("");
      setItemOK("vazio");
      return;
    }

    setItem(value);
    handleValidationInputItem(value);
  }, []);

  const handleValidationInputValorUnitario = valueNumber => {
    Number(valueNumber) < 1
      ? setValorUnitarioOK("notOk")
      : setValorUnitarioOK("ok");
    if (valueNumber.length === 0) {
      setValorUnitarioError("Campo obrigatório");
    } else if (Number(valueNumber) < 1) {
      setValorUnitarioError("Valor mínimo R$ 1,00");
    } else {
      setValorUnitarioError("");
    }
  };

  const handleInputValorUnitario = useCallback(e => {
    let value = e?.currentTarget?.value ?? e;

    if (!value) {
      setValorUnitario("");
      setValorUnitarioError("");
      setValorUnitarioOK("vazio");
      return;
    }

    setValorUnitario(formatMoney(String(value)));
    setValorUnitarioOK("ok");
    handleValidationInputValorUnitario(value || "");
  }, []);

  const handleValidationInputQntd = value => {
    value.length < 1 ? setQuantidadeOK("notOk") : setQuantidadeOK("ok");
    if (value.length === 0) {
      setQuantidadeError("Campo obrigatório");
    } else {
      setQuantidadeError("");
    }
  };

  const handleInputQuantidade = useCallback(e => {
    let value = e?.currentTarget?.value ?? e;

    if (!value) {
      setQuantidade("");
      setQuantidadeError("");
      setQuantidadeOK("vazio");
      return;
    }

    value = String(value)?.replace(/\D/g, "");
    setQuantidade(value);
    handleValidationInputQntd(value);
  }, []);

  useEffect(() => {
    if (validation) {
      handleValidationInputItem(item);
      handleValidationInputQntd(quantidade);
      handleValidationInputValorUnitario(valorUnitario);
    } else {
      setItemOK("vazio");
      setValorUnitarioOK("vazio");
      setQuantidadeOK("vazio");
    }
  }, [item, quantidade, validation, valorUnitario]);

  return (
    <Container fluid>
      <Row className={styles.container}>
        <Col
          xs={12}
          lg={5}
          className={`${styles.inputContainer} ${styles.inputContainerMd}`}
        >
          <div className={styles.input} role={itemOK}>
            <input
              type="text"
              name="item"
              placeholder="Nome do item"
              onChange={e => handleInputItem(e)}
              value={item}
              autoComplete="1234"
            />
            <AiOutlineCheck className={styles.check} role={itemOK} />
            <BsExclamation className={styles.danger} role={itemOK} />
          </div>
          <p className={styles.error}>{itemError}</p>
        </Col>

        <Col
          xs={5}
          lg={3}
          className={`${styles.inputContainer} ${styles.inputContainerSm}`}
        >
          <div className={styles.input} role={valorUnitarioOK}>
            <input
              type="text"
              name="valorUnitario"
              placeholder="Valor unitário"
              onChange={e => handleInputValorUnitario(e)}
              value={valorUnitario}
            />
            <AiOutlineCheck className={styles.check} role={valorUnitarioOK} />
            <BsExclamation className={styles.danger} role={valorUnitarioOK} />
          </div>
          <p className={styles.error}>{valorUnitarioError}</p>
        </Col>

        <Col
          xs={5}
          lg={3}
          className={` ${styles.inputContainer} ${styles.inputContainerSm}`}
        >
          <div className={styles.input} role={quantidadeOK}>
            <input
              type="text"
              name="quantidade"
              placeholder="Quantidade"
              onChange={e => handleInputQuantidade(e)}
              value={quantidade}
            />
            <AiOutlineCheck className={styles.check} role={quantidadeOK} />
            <BsExclamation className={styles.danger} role={quantidadeOK} />
          </div>
          <p className={styles.error}>{quantidadeError}</p>
        </Col>

        <Col xs={1} className={styles.trashIcon}>
          <BiTrash onClick={() => handleRemoveItemListDC(id)} />
        </Col>
      </Row>
    </Container>
  );
};

export { DeclaracaoConteudo };
