import React from "react";

const TextPrivacy = () => {
    return (
        <>
            <p>Prezado (a) Usuário (a)</p>
            <p>{"A BR COM é uma transportadora focada na realização de envios de encomendas. Atualmente, realizamos envios para todos os municípios do Brasil, e nosso objetivo é atender o cliente Pessoa Jurídica."}</p>
            <p>{"Nós fazemos a coleta na data e endereço agendados por você."}</p>
            <p>{"Atualmente, temos à disposição integrações sistêmicas com as principais plataformas e ERPs."}</p>
            <p>{"A presente Política de Privacidade foi elaborada em conformidade com a Lei 13.709 de 14 de Agosto de 2018 - Lei Geral de Proteção de Dados Pessoas (LGPD) e demais legislações brasileiras aplicáveis."}</p>
            <p>
                <b>Cláusula 1ª:</b>
                {` Esta Política de Privacidade contém informações sobre coleta, uso, armazenamento, tratamento e proteção dos dados pessoais dos usuários e visitantes da Plataforma BR COM,
                        com a finalidade de demonstrar absoluta transparência quanto ao assunto e esclarecer a todos interessados sobre os tipos de dados que são coletados, os motivos da coleta e a forma como os usuários
                        podem gerenciar ou excluir as suas informações pessoais, sendo aplicada a todos os Usuários e visitantes da plataforma.`}
            </p>

            <p>
                <b>Cláusula 2ª:</b>
                {` Ao utilizar a Plataforma BR COM, o Usuário aceita e autoriza a coleta, utilização e demais tratamentos de seus dados, nos termos da presente política de privacidade. `}
            </p>

            <p>
                <b>Cláusula 3ª:</b>
                {` Quando o Usuário cria uma conta na Plataforma BR COM, esses dados são os dados de identificação básicos, como por exemplo: e-mail, nome completo, cidade de residência e telefone de contato. A partir deles, a plataforma pode identificar e garantir uma maior segurança ao Usuário.`}
            </p>
            <p>
                <b>3.1:</b>
                {` A Plataforma BR COM poderá ainda recolher dados referentes ao pagamento e transações, tais como, número do cartão de crédito e outras informações sobre o cartão, além dos pagamentos efetuados pelos Usuários.`}
            </p>
            <p>
                <b>Cláusula 4ª:</b>
                {` Os dados pessoais do Usuário serão armazenados pela Plataforma BR COM durante o período necessário para a prestação do serviço ou o cumprimento das finalidades previstas no presente documento, conforme o disposto no inciso I do artigo 15 da Lei 13.709/18.`}
            </p>
            <p>
                <b>4.1:</b>
                {` Os dados podem ser removidos ou anonimizados a pedido do usuário, excetuando os casos em que a lei oferecer outro tratamento.`}
            </p>
            <p>
                <b>4.2:</b>
                {` Ainda, os dados pessoais dos usuários apenas podem ser conservados após o término de seu tratamento nas seguintes hipóteses previstas no artigo 16 da referida 
                            lei: (i) cumprimento de obrigação legal ou regulatória pelo controlador; (ii) estudo por órgão de pesquisa, garantida, sempre que possível, a anonimização dos dados pessoais; (iii) transferência a terceiro, desde que respeitados os requisitos de tratamento de dados dispostos nesta Lei; (iv) uso exclusivo do controlador, vedado seu acesso por terceiro, e desde que anonimizados os dados`}
            </p>
            <p>
                <b>Cláusula 5ª:</b>
                {` A Plataforma BR COM se compromete a aplicar as medidas técnicas e organizativas aptas a proteger os dados pessoais de acessos não autorizados e de situações de destruição, perda, alteração, comunicação ou difusão de tais dados.`}
            </p>
            <p>
                <b>5.1:</b>
                {` A Plataforma BR COM possui criptografia de dados de ponta a ponta para que os dados fornecidos pelo Usuário não sejam interceptados por hacker ou outros mecanismos maliciosos, conforme disposto no §2º, inciso I, do artigo 50 da Lei 13.790/2018.`}
            </p>
            <p>
                <b>5.2:</b>
                {` A Plataforma BR COM bem como os seus parceiros, colaboradores e afins não poderão ser responsabilizados por fatos oriundos de atividades criminosas (e.g. ataque hacker), caso fortuito ou força maior.`}
            </p>
            <p>
                <b>5.3:</b>
                {` Os dados pessoais armazenados são tratados com confidencialidade, dentro dos limites legais. No entanto, a Plataforma BR COM pode divulgar as informações pessoais dos Usuários caso seja obrigada pela lei para fazê-lo ou se houver violação dos Termos e Condições Gerais de Uso.`}
            </p>
            <p>
                <b>Cláusula 6ª:</b>
                {` Os cookies referem-se a arquivos de texto enviados pela Plataforma BR COM ao computador do Usuário e visitante e que nele ficam armazenados, com informações relacionadas à navegação no site. Tais informações são relacionadas aos dados de acesso como local e horário de acesso e são armazenadas pelo navegador do Usuário e visitante para que o servidor da plataforma possa lê-las posteriormente a fim de personalizar os serviços da plataforma.`}
            </p>
            <p>
                <b>6.1:</b>
                {` O Usuário e o visitante da Plataforma BR COM manifesta conhecer e aceitar que pode ser utilizado um sistema de coleta de dados de navegação mediante à utilização de cookies.`}
            </p>
            <p>
                <b>6.2:</b>
                {` O cookie persistente permanece no disco rígido do Usuário e visitante depois que o navegador é fechado e será usado pelo navegador em visitas subsequentes ao site. Os cookies persistentes podem ser removidos seguindo as instruções do seu navegador. Já o cookie de sessão é temporário e desaparece depois que o navegador é fechado. É possível redefinir seu navegador da web para recusar todos os cookies, porém alguns recursos da plataforma podem não funcionar corretamente se a capacidade de aceitar cookies estiver desabilitada.`}
            </p>
            <p>
                <b>Cláusula 7ª:</b>
                {` Ao utilizar os serviços e fornecer as informações pessoais na plataforma, o Usuário está consentindo com a presente Política de Privacidade.`}
            </p>
            <p>
                <b>Cláusula 8ª:</b>
                {` O Usuário, ao cadastrar-se, manifesta conhecer e poder exercitar seus direitos de cancelar seu cadastro, acessar e atualizar seus dados pessoais e garante a veracidade das informações por ele disponibilizadas.`}
            </p>
            <p>
                <b>Cláusula 9ª:</b>
                {` A Plataforma BR COM reserva o direito de modificar essa Política de Privacidade a qualquer momento, sem necessidade de comunicação prévia aos Usuários.`}
            </p>
            <p>
                <b>9.1:</b>
                {` As alterações e esclarecimentos vão surtir efeito imediatamente após sua publicação na plataforma. Ao utilizar o serviço ou fornecer informações pessoais após eventuais modificações, o Usuário e visitante demonstram sua concordância com as novas normas.`}
            </p>
            <p>
                <b>Cláusula 10ª:</b>
                {` Diante da fusão ou venda da Plataforma BR COM à outra empresa os dados dos Usuários podem ser transferidos para os novos proprietários para garantir a permanência dos serviços oferecidos.`}
            </p>
            <p>
                <b>Cláusula 11ª:</b>
                {` A presente Política de Privacidade aborda apenas o uso e divulgação de informações coletadas pela Plataforma BR COM. Se o Usuário divulgar suas informações a terceiros em redes abertas ou através de sites em toda a internet, regras diferentes podem ser aplicadas à utilização de suas informações. A Plataforma BR COM não controla as políticas de privacidade de terceiros, às quais o Usuário possa estar sujeito.`}
            </p>
            <p>
                <b>Cláusula 12ª:</b>
                {` A presente Política de Privacidade é regida pelas leis brasileiras, e fica definido o foro da Comarca da Capital do Rio de Janeiro como competente para dirimir eventuais controvérsias oriundas desta, em prejuízo de qualquer outro, por mais privilegiado que seja ou venha a ser.`}
            </p>
            <p>Atualizada em 25 de julho de 2024</p>
        </>
    )

}

export default TextPrivacy;
