import React from "react";
import { FaCheck } from "react-icons/fa";

import styles from "./styles.module.scss";

export default function Steps({ step, total }) {
  const timeline = [];

  for (let i = 1; i <= total; i++) {
    const objeto = {
      step: i,
      label: `Etapa ${i}`
    };
    timeline.push(objeto);
  }

  const handleStepConfirmed = itemStep => {
    let value = "#b3b3b3";

    if (itemStep <= step) {
      value = "#409e6a";
    }

    return value;
  };

  return (
    <div className={styles.timelineContainer}>
      <div className={styles.timeline}>
        {timeline.map(item => (
          <>
            <div className={styles.step} key={item.step}>
              <div className={styles.content}>
                <div
                  style={{ background: handleStepConfirmed(item.step) }}
                  className={styles.dot}
                >
                  {item.step < step ? <FaCheck size={18} /> : item.step}
                </div>
              </div>
              <p>{item.label}</p>
            </div>
            {item.step !== total && (
              <div
                style={{ background: handleStepConfirmed(item.step) }}
                className={styles.separator}
              />
            )}
          </>
        ))}
      </div>
    </div>
  );
}
