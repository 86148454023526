export const statusSelect = [
  {
    value: "0",
    label: "Qualquer status"
  },
  {
    value: "1",
    label: "Pago / Aguardando Pagamento",
  },
  {
    value: "11",
    label: "Faturado - Preparado para Envio"
  },
  {
    value: "14",
    label: "Aprovado Analise"
  },
  {
    value: "20",
    label: "Pedido Conferido"
  }
];
