/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/pages/auth/AuthPage`, `src/pages/home/AdminPage`).
 */

import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import { Switch, withRouter } from "react-router-dom";
import { useLastLocation } from "react-router-last-location";
import { LayoutContextProvider } from "../../_metronic";
import Layout from "../../_metronic/layout/Layout";
import AdminPage from "../pages/admin/AdminPage";
import AuthPage from "../pages/auth/AuthPage";
import LogoutPage from "../pages/auth/Logout";
import HomePage from "../pages/home";
import PrivacyPolicyPage from "../pages/policy/PrivacyPolicyPage";
import RecoverOrRegisterPassword from "../pages/sessionStudent/RecoverOrRegisterPassword";
import { ShippingDetailsPublic } from "../pages/ShippingDetailsPublic";
import * as routerHelpers from "./RouterHelpers";
import PublicRoute from "./components/PublicRoute";
import PrivateRoute from "./components/PrivateRoute";
import TermsOfUsePage from "../pages/terms/TermsOfUsePage";

export const Routes = withRouter(({ history }) => {
  const lastLocation = useLastLocation();

  routerHelpers.saveLastLocation(lastLocation);

  const { isAuthorized, menuConfig, userLastLocation, isBlocked } = useSelector(
    ({ auth, builder: { menuConfig } }) => ({
      menuConfig,
      isAuthorized: !!auth.user,
      userLastLocation: routerHelpers.getLastLocation(),
      isBlocked: !!auth.user && auth.user.blocked
    }),
    shallowEqual
  );

  return (
    /* Create `LayoutContext` from current `history` and `menuConfig`. */
    <LayoutContextProvider history={history} menuConfig={menuConfig}>
      <Switch>
        <PublicRoute path="/auth" isAuthenticated={isAuthorized}>
          <AuthPage />
        </PublicRoute>

        <PublicRoute exact path="/logout" isAuthenticated={isAuthorized}>
          <LogoutPage />
        </PublicRoute>

        {!isAuthorized &&
          <PublicRoute
            exact
            path="/rastreio/:rastreio"
            isAuthenticated={isAuthorized}
          >
            <ShippingDetailsPublic />
          </PublicRoute>
        }

        <PublicRoute
          exact
          path="/privacy-policy"
          isAuthenticated={isAuthorized}
        >
          <PrivacyPolicyPage />
        </PublicRoute>

        <PublicRoute
          exact
          path="/terms-of-use"
          isAuthenticated={isAuthorized}
        >
          <TermsOfUsePage />
        </PublicRoute>

        <PublicRoute exact path="/ativar-conta" isAuthenticated={isAuthorized}>
          <RecoverOrRegisterPassword />
        </PublicRoute>

        <PublicRoute
          exact
          path="/recuperar-senha"
          isAuthenticated={isAuthorized}
        >
          <RecoverOrRegisterPassword />
        </PublicRoute>

        <PublicRoute
          exact
          path="/cadastrar-senha"
          isAuthenticated={isAuthorized}
        >
          <RecoverOrRegisterPassword />
        </PublicRoute>

        <PrivateRoute path="/" isAuthenticated={isAuthorized} isBlocked={isBlocked}>
          <Layout>
            <AdminPage userLastLocation={userLastLocation} />
          </Layout>
        </PrivateRoute>
        <PublicRoute exact path="/home" isAuthenticated={isAuthorized}>
          <HomePage />
        </PublicRoute>
      </Switch>
    </LayoutContextProvider>
  );
});
