import React, {useState, useCallback} from 'react';
import { debounce } from 'lodash';

import iconVisibility from '../../../../assets/icons/visibility.svg';
import iconNotVisibility from '../../../../assets/icons/not_visibility.svg';
import {Container} from './styles';

const Input = ({
        type,
        icon,
        name,
        placeholder,
        value,
        onChange,
        onBlur,
        messageError,
        isError,
          }) => {

  const [visibilityPassword, setVisibilityPassword] = useState(false);
  const [errorDebounce, setErrorDebounce] = useState(false);

  const onChangeDebounc = useCallback(debounce((messageError) => {
       setErrorDebounce(false);
       if(!!messageError){
        return setErrorDebounce(true);
      }
      setErrorDebounce(false);
     }, 1000),[debounce, errorDebounce]);

  const handleChange = (event) => {
    onChange(event)
    onChangeDebounc(messageError);
  }

  return (
    <Container errorProps={(isError || (errorDebounce && !!messageError))} >
      <i className={icon} />
      <div>
        { (isError || (errorDebounce && !!messageError)) && <small>{messageError}</small> }
        <input
         value={value}
         onChange={handleChange}
         name={name}
         onBlur={onBlur}
         className='col'
         placeholder={placeholder}
         type={type === 'password' ?
         visibilityPassword ? 'text' : 'password' :
         type
         }

         />
       </div>
      {(type === 'password' &&
        <div
         style={{flex: 0}}
         onClick={() => setVisibilityPassword(!visibilityPassword)}>
               <img
                 src={visibilityPassword ? iconVisibility : iconNotVisibility}
                 style={{width: 20, margin: 0}}
                 alt="logo visibility" />
            </div>)}
    </Container>
  );
};

export default Input;
