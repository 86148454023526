/* eslint-disable jsx-a11y/alt-text */
import { addDays } from "date-fns";
import { ptBR } from "date-fns/locale";
import React, { useState } from "react";
import { Calendar } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { FiHelpCircle } from "react-icons/fi";
import Swal from "sweetalert2";
import CheckCircle from "../../../../assets/icons/check-circle.svg";
import { BrComerceApiService } from "../../../../services/BrComerceApiService";
import {
  CalendarContainer,
  Container,
  RightContainer,
  SendButtonContainer,
  TooltipBox,
  TooltipCard,
  TooltipText
} from "./styles";

export const DateFilter = ({
  scheduleCollectsIds,
  setShowModal,
  setCollect,
  loadShippingsByCollect,
  setPackagesFiltered,
  setCollectStage,
  handleCollectSelection
}) => {
  const api = new BrComerceApiService();
  const today = new Date();
  const tomorrow = addDays(today, 1);
  const hour = today.getHours()

  const [date, setDate] = useState();
  const [preferedTime, setPreferedTime] = useState("");
  const [instructions, setInstructions] = useState("");

  const formIsValid = () => {
    return !!date && !!preferedTime && !!instructions;
  };

  const isSchedulingToday = (date) => {
    return String(date).substring(0, 15) === String(today).substring(0, 15)
  }

  const handleSelect = date => {
    setDate(date);
    if(isSchedulingToday(date) && preferedTime.length > 0) {
      setPreferedTime("afternoon")
    }
  };

  const handleSendForm = async event => {
    event.preventDefault();
    try {
      await api
        .makeHttpRequest({
          method: "POST",
          url: `/collects/schedule-collect`,
          data: {
            collectsIds: scheduleCollectsIds,
            collectDate: date,
            collectPeriod: preferedTime,
            collectInstructions: instructions
          }
        })
        .then(async response => {
          const arrayCollect = await loadShippingsByCollect()
          await handleCollectSelection(arrayCollect);
          setCollect(response?.[0]);
          setShowModal(false);
          setPackagesFiltered(arrayCollect.shippings)
        });

      Swal.fire({
        title: "Sucesso!",
        text: "Coleta agendada com sucesso.",
        icon: "success",
        showCancelButton: false,
        confirmButtonText: "Ok"
      });
    } catch (e) {
      console.log(e);
      Swal.fire({
        icon: "error",
        title: "Coletas",
        text: "Não foi possível agendar a coleta."
      });
    }
  };

  return (
    <Container>
      <CalendarContainer>
        <div className="calendarHeader">
          <h3>
            Agende sua coleta
            <TooltipCard>
              <TooltipText>
                <FiHelpCircle />
              </TooltipText>
              <TooltipBox>
                <div>
                  <img src={CheckCircle} />
                  <p>
                    O prazo de retirada inicia-se após a confirmação do
                    pagamento pela BRCom e recebimento do e-mail com a
                    confirmação de que o pedido está disponível para retirada.
                  </p>
                </div>
                <div>
                  <img src={CheckCircle} />
                  <p>
                    O produto somente será entregue no horário de comercial e
                    após o recebimento do e-mail com a confirmação de que o
                    pedido está disponível para retirada.
                  </p>
                </div>
                <div>
                  <img src={CheckCircle} />
                  <p>
                    A data de disponibilidade para retirada é uma previsão,
                    podendo ser alterada.
                  </p>
                </div>
                <div>
                  <img src={CheckCircle} />
                  <p>
                    O cliente deverá verificar se não há nenhum dano ou avaria
                    aparente no produto e se todos os acessórios descritos na
                    embalagem estão presentes no momento da retirada.
                  </p>
                </div>
              </TooltipBox>
            </TooltipCard>
          </h3>
          <hr />
        </div>
        <Calendar
          date={date}
          minDate={hour >= 12 ? tomorrow : today}
          onChange={handleSelect}
          color="#409E6A"
          locale={ptBR}
          weekStartsOn={1}
          weekdayDisplayFormat="EEEEEE"
        />
      </CalendarContainer>
      <RightContainer>
        <h3>Preferência de horário?</h3>
        <hr />
        <p>UTC - 03:00 Brasil</p>
        <button
          onClick={() => setPreferedTime("morning")}
          className={isSchedulingToday(date) ? "disabled" : preferedTime === "morning" ? "active" : ""}
          disabled={isSchedulingToday(date)}
        >
          Manhã (08h00am - 12h00am)
        </button>
        <button
          onClick={() => setPreferedTime("afternoon")}
          className={preferedTime === "afternoon" ? "active" : ""}
        >
          Tarde (13h00pm - 18h00pm)
        </button>
        <div className="input">
          <label htmlFor="instructions">Instrucões de coleta</label>
          <textarea
            name="instructions"
            rows="6"
            placeholder="Digite as observações de sua coleta"
            value={instructions}
            onChange={({ target }) => setInstructions(target.value)}
          />
        </div>
        <SendButtonContainer>
          <button disabled={!formIsValid()} onClick={handleSendForm}>
            Agendar
          </button>
        </SendButtonContainer>
      </RightContainer>
    </Container>
  );
};
