import { FormControlLabel, styled, TextField } from "@material-ui/core";

export const StyledTextField = styled(TextField)({
  "& label ": {
    color: "#4D4C4D",
    fontSize: "18px",
    fontFamily: "Mulish, sans-serif"
  },
  "& label.Mui-focused:not(.Mui-error)": {
    color: "#409E6A",
    fontSize: "18px",
    fontFamily: "Mulish, sans-serif"
  },
  "& label.Mui-focused .Mui-error": {
    color: "#CA1818",
  },
  "& .MuiInputLabel-root.Mui-focused:not(.Mui-error)": {
    color: "#409E6A !important",
    fontSize: "18px",
    fontFamily: "Mulish, sans-serif"
  },
  "& .MuiInputLabel-root.Mui-focused .Mui-error": {
    color: "#CA1818 !important",
    fontSize: "18px",
    fontFamily: "Mulish, sans-serif"
  },
  "& .MuiOutlinedInput-root, .MuiInputBase-root": {
    "& fieldset": {
      borderWidth: "1px",
      borderColor: "#4D4C4D",
      fontFamily: "Mulish, sans-serif"
    },
    "&.Mui-focused fieldset": {
      borderColor: "#409E6A",
      borderWidth: "1px",
      fontFamily: "Mulish, sans-serif"
    },
    "&.Mui-focused.Mui-error fieldset": {
      borderColor: "#CA1818",
      borderWidth: "1px",
      fontFamily: "Mulish, sans-serif"
    }
  },
  "&.MuiInputBase-input, .MuiOutlinedInput-input": {
    padding: "17px 14px",
    fontFamily: "Mulish, sans-serif",
  },
  "&.MuiFormLabel-root, .MuiInputLabel-root, .MuiInputLabel-formControl": {
    top: "-3.5px"
  }
});

export const StyledFormControlLabel = styled(FormControlLabel)({
  "&.MuiFormControlLabel-root .MuiTypography-body1": {
    fontFamily: "Mulish, sans-serif !important"
  }
});

