import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest } from "redux-saga/effects";
import { getUserByToken } from "../../crud/auth.crud";
import * as routerHelpers from "../../router/RouterHelpers";

export const actionTypes = {
    Login: "[Login] Action",
    Logout: "[Logout] Action",
    LogoutWithLastLocation: "[Logout With Last Location] Action",
    Refresh: "[Refresh] Action",
    Register: "[Register] Action",
    UserRequested: "[Request User] Action",
    UserLoaded: "[Load User] Auth API",
    UserUnAuthorized: "[User State]"
};

const initialAuthState = {
    user: undefined,
    authData: undefined,
    authorized: false
};

export const reducer = persistReducer({ storage, key: "auth:brcom", whitelist: ["user", "authData", "authorized"] },
    (state = initialAuthState, action) => {
        switch (action.type) {
            case actionTypes.Login:
                {
                    const { authData } = action.payload;
                    return { authData, user: undefined, authorized: true };
                }

            case actionTypes.Refresh:
                {
                    const { authData } = action.payload;
                    return {...state, authData };
                }

            case actionTypes.Register:
                {
                    const { authData } = action.payload;
                    return { authData, user: undefined, authorized: true };
                }

            case actionTypes.Logout:
                {
                    routerHelpers.forgotLastLocation();
                    return initialAuthState;
                }

            case actionTypes.LogoutWithLastLocation:
                {
                    return initialAuthState;
                }

            case actionTypes.UserLoaded:
                {
                    const { user } = action.payload;

                    return {...state, user, authorized: true };
                }
            case actionTypes.UserUnAuthorized:
                {
                    const { payload } = action;

                    return {...state, authorized: payload };
                }

            default:
                return state;
        }
    }
);

export const actions = {
    login: authData => ({ type: actionTypes.Login, payload: { authData } }),
    refresh: authData => ({ type: actionTypes.Refresh, payload: { authData } }),
    register: authData => ({
        type: actionTypes.Register,
        payload: { authData }
    }),
    logout: () => ({ type: actionTypes.Logout }),
    logoutWithLastLocation: () => ({ type: actionTypes.LogoutWithLastLocation }),
    requestUser: user => ({ type: actionTypes.UserRequested, payload: { user } }),
    fulfillUser: user => ({ type: actionTypes.UserLoaded, payload: { user } }),
    unAuthorized: () => ({ type: actionTypes.UserUnAuthorized, payload: false })
};

export function* saga() {
    yield takeLatest(actionTypes.Login, function* loginSaga() {
        yield put(actions.requestUser());
    });

    yield takeLatest(actionTypes.Register, function* registerSaga() {
        yield put(actions.requestUser());
    });

    yield takeLatest(actionTypes.Refresh, function* refreshSaga() {
        yield put(actions.requestUser());
    });

    yield takeLatest(actionTypes.UserRequested, function* userRequested() {
        const data = yield getUserByToken();

        yield put(actions.fulfillUser(data));
    });
}
