export default () => {
  const menu = {
    header: {
      self: {},
      items: [
        {
          title: "Dashboards",
          root: true,
          alignment: "left",
          page: "dashboard",
          translate: "MENU.DASHBOARD"
        }
      ]
    },
    aside: {
      self: {},
      items: []
    }
  };

  menu.aside.items.push({
    title: "Usuários",
    root: true,
    icon: "flaticon2-user-1",
    bullet: "dot",
    page: "admin/usuarios"
  });

  return menu;
};
