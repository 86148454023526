import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import { Loading } from "../../../../components/Loading";
import { BrComerceApiService } from "../../../../services/BrComerceApiService";
import store from "../../../../store/store";


const BlingV3Callback = () => {
  const history = useHistory();
  const api = new BrComerceApiService();

  const { auth } = store.getState();

  const { user } = auth;

  const [loading, setLoading] = useState(false);

  const sendData = useCallback(
    async (code) => {
      setLoading(true);

      await api.makeHttpRequest({
        method: "POST",
        url: `/blingV3/auth`,
        data: {
          code,
          id: user.id
        }
      })
        .then(r => { history.push("/integracao/blingV3") })
        .catch(e => {
          console.log(e.response);

          Swal.fire({
            title: "Algo deu errado",
            icon: "error",
            html: "Revise as credenciais e tente novamente"
          })
        })
        .finally(() => {
          setLoading(false)
        })
    }, []);


  useEffect(() => {
    const code = history.location.search.split("=")[1].split("&")[0];
    sendData(code);
  }, [history.location.search]);

  return (
    <>
      <Loading loading={loading} />
    </>
  );
};

export { BlingV3Callback };
