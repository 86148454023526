import React from "react";
import { Link, Switch, Route, Redirect } from "react-router-dom";
import "../../../_metronic/_assets/sass/pages/login/login-1.scss";
import logo from "../../assets/logo/logo_brcomerce_branco.svg";
import Login from "./Login";
import Registration from "./Registration";
import Password from "./Password";
import PassActions from "./Password/enum/Actions";
import Content from "../home/components/Content/Content";
import { RegisterForm } from "./Registration/RegisterForm";

export default function AuthPage() {
  return (
    <>
      <div
        className="kt-grid kt-grid--ver kt-grid--root"
        style={{
          height: "100%"
        }}
      >
        <div
          id="kt_login"
          className=" kt-grid kt-grid--hor kt-grid--root kt-login kt-login--v1"
        >
          <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--desktop kt-grid--ver-desktop kt-grid--hor-tablet-and-mobile">
            <div className="kt-grid__item kt-grid__item--order-tablet-and-mobile-2 kt-grid kt-grid--hor kt-login__aside">
              <div className="kt-grid__item">
                <Content loading={!logo}>
                  <Link
                    to="/home"
                    className="kt-login__logo justify-content-center"
                  >
                    <img alt="Logo" src={logo} />
                  </Link>
                </Content>
              </div>
            </div>

            <div
              style={{ padding: 0 }}
              className="kt-grid__item kt-grid__item--fluid kt-grid__item--order-tablet-and-mobile-1  kt-login__wrapper"
            >
              <Switch>
                <Route
                  exact
                  path="/auth/ativar-conta"
                  component={() => <Password action={PassActions.CriarConta} />}
                />
                <Route
                  exact
                  path="/auth/recuperar-senha"
                  component={() => (
                    <Password action={PassActions.RecuperarSenha} />
                  )}
                />
                <Route
                  exact
                  path="/auth/cadastrar-senha"
                  component={() => (
                    <Password action={PassActions.CadastrarSenha} />
                  )}
                />

                <Route exact path="/auth/login" component={Login} />

                <Route
                  exact
                  path="/auth/registration"
                  component={Registration}
                />
                <Route
                  exact
                  path="/auth/registration/:flagCompany"
                  component={RegisterForm}
                />
                <Redirect from="/auth" exact to="/auth/login" />
                {/* <Redirect to="/auth/login" /> */}
              </Switch>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
