import React from 'react';
import useGetWindowWidth from '../../utils/useGetWindowWidth';
import {
    Container,
} from './styles';
import TextTerms from './TextTerms';

const TermsOfUsePage = ({ location }) => {
    const width = useGetWindowWidth();
    return (
        <Container width={width}>
            <h2>TERMOS E CONDIÇÕES GERAIS DE USO</h2>
            <TextTerms />
        </Container>
    )
};
export default TermsOfUsePage;
