import styled from "styled-components";


export const ModalContainer = styled.div`
display: flex;
flex-direction: column;
align-items: center;
width: 488px;
gap: 24px;
padding: 32px 0;
box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12),
  0px 1px 3px rgba(0, 0, 0, 0.2);
border-radius: 12px;
background-color: #ffffff;

p {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21.6px;
  text-align: center;
  margin-bottom: 0px;
  width: 420px;
}
`;

export const ModalButtonsContainer = styled.div`
display: flex;
gap: 24px;

button {
  border-radius: 5px;
  width: 200px;
  height: 48px;
  cursor: pointer;
  transition: filter 0.2s;

  font-weight: 700;
  font-size: 18px;
  line-height: 150%;

  color: #409e6a;
  border: 2px solid #409e6a;
  background-color: #fafafa;

  &:hover {
    filter: brightness(0.8);
  }

  & + button {
    color: #ffffff;
    background-color: #409e6a;
    border: 0;
  }
}
`;
