import React, { useCallback, useState, useEffect } from "react";
import { Loading } from "../../../../../components/Loading";
import styles from "./styles.module.scss";
import { BrComerceApiService } from "../../../../../services/BrComerceApiService.js";
import Swal from "sweetalert2";
import { optionsShippingType } from "../utils/optionsShippingType";
import Select from "react-select";

const Nuvemshop = () => {
  const api = new BrComerceApiService();

  const [senderName, setSenderName] = useState("");
  const [senderNameOK, setSenderNameOK] = useState("");
  const [senderNameError, setSenderNameError] = useState("");

  const [loading, setLoading] = useState(false);
  const [activeInsurance, setActiveInsurance] = useState(false);

  const [activeCreateOrder, setActiveCreateOrder] = useState(true);

  const [activeImportedRate, setActiveImportedRate] = useState(false);

  const [importedRate, setImportedRate] = useState("");
  const [importedRateOK, setImportedRateOK] = useState("");

  const [importedShippingType, setImportedShippingType] = useState();
  const [importedShippingTypeOK, setImportedShippingTypeOK] = useState("");

  const [sedex, setSedex] = useState("");
  const [sedexOK, setSedexOK] = useState("");

  const [pac, setPac] = useState("");
  const [pacOK, setPacOK] = useState("");

  const [mini, setMini] = useState("");
  const [miniOK, setMiniOK] = useState("");

  const [ownDelivery, setOwnDelivery] = useState("");
  const [ownDeliveryOK, setOwnDeliveryOK] = useState("");

  const [zipOriginOK, setZipOriginOK] = useState("vazio");
  const [zipOriginError, setZipOriginError] = useState("");
  const [zipOrigin, setZipOrigin] = useState("");

  const [addressOK, setAddressOK] = useState("vazio");
  const [addressError, setAddressError] = useState("");
  const [address, setAddress] = useState("");

  const [addressObsOK, setAddressObsOK] = useState("vazio");
  const [addressObsError, setAddressObsError] = useState("");
  const [addressObs, setAddressObs] = useState("");

  const [addressNumberOK, setAddressNumberOK] = useState("vazio");
  const [addressNumber, setAddressNumber] = useState("");

  const [addressNeighborhoodOK, setAddressNeighborhoodOK] = useState("vazio");
  const [addressNeighborhoodError, setAddressNeighborhoodError] = useState("");
  const [addressNeighborhood, setAddressNeighborhood] = useState("");

  const [canActivate, setCanActive] = useState(false);

  const [uf, setUf] = useState("");
  const [ufOK, setUfOK] = useState("");

  const [city, setCity] = useState("");
  const [cityOK, setCityOK] = useState("");

  const handleSubmit = useCallback(
    async (key, value) => {
      api.makeHttpRequest({
        url: "/nuvemshop/config",
        method: "POST",
        data: {
          key: key,
          value: value
        }
      });
    },
    [api]
  );

  const handleInputSenderName = useCallback(e => {
    const name = e.currentTarget.value;

    if (name.length < 1) {
      setSenderName("");
      setSenderNameError("");
      setSenderNameOK("vazio");
    } else if (name.length < 2) {
      setSenderName(name);
      setSenderNameError("Nome do remetente deve ter pelo menos 2 caracteres");
      setSenderNameOK("notOk");
    } else {
      setSenderName(name);
      setSenderNameError("");
      setSenderNameOK("ok");
    }
  }, []);

  const handleInputSedex = useCallback(e => {
    const valueNumber = e.currentTarget.value.replace(/\D/g, "");
    if (valueNumber > 99) {
      return;
    } else if (valueNumber === "") {
      setSedex("");
      setSedexOK("vazio");
    } else {
      setSedex(valueNumber);
      setSedexOK("ok");
    }
  }, []);

  const handleInputPac = useCallback(e => {
    const valueNumber = e.currentTarget.value.replace(/\D/g, "");
    if (valueNumber > 99) {
      return;
    } else if (valueNumber === "") {
      setPac("");
      setPacOK("vazio");
    } else {
      setPac(valueNumber);
      setPacOK("ok");
    }
  }, []);

  const handleInputMini = useCallback(e => {
    const valueNumber = e.currentTarget.value.replace(/\D/g, "");
    if (valueNumber > 99) {
      return;
    } else if (valueNumber === "") {
      setMini("");
      setMiniOK("vazio");
    } else {
      setMini(valueNumber);
      setMiniOK("ok");
    }
  }, []);

  const handleInputOwnDelivery = useCallback(e => {
    const valueNumber = e.currentTarget.value.replace(/\D/g, "");
    if (valueNumber > 99) {
      return;
    } else if (valueNumber === "") {
      setOwnDelivery("");
      setOwnDeliveryOK("vazio");
    } else {
      setOwnDelivery(valueNumber);
      setOwnDeliveryOK("ok");
    }
  }, []);

  const handleInputImportedRate = useCallback(e => {
    const value = e.currentTarget.value;
    if (value.trim() === "") {
      setImportedRate("");
      setImportedRateOK("notOK");
    } else {
      setImportedRate(value);
      setImportedRateOK("ok");
    }
  }, []);

  const handleChangeImportedShippingType = e => {
    setImportedShippingType(e);
    setImportedShippingTypeOK("ok");
  };

  const handleActiveImportedRate = () => {
    handleSubmit("HAS_IMPORTED_RATE", "true");
    handleSubmit("IMPORTED_RATE", importedRate);
    handleSubmit("IMPORTED_SHIPPING_TYPE", importedShippingType.value);
  };

  const sendData = async () => {
    const zipOriginNumber = zipOrigin.replace(/\D/g, "");

    const addressComplet = {
      zip: zipOriginNumber,
      street: address,
      number: addressNumber,
      complement: addressObs,
      district: addressNeighborhood,
      city: city,
      state: uf
    };

    console.log(
      "🚀 ~ file: index.jsx:179 ~ sendData ~ addressComplet:",
      addressComplet
    );

    try {
      await Promise.all([
        handleSubmit("SENDER_DEFAULT_NAME", senderName),
        handleSubmit("SENDER_DEFAULT_ADDRESS", JSON.stringify(addressComplet)),
        handleSubmit("ADD_DAYS_SEDEX", sedex),
        handleSubmit("ADD_DAYS_PAC", pac),
        handleSubmit("ADD_DAYS_PACMINI", mini),
        handleSubmit("ADD_DAYS_OWN_DELIVERY", ownDelivery),
        activeInsurance
          ? handleSubmit("IS_ACTIVE_INSURANCE", "true")
          : handleSubmit("IS_ACTIVE_INSURANCE", "false"),
        activeCreateOrder
          ? handleSubmit("IS_INACTIVE_ORDER_CREATE", "false")
          : handleSubmit("IS_INACTIVE_ORDER_CREATE", "true"),
        activeImportedRate
          ? handleActiveImportedRate()
          : handleSubmit("HAS_IMPORTED_RATE", "false")
      ]);

      Swal.fire({
        title: "Sucesso!",
        text: "Configurações cadastradas com sucesso.",
        icon: "success",
        showCancelButton: false,
        confirmButtonText: "Ok"
      });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Ocorreu um erro!",
        text: "Erro ao cadastrar configurações"
      });
    }
  };

  const handleCepConsult = useCallback(async cep => {
    try {
      setLoading(true);
      let response = await api.makeHttpRequest({
        method: "POST",
        url: `/addressbook/getAddress`,
        data: {
          zipCode: cep
        }
      });

      if (response?.erro === true) {
        setZipOriginOK("notOk");
        setZipOriginError("CEP inválido.");

        setAddress("");
        setAddressOK("vazio");
        setAddressError("");

        setAddressNeighborhood("");
        setAddressNeighborhoodOK("vazio");
        setAddressNeighborhoodError("");

        setUf("");
        setUfOK("");
        setCity("");
        setCityOK("");

        throw new Error("Não foi possível encontrar o CEP digitado.");
      }

      setZipOriginOK("ok");
      setZipOriginError("");

      if (!response.logradouro || response.logradouro === "undefined") {
        setAddressOK("vazio");
      } else {
        setAddress(response.logradouro);
        setAddressOK("ok");
        setAddressError("");
      }

      if (!response.bairro || response.bairro === "undefined") {
        setAddressNeighborhoodOK("vazio");
      } else {
        setAddressNeighborhood(response.bairro);
        setAddressNeighborhoodOK("ok");
        setAddressNeighborhoodError("");
      }

      setUf(response.uf);
      setUfOK("ok");
      setCity(response.localidade);

      setCityOK("ok");
    } catch (error) {
      console.error(error);

      Swal.fire({
        icon: "error",
        title: "CEP não encontrado",
        text: "Por favor, verifique o CEP inserido"
      });
    } finally {
      setLoading(false);
    }
  }, []);

  const handleValidationAddressObs = value => {
    if (value?.length < 2 && value?.length > 0) {
      setAddressObsOK("notOk");
    } else if (value?.length > 2) {
      setAddressObsOK("ok");
    } else {
      setAddressObsOK("vazio");
    }

    if (value?.length === 1) {
      setAddressObsError("Complemento deve ter pelo menos 2 caracteres");
    } else {
      setAddressObsError("");
    }
  };

  const handleInputAddressObs = useCallback(e => {
    let value = e.currentTarget.value;
    setAddressObs(value);
    handleValidationAddressObs(value);
  }, []);

  const handleInputAddress = useCallback(e => {
    let value = e.currentTarget.value;
    setAddress(value);
    handleValidationAddress(value);
  }, []);

  const handleValidationAddress = value => {
    value.length < 2 ? setAddressOK("notOk") : setAddressOK("ok");
    if (value.length === 0) {
      setAddressError("Campo obrigatório");
    } else if (value.length === 1) {
      setAddressError("Endereço deve ter pelo menos 2 caracteres");
    } else {
      setAddressError("");
    }
  };

  const handleValidationZipOrigin = useCallback(
    async (value, isASavedInfo = false) => {
      if (!value.length) {
        setZipOriginOK("notOk");
        setZipOriginError("Preenchimento obrigatório");
      } else if (value.length < 8) {
        setZipOriginOK("notOk");
        setZipOriginError("Preenchimento incorreto");
      } else {
        if (isASavedInfo) {
          setZipOriginOK("ok");
          setZipOriginError("");
        }

        // Não queremos manipular o CEP novamente,
        // se o CEP selecionado pertence a um endereço já salvo
        if (isASavedInfo === false) {
          const cep = value.substr(0, 5) + value.substr(6, 3);
          await handleCepConsult(cep);
        }
      }
    },
    [handleCepConsult]
  );

  const handleInputZipOrigin = useCallback(
    e => {
      let value = e;
      value = value.replace(/^(\d{5})(\d{3})/, "$1-$2");

      handleValidationZipOrigin(value);
      setZipOrigin(value);
    },
    [handleValidationZipOrigin]
  );

  const handleValidationAddressNeighborHood = value => {
    if (address?.length) return;

    value.length < 1
      ? setAddressNeighborhoodOK("notOk")
      : setAddressNeighborhoodOK("ok");
    if (value.length === 0) {
      setAddressNeighborhoodError("Campo obrigatório");
    } else {
      setAddressNeighborhoodError("");
    }
  };

  const handleInputAddressNeighborhood = useCallback(e => {
    let value = e.currentTarget.value;
    setAddressNeighborhood(value);
    handleValidationAddressNeighborHood(value);
  }, []);

  const handleValidationAddressNumber = value => {
    if (value !== "") {
      setAddressNumberOK("ok");
    } else {
      setAddressNumberOK("vazio");
    }
  };

  const handleInputAddressNumber = useCallback(e => {
    let value = e.currentTarget.value;

    if (value !== "" && !Number(value)) {
      return;
    }

    setAddressNumber(value);
    handleValidationAddressNumber(value);
  }, []);

  useEffect(() => {
    window.setPageTitle("Integrações | Nuvemshop");
  }, []);

  const loadingConfig = useCallback(async () => {
    try {
      await Promise.all([
        api
          .makeHttpRequest({
            method: "GET",
            url: `/nuvemshop/config/IS_ACTIVE_INSURANCE`
          })
          .then(active => {
            if (active === "true") {
              setActiveInsurance(true);
            }
          }),
        api
          .makeHttpRequest({
            method: "GET",
            url: `/nuvemshop/config/IS_INACTIVE_ORDER_CREATE`
          })
          .then(inactive => {
            if (inactive === "true") {
              setActiveCreateOrder(false);
            }
          }),
        api
          .makeHttpRequest({
            method: "GET",
            url: `/nuvemshop/config/SENDER_DEFAULT_NAME`
          })
          .then(name => {
            if (name) {
              setSenderName(name);
              setSenderNameOK("ok");
            }
          }),
        api
          .makeHttpRequest({
            method: "GET",
            url: `/nuvemshop/config/ADD_DAYS_PACMINI`
          })
          .then(res => {
            if (res) {
              setMini(res);
              setMiniOK("ok");
            }
          }),
        api
          .makeHttpRequest({
            method: "GET",
            url: `/nuvemshop/config/ADD_DAYS_PAC`
          })
          .then(res => {
            if (res) {
              setPac(res);
              setPacOK("ok");
            }
          }),
        api
          .makeHttpRequest({
            method: "GET",
            url: `/nuvemshop/config/ADD_DAYS_SEDEX`
          })
          .then(res => {
            if (res) {
              setSedex(res);
              setSedexOK("ok");
            }
          }),
        api
          .makeHttpRequest({
            method: "GET",
            url: `/nuvemshop/config/HAS_IMPORTED_RATE`
          })
          .then(res => {
            res === "true"
              ? setActiveImportedRate(true)
              : setActiveImportedRate(false);
          }),
        api
          .makeHttpRequest({
            method: "GET",
            url: `/nuvemshop/config/IMPORTED_RATE`
          })
          .then(res => {
            if (res) {
              setImportedRate(res);
              setImportedRateOK("ok");
            }
          }),
        api
          .makeHttpRequest({
            method: "GET",
            url: `/nuvemshop/config/IMPORTED_SHIPPING_TYPE`
          })
          .then(res => {
            if (res) {
              setImportedShippingType({
                value: res,
                label: res === "mini" ? "PAC MINI" : res
              });
              setImportedShippingTypeOK("ok");
            }
          }),
        api
          .makeHttpRequest({
            method: "GET",
            url: `/nuvemshop/config/SENDER_DEFAULT_ADDRESS`
          })
          .then(res => {
            if (res) {
              const address = JSON.parse(res || "[]");
              setZipOrigin(address?.zip?.replace(/^(\d{5})(\d{3})/, "$1-$2"));
              setZipOriginOK("ok");

              setAddress(address.street);
              setAddressOK("ok");

              setAddressNumber(Number(address.number) || "");
              if (address.number) {
                setAddressNumberOK("ok");
              }

              setAddressObs(address.complement);
              if (address.complement) {
                setAddressObsOK("ok");
              }

              setAddressNeighborhood(address.district);
              setAddressNeighborhoodOK("ok");

              setUf(address.state);
              setUfOK("ok");

              setCity(address.city);
              setCityOK("ok");
            }
          })
      ]);
    } catch (error) {
      console.log("🚀 ~ file: index.jsx:522 ~ loadingConfig ~ error:", error);
    }
  }, [api]);

  useEffect(() => {
    setLoading(true);

    loadingConfig()
      .then(() => {
        console.log("Configurações ok");
      })
      .catch(() => {
        Swal.fire({
          title: "Erro!",
          text:
            "Não foi possível carregar as informações da loja da Nuvemshop.",
          icon: "error"
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const selectStyle = {
    control: styles => ({
      ...styles,
      backgroundColor: "inherit",
      width: "100%",
      border: "none",
      boxShadow: "none",
      outline: "none",
      color: "#807E80",
      padding: "0",
      "&:hover": {
        borderColor: "none"
      },
      cursor: "pointer"
    }),
    container: styles => ({
      ...styles,
      width: "100%",
      outline: "none",
      border: "none",
      padding: "2px 0",
      cursor: "pointer"
    }),
    indicatorSeparator: styles => ({
      ...styles,
      display: "none",
      border: "none"
    }),
    option: (styles, { isSelected }) => ({
      ...styles,
      fontSize: "14px",
      fontFamily: "Mulish",
      fontWeight: 400,
      color: isSelected ? "#fff" : "#807E80",
      "&:hover": {
        backgroundColor: "#c1c1c1",
        color: "#fff",
        cursor: "pointer"
      },

      backgroundColor: isSelected ? "#409e6a" : "#fff"
    }),
    singleValue: styles => ({
      ...styles,
      color: "#807E80",
      fontSize: "14px"
    }),
    placeholder: styles => ({
      ...styles,
      fontSize: "14px",
      fontFamily: "Mulish",
      fontWeight: 400,
      color: "#807E80",
      whiteSpace: "nowrap"
    })
  };

  return (
    <>
      <Loading loading={loading} />
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.contentHeader}>
            <h2>Configurações</h2>
          </div>
          <div className={styles.contentBody}>
            <section>
              <form>
                <div className={`${styles.inputContainer}`}>
                  <p style={{ marginTop: 0, marginBottom: "16px" }}>
                    Informe os dados do remetente que serão usados no calculo do
                    frete e impressão de etiquetas.
                  </p>
                  <div className={styles.divContainer}>
                    <div className={styles.input} role={senderNameOK}>
                      <input
                        onChange={e => handleInputSenderName(e)}
                        value={senderName}
                        placeholder="Digite o nome do remetente"
                        type="text"
                        name="senderName"
                      />
                    </div>
                    {senderNameError && (
                      <p className={styles.error}>{senderNameError}</p>
                    )}
                  </div>
                </div>

                <div className={`${styles.inputContainer} `}>
                  <div className={` ${styles.divContainer}`}>
                    <div className={` ${styles.inputContainerSmCep} `}>
                      <div className={styles.input} role={zipOriginOK}>
                        <input
                          type="text"
                          name="cep"
                          placeholder="Digite o CEP"
                          onChange={e =>
                            handleInputZipOrigin(e.currentTarget.value)
                          }
                          value={zipOrigin}
                          maxLength={8}
                        />
                        <a
                          href="https://buscacepinter.correios.com.br/app/endereco/index.php"
                          target="_blank"
                          rel="noopener noreferrer"
                          className={styles.cepLink}
                        >
                          Não sei o CEP
                        </a>
                      </div>
                    </div>
                  </div>
                  {zipOriginError && (
                    <p className={styles.error}>{zipOriginError}</p>
                  )}
                </div>

                <div
                  className={`${styles.inputContainer} ${styles.inputContainerAddress}`}
                >
                  <div
                    className={` ${styles.divContainer} ${styles.inputContainerMd}`}
                  >
                    <div className={` ${styles.input} `} role={addressOK}>
                      <input
                        onChange={e => handleInputAddress(e)}
                        value={address}
                        placeholder="Digite o endereço"
                        type="text"
                        name="address"
                      />
                    </div>
                    {addressError && (
                      <p className={styles.error}>{addressError}</p>
                    )}
                  </div>

                  <div className={` ${styles.divContainer}`}>
                    <div className={styles.input} role={addressNumberOK}>
                      <input
                        onChange={e => handleInputAddressNumber(e)}
                        value={addressNumber}
                        placeholder="Digite o número"
                        type="text"
                        name="addressNumber"
                      />
                    </div>
                  </div>
                </div>

                <div
                  className={`${styles.inputContainer} ${styles.inputContainerAddress}`}
                >
                  <div className={` ${styles.divContainer}`}>
                    <div className={styles.input} role={addressObsOK}>
                      <input
                        onChange={e => handleInputAddressObs(e)}
                        value={addressObs}
                        placeholder="Digite o complemento"
                        type="text"
                        name="addressObs"
                      />
                    </div>
                    {addressObsError && (
                      <p className={styles.error}>{addressObsError}</p>
                    )}
                  </div>

                  <div className={` ${styles.divContainer}`}>
                    <div className={styles.input} role={addressNeighborhoodOK}>
                      <input
                        onChange={e => handleInputAddressNeighborhood(e)}
                        value={addressNeighborhood}
                        placeholder="Digite o bairro"
                        type="text"
                        name="addressNeighborhood"
                      />
                    </div>
                    {addressNeighborhoodError && (
                      <p className={styles.error}>{addressNeighborhoodError}</p>
                    )}
                  </div>
                </div>

                <div
                  className={`${styles.inputContainer} ${styles.inputContainerAddress}`}
                >
                  <div className={styles.divContainer}>
                    <div className={` ${styles.divContainer}`}>
                      <div className={styles.input} role={cityOK}>
                        <input
                          onChange={() => { }}
                          placeholder="Digite a cidade"
                          value={city}
                          type="text"
                          name="city"
                        />
                      </div>
                    </div>
                  </div>

                  <div className={styles.divContainer}>
                    <div className={styles.input} role={ufOK}>
                      <input
                        onChange={() => { }}
                        placeholder="Digite o estado"
                        value={uf}
                        type="text"
                        name="uf"
                      />
                    </div>
                  </div>
                </div>
              </form>

              <div className={styles.wrapper}>
                <h4>Criação de pedido</h4>
                <p>
                  Abaixo é possível ativar ou desatitvar a criação de pedidos.
                  <br />
                  Serviços como cotação funcionarão normalmente.
                </p>
                <br />
                <div>
                  <div className={styles.switchOption}>
                    <label className={styles.switch}>
                      <input
                        type="checkbox"
                        onChange={event =>
                          setActiveCreateOrder(event.target.checked)
                        }
                        checked={activeCreateOrder}
                      />
                      <span
                        className={`${styles.slider} ${styles.round}`}
                      ></span>
                    </label>
                    {activeCreateOrder ? "Ativado" : "Desativado"}
                  </div>
                </div>
              </div>

              <div className={styles.wrapper}>
                <h4>Configuração de Seguro</h4>
                <p>
                  Abaixo é possível ativar ou desatitvar a cotação de frete com
                  Seguro. Essa opção é válida para todos os fretes que são
                  cotados.
                </p>
                <div className={styles.contentSection}>
                  <div className={styles.switchOption}>
                    <label className={styles.switch}>
                      <input
                        type="checkbox"
                        onChange={event =>
                          setActiveInsurance(event.target.checked)
                        }
                        checked={activeInsurance}
                      />
                      <span
                        className={`${styles.slider} ${styles.round}`}
                      ></span>
                    </label>
                    {activeInsurance ? "Ativado" : "Desativado"}
                  </div>
                </div>
              </div>

              <div>
                <h4>Configurações adicionais de frete</h4>
                <p>
                  Abaixo é possível indicar a quantidade de dias adicionais para
                  cada tipo de envio. Essa opção é particularmente útil para{" "}
                  <br /> refletir nas cotações o seu prazo de manipulação dos
                  pedidos.
                </p>
                <div className={styles.contentSection}>
                  <div className={styles.addDaysContainer}>
                    <div
                      className={`${styles.input} ${sedexOK === "notOK"
                        ? styles.formSelectError
                        : sedexOK === "ok"
                          ? styles.selectOk
                          : ""
                        }`}
                    >
                      <input
                        type="number"
                        name="sedex"
                        value={sedex}
                        onChange={handleInputSedex}
                        placeholder="Expresso"
                        min={0}
                        max={99}
                      />
                    </div>
                    <div
                      className={`${styles.input} ${pacOK === "notOK"
                        ? styles.formSelectError
                        : pacOK === "ok"
                          ? styles.selectOk
                          : ""
                        }`}
                    >
                      <input
                        type="number"
                        name="pac"
                        value={pac}
                        onChange={handleInputPac}
                        placeholder="Economico"
                        min={0}
                        max={99}
                      />
                    </div>
                    <div
                      className={`${styles.input} ${miniOK === "notOK"
                        ? styles.formSelectError
                        : miniOK === "ok"
                          ? styles.selectOk
                          : ""
                        }`}
                    >
                      <input
                        type="number"
                        name="mini"
                        value={mini}
                        onChange={handleInputMini}
                        placeholder="Mini Envios"
                        min={0}
                        max={99}
                      />
                    </div>

                    <div
                      className={`${styles.input} ${ownDeliveryOK === "notOK"
                        ? styles.formSelectError
                        : ownDeliveryOK === "ok"
                          ? styles.selectOk
                          : ""
                        }`}
                    >
                      <input
                        type="number"
                        name="ownDelivery"
                        value={ownDelivery}
                        onChange={handleInputOwnDelivery}
                        placeholder="Super expressa"
                        min={0}
                        max={99}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <h4>
                  Importar pedidos com frete fixo, ou de outro parceiro
                  logístico
                </h4>
                <p>
                  Habilitando a opção abaixo será possivel importar fretes que
                  não pertençam à BRCom. O nome do frete deve ser digitado{" "}
                  <br /> idêntico ao apresentado na tela de cotação de frete da
                  sua loja.
                </p>
                <div className={styles.contentSection}>
                  <div className={styles.switchOption}>
                    <label className={styles.switch}>
                      <input
                        type="checkbox"
                        onChange={event =>
                          setActiveImportedRate(event.target.checked)
                        }
                        checked={activeImportedRate}
                      />
                      <span
                        className={`${styles.slider} ${styles.round}`}
                      ></span>
                    </label>
                    {activeImportedRate ? "Ativado" : "Desativado"}
                  </div>
                  <div className={styles.importedRateContainer}>
                    {activeImportedRate ? (
                      <>
                        <div
                          className={`${styles.input} ${importedRateOK === "notOK"
                            ? styles.formSelectError
                            : importedRateOK === "ok"
                              ? styles.selectOk
                              : ""
                            }`}
                        >
                          <input
                            type="text"
                            name="importedRate"
                            value={importedRate}
                            onChange={handleInputImportedRate}
                            placeholder="Nome do frete"
                          />
                        </div>
                        <div
                          className={`${styles.input} ${styles.select} ${importedShippingTypeOK === "notOK"
                            ? styles.formSelectError
                            : importedShippingTypeOK === "ok"
                              ? styles.selectOk
                              : ""
                            }`}
                        >
                          <Select
                            onChange={handleChangeImportedShippingType}
                            options={optionsShippingType}
                            value={importedShippingType}
                            placeholder="Tipo de envio"
                            styles={selectStyle}
                          />
                        </div>
                      </>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className={styles.buttonWrapper}>
                <button
                  type="submit"
                  onClick={sendData}
                  className={styles.button}
                  disabled={
                    activeImportedRate
                      ? !importedRate ||
                      !importedShippingType ||
                      senderNameOK === "notOk"
                      : senderNameOK === "notOk"
                  }
                  style={{ marginBottom: activeImportedRate ? "50px" : "" }}
                >
                  Salvar
                </button>
              </div>
            </section>
          </div>
        </div>
      </div>
    </>
  );
};

export { Nuvemshop };
