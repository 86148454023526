import React from "react";
import styles from "./chartBySla.module.scss";
import { Col, Row } from "react-bootstrap";
import { ReactComponent as Objetos } from "../../../../../../assets/images/dashboard/objetos.svg";
import { BsClock } from "react-icons/bs";
import moment from 'moment'

const ChartBySla = ({ data, initialDateToRedirect, endDateToRedirect, countObjectsWithError }) => {
  const handleRedirect = (value, label) => {

    const initialDateFormated = moment(initialDateToRedirect).format('DD/MM/YYYY');
    const finalDateFormated = moment(endDateToRedirect).format('DD/MM/YYYY');

    if (value === 'processCorreiosError') {
      return  window.open(
        `/rastrear-encomenda?status=${value}&label=${label}`,
        "_blank"
      );
    }

    window.open(
      `/rastrear-encomenda?status=${value}&label=${label}&initialDate=${initialDateFormated}&finalDate=${finalDateFormated}`,
      "_blank"
    );
  };

  return (
    <Row xs={{ cols: 2 }} lg={{ cols: 4 }} className={`${styles.cardsStatus}`}>
      <>
        <Col xs={12} lg={6}>
          <div
            className={`${styles.card} ${styles.cardTotal}`}
            onClick={() => handleRedirect("", "Selecione")}
          >
            <div className={styles.information}>
              <h3>Total de Objetos</h3>
              <h1>
                {Intl.NumberFormat("pt-BR").format(data.TOTAL_ITEMS.value)}
              </h1>
            </div>

          </div>
        </Col>
        {/* <Col xs={12} lg={3}>
          <div
            className={`${styles.card} ${styles.cardTotal}`}
            onClick={() => handleRedirect("processCorreiosError", "Erro de processamento dos correios")}
          >
            <div className={styles.information}>
              <h3>Total de Objetos com Erro</h3>
              <h1>
                {countObjectsWithError}
              </h1>
            </div>
          </div>
        </Col> */}
        <Col className={styles.statusContainer} xs={12} lg={6}>
          <div
            className={`${styles.card} ${styles.cardStatus} ${styles.onTime}`}
            onClick={() => handleRedirect("slaOntime", "Dentro do prazo")}
          >
            <div className={styles.cardStatusHeader}>
              <BsClock color="#409e6a" />
              <p>
                Status: <strong>Dentro do prazo</strong>
              </p>
            </div>
            <h3>
              {Intl.NumberFormat("pt-BR").format(data.DELIVERED_ON_TIME.value)}
            </h3>
          </div>
          <div
            className={`${styles.card} ${styles.cardStatus} ${styles.before}`}
            onClick={() => handleRedirect("slaEarlier", "Antes do prazo")}
          >
            <div className={styles.cardStatusHeader}>
              <BsClock color="#f5cb2b" />
              <p>
                Status: <strong>Antes do prazo</strong>
              </p>
            </div>
            <h3>
              {Intl.NumberFormat("pt-BR").format(data.DELIVERED_BEFORE.value)}
            </h3>
          </div>
          <div
            className={`${styles.card} ${styles.cardStatus} ${styles.later}`}
            onClick={() => handleRedirect("slaDelayed", "Fora do prazo")}
          >
            <div className={styles.cardStatusHeader}>
              <BsClock color="#ca1818" />
              <p>
                Status: <strong>Fora do prazo</strong>
              </p>
            </div>
            <h3>
              {Intl.NumberFormat("pt-BR").format(data.DELIVERED_LATER.value)}
            </h3>
          </div>
        </Col>
      </>
    </Row>
  );
};

export { ChartBySla };
