import React, { useCallback, useState, useEffect, useMemo } from "react";
import { AiOutlineCheck } from "react-icons/ai";
import { BsExclamation } from "react-icons/bs";
import { useHistory } from "react-router-dom";
import { BrComerceApiService } from "../../../../../services/BrComerceApiService";
import { cnpjValidation } from "../../../../../utils/CnpjValidation";
import styles from "./styles.module.scss";
import { Loading } from "../../../../../components/Loading";
import Select from "react-select";
import { StyledFormControlLabel } from "../../../../auth/Components/Input";
import { Checkbox } from "@material-ui/core";

import checkedIcon from "../../../../../assets/icons/icon-checked.svg";
import notCheckedIcon from "../../../../../assets/icons/icon-not-checked.svg";
import { cpfValidation } from "../../../../../utils/CpfValidation";
import Swal from "sweetalert2";
import ProfileEnum from "../../../../../enums/ProfileEnum";
import PlpTypeEnum from "../../../../../enums/PlpTypeEnum";
import InputSelectAsync, {
  selectStyle
} from "../../../../../components/InputSelectAsync";
import { handleSelectionInput } from "../../../../../utils/handleSelectionInput";
import Container from "../../../components/Container";

const UpdateUserForm = () => {
  const api = new BrComerceApiService();

  const history = useHistory();

  const [user, setUser] = useState({});

  const [isManager, setIsManager] = useState(false);

  const [userId, setUserId] = useState();

  const [nameOK, setNameOK] = useState("vazio");
  const [nameError, setNameError] = useState("");
  const [name, setName] = useState("");

  const [profileOK, setProfileOK] = useState("vazio");
  const [profileError, setProfileError] = useState("");
  const [profile, setProfile] = useState("");
  const [frete, setFrete] = useState("");

  const profileOpt = useMemo(() => {
    return [
      {
        label: ProfileEnum.USER.label,
        value: ProfileEnum.USER.value
      },
      {
        label: ProfileEnum.MANAGER.label,
        value: ProfileEnum.MANAGER.value
      },
      {
        label: ProfileEnum.ADMIN.label,
        value: ProfileEnum.ADMIN.value
      }
    ];
  }, []);

  const plpTypeOpt = useMemo(() => {
    return [
      {
        label: PlpTypeEnum.INDUSTRIAL.label,
        value: PlpTypeEnum.INDUSTRIAL.value
      },
      {
        label: PlpTypeEnum.RETAIL.label,
        value: PlpTypeEnum.RETAIL.value
      }
    ];
  }, []);

  const freteOpt = useMemo(() => {
    return [
      {
        label: "Industrial",
        value: "industrial"
      },
      {
        label: "Varejo",
        value: "varejo"
      }
    ];
  }, []);

  const [emailOK, setEmailOK] = useState("vazio");
  const [emailError, setEmailError] = useState("");
  const [email, setEmail] = useState("");

  const [creditLimitOK, setCreditLimitOk] = useState("vazio");
  const [creditLimitError, setCreditLimitError] = useState("");
  const [creditLimit, setCreditLimit] = useState("");

  const [documentOK, setDocumentOK] = useState("vazio");
  const [documentError, setDocumentError] = useState("");
  const [document, setDocument] = useState("");

  const [phoneOK, setPhoneOK] = useState("vazio");
  const [phoneError, setPhoneError] = useState("");
  const [phone, setPhone] = useState("");

  const [heightOK, setHeightOK] = useState("vazio");
  const [heightError, setHeightError] = useState("");
  const [height, setHeight] = useState("");

  const [lengthOK, setLengthOK] = useState("vazio");
  const [lengthError, setLengthError] = useState("");
  const [length, setLength] = useState("");

  const [widthOK, setWidthOK] = useState("vazio");
  const [widthError, setWidthError] = useState("");
  const [width, setWidth] = useState("");

  const [retailLimitOK, setRetailLimitOK] = useState("vazio");
  const [retailLimitError, setRetailLimitError] = useState("");
  const [retailLimit, setRetailLimit] = useState(0);

  const [userPrePostagemOK, setUserPrePostagemOK] = useState("vazio");
  const [userPrePostagemError, setUserPrePostagemError] = useState("");
  const [userPrePostagem, setUserPrePostagem] = useState("");

  const [passwordPrePostagemOK, setPasswordPrePostagemOK] = useState("vazio");
  const [passwordPrePostagemError, setPasswordPrePostagemError] = useState("");
  const [passwordPrePostagem, setPasswordPrePostagem] = useState("");

  const [isCompany, setIsCompany] = useState(false);

  const [loading, setLoading] = useState(false);

  const [isVerified, setIsVerified] = useState(false);
  const [canCreateCollectSchedule, setCanCreateCollectSchedule] = useState(
    false
  );
  const [createCollectAutomatically, setCreateCollectAutomatically] = useState(
    false
  );
  const [blocked, setBlocked] = useState(false);

  const [searchClient, setSearchClient] = useState(null);
  const [userParenting, setUserParenting] = useState(null);

  const [sendToPrepost, setSendToPrepost] = useState(false);

  const [plpTypeOK, setPlpTypeOK] = useState("vazio");
  const [plpType, setPlpType] = useState("");

  const handleInputName = useCallback(e => {
    let value = e.currentTarget.value;
    setName(value);
    value.length < 2 ? setNameOK("notOk") : setNameOK("ok");
    if (value.length === 0) {
      setNameError("Campo obrigatório");
    } else if (value.length === 1) {
      setNameError("Nome deve ter pelo menos 2 caracteres");
    } else {
      setNameError("");
    }
  }, []);

  const handleValidationCPF = value => {
    const validCPF = cpfValidation({
      cpf: value,
      emptyCpf: "Campo obrigatório",
      incorrectCpf: "CPF incorreto"
    });
    setDocumentOK(validCPF.status);
    setDocumentError(validCPF.message);
  };

  const handleValidationCNPJ = value => {
    const validCnpj = cnpjValidation({
      cnpj: value,
      emptyCnpj: "Campo obrigatório",
      incorrectCnpj: "CNPJ incorreto"
    });
    setDocumentOK(validCnpj.status);
    setDocumentError(validCnpj.message);
  };

  const handleInputDocument = useCallback(e => {
    let inputValue = e.currentTarget.value.replace(/\W/gi, "");

    if (inputValue.length <= 11) {
      inputValue = inputValue.replace(/\D/g, "");
      inputValue = inputValue.replace(
        /^(\d{3})(\d{3})(\d{3})(\d{2})/,
        "$1.$2.$3-$4"
      );
      e.currentTarget.inputValue = inputValue;
      handleValidationCPF(inputValue);
      setDocument(inputValue);
      setIsCompany(false);
    } else {
      e.currentTarget.maxLength = 18;

      inputValue = inputValue.replace(/\D/g, "");
      inputValue = inputValue.replace(
        /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
        "$1.$2.$3/$4-$5"
      );
      e.currentTarget.inputValue = inputValue;
      handleValidationCNPJ(inputValue);
      setDocument(inputValue);
      setIsCompany(true);
    }
  }, []);

  const handleInputPhone = useCallback(e => {
    e.currentTarget.maxLength = 15;
    let value = e.currentTarget.value;
    value = value.replace(/\D/g, "");
    if (value.length === 11) {
      value = value.replace(/^(\d{2})(\d{5})(\d{4})/, "($1) $2-$3");
    } else if (value.length === 10) {
      value = value.replace(/^(\d{2})(\d{4})(\d{4})/, "($1) $2-$3");
    }
    e.currentTarget.value = value;
    if (value.length > 0 && value.length < 14) {
      setPhoneError("Telefone incorreto");
      setPhoneOK("notOk");
    } else if (value.length === 0) {
      setPhoneError("Campo obrigatório");
      setPhoneOK("notOk");
    } else {
      setPhoneError("");
      setPhoneOK("ok");
    }
    setPhone(value);
  }, []);

  const handleInputHeight = useCallback(e => {
    const MIN_HEIGHT = 1;
    let value = e.currentTarget.value;
    setHeight(value);
    if (value < MIN_HEIGHT) {
      setHeightError(`A altura mínima é ${MIN_HEIGHT}`);
      setHeightOK("notOk");
    } else {
      setHeightOK("ok");
    }
  }, []);

  const handleInputWidth = useCallback(e => {
    const MIN_WIDTH = 10;
    let value = e.currentTarget.value;
    setWidth(value);
    if (value < MIN_WIDTH) {
      setWidthError(`A largura mínima é ${MIN_WIDTH}`);
      setWidthOK("notOk");
    } else {
      setWidthOK("ok");
    }
  }, []);

  const handleInputLength = useCallback(e => {
    const MIN_LENGTH = 15;
    let value = e.currentTarget.value;
    setLength(value);
    if (value < MIN_LENGTH) {
      setLengthError(`O comprimento mínimo é ${MIN_LENGTH}`);
      setLengthOK("notOk");
    } else {
      setLengthOK("ok");
    }
  }, []);

  const handleInputRetailLimit = useCallback(e => {
    let value = e.currentTarget.value;

    value = Number(value.replace(/\D/g, ""));

    if (value < 0) {
      setRetailLimitError("O limite não pode ser negativo");
      setRetailLimitOK("notOk");
    } else {
      setRetailLimitOK("ok");
      setRetailLimitError("");
      setRetailLimit(value);
    }
  }, []);

  const replaceFormatter = (match, group1, group2, group3) => {
    if (group1) {
      return `R$ ${group1}.${group2},${group3}`;
    }

    if (group2) {
      return `R$ ${group2},${group3}`;
    }

    return `R$ ${group3}`;
  };

  const handleInputCreditLimit = useCallback(value => {
    let valueNumber = String(value.replace(/\D/g, ""));

    if (valueNumber !== "" && valueNumber.length < 3) {
      valueNumber = valueNumber.padStart(3, "0");
    }

    if (valueNumber !== "" && valueNumber.length === 4) {
      if (valueNumber.startsWith("0")) {
        const arrayNumbers = valueNumber.split("");
        arrayNumbers.splice(0, 1);

        valueNumber = arrayNumbers.join("");
      }
    }

    if (valueNumber.length === 9) {
      return;
    }

    setCreditLimit(
      valueNumber.replace(/(\d{0,3}?)(\d{0,3}?)(\d{1,2}$)/g, replaceFormatter)
    );
  }, []);

  const handleInputCanCreateCollectSchedule = () => {
    setCanCreateCollectSchedule(!canCreateCollectSchedule);
  };

  const handleInputIsVerified = () => {
    setIsVerified(!isVerified);
  };

  const handleInputBlocked = () => {
    setBlocked(!blocked);
  };

  const handleInputCreateCollectAutomatically = () => {
    setCreateCollectAutomatically(!createCollectAutomatically);
  };

  const handleFormSubmit = useCallback(
    async e => {
      e.preventDefault();

      if (name === "" || email === "" || document === "" || phone === "") {
        if (name === "") {
          setNameError("Campo obrigatório");
          setNameOK("notOk");
        }

        if (email === "") {
          setEmailError("Campo obrigatório");
          setEmailOK("notOk");
        }

        if (document === "") {
          setDocumentError("Campo obrigatório");
          setDocumentOK("notOk");
        }

        if (phone === "") {
          setPhoneError("Campo obrigatório");
          setPhoneOK("notOk");
        }

        if (height === "") {
          setHeightError("Campo obrigatório");
          setHeightOK("notOk");
        }

        if (width === "") {
          setWidthError("Campo obrigatório");
          setWidthOK("notOk");
        }

        if (length === "") {
          setLengthError("Campo obrigatório");
          setLengthOK("notOk");
        }
      }
      if (
        name === "" ||
        email === "" ||
        document === "" ||
        phone === "" ||
        height === "" ||
        width === "" ||
        length === "" ||
        nameOK === "notOk" ||
        emailOK === "notOk" ||
        documentOK === "notOk" ||
        phoneOK === "notOk" ||
        heightOK === "notOk" ||
        widthOK === "notOk" ||
        lengthOK === "notOk"
      ) {
        return;
      }

      try {
        setLoading(true);
        let data = {
          name,
          email,
          cnpj: document.replace(/\D/g, ""),
          phone: "+55" + phone.replace(/\D/g, ""),
          profile: profile.value,
          isIndustrial: frete?.value === "industrial",
          // O valor é divido por 100, por questões decimais.
          canCreateCollectSchedule,
          creditLimit: creditLimit.replace(/\D/g, "") / 100,
          defaultPackageHeight: height,
          defaultPackageWidth: width,
          defaultPackageLength: length,
          prePostagemUsuario: userPrePostagem,
          prePostagemSenha: passwordPrePostagem,
          isCompany,
          isVerified,
          createCollectAutomatically,
          sendToPrepost,
          plpType: plpType.value,
          retailLimit,
          blocked
        };

        if (profile.value === ProfileEnum.MANAGER.value) {
          const subUsers = searchClient?.map(i => {
            return i.sub;
          });
          data = {
            ...data,
            subUsers
          };
        }

        await api.makeHttpRequest({
          method: "PUT",
          url: `/users`,
          data
        });

        Swal.fire({
          title: "Sucesso!",
          text: "Usuário editado com sucesso",
          icon: "success",
          showCancelButton: false,
          confirmButtonText: "Ok",
          confirmButtonColor: "#409E6A"
        }).then(() => {
          history.push("/users");
        });
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Erro",
          text: "Não foi possível editar usuário",
          confirmButtonText: "Ok",
          confirmButtonColor: "#CA1818"
        });
      } finally {
        setLoading(false);
      }
    },
    [
      name,
      email,
      document,
      phone,
      height,
      width,
      length,
      nameOK,
      emailOK,
      documentOK,
      phoneOK,
      heightOK,
      widthOK,
      lengthOK,
      profile.value,
      frete.value,
      canCreateCollectSchedule,
      creditLimit,
      userPrePostagem,
      passwordPrePostagem,
      isCompany,
      createCollectAutomatically,
      api,
      searchClient,
      history,
      isVerified,
      sendToPrepost,
      plpType,
      blocked,
      retailLimit,
    ]
  );

  useEffect(() => {
    setUserId(history.location.pathname.split("/")[2]);
  }, [history]);

  useEffect(() => {
    if (userId) {
      setLoading(true);
      api
        .makeHttpRequest({
          method: "GET",
          url: `/users/${userId}`
        })
        .then(response => {
          setUser(response);
          setLoading(false);
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [userId]);

  useEffect(() => {
    if (user) {
      setLoading(true);
      setEmail(user?.email);

      setName(user?.userData?.find(attr => attr.Name === "name").Value);

      const document = user?.userData?.find(attr => attr.Name === "custom:cnpj")
        .Value;
      const documentFormated =
        document?.length === 14
          ? document?.replace(
            /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
            "$1.$2.$3/$4-$5"
          )
          : document?.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");

      setDocument(documentFormated);
      setIsCompany(document?.length === 14 ? true : false);

      setPhone(
        user?.userData
          ?.find(attr => attr.Name === "phone_number")
          .Value.substring(3, 20)
          .replace(/^(\d{2})(\d{5})(\d{4})/, "($1) $2-$3")
      );

      const profile = profileOpt.filter(x => x.value === user.profile);

      setProfile({
        label: profile[0]?.label,
        value: user.profile
      });

      setFrete({
        label: user?.isIndustrial ? "Industrial" : "Varejo",
        value: user?.isIndustrial ? "industrial" : "varejo"
      });

      setUserPrePostagem(user.prePostagemUsuario);
      setPasswordPrePostagem(user.prePostagemSenha);

      setHeight(user?.defaultPackageHeight);
      setWidth(user?.defaultPackageWidth);
      setLength(user?.defaultPackageLength);
      setRetailLimit(user?.retailLimit || 0);

      setCreateCollectAutomatically(user?.createCollectAutomatically);

      setCanCreateCollectSchedule(user?.canCreateCollectSchedule);
      setIsVerified(user?.isVerified || false);
      handleInputCreditLimit(String(user?.creditLimit * 100));
      setBlocked(user?.blocked || false);

      setIsManager(user?.profile === ProfileEnum.MANAGER.value);
      setLoading(false);

      setSendToPrepost(user?.sendToPrepost);

      const plp = plpTypeOpt.filter(x => x.value === user?.plpType);
      setPlpType({
        label: plp[0]?.label,
        value: user.plpType
      });
    }
  }, [handleInputCreditLimit, profileOpt, user, user.userData]);

  useEffect(() => {
    if (userId && isManager) {
      setLoading(true);
      api
        .makeHttpRequest({
          method: "GET",
          url: `/user-parenting/${userId}`
        })
        .then(response => {
          const list = response.map(i => {
            return {
              value: i.id,
              label: i.user?.userData[1]?.Value,
              sub: i.user?.sub
            };
          });
          setUserParenting(list);
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [isManager, userId]);

  return (
    <>
      <Loading loading={loading} />
      <Container title="Editar cadastro">
        <form className={styles.form} onSubmit={handleFormSubmit}>
          <div className={styles.formContent}>
            <h4>Dados pessoais</h4>
            <div className={styles.row}>
              <div className={`${styles.inputContainer}`}>
                <div
                  style={{ backgroundColor: "#d1cdcd" }}
                  className={`${styles.input} ${styles.disabled}`}
                  role={emailOK}
                >
                  <input
                    type="text"
                    name="email"
                    placeholder="E-mail"
                    value={email}
                    disabled
                  />
                  <AiOutlineCheck className={styles.check} role={emailOK} />
                  <BsExclamation className={styles.danger} role={emailOK} />
                </div>
                <p className={styles.error}>{emailError}</p>
              </div>
              <div className={styles.inputContainer}>
                <div className={styles.input} role={nameOK}>
                  <input
                    type="text"
                    name="name"
                    placeholder="Empresa Ltda."
                    onChange={e => handleInputName(e)}
                    value={name}
                    defaultValue={name}
                  />
                  <AiOutlineCheck className={styles.check} role={nameOK} />
                  <BsExclamation className={styles.danger} role={nameOK} />
                </div>
                <p className={styles.error}>{nameError}</p>
              </div>
              <div className={`${styles.inputContainer}`}>
                <div className={styles.input} role={documentOK}>
                  <input
                    type="text"
                    name="document"
                    placeholder="Número do Documento"
                    onChange={handleInputDocument}
                    value={document}
                  />
                  <AiOutlineCheck className={styles.check} role={documentOK} />
                  <BsExclamation className={styles.danger} role={documentOK} />
                </div>
                <p className={styles.error}>{documentError}</p>
              </div>
              <div className={`${styles.inputContainer}`}>
                <div className={styles.input} role={phoneOK}>
                  <input
                    type="text"
                    name="phone"
                    placeholder="Telefone"
                    onChange={handleInputPhone}
                    value={phone}
                  />
                  <AiOutlineCheck className={styles.check} role={phoneOK} />
                  <BsExclamation className={styles.danger} role={phoneOK} />
                </div>
                <p className={styles.error}>{phoneError}</p>
              </div>
            </div>

            <h4>Informações do usuário</h4>

            <div className={styles.row}>
              <div
                className={`${styles.formSelect} ${profileOK === "notOk" &&
                  styles.formError}`}
              >
                <Select
                  onChange={e => setProfile(e)}
                  defaultValue={profile}
                  value={profile}
                  options={profileOpt}
                  placeholder="Perfil"
                  styles={selectStyle}
                />
              </div>
              <div className={`${styles.inputContainer}`}>
                <div className={styles.input} role={creditLimitOK}>
                  <input
                    type="text"
                    name="creditLimit"
                    placeholder="Limite de crédito"
                    onChange={e =>
                      handleInputCreditLimit(e.currentTarget.value)
                    }
                    value={creditLimit}
                  />
                  <AiOutlineCheck
                    className={styles.check}
                    role={creditLimitOK}
                  />
                  <BsExclamation
                    className={styles.danger}
                    role={creditLimitOK}
                  />
                </div>
                <p className={styles.error}>{creditLimitError}</p>
              </div>
            </div>
            <div className={styles.row}>
              {profile?.value === ProfileEnum.MANAGER.value && (
                <div className={`${styles.inputContainer}`}>
                  <InputSelectAsync
                    search={searchClient}
                    setSearch={setSearchClient}
                    isMulti
                    userParenting={userParenting}
                  />
                </div>
              )}
              <div className={`${styles.inputContainer}`}>
                <div className={styles.input} role={userPrePostagemOK}>
                  <input
                    type="text"
                    name="userPrePostagem"
                    placeholder="Usuário Pre.Postagem"
                    onChange={e => setUserPrePostagem(e.currentTarget.value)}
                    value={userPrePostagem}
                  />
                  <AiOutlineCheck
                    className={styles.check}
                    role={userPrePostagemOK}
                  />
                  <BsExclamation
                    className={styles.danger}
                    role={userPrePostagemOK}
                  />
                </div>
                <p className={styles.error}>{userPrePostagemError}</p>
              </div>
              <div className={`${styles.inputContainer}`}>
                <div className={styles.input} role={passwordPrePostagemOK}>
                  <input
                    type="text"
                    name="passwordPrePostagem"
                    placeholder="Senha Pre.Postagem"
                    onChange={e =>
                      setPasswordPrePostagem(e.currentTarget.value)
                    }
                    value={passwordPrePostagem}
                  />
                  <AiOutlineCheck
                    className={styles.check}
                    role={passwordPrePostagemOK}
                  />
                  <BsExclamation
                    className={styles.danger}
                    role={passwordPrePostagemOK}
                  />
                </div>
                <p className={styles.error}>{passwordPrePostagemError}</p>
              </div>
            </div>

            <h4>Tipo de Cotação e Tipo de PLP</h4>
            <div className={styles.row}>
              <div
                className={`${styles.formSelect} ${profileOK === "notOk" &&
                  styles.formError}`}
              >
                <Select
                  onChange={e => setFrete(e)}
                  defaultValue={frete}
                  value={frete}
                  options={freteOpt}
                  placeholder="Tipo de Cotação"
                  styles={selectStyle}
                />
              </div>
              <div
                className={`${styles.formSelect} ${plpTypeOK === "notOk" &&
                  styles.formError}`}
              >
                <Select
                  onChange={e => setPlpType(e)}
                  defaultValue={plpType}
                  value={plpType}
                  options={plpTypeOpt}
                  placeholder="Tipo de PLP"
                  styles={selectStyle}
                />
              </div>
            </div>

            <h4>Medidas das Embalagens e Limite de Objetos</h4>

            <div className={styles.row}>
              <div className={`${styles.inputContainer}`}>
                <div className={styles.input} role={heightOK}>
                  <input
                    type="number"
                    name="height"
                    placeholder="Altura padrão das embalagens."
                    onChange={e => handleInputHeight(e)}
                    value={height}
                    defaultValue={height}
                  />
                  <AiOutlineCheck className={styles.check} role={heightOK} />
                  <BsExclamation className={styles.danger} role={heightOK} />
                </div>
                <p className={styles.error}>{heightError}</p>
              </div>
              <div className={`${styles.inputContainer}`}>
                <div className={styles.input} role={widthOK}>
                  <input
                    type="number"
                    name="width"
                    placeholder="Largura padrão das embalagens."
                    onChange={e => handleInputWidth(e)}
                    value={width}
                    defaultValue={width}
                  />
                  <AiOutlineCheck className={styles.check} role={widthOK} />
                  <BsExclamation className={styles.danger} role={widthOK} />
                </div>
                <p className={styles.error}>{widthError}</p>
              </div>
              <div className={`${styles.inputContainer}`}>
                <div className={styles.input} role={lengthOK}>
                  <input
                    type="number"
                    name="length"
                    placeholder="Comprimento padrão das embalagens."
                    onChange={e => handleInputLength(e)}
                    value={length}
                    defaultValue={length}
                  />
                  <AiOutlineCheck className={styles.check} role={lengthOK} />
                  <BsExclamation className={styles.danger} role={lengthOK} />
                </div>
                <p className={styles.error}>{lengthError}</p>
              </div>

              <div className={`${styles.inputContainer}`}>
                <div className={styles.input}>
                  <input
                    type="text"
                    name="retailLimit"
                    placeholder="Limite de objetos não postados."
                    onChange={e => handleInputRetailLimit(e)}
                    value={retailLimit}
                  />
                  <AiOutlineCheck
                    className={styles.check}
                    role={retailLimitOK}
                  />
                  <BsExclamation
                    className={styles.danger}
                    role={retailLimitOK}
                  />
                </div>
                <p className={styles.error}>{retailLimitError}</p>
              </div>
            </div>

            <div className={styles.inputContainer}>
              <div className={styles.inputContainer}>
                <StyledFormControlLabel
                  control={
                    <Checkbox
                      name="canCreateCollectSchedule"
                      checked={canCreateCollectSchedule}
                      onChange={() =>
                        handleSelectionInput(setCanCreateCollectSchedule)
                      }
                      icon={<img src={notCheckedIcon} alt="" />}
                      checkedIcon={<img src={checkedIcon} alt="" />}
                      label=""
                    />
                  }
                  label="Permitir a COTAÇÃO e AGENDAMENTO de FRETES do tipo COLETA"
                />
              </div>
              <div className={styles.inputContainer}>
                <StyledFormControlLabel
                  control={
                    <Checkbox
                      name="createCollectAutomatically"
                      checked={createCollectAutomatically}
                      onChange={handleInputCreateCollectAutomatically}
                      icon={<img src={notCheckedIcon} alt="" />}
                      checkedIcon={<img src={checkedIcon} alt="" />}
                      label=""
                    />
                  }
                  label="Criar pedidos e agendar coleta automaticamente"
                />
              </div>

              <div className={styles.inputContainer}>
                <StyledFormControlLabel
                  control={
                    <Checkbox
                      name="sendToPrepost"
                      checked={sendToPrepost}
                      onChange={() => handleSelectionInput(setSendToPrepost)}
                      icon={<img src={notCheckedIcon} alt="" />}
                      checkedIcon={<img src={checkedIcon} alt="" />}
                      label=""
                    />
                  }
                  label="Enviar objetos desse usuário para o PRÉ-POSTAGEM"
                />
              </div>

              <div className={styles.inputContainer}>
                <StyledFormControlLabel
                  control={
                    <Checkbox
                      name="isVerified"
                      checked={isVerified}
                      onChange={() => handleSelectionInput(setIsVerified)}
                      icon={<img src={notCheckedIcon} alt="" />}
                      checkedIcon={<img src={checkedIcon} alt="" />}
                      label=""
                    />
                  }
                  label="VERIFICAR esse usuário"
                />
              </div>

              <div className={styles.inputContainer}>
                <StyledFormControlLabel
                  control={
                    <Checkbox
                      name="blocked"
                      checked={blocked}
                      onChange={handleInputBlocked}
                      icon={<img src={notCheckedIcon} alt="" />}
                      checkedIcon={<img src={checkedIcon} alt="" />}
                      label=""
                    />
                  }
                  label="BLOQUEAR esse usuário"
                />
              </div>
            </div>
          </div>
          <div className={styles.buttonsContainer}>
            <div className={styles.buttonContainerBack}>
              <button type="button" onClick={() => history.push("/users")}>
                Voltar
              </button>
            </div>
            <div className={styles.buttonContainer}>
              <button type="submit">Atualizar</button>
            </div>
          </div>
        </form>
      </Container>
    </>
  );
};

export { UpdateUserForm };
