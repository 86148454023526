import axios from "axios";

const PAGARME_TOKEN_GENERATOR_URL = "https://api.pagar.me/core/v5/tokens";

export async function generateCardToken({
  number, holder_name, exp_month, exp_year, cvv, label
}) {
  const response = await axios.post(PAGARME_TOKEN_GENERATOR_URL, {
    card: {
      number,
      holder_name,
      exp_month,
      exp_year,
      cvv,
      label,
    },
    type: "card"
  }, { params: {
    appId: process.env.REACT_APP_PAGARME_PUBLIC_KEY
  } });

  return response.data.id;
}
