import React, { useEffect, useRef } from "react";
import { DateRange, DayPicker } from "react-day-picker";
import DatePickerComponent from "react-datepicker";
import "react-day-picker/dist/style.css";
import "./styles.scss";

import { MdClear, MdEvent } from "react-icons/md";

import { format } from "date-fns";
import { pt, ptBR } from "date-fns/locale";
import moment from "moment";

const DatePicker = ({
  placeholder,
  change,
  popAuto,
  range,
  showClearButton,
  onClearFields,
  className,
  dateStart,
  dateFinal
}) => {
  const [value, setValue] = React.useState("" | undefined);
  const [showCalendar, setShowCalendar] = React.useState(false);
  const selectRef = useRef(null);

  useEffect(() => {
    if (dateStart && dateFinal) {
      const start = moment(dateStart, 'DD/MM/YYYY').toISOString()
      const end = moment(dateFinal, 'DD/MM/YYYY').toISOString()
      setValue({ from: start, to: end });
    }
  }, [dateStart, dateFinal]);

  const formatCaption = month => {
    const monthFormatted = format(month, "LLLL yyyy", { locale: ptBR });
    return monthFormatted.charAt(0).toUpperCase() + monthFormatted.slice(1);
  };

  const onChange = value => {
    setValue(value);

    if (range) {
      const dateStart = moment(value?.from).format("DD/MM/YYYY");
      const dateEnd = value.to ? moment(value.to).format("DD/MM/YYYY") : null;

      change({ dateStart, dateEnd });
    } else {
      change(value);
    }
  };

  const handleClearFields = () => {
    setValue("");
    onClearFields();
  };

  const popupOptions = [
    {
      name: "offset",
      options: {
        offset: [-10, 0]
      }
    }
  ];

  const handleFormatValue = value => {
    if (range) {
      if (value?.from) {
        if (!value.to) {
          return moment(value.from).format("DD/MM/YYYY");
        } else if (value.to) {
          return `${moment(value.from).format("DD/MM/YYYY")} - ${moment(
            value.to
          ).format("DD/MM/YYYY")}`;
        }
      }

      if (!value) {
        return "";
      }
    }
  };

  const handleClickOutside = event => {
    if (
      selectRef.current &&
      !(
        event.target instanceof Node && selectRef.current.contains(event.target)
      )
    ) {
      setShowCalendar(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <div className="content" ref={selectRef}>
      <input
        type="text"
        name="card number"
        placeholder={placeholder}
        value={handleFormatValue(value)}
        onClick={() => setShowCalendar(!showCalendar)}
      />
      {showClearButton ? (
        <MdClear
          size={24}
          style={{ cursor: "pointer" }}
          onClick={handleClearFields}
        />
      ) : (
        <MdEvent color="#409e6a" size={24} />
      )}

      {showCalendar && (
        <div className={`date-input ${className}`}>
          <DayPicker
            mode={range ? "range" : "single"}
            selected={value}
            onSelect={onChange}
            formatters={{ formatCaption }}
            locale={pt}
            modifiersClassNames={{
              selected: "day-selected",
              today: "today-selected"
            }}
          />
        </div>
      )}
    </div>
  );
};

export default DatePicker;
