import React, { useCallback, useEffect, useState } from "react";
import AsyncSelect from "react-select/async";
import Swal from "sweetalert2";
import { BrComerceApiService } from "../../services/BrComerceApiService";
import styles from "./inputSelectAsync.module.scss";
import { ValueContainer } from "./MultipleHelper";

export let selectStyle = {
  control: (styles, { isDisabled }) => ({
    ...styles,
    backgroundColor: isDisabled ? "#F2F2F2" : "#fff",
    width: "100%",
    border: "1px solid #c2c6ca",
    boxShadow: "none",
    outline: "none",
    color: "#4d4c4d",
    padding: "0 10px",
    "&:hover": {
      borderColor: "none"
    },
    maxHeight: "51px",
    minHeight: "51px",
    borderRadius: "12px",
    cursor: "pointer"
  }),
  container: styles => ({
    ...styles,
    width: "100%",
    outline: "none",
    border: "none",
    padding: "0",
    cursor: "pointer"
  }),
  indicatorSeparator: styles => ({
    ...styles,
    display: "none",
    border: "none"
  }),
  option: (styles, { isSelected }) => ({
    ...styles,
    fontSize: "14px",
    fontFamily: "Mulish",
    fontWeight: 400,
    color: isSelected ? "#fff" : "#807E80",
    paddingLeft: "20px",
    "&:hover": {
      backgroundColor: "#EFEFEF",
      color: "#333333",
      cursor: "pointer"
    },
    "&:focus": {
      backgroundColor: "#EFEFEF",
      color: "#333333",
      cursor: "pointer"
    },
    backgroundColor: isSelected ? "#409e6a" : "#fff"
  }),
  singleValue: styles => ({
    ...styles,
    color: "#807E80",
    fontSize: "14px",
    fontWeight: 400
  }),
  placeholder: styles => ({
    ...styles,
    fontSize: "14px",
    fontFamily: "Mulish",
    fontWeight: 400,
    color: "#807E80",
    whiteSpace: "nowrap"
  }),
  dropdownIndicator: styles => ({
    ...styles,
    color: "#333"
  }),
  menu: styles => ({
    ...styles,
    paddingTop: "3px",
    paddingRight: "5px",

    borderRadius: "12px",
    marginTop: "15px"
  }),
  menuList: styles => ({
    ...styles,

    "::-webkit-scrollbar": {
      width: "7px"
    },
    "::-webkit-scrollbar-track": {
      background: "#FFF",
      margin: "15px 0 15px 10px"
    },
    "::-webkit-scrollbar-thumb": {
      background: "#B3B3B3",
      borderRadius: "7px"
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: "#979797"
    }
  })
};


const InputSelectAsync = ({
  search,
  setSearch,
  isMulti = false,
  userParenting = null,
  listUserOrDriver = "user",
  applyingBorders = false,
  placeholder
}) => {
  const api = new BrComerceApiService();

  const [allClient, setAllClient] = useState([]);
  const [usersPreSelection, setUsersPreSelection] = useState([]);
  const [role, setRole] = useState("vazio");

  selectStyle = {
    ...selectStyle,
    control: (styles, { isDisabled }) => ({
      ...styles,
      backgroundColor: isDisabled ? "#F2F2F2" : "#fff",
      width: "100%",
      border: (role === "notOk" && applyingBorders) ? "1px solid #ca1818" : (role === "ok" && applyingBorders) ? "1px solid #409e6a" : "1px solid #c2c6ca",
      boxShadow: "none",
      outline: "none",
      color: "#4d4c4d",
      padding: "0 10px",
      "&:hover": {
        borderColor: "none"
      },
      maxHeight: "51px",
      minHeight: "51px",
      borderRadius: "12px",
      cursor: "pointer"
    }),
    container: styles => ({
      ...styles,
      width: "100%",
      outline: "none",
      border: "none",
      padding: "0",
      cursor: "pointer"
    }),
    indicatorSeparator: styles => ({
      ...styles,
      display: "none",
      border: "none"
    }),
    option: (styles, { isSelected }) => ({
      ...styles,
      fontSize: "14px",
      fontFamily: "Mulish",
      fontWeight: 400,
      color: isSelected ? "#fff" : "#807E80",
      paddingLeft: "20px",
      "&:hover": {
        backgroundColor: "#EFEFEF",
        color: "#333333",
        cursor: "pointer"
      },
      "&:focus": {
        backgroundColor: "#EFEFEF",
        color: "#333333",
        cursor: "pointer"
      },
      backgroundColor: isSelected ? "#409e6a" : "#fff"
    }),
    singleValue: styles => ({
      ...styles,
      color: "#807E80",
      fontSize: "14px",
      fontWeight: 400
    }),
    placeholder: styles => ({
      ...styles,
      fontSize: "14px",
      fontFamily: "Mulish",
      fontWeight: 400,
      color: (role === "notOk" && applyingBorders) ? "#ca1818" : (role === "ok" && applyingBorders) ? "#409e6a" : "#807E80",
      whiteSpace: "nowrap"
    }),
    dropdownIndicator: styles => ({
      ...styles,
      color: "#333"
    }),
    menu: styles => ({
      ...styles,
      paddingTop: "3px",
      paddingRight: "5px",

      borderRadius: "12px",
      marginTop: "15px"
    }),
    menuList: styles => ({
      ...styles,

      "::-webkit-scrollbar": {
        width: "7px"
      },
      "::-webkit-scrollbar-track": {
        background: "#FFF",
        margin: "15px 0 15px 10px"
      },
      "::-webkit-scrollbar-thumb": {
        background: "#B3B3B3",
        borderRadius: "7px"
      },
      "::-webkit-scrollbar-thumb:hover": {
        background: "#979797"
      }
    })
  };

  const isDriver = listUserOrDriver === "driver";

  const getUsers = useCallback(async value => {
    if (value?.length < 3) {
      return;
    }

    try {
      const response = await api.makeHttpRequest({
        method: "GET",
        url: `${!isDriver ? "/users" : "/driver-admin"}`,
        params: {
          page: 1,
          resultsPerPage: 25,
          text: value
        }
      });

      const list = response.results.map(user => {
        return {
          value: user.id,
          label: !isDriver ? user?.userData[1]?.Value : user?.name,
          sub: user.sub
        };
      });

      setAllClient(list);
      return list;
    } catch (e) {
      console.log(e);
      Swal.fire({
        icon: "error",
        title: "Erro",
        text: "Não foi possível carregar as informações."
      });
    }
  }, []);

  useEffect(() => {
    if (userParenting) {
      setSearch(userParenting);
      setUsersPreSelection({ selectValue: userParenting });
    }
  }, [userParenting]);

  const components = isMulti ? { ValueContainer } : {};

  return (
    <div className={`${styles.inputContainer}`} role={role}>
      <div className={styles.divInput}>
        <AsyncSelect
          isMulti={isMulti}
          placeholder={placeholder ?? `Filtrar por ${!isDriver ? "cliente" : "motorista"}`}
          isClearable
          cacheOptions
          loadOptions={getUsers}
          value={usersPreSelection.selectValue}
          onChange={e => {
            setUsersPreSelection({ selectValue: e });
            setSearch(e);
            if (!e) {
              setRole("notOk")
            } else {
              setRole("ok")
            }
          }}
          defaultOptions={allClient}
          noOptionsMessage={() =>
            !search || search?.length < 3
              ? "Digite no mínimo 3 caractéres para pesquisar."
              : `Nenhum ${!isDriver ? "cliente" : "motorista"} encontrado`
          }
          loadingMessage={() => "Carregando..."}
          styles={selectStyle}
          components={components}
        />
      </div>
    </div>
  );
};

export default InputSelectAsync;
