import React, { useState } from "react";
import { IconButton, InputAdornment } from "@material-ui/core";
import Loading from "../../home/components/Loading";
import IsValidEmail from "../../../utils/validators/IsValidEmail";
import { BrComerceApiService } from "../../../services/BrComerceApiService";

import {
  Container,
  StyledInput,
  ButtonForm,
  CancelButton,
  SubmitButton
} from "./style";
import Swal from "sweetalert2";
import { VisibilityOffOutlined, VisibilityOutlined } from "@material-ui/icons";
import { useCallback } from "react";

const ForgotPassword = ({ closeModal, bg }) => {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [codeConfirmation, setCodeConfirmation] = useState("");
  const [codeError, setCodeError] = useState("");
  const [disabledButton, setDisabledButton] = useState(false);
  const [sendEmailSuccess, setSendEmailSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleEmailChange = e => {
    setEmail(e.target.value);
    emailValidation(e.target.value);
  };

  const emailValidation = async email => {
    if (email === "") {
      setEmailError("Preencha com seu email!");
      setDisabledButton(false);
      return false;
    }

    if (!IsValidEmail(email)) {
      setEmailError("Este email é invalido!");
      setDisabledButton(false);
      return false;
    }

    setEmailError("");
    return true;
  };

  const handleInputPassword = useCallback(e => {
    let value = e.currentTarget.value;
    const regex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
    if (value.length === 0) {
      setPasswordError("Preencha com sua nova senha");
    } else if (!value.match(regex)) {
      setPasswordError(
        "Senha precisar ter no mínimo 8 caracteres, uma letra maiúscula, um número e um carácter especial"
      );
    } else {
      setPasswordError("");
    }

    setPassword(value);
  }, []);

  const [passwordVisibility, setPasswordVisibility] = useState(false);

  const handleClickShowPassword = () => {
    setPasswordVisibility(!passwordVisibility);
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  const handleCodeChange = e => {
    setCodeConfirmation(e.target.value);
    codeValidation(e.target.value);
  };

  const codeValidation = async code => {
    if (code === "") {
      setCodeError("Preencha com o código de verificação recebido no email");
      setDisabledButton(false);
      return false;
    }

    if (code.length !== 6 || isNaN(code.trim())) {
      setCodeError("Este código é invalido!");
      setDisabledButton(false);
      return false;
    }

    setCodeError("");
    return true;
  };

  const sendEmail = async () => {
    setIsLoading(true);
    try {
      const api = new BrComerceApiService();
      await api.makeHttpRequest({
        url: "/users/forgot-password",
        method: "POST",
        data: {
          email
        }
      });
      setSendEmailSuccess(true);
      Swal.fire({
        title: "Sucesso!",
        text: "Email enviado com sucesso.",
        icon: "success",
        showCancelButton: false,
        confirmButtonText: "Ok",
        target: document.body.querySelector("#forgot-password-modal")
      });
    } catch (e) {
      Swal.fire({
        title: "Erro!",
        text: e?.response?.data?.message
          ? e?.response?.data?.message
          : "Email não cadastrado.",
        icon: "error",
        target: document.body.querySelector("#forgot-password-modal")
      });
    } finally {
      setIsLoading(false);
    }
  };

  const hanldePasswordSubmit = async () => {
    setIsLoading(true);
    try {
      const api = new BrComerceApiService();
      await api.makeHttpRequest({
        url: "/users/confirm-password",
        method: "POST",
        data: {
          email,
          password,
          codeConfirmation
        }
      });
      setPassword(true);
      setCodeConfirmation(true);
      closeModal();
      Swal.fire({
        title: "Sucesso!",
        text: "Senha alterada com sucesso.",
        icon: "success",
        showCancelButton: false,
        confirmButtonText: "Ok"
      });
    } catch (e) {
      Swal.fire({
        title: "Erro!",
        text: e?.response?.data?.message
          ? e?.response?.data?.message
          : "Verificar campos inválidos.",
        icon: "error",
        target: document.body.querySelector("#forgot-password-modal")
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Loading isLoading={isLoading} />

      <Container id="forgot-password-modal" bg={bg}>
        {!sendEmailSuccess ? (
          <>
            <span className="forgot-title">Esqueceu sua senha?</span>
            <p className="forgot-subtitle">
              Insira seu email para iniciar o processo de recuperação de senha:
            </p>

            <StyledInput
              placeholder="Insira seu email"
              value={email}
              error={emailError !== "" ? true : false}
              onChange={handleEmailChange}
              autoFocus={true}
              helperText={emailError !== "" && emailError}
            />

            <ButtonForm>
              <CancelButton
                type="button"
                disabled={disabledButton}
                onClick={closeModal}
              >
                Cancelar
              </CancelButton>
              <SubmitButton
                type="button"
                className="btn btn-primary"
                disabled={disabledButton}
                onClick={sendEmail}
              >
                Enviar
              </SubmitButton>
            </ButtonForm>
          </>
        ) : (
          <>
            <span className="forgot-title">Redefinir sua senha</span>
            <p className="forgot-subtitle">
              Insira sua nova senha e o código de verificação enviado no seu
              email para concluir o processo de redefinição de senha:
            </p>

            <StyledInput
              placeholder="Insira sua nova senha"
              type={passwordVisibility ? "text" : "password"}
              value={password}
              error={passwordError !== "" ? true : false}
              onChange={handleInputPassword}
              autoFocus={true}
              helperText={passwordError !== "" && passwordError}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="Alterar visibilidade da senha"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {passwordVisibility ? (
                        <VisibilityOffOutlined />
                      ) : (
                        <VisibilityOutlined />
                      )}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />

            <StyledInput
              placeholder="Insira o código de verificação"
              value={codeConfirmation}
              error={codeError !== "" ? true : false}
              onChange={handleCodeChange}
              //autoFocus={true}
              helperText={codeError !== "" && codeError}
            />

            <ButtonForm>
              <CancelButton
                disabled={disabledButton}
                onClick={closeModal}
                type="button"
              >
                Cancelar
              </CancelButton>
              <SubmitButton
                className="btn btn-primary"
                disabled={disabledButton}
                onClick={hanldePasswordSubmit}
                type="submit"
              >
                Enviar
              </SubmitButton>
            </ButtonForm>
          </>
        )}
      </Container>
    </>
  );
};

export default ForgotPassword;
