const requestInformation = {
  PEDIDO_PENDENTE: {
    id: 1,
    message: "Pedido Pendente"
  },
  PEDIDO_APROVADO: {
    id: 2,
    message: "Pedido Aprovado"
  },
  PEDIDO_RECUSADO: {
    id: 3,
    message: "Pedido Recusado"
  }
};

export default Object.freeze({
  ...requestInformation
});
