import React from "react";
import styles from "./styles.module.scss";
//import { FiUser } from "react-icons/fi";
//import { FaRegBuilding } from "react-icons/fa";
//import { useHistory } from "react-router-dom";
import background from "../../../assets/images/home-banner-principal.png";

const BannerPrincipal = () => {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.img}>
          <img src={background} alt="">
          </img>
        </div>
        <div className={styles.txts}>
          <div className={styles.title}>
            <h1>Saiba mais sobre a coleta de encomendas</h1>
          </div>
          <div className={styles.txtPrincipal}>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Pellentesque venenatis luctus mattis. Maecenas dictum eu est in
              tincidunt. Vivamus sit amet pulvinar ipsum. Phasellus varius,
              lectus sit amet bibendum sollicitudin, turpis lorem commodo quam,
              eu condimentum purus nunc a nisi. Ut egestas tincidunt lacus, id
              blandit ipsum. Maecenas pellentesque lacus dictum, consequat augue
              laoreet, sagittis lectus.
            </p>
            <p>
              Morbi vitae purus et tellus lacinia vulputate eu sit amet ex.
              Fusce odio velit, condimentum vel libero eget, ullamcorper
              scelerisque ante. Fusce auctor, nibh a fermentum tempus, eros erat
              interdum orci, non cursus velit mi dapibus erat.
            </p>
          </div>
          <div className={styles.buttonContainer}>
            <button href="/#">
                Solicitar Coleta
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BannerPrincipal;
