import React, { useCallback, useEffect, useState } from "react";
import Select from "react-select";
import Swal from "sweetalert2";
import { Loading } from "../../../../../components/Loading";
import { RateTypeShippingEnum } from "../../../../../enums/RateTypeShippingEnum";
import { BrComerceApiService } from "../../../../../services/BrComerceApiService.js";
import styles from "./styles.module.scss";
import store from "../../../../../store/store";
import DeliveryTypeEnum from "../../../../../enums/DeliveryTypeEnum.js";

const Vtex = () => {
  const api = new BrComerceApiService();
  const {
    auth: { user }
  } = store.getState();

  const [loading, setLoading] = useState(false);

  const [userApiKey, setUserApiKey] = useState("");
  const [userApiKeyOK, setUserApiKeyOK] = useState("");
  const [userApiKeyError, setUserApiKeyError] = useState("");

  const [userApiToken, setUserApiToken] = useState("");
  const [userApiTokenOK, setUserApiTokenOK] = useState("");
  const [userApiTokenError, setUserApiTokenError] = useState("");

  const [storeName, setStoreName] = useState("");
  const [storeNameOK, setStoreNameOK] = useState("");
  const [storeNameError, setStoreNameError] = useState("");

  const [storeNumber, setStoreNumber] = useState("");
  const [storeNumberOK, setStoreNumberOK] = useState("");
  const [storeNumberError, setStoreNumberError] = useState("");

  const [active, setActive] = useState(false);

  const [selectedDeliveryStrategy, setSelectedDeliveryStrategy] = useState(
    null
  );

  const [activeInsurance, setActiveInsurance] = useState(false);
  const [activeCreateOrder, setActiveCreateOrder] = useState(true);
  const [activeStores, setActiveStores] = useState(false);

  const [senderName, setSenderName] = useState("");
  const [senderNameOK, setSenderNameOK] = useState("");
  const [senderNameError, setSenderNameError] = useState("");

  const [zipOriginOK, setZipOriginOK] = useState("vazio");
  const [zipOriginError, setZipOriginError] = useState("");
  const [zipOrigin, setZipOrigin] = useState("");

  const [addressOK, setAddressOK] = useState("vazio");
  const [addressError, setAddressError] = useState("");
  const [address, setAddress] = useState("");

  const [addressObsOK, setAddressObsOK] = useState("vazio");
  const [addressObsError, setAddressObsError] = useState("");
  const [addressObs, setAddressObs] = useState("");

  const [addressNumberOK, setAddressNumberOK] = useState("vazio");
  const [addressNumber, setAddressNumber] = useState("");

  const [addressNeighborhoodOK, setAddressNeighborhoodOK] = useState("vazio");
  const [addressNeighborhoodError, setAddressNeighborhoodError] = useState("");
  const [addressNeighborhood, setAddressNeighborhood] = useState("");

  const [canActivate, setCanActive] = useState(false);

  const [uf, setUf] = useState("");
  const [ufOK, setUfOK] = useState("");

  const [city, setCity] = useState("");
  const [cityOK, setCityOK] = useState("");

  const [hasSedexHojeOption, setHasSedexHojeOption] = useState(false);

  const canCollect = Boolean(user.canCreateCollectSchedule);

  const optionsDelivery = [
    { value: "SEDEX", label: "Expresso" },
    { value: "PAC", label: "Econômico" },
    { value: "PACMINI", label: "Super Econômico" }
  ];

  const selectStyle = {
    control: styles => ({
      ...styles,
      backgroundColor: "inherit",
      width: "100%",
      border: "none",
      boxShadow: "none",
      outline: "none",
      color: "#807E80",
      padding: "0",
      "&:hover": {
        borderColor: "none"
      },
      cursor: "pointer"
    }),
    container: styles => ({
      ...styles,
      width: "100%",
      outline: "none",
      border: "none",
      padding: "2px 0",
      cursor: "pointer"
    }),
    indicatorSeparator: styles => ({
      ...styles,
      display: "none",
      border: "none"
    }),
    option: (styles, { isSelected }) => ({
      ...styles,
      fontSize: "14px",
      fontFamily: "Mulish",
      fontWeight: 400,
      color: isSelected ? "#fff" : "#807E80",
      "&:hover": {
        backgroundColor: "#c1c1c1",
        color: "#fff",
        cursor: "pointer"
      },
      backgroundColor: isSelected ? "#409e6a" : "#fff"
    }),
    singleValue: styles => ({
      ...styles,
      color: "#807E80",
      fontSize: "14px"
    }),
    placeholder: styles => ({
      ...styles,
      fontSize: "14px",
      fontFamily: "Mulish",
      fontWeight: 400,
      color: "rgba(193, 193, 193, 1)",
      whiteSpace: "nowrap"
    }),
  };

  const handleChangeSelectedDeliveryStrategy = e => {
    setSelectedDeliveryStrategy(e);
  };

  const activeIntegration = useCallback(async () => {
    try {
      setLoading(true);
      await api.makeHttpRequest({
        method: "POST",
        url: `/vtex/config`
      });
      setActive(true);
    } catch (e) {
      const msg = e?.response?.body?.data?.message;
      Swal.fire({
        title: "Erro!",
        text: msg || "Erro ao ativar integração",
        icon: "error"
      });
    } finally {
      setLoading(false);
    }
  }, []);


  const handleSubmit = useCallback(
    async (key, value) => {
      api.makeHttpRequest({
        url: "/vtex/config/key",
        method: "POST",
        data: {
          key: key,
          value: value
        }
      });
    },
    [api]
  );

  const sendData = useCallback(async () => {
    const zipOriginNumber = zipOrigin.replace(/\D/g, "");

    const addressComplet = {
      zip: zipOriginNumber,
      street: address,
      number: addressNumber,
      complement: addressObs,
      district: addressNeighborhood,
      city: city,
      state: uf
    };

    setLoading(true);
    try {
      await Promise.all([
        handleSubmit("apiKey", userApiKey),
        handleSubmit("apiToken", userApiToken),
        handleSubmit("store", storeName),
        activeStores
          ? handleSubmit("storeNumber", `${storeName}${storeNumber}`)
          : handleSubmit("storeNumber", `${storeName}`),
        handleSubmit("zipcode", zipOrigin),
        handleSubmit("SENDER_DEFAULT_NAME", senderName),
        handleSubmit("SENDER_DEFAULT_ADDRESS", JSON.stringify(addressComplet)),
        activeInsurance
          ? handleSubmit("IS_ACTIVE_INSURANCE", "true")
          : handleSubmit("IS_ACTIVE_INSURANCE", "false"),
        activeCreateOrder
          ? handleSubmit("IS_INACTIVE_ORDER_CREATE", "false")
          : handleSubmit("IS_INACTIVE_ORDER_CREATE", "true"),
        activeStores
          ? handleSubmit("IS_ACTIVE_STORES", "true")
          : handleSubmit("IS_ACTIVE_STORES", "false")
        // selectedStatus.length > 0
        //   ? handleSubmit(
        //     "STATUSES_TO_ADD_ORDER",
        //     JSON.stringify(selectedStatus.map(status => status.value))
        //   ) : undefined
      ]);

      setLoading(false);
      Swal.fire({
        title: "Sucesso!",
        text: "Configurações cadastradas com sucesso.",
        icon: "success",
        showCancelButton: false,
        confirmButtonText: "Ok"
      });

      setCanActive(true);
    } catch (error) {
      setLoading(false);
      Swal.fire({
        icon: "error",
        title: "Ocorreu um erro!",
        text: "Erro ao cadastrar configurações"
      });
    }
  }, [
    zipOrigin,
    address,
    addressNumber,
    addressObs,
    addressNeighborhood,
    city,
    uf,
    handleSubmit,
    userApiKey,
    userApiToken,
    storeName,
    activeStores,
    storeNumber,
    senderName,
    activeInsurance
  ]);

  const generateShippingStrategy = useCallback(async () => {
    try {
      setLoading(true);
      const data = {
        id: `${selectedDeliveryStrategy.value.toLowerCase()}-${canCollect
          ? RateTypeShippingEnum.COLLECT
          : RateTypeShippingEnum.RETAIL
          }`,
        name: "Brcomerce",
        shippingMethod: `${selectedDeliveryStrategy.label}`,
        weekendAndHolidays: {
          saturday: false,
          sunday: false,
          holiday: false
        },
        maxDimension: {
          largestMeasure: 100,
          maxMeasureSum: 100
        },
        numberOfItemsPerShipment: 1,
        minimumValueAceptable: 0,
        maximumValueAceptable: 999999999,
        cubicWeightSettings: {
          volumetricFactor: 0.16666,
          minimunAcceptableVolumetricWeight: 1
        },
        isActive: true
      };

      await api.makeHttpRequest({
        method: "POST",
        url: `/vtex/shipping-strategy`,
        data: data
      });

      Swal.fire({
        title: "Sucesso!",
        text: "Estratégia de envio gerada com sucesso!.",
        icon: "success",
        showCancelButton: false,
        confirmButtonText: "Ok"
      });
    } catch (e) {
      Swal.fire({
        title: "Erro!",
        text: "Erro ao gerar estratégia de envio, tente novamente mais tarde.",
        icon: "error",
        showCancelButton: false,
        confirmButtonText: "Ok"
      });
    } finally {
      setLoading(false);
    }
  }, [selectedDeliveryStrategy, canCollect]);

  const handleCepConsult = useCallback(async cep => {
    try {
      setLoading(true);
      let response = await api.makeHttpRequest({
        method: "POST",
        url: `/addressbook/getAddress`,
        data: {
          zipCode: cep
        }
      });

      if (response?.erro === true) {
        setZipOriginOK("notOk");
        setZipOriginError("CEP inválido.");

        setAddress("");
        setAddressOK("vazio");
        setAddressError("");

        setAddressNeighborhood("");
        setAddressNeighborhoodOK("vazio");
        setAddressNeighborhoodError("");

        setUf("");
        setUfOK("");
        setCity("");
        setCityOK("");

        throw new Error("Não foi possível encontrar o CEP digitado.");
      }

      setZipOriginOK("ok");
      setZipOriginError("");

      if (!response.logradouro || response.logradouro === "undefined") {
        setAddressOK("vazio");
      } else {
        setAddress(response.logradouro);
        setAddressOK("ok");
        setAddressError("");
      }

      if (!response.bairro || response.bairro === "undefined") {
        setAddressNeighborhoodOK("vazio");
      } else {
        setAddressNeighborhood(response.bairro);
        setAddressNeighborhoodOK("ok");
        setAddressNeighborhoodError("");
      }

      setUf(response.uf);
      setUfOK("ok");
      setCity(response.localidade);

      setCityOK("ok");
    } catch (error) {
      console.error(error);

      Swal.fire({
        icon: "error",
        title: "CEP não encontrado",
        text: "Por favor, verifique o CEP inserido"
      });
    } finally {
      setLoading(false);
    }
  }, []);

  const handleValidationAddressObs = value => {
    if (value?.length < 2 && value?.length > 0) {
      setAddressObsOK("notOk");
    } else if (value?.length > 2) {
      setAddressObsOK("ok");
    } else {
      setAddressObsOK("vazio");
    }

    if (value?.length === 1) {
      setAddressObsError("Complemento deve ter pelo menos 2 caracteres");
    } else {
      setAddressObsError("");
    }
  };

  const handleInputAddressObs = useCallback(e => {
    let value = e.currentTarget.value;
    setAddressObs(value);
    handleValidationAddressObs(value);
  }, []);

  const handleValidationZipOrigin = useCallback(
    async (value, isASavedInfo = false) => {
      if (!value.length) {
        setZipOriginOK("notOk");
        setZipOriginError("Preenchimento obrigatório");
      } else if (value.length < 8) {
        setZipOriginOK("notOk");
        setZipOriginError("Preenchimento incorreto");
      } else {
        if (isASavedInfo) {
          setZipOriginOK("ok");
          setZipOriginError("");
        }

        // Não queremos manipular o CEP novamente,
        // se o CEP selecionado pertence a um endereço já salvo
        if (isASavedInfo === false) {
          const cep = value.substr(0, 5) + value.substr(6, 3);
          await handleCepConsult(cep);
        }
      }
    },
    [handleCepConsult]
  );

  const handleInputZipOrigin = useCallback(
    e => {
      let value = e;
      value = value.replace(/^(\d{5})(\d{3})/, "$1-$2");

      handleValidationZipOrigin(value);
      setZipOrigin(value);
    },
    [handleValidationZipOrigin]
  );

  const handleValidationAddressNeighborHood = value => {
    if (address?.length) return;

    value.length < 1
      ? setAddressNeighborhoodOK("notOk")
      : setAddressNeighborhoodOK("ok");
    if (value.length === 0) {
      setAddressNeighborhoodError("Campo obrigatório");
    } else {
      setAddressNeighborhoodError("");
    }
  };

  const handleInputAddressNeighborhood = useCallback(e => {
    let value = e.currentTarget.value;
    setAddressNeighborhood(value);
    handleValidationAddressNeighborHood(value);
  }, []);

  const handleValidationAddressNumber = value => {
    if (value !== "") {
      setAddressNumberOK("ok");
    } else {
      setAddressNumberOK("vazio");
    }
  };

  const handleInputAddressNumber = useCallback(e => {
    let value = e.currentTarget.value;

    if (value !== "" && !Number(value)) {
      return;
    }

    setAddressNumber(value);
    handleValidationAddressNumber(value);
  }, []);

  const handleValidationAddress = value => {
    value.length < 2 ? setAddressOK("notOk") : setAddressOK("ok");
    if (value.length === 0) {
      setAddressError("Campo obrigatório");
    } else if (value.length === 1) {
      setAddressError("Endereço deve ter pelo menos 2 caracteres");
    } else {
      setAddressError("");
    }
  };

  const handleInputAddress = useCallback(e => {
    let value = e.currentTarget.value;
    setAddress(value);
    handleValidationAddress(value);
  }, []);

  const handleInputApiKey = useCallback(e => {
    if (e.length < 1) {
      setUserApiKey(e);
      setUserApiKeyError("Campo obrigatório");
      setUserApiKeyOK("notOk");
    } else {
      setUserApiKey(e);
      setUserApiKeyError("");
      setUserApiKeyOK("ok");
    }
  }, []);

  const handleInputApiToken = useCallback(e => {
    if (e.length < 1) {
      setUserApiToken(e);
      setUserApiTokenError("Campo obrigatório");
      setUserApiTokenOK("notOk");
    } else {
      setUserApiToken(e);
      setUserApiTokenError("");
      setUserApiTokenOK("ok");
    }
  }, []);

  const handleInputStoreName = useCallback(e => {
    if (e.length < 1) {
      setStoreName(e);
      setStoreNameError("Campo obrigatório");
      setStoreNameOK("notOk");
    } else {
      setStoreName(e);
      setStoreNameError("");
      setStoreNameOK("ok");
    }
  }, []);

  const handleInputStoreNumber = useCallback(e => {
    if (e.length < 1) {
      setStoreNumber(e);
      setStoreNumberError("Campo obrigatório");
      setStoreNumberOK("notOk");
    } else {
      setStoreNumber(e);
      setStoreNumberError("");
      setStoreNumberOK("ok");
    }
  }, []);

  const handleInputSenderName = useCallback(e => {
    if (e.length < 1) {
      setSenderName("");
      setSenderNameError("Campo obrigatório");
      setSenderNameOK("notOk");
    } else if (e.length < 3) {
      setSenderName(e);
      setSenderNameError("Nome do remetente deve ter pelo menos 3 caracteres");
      setSenderNameOK("notOk");
    } else {
      setSenderName(e);
      setSenderNameError("");
      setSenderNameOK("ok");
    }
  }, []);

  useEffect(() => {
    window.setPageTitle("Integrações | Vtex");
  }, []);

  const loadingConfig = useCallback(async () => {
    await Promise.all([
      api
        .makeHttpRequest({
          method: "GET",
          url: `/vtex/config/key/apiKey`
        })
        .then(apiKey => {
          if (apiKey) {
            setUserApiKey(apiKey);
            setUserApiKeyOK("ok");
          }
        }),
      api
        .makeHttpRequest({
          method: "GET",
          url: `/vtex/config/key/apiToken`
        })
        .then(apiToken => {
          if (apiToken) {
            setUserApiToken(apiToken);
            setUserApiTokenOK("ok");
          }
        }),
      api
        .makeHttpRequest({
          method: "GET",
          url: `/vtex/config/key/store`
        })
        .then(apiStore => {
          if (apiStore) {
            setStoreName(apiStore);
            setStoreNameOK("ok");
          }
        }),
      api
        .makeHttpRequest({
          method: "GET",
          url: `/vtex/config/key/storeNumber`
        })
        .then(apiStoreNumber => {
          if (apiStoreNumber) {
            const storeNumber = apiStoreNumber.replace(/\D/g, "");
            setStoreNumber(Number(storeNumber));
            setStoreNumberOK("ok");
          }
        }),
      api
        .makeHttpRequest({
          method: "GET",
          url: `/vtex/config/key/active`
        })
        .then(active => {
          if (active === "true") {
            setActive(true);
          }
        }),
      api
        .makeHttpRequest({
          method: "GET",
          url: `/vtex/config/key/IS_ACTIVE_INSURANCE`
        })
        .then(active => {
          if (active === "true") {
            setActiveInsurance(true);
          }
        }),
      api
        .makeHttpRequest({
          method: "GET",
          url: `/vtex/config/key/IS_INACTIVE_ORDER_CREATE`
        })
        .then(inactive => {
          if (inactive === "true") {
            setActiveCreateOrder(false);
          }
        }),
      api
        .makeHttpRequest({
          method: "GET",
          url: `/vtex/config/key/IS_ACTIVE_STORES`
        })
        .then(active => {
          if (active === "true") {
            setActiveStores(true);
          }
        }),
      // api
      //   .makeHttpRequest({
      //     method: "GET",
      //     url: `/vtex/config/key/STATUSES_TO_ADD_ORDER`
      //   })
      //   .then(res => {
      //     if (res) {
      //       const statuses = JSON.parse(res || "[]").map(
      //         status => ({
      //           value: status,
      //           label: status === "waiting-for-sellers-confirmation" ? "Aguardando confirmação do vendedor" :
      //             status === "payment-pending" ? "Pagamento pendente" :
      //               status === "payment-approved" ? "Pagamento aprovado" :
      //                 status === "ready-for-handling" ? "Pronto para manuseio" :
      //                   status === "handling" ? "Manuseamento" :
      //                     status === "invoiced" ? "Faturado" : "Cancelado"
      //         })
      //       );
      //       setSelectedStatus(statuses)
      //       setSelectedStatusOK("ok")
      //     }
      //   }),
      api
        .makeHttpRequest({
          method: "GET",
          url: `/vtex/config/key/SENDER_DEFAULT_NAME`
        })
        .then(name => {
          if (name) {
            setSenderName(name);
            setSenderNameOK("ok");
          }
        }),
      api
        .makeHttpRequest({
          method: "GET",
          url: `/vtex/config/key/SENDER_DEFAULT_ADDRESS`
        })
        .then(res => {
          if (res) {
            const address = JSON.parse(res || "[]");
            setZipOrigin(address?.zip?.replace(/^(\d{5})(\d{3})/, "$1-$2"));
            setZipOriginOK("ok");

            setAddress(address.street);
            setAddressOK("ok");

            setAddressNumber(Number(address.number) || "");
            if (address.number) {
              setAddressNumberOK("ok");
            }

            setAddressObs(address.complement);
            if (address.complement) {
              setAddressObsOK("ok");
            }

            setAddressNeighborhood(address.district);
            setAddressNeighborhoodOK("ok");

            setUf(address.state);
            setUfOK("ok");

            setCity(address.city);
            setCityOK("ok");
          }
        })
    ]);
  }, [api]);

  useEffect(() => {
    setLoading(true);

    loadingConfig().catch(() => {
        Swal.fire({
          title: "Erro!",
          text: "Não foi possível carregar as informações da loja da Vtex.",
          icon: "error"
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (uf && uf.toUpperCase() === "RJ" && canCollect) {
      optionsDelivery.push({ value: "OWN_DELIVERY", label: "Super Expresso" });
    }
  }, [optionsDelivery, canCollect, uf]);

  useEffect(() => {
    if (optionsDelivery.find(r => !(r.value === DeliveryTypeEnum.SEDEX_HOJE))) {
      (async () => {
        if (zipOrigin && canCollect) {
          const result = await api.makeHttpRequest({
            method: "GET",
            url: `zipcode-ranges/check-zipcode`,
            params: {
              zipcode: zipOrigin?.replace("-", ""),
              type: DeliveryTypeEnum.SEDEX_HOJE
            }
          });

          setHasSedexHojeOption(result);
        }
      })()
    }
  }, [zipOrigin, canCollect]);

  useEffect(() => {
    if (hasSedexHojeOption) {
      optionsDelivery.push({ value: "SEDEX_HOJE", label: "Entrega Hoje" });
    } else {
      const index = optionsDelivery.findIndex(r => r.value === DeliveryTypeEnum.SEDEX_HOJE);

      if (index > -1) {
        optionsDelivery.splice(index, 1);
      }
    }
  }, [optionsDelivery, hasSedexHojeOption]);

  return (
    <>
      <Loading loading={loading} />
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.contentHeader}>
            <h2>Configurações</h2>
          </div>
          <div className={styles.contentBody}>
            <section className={styles.firstSection}>
              <div className={styles.contentSection}>
                <h4>
                  1º Passo - Desejo integrar as opções de frete da BRCom com a
                  minha loja
                </h4>
                <div>
                  <p>Informe os dados de usuário da VTEX</p>
                  <br />
                  <div className={` ${styles.divContainer}`}>
                    <div className={styles.input} role={userApiKeyOK}>
                      <input
                        type="text"
                        name="userApiKey"
                        value={userApiKey}
                        onChange={e => handleInputApiKey(e.currentTarget.value)}
                        placeholder="API Key"
                      />
                    </div>
                  </div>
                  {userApiKeyError && (
                    <p className={styles.error}>{userApiKeyError}</p>
                  )}
                  <br />
                  <div className={` ${styles.divContainer}`}>
                    <div className={styles.input} role={userApiTokenOK}>
                      <input
                        type="text"
                        name="userApiToken"
                        value={userApiToken}
                        onChange={e =>
                          handleInputApiToken(e.currentTarget.value)
                        }
                        placeholder="API Token"
                      />
                    </div>
                    {userApiTokenError && (
                      <p className={styles.error}>{userApiTokenError}</p>
                    )}
                  </div>
                  <br />
                  <div className={` ${styles.divContainer}`}>
                    <div className={styles.input} role={storeNameOK}>
                      <input
                        type="text"
                        name="storeName"
                        value={storeName}
                        onChange={e =>
                          handleInputStoreName(e.currentTarget.value)
                        }
                        placeholder="Nome da loja"
                      />
                    </div>
                    {storeNameError && (
                      <p className={styles.error}>{storeNameError}</p>
                    )}
                  </div>
                </div>

                <div>
                  <p>
                    Selecione abaixo caso possua mais de uma loja configurada na
                    VTEX e deseje filtrar os pedidos que serão importados.
                  </p>
                  <div className={styles.contentSection}>
                    <div className={styles.switchOption}>
                      <label className={styles.switch}>
                        <input
                          type="checkbox"
                          onChange={event =>
                            setActiveStores(event.target.checked)
                          }
                          checked={activeStores}
                        />
                        <span
                          className={`${styles.slider} ${styles.round}`}
                        ></span>
                      </label>
                      {activeStores
                        ? "Possuo mais de uma loja"
                        : "Possuo apenas uma loja"}
                    </div>
                  </div>
                </div>

                {activeStores && (
                  <>
                    <p>Informe o número da loja</p>

                    <div className={` ${styles.divContainer}`}>
                      <div className={styles.input} role={storeNumberOK}>
                        <input
                          type="number"
                          name="storeNumber"
                          value={storeNumber}
                          onChange={e =>
                            handleInputStoreNumber(e.currentTarget.value)
                          }
                          placeholder="Número da loja"
                        />
                      </div>
                      {storeNumberError && (
                        <p className={styles.error}>{storeNumberError}</p>
                      )}
                    </div>
                  </>
                )}

                <div className={styles.sectionTitle}>
                  <h5>
                    Informe o endereço de remetente que deve constar nas
                    etiquetas de envio geradas pela plataforma.
                  </h5>
                  <form>
                    <div className={styles.inputContainer}>
                      <div className={` ${styles.divContainer}`}>
                        <div className={` ${styles.inputContainerSmCep} `}>
                          <div className={styles.input} role={zipOriginOK}>
                            <input
                              type="text"
                              name="cep"
                              placeholder="Digite o CEP"
                              onChange={e =>
                                handleInputZipOrigin(e.currentTarget.value)
                              }
                              value={zipOrigin}
                              maxLength={8}
                            />
                            <a
                              href="https://buscacepinter.correios.com.br/app/endereco/index.php"
                              target="_blank"
                              rel="noopener noreferrer"
                              className={styles.cepLink}
                            >
                              Não sei o CEP
                            </a>
                          </div>
                        </div>
                      </div>
                      {zipOriginError && (
                        <p className={styles.error}>{zipOriginError}</p>
                      )}
                    </div>

                    <div
                      className={`${styles.inputContainer} ${styles.inputContainerAddress}`}
                    >
                      <div
                        className={` ${styles.divContainer} ${styles.inputContainerMd}`}
                      >
                        <div className={` ${styles.input} `} role={addressOK}>
                          <input
                            onChange={e => handleInputAddress(e)}
                            value={address}
                            placeholder="Digite o endereço"
                            type="text"
                            name="address"
                          />
                        </div>
                        {addressError && (
                          <p className={styles.error}>{addressError}</p>
                        )}
                      </div>

                      <div className={` ${styles.divContainer}`}>
                        <div className={styles.input} role={addressNumberOK}>
                          <input
                            onChange={e => handleInputAddressNumber(e)}
                            value={addressNumber}
                            placeholder="Digite o número"
                            type="text"
                            name="addressNumber"
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      className={`${styles.inputContainer} ${styles.inputContainerAddress}`}
                    >
                      <div className={` ${styles.divContainer}`}>
                        <div className={styles.input} role={addressObsOK}>
                          <input
                            onChange={e => handleInputAddressObs(e)}
                            value={addressObs}
                            placeholder="Digite o complemento"
                            type="text"
                            name="addressObs"
                          />
                        </div>
                        {addressObsError && (
                          <p className={styles.error}>{addressObsError}</p>
                        )}
                      </div>

                      <div className={` ${styles.divContainer}`}>
                        <div
                          className={styles.input}
                          role={addressNeighborhoodOK}
                        >
                          <input
                            onChange={e => handleInputAddressNeighborhood(e)}
                            value={addressNeighborhood}
                            placeholder="Digite o bairro"
                            type="text"
                            name="addressNeighborhood"
                          />
                        </div>
                        {addressNeighborhoodError && (
                          <p className={styles.error}>
                            {addressNeighborhoodError}
                          </p>
                        )}
                      </div>
                    </div>
                    <div
                      className={`${styles.inputContainer} ${styles.inputContainerAddress}`}
                    >
                      <div className={styles.divContainer}>
                        <div className={styles.input} role={ufOK}>
                          <input
                            onChange={() => { }}
                            value={uf}
                            type="text"
                            name="uf"
                          />
                        </div>
                      </div>

                      <div className={styles.divContainer}>
                        <div className={` ${styles.divContainer}`}>
                          <div className={styles.input} role={cityOK}>
                            <input
                              onChange={() => { }}
                              value={city}
                              type="text"
                              name="city"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>

                <div className={styles.sectionTitle}>
                  <p>
                    Informe o nome de remetente que deve constar nas etiquetas
                    de envio geradas pela plataforma.
                  </p>
                  <div
                    style={{ marginTop: "16px" }}
                    className={styles.divContainer}
                  >
                    <div className={styles.input} role={senderNameOK}>
                      <input
                        onChange={e =>
                          handleInputSenderName(e.currentTarget.value)
                        }
                        value={senderName}
                        placeholder="Nome do remetente"
                        type="text"
                        name="senderName"
                      />
                    </div>
                    {senderNameError && (
                      <p className={styles.error}>{senderNameError}</p>
                    )}
                  </div>
                </div>

                {/* <div className={styles.contentSection}>
                  <h5>
                    Selecione abaixo o(s) status dos pedidos que deverão ser
                    importados pela integração:
                  </h5>
                  <br />
                  <div>
                    <div className={`${styles.input}  ${selectedStatusOK === "notOk" ?
                      styles.formSelectError :
                      selectedStatusOK === "ok" ?
                        styles.selectOk : ""
                      }`}>
                      <label htmlFor="type">Status</label>
                      <Select
                        onChange={(e) => handleChangeSelectStatus(e)}
                        options={optionsStatuses}
                        value={selectedStatus}
                        placeholder="Selecione o status"
                        styles={selectStyle}
                        isMulti
                      />
                    </div>
                    {selectedStatusError && <p className={styles.error}>{selectedStatusError}</p>}
                  </div>
                </div> */}

                <div className={styles.wrapper}>
                  <h4>Criação de pedido</h4>
                  <br />
                  <p>
                    Abaixo é possível ativar ou desatitvar a criação de pedidos.
                    <br />
                    Serviços como cotação funcionarão normalmente.
                  </p>
                  <br />
                  <div>
                    <div className={styles.switchOption}>
                      <label className={styles.switch}>
                        <input
                          type="checkbox"
                          onChange={event =>
                            setActiveCreateOrder(event.target.checked)
                          }
                          checked={activeCreateOrder}
                        />
                        <span
                          className={`${styles.slider} ${styles.round}`}
                        ></span>
                      </label>
                      {activeCreateOrder ? "Ativado" : "Desativado"}
                    </div>
                  </div>
                </div>

                <div>
                  <h4>Configuração de Seguro</h4>
                  <p style={{ marginTop: "16px" }}>
                    Abaixo é possível ativar ou desatitvar a cotação de frete
                    com Seguro.
                    <br />
                    Essa opção é válida para todos os fretes que são cotados.
                  </p>
                  <div className={styles.contentSection}>
                    <div className={styles.switchOption}>
                      <label className={styles.switch}>
                        <input
                          type="checkbox"
                          onChange={event =>
                            setActiveInsurance(event.target.checked)
                          }
                          checked={activeInsurance}
                          disabled={!userApiToken || !userApiKey || !storeName}
                        />
                        <span
                          className={`${styles.slider} ${styles.round}`}
                        ></span>
                      </label>
                      {activeInsurance ? "Ativado" : "Desativado"}
                    </div>
                  </div>
                </div>
              </div>

              <div className={styles.btnContainer}>
                <button
                  type="submit"
                  onClick={sendData}
                  className={styles.btnSave}
                  disabled={
                    userApiTokenOK === "notOk" ||
                    userApiKeyOK === "notOk" ||
                    storeNameOK === "notOk" ||
                    //selectedStatusOK === "notOk" ||
                    zipOriginOK === "notOk" ||
                    !zipOrigin ||
                    //!selectedStatus ||
                    !storeName ||
                    !userApiKey ||
                    !userApiToken ||
                    !senderName ||
                    senderNameOK === "notOk"
                  }
                >
                  Salvar
                </button>
              </div>
              {!active && (
                <div className={styles.btnContainer}>
                  <button
                    type="button"
                    onClick={activeIntegration}
                    className={styles.btnSave}
                    disabled={
                      userApiTokenOK === "notOk" ||
                      userApiKeyOK === "notOk" ||
                      storeNameOK === "notOk" ||
                      zipOriginOK === "notOk" ||
                      !zipOrigin ||
                      !userApiToken ||
                      !userApiKey ||
                      !storeName ||
                      !canActivate
                    }
                  >
                    Ativar integração
                  </button>
                </div>
              )}
            </section>
            {active && (
              <>
                <section className={styles.secondSection}>
                  <div>
                    <div>
                      <h4>2º Passo - Gerar estratégia de envio</h4>
                      <p style={{ marginTop: "16px" }}>
                        Cada tipo de frete precisa de uma estratégia de envio
                        correspondente na sua conta VTEX.
                        <br />
                        Abaixo, selecione os tipos de frete para os quais deseja
                        gerar uma estratégia de envio.
                        <br /> Após gerado, acesse sua conta VTEX e anexe a
                        planilha gerada no passo anterior a cada estratégia
                        correspondente.
                      </p>
                    </div>
                    <br />
                    <div className={styles.contentSection}>
                      <div className={styles.input}>
                        <Select
                          onChange={handleChangeSelectedDeliveryStrategy}
                          options={optionsDelivery}
                          value={selectedDeliveryStrategy}
                          placeholder="Tipo de envio"
                          styles={selectStyle}
                          isClearable
                        />
                      </div>
                    </div>

                    <div className={styles.btnContainer}>
                      <button
                        type="submit"
                        onClick={() => generateShippingStrategy()}
                        className={`${styles.btnSave} ${styles.btnGerar}`}
                        disabled={!selectedDeliveryStrategy}
                      >
                        Gerar
                      </button>
                    </div>
                  </div>
                </section>

              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export { Vtex };
