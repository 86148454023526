import React, { useState } from "react";
import { connect } from "react-redux";
import objectPath from "object-path";
import Header from "./header/Header";
import { withRouter } from "react-router-dom";
import HeaderMobile from "./header/HeaderMobile";
import ScrollTop from "../../app/partials/layout/ScrollTop";
import HTMLClassService from "./HTMLClassService";
import LayoutConfig from "./LayoutConfig";
import MenuConfig from "./MenuConfig";
import LayoutInitializer from "./LayoutInitializer";
import KtContent from "./KtContent";
import QuickPanel from "../../app/partials/layout/QuickPanel";
import "./assets/Base.scss";
import { Sidebar } from "../../app/components/Sidebar";

const htmlClassService = new HTMLClassService();

function Layout({ children, asideDisplay, selfLayout, layoutConfig }) {
  htmlClassService.setConfig(layoutConfig);
  // scroll to top after location changes
  window.scrollTo(0, 0);

  const [mobileBarOpen, setMobileBarOpen] = useState(false);

  const toggleMobile = () => {
    setMobileBarOpen(!mobileBarOpen);
  };

  const contentCssClasses = htmlClassService.classes.content.join(" ");

  return selfLayout !== "blank" ? (
    <LayoutInitializer
      styles={[]}
      menuConfig={MenuConfig}
      layoutConfig={LayoutConfig}
      htmlClassService={htmlClassService}
    >
      {/* <!-- begin:: Header Mobile --> */} <HeaderMobile toggleMobile={toggleMobile}/>{" "}
      {/* <!-- end:: Header Mobile --> */}
      <div
        className="kt-grid kt-grid--hor kt-grid--root"
        style={{ backgroundColor: "#E5E5E5" }}
      >
        {/* <!-- begin::Body --> */}{" "}
        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
          {" "}
          {/* <!-- begin:: Aside Left --> */}{" "}
          {asideDisplay && (
            <>
              {/* <AsideLeft /> */}
              <Sidebar toggleMobile={toggleMobile} mobileBarOpen={mobileBarOpen} />
            </>
          )}{" "}
          {/* <!-- end:: Aside Left --> */}{" "}
          <div
            className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper"
            style={{ paddingLeft: 0 }}
            id="kt_wrapper"
          >
            {/* <!-- begin:: Header READY --> */}
            <Header /> {/* <!-- end:: Header --> */}
            {/* <!-- begin:: Content --> */}{" "}
            <div
              id="kt_content"
              className={`kt-content ${contentCssClasses} kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor`}
              style={{ padding: 0 }}
            >
              {/* <!-- begin:: Content Body --> */} {children}
              {/*  <KtContent> {children} </KtContent>{" "} */}
              {/*<!-- end:: Content Body -->*/}{" "}
            </div>{" "}
            {/* <!-- end:: Content --> */} {/* <Footer /> */}{" "}
          </div>{" "}
        </div>{" "}
        {/* <!-- end:: Body --> */}{" "}
      </div>{" "}
      <QuickPanel />
      <ScrollTop />
    </LayoutInitializer>
  ) : (
    // BLANK LAYOUT
    <div className="kt-grid kt-grid--ver kt-grid--root kt-page">
      <KtContent> {children} </KtContent>{" "}
    </div>
  );
}

const mapStateToProps = ({ builder: { layoutConfig } }) => ({
  layoutConfig,
  selfLayout: objectPath.get(layoutConfig, "self.layout"),
  asideDisplay: objectPath.get(layoutConfig, "aside.self.display"),
  subheaderDisplay: objectPath.get(layoutConfig, "subheader.display")
});

export default withRouter(connect(mapStateToProps)(Layout));
