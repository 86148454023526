import React, { useCallback } from "react";

import ReactModal from "react-modal";

import error from "../../assets/icons/redErrorCircle.svg";

import { Container } from "./styles";

const ErrorModal = ({ setErrorModal, paymentError, responseCollectId, paymentMessageError = "" }) => {

  const handleRedirect = useCallback(() => {
    setErrorModal(false);
  }, [setErrorModal]);

  const errorMessages = {
    "400": "Falha na Requisição",
    "401": "Usuário não autorizado",
    "403": "Bloqueio por IP/Domínio",
    "404": "Dados não encontrados",
    "412": "Parâmetros válidos, mas a Requisação Falhou",
    "422": "Dados do Cartão Inválidos",
    "429":
      "Quantidade de requisições realizadas pelo IP maior que o permitido pela Pagar.me"
  };

  return (
    <>
      <ReactModal
        isOpen={true}
        shouldCloseOnOverlayClick={true}
        onRequestClose={() => setErrorModal(false)}
        style={{
          overlay: {
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(51, 51, 51, 0.75)",
            zIndex: 999
          },
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            transform: "translate(-50%, -50%)",
            zIndex: 999,
            border: "none",
            backgroundColor: "transparent",
            padding: 0
          }
        }}
      >
        <Container>
          <img src={error} alt="" />
          <p>
            Falha ao realizar o pagamento:
            <br />
            <span>{(paymentMessageError !== "" || paymentMessageError !== "{}") ? paymentMessageError : errorMessages[paymentError.toString()]}</span>
          </p>
          <button onClick={handleRedirect}>Continuar</button>
        </Container>
      </ReactModal>
    </>
  );
};

export default ErrorModal;
