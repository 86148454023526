import React from "react";

import amex from "../../../../assets/logo/creditCardFlags/amex.svg";
import elo from "../../../../assets/logo/creditCardFlags/elo.svg";
import hiper from "../../../../assets/logo/creditCardFlags/hiper.svg";
import { default as master, default as mastercard } from "../../../../assets/logo/creditCardFlags/master.svg";
import visa from "../../../../assets/logo/creditCardFlags/visa.svg";

const CardFlag = ({ cardFlag }) => {
  const pathsCardFlags = {
    amex,
    elo,
    hiper,
    master,
    mastercard,
    visa
  };

  return cardFlag ? (
    <img
      src={pathsCardFlags[cardFlag]}
      alt={`Logo ${pathsCardFlags[cardFlag]}`}
      style={{ width: 40, height: 40 }}
    />
  ) : null;
};

export default CardFlag;
