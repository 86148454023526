import styled from "styled-components";

export const Container = styled.div`
  border-radius: 4px !important;
  * {
    font-family: "Mulish";
  }
`;
export const Table = styled.table`
  border: 0 solid #c1c1c1;
  font-size: 16px;
  width: 100%;
  margin-top: 16px;
  overflow-y: hidden;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);

  tr {
    color: #333333;
  }

  thead {
    tr {
      background-color: #def6e7;

      th {
        padding: 14px 10px;
        font-weight: 700;
        font-size: 14px;
        width: fit-content;
        text-align: center;
      }
      > :last-child {
        text-align: center;
      }
    }
  }
  tbody {
    &:nth-child(odd) {
      background-color: #fafafa;
    }
    /* box-shadow: 3px 4px 10px rgba(0, 0, 0, 0.1); */
    tr {
      td {
        font-size: 12px;
        padding: 10px 10px;
        font-weight: 500;
        text-align: center;
        /* width: 150px;
        max-width: 200px; */
        color: #333333;

        &[role="ok"] {
          font-weight: 400;
          color: #409e6a;
        }

        &[role="notOk"] {
          font-weight: 400;
          color: #ca1818;
        }

        a {
          font-weight: 600;
        }
        span {
          border-radius: 4px !important;
          padding: 5px 4px;
        }
      }
      td:nth-child(1) {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
      td:nth-child(5) {
        white-space: nowrap;
      }
      td:nth-child(6) {
        white-space: nowrap;
      }
      .gray {
        font-weight: 600;
        background-color: #c1c1c1;
      }
      .going {
        font-weight: 600;
        background-color: #f5cb2b;
      }
      .delivered {
        font-weight: 600;
        background-color: #76c092;
      }
      .error {
        background-color: #ed7878;
        font-weight: 600;
      }
      .iconTitle {
        color: #409e6a;
        font-size: 18px;
        font-weight: bold;
        text-decoration: none;
      }
      .rowCardConciliation {
        padding: 0;
        white-space: break-spaces !important;
        text-align: left;
        table {
          tbody tr td {
            text-align: left !important;
            padding: 0 !important;
          }
          tbody tr {
            border-top: none;
          }
          thead tr {
            background-color: #fff;
            border-top: none;
          }
          thead tr td {
            text-align: left !important;
            padding: 0 !important;
          }
        }
      }
    }
    .titleHeader {
      cursor: pointer;
    }

    .accordionButton {
      width: 10px !important;
    }
  }
`;
