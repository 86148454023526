import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { FaTruck, FaSearch } from "react-icons/fa";
import { AiOutlineCalendar } from "react-icons/ai";
import { BiSolidMessageSquareError } from "react-icons/bi";
import { Link } from "react-router-dom";
import { ReactComponent as CheckedIconSVG } from "../../../../assets/icons/checked.svg";
import collectStagesEnum from "../../../../enums/CollectStagesEnum";
import shippingStagesEnum from "../../../../enums/ShippingStagesEnum";
import styles from "./styles.module.scss";
import { BrComerceApiService } from "../../../../services/BrComerceApiService";
import { Loading } from "../../../../components/Loading";
import { FiPrinter } from "react-icons/fi";
import { MdFactCheck } from "react-icons/md";
import store from "../../../../store/store";

function CardCollect({
  collect,
  handleCheck,
  isChecked,
  showModalDetails,
  setShowScheduleModal,
  setIds,
  ids
}) {
  const { auth } = store.getState();
  const { user } = auth;

  const api = new BrComerceApiService();

  const [totalPrice, setTotalPrice] = useState(0);
  const [shippingStatus, setShippingStatus] = useState({
    COM_PROBLEMAS: 0,
    ENTREGUES: 0,
    EM_TRANSITO: 0
  });
  const [statusCollect, setStatusCollect] = useState({
    type: "",
    message: ""
  });
  const [statusColor, setStatusColor] = useState("");

  const loadCollectStatusInfo = useCallback(collect => {
    const allStagesOfShippings = {
      EM_TRANSITO: [
        shippingStagesEnum.OBJETO_COLETADO.id,
        shippingStagesEnum.OBJETO_EXPEDIDO.id,
        shippingStagesEnum.OBJETO_EM_TRANSITO.id,
        shippingStagesEnum.OBJETO_SAIU_PARA_ENTREGA.id,
        shippingStagesEnum.OBJETO_ATRASADO.id,
        shippingStagesEnum.AGUARDANDO_POSTAGEM.id,
        shippingStagesEnum.OBJETO_POSTADO.id
      ],
      ENTREGUES: [
        shippingStagesEnum.OBJETO_PRONTO_PARA_RETIRADA.id,
        shippingStagesEnum.ENTREGA_REALIZADA.id
      ],
      COM_PROBLEMAS: [
        shippingStagesEnum.OBJETO_DEVOLVIDO.id,
        shippingStagesEnum.OBJETO_EXTRAVIADO.id,
        shippingStagesEnum.OBJETO_NAO_FOI_ENTREGUE.id,
        shippingStagesEnum.NAO_FOI_POSSIVEL_ENTREGAR_O_OBJETO.id
      ]
    };

    const allCollectCountStatus = collect.shippings.reduce(
      (acc, item) => {
        if (allStagesOfShippings.EM_TRANSITO.includes(item.idShippingStatus)) {
          return {
            ...acc,
            EM_TRANSITO: acc.EM_TRANSITO + 1
          };
        }
        if (allStagesOfShippings.ENTREGUES.includes(item.idShippingStatus)) {
          return {
            ...acc,
            ENTREGUES: acc.ENTREGUES + 1
          };
        }
        if (
          allStagesOfShippings.COM_PROBLEMAS.includes(item.idShippingStatus)
        ) {
          return {
            ...acc,
            COM_PROBLEMAS: acc.COM_PROBLEMAS + 1
          };
        }

        return acc;
      },
      {
        COM_PROBLEMAS: 0,
        ENTREGUES: 0,
        EM_TRANSITO: 0
      }
    );

    setShippingStatus(allCollectCountStatus);
  }, []);

  const loadShippingStatus = useCallback(collect => {
    const idCollect = collect.idCollectsStatus;

    const {
      AGUARDANDO_PAGAMENTO,
      AGENDAMENTO,
      PAGAMENTO,
      COLETA,
      ETIQUETAS_DISPONIVEIS,
      ESTORNO
    } = collectStagesEnum;

    const statusCollect = {
      WARNING: {
        status: "warning",
        infoStatus: [
          {
            id: AGUARDANDO_PAGAMENTO.id,
            message: AGUARDANDO_PAGAMENTO.message
          },
          {
            id: AGENDAMENTO.AGUARDANDO_AGENDAMENTO.id,
            message: AGENDAMENTO.AGUARDANDO_AGENDAMENTO.message
          }
        ]
      },
      ERROR: {
        status: "error",
        infoStatus: [
          {
            id: PAGAMENTO.PAGAMENTO_NEGADO.id,
            message: PAGAMENTO.PAGAMENTO_NEGADO.message
          },
          {
            id: COLETA.COLETA_CANCELADA.id,
            message: COLETA.COLETA_CANCELADA.message
          }
        ]
      },
      NEUTRAL: {
        status: "neutral",
        infoStatus: [
          {
            id: ESTORNO.ESTORNO_CONCLUIDO.id,
            message: ESTORNO.ESTORNO_CONCLUIDO.message
          },
          {
            id: ESTORNO.ESTORNO_PROCESSANDO.id,
            message: ESTORNO.ESTORNO_PROCESSANDO.message
          },
          {
            id: ETIQUETAS_DISPONIVEIS.id,
            message: ETIQUETAS_DISPONIVEIS.message
          }
        ]
      },
      SUCCESS: {
        status: "success",
        infoStatus: [
          {
            id: PAGAMENTO.PAGAMENTO_APROVADO.id,
            message: PAGAMENTO.PAGAMENTO_APROVADO.message
          },
          {
            id: AGENDAMENTO.COLETA_AGENDADA.id,
            message: AGENDAMENTO.COLETA_AGENDADA.message
          },
          {
            id: COLETA.COLETA_CONCLUIDA.id,
            message: COLETA.COLETA_CONCLUIDA.message
          }
        ]
      }
    };

    const statusCollectActual = Object.values(statusCollect).reduce(
      (acc, item) => {
        const status = item.infoStatus.find(info => info.id === idCollect);

        if (!status) return acc;

        return {
          type: item.status,
          message: status.message
        };
      },
      ""
    );

    setStatusCollect(statusCollectActual);
  }, []);

  const [loading, setLoading] = useState(false);

  const handlePrint = useCallback(async () => {
    const shippingsIds = collect?.shippings.map(s => s?.id);
    try {
      setLoading(true);

      window.localStorage.setItem(
        "Brcom:Etiquetas",
        JSON.stringify(shippingsIds)
      );

      window.open("/etiquetas", "_blank");
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  }, [api, collect.id, setLoading]);

  useEffect(() => {
    const totalPrice = collect.shippings.reduce(
      (acc, shipping) => acc + shipping.price,
      0
    );
    setTotalPrice(totalPrice);
  }, [collect]);

  useEffect(() => {
    loadCollectStatusInfo(collect);
    loadShippingStatus(collect);
  }, [collect]);

  useEffect(() => {
    handleBackgroundColorByCollectStatus(statusCollect);
  }, [statusCollect]);

  const handleScheduleCollect = () => {
    setShowScheduleModal(true);
    setIds([collect.id]);
  };

  const handleBackgroundColorByCollectStatus = statusCollect => {
    const status = {
      [collectStagesEnum.AGENDAMENTO.COLETA_AGENDADA.message]: "#9DDFF3",
      [collectStagesEnum.AGENDAMENTO.AGUARDANDO_AGENDAMENTO.message]: "#F5CB2B",
      [collectStagesEnum.AGUARDANDO_PAGAMENTO.message]: "#FFA624",
      [collectStagesEnum.PAGAMENTO.PAGAMENTO_APROVADO.message]: "#69BE40",
      [collectStagesEnum.PAGAMENTO.PAGAMENTO_NEGADO.message]: "#FF6B71",
      [collectStagesEnum.COLETA.COLETA_CONCLUIDA.message]: "#409E6A",
      [collectStagesEnum.COLETA.COLETA_CANCELADA.message]: "#CA1818",
      [collectStagesEnum.ETIQUETAS_DISPONIVEIS.message]: "#0050C1"
    };

    setStatusColor(status[statusCollect.message]);
  };

  return (
    <>
      <Loading loading={loading} />
      <div className={`${styles.cardWrapper} `}>
        <div className={styles.header}>
          <div className={styles.headerCollectInfo}>
            <button
              onClick={() => handleCheck(collect, statusCollect)}
              type="button"
            >
              {isChecked ? <CheckedIconSVG /> : <div />}
            </button>
            <p>
              <strong>PEDIDO:</strong>
              <Link to={`detalhe-coleta/${collect.id}`}>{collect.id}</Link>
            </p>
          </div>
          <div className={styles.headerAction}>
            {user.canCreateCollectSchedule && collect.idCollectStatus >= 1 && (
              <button onClick={handleScheduleCollect}>
                <AiOutlineCalendar />
              </button>
            )}
            <button onClick={showModalDetails}>
              <FaSearch />
            </button>
            {collect.idCollectStatus >= 1 && (
              <button onClick={handlePrint}>
                <FiPrinter />
              </button>
            )}
          </div>
        </div>

        <div className={styles.body}>
          {collect?.user && (
            <div className={styles.name_client}>
              <p>Empresa: {collect.user.userData[1].Value}</p>
            </div>
          )}
          <div className={styles.description}>
            <h3>
              Total de <strong>{collect.shippings.length} objetos</strong>
            </h3>
            <p>
              <strong>{moment(collect.created_at).format("DD/MM/YYYY")}</strong>
            </p>
          </div>

          <div className={styles.collects}>
            <div className={styles.collectInfo}>
              <p>
                <MdFactCheck size={24} className={styles.success} />
                <strong>
                  {shippingStatus.ENTREGUES >= 10
                    ? shippingStatus.ENTREGUES
                    : String(shippingStatus.ENTREGUES).padStart(2, "0")}
                </strong>
                <span>{` Entregues`}</span>
              </p>
              <p>
                <FaTruck size={24} className={styles.inProgress} />
                <strong>
                  {shippingStatus.EM_TRANSITO >= 10
                    ? shippingStatus.EM_TRANSITO
                    : String(shippingStatus.EM_TRANSITO).padStart(2, "0")}
                </strong>
                <span>{` Em trânsito`}</span>
              </p>
              <p>
                <BiSolidMessageSquareError
                  size={24}
                  className={styles.danger}
                />
                <strong>
                  {shippingStatus.COM_PROBLEMAS >= 10
                    ? shippingStatus.COM_PROBLEMAS
                    : String(shippingStatus.COM_PROBLEMAS).padStart(2, "0")}
                </strong>
                <span>{` Com problemas`}</span>
              </p>
            </div>

            <div
              className={styles.totalValue}
              style={{
                backgroundColor: `${statusColor}`
              }}
            >
              <p>
                {new Intl.NumberFormat("pt-BR", {
                  style: "currency",
                  currency: "BRL"
                }).format(totalPrice)}
              </p>
            </div>
          </div>
        </div>
        <div
          className={styles.footer}
          style={{
            backgroundColor: `${statusColor}`
          }}
        >
          <p>{statusCollect.message}</p>
        </div>
      </div>
    </>
  );
}

function cardCollectAreEqual(prevCollect, nextCollect) {
  const collectHasSameId = prevCollect.collect.id === nextCollect.collect.id;
  const collectHasSameCheckEqual =
    prevCollect.isChecked === nextCollect.isChecked;

  return collectHasSameId && collectHasSameCheckEqual;
}
export const CardCollectComponent = React.memo(
  CardCollect,
  cardCollectAreEqual
);
