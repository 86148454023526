import React, { useEffect, useState } from "react";
import { MdContentCopy } from "react-icons/md";
import Swal from "sweetalert2";
import { Loading } from "../../../../components/Loading";
import { BrComerceApiService } from "../../../../services/BrComerceApiService";
import moment from "moment";
import styles from "./styles.module.scss";
import isQrCodeExpired from "../../../../utils/ExpireTimePix";

const WalletDepositPix = ({ invoiceId, setShowGenerated }) => {
  const api = new BrComerceApiService();

  const [qrCodeData, setQrCodeData] = useState({});
  const [loading, setLoading] = useState(false);
  const [isQrCodeCopied, setIsQrCodeCopied] = useState(false);
  const [showInstructions, setShowInstructions] = useState(false);


  const handleCopyQrCodeString = () => {
    navigator.clipboard.writeText(qrCodeData?.qrCodeString);
    setIsQrCodeCopied(true);
  };

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const response = await api.makeHttpRequest({
          method: "GET",
          url: `invoices/${invoiceId}`
        });

        const expiresDate = moment(
          response?.invoice?.lastTransaction?.expire_at
        );

        setQrCodeData({
          expiresDate,
          qrCodeUrl: response?.invoice?.lastTransaction?.qrCodeUrl,
          qrCodeString: response?.invoice?.lastTransaction?.qrCodeString
        });
      } catch (e) {
        Swal.fire({
          icon: "error",
          title: "Erro",
          text:
            "Houve um erro ao carregar a página, tente novamente mais tarde."
        });
        console.error("Houve um erro ao carregar a página");
      } finally {
        setLoading(false);
      }
    })();
  }, [invoiceId]);

  useEffect(() => {
    if (!isQrCodeCopied) return;

    const timeout = setTimeout(() => {
      setIsQrCodeCopied(false);
    }, 2500);

    return () => clearTimeout(timeout);
  }, [isQrCodeCopied]);

  return (
    <>
      <div className={styles.background} />
      <div className={styles.content}>
        <Loading loading={loading} />
        {showInstructions ? (
          <div className={styles.stepBys}>
            <p>
              <span>1.</span> Acesse a opção Pix pelo app de sua preferência;
            </p>
            <p>
              <span>2.</span> Escaneie o QRCode ou copie e cole o código abaixo;
            </p>
            <div className={styles.qrCodeContainer}>
              <img
                className={styles.qrCode}
                src={qrCodeData?.qrCodeUrl}
                alt="QR Code"
              />
              {
                !qrCodeData.qrCodeString && (
                  <>
                    <span className={styles.preferenceWarning}>
                    </span>
                    <div className={styles.copyCodeContainer}>
                      <p>{qrCodeData.qrCodeString}</p>
                      <div
                        className={styles.copyIcon}
                        onClick={() => handleCopyQrCodeString()}
                      >
                        <MdContentCopy />
                      </div>
                      {isQrCodeCopied && (
                        <span className={styles.copyWarning}>Copiado!</span>
                      )}
                    </div>
                  </>
                )
              }
              <span className={styles.timeWarning}>
                Efetue o pagamento em até{" "}
                <strong>
                  {isQrCodeExpired(qrCodeData?.expiresDate) || "00"} minuto
                  {isQrCodeExpired(qrCodeData?.expiresDate) === 1 ? "." : "s."}
                </strong>
              </span>
            </div>
            <p>
              <span>3.</span> Após o pagamento, você será notificado por e-mail e uma mensagem aparecerá aqui na tela;
            </p>
          </div>
        ) :
          <div className={styles.qrCodeContainer}>
            <img
              className={styles.qrCode}
              src={qrCodeData?.qrCodeUrl}
              alt="QR Code"
            />
            {
              !qrCodeData.qrCodeString && (
                <>
                  <span className={styles.preferenceWarning}>
                    Se preferir, copie o código abaixo para realizar o pagamento.
                  </span>
                  <div className={styles.copyCodeContainer}>
                    <p>{qrCodeData.qrCodeString}</p>
                    <div
                      className={styles.copyIcon}
                      onClick={() => handleCopyQrCodeString()}
                    >
                      <MdContentCopy />
                    </div>
                    {isQrCodeCopied && (
                      <span className={styles.copyWarning}>Copiado!</span>
                    )}
                  </div>
                </>
              )
            }
            <span className={styles.timeWarning}>
              Efetue o pagamento em até{" "}
              <strong>
                {isQrCodeExpired(qrCodeData?.expiresDate) || "00"} minuto
                {isQrCodeExpired(qrCodeData?.expiresDate) === 1 ? "." : "s."}
              </strong>
            </span>
          </div>
        }

        <div className={styles.btnContainer}>
          <button type="button" className={styles.btnCancel} onClick={() => setShowGenerated(false)}>
            <span>Fechar</span>
          </button>

          <button type="button" className={styles.btnOk} onClick={() => setShowInstructions(!showInstructions)}>
            <span>{showInstructions ? "Esconder instruções" : "Exibir instruções"}</span>
          </button>
        </div>
      </div>
    </>
  );
};

export { WalletDepositPix };
