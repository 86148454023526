import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { BrComerceApiService } from "../../../../services/BrComerceApiService";
import { Loading } from "../../../../components/Loading";
import Swal from "sweetalert2";

const BlingCallback = () => {
  const history = useHistory();
  const api = new BrComerceApiService();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    api
      .makeHttpRequest({
        method: "POST",
        url: `/bling/users`,
        data: {}
      })
      .then(response => {
        Swal.fire({
          title: "Sucesso!",
          text: "Integração criada com sucesso.",
          icon: "success",
          showCancelButton: false,
          confirmButtonText: "Ok"
        });
      })
      .catch(e => {
        Swal.fire({
          icon: "error",
          title: "Integração Bling",
          text: e.response.data.message
        });
        console.log(e.response);
      })
      .finally(() => {
        setLoading(false);
        history.push("/integracao/bling");
      });
  }, [history.location.search]);
  return (
    <>
      <Loading loading={loading} />
    </>
  );
};

export { BlingCallback };
